export interface SandboxIndex {
  IndexId: number;
  Order: number;
}

export function sortSandboxIndices(a: SandboxIndex, b: SandboxIndex): number {
  const aOrder = Number(a.Order);
  const bOrder = Number(b.Order);
  if (!isNaN(aOrder) && !isNaN(bOrder)) {
    return a.Order - b.Order;
  } else if (!isNaN(aOrder) && isNaN(bOrder)) {
    return 1; // a
  } else if (isNaN(aOrder) && !isNaN(bOrder)) {
    return -1; // b
  } else {
    return 0; // ?
  }
}
