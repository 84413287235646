import { SandboxPortfolio, datetime } from '../../api/webservice.service';
import { SandboxPortfolioMetaResponse } from './sandbox-portfolio-meta-response';

export class Sandbox3PortfolioMeta {
    Id?: number;
    Portfolio: SandboxPortfolio;
    LastModifiedDate: datetime;
    Starred: boolean;
    Hidden: boolean;

    get Name() {
        if (this.Portfolio.CombinedIndex != null) {
            return this.Portfolio.CombinedIndex.Name;
        } else {
            return null;
        }
    }

    constructor(init?: Sandbox3PortfolioMeta | SandboxPortfolioMetaResponse, portfolio?: SandboxPortfolio) {
        if (init && 'Portfolio' in init) {
            if (init['Id']) {
                this.Id = init.Id;
            }
            this.Portfolio = new SandboxPortfolio(portfolio || init.Portfolio);
            this.LastModifiedDate = init.LastModifiedDate;
            this.Starred = init.Starred;
            this.Hidden = init.Hidden;
        }
        if (init && 'combinedIndexId' in init) {
            this.Id = init.id;
            this.Hidden = init.hidden;
            this.LastModifiedDate = init.lastModifiedDate;
            this.Starred = init.starred;
        }
        if (portfolio instanceof SandboxPortfolio) {
            this.Portfolio = portfolio;
        }
    }

    toString() {
        return this.Name || 'Unnamed portfolio';
    }
}
