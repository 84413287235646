export enum SandboxEditorView {
  Settings,
  Standard,
  Zoomed,
  Optimize, 
  Statistics,
  ForecastConfig,
  ImplementPortfolio
}

