import { Component, OnInit } from '@angular/core';
import { QuizId } from '../../models';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-kyc-sustainability-estimate',
  templateUrl: './kyc-sustainability-estimate.component.html',
  styleUrls: ['./kyc-sustainability-estimate.component.scss']
})
export class KycSustainabilityEstimateComponent implements OnInit {

  constructor(
    private _quizSvc: QuizService,
  ) { }

  ngOnInit(): void {
  }

  calculateInvestmentRatio() {
    // fetch answers from previous questions
    const assetAnswer = this._quizSvc.getCachedAnswer(QuizId.AMLPrivate1, 3)?.answer as string;
    // translate asset answer to number
    const assetAnswerMap = {
      '< 0 €': 0,
      '0 - 10 000 €': 10000,
      '10 000 - 100 000 €': 100000,
      '100 000 - 1 000 000 €': 1000000,
      '> 1 000 000 €': 10000000,
    }
    const netAssetValue = assetAnswer in assetAnswerMap ? assetAnswerMap[assetAnswer] : 0;

    const age = +this._quizSvc.getCachedAnswer(QuizId.Amateur, 1)?.answer || 31;

    const incomeAnswer = this._quizSvc.getCachedAnswer(QuizId.AMLPrivate1, 1).answer as string;
    // translate answer to number
    const incomeAnswerMap = {
      '< 35 000 €': 35000,
      '35 000 - 100 000 €': 100000,
      '> 100 000 €': 200000,
    }
    const annualIncome = incomeAnswer in incomeAnswerMap ? incomeAnswerMap[incomeAnswer] : 0;

    const annualCommitmentsAnswer = this._quizSvc.getCachedAnswer(QuizId.AMLPrivate1, 2).answer as string;
    // translate answer to number
    const monthlyCommitmentsAnswerMap = {
      '< 20 000 €': 0,
      '20 000 - 50 000 €': 20000,
      '> 50 000 €': 50000,
    }
    const annualCommitments = annualCommitmentsAnswer in monthlyCommitmentsAnswerMap ? monthlyCommitmentsAnswerMap[annualCommitmentsAnswer] : 0;
    
    const investmentSize = +this._quizSvc.getCachedAnswer(QuizId.AMLPrivate1, 12).answer;

    // perform calculations
    const workYearsLeft = age > 65 ? 0 : 65 - age; // if age > 65 workYearsLeft is 0
    const annualSurplus = annualIncome - annualCommitments;
    const futureSavings = workYearsLeft * annualSurplus;
    const futureWealth = netAssetValue + futureSavings;

    // if future wealth is < 0 return 1 (max unsustainable) to avoid display issues
    if (futureWealth < 0 ) {
      return 1;      
    }
    
    const investmentRatio = investmentSize / futureWealth;
    return investmentRatio;
  }

}
