import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SpinnerService } from '@chevtek/angular-spinners';
import * as Highcharts from 'highcharts';
import { debounce } from 'lodash-decorators';
import * as moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';

import { AccountDataService } from '../../account-data/account-data.service';
import { buildReturnChartParams, getOffsetPeriodStartDate, TZ } from '../utils';
import { LoggingService } from '../../utils/logging/logging.service';
import { AccountTradingLevel } from 'app/api/webservice.service';
import { sigFigs } from '../../shared/utils';


enum TimePeriods {
  Today = 'Today',
  Daily = 'Last day',
  MTD = 'Monthly',
  YTD = 'YTD',
  ThreeMonths = '3 months',
  SixMonths = '6 months',
  OneYear = '1 year',
  All = 'All'
}

enum LongTimePeriods {
  ThreeMonths = TimePeriods.ThreeMonths,
  SixMonths = TimePeriods.SixMonths,
  OneYear = TimePeriods.OneYear,
  All = TimePeriods.All
}

enum RiskMeasures {
  Delta = 'Delta',
  Gamma = 'Reactivity',
  Volatility = 'Volatility',
  VaR = 'VaR',
  Margin = 'Margin',
}

enum CommonRiskMeasures {
  Volatility = RiskMeasures.Volatility,
  VaR = RiskMeasures.VaR,
  Margin = RiskMeasures.Margin,
}

function dateAndValToNums(date: string, val: number): [number, number] {
  const timestamp = moment.tz(date, TZ).valueOf();
  return [timestamp, val];
}

@Component({
  selector: 'app-account-ts-risk',
  templateUrl: './account-ts-risk.component.html',
  styleUrls: ['./account-ts-risk.component.scss']
})
export class AccountTsRiskComponent implements OnInit, OnChanges {
  @Input() userId: number;
  @Input() accountId: number | string;
  @Input() selectedDate: Date;

  CommonRiskMeasures = CommonRiskMeasures;

  cancelLoadSubj: Subject<void>;
  chartOptions: Highcharts.Options = null;
  Highcharts = Highcharts;
  initialPeriod = 0;
  initialType = 0;
  selectedPeriod = LongTimePeriods.ThreeMonths;
  selectedType = CommonRiskMeasures.Volatility;
  readonly spinnerId = 'account-ts-risk';
  tradingLevel: AccountTradingLevel;
  tsDataEmpty = false;
  tsMeasureTypes = [CommonRiskMeasures.Volatility, CommonRiskMeasures.VaR, CommonRiskMeasures.Margin, ];
  tsMeasureTypesDisabled = [];
  tsPeriods = [LongTimePeriods.ThreeMonths, LongTimePeriods.SixMonths, LongTimePeriods.OneYear, LongTimePeriods.All];
  updateChart = false; // set to true to make highcharts-angular update chart

  private _chartObj: Highcharts.Chart;

  constructor(
    private acctDataSvc: AccountDataService,
    private logger: LoggingService,
    private spinnerService: SpinnerService,
  ) {
    this.cancelLoadSubj = new Subject<void>();
    this.initialPeriod = this.tsPeriods.indexOf(this.selectedPeriod);
    this.initialType = this.tsMeasureTypes.indexOf(this.selectedType);
    this.acctDataSvc.tradingLevel$.pipe(
      // distinctUntilChanged((x, y) => x.AccountId === y.AccountId && x.Currency === y.Currency && x.Date === y.Date && x.TL === y.TL),
    ).subscribe(
      tl => {
        if (tl != null && tl.AccountId === this.accountId) {
          this.tradingLevel = tl;
        }
      }
    );
  }

  getChartSeries$(): Observable<[number, number][]> {
    const startDate = getOffsetPeriodStartDate(this.selectedDate, this.selectedPeriod.toString());
    switch (this.selectedType) {
      case CommonRiskMeasures.Volatility:
        return this.acctDataSvc.getExAnteVolatilityHistorical(this.userId, this.accountId, startDate, this.selectedDate).pipe(
          map(volPoints => volPoints.map(vp => dateAndValToNums(vp.Date, vp.Volatility * 100))),
        );
        break;
      case CommonRiskMeasures.VaR:
          return this.acctDataSvc.getValueAtRiskHistorical(this.userId, this.accountId, startDate, this.selectedDate).pipe(
            map(varPoints => varPoints.map(vp => dateAndValToNums(vp.Date, vp.VaR * 100))),
          );
          break;
      case CommonRiskMeasures.Margin:
        return this.acctDataSvc.getMarginRequirements(this.userId, this.accountId, startDate, this.selectedDate).pipe(
          map(margReqs => margReqs.map(mr => dateAndValToNums(mr.Date, mr.MarginRequirementToTradingLevel * 100))),
        );
        break;
    }
  }

  getTradingLevelFactors(): [string, number] {
    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this.tradingLevel != null) {
      if (this.tradingLevel.TL > 1e6) {
        scalingFactor = 1 / 1e8;
        magnitudeSymbol = 'M';
      } else if (this.tradingLevel.TL > 1e3) {
        scalingFactor = 1 / 1e5;
        magnitudeSymbol = 'K';
      } else {
        scalingFactor = 100;
        magnitudeSymbol = '';
      }
    }
    return [magnitudeSymbol, scalingFactor];
  }

  @debounce(200, {leading: true})
  updateChartOptions() {
    this.spinnerService.show(this.spinnerId);
    this.cancelLoadSubj.next();
    if (this.userId != null && this.accountId != null && this.selectedDate != null) {
      if (this._chartObj != null) {
        this._chartObj.showLoading('Loading...');
      }
      this.getChartSeries$().pipe(
        takeUntil(this.cancelLoadSubj),
      ).subscribe(
        series => {
          this.tsDataEmpty = (series == null || series.length === 0);
          if (this.tsDataEmpty) {
            return;
          }

          series.sort((a, b) => a[0] - b[0]);

          let magnitudeSymbol: string;
          let scalingFactor = 1;
          if (this.showTradingLevelBasedFigures()) {
            [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
            for (const point of series) {
              point[1] = point[1] * scalingFactor * this.tradingLevel.TL;
            }
          }

          // build chart options
          const seriesOpts = {
            data: series,
            name: this.selectedType.toString(),
          } as Highcharts.SeriesLineOptions;

          if (magnitudeSymbol !== undefined) {
            seriesOpts['tooltip'] = {
              pointFormatter: function() {
                return `
                <span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>€${sigFigs(this.y, 2)}${magnitudeSymbol}</b><br/>
                `;
              },
              valuePrefix: '€',
              valueSuffix: magnitudeSymbol,
            } as Highcharts.TooltipOptions;
          }

          let min = series.reduce((acc, point) => Math.min(acc, point[1]), series[0][1]);
          let max = series.reduce((acc, point) => Math.max(acc, point[1]), series[0][1]);
          const diff = max - min;
          min -= diff / 2;
          max += diff / 2;

          const newOptions = buildReturnChartParams(this.selectedPeriod.toString(), [ seriesOpts ], min, max);
          newOptions.title = { text: null };
          (<Highcharts.AxisOptions>newOptions.yAxis).title = { text: this.selectedType.toString() };
          newOptions.legend.enabled = false;
          newOptions.series = [seriesOpts];
          if (this.selectedType === CommonRiskMeasures.VaR) {
            (seriesOpts as unknown as Highcharts.SeriesAreaOptions).type = 'area';
            (<Highcharts.AxisOptions>newOptions.yAxis).max = 0; // always zero for VaR;
            (<Highcharts.PlotOptions>newOptions.plotOptions).area = { lineWidth: 0, marker: { enabled: false }, };
            // use second (yellow) color
            seriesOpts.color = newOptions.colors[1];
          } else {
            // use default color for line chart
            seriesOpts.color = undefined;
            (seriesOpts as unknown as Highcharts.SeriesLineOptions).type = 'line';
          }
          if (magnitudeSymbol !== undefined) {
            (<Highcharts.AxisOptions>newOptions.yAxis).labels.formatter = function () {
              return `€${sigFigs(this.value as number, 2)}${magnitudeSymbol}`;
            };
          }

          this.chartOptions = newOptions;
          this.updateChart = true;
        },
        (err) => this.logger.error(err),
        () => {
          if (this._chartObj != null) {
            this._chartObj.hideLoading();
          }
          this.spinnerService.hide(this.spinnerId);
        }
      );
    } else {
      this.spinnerService.hide(this.spinnerId);
    }
  }

  ngOnChanges() {
    this.updateChartOptions();
  }

  ngOnInit() {
    this.updateChartOptions();
  }

  setChartObj(obj: Highcharts.Chart) {
    this._chartObj = obj;
  }

  selectTsPeriod(period: LongTimePeriods) {
    if (this.tsPeriods.indexOf(period) >= 0) {
      this.selectedPeriod = period;
      this.updateChartOptions();
    }
  }

  selectTsType(type: CommonRiskMeasures) {
    if (this.tsMeasureTypes.indexOf(type) >= 0) {
      this.selectedType = type;
      this.updateChartOptions();
    }
  }

  showTradingLevelBasedFigures(): boolean {
    return this.acctDataSvc.showTradingLevelBasedFigures(this.userId, this.accountId, this.tradingLevel);
  }
}
