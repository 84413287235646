import { Component, Input, OnChanges, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { debounce } from 'lodash-decorators';
import { Observable, Subject } from 'rxjs';
import { checkboxLegendOptions } from '../../../account-overview/utils/charts';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-ep-linechart',
  templateUrl: './ep-linechart.component.html',
  styleUrls: ['./ep-linechart.component.css']
})
export class EpLinechartComponent implements OnChanges, OnDestroy, OnInit {
  @Input() set isLoading(val: boolean) {
    if (val) {
      if (this.chart != null) {
        this.chart.showLoading('Loading...');
      }
    } else {
      if (this.chart != null) {
        this.chart.hideLoading();
      }
    }
  }

  @Input() set options(inOptions: Highcharts.Options) {
    const newOptions = {} as Highcharts.Options;
    Object.assign(newOptions, this._options);
    Object.assign(newOptions, inOptions);
    if ((newOptions.series == null || newOptions.series.length === 0) && this._series != null) {
      newOptions.series = this.series;
    }
    this._options = newOptions as Highcharts.Options;
    this.updateFlag = true;
    this._changeDetRef.markForCheck();
  }

  @Input() set series(nSeries: Array<Highcharts.SeriesLineOptions>) {
    if (nSeries == null) {
      this._series = [];
    } else {
      this._series = nSeries;
    }
    this._options.series = this._series;
    if (Array.isArray(nSeries) && nSeries.length > 0) {
      this.updateFlag = true;
    }
  }

  @Input() chartHeight = '400px';

  @Input() enableZoom$: Observable<boolean>;

  @Input() Highcharts = Highcharts;

  @Output() chartObject = new EventEmitter<Highcharts.Chart>();

  chart: Highcharts.Chart;
  updateFlag = true;

  private _destroy = new Subject<void>();
  private _options: Highcharts.Options;
  private _series: Array<Highcharts.SeriesLineOptions>;

  constructor(
    private _changeDetRef: ChangeDetectorRef,
  ) {
    this._options = {
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      colors: ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'],
      series: [],
    };
  }

  ngOnDestroy() {
    if (this.chart != null) {
      try {
        this.chart.destroy();
      } catch {
        // nothing
      }
      this.chart = null;
    }
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
    if (this.enableZoom$ != null) {
      this.enableZoom$.pipe(
        takeUntil(this._destroy),
      ).subscribe(
        (zoom) => {
          if (this.chart != null) {
            // this.chart.xAxis[0]['zoomEnabled'] = zoom;
          }
        }
      );
    }
  }

  ngOnChanges(prop: any) {
    setTimeout(() => {
      this.updateFlag = true;
    });
  }

  resetZoom() {
    if (this.chart != null) {
      this.chart.zoomOut();
    }
  }

  get options() {
    return this._options;
  }

  get series() {
    return this._series;
  }

  setChartObject = (chartObj: Highcharts.Chart) => {
    this.chart = chartObj;
    this.chartObject.emit(chartObj);
  }
}
