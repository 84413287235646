<button 
  type="button"
  [id]="collapseButtonId()"
  class="collapse-button"
  (click)="toggleCollapsed($event)"
  [attr.title]="collapsed ? 'Expand section' : 'Minimize section'">
  <i class="fa" [ngClass]="{'fa-plus': collapsed, 'fa-minus': !collapsed}"></i>
</button>
<h2 class="subtitle" *ngIf="subtitle"><label [attr.for]="collapseButtonId()">{{subtitle}}</label></h2>
<ng-content select=".subtitle"></ng-content>
<div class="section-content" [ngClass]="{'collapsed': collapsed}">
  <ng-content></ng-content>
</div>