import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { AccountDataService } from '../../account-data/account-data.service';
import { debounce } from 'lodash-decorators';
import { AccountTradingLevel } from '../../api/webservice.service';
import { LoggingService } from '@app/utils/logging/logging.service';

@Component({
  selector: 'app-account-trading-level',
  templateUrl: './account-trading-level.component.html',
  styleUrls: ['./account-trading-level.component.css']
})
export class AccountTradingLevelComponent implements OnInit {
  @Input() set userId(val: number) {
    this.placeholder = true;
    this.tradingLevel = null;
    this._userId = val;
    this.getAccountTradingLevel(this.userId, this.accountId, this.date);
  }
  get userId() { return this._userId; }
  @Input() set accountId(val: string) {
    this.placeholder = true;
    this.tradingLevel = null;
    this._accountId = val;
    this.getAccountTradingLevel(this.userId, this.accountId, this.date);
  }
  get accountId() { return this._accountId; }
  @Input() set date(val: string) {
    this.placeholder = true;
    this.tradingLevel = null;
    this._date = val;
    this.getAccountTradingLevel(this.userId, this.accountId, this.date);
  }
  get date() { return this._date; }

  @HostBinding('class.placeholder') placeholder = true;

  tradingLevel: AccountTradingLevel = null;
  private _accountId: string;
  private _date: string;
  private _userId: number;

  constructor(
    private _acctSvc: AccountDataService,
    private _logger: LoggingService,
  ) {

  }

  currencySymbol(code: string) {
    switch (code) {
      case 'EUR':
      case 'USD':
        return 'symbol';
      default:
        return 'code';
    }
  }

  ngOnInit() {
    this.getAccountTradingLevel(this.userId, this.accountId, this.date);
  }

  @debounce(1000)
  getAccountTradingLevel(userId, accountId, date) {
    if (date == null) {
      this._logger.debug('getAccountTradingLEvel date is null');
      return;
    }
    this._acctSvc.getAccountTradingLevel(this.userId, this.accountId, this.date).subscribe(
      (tl: AccountTradingLevel) => { this.tradingLevel = tl; this.placeholder = tl == null; },
      err => this.tradingLevel = null
    );
  }
}
