<div class="circular" [ngStyle]="{ width: width, height: width }">
    <div class="outer" [ngStyle]="{
        width: 'calc(' + width + ' + 6px)',
        height:  'calc(' + width + ' + 6px)',
        'background-color': color,
        'margin-left': 'calc(' + width + ' * -0.5 - 3px)',
        'margin-top': 'calc(' + width + ' * -0.5 - 3px)'
     }"></div>
    
    <div class="circle">
        <div class="bar left" [ngStyle]="{
            'background-color': color,
            clip: 'rect(0px, calc(' + width + ' / 1), calc(' + width + ' / 1), calc(' + width + ' / 2) )'
        }">
            <div class="progress" [ngStyle]="{
                'background-color': '#422a1e',
                clip: 'rect(0px, calc(' + width + ' / 2), calc(' + width + ' / 1), 0px)',
                transform: value < 0.5 ? 'rotate(' + (-0.5 + value) + 'turn)' : 'rotate(0)'
            }"></div>
        </div>
        <div class="bar right" [ngStyle]="{
            'background-color': color,
            clip: 'rect(0px, calc(' + width + ' / 1), calc(' + width + ' / 1), calc(' + width + ' / 2))'
        }">
            <div class="progress" [ngStyle]="{
                'background-color': '#422a1e',
                clip: 'rect(0px, calc(' + width + ' / 2), calc(' + width + ' / 1), 0px)',
                transform: value > 0.5 ? 'rotate(' + (value) + 'turn)' : null
            }"></div>
        </div>
    </div>
</div>