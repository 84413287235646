import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import * as Highcharts from 'highcharts';

import { int } from '../../api/webservice.service';
import { AccountDataService } from '../../account-data/account-data.service';
import { beginningOfTime } from 'app/account-overview/utils';


@Component({
  selector: 'app-asset-class-signal-strength',
  templateUrl: './asset-class-signal-strength.component.html',
  styleUrls: ['./asset-class-signal-strength.component.css']
})
export class AssetClassSignalStrengthComponent implements OnInit, AfterViewInit {
  @Input() accountId: string;

  @Input() set defaultPeriod(value: string) {
    const index = this.availablePeriods.indexOf(value);
    if (index >= 0) {
      if (this.selectedPeriod == null) {
        this.selectedPeriod = value;
      }
      this._defaultPeriod = value;
      this.initialPeriodTab = index;
    } else {
      this.initialPeriodTab = this.availablePeriods.indexOf('3 months');
    }
  }

  get defaultPeriod() {
    return this._defaultPeriod;
  }

  @Input() set selectedDate(value: Date) {
    if (value == null) {
      return;
    }
    const selDate = moment.tz(value, this.tz);
    this.initPeriodMap(selDate);
    if (this) {
      this._selectedDate = selDate;
    }
  }
  @Input() userId: int;

  availablePeriods = ['MTD', 'YTD', '3 months', '6 months', '1 year', 'All'];
  chartOptions = <Highcharts.Options>{
    plotOptions: {
      line: {
        marker: { enabled: false },
        tooltip: {
          dateTimeLabelFormats: {
            second: '%a, %e %b %Y',
            minute: '%a, %e %b %Y',
            hour: '%a, %e %b %Y',
            day: '%a, %e %b %Y',
            week: '%a, %e %b %Y',
            month: '%a, %e %b %Y',
            year: '%a, %e %b %Y',
          },
          shared: true,
          split: false,
          valueDecimals: 1,
          valueSuffix: ' %',
          xDateFormat: '%a, %e %b %Y',
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        format: '{value:%d-%b-%Y}',
        rotation: -45,
      },
      minTickInterval: 1000 * (60 * 60 * 24), // 1 day
      type: 'datetime',
    },
    yAxis: {
      labels: {
        format: '{value:.0f} %',
      },
      min: -50,
      max: 100,
      title: {
        text: null,
      },
    },
  };
  _defaultPeriod: string;
  initialPeriodTab: number;
  periodStartMap: Map<string, moment.Moment>;
  selectedPeriod = this.defaultPeriod;
  signalSeries$: Observable<Object[]>;
  tz = 'Europe/Helsinki';

  private _selectedDate: moment.Moment;

  constructor(private acctDataSvc: AccountDataService) {

  }

  initPeriodMap(end: moment.Moment) {
    const MTD = moment(end).startOf('month');
    const YTD = moment(end).startOf('year');
    const three = moment(end).subtract(3, 'months');
    const six = moment(end).subtract(6, 'months');
    const year = moment(end).subtract(1, 'year');
    const all = beginningOfTime;
    this.periodStartMap = new Map<string, moment.Moment>([
      ['MTD', MTD],
      ['YTD', YTD],
      ['3 months', three],
      ['6 months', six],
      ['1 year', year],
      ['All', all],
    ]);
  }

  get selectedDate(): Date {
    return this._selectedDate.tz(this.tz).toDate();
  }

  get startTime(): moment.Moment {
    if (this.periodStartMap != null && this.selectedPeriod != null) {
      return this.periodStartMap.get(this.selectedPeriod);
    }
    return null;
  }

  get endTime(): moment.Moment {
    return this._selectedDate;
  }

  loadSignalData() {
    if (this.startTime != null && this.endTime != null) {
      this.signalSeries$  = this.acctDataSvc.getAssetClassesSignalStrengthSeries(this.userId, this.accountId, this.startTime, this.endTime);
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.initPeriodMap(this.endTime);
    this.loadSignalData();
  }

  selectPeriod(event: { selected: string }) {
    if (this.availablePeriods.indexOf(event.selected) < 0) {
      return false;
    }
    this.selectedPeriod = event.selected;
    this.loadSignalData();
    return true;
  }

}
