import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTableComponent } from './data-table/data-table.component';
import { EppBasicDropdownComponent } from './epp-basic-dropdown/epp-basic-dropdown.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { HelpPopupComponent } from './help-popup/help-popup.component';
import { NavbarButtonComponent } from './navbar-button/navbar-button.component';
import { HelpPopupService } from './help-popup/help-popup.service';
import { GetDateTimePipe } from './pipes/get-date-time.pipe';
import { IsSubstringPipe } from './pipes/is-substring.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { SignedPercentPipe } from './pipes/signed-percent.pipe';
import { SparklineBarComponent } from './sparkline-bar/sparkline-bar.component';
import { UiCardComponent } from './ui-components/ui-card/ui-card.component';
import { UiCollapsibleSectionComponent } from './ui-components/ui-collapsible-section/ui-collapsible-section.component';
import { EpLinechartComponent } from './charts/ep-linechart/ep-linechart.component';
import { EpPiechartComponent } from './charts/ep-piechart/ep-piechart.component';
import { UiConfirmDialogComponent } from './ui-confirm-dialog/ui-confirm-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { HighchartsChartModule } from 'highcharts-angular';
import { StripThousandsSeparatorPipe } from './pipes/strip-thousands-separator';
import { UiLoadingIndicatorInlineComponent } from './ui-loading-indicator-inline/ui-loading-indicator-inline.component';
import { LoggerTestComponent } from './logger-test/logger-test.component';
import { UiSnackbarComponent } from './ui-snackbar/ui-snackbar.component';
import { UiSlideshowComponent } from './ui-components/ui-slideshow/ui-slideshow.component';
import { ColorLightenPipe } from './pipes/color-lighten.pipe';
import { PositivePipe } from './pipes/positive.pipe';
import { UiPercentCircleComponent } from './ui-percent-circle/ui-percent-circle.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    OverlayModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FileSizePipe,
    IsSubstringPipe,
    GetDateTimePipe,
    SignedPercentPipe,
    NoCommaPipe,
    DataTableComponent,
    EpLinechartComponent,
    EpPiechartComponent,
    EppBasicDropdownComponent,
    HelpPopupComponent,
    NavbarButtonComponent,
    SparklineBarComponent,
    StripThousandsSeparatorPipe,
    UiCardComponent,
    UiCollapsibleSectionComponent,
    UiConfirmDialogComponent,
    UiLoadingIndicatorInlineComponent,
    LoggerTestComponent,
    UiSnackbarComponent,
    UiSlideshowComponent,
    ColorLightenPipe,
    PositivePipe,
    UiPercentCircleComponent,
  ],
  exports: [
    ColorLightenPipe,
    DataTableComponent,
    EpLinechartComponent,
    EpPiechartComponent,
    EppBasicDropdownComponent,
    FileSizePipe,
    HelpPopupComponent,
    IsSubstringPipe,
    GetDateTimePipe,
    LoggerTestComponent,
    SignedPercentPipe,
    NavbarButtonComponent,
    NoCommaPipe,
    PositivePipe,
    SparklineBarComponent,
    StripThousandsSeparatorPipe,
    UiCardComponent,
    UiCollapsibleSectionComponent,
    UiConfirmDialogComponent,
    UiLoadingIndicatorInlineComponent,
    UiSnackbarComponent,
    UiPercentCircleComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ HelpPopupService, SignedPercentPipe ]
    };
  }
}
