import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from './logging.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  private logger: LoggingService;
  constructor(private injector: Injector) {
    super();
    this.logger = this.injector.get(LoggingService);
  }

  handleError(error: Error | string) {
    let message: string;
    if (!environment.production) {
      throw error;
    }
    if (error == null) {
      message = '(no message)';
      this.logger.error(`Unhandled exception: ${message}`);
      return;
    }
    try {
      if (typeof error === 'string') {
        message = error;
        this.logger.error(`Unhandled exception: ${message}`);
      } else {
        if ('message' in error && error['message'] != null) {
          message = error['message'];
        } else {
          message = error.toString() || '(no message)';
        }
      }

      this.logger.error(`Unhandled exception: ${message}`, error);
    } catch {
      console.error('Could not log', error);
    }
  }
}
