import { dateTime } from '../api/webservice.service';
import { cmpAcctIdxIds, canonicalAccountOrder } from './acct-idx';
import naturalSort from '../natural-sort';

export class AccountPerformance {
  accountId: string;
  accountName: string;
  performance: number;
  time: dateTime;
  order?: number;

  constructor(accountId: string, accountName: string, performance: number, time?: dateTime, order?: number) {
    this.accountId = accountId;
    this.accountName = accountName;
    this.performance = performance;
    if (time) {
        this.time = time;
    } else {
        this.time = null;
    }
    this.order = order;
  }
}

export function cmpAcctPerfs(a: AccountPerformance, b: AccountPerformance): number {
  let result = 0;

  // sort by order if available
  if (a.order != null || b.order != null) {
    if (a.order != null && b.order == null) {
        result = 1;
    }
    if (a.order == null && b.order != null) {
        result = -1;
    }
    if (a.order != null && b.order != null) {
        result = a.order - b.order;
    }
    return result;
  }

  // Assumes account ID always non-numeric and index ID always numeric
  const aIsIndex = !isNaN(Number(a.accountId));
  const bIsIndex = !isNaN(Number(b.accountId));

  if ((a.accountId in canonicalAccountOrder || b.accountId in canonicalAccountOrder)) {
      result = cmpAcctIdxIds(a.accountId, b.accountId);
  } else {
    if (aIsIndex !== bIsIndex) {
        // accounts before indices
        result = bIsIndex ? -1 : 1;
    } else {
        result = naturalSort(a.accountName, b.accountName);
    }
  }
  return result;
}
