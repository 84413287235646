<div class="">
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title title">Documents</h1>
    </div>
    <div class="card-content">
      <spinner name="loadDocuments">
        <div class="spinner-shield">
          <div class="spinner-badge">
            <div class="spinner-indicator"></div>
            <div class="spinner-message"><p>Loading documents...</p></div>
          </div>
        </div>
      </spinner>
      <spinner name="fetchDocument">
        <div class="spinner-shield">
          <div class="spinner-badge">
            <div class="spinner-indicator"></div>
            <div class="spinner-message"><p>Downloading document...</p></div>
          </div>
        </div>
      </spinner>
      <navbar-button
        #docListCategorySelector
        [tabs]="docListTabs"
        [initial]="0"
        (selectionChanged)="changeDocListFilter($event)"
        class="flex-1">
      </navbar-button>
      <data-table 
        *ngIf="availableDocuments?.length > 0"
        [items]="filteredDocuments" 
        [columns]="docListColumns"
        [options]="docListOptions"
        [selected]="selectedDocs" 
        [maxItems]="1"
        (selectionChanged)="selectDocument($event)">
      </data-table>

      <div *ngIf="noDocumentsAvailable">
        <p>No documents available at this time.</p>
      </div>
  </div>
</div>