import { PortfolioSet } from "@app/sandbox/models/portfolio-set";
import { SandboxErrorCode } from "./sandbox-error-code.enum";
import { SandboxPortfolioSetError } from "./sandbox-portfolio-set-error";

export class SandboxPortfolioSetNameError extends SandboxPortfolioSetError {
    code = SandboxErrorCode.PortfolioSetName;
    set_name: string;
    existing_set: PortfolioSet;

    constructor(
        message='Portfolio set name invalid',
        code=SandboxErrorCode.PortfolioSetName,
        set_name: string= null,
        existing_set: PortfolioSet = null
    ) {
        super(message, code);
        if (set_name) {
            this.set_name = set_name;
        }
        if (existing_set) {
            this.existing_set = existing_set;
        }
    }

    toString() {
        if (!this.set_name) {
            return `${this.message}: blank name`
        }
        if (this.existing_set) {
            return `${this.message}: portfolio set with same name already exisits`;
        }
    }

    toHtml() {
        if (!this.set_name) {
            return `<strong>${this.message}:</strong> blank name`;
        }
        if (this.existing_set) {
            return `<strong>${this.message}</strong>: portfolio set with same name already exisits`;
        }
    }
}
