import { Account, Index } from '../api/webservice.service';
import naturalSort from '../natural-sort';

// account ids => ordering
export const canonicalAccountOrder = {
    'TIM':    1,
    'ESIF1':  2,
    'FFI':    3,
    'DYNF':   4,
    'AT3X':   5,
    'RESOR':  6,
    'ERRY':   7,
    'ALPHA':  8,
    'ALAND':  9,
    'ALAN2': 10,
    'GMFSE': 11,
    127:    12,  // EP Risk Premia Portfolio I
    128:    13,  // EP Risk Premia Portfolio II
    115:    14,  // EP Balanced Futures
    163:    15,  // EP 3 month Straddle Replication Index
    113:    16,  // EP Short Term Reversal
    105:    17,  // EP Currency Carry
    106:    18,  // EP Government Bond Carry
    107:    19,  // EP Global Equity Volatility Target 15
    112:    20,  // EP Commodity Term Structure Alpha
}

// sort accounts according to canonicalAccountorder. If not in table, sort last.
export const cmpAcctIdxIds = (a: number | string, b: number | string): number => {
    if (a in canonicalAccountOrder && b in canonicalAccountOrder) {
        return canonicalAccountOrder[a] - canonicalAccountOrder[b];
    }
    if (a in canonicalAccountOrder) {
        return -1;
    }
    if (b in canonicalAccountOrder) {
        return 1;
    }
    return naturalSort(a, b);
}

/* Sorts AcctIdxs by the following algorithm
  1. canonicalOrder of Id
  2. Type: account before index
  3. Name: alphabetical
*/
export const cmpAcctIdxs = (a: AcctIdx, b: AcctIdx): number => {
    // sort by order if available
    let result = 0;
    if (a.Order != null || b.Order != null) {
        if (a.Order != null && b.Order == null) {
            result = 1;
        }
        if (a.Order == null && b.Order != null) {
            result = -1;
        }
        if (a.Order != null && b.Order != null) {
            result = a.Order - b.Order;
        }
        return result;
    }
    const canonicalOrder = cmpAcctIdxIds(a.Id, b.Id);
    if (a.Id in canonicalAccountOrder && b.Id in canonicalAccountOrder) {
        return cmpAcctIdxIds(a.Id, b.Id);
    } else {
        if (a.Type === b.Type) {
            return naturalSort(a.Name, b.Name);
        } else {
            // accounts before indices
            return a.Type === 'account' ? -1 : 1;
        }
    }
}


export class AcctIdx {
    public Id: string;
    public Name: string;
    public Type: string;
    public Original: Account|Index;
    public Order: number;

    constructor(account: Account);
    // tslint:disable-next-line:unified-signatures
    constructor(index: Index);
    constructor(obj: any, order?: number) {
        if (obj.AccountId) {
            this.Id = obj.AccountId;
            this.Name = obj.Name;
            this.Type = 'account';
            this.Original = obj;
        }
        if (obj.IndexId) {
            this.Id = obj.IndexId.toString();
            this.Name = obj.Name;
            this.Type = 'index';
            this.Original = obj;
        }
        this.Order = order;
    }

    toString() {
        return this.Name;
    }
}
