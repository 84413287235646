import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { NavbarButtonResetMessage } from './navbar-button-reset-message';
import { AppService } from '@app/app-service.service';

@Injectable()
export class NavbarButtonService {
  private _resetSubject = new Subject<NavbarButtonResetMessage>();
  private _floatingNavbarValueMap = new Map<string, boolean>();
  private _floatingNavbarSubjectMap = new Map<string, Subject<boolean>>();

  constructor(private _appSvc: AppService, private _zone: NgZone) {

  }

  getReset(): Observable<any> {
    return this._resetSubject.asObservable();
  }

  createFloatingNavbarSubject(id: string, wrapperElem: HTMLElement) {
    const isFloatingSubject = new BehaviorSubject<boolean>(this._floatingNavbarValueMap.get(id));
    this._appSvc.addScrollEventListener((scrollEvent: UIEvent) => {
      // console.log(this);
      if (wrapperElem != null && wrapperElem.offsetTop > 0) {
        if (!this._floatingNavbarValueMap.get(id)) {
          this._floatingNavbarValueMap.set(id, true);

          this._zone.run(() => isFloatingSubject.next(true));
        }
      } else {
        if (this._floatingNavbarValueMap.get(id)) {
          this._floatingNavbarValueMap.set(id, false);
          this._zone.run(() => isFloatingSubject.next(false));
        }
      }
    });
    return isFloatingSubject;
  }

  isNavbarFloating$(id: string, floatingElem: HTMLElement): Observable<boolean> {
    if (!this._floatingNavbarValueMap.has(id)) {
      this._floatingNavbarValueMap.set(id, false);
    }
    if (!this._floatingNavbarSubjectMap.has(id)) {
      this._floatingNavbarSubjectMap.set(id, this.createFloatingNavbarSubject(id, floatingElem));
    }
    return this._floatingNavbarSubjectMap.get(id).asObservable();
  }

  resetAll() {
    this._resetSubject.next(new NavbarButtonResetMessage('all'));
  }

  unregisterFloatingNavbars() {
    this._floatingNavbarValueMap.clear();
    for (const s of Array.from(this._floatingNavbarSubjectMap.values())) {
      s.complete();
    }
    this._floatingNavbarSubjectMap.clear();
  }
}
