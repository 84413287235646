import { Component, NgZone, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute, RoutesRecognized, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounce } from 'lodash';

import { TypeHelpers } from './lib/type-helpers';

import { AuthenticationService, User} from './auth/authentication.service';

import { AppService } from './app-service.service';
import { WindowRefService } from './window-ref.service';
import { MenuService } from './menu.service';
import { MainMenuItem } from './main-menu-item';
import { googleAnalytics } from './analytics/google-analytics-scripts';
import { UiSnackbarOptions, UiSnackbarService } from './shared/ui-snackbar/ui-snackbar.service';
import { SandboxPfConfirmDeleteComponent } from './sandbox/sandbox-pf-confirm-delete/sandbox-pf-confirm-delete.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {

  get loggedInUser$(): Observable<User> {
    return this.authenticationService.getCurrentUser();
  }
  title = 'app';

  public appVersion: string;
  public isSidebarVisible$: Observable<boolean>;
  public mainMenuItems$: Observable<MainMenuItem[]>;
  public menuMode$: Observable<'icons'|'labels'>;
  public subMenuCollapsed: Map<string, boolean>;
  public notificationOptions = {
    icons: {
      error:  '<i class="fa fa-times-circle fa-2x"></i>',
      info:    '<i class="fa fa-info-circle fa-2x"></i>',
      success: '<i class="fa fa-check fa-2x"></i>',
      warning: '<i class="fa fa-exclamation-triangle fa-2x"></i>',
    },
    showProgressBar: false,
    timeOut: 3000,
  };
  public snackbar$: Observable<UiSnackbarOptions>;

  private _subscription: Subscription;
  private _acctIdSub: Subscription;
  private eventOptions: (boolean|{capture?: boolean, passive?: boolean});
  private lastScrollPosition = 0;

  /** Update horizontal navbar visibilty. Run outside ngZone from ngOnInit() */
  private scrollNavbar = debounce((event: UIEvent) => {
    let hideIt: boolean;
    const window = this.windowRefService.nativeWindow;
    const scrollY = window.scrollY;

    if (this.lastScrollPosition > scrollY) {
      hideIt = false;
    } else if (scrollY > 50) {
      hideIt = true;
    }

    this.lastScrollPosition = scrollY;

    this.ngZone.run(() => {
      // run delayed to avoid change detection errors
      Promise.resolve(null).then(() => this.appService.isNavbarScrolledUp.next(hideIt));
    });
  }, 200);

  constructor(
    public appService: AppService,
    public authenticationService: AuthenticationService,
    private el: ElementRef,
    public menuService: MenuService,
    private ngZone: NgZone,
    private router: Router,
    private snackbarSvc: UiSnackbarService,
    private windowRefService: WindowRefService,
  ) {
    this.subMenuCollapsed = new Map<string, boolean>();
    this.router.events.subscribe(event => {
      // close sidebar when link activated
      if (event instanceof NavigationStart && this.checkSideBarVisible()) {
        this.toggleSideBar();
      }
      if (event instanceof NavigationEnd) {
        this.appService.updateTime = null;
        this.appService.updateTimeFormat = 'default';
        // Hook up Google Analytics
        googleAnalytics(event.url);
      }
      if (event instanceof RoutesRecognized) {
        // hide navbar if showNavbar flag is set on route.
        const showNavbarSetting = getShowNavbar(event.state);
        if (showNavbarSetting != null) {
          this.appService.isNavbarVisible = !!showNavbarSetting;
        }
      }
    });

    if (!TypeHelpers.hasSmoothing()) {
      // Lato looks bad without font smoothing. Fall back to Helvetica if smoothing not available.
      (<HTMLElement>this.el.nativeElement).style.fontFamily = '"Helvetica", "Arial", sans-serif';
    }

    this.snackbar$ = this.snackbarSvc.snackbar$;

    this.appVersion = appService.appVersion;
    this.isSidebarVisible$ = this.appService.isSidebarVisible$;
    this.mainMenuItems$ = this.menuService.mainMenuItems$;
    this.menuMode$ = this.appService.menuMode$;
  }

  checkSideBarVisible() {
    return this.appService.isSidebarVisible;
  }

  collapseIcon(item) {
    return {
      'fa-angle-double-down': this.subMenuCollapsed.get(item.url),
      'fa-angle-double-up': !this.subMenuCollapsed.get(item.url),
    };
  }

  isSubMenuItemHighlighted(item) {
    const subMenuItems = (item['subMenuItems'] || []);
    if (subMenuItems.length > 0) {
      if (subMenuItems.find(subItem => subItem['highlighted'])) {
        return true;
      }
    }
    return false;
  }

  itemClasses(item) {
    return {
      'navbar-highlight': item.highlighted && !this.isSubMenuItemHighlighted(item),
      'sub-items-available': item['subMenuItems'] && item.subMenuItems.length > 0,
      'sub-item-highlighted': this.isSubMenuItemHighlighted(item),
      'sub-items-collapsed': this.subMenuCollapsed.get(item.url),
    };
  }


  logout() {
    return this.authenticationService.logout();
  }

  ngOnDestroy() {
    this.windowRefService.nativeWindow.removeEventListener('scroll', this.scrollNavbar, <any>this.eventOptions);
    this._subscription.unsubscribe();
    this._acctIdSub.unsubscribe();
  }

  ngOnInit() {
    this.appService.addScrollEventListener(this.scrollNavbar);
  }

  sidebarLogout() {
    if (this.checkSideBarVisible()) {
      this.toggleSideBar();
    }
    this.logout();
  }

  toggleSideBar() {
    this.appService.toggleSidebar();
  }

  toggleSubMenuCollapsed(url: string, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    const value = this.subMenuCollapsed.has(url) ? this.subMenuCollapsed.get(url) : false;
    this.subMenuCollapsed.set(url, !value);
  }


}

function getShowNavbarChild(snap: ActivatedRouteSnapshot): boolean {
  const showNavbar = snap.data['showNavbar'] === undefined ? true : snap.data['showNavbar'];
  if (snap.children.length > 0) {
    return showNavbar && getShowNavbarChild(snap.firstChild)
  }
  return showNavbar;
}

function getShowNavbar(state: RouterStateSnapshot): boolean {
  return getShowNavbarChild(state.root.firstChild);
}

