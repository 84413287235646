<div class="" *ngIf="true">
  <div class="card">
    <div class="card-content">
      <div class="time-selector level is-hidden-mobile">
        <div class="level-right">
          <div *ngIf="asOfDate" class="level-item timestamp">
            <span *ngIf="selectedPeriod === DataPeriods.Today; else pastTimestamp">
              Data as of {{asOfDate|date:'HH:mm'}}
              <abbr title="Eastern European {{ asOfDate.isDST() ? 'Summer' : '' }} Time (Berlin + 1/London + 2)">EET</abbr>
            </span>
            <ng-template #pastTimestamp>
              <span>Data as of {{asOfDate|date:'dd-MMM-yyyy'}}</span>
            </ng-template>
            &nbsp;
            (<a (click)="changePeriod(selectedPeriod, true)">Refresh <span class="icon"> &nbsp; <i class="fa fa-refresh"></i></span></a>)
          </div>
          <div class="level-item">
            <div class="field has-addons is-pulled-right">
              <p class="control" (click)="changePeriod(DataPeriods.Today)">
                <a class="button" [ngClass]="{'is-primary is-active': selectedPeriod == DataPeriods.Today}">
                  <span class="">Today</span>
                </a>
              </p>
              <p class="control" (click)="changePeriod(DataPeriods.LastDay)">
                <a class="button" [ngClass]="{'is-primary is-active': selectedPeriod == DataPeriods.LastDay}">
                  <span class="">Previous day</span>
                </a>
              </p>
              <p class="control" (click)="changePeriod(DataPeriods.MTD)">
                <a class="button" [ngClass]="{'is-primary is-active': selectedPeriod == DataPeriods.MTD}">
                  <span class="">Month to date</span>
                </a>
              </p>
              <p class="control" (click)="changePeriod(DataPeriods.YTD)">
                <a class="button" [ngClass]="{'is-primary is-active': selectedPeriod == DataPeriods.YTD}">
                  <span class="">Year to date</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
  <!-- My dropdown mobile-->
  <div class="time-selector dropdown is-hidden-tablet" [ngClass]="{'is-active': isPeriodDrawdownVisible}">
      <div class="dropdown-trigger">
        <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu" (click)="isPeriodDrawdownVisible=true">
          <span *ngIf="selectedPeriod == DataPeriods.Today">Today</span>
          <span *ngIf="selectedPeriod == DataPeriods.LastDay">Last day</span>
          <span *ngIf="selectedPeriod == DataPeriods.MTD">Month to date</span>
          <span *ngIf="selectedPeriod == DataPeriods.YTD">Year to date</span>
          <span class="icon is-small">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a class="dropdown-item" (click)="changePeriod(DataPeriods.Today)" >
            Today
          </a>
          <a class="dropdown-item" (click)="changePeriod(DataPeriods.LastDay)">
            Previous day
          </a>
          <a class="dropdown-item" (click)="changePeriod(DataPeriods.MTD)">
            Month to date
            </a>
          <a class="dropdown-item" (click)="changePeriod(DataPeriods.YTD)">
            Year to date
            </a>
          </div>
      </div>
    </div>
<!-- My dropdown mobile-->

    <div class="pf-table" [ngStyle]="{'min-height': (accountPerformances?.length === 0 ? '20em' : 'unset')}">
      <spinner name="dashboard-portfolios">
          <div class="spinner-shield"><div class="spinner-indicator" style="top: min( calc(50vh - 140px) 50% )"><span>Loading...</span></div></div>
      </spinner>
      <div *ngIf="accountPerformances != null && accountPerformances.length === 0 && allAcctPerfs != null && allAcctPerfs.length > 0 && showHiddenAcctsHelp"
        class="settings-hint message is-info">
        <i class="fa fa-info-circle"></i> There are no portfolios visible now, but some can be enabled in the <a routerLink="/portal/settings/landing-page">home page settings</a>.</div>
        <data-table #portfolioListTable
          class="is-marginless"
          [columns]="accountTableColumns"
          [items]="accountPerformances"
          [maxItems]="1"
          [options]="accountTableOptions"
          [selected]="accountSelected"
          (columnWidths)="setMarketTableColumnWidths($event)"
          (selectionChanged)="openPortfolio($event)">
        </data-table>
        <div *ngIf="benchmarkPerformance != null" class="benchmark-table">          
          <table class="table is-fullwidth no-highlight">
            <tbody>
              <tr>
                <td class="market-name">{{ benchmarkPerformance.name }}</td>
                <td class="performance">
                  <div class="percentbar">
                    <div class="percent">{{ benchmarkPerformance.performance | percent:'1.2-2' }}</div>
                    <div class="bar">
                        <sparkline-bar
                          scale="{{performanceScale}}"
                          class="is-pulled-right"
                          [width]="percentbarWidth"
                          [height]="24"
                          [value]="benchmarkPerformance.performance"
                        ></sparkline-bar>
                    </div>
                  </div>
                </td>
    
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <div>&nbsp;</div>

      <div *ngIf="KeyMarketPerformances != null && KeyMarketPerformances.length > 0" class="km-table">          
      <table class="table is-fullwidth no-highlight">
        <thead>
          <tr>
            <th class="market-name has-vertical-separator">Markets</th>
            <th *ngIf="(layoutMode$|async) === 'wide'" class="ticker-symbol has-vertical-separator">Bloomberg Code</th>
            <th class="performance" colspan="2">Return since previous close</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let market of KeyMarketPerformances">
            <td class="market-name">{{ market.Instrument.ProductLongName }}</td>
            <td class="ticker-symbol" *ngIf="(layoutMode$|async) === 'wide'">{{ market.Instrument.BloombergTicker }}</td>
            <td class="performance">
              <div class="percentbar">
                <div class="percent">{{ market.IntradayPerformance.Performance | percent:'1.2-2' }}</div>
                <div class="bar">
                    <sparkline-bar
                      scale="{{performanceScale}}"
                      class="is-pulled-right"
                      [width]="percentbarWidth"
                      [height]="24"
                      [value]="market.IntradayPerformance.Performance"
                    ></sparkline-bar>
                </div>
              </div>
            </td>

          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>

</div>
