import { Component, OnInit, Input, TemplateRef, EventEmitter, Output, ElementRef, HostBinding } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { state, transition, style, trigger, group, animate } from '@angular/animations';

@Component({
  selector: 'app-ui-card',
  templateUrl: './ui-card.component.html',
  styleUrls: ['./ui-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({height: '*', opacity: 0})),
      transition(':leave', [
          style({height: '*', opacity: 1}),

          group([
              animate(300, style({height: 0})),
              animate('200ms ease-in-out', style({'opacity': '0'}))
          ])

      ]),
      transition(':enter', [
          style({height: '0', opacity: 0}),

          group([
              animate(300, style({height: '*'})),
              animate('400ms ease-in-out', style({'opacity': '1'}))
          ])

      ])
    ])
  ],
})
export class UiCardComponent implements OnInit {
  @Input() title: string = null;
  @Input() set minimized(val: boolean | Observable<boolean>) {
    if (val instanceof Observable) {
      if (this._minSub != null) {
        this._minSub.unsubscribe();
        this._minSub = null;
      }
      this._minSub = val.subscribe(
        min => {
          this._minimized$.next(min);
          this.minimizedClass = !!min;
        }
      );
    } else {
      this._minimized$.next(val);
      this.minimizedClass = !!val;
    }
  }
  @HostBinding('class.minimized') minimizedClass = false;
  @Output() minimizeToggle = new EventEmitter<boolean>();

  public get minimized$() {
    return this._minimized$.asObservable();
  }

  private _minimized$ = new BehaviorSubject(false);
  private _minSub: Subscription;

  constructor(public element: ElementRef<HTMLElement>) {
  }

  scrollTo() {
    this.element.nativeElement.scrollIntoView();
  }

  toggleMinimizeCard(card: HTMLDivElement) {
    this._minimized$.pipe(take(1)).subscribe(
      min => {
        this.minimized = !min;
        this.minimizeToggle.emit(!min);
      }
    );
  }

  ngOnInit() {
  }

}
