import naturalSort from '../natural-sort';

/**
 * Compare two objects for sorting.
 * Special case sorting for the names of Asset Classes, specfied in the weights table.
 *
 * @param objA - an object
 * @param objB - an object to compare to objA
 * @param classAttr - the attribute on objA and objB to compare, if null compare toString() of objects
 */
export function cmpAssetClasses(objA: any, objB: any, classAttr = 'name') {
    // Inplace sort items by asset class name
    const weights = {
        'equity': 100,
        'fixed income': 80,
        'currency': 60,
        'commodity': 40,
    };

    let a: string;
    let b: string;
    if (classAttr != null && classAttr in objA && classAttr in objB) {
        a = objA[classAttr].toString().toLowerCase();
        b = objB[classAttr].toString().toLowerCase();
    } else {
        a = objA.toString().toLowerCase();
        b = objB.toString().toLowerCase();
    }
    const weightA = weights[a] || 0;
    const weightB = weights[b] || 0;
    if (weightA < weightB) {
        return 1;
    } else if (weightA > weightB) {
        return -1;
    } else {
        return naturalSort(a, b);
    }
}

export const subAssetClassOrder = [
    'European Equity',
    'North-American Equity',
    'Other Equity',
    'Equity Volatility',
    'European Fixed Income',
    'North-America Fixed Income',
    'Other Fixed Income',
    'US Foreign Exchange',
    'Other Foreign Exchange',
    'Energy',
    'Agricultural',
    'Base Metal',
    'Precious Metal',
    'Meat',
    'Commodity Index',
    'Other',
].map(s => s.toLowerCase());

export function cmpSubAssetClassNames<T = string>(a: T, b: T, options?: Object): number {
    const aIdx = subAssetClassOrder.indexOf(a.toString().toLowerCase());
    const bIdx = subAssetClassOrder.indexOf(b.toString().toLowerCase());
    if (aIdx >= 0 && bIdx >= 0) {
        return aIdx - bIdx;
    }
    if (aIdx >= 0) {
        return -1;
    }
    if (bIdx >= 0) {
        return 1;
    }
    // both names unknown
    return naturalSort(a, b);
}

export function sortSubAssetClassColumn<T extends { assetClassName: string, subAssetClassName: string }>(
  items: Array<T>,
  fieldName: string = 'subAssetClassName',
  isAscending: boolean = true,
  cmp: (a: T, b: T) => number = cmpSubAssetClassNames
) {
  items.sort((a, b) => {
    const acSort = cmpAssetClasses(a, b, 'assetClassName');
    if (acSort !== 0) {
    return acSort * (isAscending ? 1 : -1);
    }
    return cmp(a[fieldName], b[fieldName]) * (isAscending ? 1 : -1);
  });
  return items;
}
