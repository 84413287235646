import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountTsRiskComponent } from './account-ts-risk/account-ts-risk.component';
import { FundDocumentsComponent } from './fund-documents/fund-documents.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SharedModule } from '../shared/shared.module';
import { SpinnerModule } from '@chevtek/angular-spinners';
import { OverviewMenuComponent } from './overview-menu/overview-menu.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FormsModule } from '@angular/forms';
import { OverviewDatePickerComponent } from './overview-date-picker/overview-date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    AngularMyDatePickerModule,
    SharedModule,
    SpinnerModule,
  ],
  declarations: [AccountTsRiskComponent, FundDocumentsComponent, OverviewMenuComponent, OverviewDatePickerComponent, ],
  exports: [AccountTsRiskComponent, FundDocumentsComponent, OverviewMenuComponent, OverviewDatePickerComponent, ],
})
export class AccountOverviewModule { }
