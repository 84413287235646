<div *ngIf="this.useDropdown !== true; else dropdownNav" class="buttons has-addons">
    <button type="button" class="button" 
      *ngFor="let element of _tabs" 
      (click)="changeSelection(element?.value || element, $event)"
      [ngClass]="{
        'is-selected': selectedValue === (element?.value || element),
        'is-disabled': element?.disabled || isDisabled(element?.value || element)
      }"
      [attr.title]="isDisabled(element?.value || element) ? 'Data not available': ''">
      <span class="">{{ element?.label || element }}</span>
    </button>
</div>
<ng-template #dropdownNav>
  <app-epp-basic-dropdown
    [items$]="_tabs"
    [activeItem$]="selectedValue"
    (activateItem)="changeSelection($event)"
  >
  </app-epp-basic-dropdown>
</ng-template>