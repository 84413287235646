export enum BreakdownNumberDisplayType {
    Percent = 'percent',
    NAVFraction = 'nav',
}

export class ActivePortfolio {
    PortfolioId: number | string;
    Order: number;
    NumberDisplayType = BreakdownNumberDisplayType.Percent;

    constructor(portfolioId?: number|string, order?: number, numberDisplayType?: BreakdownNumberDisplayType) {
        this.PortfolioId = portfolioId;
        this.Order = order;
        if (numberDisplayType !== undefined) {
            this.NumberDisplayType = numberDisplayType;
        }
    }
}
