import { AuthenticationService } from 'app/auth/authentication.service';
import { HttpClientService } from 'app/http-client.service';
import { NgxLoggerLevel, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';
import { concat, Observable, Subject } from 'rxjs';
import { concatAll, debounceTime, map, take } from 'rxjs/operators';

class LogMessageItem {
  date: string;
  loggerMessage: NGXLogInterface;
  userId: number;
  uploaded = false;

  constructor(userId, log: NGXLogInterface) {
    this.userId = userId;
    this.loggerMessage = log;
    if (log['userId'] == null) {
      log['userId'] = userId;
    }
    if (log.timestamp) {
      this.date = log.timestamp;
    } else {
      this.date = new Date().toISOString();
    }
  }
}

interface LogMessageResponse { userId: number; timestamp: string; }

export class LoggingMonitor extends NGXLoggerMonitor {
  private _dbFactory: IDBFactory;
  private _db: IDBDatabase;
  private _memoryQueue: LogMessageItem[];
  private _pushSignal = new Subject<void>();

  private _serverLoggingUrl = '/api/logging/v1/LogMessageList/';
  private _serverLogLevel = NgxLoggerLevel.INFO;

  constructor(
    private auth: AuthenticationService,
    private httpClient: HttpClientService,
  ) {
    super();
    return;
    this._dbFactory = window['indexedDB'] || window['mozIndexedDB'] || window['webkitIndexedDB'] || window['msIndexedDB'];
    this.openMessageTable();
    this._pushSignal.pipe(
      debounceTime(10000),
    ).subscribe(() => this.pushMessages());
  }

  openMessageTable() {
    return;
    const openReq = this._dbFactory.open('epp_logging', 1);
    openReq.onerror = (event: Event) => {
      console.warn('Cannot create IDB log message queue. Using memory table instead.', openReq.error);
      this._memoryQueue = [];
    };
    openReq.onsuccess = (event: Event) => {
      this._db = openReq.result;
    };
    openReq.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = event.target as IDBRequest;
      const table = db.result.createObjectStore('log_messages', { keyPath: ['userId', 'date']});
      table.createIndex('userId', 'userId', { unique: false });
      table.createIndex('date', 'date', { unique: false });
      table.createIndex('uploaded', 'uploaded', { unique: false });
    };
  }

  onLog(log: NGXLogInterface) {
    return;
    this.auth.getCurrentUser().pipe(take(1)).subscribe(user => {
      if (this._memoryQueue != null) {
        this._memoryQueue.push(new LogMessageItem(user.userId, log));
      } else {
        setTimeout(
          () => {
            const table_rw = this._db.transaction('log_messages', 'readwrite').objectStore('log_messages');
            try {
              const msgObj = JSON.parse(JSON.stringify(new LogMessageItem(user.userId, log)));
              table_rw.add(msgObj);
            } catch (DOMException) {
              console.error('Could not write to error log.', log);
            }
          }
        );
      }
      this._pushSignal.next();
    });
  }

  pushMessages() {
    return;
    this.auth.getCurrentUser().pipe(take(1)).subscribe(user => {
      if (this._memoryQueue != null) {
        const messages = this._memoryQueue.filter(
          msg => msg.loggerMessage.level >= this._serverLogLevel
        ).map(
          msg => {
            const log = msg.loggerMessage;
            log['userId'] = msg.userId;
            return log;
          }
        );
        this._sendLogMessage$(messages).subscribe(
          (loggedMessages) => {
            if (loggedMessages instanceof Error) {
              const errMsg = new NGXLogInterface();
              errMsg.message = loggedMessages.message;
              errMsg.level = NgxLoggerLevel.ERROR;
              errMsg.additional = [loggedMessages, ];
              errMsg.timestamp = new Date().toISOString();
              this.onLog(errMsg);
              return;
            }
            if (loggedMessages != null && loggedMessages.length > 0) {
              // remove any messages from queue that were successfully logged
              this._memoryQueue = this._memoryQueue.filter(qMsg =>
                loggedMessages.find(lMsg => qMsg.userId === lMsg.userId && qMsg.loggerMessage.timestamp === lMsg.timestamp) == null
              );
            }
          }
        );
      } else {
        const table = this._db.transaction('log_messages', 'readwrite').objectStore('log_messages');
        table.index('userId').getAll(user.userId).onsuccess = (event) => {
          const queuedMessages = event.target['result'].map((lm: LogMessageItem) => lm.loggerMessage);
          if (queuedMessages != null && queuedMessages.length > 0) {
            this._sendLogMessage$(queuedMessages).subscribe({
              next: (loggedMessages) => {

                if (loggedMessages != null && loggedMessages.length > 0) {
                  // remove any messages from queue that were successfully logged
                  const transaction = this._db.transaction('log_messages', 'readwrite');
                  const table_rw = transaction.objectStore('log_messages');
                  for (const lMsg of loggedMessages) {
                    const msgKey = [lMsg.userId, new Date(lMsg.timestamp).toISOString()];
                    table_rw.get(msgKey).onsuccess = (loggedItemEvent: Event) => {
                      const result = loggedItemEvent.target['result'];
                      if (result != null) {
                        table_rw.delete(msgKey);
                      }
                    };
                  }
                }
              },
              complete: () => {
                table.count().onsuccess = (event) => {
                  const msgCount = event.target['result'];
                  const limit = 5000;
                  if (msgCount > limit) {
                    const transaction = this._db.transaction('log_messages', 'readwrite');
                    const table_rw = transaction.objectStore('log_messages');
                    const date_ix = table_rw.index('date');
                    let i = 0;
                    const processed = [];
                    date_ix.openCursor().onsuccess = (event) => {
                      const cursor = event.target['result'] as IDBCursorWithValue;
                      if (cursor && msgCount - i > limit) {
                        processed.push(cursor.value);
                        // cursor.delete();
                        i += 1;
                        cursor.continue();
                      }
                    }
                    console.log('Delete logmessages ', processed);
                  }
                };
              }
            });
          }
        }
      }
    });
  }

  /**
   * Split up messages so they fit in gcloud logging limits. See https://cloud.google.com/logging/quotas 
   * @param messages - the log messages to split into chunks that fit into logging API limits
   * @returns list of list of messages, each sub list is small enough for one API call
   * */
  private _splitMessages(messages: NGXLogInterface[]): NGXLogInterface[][] {
    const chunks: NGXLogInterface[][] = [];
    const CHUNK_MAX = 1e6 - 1024;
    const ENTRY_MAX = 1000;
    const MESSAGE_MAX = 8192;
    let chunkTotal = 0;
    let chunk: NGXLogInterface[] = [];
    messages.forEach((msg) => {
      if (msg.message.length > MESSAGE_MAX) {
        // msg,message too long, reduce message size
        msg.message = msg.message.substr(0, MESSAGE_MAX - 20).concat('…[MSG_TOO_LONG');
      }
      let s = JSON.stringify(msg);
      if (s.length > ENTRY_MAX) {
        // throw away context if too large
        msg.additional = ['Context too large'];
        s = JSON.stringify(msg);
      }
      if (s.length + chunkTotal < CHUNK_MAX) {
        chunk.push(msg);
        chunkTotal += s.length;
      } else {
        chunks.push(chunk);
        chunk = [msg];
        chunkTotal = s.length;
      }
    });
    return chunks;
  }

  private _sendLogMessage$(messages: NGXLogInterface[]) {
    const requests: Observable<LogMessageResponse[]>[] = this._splitMessages(messages).map(
      (chunk) => this.httpClient.post<LogMessageResponse[]>(
        this._serverLoggingUrl, { messages: chunk }
        ).pipe(
        map((response) => {
          if (response instanceof Error) {
            const errMsg = new NGXLogInterface();
            errMsg.message = response.message;
            errMsg.level = NgxLoggerLevel.ERROR;
            errMsg.additional = [response, ];
            errMsg.timestamp = new Date().toISOString();
            this.onLog(errMsg);
            throw response;
          }
          return response;
        })
      )
    );
    return concat(requests).pipe(
      concatAll(),
    );
  }
}
