<div class="card closed" id="perf-chart-card" (click)="closeMarketChart()" #marketChartDiv>
  <div class="card-content" (click)="$event.stopPropagation()">
    <div class="market-chart-navbar" #marketChartNavbar>
      <div class="buttons has-addons market-price-chart-paginator">
        <button class="button" (click)="marketChartChangeProduct(prevProduct)">
          &lt;&lt;&nbsp;&nbsp; {{prevProduct?.LongName}}</button>
        <button class="button is-primary">{{
          currentProduct?.LongName
        }} ( {{
          currentProduct?.SubAssetClass?.Name
        }}&mdash;{{
          currentProduct?.SubAssetClass?.AssetClass?.Name
        }} )</button>
        <button class="button" (click)="marketChartChangeProduct(nextProduct)">
          {{nextProduct?.LongName}} &nbsp;&nbsp;&gt;&gt;</button>
      </div>
      <navbar-button class="flex" 
        #assetMarketChartTypeNavbar 
        [initial]="1" 
        [tabs]="marketChartTypeOptions" 
        (selectionChanged)="marketChartTypeChange($event)">
      </navbar-button>
      <navbar-button class="flex" 
        #assetMarketChartNavbar 
        [dropdownMode]="0"
        [initial]="2" 
        [tabs]="marketChartPeriodTabs" 
        (selectionChanged)="marketChartPeriodSelect($event)">
      </navbar-button>
    </div>
    <button class="close-perf-chart" (click)="closeMarketChart()" title="Close"><i class="fa fa-times" aria-hidden="true"></i></button>
    <div class="chart-wrapper">
        <spinner name="market-charts">
          <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
        </spinner>
        <div class="chart-container">
        <highcharts-chart 
          *ngIf="marketChartMainOptions"
          #marketChartMain
          id="marketChart1" 
          style="width: 100%"
          [callbackFunction]="setMainChartObject"
          [constructorType]="'stockChart'"
          [Highcharts]="Highcharts"
          [ngStyle]="{'height.px': chartHeight || 100}"
          [options]="marketChartMainOptions"
          [(update)]="marketChartMainUpdate"
        >
        </highcharts-chart>
      </div>
      <div *ngIf="showIntradayChart" class="chart-container">
        <highcharts-chart 
          *ngIf="marketChartIntradayOptions"
          #marketChartIntraday
          id="marketChart2" 
          style="width: 100%"
          [ngStyle]="{'height.px': chartHeight || 100}"
          [callbackFunction]="setIntradayChartObject"
          [Highcharts]="Highcharts"
          [options]="marketChartIntradayOptions"
          [constructorType]="'stockChart'"
          [(update)]="marketChartIntradayUpdate"
        >
        </highcharts-chart>
      </div>
    </div>
  </div>
</div>
