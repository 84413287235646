import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sparkline-bar',
  templateUrl: './sparkline-bar.component.html',
  styleUrls: ['./sparkline-bar.component.css']
})
export class SparklineBarComponent implements OnInit {

  @Input() width: number = 164;
  @Input() height: number = 24;

  _value: number = 0;
  _scale: number = 1;

  barPaddingTop: number = 4;
  barPaddingBottom: number = 4;

  @Input() direction: 'negative' | 'both' | 'positive' = 'both';

  private _positiveColor = "#009c90";
  get positiveColor(): string {
    return this._positiveColor;
  }
  @Input() set positiveColor(value: string) {
    if ( value != null ) {
      this._positiveColor = value;  
    }
  };
  private _negativeColor = "#d73600";
  get negativeColor(): string {
    return this._negativeColor;
  }
  @Input() set negativeColor(value: string) {
    if ( value != null ) {
      this._negativeColor = value;  
    }
  };

  constructor() {
    //this.height = 24;
    //this.width = 164;
  }

  ngOnInit() {
    true;
  }


  @Input("value")
  set value(val: number) {
    this._value = val;
  }

  get value() {
    return this._value
  }

  get viewbox() {
    const x = 0;
    const y = 0;
    const { width, height } = this;
    return `${x} ${y} ${width} ${height}`
  }

  get scaleRange(): number | string {
    return this._scale;
  }

  @Input("scale") 
  set scaleRange(val: number | string) {
    let numeric = (new Number(val)).valueOf();
    if (isNaN(numeric) || numeric === 0) {
      if (val == "day") {
        numeric = 0.015;
      } else if (val == "month") {
        numeric = 0.05;
      } else if (val == "year") {
        numeric = 0.10;
      } else {
        numeric = 1;
      }
    }
    this._scale = numeric;
  }

  get barY() {
    return this.barPaddingTop;
  }

  get barHeight() {
    return this.height - this.barPaddingTop - this.barPaddingBottom;
  }

  get normalizedValue() {
    const max = this._scale;
    const val = Math.abs(this.value);
    return val / max;
  }

  get barWidth() {
    const minValue = 2;
    return Math.max(minValue, Math.min(this.halfWidth, this.normalizedValue * this.halfWidth));
  }

  get halfWidth() {
    if (this.direction === 'both') {
      return this.width / 2;
    } else {
      return this.width;
    }
  }

  get color() {
    if (this.isPositive) {
      return this.positiveColor;
    } else {
      return this.negativeColor;
    }
  }

  get isPositive() {
    return this.value >= 0;
  }

  get positiveBarX() {
    if (this.direction !== 'both') {
      return 0;
    } else {
      return this.halfWidth;
    }
  }

  get negativeBarX() {
    if (this.direction !== 'both') {
      return 0;
    } else {
      return this.halfWidth - this.barWidth;
    }
  }

  get positiveBarWidth() {
    if (this.value < 0)
      return 0.0;
    return this.barWidth;
  }

  get negativeBarWidth() {
    if (this.value >= 0)
      return 0.0;
    return this.barWidth;
  }

}
