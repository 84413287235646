<table id="instrument-table" class="table is-fullwidth">
  <thead>
    <tr>
      <th *ngFor="let column of columns; let first = first; last as isLastColumn" 
        class="column-header column-type-{{ column.type }} column-name-{{ column.name }}"
        (click)="sortItems(column)"
        [ngClass]="{'has-vertical-separator': !isLastColumn || options?.showSelectColumn }">
        <div class="first-header" [ngClass]="{'has-breadcrumbs': first && breadcrumbs.length > 0}">
          <div class="label-and-sort">
            <div class="table-column-label">
              {{ column.label }}
            </div>
            <div class="sort-ctrl" 
              *ngIf="column.label?.length > 0 && column?.sort !== null">
              <i [ngClass]="{'fa-sort': (this.currentColumn != column.name), 'fa-sort-desc': (this.currentColumn == column.name) && !this.isColumnAscending[column.name], 'fa-sort-asc': (this.currentColumn == column.name) && this.isColumnAscending[column.name]}"
              class="fa sort-icon" aria-hidden="true"></i>
            </div>
          </div>
          <div *ngIf="first && breadcrumbs.length > 0" 
            class="breadcrumbs buttons is-pulled-right has-addons">
            <div class="button" 
              *ngFor="let crumb of breadcrumbs" 
              (click)="onBreadcrumbButtonClicked($event, crumb.type, crumb.id)"
              [ngClass]="{'is-selected': crumb.selected}">{{crumb.label}}
            </div>
          </div>
        </div>
      </th>
      <th class="select-column" [ngClass]="{'is-hidden': !options['showSelectColumn']}">Select</th>
    </tr>
  </thead>
  <tbody *ngIf="items == null" class="no-items loading">
    <tr>
      <td [attr.colspan]="columns.length + 1">Loading items...     <span class="icon inline-loader small" style="height: 1rem; width: 1rem"></span>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="items != null && items?.length === 0" class="no-items">
    <tr>
      <td [attr.colspan]="columns.length + 1">No items to display.</td>
    </tr>
  </tbody>
  <ng-container *ngIf="options['dataGroups'] != null; then groupedItems; else ungroupedItems"></ng-container>

  <ng-template #groupedItems>
    <tbody *ngFor="let group of dataGroups" class="data-group" [ngClass]="{'collapsed': isDataGroupCollapsed(group)}">
      <tr class="group-header" (click)="toggleDataGroupVis(group)">
        <th [attr.colspan]="columns.length + 1">
          <i class="fa"
            [ngClass]="{ 'fa-folder-o' : isDataGroupCollapsed(group), 'fa-folder-open-o': !isDataGroupCollapsed(group) }">
          </i> {{group.Name}}
          <span *ngIf="isDataGroupCollapsed(group) && (numSelectedInDataGroup(group) > 0)" class="is-size-7">({{numSelectedInDataGroup(group)}} selected)</span>
        </th>
      </tr>
      <ng-container *ngTemplateOutlet="itemRows; context: { items: group.Items }"></ng-container>
    </tbody>
  </ng-template>

  <ng-template #ungroupedItems>
    <tbody>
    <ng-container *ngTemplateOutlet="itemRows; context: { items: items }" ></ng-container>
    </tbody>
  </ng-template>
  
  <ng-template #itemRows let-items="items">
    <tr *ngFor="let item of items; let i = index" class="item-row" [ngClass]="{'selected': selectBox.value }">
        <td *ngFor="let column of columns" 
          class="column-type-{{ column.type }} data-cell column-name-{{ column.name }}"
          [ngClass]="{'clickable': _maxItems > 0 }"
          (click)="itemSelectionChanged(item[id_field], !selectBox.value, $event)">
          <span *ngIf="column.type == 'date'">
            {{ item[column.name] | date: 'dd-MMM-yyyy' }}
          </span>
          <button *ngIf="column.type == 'fa-icon'" class="fa-icon button" (click)="column.click($event, item[id_field])" type="button">
            <i [ngClass]="'fa '.concat(item[column.name])"></i>
          </button>
          <span *ngIf="column.type == 'filesize'">
            {{ item[column.name] | fileSize }}
          </span>
          <span *ngIf="column.type == 'currency'">
              {{ item?.currencySymbol }}{{ 
                sigFigs(item[column.name]?.value == null ? item[column.name] : item[column.name]?.value, 2)
              }}{{ item?.magnitudeSymbol }}
          </span>
          <span *ngIf="column.type == 'number'">
              {{ item[column.name] | number:numberFormat(column.name) }}
          </span>
          <span *ngIf="column.type == 'percent'">
            {{ item[column.name] | percent:numberFormat(column.name) }}
          </span>
          <span *ngIf="column.type == 'html'">
            <div [innerHtml]="item[column.name]"></div>
          </span>
          <span *ngIf="column.type == 'text'">
            {{ item[column.name] }}
          </span>
          <span *ngIf="column.type == 'popup-text'" class="popup-text">
            <ng-container  *ngIf="item[column.name] != null && item[column.name].length > 0">
              <app-help-popup>{{item[column.name]}}</app-help-popup>
            </ng-container>
          </span>
          <div *ngIf="column.type == 'currencybar'" class="currencybar is-pulled-right">
              <div class="currency">
                {{ item?.currencySymbol }}{{ 
                  sigFigs(item[column.name]?.value == null ? item[column.name] : item[column.name]?.value, 2)
                }}{{ item?.magnitudeSymbol }}
              </div>
              <div class="bar">
                <sparkline-bar 
                  class="" [width]="percentbarWidth" height="24"
                  [direction]="getItemOrColumnOption(column, item, 'direction') || 'both'"
                  [positiveColor]="getOptionPositiveColor(column, item)"
                  [negativeColor]="getOptionNegativeColor(column, item)"
                  [scale]="column['scale'] ? column['scale'] : 'day'" 
                  [value]="item[column.name]?.value == null ? item[column.name] : item[column.name]?.value">
                </sparkline-bar>
              </div>
          </div>
          <div *ngIf="column.type == 'percentbar'" class="percentbar is-pulled-right">
              <div class="percent">{{ (item[column.name]?.value == null ? item[column.name] : item[column.name]?.value) | percent:numberFormat(column.name) }}</div>
              <div class="bar">
                <sparkline-bar 
                *ngIf="item[column.name]?.options?.positiveColor != null || column['positiveColor'] != null; else sparklineBarDefaultColors"
                  class="" [width]="percentbarWidth" height="24"
                  [direction]="getItemOrColumnOption(column, item, 'direction') || 'both'"
                  [positiveColor]="getOptionPositiveColor(column, item)"
                  [negativeColor]="getOptionNegativeColor(column, item)"
                  [scale]="column['scale'] ? column['scale'] : 'day'" 
                  [value]="item[column.name]?.value == null ? item[column.name] : item[column.name]?.value">
                </sparkline-bar>
              </div>
          </div>
          <ng-template #sparklineBarDefaultColors>
            <sparkline-bar
            class="" [width]="percentbarWidth" height="24"
            [direction]="getItemOrColumnOption(column, item, 'direction') || 'both'"
            [scale]="column['scale'] ? column['scale'] : 'day'" 
            [value]="item[column.name]?.value == null ? item[column.name] : item[column.name]?.value"></sparkline-bar>
          </ng-template>
        </td>
      <td class="select-column" [ngClass]="{'is-hidden': !options?.showSelectColumn}">
        <label class="checkbox checkbox--checkbox">
            <input #selectBox="ngModel"
              [ngModel]="isSelected(item[id_field])"
              (ngModelChange)="itemSelectionChanged(item[id_field], selectBox.value)"
              type="checkbox"/>
            <div class="checkbox__indicator"></div>
          </label>
      </td>
    </tr>
  </ng-template>
</table>