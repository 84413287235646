import { isEmpty } from "lodash";

/** Check if name is a valid portfolio name, ie. not blank or 'Portfolio n', where n is a single digit. */
export function validPortfolioName(name: string) {
  /* return !(
    name === ''
    || name.match(/^\s*Portfolio \d\s*$/) != null);
    */
   return !isEmpty(name) || !name.match(/^\s*$/);
}

/** check that the name is not 'Portfolio Set 1' or empty space */
export function validPortfolioSetName(name: string): boolean {
  // return !/^(?:(?:\s*(Portfolio Set 1|New portfolio set)\s*)|\s*)$/.test(name);
  return !/^\s*$/.test(name);
}
