import { FlexibleConnectedPositionStrategy, GlobalPositionStrategy, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';
import { debounce } from 'lodash-decorators';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelpPopupService } from './help-popup.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss', ],
})
export class HelpPopupComponent implements OnDestroy, OnInit {
  /** If this variable is set to an ElementRef the popup will be centered in this element rather than connected to the icon */
  @Input() centeredOn: ElementRef<unknown>;
  clicked = false;
  public get showPopup() {
    return this._showPopup;
  }
  public set showPopup(value) {
    if (!value && this._overlayRef != null) {
      if (this._bdClickSub) {
        this._bdClickSub.unsubscribe();
        this._bdClickSub = null;
      }
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
    this._showPopup = value;
    this._changeDetectorRef.markForCheck();
  }


  @ViewChild('centeredMessage', {static: false}) centeredTemplate: TemplateRef<unknown>;

  private _destroy$ = new Subject<void>();
  private _overlayRef: OverlayRef;
  private _showPopup = false;
  private _bdClickSub: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _helpPopupService: HelpPopupService,
    private _overlay: Overlay,
    private _viewRef: ViewContainerRef,
  ) {
    this._helpPopupService.closeAllPopups$.pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => this.closeHelpPopup());
  }

  connectedOverlayDetach() {
    this.closeHelpPopup();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit() {

  }

  @debounce(240)
  showHelpPopup($event: MouseEvent, clicked = true) {
    console.debug('centeredOn', this.centeredOn);
    this._helpPopupService.closeOtherPopups();
    if (this.centeredOn != null) {
      if (!clicked) {
        return false;
      }
    }
    setTimeout(() => {
      if (this.centeredOn) {
        const positionStrategy = this._overlay.position().flexibleConnectedTo(this.centeredOn).withPositions([
          { originX: 'center', originY: 'center', overlayX: 'center', overlayY: 'center' },
        ]);
        this._overlayRef = this._overlay.create({
          hasBackdrop: true,
          positionStrategy
        });
        const portal = new TemplatePortal(this.centeredTemplate, this._viewRef);
        this._overlayRef.attach(portal);
        this._bdClickSub = this._overlayRef.backdropClick().subscribe(() => this.closeHelpPopup());
      }
      this.showPopup = true;
      this.clicked = clicked;
      setTimeout(() => {
        this._changeDetectorRef.markForCheck();
      });
    });

    $event.stopPropagation();
  }

  closeHelpPopup() {
    this.showPopup = false;
    this.clicked = false;
    this._changeDetectorRef.markForCheck();
  }

  @debounce(240)
  leaveHelpPopup() {
    if (!this.clicked && !this.centeredOn) {
      this.showPopup = false;
      this._changeDetectorRef.markForCheck();
    }
  }

}

