import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, NgZone, OnDestroy, Inject } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment-timezone';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { map, share, takeUntil, throttleTime, take } from 'rxjs/operators';
import { AccountDataCacheService } from './account-data/account-data-cache.service';
import { appVersion } from './app.version';
import { AuthenticationService } from './auth/authentication.service';
import { LoggingService } from './utils/logging/logging.service';
import { WindowRefService } from './window-ref.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

const floatingDisclaimerStorageId = 'app.floatingDisclaimerClosed';

@Injectable()
export class AppService implements OnDestroy {
  appVersion = appVersion;
  error: { title?: string, message?: string } = {};
  floatingDisclaimerClosed$: Observable<boolean>;
  isErrorVisible = false;
  isNavbarScrolledUp = new BehaviorSubject(false);
  isSidebarVisible$: Observable<boolean>;
  _isSidebarVisible: boolean;
  layoutMode: BehaviorSubject<'narrow' | 'wide'>;
  updateTime$: Observable<string>;
  menuMode$: Observable<'icons' | 'labels'>;
  private _isSidevarVisibleSubj = new BehaviorSubject<boolean>(false);

  public set updateTime(input: Date) {
    Promise.resolve(null).then(
      () => this._updateTimeSubj.next(input)
    );
  }

  public get updateTimeFormat() {
    if (this._updateTimeFormat == null) {
      this.updateTimeFormat = 'default';
    }
    return this._updateTimeFormat;
  }
  public set updateTimeFormat(value: string) {
    if (value == null || value === 'default') {
      this._updateTimeFormat = 'DD-MMM-YYYY HH:mm';
    } else {
      this._updateTimeFormat = value;
    }
  }
  public readonly TZ = 'Europe/Helsinki';

  public viewportChange$: Observable<UIEvent>;
  public viewportSize: { height: number, width: number };
  public viewportSize$: Observable<{ height: number, width: number }>;

  private _destroySubj = new Subject<void>();

  // App-wide state

  /** Should disclaimer notice be shown in page footer as a floating panel at bottom of screen.
   * Reflected by an entry in LocalStorage.
   */
  private _floatingDisclaimerClosedSubj = new BehaviorSubject<boolean>(null);
  private _isNavbarVisible = new BehaviorSubject<boolean>(true);
  private _menuModeSubj = new BehaviorSubject<'icons'|'labels'>('labels');
  private _updateTimeFormat: string;
  private _updateTimeSubj = new BehaviorSubject<Date>(null);
  private _viewportSizeSubj: BehaviorSubject<{ height: number, width: number }>;

  constructor(
    public authService: AuthenticationService,
    private accountDataCache: AccountDataCacheService,
    private breakpoints: BreakpointObserver,
    private _cookies: CookieService,
    @Inject('LOCAL_STORAGE') private _localStorage: Storage,
    private ngZone: NgZone,
    private notificationService: NotificationsService,
    private windowRefService: WindowRefService,
  ) {
    this.floatingDisclaimerClosed$ = this._floatingDisclaimerClosedSubj.asObservable();
    this.isSidebarVisible = false;
    this.isNavbarVisible = true;
    this.layoutMode = new BehaviorSubject<'narrow'|'wide'>('narrow');
    this.breakpoints.observe(['(min-width: 769px)', '(max-width: 1024px)']).pipe(
      takeUntil(this._destroySubj),
    ).subscribe(
      (state) => {
        if (state.breakpoints['(min-width: 769px)']) {
          this.layoutMode.next('wide');
          if (state.breakpoints['(max-width: 1024px)']) {
            this.menuMode = 'icons';
          } else {
            this.menuMode = 'labels';
          }
        } else if (!state.breakpoints['(min-width: 769px)']) {
          this.layoutMode.next('narrow');
        }
      }
    );

    this.isSidebarVisible$ = this._isSidevarVisibleSubj.asObservable();

    this.menuMode$ = this._menuModeSubj.asObservable();

    // needed by highcharts
    this.windowRefService.nativeWindow['moment'] = moment;

    this.viewportSize = { height: windowRefService.nativeWindow.innerHeight , width: windowRefService.nativeWindow.innerWidth };
    this._viewportSizeSubj = new BehaviorSubject(this.viewportSize);
    this.viewportSize$ = this._viewportSizeSubj.asObservable();
    this.viewportChange$ = fromEvent<UIEvent>(windowRefService.nativeWindow, 'resize').pipe(throttleTime(100), share());
    this.viewportChange$.pipe(
      takeUntil(this._destroySubj),
    ).subscribe((event) => {
      this.viewportSize = { height: (<Window>(event.target)).innerHeight , width: (<Window>(event.target)).innerWidth };
      this._viewportSizeSubj.next(this.viewportSize);
    });

    this.updateTime$ = this._updateTimeSubj.asObservable().pipe(
      map(time => {
        const m = moment(time, this.TZ);
        if (m.isValid()) {
          return m.format(this.updateTimeFormat);
        } else {
          return null;
        }
      }),
    );

    this._initFloatingDisclaimerSetting();

    this.authService.isSandboxOnly$().pipe(takeUntil(this._destroySubj)).subscribe((sandboxOnly) => {
      if (sandboxOnly) {
        setTimeout(() => this.hideNavbar());
      }
    });

  }

  addScrollEventListener(scrollEventListener: (event: UIEvent) => void, target?: Element|Window) {
    const window = this.windowRefService.nativeWindow;
    if (target == null) {
      target = window;
    }
    let eventOptions: any;
    if (this.windowRefService.passiveSupported) {
      eventOptions = {
          capture: true,
          passive: true
      };
    } else {
        eventOptions = true;
    }
    // Run outside zone for performance because browser spams scroll event
    this.ngZone.runOutsideAngular(() => {
        target.addEventListener('scroll', scrollEventListener, eventOptions);
    });
  }

  closeFloatingDisclaimer() {
    this._floatingDisclaimerClosedSubj.next(true);
    const setting = this._localStorage.getItem(floatingDisclaimerStorageId);
    if (setting !== 'true') {
      try {
        this._localStorage.setItem(floatingDisclaimerStorageId, 'true');
      } catch (err) {
        // Quote exceeded, set a cookie instead
        this._cookies.set(floatingDisclaimerStorageId, 'true', moment().add(1, 'month').toDate(), '/');
      }
    }
  }

  set isNavbarVisible(value: boolean) {
    Promise.resolve(null).then(() => this._isNavbarVisible.next(value));
  }

  get isNavbarVisible$(): Observable<boolean> {
    return this._isNavbarVisible.asObservable();
  }

  set menuMode(val: 'icons' | 'labels') {
    Promise.resolve(null).then(() => this._menuModeSubj.next(val));
  }

  hideNavbar() {
    this.isNavbarVisible = false;
  }

  ngOnDestroy() {
    this._destroySubj.next();
    this._destroySubj.complete();
    this._floatingDisclaimerClosedSubj.complete();
    this._floatingDisclaimerClosedSubj = null;
    this._destroySubj = null;
    this.layoutMode.complete();
    this.layoutMode = null;
    this._menuModeSubj.complete();
    this._menuModeSubj = null;
    this._viewportSizeSubj.complete();
    this._viewportSizeSubj = null;
    this._updateTimeSubj.complete();
    this._updateTimeSubj = null;
  }

  showNavbar() {
    this.isNavbarVisible = true;
  }

  toggleNavbar() { this.isNavbarVisible$.pipe(take(1)).subscribe(val => this.isNavbarVisible = !val); }

  set isSidebarVisible(value) {
    this._isSidebarVisible = value;
    this._isSidevarVisibleSubj.next(value);
  }

  get isSidebarVisible() {
    return this._isSidebarVisible;
  }


  logout() {
    this.accountDataCache.clearUserCache();
    this.authService.logout();
  }

  hideSidebar() {
    this.isSidebarVisible = false;
  }

  showSidebar(show = true) {
    this.isSidebarVisible = show;
  }

  toggleSidebar() {
    this.isSidebarVisible$.pipe(take(1)).subscribe(
      (val) => this.isSidebarVisible = !val
    );
  }

  showError(title, message) {
    this.error = {title, message};
    this.isErrorVisible = true;
  }

  hideError() {
    this.error = {};
    this.isErrorVisible = false;
  }

  showNotification(title: string, message: string, type = NotificationType.Bare, override?: any) {
    this.notificationService.create(title, message, type, override);
  }

  private _initFloatingDisclaimerSetting() {
    let setting = this._localStorage.getItem(floatingDisclaimerStorageId);
    if (!setting) {
      setting = this._cookies.get(floatingDisclaimerStorageId);
    }
    this._floatingDisclaimerClosedSubj.next(setting === 'true');
  }
}
