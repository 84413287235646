import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import * as Highcharts from 'highcharts';

import { AccountDataService } from '../../account-data/account-data.service';
import { AppService } from '../../app-service.service';
import { beginningOfTime, getOffsetPeriodStartDate } from '../../account-overview/utils';
import { NavbarTab } from '../../shared/navbar-button/navbar-button.component';
import { AssetClassDeltas, AssetClassGammas, DailyDelta, DailyGamma } from '../../api/webservice.service';
import { debounce, cloneDeep } from 'lodash';
import { cmpAssetClasses, cmpSubAssetClassNames, sortSubAssetClassColumn } from '../../account-data/asset-class-sorting';
import { MarketBrowserTableData } from '../market-browser/market-browser-table-data';
import { DataTableColumn } from '../../shared/data-table/data-table-models';
import { map, takeUntil } from 'rxjs/operators';
import { SpinnerService } from '@chevtek/angular-spinners';
import naturalSort from '../../natural-sort';
import { checkboxLegendOptions, defaultLineChartScalingFactor } from '../../account-overview/utils/charts';
import { LoggingService } from '../../utils/logging/logging.service';
import { NavbarButtonService } from '@app/shared/navbar-button/navbar-button.service';

type SignalType = 'delta' | 'gamma';

const lineColors = ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'];

@Component({
  selector: 'app-account-signal-dynamics',
  templateUrl: './account-signal-dynamics.component.html',
  styleUrls: ['./account-signal-dynamics.component.scss']
})
export class AccountSignalDynamicsComponent implements OnChanges, OnDestroy, OnInit {
  @Input() userId: number;
  @Input() accountId: number | string;
  @Input() endDate: Date;
  @Input() defaultPeriod = '3 months';
  @Output() selectedMarket = new EventEmitter<number>();

  @ViewChild('dynamicsNavbar', { static: true }) navbarWrapper: ElementRef<HTMLDivElement>;

  assetClassChart: Highcharts.Chart;
  accountChart: Highcharts.Chart;
  cancelChartUpdate = new Subject<void>();
  cancelTableUpdate = new Subject<void>();
  dynamicsTimePeriodInitial: number;
  dynamicsTimePeriodOptions = ['3 months', '6 months', '1 year', 'All'];
  dynamicsTypeOptions: NavbarTab[] = [
    { 'label': 'Delta', value: 'delta' },
    { 'label': 'Gamma (reactivity)', value: 'gamma' },
  ];
  isOverviewNavbarScrolledUp: Observable<boolean>;
  navbarIsFloating$: Observable<boolean>;
  selectedPeriod: string;
  selectedType: SignalType = 'delta';
  assetClassesSignalTableColumns: DataTableColumn[] = [
    { label: 'Asset classes', name: 'assetClassName', type: 'text', cmp: (a, b) => cmpAssetClasses(a, b, null) },
    { label: 'Signal', name: 'signal', type: 'percentbar', scale: 1 },
  ];
  subAssetClassesSignalTableColumns: DataTableColumn[] = [
    { label: 'Sub asset classes', name: 'subAssetClassName', type: 'text', cmp: cmpSubAssetClassNames },
    { label: 'Signal', name: 'signal', type: 'percentbar', scale: 1 },
  ];
  productsSignalTableColumns: DataTableColumn[] = [
    { label: 'Markets', name: 'productName', type: 'text' },
    { label: 'Signal', name: 'signal', type: 'percentbar', scale: 1 },
  ];
  tableData: MarketBrowserTableData = {
    assetClasses: {
      columns: this.assetClassesSignalTableColumns,
      items: null,
    },
    subAssetClasses: {
      columns: this.subAssetClassesSignalTableColumns,
      items: null,
    },
    products: {
      columns: this.productsSignalTableColumns,
      items: null,
    }
  };
  tableDataDate: moment.Moment;
  tableOptions = {
    numberFormat: '1.0',
    sort: {
      subAssetClassName: sortSubAssetClassColumn,
    }
  };
  TZ: string;

  constructor(
    private acctDataSvc: AccountDataService,
    private appService: AppService,
    private logger: LoggingService,
    private _navbarSvc: NavbarButtonService,
    private spinnerService: SpinnerService,
  ) {
    // TODO: get default time period from SettingsDataService
    this.selectedPeriod = this.defaultPeriod;
    const timeIndex = this.dynamicsTimePeriodOptions.indexOf(this.defaultPeriod);
    this.dynamicsTimePeriodInitial = timeIndex < 0 ? 0 : timeIndex;
    this.TZ = this.appService.TZ;
    this.isOverviewNavbarScrolledUp = this.appService.isNavbarScrolledUp.asObservable();
  }

  buildAssetClassSignalChartOptions(sigType: SignalType, period: string) {
    return {
      colors: lineColors,
      credits: {
        enabled: false,
      },
      legend: checkboxLegendOptions,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: null
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
        valueSuffix: ' %',
      },
      yAxis: {
        labels: {
          format: '{value: f} %',
        },
        opposite: false,
        title: {
          text: this.dynamicsTypeLabel(sigType),
        }
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d-%m-%Y}',
        },
        ordinal: false,
      }
    } as Highcharts.Options;
  }

  createAccountChart(userId: number, accountId: number | string, endDate: Date, sigType: SignalType, period: string) {
    const chartData$ = this.loadAccountData(userId, accountId, endDate, sigType, period);
    this.cancelChartUpdate.next();
    if (chartData$ == null) {
      return;
    }
    if (this.accountChart != null) {
      this.accountChart.showLoading('Loading...');
    }

    let typedData$;
    if (sigType === 'delta') {
      typedData$ = chartData$ as Observable<DailyDelta[]>;
    } else {
      typedData$ = chartData$ as Observable<DailyGamma[]>;
    }

    typedData$.pipe(
      takeUntil(this.cancelChartUpdate),
    ).subscribe(
      signalData => this.plotAccountChart(sigType, period, signalData),
      (err) => this.logger.error('Cannot load signal dynamics account chart', err),
      () => {
        setTimeout(
          () => {
            if (this.assetClassChart != null) {
              this.assetClassChart.hideLoading();
            }
          }
        );
      },
    );
  }

  createAssetClassesChart(userId: number, accountId: number | string, endDate: Date, sigType: SignalType, period: string) {
    const chartData$ = this.loadAssetClassesData(userId, accountId, endDate, sigType, period);
    if (chartData$ == null) {
      return;
    }
    if (this.assetClassChart != null) {
      this.assetClassChart.showLoading('Loading...');
    }

    let typedData$;
    if (sigType === 'delta') {
      typedData$ = chartData$ as Observable<AssetClassDeltas[]>;
    } else {
      typedData$ = chartData$ as Observable<AssetClassGammas[]>;
    }

    typedData$.pipe(
      takeUntil(this.cancelChartUpdate),
    ).subscribe(
      signalData => this.plotAssetClassesChart(sigType, period, signalData),
      (err) => this.logger.error('Cannot load signal dynamics asset classes chart', err),
      () => {
        setTimeout(
          () => {
            if (this.assetClassChart != null) {
              this.assetClassChart.hideLoading();
            }
          }
        );
      },
    );
  }

  createSignalDynamicsCharts(userId: number, accountId: number | string, endDate: Date, sigType: SignalType, period: string) {
    this.cancelChartUpdate.next();
    this.createAccountChart(userId, accountId, endDate, sigType, period);
    this.createAssetClassesChart(userId, accountId, endDate, sigType, period);
  }

  createSignalDynamicsTables(userId: number, accountId: number | string, endDate: Date, sigType: SignalType) {
    const dateStr = moment.tz(endDate, this.TZ).startOf('day').toISOString(true);

    this.cancelTableUpdate.next();
    this.resetTableData();
    if (this.assetClassChart != null) {
      this.assetClassChart.showLoading('Loading...');
    }
    let max = 0;
    let overview$;
    if (sigType === 'delta') {
      overview$ = this.acctDataSvc.getDeltaOverview(userId, accountId as string, dateStr).pipe(
        takeUntil(this.cancelTableUpdate),
        map(overview => {
          const acItems = overview.AssetClassesDeltas.map(d => {
            const item = {
              id: d.AssetClass.AssetClassId,
              label: d.AssetClass.Name,
              assetClassName: d.AssetClass.Name,
            };
            max = Math.max(Math.abs(d.TotalDelta), max);
            item['signal'] = d.TotalDelta;
            return item;
          });
          const subItems = overview.SubAssetClassesDelta.map(d => {
            const item = {
              id: d.SubAssetClass.Name,
              label: d.SubAssetClass.Name,
              assetClassId: d.SubAssetClass.AssetClass.AssetClassId,
              assetClassName: d.SubAssetClass.AssetClass.Name,
              subAssetClassName: d.SubAssetClass.Name,
            };
            max = Math.max(Math.abs(d.DailyDelta.Delta), max);
            item['signal'] = d.DailyDelta.Delta;
            return item;
          });
          const prodItems = overview.ProductsDelta.map(d => {
            const item = {
              id: d.Product.ProductId,
              label: d.Product.LongName,
              assetClassId: d.Product.SubAssetClass.AssetClass.AssetClassId,
              productName: d.Product.LongName,
              subAssetClassId: d.Product.SubAssetClass.Name,
            };
            max = Math.max(Math.abs(d.DailyDelta.Delta), max);
            item['signal'] = d.DailyDelta.Delta;
            return item;
          });
          this.tableDataDate = moment.tz(overview.Date, this.TZ);
          return [acItems, subItems, prodItems];
        })
      );
    } else {
      overview$ = this.acctDataSvc.getGammaOverview(userId, accountId as string, dateStr).pipe(
        takeUntil(this.cancelTableUpdate),
        map(overview => {
          const acItems = overview.AssetClassesGammas.map(g => {
            const item = {
              id: g.AssetClass.AssetClassId,
              label: g.AssetClass.Name,
              assetClassName: g.AssetClass.Name,
            };
            max = Math.max(Math.abs(g.TotalGamma), max);
            item['signal'] = g.TotalGamma;
            return item;
          });
          const subItems = overview.SubAssetClassesGamma.map(g => {
            const item = {
              id: g.SubAssetClass.Name,
              label: g.SubAssetClass.Name,
              assetClassId: g.SubAssetClass.AssetClass.AssetClassId,
              subAssetClassName: g.SubAssetClass.Name,
            };
            max = Math.max(Math.abs(g.Gamma.Gamma), max);
            item['signal'] = g.Gamma.Gamma;
            return item;
          });
          const prodItems = overview.ProductsGamma.map(g => {
            const item = {
              id: g.Product.ProductId,
              label: g.Product.LongName,
              assetClassId: g.Product.SubAssetClass.AssetClass.AssetClassId,
              productName: g.Product.LongName,
              subAssetClassId: g.Product.SubAssetClass.Name,
            };
            max = Math.max(Math.abs(g.DailyGamma.Gamma), max);
            item['signal'] = g.DailyGamma.Gamma;
            return item;
          });
          this.tableDataDate = moment.tz(overview.Date, this.TZ);
          return [acItems, subItems, prodItems];
        })
      );
    }

    overview$.pipe(
      takeUntil(this.cancelTableUpdate),
    ).subscribe(
      ([acItems, subItems, prodItems]) => {
        const newData = cloneDeep(this.tableData);
        const sigLabel = this.dynamicsTypeLabel(sigType);

        newData.assetClasses.columns[1].label = sigLabel;
        newData.assetClasses.columns[1].scale = max;
        newData.subAssetClasses.columns[1].label = sigLabel;
        newData.subAssetClasses.columns[1].scale = max;
        newData.products.columns[1].label = sigLabel;
        newData.products.columns[1].scale = max;

        acItems.sort((a, b) => cmpAssetClasses(a, b, 'assetClassName'));
        subItems.sort((a, b) => {
          if (a.assetClassName !== b.assetClassName) {
            return cmpAssetClasses(a, b, 'assetClassName');
          }
          return naturalSort(a.subAssetClassName, b.subAssetClassName);
        });
        prodItems.sort((a, b) => naturalSort(a.productName, b.productName));

        newData.assetClasses.items = acItems;
        newData.subAssetClasses.items = subItems;
        newData.products.items = prodItems;
        this.tableData = newData;
      },
    );
  }

  plotAccountChart(sigType: SignalType, period: string, signalData: DailyDelta[] | DailyGamma[]) {
    const chartOptions = this.buildAssetClassSignalChartOptions(sigType, period);
      const seriesData: Highcharts.Point[] = [];
      const seriesOptions: Highcharts.SeriesLineOptions = {
        data: seriesData,
        type: 'line',
      };
      let y_max = -Infinity;
      let y_min = Infinity;

      if (sigType === 'delta') {
        const deltasList = signalData as DailyDelta[];
        for (const dailyDelta of deltasList) {
          const dp = {} as Highcharts.Point;
          dp.x = moment.tz(dailyDelta.Date, this.TZ).valueOf();
          dp.y = dailyDelta.Delta * 100;
          seriesData.push(dp);
          y_max = Math.max(dp.y, y_max);
          y_min = Math.min(dp.y, y_min);
        }
      } else {
        const gammasList = signalData as DailyGamma[];
        for (const dailyGamma of gammasList) {
          const dp = {} as Highcharts.Point;
          dp.x = moment.tz(dailyGamma.Date, this.TZ).valueOf();
          dp.y = dailyGamma.Gamma * 100;
          seriesData.push(dp);
          y_max = Math.max(dp.y, y_max);
          y_min = Math.min(dp.y, y_min);
        }
    }

    chartOptions.series = [seriesOptions, ];
    (chartOptions.yAxis as Highcharts.AxisOptions).max = y_max > 0 ?
      y_max * defaultLineChartScalingFactor
      : y_max / defaultLineChartScalingFactor;
      (chartOptions.yAxis as Highcharts.AxisOptions).min = y_min > 0 ?
        y_min / defaultLineChartScalingFactor
        : y_min * defaultLineChartScalingFactor;
    if (this.accountChart != null) {
      this.accountChart.update(chartOptions);
    } else {
      this.accountChart = Highcharts.chart('account-signal', chartOptions);
    }
    this.accountChart.hideLoading();
  }

  plotAssetClassesChart(sigType: SignalType, period: string, signalData: AssetClassDeltas[] | AssetClassGammas[]) {
    const chartOptions = this.buildAssetClassSignalChartOptions(sigType, period);
    const seriesList: Highcharts.SeriesLineOptions[] = [];
    let y_max = -Infinity;
    let y_min = Infinity;
    for (const acSignalList of signalData) {
      const acName = acSignalList.AssetClass.Name;
      const seriesData: Highcharts.Point[] = [];
      const seriesOptions: Highcharts.SeriesLineOptions = {
        name: acName,
        data: seriesData,
        type: 'line',
      };
      if (sigType === 'delta') {
        const deltasObject = (<AssetClassDeltas>acSignalList).Deltas;
        for (const dailyDelta of deltasObject) {
          const dp = {} as Highcharts.Point;
          dp.x = moment.tz(dailyDelta.Date, this.TZ).valueOf();
          dp.y = dailyDelta.Delta * 100;
          seriesData.push(dp);
          y_max = Math.max(dp.y, y_max);
          y_min = Math.min(dp.y, y_min);
        }
      } else {
        const gammasObject = (<AssetClassGammas>acSignalList).Gammas;
        for (const dailyGamma of gammasObject) {
          const dp = {} as Highcharts.Point;
          dp.x = moment.tz(dailyGamma.Date, this.TZ).valueOf();
          dp.y = dailyGamma.Gamma * 100;
          seriesData.push(dp);
          y_max = Math.max(dp.y, y_max);
          y_min = Math.min(dp.y, y_min);
        }
      }
      seriesList.push(seriesOptions);
    }

    seriesList.sort(
      (a: Highcharts.SeriesLineOptions, b:  Highcharts.SeriesLineOptions) => cmpAssetClasses(a, b, 'name')
    );

    chartOptions.series = seriesList;
    (chartOptions.yAxis as Highcharts.AxisOptions).max = y_max > 0 ?
      y_max * defaultLineChartScalingFactor
      : y_max / defaultLineChartScalingFactor;
      (chartOptions.yAxis as Highcharts.AxisOptions).min = y_min > 0 ?
        y_min / defaultLineChartScalingFactor
        : y_min * defaultLineChartScalingFactor;
    if (this.assetClassChart != null) {
      this.assetClassChart.update(chartOptions);
    } else {
      this.assetClassChart = Highcharts.chart('asset-classes-signal', chartOptions);
    }
    this.assetClassChart.hideLoading();
  }

  dynamicsChangeType(sigType: SignalType) {
    this.selectedType = sigType;
    this.createSignalDynamicsCharts(this.userId, this.accountId, this.endDate, sigType, this.selectedPeriod);
    this.createSignalDynamicsTables(this.userId, this.accountId, this.endDate, sigType);
  }

  dynamicsSelectTimePeriod(period: string) {
    this.selectedPeriod = period;
    this.createSignalDynamicsCharts(this.userId, this.accountId, this.endDate, this.selectedType, period);
  }

  dynamicsTypeLabel(id: SignalType) {
    if (id != null) {
      const found = this.dynamicsTypeOptions.find(opt => opt.value === id);
      if (found != null) {
        return found.label;
      }
    }
    return '';
  }

  loadAccountData(
    userId: number, accountId: number | string, endDate: Date, sigType: SignalType, period: string
  ): Observable<DailyDelta[]> | Observable<DailyGamma[]> {
    const startDate = getOffsetPeriodStartDate(endDate, period);
    if (endDate == null || startDate == null) {
      return;
    }
    if (sigType === 'delta') {
      return this.acctDataSvc.getAccountDeltas(userId, accountId, startDate, endDate).pipe(
        takeUntil(this.cancelChartUpdate),
      ) as Observable<DailyDelta[]>;
    } else {
      return this.acctDataSvc.getAccountGammas(userId, accountId, startDate, endDate).pipe(
        takeUntil(this.cancelChartUpdate),
      ) as Observable<DailyGamma[]>;
    }
  }

  loadAssetClassesData(
    userId: number, accountId: number | string, endDate: Date, sigType: SignalType, period: string
  ): Observable<AssetClassDeltas[] | AssetClassGammas[]> {
    const startDate = getOffsetPeriodStartDate(endDate, period);
    if (endDate == null || startDate == null) {
      return;
    }
    if (sigType === 'delta') {
      return this.acctDataSvc.getAssetClassesDeltas(userId, accountId, startDate, endDate).pipe(
        takeUntil(this.cancelChartUpdate),
      );
    } else {
      return this.acctDataSvc.getAssetClassesGammas(userId, accountId, startDate, endDate).pipe(
        takeUntil(this.cancelChartUpdate),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.userId != null && this.accountId != null && this.endDate != null) {
      debounce(
        () => {
          this.createSignalDynamicsCharts(this.userId, this.accountId, this.endDate, this.selectedType, this.selectedPeriod);
          this.createSignalDynamicsTables(this.userId, this.accountId, this.endDate, this.selectedType);
        },
        1000,
        {leading: true}
      );
    }
  }

  ngOnDestroy() {
    this.cancelChartUpdate.next();
    this.cancelChartUpdate.complete();
    this.cancelChartUpdate.unsubscribe();
    this.cancelTableUpdate.next();
    this.cancelTableUpdate.complete();
    this.cancelTableUpdate.unsubscribe();
  }

  ngOnInit() {
    this.navbarIsFloating$ = this._navbarSvc.isNavbarFloating$('sgnlDnmcs', this.navbarWrapper.nativeElement);
    if (this.assetClassChart != null) {
      this.assetClassChart.destroy();
    }
    this.createSignalDynamicsCharts(this.userId, this.accountId, this.endDate, this.selectedType, this.selectedPeriod);
    this.createSignalDynamicsTables(this.userId, this.accountId, this.endDate, this.selectedType);
  }

  resetTableData() {
    this.tableData = {
      assetClasses: {
        columns: this.assetClassesSignalTableColumns,
        items: null,
      },
      subAssetClasses: {
        columns: this.subAssetClassesSignalTableColumns,
        items: null,
      },
      products: {
        columns: this.productsSignalTableColumns,
        items: null,
      }
    };
  }

  selectMarket(prodId: number) {
    this.selectedMarket.emit(prodId);
  }
}
