import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  DATA_FREQ_ID,
  SandboxAsset,
  SandboxPortfolio,
  TemporarySandboxPortfolioInfo,
  ForecastResult,
  IndexDateRange,
  ForecastSetMember
} from '@app/api/webservice.service';
import { SandboxAssetAllocation } from '../models/sandbox-asset-allocation';
import { environment } from '../../../environments/environment';
import { SandboxEditorView } from '../models/sandbox-editor-view';
import { ValidationErrors } from '@angular/forms';
import { Sandbox3PortfolioMeta } from '../models/sandbox3-portfolio-meta';
import { LoggingService } from '@app/utils/logging/logging.service';
import { PortfolioSet } from '../models/portfolio-set';
import { ValueChartTimePeriod } from '../models/ValueChartTimePeriod';
import { take } from 'rxjs/operators';
import { SandboxAssetSettings } from '../models/SandboxAssetSettings';
import { AssetDragOperationState } from '../models/asset-drag-operation-state.enum';
import { AssetInfoCollapsedState } from '../models/asset-info-collapsed-state.enum';

function* makeRangeIterator(start = 0, end = Infinity, step = 1) {
  let iterationCount = 0;
  for (let i = start; i < end; i += step) {
      iterationCount++;
      yield i;
  }
  return iterationCount;
}


@Injectable({
  providedIn: 'root'
})
export class Sandbox3StateService implements OnDestroy {
  /** List of max capital allocation per asset class for each loadedPortfolio */
  public acMaxCapList$: Observable<number[]>;

  /** List of max capital allocation per asset class for each loadedPortfolio */
  public acMaxVolList$: Observable<number[]>;

  /** Currently selected data frequency id, used in statistics view */
  public activeDataFrequencyId$: Observable<DATA_FREQ_ID>;

  /** Track ongoing drag operation */
  public assetDragOperationState$: Observable<AssetDragOperationState>;

  /** Signal sandbox components that an asset has been dropped somewhere. */
  public assetDropped$: Observable<void>;

  /**
   * Expected return values for each forecast set. 
   * Structure: Forecast Set Name -> Asset ID -> Expected Return
   */
  public assetExpectedReturnMap$: Observable<Map<string, Map<number, number>>>;

  /** Determines wheter asset info columns is expanded in portfolio editor */
  public assetInfoColumnCollapsed$: Observable<AssetInfoCollapsedState>;
  /** Expanded assetInfoColumn width in pixels */
  public assetInfoColumnWidth$: Observable<number>;

  public assetListDimensions$: Observable<DOMRect>;
  public assetSettingsMap$: Observable<Map<number, SandboxAssetSettings>>;
  public availableAssets$: Observable<SandboxAsset[]>;
  public availableAssetsLoading$: Observable<boolean>;
  public availablePortfolios$: Observable<Sandbox3PortfolioMeta[]>;
  public availablePortfolioSets$: Observable<PortfolioSet[]>;
  public blockValueChart$: Observable<boolean>;
  public confirmPortfolioSetOverwrite$: Observable<PortfolioSet>;
  public confirmPortfoliosOverwrite$: Observable<Sandbox3PortfolioMeta[]>;

  /** Collection of allocations for current portfolio. Maps asset ids to allocation objects. */
  public currentAssetAllocations$: Observable<Map<number, SandboxAssetAllocation>[]>;
  /** Preview of currentAssetAllocations used during resize operations for performance.
   * Only components that need to be updated live subscribe to this one. */
  public currentAssetAllocationsLive$: Observable<Map<number, SandboxAssetAllocation>[]>;

  public currentAssetColorTable$: Observable<Map<number, string>>;

  /** Total capital budget of current portfolio */
  public currentCapitalBudget$: Observable<number[]>;
  public currentForecastSet$: Observable<string>;

  /** Currently selected portfolio. Index into loadedPortfolios array. */
  public currentPortfolio$: Observable<number>;

  public currentRiskBudget$: Observable<number>;
  public currentPortfolioSet$: Observable<PortfolioSet>;

  /** Master list of currently loaded portfolios */
  public loadedPortfolios$: Observable<SandboxPortfolio[]>;

  public minimizeStatisticsCharts$: Observable<boolean>;
  public minimizeStatistics$: Observable<boolean>;
  public portfolioReturnForecasts$: Observable<ForecastResult[]>;
  public portfolioReturnForecastsLoading$: Observable<boolean>;
  public portfoliosIncomplete$: Observable<boolean[]>;
  public portfolioSetSaving$: Observable<boolean>;
  public portfolioStatistics$: Observable<TemporarySandboxPortfolioInfo[]>;
  public portfolioStatisticsAssetDateRanges$: Observable<IndexDateRange[]>;
  public portfolioStatisticsLoading$: Observable<boolean>;
  public portfolioStatisticsTimePeriod$: Observable<ValueChartTimePeriod>;
  public portfoliosValidationErrors$: Observable<Map<number, ValidationErrors>>;
  public overBudgetErrorTotal$: Observable<number>;
  public overBudgetPortfolios$: Observable<Map<number, number>>;
  public overRiskBudgetErrorTotal$: Observable<number>;
  public sandboxAssetListError$: Observable<Error>;
  public sandboxEditorView$: Observable<SandboxEditorView>;
  public overwritePortfolioSetRequest$: Observable<PortfolioSet>;
  public overwritePortfoliosRequest$: Observable<Sandbox3PortfolioMeta[]>;
  public showPortfolioEditorMoreTools$: Observable<boolean>;
  public sandboxClosePortfolioMenu$: Observable<void>;
  public sandboxClosePortfolioSetMenu$: Observable<void>;
  public sandboxClosePortfolioForecastMenu$: Observable<void>;
  public sandboxEditorAllocationView$: Observable<'capital'|'volatility'|'both'>;
  public sandboxAllocationSunburstsOpen$: Observable<boolean>;
  public sandboxAssetListOpen$: Observable<boolean>;
  public sandboxInvalidNames$: Observable<Map<number, string>>;
  public sandboxPortfoliosLoading$: Observable<Map<number, boolean>>;
  public sandboxPortfoliosSaving$: Observable<Map<number, boolean>>;
  public selectedAssets$: Observable<SandboxAsset[][]>;
  public showDisclaimer$: Observable<boolean>;
  public showHelpCapitalHint$: Observable<boolean>;
  public showPortfolioPicker$: Observable<boolean>;
  public showPortfolioReturnForecastInValueChart$: Observable<boolean>;
  public showPortfolioSetPicker$: Observable<boolean>;
  public showStatistics$: Observable<boolean>;
  public showStatisticsCharts$: Observable<boolean>;
  public showStatisticsPortfolios$: Observable<Map<number, boolean>>;
  public showUserGuide$: Observable<boolean>;
  public unusedAssets$: Observable<SandboxAsset[][]>;

  private _acMaxCapListSubj = new BehaviorSubject<number[]>(null);
  private _acMaxVolListSubj = new BehaviorSubject<number[]>(null);
  private _activeDataFrequencyIdSubj = new BehaviorSubject<DATA_FREQ_ID>(DATA_FREQ_ID.MONTHLY);
  private _assetDragOperationStateSubj = new BehaviorSubject<AssetDragOperationState>(AssetDragOperationState.NotDragging);
  private _assetDroppedSubj = new Subject<void>();
  private _assetExpectedReturnMapSubj = new BehaviorSubject<Map<string, Map<number, number>>>(new Map());
  private _assetInfoColumnCollapsedSubj = new BehaviorSubject<AssetInfoCollapsedState>(AssetInfoCollapsedState.Open);
  private _assetInfoColumnWidthSubj = new BehaviorSubject<number>(200);
  private _assetListDimensionsSubj = new BehaviorSubject(null);
  private _assetSettingsMapSubj = new BehaviorSubject<Map<number, SandboxAssetSettings>>(new Map());
  private _availableAssetsSubj = new BehaviorSubject<SandboxAsset[]>(null);
  private _availableAssetsLoadingSubj = new BehaviorSubject<boolean>(false);
  private _availablePortfoliosSubj = new BehaviorSubject<Sandbox3PortfolioMeta[]>(null);
  private _availablePortfolioSetsSubj = new BehaviorSubject<PortfolioSet[]>(null);
  private _blockValueChartSubj = new BehaviorSubject<boolean>(false);
  private _confirmPortfolioSetOverwriteSubj = new Subject<PortfolioSet>();
  private _confirmPortfoliosOverwriteSubj = new Subject<Sandbox3PortfolioMeta[]>();
  private _currentAssetAllocationsSubj = new BehaviorSubject<Map<number, SandboxAssetAllocation>[]>(null);
  private _currentAssetColorTableSubj = new BehaviorSubject<Map<number, string>>(null);
  private _currentAssetAllocationsLiveSubj = new BehaviorSubject<Map<number, SandboxAssetAllocation>[]>(null);
  private _currentCapitalBudgetSubj = new BehaviorSubject<number[]>([100]);
  private _currentForecastSetSubj = new BehaviorSubject(null);
  private _currentPortfolioSubj = new BehaviorSubject<number>(null);
  private _currentPortfolioSetSubj = new BehaviorSubject<PortfolioSet>(null);
  private _currentRiskBudgetSubj = new BehaviorSubject<number>(100); // unused for now.
  private _loadedPortfoliosSubj = new BehaviorSubject<SandboxPortfolio[]>(null);
  private _minimizeStatisticsChartsSubj = new BehaviorSubject<boolean>(false);
  private _minimizeStatisticsSubj = new BehaviorSubject<boolean>(false);
  private _overBudgetErrorTotalSubj = new BehaviorSubject<number>(null);
  private _overBudgetPortfoliosSubj = new BehaviorSubject<Map<number, number>>(null);
  private _overRiskBudgetErrorTotalSubj = new BehaviorSubject<number>(null);
  private _portfolioSetSavingSubj = new BehaviorSubject<boolean>(false);
  private _overwritePortfolioSetRequestSubj = new BehaviorSubject<PortfolioSet>(null);
  private _overwritePortfoliosRequestSubj = new BehaviorSubject<Sandbox3PortfolioMeta[]>(null);
  private _portfoliosIncompleteSubj = new BehaviorSubject<boolean[]>([false]);
  private _portfolioReturnForecastsSubj = new BehaviorSubject<ForecastResult[]>(null);
  private _portfolioReturnForecastsLoadingSubj = new BehaviorSubject<boolean>(false);
  private _portfoliosValidationErrorsSubj = new BehaviorSubject<Map<number, ValidationErrors>>(new Map());
  private _portfolioStatisticsSubj = new BehaviorSubject<TemporarySandboxPortfolioInfo[]>(null);
  private _portfolioStatisticsAssetDateRangesSubj = new BehaviorSubject<IndexDateRange[]>(null);
  private _portfolioStatisticsLoadingSubj = new BehaviorSubject<boolean>(false);
  private _portfolioStatisticsTimePeriodSubj = new BehaviorSubject(ValueChartTimePeriod.All);
  private _sandboxAllocationSunburstsOpenSubj = new BehaviorSubject<boolean>(false);
  private _sandboxAssetListErrorSubj = new BehaviorSubject<Error>(null);
  private _sandboxEditorAllocationViewSubj = new BehaviorSubject<'capital'|'volatility'|'both'>('both');
  private _sandboxEditorViewSubj = new BehaviorSubject<SandboxEditorView>(SandboxEditorView.Standard);
  private _showPortfolioPickerSubj = new BehaviorSubject<boolean>(false);
  private _showPortfolioSetPickerSubj = new BehaviorSubject<boolean>(false);
  private _sandboxAssetListOpenSubj = new BehaviorSubject<boolean>(true);
  private _sandboxClosePortfolioMenuSubj = new Subject<void>();
  private _sandboxClosePortfolioSetMenuSubj = new Subject<void>();
  private _sandboxClosePortfolioForecastMenuSubj = new Subject<void>();
  private _sandboxInvalidNamesSubj = new BehaviorSubject<Map<number, string>>(new Map());
  private _sandboxPortfoliosLoadingSubj = new BehaviorSubject<Map<number, boolean>>(new Map<number, boolean>());
  private _sandboxPortfoliosSavingSubj = new BehaviorSubject<Map<number, boolean>>(new Map<number, boolean>());
  private _selectedAssetsSubj = new BehaviorSubject<SandboxAsset[][]>(null);
  private _showDisclaimerSubj = new BehaviorSubject<boolean>(environment.production);
  private _showHelpCapitalHintSubj = new BehaviorSubject<boolean>(true);
  private _showPortfolioReturnForecastInValueChartSubj = new BehaviorSubject(false);
  private _showStatisticsSubj = new BehaviorSubject<boolean>(false);
  private _showStatisticsChartsSubj = new BehaviorSubject<boolean>(false);
  private _showStatisticsPortfoliosSubj = new BehaviorSubject<Map<number, boolean>>(new Map());
  private _showUserGuideSubj = new BehaviorSubject<boolean>(
    environment['showUserGuideByDefault'] != null && environment['showUserGuideByDefault'] === true
  );
  private _unusedAssetsSubj = new BehaviorSubject<SandboxAsset[][]>(null);

  constructor(private logger: LoggingService) {
    this.acMaxCapList$ = this._acMaxCapListSubj.asObservable();
    this.acMaxVolList$ = this._acMaxVolListSubj.asObservable();
    this.activeDataFrequencyId$ = this._activeDataFrequencyIdSubj.asObservable();
    this.assetDragOperationState$ = this._assetDragOperationStateSubj.asObservable();
    this.assetDropped$ = this._assetDroppedSubj.asObservable();
    this.assetExpectedReturnMap$ = this._assetExpectedReturnMapSubj.asObservable();
    this.assetInfoColumnCollapsed$ = this._assetInfoColumnCollapsedSubj.asObservable();
    this.assetInfoColumnWidth$ = this._assetInfoColumnWidthSubj.asObservable();
    this.assetListDimensions$ = this._assetListDimensionsSubj.asObservable();
    this.assetSettingsMap$ = this._assetSettingsMapSubj.asObservable();
    this.availableAssets$ = this._availableAssetsSubj.asObservable();
    this.availableAssetsLoading$ = this._availableAssetsLoadingSubj.asObservable();
    this.availablePortfolios$ = this._availablePortfoliosSubj.asObservable();
    this.availablePortfolioSets$ = this._availablePortfolioSetsSubj.asObservable();
    this.blockValueChart$ = this._blockValueChartSubj.asObservable();
    this.confirmPortfolioSetOverwrite$ = this._confirmPortfolioSetOverwriteSubj.asObservable();
    this.confirmPortfoliosOverwrite$ = this._confirmPortfoliosOverwriteSubj.asObservable();
    this.currentAssetAllocations$ = this._currentAssetAllocationsSubj.asObservable();
    this.currentAssetAllocationsLive$ = this._currentAssetAllocationsLiveSubj.asObservable();
    this.currentAssetColorTable$ = this._currentAssetColorTableSubj.asObservable();
    this.currentCapitalBudget$ = this._currentCapitalBudgetSubj.asObservable();
    this.currentForecastSet$ = this._currentForecastSetSubj.asObservable();
    this.currentPortfolio$ = this._currentPortfolioSubj.asObservable();
    this.currentRiskBudget$ = this._currentRiskBudgetSubj.asObservable();
    this.currentPortfolioSet$ = this._currentPortfolioSetSubj.asObservable();
    this.loadedPortfolios$ = this._loadedPortfoliosSubj.asObservable();
    this.minimizeStatistics$ = this._minimizeStatisticsSubj.asObservable();
    this.minimizeStatisticsCharts$ = this._minimizeStatisticsChartsSubj.asObservable();
    this.overBudgetErrorTotal$ = this._overBudgetErrorTotalSubj.asObservable();
    this.overBudgetPortfolios$ = this._overBudgetPortfoliosSubj.asObservable();
    this.overRiskBudgetErrorTotal$ = this._overRiskBudgetErrorTotalSubj.asObservable();
    this.portfolioSetSaving$ = this._portfolioSetSavingSubj.asObservable();
    this.overwritePortfolioSetRequest$ = this._overwritePortfolioSetRequestSubj.asObservable();
    this.overwritePortfoliosRequest$ = this._overwritePortfoliosRequestSubj.asObservable();
    this.portfoliosIncomplete$ = this._portfoliosIncompleteSubj.asObservable();
    this.portfolioReturnForecasts$ = this._portfolioReturnForecastsSubj.asObservable();
    this.portfolioReturnForecastsLoading$ = this._portfolioReturnForecastsLoadingSubj.asObservable();
    this.portfoliosValidationErrors$ = this._portfoliosValidationErrorsSubj.asObservable();
    this.portfolioStatistics$ = this._portfolioStatisticsSubj.asObservable();
    this.portfolioStatisticsAssetDateRanges$ = this._portfolioStatisticsAssetDateRangesSubj.asObservable();
    this.portfolioStatisticsLoading$ = this._portfolioStatisticsLoadingSubj.asObservable();
    this.portfolioStatisticsTimePeriod$ = this._portfolioStatisticsTimePeriodSubj.asObservable();
    this.sandboxAllocationSunburstsOpen$ = this._sandboxAllocationSunburstsOpenSubj.asObservable();
    this.sandboxAssetListError$ = this._sandboxAssetListErrorSubj.asObservable();
    this.sandboxAssetListOpen$ = this._sandboxAssetListOpenSubj.asObservable();
    this.sandboxEditorView$ = this._sandboxEditorViewSubj.asObservable();
    this.sandboxClosePortfolioMenu$ = this._sandboxClosePortfolioMenuSubj.asObservable();
    this.sandboxClosePortfolioForecastMenu$ = this._sandboxClosePortfolioForecastMenuSubj.asObservable();
    this.sandboxClosePortfolioSetMenu$ = this._sandboxClosePortfolioSetMenuSubj.asObservable();
    this.sandboxInvalidNames$ = this._sandboxInvalidNamesSubj.asObservable();
    this.sandboxPortfoliosLoading$ = this._sandboxPortfoliosLoadingSubj.asObservable();
    this.sandboxPortfoliosSaving$ = this._sandboxPortfoliosSavingSubj.asObservable();
    this.selectedAssets$ = this._selectedAssetsSubj.asObservable();
    this.showDisclaimer$ = this._showDisclaimerSubj.asObservable();
    this.showHelpCapitalHint$ = this._showHelpCapitalHintSubj.asObservable();
    this.showPortfolioPicker$ = this._showPortfolioPickerSubj.asObservable();
    this.showPortfolioReturnForecastInValueChart$ = this._showPortfolioReturnForecastInValueChartSubj.asObservable();
    this.showPortfolioSetPicker$ = this._showPortfolioSetPickerSubj.asObservable();
    this.showStatistics$ = this._showStatisticsSubj.asObservable();
    this.showStatisticsCharts$ = this._showStatisticsChartsSubj.asObservable();
    this.showStatisticsPortfolios$ = this._showStatisticsPortfoliosSubj.asObservable();
    this.showUserGuide$ = this._showUserGuideSubj.asObservable();
    this.unusedAssets$ = this._unusedAssetsSubj.asObservable();
  }

  public set acMaxCapList(val: number[]) {
    this.logger.debug('acMaxCapList', val);
    Promise.resolve(null).then(() => this._acMaxCapListSubj.next(val));
  }

  public set acMaxVolList(val: number[]) {
    this.logger.debug('acMaxVolList', val);
    Promise.resolve(null).then(() => this._acMaxVolListSubj.next(val));
  }

  public set activeDataFrequencyId(val: DATA_FREQ_ID) {
    this.logger.debug('activeDataFrequencyId', val);
    Promise.resolve(null).then(() => this._activeDataFrequencyIdSubj.next(val));
  }

  public set assetDragOperationState(val: AssetDragOperationState) {
    this.logger.debug('assetDragOperationState');
    Promise.resolve(null).then(() => this._assetDragOperationStateSubj.next(val));
  }

  public set assetDropped(val: boolean) {
    this.logger.debug('assetDropped');
    Promise.resolve(null).then(() => this._assetDroppedSubj.next());
  }

  public set assetExpectedReturnMap(val: Map<string, Map<number, number>>) {
    this.logger.debug('assetExpectedReturnMap', val);
    Promise.resolve(null).then(() => this._assetExpectedReturnMapSubj.next(val));
  }

  public set assetInfoColumnCollapsed(val: AssetInfoCollapsedState) {
    this.logger.debug('assetInfoColumnCollapsed', val);
    Promise.resolve(null).then(() => this._assetInfoColumnCollapsedSubj.next(val));
  }

  public set assetInfoColumnWidth(val: number) {
    this.logger.debug('assetInfoColumnWidth', val);
    Promise.resolve(null).then(() => this._assetInfoColumnCollapsedSubj.next(val));
  }

  public set assetListDimensions(val: DOMRect) {
    this.logger.debug('assetListDimensions', val);
    Promise.resolve(null).then(() => this._assetListDimensionsSubj.next(val));
  }

  public set assetSettingsMap(val: Map<number, SandboxAssetSettings>) {
    this.logger.debug('assetSettingsMap', val);
    Promise.resolve(null).then(() => this._assetSettingsMapSubj.next(val));
  }

  public set availableAssets(val: SandboxAsset[]) {
    this.logger.debug('availableAssets', val);
    Promise.resolve(null).then(() => this._availableAssetsSubj.next(val));
  }

  public set availableAssetsLoading(val: boolean) {
    this.logger.debug('availableAssetsLoading', val);
    Promise.resolve(null).then(() => this._availableAssetsLoadingSubj.next(val));
  }

  public set availablePortfolios(val: Sandbox3PortfolioMeta[]) {
    this.logger.debug('availablePortfolios', val);
    Promise.resolve(null).then(() => this._availablePortfoliosSubj.next(val));
  }

  public set availablePortfolioSets(val: PortfolioSet[]) {
    this.logger.debug('availablePortfolioSets', val);
    Promise.resolve(null).then(() => this._availablePortfolioSetsSubj.next(val));
  }

  public set blockValueChart(val: boolean) {
    this.logger.debug('blockValueChart', val);
    Promise.resolve(null).then(() => this._blockValueChartSubj.next(val));
  }

  public set confirmPortfolioSetOverwrite(val: PortfolioSet) {
    this.logger.debug('confirmPortfolioSetOverwrite', val);
    Promise.resolve(null).then(() => this._confirmPortfolioSetOverwriteSubj.next(val));
  }

  public set confirmPortfoliosOverwrite(val: Sandbox3PortfolioMeta[]) {
    this.logger.debug('confirmPortfoliosOverwrite', val);
    Promise.resolve(null).then(() => this._confirmPortfoliosOverwriteSubj.next(val));
  }

  public set currentAssetAllocations(val: Map<number, SandboxAssetAllocation>[]) {
    this.logger.debug('currentAssetAllocations', val);
    Promise.resolve(null).then(() => this._currentAssetAllocationsSubj.next(val));
  }

  public set currentAssetColorTable(val: Map<number, string>) {
    this.logger.debug('currentAssetColorTable', val);
    Promise.resolve(null).then(() => this._currentAssetColorTableSubj.next(val));
  }

  public set currentAssetAllocationsLive(val: Map<number, SandboxAssetAllocation>[]) {
    this.logger.debug('currentAssetAllocationsLive', val);
    this._currentAssetAllocationsLiveSubj.next(val);
  }

  public set currentCapitalBudget(val: number[]) {
    this.logger.debug('currentCapitalBudget', val);
    Promise.resolve(null).then(() => this._currentCapitalBudgetSubj.next(val));
  }

  public set currentRiskBudget(val: number) {
    this.logger.debug('currentRiskBudget', val);
    Promise.resolve(null).then(() => this._currentRiskBudgetSubj.next(val));
  }

  public set currentPortfolio(val: number) {
    this.logger.debug('currentPortfolio', val);
    Promise.resolve(null).then(() => this._currentPortfolioSubj.next(val));
  }

  public set currentPortfolioSet(val: PortfolioSet) {
    this.logger.debug('currentPortfolioSet', val);
    Promise.resolve(null).then(() => this._currentPortfolioSetSubj.next(val));
  }

  public set currentForecastSet(val: string) {
    this.logger.debug('currentForecastSet', val);
    Promise.resolve(null).then(() => this._currentForecastSetSubj.next(val));
  }

  public set loadedPortfolios(val: SandboxPortfolio[]) {
    this.logger.debug('loadedPortfolios', val);
    Promise.resolve(null).then(() => this._loadedPortfoliosSubj.next(val));
  }

  public set minimizeStatistics(val: boolean) {
    this.logger.debug('minimizeStatistics', val);
    Promise.resolve(null).then(() => this._minimizeStatisticsSubj.next(val));
  }

  public set minimizeStatisticsCharts(val: boolean) {
    this.logger.debug('minimizeStatisticsCharts', val);
    Promise.resolve(null).then(() => this._minimizeStatisticsChartsSubj.next(val));
  }

  public set overBudgetErrorTotal(val: number) {
    this.logger.debug('overBudgetErrorTotal', val);
    Promise.resolve(null).then(() => this._overBudgetErrorTotalSubj.next(val));
  }

  public set overBudgetPortfolios(val: Map<number, number>) {
    this.logger.debug('overBudgetPortfolios', val);
    Promise.resolve(null).then(() => this._overBudgetPortfoliosSubj.next(val));
  }

  public set overRiskBudgetErrorTotal(val: number) {
    this.logger.debug('overRiskBudgetErrorTotal', val);
    Promise.resolve(null).then(() => this._overRiskBudgetErrorTotalSubj.next(val));
  }

  public set portfolioSetSaving(val: boolean) {
    this.logger.debug('portfolioSetSaving', val);
    Promise.resolve(null).then(() => this._portfolioSetSavingSubj.next(val));
  }

  public set overwritePortfolioSetRequest(val: PortfolioSet) {
    this.logger.debug('overwritePortfolioSetRequest', val);
    Promise.resolve(null).then(() => this._overwritePortfolioSetRequestSubj.next(val));
  }

  public set overwritePortfoliosRequest(val: Sandbox3PortfolioMeta[]) {
    this.logger.debug('overwritePortfoliosRequest', val);
    Promise.resolve(null).then(() => this._overwritePortfoliosRequestSubj.next(val));
  }

  public set portfoliosIncomplete(val: boolean[]) {
    this.logger.debug('portfoliosIncomplete', val);
    Promise.resolve(null).then(() => this._portfoliosIncompleteSubj.next(val));
  }

  public set portfolioReturnForecasts(val: ForecastResult[]) {
    this.logger.debug('portfolioReturnForecasts', val);
    Promise.resolve(null).then(() => this._portfolioReturnForecastsSubj.next(val));
  }

  public set portfolioReturnForecastsLoading(val: boolean) {
    this.logger.debug('portfolioReturnForecastsLoading', val);
    Promise.resolve(null).then(() => this._portfolioReturnForecastsLoadingSubj.next(val));
  }

  public set portfoliosValidationErrors(val: Map<number, ValidationErrors>) {
    this.logger.debug('portfoliosValidationErrors', val);
    Promise.resolve(null).then(() => this._portfoliosValidationErrorsSubj.next(val));
  }

  public set portfolioStatistics(val: TemporarySandboxPortfolioInfo[]) {
    this.logger.debug('portfolioStatistics', val);
    Promise.resolve(null).then(() => this._portfolioStatisticsSubj.next(val));
  }

  public set portfolioStatisticsAssetDateRanges(val: IndexDateRange[]) {
    this.logger.debug('portfolioStatisticsAssetDateRanges', val);
    Promise.resolve(null).then(() => this._portfolioStatisticsAssetDateRangesSubj.next(val));
  }

  public set portfolioStatisticsLoading(val: boolean) {
    this.logger.debug('portfolioStatisticsLoading', val);
    Promise.resolve(null).then(() => this._portfolioStatisticsLoadingSubj.next(val));
  }

  public set portfolioStatisticsTimePeriod(val: ValueChartTimePeriod) {
    this.logger.debug('portfolioStatisticsTimePeriod', val);
    Promise.resolve(null).then(() => this._portfolioStatisticsTimePeriodSubj.next(val));
  }

  public set sandboxAllocationSunburstsOpen(val: boolean) {
    this.logger.debug('sandboxAllocationSunburstsOpen', val);
    Promise.resolve(null).then(() => this._sandboxAllocationSunburstsOpenSubj.next(val));
  }

  public set sandboxAssetListError(val: Error) {
    this.logger.debug('sandboxAssetListError', val);
    Promise.resolve(null).then(() => this._sandboxAssetListErrorSubj.next(val));
  }

  public set sandboxAssetListOpen(val: boolean) {
    this.logger.debug('sandboxAssetListOpen', val);
    Promise.resolve(null).then(() => this._sandboxAssetListOpenSubj.next(val));
  }

  public set sandboxClosePortfolioMenu(val: boolean) {
    this.logger.debug('sandboxClosePortfolioMenu');
    Promise.resolve(null).then(() => this._sandboxClosePortfolioMenuSubj.next());
  }

  public set sandboxClosePortfolioForecastMenu(val: boolean) {
    this.logger.debug('sandboxClosePortfolioForecastMenu');
    Promise.resolve(null).then(() => this._sandboxClosePortfolioForecastMenuSubj.next());
  }

  public set sandboxClosePortfolioSetMenu(val: boolean) {
    this.logger.debug('sandboxClosePortfolioSetMenu');
    Promise.resolve(null).then(() => this._sandboxClosePortfolioSetMenuSubj.next());
  }

  public set sandboxEditorView(val: SandboxEditorView) {
    this.logger.debug('sandboxEditorView', val);
    Promise.resolve(null).then(() => this._sandboxEditorViewSubj.next(val));
  }

  public set sandboxInvalidNames(val: Map<number, string>) {
    this.sandboxInvalidNames$.pipe(take(1)).subscribe(lastVal => {
      if (lastVal !== val) {
        this.logger.debug('sandboxInvalidNames', val);
        Promise.resolve(null).then(() => this._sandboxInvalidNamesSubj.next(val));
      }
    });
  }

  public set sandboxPortfoliosLoading(val: Map<number, boolean>) {
    this.logger.debug('sandboxPortfoliosLoading', val);
    Promise.resolve(null).then(() => this._sandboxPortfoliosLoadingSubj.next(val));
  }

  public set sandboxPortfoliosSaving(val: Map<number, boolean>) {
    this.logger.debug('sandboxPortfoliosSaving', val);
    Promise.resolve(null).then(() => this._sandboxPortfoliosSavingSubj.next(val));
  }

  public set selectedAssets(val: SandboxAsset[][]) {
    this.logger.debug('selectedAssets', val);
    Promise.resolve(null).then(() => this._selectedAssetsSubj.next(val));
  }

  public set showDisclaimer(val: boolean) {
    this.logger.debug('showDisclaimer', val);
    Promise.resolve(null).then(() => this._showDisclaimerSubj.next(val));
  }

  public set showPortfolioPicker(val: boolean) {
    this.logger.debug('showPortfolioPicker', val);
    Promise.resolve(null).then(() => this._showPortfolioPickerSubj.next(val));
  }

  public set showPortfolioReturnForecastInValueChart(val: boolean) {
    Promise.resolve(null).then(() => this._showPortfolioReturnForecastInValueChartSubj.next(val));
  }

  public set showPortfolioSetPicker(val: boolean) {
    this.logger.debug('showPortfolioSetPicker', val);
    Promise.resolve(null).then(() => this._showPortfolioSetPickerSubj.next(val));
  }

  public set showHelpCapitalHint(val: boolean) {
    this.logger.debug('showHelpCapitalHint', val);
    Promise.resolve(null).then(() => this._showHelpCapitalHintSubj.next(val));
  }

  public set showStatistics(val: boolean) {
    this.logger.debug('showStatistics', val);
    Promise.resolve(null).then(() => this._showStatisticsSubj.next(val));
  }

  public set showStatisticsCharts(val: boolean) {
    this.logger.debug('showStatisticsCharts', val);
    Promise.resolve(null).then(() => this._showStatisticsChartsSubj.next(val));
  }

  public set showStatisticsPortfolios(val: Map<number, boolean>) {
    this.logger.debug('showStatisticsPortfolios', val);
    Promise.resolve(null).then(() => this._showStatisticsPortfoliosSubj.next(val));
  }

  public set showUserGuide(val: boolean) {
    this.logger.debug('showUserGuide', val);
    Promise.resolve(null).then(() => this._showUserGuideSubj.next(val));
  }

  public set unusedAssets(val: SandboxAsset[][]) {
    this.logger.debug('unusedAssets', val);
    Promise.resolve(null).then(() => this._unusedAssetsSubj.next(val));
  }

  ngOnDestroy() {
    [
      this._acMaxCapListSubj,
      this._acMaxVolListSubj,
      this._activeDataFrequencyIdSubj,
      this._assetExpectedReturnMapSubj,
      this._assetListDimensionsSubj,
      this._assetInfoColumnCollapsedSubj,
      this._assetInfoColumnWidthSubj,
      this._assetSettingsMapSubj,
      this._availableAssetsSubj,
      this._availableAssetsLoadingSubj,
      this._blockValueChartSubj,
      this._currentAssetAllocationsSubj,
      this._currentAssetAllocationsLiveSubj,
      this._currentAssetColorTableSubj,
      this._currentCapitalBudgetSubj,
      this._currentForecastSetSubj,
      this._currentPortfolioSubj,
      this._currentPortfolioSetSubj,
      this._currentRiskBudgetSubj,
      this._loadedPortfoliosSubj,
      this._minimizeStatisticsSubj,
      this._minimizeStatisticsChartsSubj,
      this._overBudgetErrorTotalSubj,
      this._overBudgetPortfoliosSubj,
      this._overRiskBudgetErrorTotalSubj,
      this._overwritePortfoliosRequestSubj,
      this._overwritePortfolioSetRequestSubj,
      this._portfolioReturnForecastsSubj,
      this._portfolioReturnForecastsLoadingSubj,
      this._portfolioSetSavingSubj,
      this._portfolioStatisticsSubj,
      this._portfolioStatisticsLoadingSubj,
      this._portfolioStatisticsTimePeriodSubj,
      this._sandboxAllocationSunburstsOpenSubj,
      this._sandboxAssetListErrorSubj,
      this._sandboxAssetListOpenSubj,
      this._sandboxEditorViewSubj,
      this._sandboxEditorAllocationViewSubj,
      this._sandboxClosePortfolioMenuSubj,
      this._sandboxClosePortfolioSetMenuSubj,
      this._sandboxInvalidNamesSubj,
      this._sandboxPortfoliosLoadingSubj,
      this._sandboxPortfoliosSavingSubj,
      this._selectedAssetsSubj,
      this._showDisclaimerSubj,
      this._showHelpCapitalHintSubj,
      this._showPortfolioReturnForecastInValueChartSubj,
      this._showStatisticsSubj,
      this._showStatisticsChartsSubj,
      this._showStatisticsPortfoliosSubj,
      this._showUserGuideSubj,
      this._unusedAssetsSubj,
    ].forEach((subj) => {
      subj.complete();
    });
  }
}
