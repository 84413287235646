<section class="section login-background-cover">
  <div class="container">
    <div class="columns">
      <div class="column is-offset-2 is-8">
        <div #loginCard class="card">
          <ng-container *ngIf="{ config: branding$|async } as branding">
            <div class="card-content">
              <ng-container *ngIf="branding?.config; else epBrandingLogo">
                <div *ngIf="branding?.config?.login_logo" class="login-logo">
                  <div><img src="../../static/assets/branding/{{branding?.config?.login_logo}}" height="270"></div>
                </div>
                <h1  *ngIf="branding?.config?.login_purpose_statement"  class="title purpose-statement">
                    {{branding?.config?.login_purpose_statement}}
                </h1>
              </ng-container>
              <ng-template #epBrandingLogo>
                <div class="login-logo">
                  <div><img src="../../static/assets/ep-logo-symbol_x2.png"></div>
                </div>
                <h1 class="title purpose-statement">
                    Helping people prepare for tomorrow
                </h1>
              </ng-template>            
            <div class="error" *ngIf="loginState == LoginState.failure">Login failed: wrong email address or password.</div>
            <div class="error" *ngIf="loginState == LoginState.error">Error while logging in. Try again or wait a few minutes.</div>
            <p>Please enter your credentials below.</p>
            <div class="field field-username block">
              <label class="label">Email address</label>
              <div class="control has-icons-right">
                <input #usernameInput="ngModel" [(ngModel)]="username" id="username" class="input is-medium" type="email"
                  placeholder="" (keyup.enter)="passwordControl.focus()" required spellcheck="off">
                <!-- 
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
                 -->
                <span class="icon is-small is-right">
                  <i *ngIf="!usernameInput.pristine && usernameInput.invalid" class="fa fa-times has-text-danger"></i>
                </span>
              </div>
            </div>

            <div class="field field-password block">
              <label class="label">Password</label>
              <div class="control has-icons-right">
                <input #passwordControl #passwordInput="ngModel" [(ngModel)]="password" id="password" class="input is-medium" type="password"
                  placeholder="" (keyup.enter)="onLoginClicked()" required>
                <!--
                <span class="icon is-small is-left">
                  <i class="fa fa-key"></i>
                </span>
                -->
                <span class="icon is-small is-right">
                  <i *ngIf="!passwordInput.pristine && passwordInput.invalid" class="fa fa-times has-text-danger"></i>
                </span>
              </div>
            </div>

            {{ errorMessage }}

            <div class="checkbox">
                <label>
                  <input #rememberMeInput [(ngModel)]="rememberMe" id="remember-me" type="checkbox"/>
                  <div class="checkbox__indicator"></div>
                Keep me logged in</label>
            </div>

            <br>
            <div class="buttons field">
              <div class="control">
                <button id="login-button" class="button is-primary" [ngClass]="{'is-loading': isLoading}" (click)="onLoginClicked()">
                   Log in
               </button>
               <span class="login-lost-password is-pulled-right"><a href="#" (click)="onLostPasswordClicked()">I've lost my password</a></span>
              </div>
            </div>

             
            <div *ngIf="branding$|async as branding; else clarityNotificationText" class="notification-text">
              <span *ngIf="branding?.config?.login_note_title" class="notification-text-title">{{branding?.config?.login_note_title}}</span><br/>
              <div  *ngIf="branding?.config?.login_note_content" class="notification-text-content">
                  {{branding?.config?.login_note_content}}
              </div>
            </div>
            <ng-template #clarityNotificationText>
              <div class="notification-text">
                <span class="notification-text-title">This is a beta-version of Estlander &amp; Partners Clarity platform</span><br/>
                <div class="notification-text-content">
                  <span>Please email <a href="mailto:clarity@estlanderpartners.com">clarity@estlanderpartners.com</a> for feedback.</span>
                  <span>Note that a daily maintenance is scheduled at 1 pm and that the Clarity platform may be inaccessible at and around that hour.</span>
                </div>
              </div>
            </ng-template>

            <!-- forgot password message -->
            <div class="modal" [ngClass]="{'is-active': showLostPasswordMessage}">
              <div class="modal-background"></div>
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Lost password</p>
                  <button class="delete" aria-label="close" (click)="onLostPasswordMessageDismissed()"></button>
                </header>
                <section *ngIf="branding; else claritySupport" class="modal-card-body" [innerHTML]="branding?.config?.login_support">
                </section>
                <ng-template #claritySupport>
                    <p>If you've lost your password please email 
                      <a href="mailto:clarity@estlanderpartners.com">clarity@estlanderpartners.com</a> to get a new one.</p>
                </ng-template>
                
                <footer class="modal-card-foot">
                  <button class="button is-primary" (click)="onLostPasswordMessageDismissed()">OK</button>
                </footer>
              </div>
            </div>
            <div *ngIf="!(cookiesAccepted$|async)" class="message cookie-warning">
              <div class="message-body">
                <button class="delete ok" (click)="dismissCookieWarning()">Accept & close</button>
                <div *ngIf="branding?.config?.login_cookie_warning != null; else epCookieWarning" [innerHTML]="branding?.config?.login_cookie_warning"></div>
                  <ng-template #epCookieWarning>
                    <p>Estlander & Partners uses cookies to help us improve the browsing experience for you. The cookie is stored locally on
                      your computer or mobile device. By continuing to use this site you are giving consent to the use of cookies. 
                      <a target="_blank" href="/static/assets/Privacy.Policy.pdf">Learn more.</a></p>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>