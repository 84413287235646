import { isNumber } from "lodash";
import { SandboxErrorCode } from "./sandbox-error-code.enum";

export class SandboxError extends Error {
    code = SandboxErrorCode.Error;

    constructor(message?: string, code?: SandboxErrorCode) {
        super(message);
        if (isNumber(code)) {
            this.code = code;
        }
    }
}
