export enum QuestionTypeId {
    CheckboxGroupExclusive = 1, // Only one option allowed -- use RadioButtonGroup instead
    TextInput = 2,
    SelectDropdown = 3,
    CheckboxGroupNoTitle = 5,
    CheckboxGroup = 6,
    ToggleButtonGroup = 7,   // list of buttons, only one of which can be active
    RadioButtonGroup = 8,
    Slider = 9,
    PopupTextbox = 102,
}
