import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { debounce, Debounce } from 'lodash-decorators';
import { SpinnerService } from '@chevtek/angular-spinners';
import { Subject, of, forkJoin } from 'rxjs';
import { NavbarTab } from '../shared/navbar-button/navbar-button.component';
import { isIndex, AccountDataService } from '../account-data/account-data.service';
import { map, takeUntil } from 'rxjs/operators';
import { Product, ProductRolledDailyClose, ProductIntradayPrices, DailyNetExposure, DailyDelta } from '../api/webservice.service';
import { cmpAssetClasses } from '../account-data/asset-class-sorting';
import { getOffsetStartDays, getOffsetPeriodStartDate, numDigits } from '../account-overview/utils';
import * as moment from 'moment-timezone';
import { AppService } from '../app-service.service';
import * as Highcharts from 'highcharts/highstock';
import { Point, Series, SeriesAreaOptions, SeriesLineOptions, SeriesOptions } from 'highcharts';
import { checkboxLegendOptions, deleteLegendSymbols } from '../account-overview/utils/charts';
import { LoggingService } from '../utils/logging/logging.service';
import { Location, PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-market-chart',
  templateUrl: './market-chart.component.html',
  styleUrls: ['./market-chart.component.scss']
})
export class MarketChartComponent implements OnChanges, OnDestroy, OnInit {
  @Input() set showIntraday(flag: boolean) { this._showIntraday = flag; this.showIntradayChart = flag; }
  @Input() accountId: number | string;
  @Input() set productId(value: number) {
    if (value != null) {
      this.updateMarketChart(
        value,
        null,
        this.selectedDate,
        this.marketChartSelectedType != null ? this.marketChartSelectedType.value : null
      );
    }
  }
  @Input() selectedDate: Date;
  @Input() userId: number;
  @Output() closed = new EventEmitter<boolean>(true);

  allProducts: Product[];
  chartHeight = 100;
  chartSelectedPeriod: [Date, Date] = null;
  isAnyMarketSelected = false;
  Highcharts = Highcharts; // for <highcharts-chart>
  lineColors = ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'];
  @ViewChild('marketChartDiv', { static: true }) marketChartDiv: ElementRef;
  marketChartMain: Highcharts.Chart;
  marketChartMainOptions: Highcharts.Options;
  marketChartMainUpdate = false;
  marketChartIntraday: Highcharts.Chart;
  marketChartIntradayOptions: Highcharts.Options;
  marketChartIntradayUpdate = false;
  @ViewChild('marketChartNavbar', { static: true }) marketChartNavbar: ElementRef;
  marketChartPeriodTabs = ['3 months', '6 months', '1 year', 'All'];
  marketChartTypeOptions: Array<NavbarTab> = [
    { disabled: true, label: 'Delta', value: 'delta' },
    { disabled: true, label: 'Net Exposure', value: 'netExposure' }
  ];
  marketChartSelectedType: NavbarTab;
  nextProduct: Product;
  prevProduct: Product;
  showIntradayChart = true;
  _validSecondSeriesTypes = ['delta', 'netExposure'];

  get currentProduct(): Product {
    return this._currentProduct;
  }

  // currentProduct is a property so we can make sure it is synchronized with productId
  set currentProduct(p: Product) {
    this._currentProduct = p;
    if (p == null || p.ProductId == null) {
      this.productId = null;
    }
  }

  private _currentProduct: Product;
  private _destroy$ = new Subject<void>();
  private _oldUrl: string;
  private _showIntraday: boolean;


  constructor(
    private accountData: AccountDataService,
    private appService: AppService,
    private location: Location,
    private logger: LoggingService,
    private spinnerService: SpinnerService
  ) {
    this.location.subscribe(event => {
      if (!event.url.includes('#market-chart')) {
        this.closeMarketChart();
      }
    });
  }


  // calculate proper height of market charts
  calcMarketChartHeight(): number {
    let height = 0;
    const rem = 14; // 14px font
    const vp = this.appService.viewportSize.height;
    let navHeight = 14 * rem;
    if (this.marketChartNavbar) {
      const elemHeight = this.getElementHeight(this.marketChartNavbar);
      navHeight = Math.max(elemHeight, 100);
    }

    height = vp - navHeight - 3 * rem;

    if (this.showIntradayChart) {
      height = height / 2 - 3 * rem;
    }

    return height;
  }

  public closeMarketChart(): void {
    const card: HTMLDivElement = this.marketChartDiv.nativeElement;
    this.currentProduct = null;
    setTimeout(() => {
      card.classList.add('closed');
    }, 1000);
    if (this.location.path().includes('#market-chart')) {
      if (this._oldUrl != null) {
        this.location.replaceState(this._oldUrl);
      }
    }
    this.closed.emit(true);
  }

  // Resize chart to viewport, and display
  public displayMarketChart(): void {
    const chartCard: HTMLDivElement = this.marketChartDiv.nativeElement;

    if (chartCard) { // chartElem will not exist on first load.
      chartCard.classList.remove('closed');
    }
    this._oldUrl = this.location.path();
    this.location.go(`${this.location.path()}#market-chart`);
  }

  getElementHeight(element: ElementRef | HTMLElement): number {
    let el: HTMLElement;
    if ((<ElementRef>element).nativeElement) {
      el = (<ElementRef>element).nativeElement;
    } else {
      el = <HTMLElement>element;
    }

    return el.clientHeight;
  }

  getMarketChartNavbarHeight(element: ElementRef | HTMLElement) {
    return this.getElementHeight(element) + 17 * 14;
  }

  findMarketChartTypeFromValue(value: string): NavbarTab {
    return this.marketChartTypeOptions. find(item => item.value === value);
  }

  @Debounce(500, { leading: true})
  marketChartChangeProduct(prod: Product) {
    if (prod != null) {
      this.currentProduct = prod;
      this.productId = prod.ProductId;
    }
  }

  @Debounce(500, { leading: true})
  marketChartPeriodSelect(event: { selected: string }): void {
    const endDate = this.selectedDate;
    const startDate: Date = getOffsetPeriodStartDate(endDate, event.selected);
    this.updateMarketChart(this.currentProduct.ProductId, startDate);
  }

  @Debounce(500, { leading: true})
  marketChartTypeChange(option: { selected: string }) {
    const value = option.selected;
    this.marketChartSelectedType = { label: '', value };
    if (this._validSecondSeriesTypes.indexOf(value) < 0) {
      this.updateMarketChart(this.currentProduct.ProductId, null, null, null);
    } else {
      this.updateMarketChart(this.currentProduct.ProductId, null, null, value);
    }
    return true;
  }

  compareMarketChartTypes(t1: string, t2: string) {
    return t1 === t2;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('productId' in changes && changes.productId != null) {
      this.updateMarketChart(changes.productId.currentValue);
      setTimeout(() => this.displayMarketChart());
    }
  }

  ngOnDestroy() {
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }

  ngOnInit() {
    this.appService.viewportChange$.subscribe((event) => {
          this.chartHeight = this.calcMarketChartHeight();
    });

  }

  // Set chart object. Called by highcharts-chart component
  setIntradayChartObject(chart: Highcharts.Chart) {
    this.marketChartIntraday = chart;
  }

  // Set chart object. Called by highcharts-chart component
  setMainChartObject(chart: Highcharts.Chart) {
    this.marketChartMain = chart;
  }

  updateMarketChart(productId: number, startDate: Date = null, endDate: Date = null, seriesType: string = null) {
    // clean up previous runs
    this._destroy$.next();
    if (this.marketChartMain) {
      this.marketChartMain.destroy();
      this.marketChartMain = null;
    }
    if (this.marketChartIntraday) {
      this.marketChartIntraday.destroy();
      this.marketChartIntraday = null;
    }

    if (productId == null || isNaN(productId) || this.userId == null) {
      this.isAnyMarketSelected = false;
      return;
    }
    // reset to input
    this.showIntradayChart = this._showIntraday;

    setTimeout(() => this.spinnerService.show('market-charts'));
    this.isAnyMarketSelected = true;


    let sType: string;
    if (!seriesType || this._validSecondSeriesTypes.indexOf(seriesType) < 0) {
      if ( !this.marketChartSelectedType) {
        sType = 'netExposure';
      } else {
        sType = this.marketChartSelectedType.value;
      }
    } else {
      sType = seriesType;
    }

    if (isIndex(this.accountId) || this.accountId !== 'TIM') {
      // only net exposure is available for indexes
      sType = 'netExposure';
    }

    this.marketChartSelectedType = this.findMarketChartTypeFromValue(sType);

    this.accountData.getProducts$(this.userId, this.accountId, this.selectedDate).subscribe(products => {
      this.allProducts = products;
      this.allProducts.sort((prodA, prodB) => {

        // compare asset classes by explicit ordering or alphabetically
        const acNameA = prodA.SubAssetClass.AssetClass.Name.toLowerCase();
        const acNameB = prodB.SubAssetClass.AssetClass.Name.toLowerCase();
        if (acNameA !== acNameB) {
          const acOrder = cmpAssetClasses(acNameA, acNameB, null);
          if (acOrder !== 0) {
            return acOrder;
          }
        }

        return prodA.LongName.localeCompare(prodB.LongName);
      });
      const prodIdx = this.allProducts.findIndex(p => p.ProductId === productId);
      if (prodIdx >= 0) {
        this.currentProduct = this.allProducts[prodIdx];
      } else {
        // Show first product if productId not
        this.currentProduct = this.allProducts[0];
      }
      if (this.allProducts.length <= 1) {
        // pathological case where allProducts only has one or zero products. product navbar is not shown in this case
        this.prevProduct = null;
        this.nextProduct = null;
      }
      if (prodIdx === 0) {
        // first, wrap around to last
        this.prevProduct = this.allProducts[this.allProducts.length - 1];
        this.nextProduct = this.allProducts[prodIdx + 1];
      } else if (prodIdx > 0 && prodIdx < this.allProducts.length - 1) {
        this.prevProduct = this.allProducts[prodIdx - 1];
        this.nextProduct = this.allProducts[prodIdx + 1];
      } else if (prodIdx === this.allProducts.length - 1) {
        // last, wrap around to first
        this.prevProduct = this.allProducts[prodIdx - 1];
        this.nextProduct = this.allProducts[0];
      } else if (prodIdx < 1) {
        // pathological case where selectProduct not in allProducts
        this.prevProduct = this.allProducts[0];
        this.nextProduct = this.allProducts[1];
      }

    });

    let dateEnd: Date;
    let dateStart: Date;
    if (startDate !== null) {
      dateStart = startDate;
    } else {
      if (this.chartSelectedPeriod) {
        dateStart = this.chartSelectedPeriod[0];
      } else {
        const offset = getOffsetStartDays(moment(this.selectedDate).subtract(1, 'years').toDate());
        dateStart = moment(this.selectedDate).subtract(1, 'years').subtract(offset, 'days').toDate();
      }
    }
    if (endDate !== null) {
      dateEnd = endDate;
    } else {
      if (this.chartSelectedPeriod) {
        dateEnd = this.chartSelectedPeriod[1];
      } else {
        dateEnd = moment(this.selectedDate)
          .subtract(getOffsetStartDays(moment(this.selectedDate).toDate()), 'days')
          .toDate();
      }
    }

    // save end/startDate
    this.chartSelectedPeriod = [dateStart, dateEnd];
    let productOneReturn: [number, number][];
    return forkJoin<
      ProductRolledDailyClose,
      ProductIntradayPrices,
      DailyDelta[],
      DailyNetExposure[]
    >([
      this.accountData.getRolledDailyClose$(this.userId, productId, dateStart, dateEnd),
      this.accountData.getProductIntradayPrices$(this.userId, productId),
      this.accountData.getProductDeltas$(
        this.userId,
        this.accountId,
        productId,
        dateStart,
        dateEnd
      ),
      this.accountData.getProductNetExposures$(this.userId, this.accountId, productId, dateStart, dateEnd)
    ]).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      ([ rolledDaily, intradayPrices, prodDeltas, prodNetExps, ]:
        [ ProductRolledDailyClose, ProductIntradayPrices, DailyDelta[], DailyNetExposure[], ]
      ) => {
      if (rolledDaily.ClosePricesOrdered != null && rolledDaily.Product != null) {
        productOneReturn = rolledDaily.ClosePricesOrdered.map(point => {
          return [moment.tz(point.DateTime, this.appService.TZ).valueOf(), point.Price] as [number, number];
        });
      }

      const seriesTwo: SeriesAreaOptions = {
        id: 'delta',
        type: 'area',
        yAxis: 1,
      };
      seriesTwo['tooltip'] = {
        valueSuffix: ' %',
      };
      const seriesTwoAxis = {
        enabled: true,
        endOnTick: false,
        max: 100,
        min: -100,
        opposite: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          align: 'right',
          format: '{value:.1f} %',
        },
        showLastLabel: true,
        startOnTick: false,
        tickAmount: 5,
        title: {
          text: 'Delta',
        }
      };
      if (sType === 'delta') {
        const deltas = prodDeltas.map(point => {
          return [+(new Date(point.Date)), point.Delta * 100] as [number, number];
        });
        Object.assign(seriesTwo, {name: 'Delta', data: deltas});
      } else {
        seriesTwo.id = 'netExposure';
        const netExposures = prodNetExps.map(point => {
          return [moment.tz(point.Date, this.appService.TZ).valueOf(), point.NetExposure * 100] as [number, number];
        });
        Object.assign(seriesTwo, {name: 'Net exposure', data: netExposures});
        const { exp_y_max, exp_y_min } = this.calcNetExposureLimits(netExposures);

        Object.assign(seriesTwoAxis, {
          enabled: true,
          labels: {
            format: '{value:.1f} %'
          },
          max: exp_y_max,
          min: exp_y_min,
          title: {
            text: 'Net Exposure',
          },
        });
      }

      // Restrict return series to period when seriesTwo is available
      if (seriesTwo != null && seriesTwo['data'] != null && seriesTwo.data.length > 0) {
        productOneReturn = productOneReturn.filter(item => item[0] >= seriesTwo.data[0][0]);
      }
      if (productOneReturn) {
        this.chartHeight = this.calcMarketChartHeight();
        setTimeout(
          () => {
            const [min, max] = productOneReturn.reduce((acc, curr) => {
              const acc_min = Math.min(curr[1], acc[0]);
              const acc_max = Math.max(curr[1], acc[1]);
              return [acc_min, acc_max];
            });
            const legendOptions = cloneDeep(checkboxLegendOptions);
            legendOptions.enabled = true;

            // Custom label format with tooltips explaining second series
            legendOptions.labelFormatter = function () {
              const series = this as Highcharts.Series;
              let label: string;
              if (series.visible) {
                let color = series['color'];
                if (!color) {
                  color = (series.options as Highcharts.SeriesLineOptions)['color'];
                }
                label = `<i class="fa fa-check-square" style="color: ${color}"></i> ${series.name}`;
              } else {
                label = `<i class="fa fa-square"></i> ${series.options.name}`;
              }
              if (series.options.id === 'delta') {
                label +=  ` <i class="fa fa-info-circle"
                title="Delta is an indication of the relative positioning of the strategy versus a theoretical maximum of 100%."></i`;
              } else if (series.options.id === 'netExposure') {
                label += ` <i class="fa fa-info-circle"
                title="Net exposure is underlying value of positions relative to account NAV."></i`;
              }
              return label;
            };
            this.marketChartMainOptions = {
              chart: {
                events: {
                  // Add tooltips to second series labels in legend explaining the meaning of the second series
                  render: deleteLegendSymbols,
                },
              },
              rangeSelector: {
                enabled: false,
              },
              credits: {
                enabled: false,
              },
              navigator: {
                enabled: false
              },
              exporting: {
                enabled: false
              },
              scrollbar: {
                enabled: false
              },
              legend: legendOptions,
              colors: this.lineColors,
              plotOptions: {
                area: {
                  connectNulls: false,
                  lineWidth: 0,
                  step: 'left',
                }
              },
              time: {
                timezone: this.appService.TZ,
              },
              title: {
                text: 'Price and delta',
              },
              tooltip: {
                split: false,
                shared: true,
                valueDecimals: 2,
              },
              xAxis: {
                labels: {
                  enabled: true,
                  format: '{value:%d-%m-%Y}',
                },
                type: 'datetime',
              },
              yAxis: [
                {
                  max: max + (Math.abs(max - min) * 0.1),
                  min: min - (Math.abs(max - min) * 0.1),
                  opposite: true,
                  visible: true,
                  endOnTick: false,
                  labels: {
                    align: 'center',
                  } as Highcharts.ColorAxisLabelsOptions,
                  showLastLabel: true,
                  align: 'left',
                  title: {
                    text: 'Price',
                  },
                },
                seriesTwoAxis
              ] as Highcharts.YAxisOptions[],
              series: [
                {
                  name: 'Price',
                  type: 'line',
                  data: productOneReturn,
                  zIndex: 1,
                },
                seriesTwo,
              ]
            };
            if (sType === 'netExposure') {
              this.marketChartMainOptions.title.text = 'Price and net exposure';
            }

            this.marketChartMainUpdate = true;
          }
        );
      }

      // set up second series dropdown
      const typeOptions = [
        { disabled: true, label: 'Delta', value: 'delta' },
        { disabled: true, label: 'Net Exposure', value: 'netExposure' }
      ];
      if (!isIndex(this.accountId) && this.accountId === 'TIM' && prodDeltas.length > 0) {
        typeOptions[0]['disabled'] = false;
      }
      if ( prodNetExps.length > 0) {
        typeOptions[1]['disabled'] = false;
      }
      this.marketChartTypeOptions = typeOptions;


      if (this.showIntradayChart) {
        const instrumentIntradayPrices: SeriesLineOptions[] = [];
        let mostActiveId: number;
        if (intradayPrices['InstrumentPrices'] != null) {
          mostActiveId = intradayPrices.MostActiveInstrumentId;
          for (let i = 0; i < intradayPrices.InstrumentPrices.length; i++) {
            const instrument = intradayPrices.InstrumentPrices[i].Instrument;
            const instrumentTicker = instrument.BloombergTicker;
            const instrumentPrices = intradayPrices.InstrumentPrices[i].PricesOrdered;
            const timePricePoints = instrumentPrices.map(point => {
              return [ moment.tz(point.DateTime, this.appService.TZ).valueOf(), Number(point.Price) ] as [number, number];
            });
            const iSeries = {
              name: instrumentTicker,
              data: timePricePoints,
            } as Highcharts.SeriesLineOptions;

            if (mostActiveId) {
              if (instrument.InstrumentId === mostActiveId) {
                instrumentIntradayPrices.push(iSeries);
                break;
              } else {
                // do nothing
              }
            } else {
              instrumentIntradayPrices.push(iSeries);
            }
          }
        }
        if ( mostActiveId !== 0 ) {
          const [min_y, max_y] = (<[number, number][]>instrumentIntradayPrices[0].data).reduce(
            (acc, curr) => {
              const min = Math.min(curr[1], acc[0]);
              const max = Math.max(curr[1], acc[1]);
              return [min, max];
            });
          const mciOptions: Highcharts.Options = {
            credits: { enabled: false },
            rangeSelector: { enabled: false },
            scrollbar: { enabled: false },
            legend: { enabled: false, },
            navigator: { enabled: false },
            exporting: { enabled: false },

            chart: {
              spacingLeft: 60,  // align with main market price chart
            },
            colors: this.lineColors,
            time: {
              timezone: this.appService.TZ,
            },
            title : {
              text: 'Price last five days',
            },
            tooltip: {
              valueDecimals: 2,
              pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
              split: false,
              shared: true,
            },
            xAxis: {
              type: 'datetime',
              labels: {
                enabled: true,
                format: '{value:%d-%m-%Y}',
                step: 1,
              },
              minTickInterval: moment.duration(1, 'day').asMilliseconds(),
            },
            yAxis: {
              labels: {
                align: 'left',
                reserveSpace: true,
              } as Highcharts.ColorAxisLabelsOptions,
              max: max_y + (Math.abs(max_y - min_y) * 0.1),
              min: min_y - (Math.abs(max_y - min_y) * 0.1),
              opposite: true,
              showLastLabel: true,
              tickAmount: 5,
              title: {
                text: 'Price last five days',
              },
            },
            series: instrumentIntradayPrices,
          };

          this.chartHeight = this.calcMarketChartHeight();
          setTimeout(
            () => {
              this.marketChartIntradayOptions = mciOptions;
              this.marketChartIntradayUpdate = true;
              /* this.marketChartIntraday = this.createCumulativeReturnChart(
                'marketChart2',
                instrumentIntradayPrices,
                '{value}',
                false,
                true,
                mciOptions,
              ); */
            }
          );
        } else {
          this.showIntradayChart = false;
          this.marketChartIntradayOptions = null;
          this.marketChartIntradayUpdate = true;
          setTimeout(() => {
            this.chartHeight = this.calcMarketChartHeight();
            if (this.marketChartMain != null) {
              this.marketChartMainUpdate = true;
            }
          }, 250);
        }
      }
    },
      err => {
        this.logger.error('Cannot fetch market chart data', err);
      },
      () => Promise.resolve(null).then(() => this.spinnerService.hide('market-charts'))
    );
  }

  private calcNetExposureLimits(dataSeries: [number, number][]) {
    const exposure = dataSeries.map(point => point[1]);
    exposure.sort((x, y) => x - y);
    const min_exposure = exposure[0];
    const max_exposure = exposure[exposure.length - 1];
    const exp_range = max_exposure - min_exposure;
    let exp_n_dig = numDigits(exp_range);
    const exp_n_dig_count = exp_n_dig;
    if (exp_n_dig < 0) {
      exp_n_dig = Math.pow(10, exp_n_dig);
    }
    const exp_y_min = exp_n_dig * Math.floor(min_exposure / exp_n_dig); // min_exposure rounded down to the closest factor of n_dig
    let exp_y_max = exp_n_dig * Math.round(max_exposure / exp_n_dig); // max_exposure rounded up to the closest factor of n_dig
    exp_y_max = exp_y_max + (exp_y_max - exp_y_min);

    /*
    // always include y = 0 in limits
    if (exp_y_max < 0) {
      exp_y_max = 0;
    }
    if (exp_y_min > 0) {
      exp_y_min = 0;
    }
    */

    const abs_max = Math.max(
      Math.abs(min_exposure), Math.abs(max_exposure)
    ) + Math.abs(exp_range) * 0.1;

    return { exp_y_max: abs_max, exp_y_min: -abs_max };
  }
}
