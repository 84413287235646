import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Investor from '../quizes/Investor.json';
import * as NonprofitBoard from '../quizes/NonprofitBoard.json';
import * as Private1 from '../quizes/Private1.json';
import * as Company1 from '../quizes/Company1.json';
import * as AMLCompany from '../quizes/AMLCompany.json';
import * as AMLPrivate1 from '../quizes/AMLPrivate1.json';
import * as Upgrade from '../quizes/Upgrade.json';
import * as Experience from '../quizes/Experience.json';
import * as ExperiencePro from '../quizes/ExperiencePro.json';
import * as Semiprofessional from '../quizes/Semiprofessional.json';
import * as Amateur from '../quizes/Amateur.json';
import * as LargerShareHolder from '../quizes/LargerShareHolder.json';
import * as Behalfofother from '../quizes/Behalfofother.json';
import * as Done from '../quizes/Done.json';
import * as UpgradedToProfessional from '../quizes/UpgradedToProfessional.json';
import * as UpgradedToSemiProfessional from '../quizes/UpgradedToSemiProfessional.json';
import * as AreYouSemiproffesional from '../quizes/AreYouSemiproffesional.json';
import * as AmateurDisclaimer from '../quizes/AmateurDisclaimer.json';
import * as ProDone from '../quizes/ProDone.json';
import * as Private2 from '../quizes/Private2.json';
import * as Company2 from '../quizes/Company2.json';
import * as AMLCompany2 from '../quizes/AMLCompany2.json';
import * as Alternative from '../quizes/alternative.json';
import { Option, Question, Quiz, QuizAnswer, QuizId } from '../models';
import * as i18nISOCountries from 'i18n-iso-countries';
import { QuestionTypeId } from '../models/question-type-id.enum';
import naturalSort from '@app/natural-sort';


@Injectable()
export class QuizService {
  i18nISOCountries = i18nISOCountries;

  questionToKycMap = new Map([
    [ QuizId.Investor, new Map([
        [1, "IsPrivate"],
      ]),
    ],
    [
      QuizId.Private1, new Map([
        [2, 'Contacts:FirstName'],
        [3, "Contacts:LastName"],
        [4, "Contacts:NationalityCode"],
        [5, "Contacts:SocialSecurityNumber"],
      ])
    ],
    [
      QuizId.Private2, new Map([
        [10, "Contacts:Telephone"],
        [11, "Contacts:Email"],
        [9, "Contacts:Address"],
        [8, "Contacts:Zip"],
        [7, "Contacts:City"],
        [6, "Contacts:CountryCode"],
      ])
    ],
    [
      QuizId.AMLPrivate1, new Map([
        [1, "EstimatedIncome"],
        [2, "EconomicCommitmentsMonthly"],
        [3, "TotalAssets"],
        [5, "InvestmentOnBehalfOfOtherEntity"],
        [6, "Contacts:Pep"],
        [7, "Contacts:PepDescription"],
        [10, "InvestmentObjective"],
        [11, "InvestmentObjective"],
        [8, "OriginOfInvestedAssests"],
        [9, "OriginOfInvestedAssests"],
        [12, "InvestmentSize"],
      ])
    ],
    [ QuizId.Upgrade, new Map([
        [1, "IsProfessional"],
      ]),
    ],
    [ QuizId.AmateurDisclaimer, new Map([
        [274, "AgreementToAdvice"],
      ]),
    ],
    [ QuizId.Amateur, new Map([
        [1, "Age"],
        [3, "OpinionOnExperience"],
        [4, "RiskTolerance"],
        [5, "ReturnGoal"],
        [6, "VolatilityGoal"],
        [7, "InvestmentHorison"],
        [8, "BadNewsTolerance"],
      ]),
    ],
    [ QuizId.AreYouSemiprofessional, new Map([
      [1, "IsSemiprofessional"],
    ]),
  ],
  ]);

  private _answerCache = new Map<number, Map<number, QuizAnswer>>();

  constructor(private http: HttpClient) { 
    const i18nISOCountriesLocaleEn = require('i18n-iso-countries/langs/en.json');
    this.i18nISOCountries.registerLocale(i18nISOCountriesLocaleEn);
  }

  clearAnswerCache(quizId?: QuizId) {
    if (quizId == null) {
      this._answerCache.clear();
    }
    else {
      for (const k of this._answerCache.keys()) {
        if (k >= quizId) {
          this._answerCache.delete(k);
        }
      }
    }
  }
  
  dumpAnswerCache() {
    console.debug('QuizAnswerCache', this._answerCache);
  }

  findAnswerInQuestion(question: Question) {
    switch (question.questionTypeId) {
      case QuestionTypeId.CheckboxGroupExclusive:
      case QuestionTypeId.ToggleButtonGroup:
      case QuestionTypeId.RadioButtonGroup:
        const opt = question.options.find(x => x.selected)
        if (typeof opt === "undefined") {
          return "";
        } else {
          return opt.name;
        }
      break;
    case QuestionTypeId.SelectDropdown:
      const selectedOption = question.options.find(opt => opt.selected);
      if (selectedOption === undefined) {
        if (question.options.length > 1 && question.options[1].name === "No") {
          return false;
        } else {
          return "";
        }
      }
      if (selectedOption.name === "No") { return false; }
      if (selectedOption.name === "Yes") { return true; }
      if (selectedOption.value != null) {
        return selectedOption.value;
      }
      return selectedOption.name;
      break;
    case QuestionTypeId.CheckboxGroupNoTitle:
    case QuestionTypeId.CheckboxGroup:
      let checkedOptions = question.options.filter(x => x.selected);
      return checkedOptions;
      break;
    case QuestionTypeId.Slider:
      return question.options[0].value !== undefined ? question.options[0].value : null;
      break;
    default:
      return question.options[0].name;
    }
  }

  get(quizId: QuizId) {
    const allQuizes = this.getAll();
    const item = allQuizes.find((item) => item.quiz.id === quizId);
    if (item == null || item.quiz == null) {
      return null;
    } else {
      const quiz = item.quiz;
      for (const q of quiz.questions) {
        // Handle special case for country selectors
        if (
          q.questionTypeId === QuestionTypeId.SelectDropdown
          && q.options.length > 0
          && q.options[0].name === '__COUNTRIES__') {
          const countriesObj = this.getCountries();
          const country_options = [] as Option[];
          for (const alpha2 in countriesObj) {
            country_options.push({
              id: country_options.length,
              questionId: q.id,
              name: countriesObj[alpha2],
              selected: false,
              value: alpha2,
            });
          }
          country_options.sort((a, b) => {
            const highlight = ['FI', 'SE', 'DE', 'US'];
            const highlight_index_a = highlight.findIndex((a2) => a2 === a.value);
            const highlight_index_b = highlight.findIndex((a2) => a2 === b.value);
            if (highlight_index_a < 0 && highlight_index_b < 0) {
              return naturalSort(a.name, b.name);
            }
            if (highlight_index_a >= 0 && highlight_index_b >= 0) {
              return highlight_index_a - highlight_index_b;
            }
            if (highlight_index_a >= 0 && highlight_index_b < 0) {
              return -1;
            }
            if (highlight_index_a < 0 && highlight_index_b >= 0) {
              return 1;
            }
          });
          q.options = country_options;
        }
      }
  
      return item.quiz;
    }
  }

  getCachedAnswer(quizId: QuizId, questionId: number): QuizAnswer {
    if (quizId == null || !this._answerCache || !this._answerCache.has(quizId)) {
      return undefined;
    }
    const quizAnswerCache = this._answerCache.get(quizId);
    return quizAnswerCache.get(questionId);
  }

  getCachedAnswerList(quizId: QuizId): QuizAnswer[] {
    if (quizId == null || !this._answerCache || !this._answerCache.has(quizId)) {
      return undefined;
    }
    const quizAnswerCache = this._answerCache.get(quizId);
    // get all answers for question
    return Array.from(quizAnswerCache.values());
  }
  
  getCountries() {
    return this.i18nISOCountries.getNames('en');
  }

  getAll() {
    return [
      { quiz: NonprofitBoard, name: 'Boardmembers' }, //0
      { quiz: Investor, name: 'Investor' }, //1
      { quiz: Private1, name: 'Private1' },//2
      { quiz: Company1, name: 'Company1' },//3
      { quiz: AMLCompany, name: 'Company AML' },//4
      { quiz: AMLPrivate1, name: 'Private AML' },//5
      { quiz: Upgrade, name: 'Upgrade' },//6
      { quiz: Experience, name: 'Experience' },//7
      { quiz: Semiprofessional, name: 'Semiprofessional' },//8
      { quiz: Amateur, name: 'Amateur' },//9
      { quiz: LargerShareHolder, name: 'LargeShareholder' },//10
      { quiz: Behalfofother, name: 'Behalfofother' },//11
      { quiz: Done, name: 'Done' },//12
      { quiz: UpgradedToProfessional, name: 'UpgradedToProfessional' },//13
      { quiz: UpgradedToSemiProfessional, name: 'UpgradedToSemiProfessional' },//14
      { quiz: AreYouSemiproffesional, name: 'AreYouSemiproffesional' },//15
      { quiz: AmateurDisclaimer, name: 'AmateurDisclaimer' },//16
      { quiz: Alternative, name: 'alternative' },//17
      { quiz: ProDone, name: 'ProDone' },//18
      { quiz: Private2, name: 'Private2' },//19
      { quiz: Company2, name: 'Company2' },//20
      { quiz: AMLCompany2, name: 'Company AML2' }, //21
      { quiz: ExperiencePro, name: 'Experience (Professional)' } //22
    ].map((q) => {
      q.quiz = new Quiz(q.quiz);
      return q as {
        quiz: Quiz;
        name: string;
      };
    });
  }

  initAnswerCache(inital: [number, Map<number, QuizAnswer>][] = []) {
    this._answerCache = new Map<number, Map<number, QuizAnswer>>(inital);
  }

  setCachedAnswer(quizId: number, questionId: number, newAnswer: QuizAnswer) {
    let quizAnswerCache = this._answerCache.get(quizId);
    if (quizAnswerCache == null) {
      quizAnswerCache = new Map();
      this._answerCache.set(quizId, quizAnswerCache);
    }
    const cachedAnswer = quizAnswerCache.get(questionId);
    quizAnswerCache.set(questionId, newAnswer);
  }
}


