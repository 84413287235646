<div
  #quizWrapper
  class="quiz"
  [class.simplified]="quiz?.config?.simplifiedDisplay"
  [class.clickthrough]="quiz?.questions?.length === 1 && quiz?.questions[0]?.questionTypeId === QuestionTypeId.ToggleButtonGroup"
  [ngClass]="quiz && quiz.config && quiz.config.theme ? quiz.config.theme : null"
  >
  <div *ngIf="loading" class="loading">
    Loading… <app-ui-loading-indicator-inline></app-ui-loading-indicator-inline>
  </div>
    <div *ngIf="mode=='quiz' && quiz != null" class="questions">
      <h2 class="quiz-name">{{quiz?.name}}</h2>
      <div *ngIf="quiz?.description" class="quiz-description">
        <div *ngIf="quiz?.config?.richText; else plainDescription" [innerHtml]="quiz.description"></div>
        <ng-template #plainDescription>
          {{quiz?.description}}
        </ng-template></div>
      <div
          *ngFor="let question of filteredQuestions; count as questionCount; first as firstQ; last as lastQ"
          class="question"
          [ngClass]="{
            'first-question': firstQ,
            'last-question': lastQ,
            'single': questionCount === 1
          }"
          [ngStyle]="{ 'marginTop': questionCount === 1 && (question.questionTypeId === 7 || question.questionTypeId === 9) ? 'auto' : null}"
      >
      <!--           
-->
        <ng-template [ngIf]="question.questionTypeId === 1">
          <!-- List of checkboxes -->
          <div class="fields">
            <div  *ngFor="let option of question.options" class="field">
              <label class="label question-name" for="question-{{question.id}}-option-{{option.id}}">
                {{question?.name}}
              </label>
              <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
              <input
                id="question-{{question.id}}-option-{{option.id}}"
                type="checkbox"
                [(ngModel)]="option.selected"
                (change)="onSelect(question, option);"> {{option.name}}
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 2">
          <!-- text input field -->
          <div class="fields">
            <div class="field">
              <label class="label question-name" for="question-{{ question.id }}-input">
                <span [innerHtml]="question.name"></span>
                <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
              </label>
              <input
                class="input"
                id="question-{{ question.id }}-input"
                [required]="quiz.isRequired(question)"
                type="text"
                [(ngModel)]="question.options[0].name"
                placeholder="{{ question.placeholdertext }}"
                (change)="onText(question, question.options[0].name);" />
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 3">
          <!-- select dropdown -->
          <div class="fields">
            <div class="field">
              <label id="question-{{question?.id}}-label" for="question-{{question?.id}}-select" class="question-name">
                {{question?.name}}
                <app-help-popup *ngIf="question && question.infoPopup" [centeredOn]="quizWrapper">
                  <div [innerHtml]="question.infoPopup" class="question-info-popup"></div>
                </app-help-popup>
                <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
                </label>
                <mat-select
                  id="question-{{question?.id}}-select"
                  class="input"
                  aria-labelledby="question-{{question?.id}}-label"
                  [placeholder]="question.placeholdertext"
                  [required]="quiz.isRequired(question)"
                  (selectionChange)="onSelect(question, null, $event)"
                  [value]="question.selectedOption() != null ? question.selectedOption().id : null">
                  <mat-option *ngFor="let option of question.options" [value]="option.id">{{ option.name }}</mat-option>
                </mat-select>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 5">
          <!-- list of checkboxes, no title? -->
          <div class="fields">
            <div class="field" *ngFor="let option of question.options">
                <label class="label question-name" for="option-{{option.id}}">
                  {{ question?.name }}
                  <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
                </label>
                <input
                  id="option-{{option.id}}"
                  type="checkbox"
                  [(ngModel)]="option.selected"
                  (change)="onSelect(question, option);"> {{option.name}}
              </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 6">
          <!-- list of checkboxes-->
          <h3 class="question-name">
            <span [innerHTML]="question.name"></span>
            <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
          </h3>
          <div class="fields">
            <div class="field" *ngFor="let option of question.options">
                <mat-checkbox
                  [checked]="option.selected"
                  (change)="onSelect(question, option)"
                >
                  <span class="wrap-mat-label">
                    {{option.name}}
                  </span>
                </mat-checkbox>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 7">
          <!-- Push buttons for single question multiple choice quiz -->
          <h3 *ngIf="!(quiz?.config?.richText)" class="question-name">
            <span [innerHTML]="question?.name"></span>
            <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
          </h3>
          <div *ngIf="quiz?.config?.richText" class="question-name">
            <div class="content" [innerHTML]="question?.name"></div>
            <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
          </div>
          <div class="fields buttons">
              <button
                *ngFor="let option of question.options; first as first"
                type="button"
                (click)="onClick(question, option)"
                class="button"
                [class.selected]="option.selected"
                [class.is-info]="question.options.length === 1"
                [value]="option.id">
                {{option.name}}
              </button>
          </div>
          <div *ngIf="question?.description" class="info">
            {{question.description}}
              <app-help-popup *ngIf="question && question.infoPopup" [centeredOn]="quizWrapper">
                  <div [innerHtml]="question.infoPopup" class="question-info-popup"></div>
              </app-help-popup>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 8">
          <!-- radio button list-->
          <h3 class="question-name">
            <label id="question-{{question.id}}-group-label" [innerHTML]="question.name"></label>
            <app-help-popup *ngIf="question && question.infoPopup" [centeredOn]="quizWrapper">
              <div [innerHtml]="question.infoPopup" class="question-info-popup"></div>
            </app-help-popup>
            <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
          </h3>
          <div class="fields">
            <mat-radio-group
              class=""
              aria-labelled-by="question-{{question.id}}-group-label"
              (change)="onRadio(question, $event)"
              name="question-{{question?.id}}-group"
              >
                <mat-radio-button
                  *ngFor="let option of question.options"
                  #radioBtn
                  id="radio-{{question?.id}}-{{option?.id}}"
                  class="field"
                  [checked]="option.selected"
                  [value]="option.id"
                ><span class="wrap-mat-label">{{option?.name}}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 9">
          <!-- slider -->
          <div class="slider-question">
            <div
              *ngIf="(question?.options?.length > 0 && question?.options[0]?.config) as config"
              class="field slider">
              <h3 class="question-name">
                <label id="question-{{question.id}}-label" [innerHTML]="question.name"></label>
                <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
                <div *ngIf="config?.unit === 'euro'" class="slider-amount">{{
                  calculateSliderDisplayValue(
                    slider.value,
                    config?.min || 0,
                    config?.middle || 0.5,
                    config?.max || 1
                  )|currency:"EUR":"symbol":config?.digitInfo || '1.0-0':"fi"
                }}</div>
                <div *ngIf="config?.unit !== 'euro'" class="slider-amount">{{
                  calculateSliderDisplayValue(
                    slider.value,
                    config?.min || 0,
                    config?.middle || 0.5,
                    config?.max || 1
                  )|number:config?.digitInfo || '1.0-0'}} {{config?.unit}}</div>
              </h3>
              <mat-slider
                #slider
                aria-labelledby="question-{{question.id}}-label"
                (change)="onSlider(question, $event)"
                id="question-{{question.id}}"
                [min]="0"
                [max]="1"
                [step]="0.0001"
                thumbLabel="false"
                [value]="question?.options && question.options.length
                  && calculateSliderActualValue(
                    question.options[0].value, 
                    config?.min || 0,
                    config?.middle || 0.5,
                    config?.max || 1
                  ) || null"></mat-slider>
                <p *ngIf="config?.minLabel" class="min-label">{{config.minLabel}}</p>
                <p *ngIf="config?.maxLabel" class="max-label">{{config.maxLabel}}</p>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 102">
          <!-- text field in modal dialog - activated by setting showModal = question.id -->
          <div class="modal single-question" [class.is-active]="showQuestionModal === question.id">
            <div class="modal-background" (click)="showQuestionModal = null"></div>
            <div class="modal-content">
              <label class="label question-name" for="question-{{ question.id }}-input">
                {{ question.name }}
                <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
              </label>
              <textarea
                  class="textarea"
                  id="question-{{ question.id }}-input"
                  rows="3"
                  type="text"
                  [(ngModel)]="question.options[0].name"
                  placeholder="{{ question.placeholdertext }}"
                  (change)="onText(question, question.options[0].name);"
                ></textarea>
              <button (click)="showQuestionModal = null" type="button" class="button" style="text-align: right">OK</button>
              <button class="modal-close" (click)="showQuestionModal = null" aria-label="close"></button>
            </div>
          </div>
        </ng-template>
        <app-kyc-sustainability-estimate
          *ngIf="quiz.id === QuizId.Amateur && question.id === 1"
        ></app-kyc-sustainability-estimate>
        <!--<ng-template [ngIf]="question.questionTypeId === 4">
          <h3 class="font-weight-normal question-name">
            <span [innerHTML]="question.name"></span>
          </h3>
          <div class="row text-left options">
            <div class="col-6">
              <div class="option">
                <label class="font-weight-normal" >
                    <span>R</span>
                    <mat-slider min="0" max="255" [(ngModel)]="question.options[0].name" aria-label="red" id="red-slider">
                    </mat-slider>
                      <input id="{{question.id}}" type="number" [(ngModel)]="question.options[0].name" aria-label="red" aria-controls="red-slider">
                </label>
              </div>
            </div>
          </div>
        </ng-template>-->
      </div>


        <!--<button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(0);">First</button>-->
        <!--<button class="btn btn-default" *ngIf="config.allowBack" (click)="goTo(pager.count - 1);">Last</button>-->
        <!--<pagination *ngIf="config.showPager" direction-links="false" total-items="totalItems" items-per-page="itemsPerPage" ng-model="currentPage" ng-change="pageChanged()"></pagination>-->
    </div>
    <ng-template [ngIf]="subQuiz != null">
      <!-- sub quiz in modal dialog - activated by setting subQuiz to non-nullish value -->
      <app-kyc-sub-quiz [quiz]="subQuiz" [startCount]="subQuiz?.config?.repeat || 1" (answered)="onSubQuizSubmit(subQuiz, $event)">
      </app-kyc-sub-quiz>
    </ng-template>
</div>
<div class="steps">
  <ul *ngIf="progress(quiz) as prog">
    <li class="nav-button">
      <button
        class="button"
        [disabled]="quiz?.id === firstQuiz && pager?.index === 0"
        type="button"
        (click)="goTo( pager.index > 0 ? pager.index - pager.size : -1);"
        title="Previous">
        <span class="icon"><i class="fa fa-arrow-left"></i></span></button>
    </li>
    <!-- Test balls
    <li [attr.title]="'full'" class="step">
      <app-ui-percent-circle color="#f3ecdc" width="34px" [value]="1" class="full"></app-ui-percent-circle>    
    </li>
    <li *ngFor="let w of [0.825, 0.75, 0.67, 0.5, 0.33, 0.25, 0.125]" [attr.title]="'partial'" class="step">
      <app-ui-percent-circle color="#f3ecdc" width="34px" [value]="w" class="partial"></app-ui-percent-circle>    
    </li>
    <li [attr.title]="'empty'" class="step">
      <app-ui-percent-circle color="#f3ecdc" width="34px" [value]="0" class="empty"></app-ui-percent-circle>    
    </li>
    -->
    <li *ngFor="let sect of prog?.sections; index as i" class="step">
      <app-ui-percent-circle
        *ngIf="i < prog?.sectionIndex" color="#f3ecdc" width="36px" [value]="1" class="full"></app-ui-percent-circle>    
      <app-ui-percent-circle
        *ngIf="prog?.partIndex >= 0 && i === prog?.sectionIndex"
        color="#f3ecdc" width="36px"
        [value]="(prog?.sectionParts?.length > 1) ? (prog?.partIndex / prog?.sectionParts?.length) : 1"
        class="partial"></app-ui-percent-circle>    
      <app-ui-percent-circle
        *ngIf="i === prog?.sectionIndex && prog?.partIndex < 0 || i > prog?.sectionIndex"
        color="#f3ecdc" width="36px" [value]="0" class="empty"></app-ui-percent-circle>    

      <!--
  
      <span *ngIf="i < prog?.sectionIndex" class="filled">Filled</span>
      <span *ngIf="prog?.partIndex >= 0 && i === prog?.sectionIndex" class="partial">
        {{ (prog?.sectionParts?.length > 1) ? (prog?.partIndex / prog?.sectionParts?.length - 1) : 1 | percent }}
      </span>
      <span *ngIf="prog?.partIndex < 0 && i >= prog?.sectionIndex" class="empty">Empty</span>
    -->
    </li>
    <li class="nav-button">
      <button
        class="button"
        type="button"
        (click)="nextPage()"
        title="Next">
        <span class="icon"><i class="fa fa-arrow-right"></i></span>
      </button>
    </li>
  </ul>
</div>
<!--
<div class="card">
  <div class="card-content">
    <div>progress <code>{{ progress(quiz)|json}}</code></div>
    <div>quiz <code>{{ quiz|json}}</code></div>
    <div>allAnswers <code>{{ allAnswers|json}}</code></div>
  </div>
</div>
-->