import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-percent-circle',
  templateUrl: './ui-percent-circle.component.html',
  styleUrls: ['./ui-percent-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPercentCircleComponent implements OnInit {
  @Input() color: string = 'red';
  @Input() label: string = '';
  @Input() value: number = 0; // 0...1
  @Input() width: string = '100px';

  constructor() { }

  ngOnInit(): void {
    // console.table({ color: this.color, label: this.label, value: this.value });
  }

}
