
import { Injectable } from '@angular/core';
import { LoggingService } from 'app/utils/logging/logging.service';
import { Observable, Subject, throwError, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClientService } from '../http-client.service';
import {
  Account,
  AccountDailyPerformanceOverview,
  AccountDailyRiskOverview,
  AccountGammaAndDeltaOverview,
  AccountHoldingsOverview,
  AccountInstrumentIntradayPerformance,
  AccountIntradayPerformance,
  AccountIntradayPerformanceRiskOverview,
  AccountMarginOverview,
  AccountOverviewInstruments,
  AccountStrategyInstrumentIntradayPerformance,
  AccountStrategyIntradayPerformance,
  AccountStyleGammaAndDeltaOverview,
  AccountStyleHoldingsOverview,
  AccountStyleMarginOverview,
  AccountStylePerformanceOverview,
  AccountStyleRiskOverview,
  AccountStyleVaROverview,
  AccountStyleVolatilityOverview,
  AccountTradingLevel,
  AccountVaROverview,
  AccountVolatilityOverview,
  AssetClass,
  AssetClassCumulativeReturns,
  AssetClassDailyHoldings,
  AssetClassDeltas,
  AssetClassExposures,
  AssetClassGammas,
  AssetClassIntradayDetails,
  AssetClassIntradayPerformance,
  AssetClassIntradayPerformances,
  AssetClassMarginRequirement,
  AssetClassSignalStrength,
  AssetClassValueAtRiskHistorical,
  AssetClassVaRAndDirection,
  AssetClassVaRDetails,
  AssetClassVolatilityDetails,
  CombinedIndex,
  CorrelationPoint,
  CostType,
  CumulativeSlippage,
  DailyDelta,
  DailyGamma,
  DailyNetExposure,
  DailyReturnData,
  DataFrequency,
  DATA_FREQ_ID,
  datetime,
  DeltaOverview,
  DrawdownPoint,
  ExAnteVolatility,
  ExecutedTrade,
  GammaOverview,
  guid,
  Index,
  IndexContract,
  IndexDailyPerformanceOverview,
  IndexDailyRiskOverview,
  IndexDateRange,
  IndexGammaAndDeltaOverview,
  IndexHoldingsOverview,
  IndexInstrumentIntradayPerformance,
  IndexIntradayPerformance,
  IndexIntradayPerformanceRiskOverview,
  IndexMarginOverview,
  IndexPerformanceInfo,
  IndexVaROverview,
  IndexVolatilityOverview,
  InstrumentIntradayPrices,
  int,
  IntradayPerformanceDetails,
  IntradayPerformancePoint,
  LandingPageDailyData,
  LandingPageData,
  MarginRequirement,
  MarketIntradayReturn,
  MemberIndexWeight,
  MemberRiskWeight,
  ModifiedIndex,
  Order,
  PerformanceAndCorrelationInfo,
  PortfolioProfile,
  ProductDelta,
  ProductExposure,
  ProductIntradayDetails,
  ProductIntradayPrices,
  ProductMarginRequirement,
  ProductPerformanceAggregations,
  ProductRolledDailyClose,
  ProductVaR,
  ProductVolatility,
  RealTrackStartDate,
  ReferencePriceType,
  SignalDirection,
  StrategyCumulativeReturns,
  StrategyStyleCumulativeReturns,
  StrategyStyleIntradayPerformanceRiskOverview,
  StrategyStyleIntradayPerformances,
  SubAssetClassExposures,
  SubAssetClassGammaAndDelta,
  SubAssetClassIntradayDetails,
  SubAssetClassMarginRequirement,
  SubAssetClassPerformanceAggregations,
  SubAssetClassVaRDetails,
  SubAssetClassVolatilityDetails,
  TemporaryCombinedIndex,
  TemporaryModifiedIndex,
  VaRPoint,
  VolatilityPoint
} from './psp-backend.models';
import {
  Companies,
  Contacts,
  KYC,
  PrivateKYC,
  CorporateKYC,
} from './kyc.models';
import {
  ForecastResult,
  ForecastSet,
  MemberAssetWeight,
  SandboxAsset,
  SandboxPortfolio,
  SandboxPortfolioForecastsRequest,
  SandboxPortfolioParameters,
  SandboxPortfoliosCorrelations,
  TemporarySandboxPortfolioInfo
} from './sandbox3.models';
import * as json5 from 'json5';
import { HttpErrorResponse } from '@angular/common/http';
export * from './psp-backend.models';
export * from './sandbox3.models';

function copyObjectDataToInstance(objectData: object, instance) {
  Object.keys(objectData).map(key => {
    const value = objectData[key];
    instance[key] = objectData[key];
  });
  return instance;
}

@Injectable()
export class PspWebService {

  currentDateTime: Date;
  dateChange: Subject<Date> = new Subject<Date>();

  constructor(
    private httpClient: HttpClientService,
    private logger: LoggingService,
  ) {
    this.updateDateTime();
  }

  KycGetCompanies(postData: { UserId: number }): Observable<Companies[]|Error> {
    return this.httpClient.post<Companies[]>('/api/v2/PostCompanies/', postData);
  }

  KycStoreData(url: string, postData: Partial<KYC>): Observable<boolean|Error> {
    return this.httpClient.post<boolean>(url, postData);
  }

  KycUpdateKyc(url: string, postData: KYC): Observable<boolean | Error> {
    return this.httpClient.post<boolean>(url, postData);
  }

  KycUpdatePrivateKyc(postData: PrivateKYC): Observable<boolean | Error> {
    const url = '/api/v2/StorePrivateKYC/';
    return this.httpClient.post<boolean>(url, postData);
  }

  KycUpdateCorporateKyc(url: string, postData: CorporateKYC): Observable<boolean | Error> {
    return this.httpClient.post<boolean>(url, postData);
  }

  KycGetContact(UserId: number): Observable<Contacts | Error> {
    return this.httpClient.post<Contacts>('/api/v2/KycGetContact/', {UserId});
  }

  KycGetCompany(UserId: number, EntityId: number): Observable<Companies | Error> {
    return this.httpClient.post<Companies>('/api/v2/KycGetCompany/', {UserId, EntityId});
  }
  /*
  getStoreContacts(
    UserId: number,
    Address: string,
    City: string,
    CountryCode: string,
    Email: string,
    EntityId: number,
    FirstName: string,
    KYC,
    LastName: string,
    NationalityCode: string,
    Pep: boolean,
    PepDescription: string,
    SocialSecurityNumber: string,
    Telephone: string,
    Zip: string
  ): Observable<any> {
    const url = `/api/v2/StoreContacts/`;
   const postData = {
     "Address": Address,
     "City": City,
     "CountryCode": CountryCode,
     "Email": Email,
     "EntityId": EntityId,
     "FirstName": FirstName,
     "KYC": KYC,
     "LastName": LastName,
     "UserId": UserId,
     "NationalityCode": NationalityCode,
     "Pep": Pep,
     "PepDescription": PepDescription,
     "SocialSecurityNumber": SocialSecurityNumber,
     "Telephone": Telephone,
     "Zip": Zip
   }
    return this.httpClient.post<any>(url, postData);
  }

  getStoreCorporateKYC(
    UserId: number,
    Balance,
    DoesSomebodyOwnMoreThen25Percent,
    EntityType,
    Equity,
    HowMuchDoYouPlanToInvest,
    InvestmentObjective,
    IsSomeoneOfTheBeneficiariesPEP,
    MoreThen25PercentOwners,
    NonProfitOrganisation,
    OwnInvestment,
    PepOwners,
    ProfitLoss,
    SourceOfAssetsUsedForTheInvestment,
    Turnover
  ): Observable<any>{
      const url = '/api/v2/StoreCorporateKYC/'
    const postData = {
      "UserId": UserId,
      "Balance": Balance,
      "DoesSomebodyOwnMoreThen25Percent": DoesSomebodyOwnMoreThen25Percent,
      "EntityType": EntityType,
      "Equity": Equity,
      "HowMuchDoYouPlanToInvest": HowMuchDoYouPlanToInvest,
      "InvestmentObjective": InvestmentObjective,
      "IsSomeoneOfTheBeneficiariesPEP": IsSomeoneOfTheBeneficiariesPEP,
      "MoreThen25PercentOwners": MoreThen25PercentOwners,
      "NonProfitOrganisation": NonProfitOrganisation,
      "OwnInvestment": OwnInvestment,
      "PepOwners": PepOwners,
      "ProfitLoss": ProfitLoss,
      "SourceOfAssetsUsedForTheInvestment": SourceOfAssetsUsedForTheInvestment,
      "Turnover": Turnover,
    }
    return this.httpClient.post<any>(url, postData);
  }
  getStorePrivateKYC(
    UserId: number,
    EconomicCommitmentsMonthly,
    EstimatedIncome,
    InvestmentEntityId,
    InvestmentObjective,
    InvestmentOnBehalfOfOtherEntity,
    LonsAndOtherObligations,
    OriginOfInvestedAssests,
    TotalAssets
  ): Observable<any>{
      const url = '/api/v2/StorePrivateKYC/'
      const postData = {
          "UserId": UserId,
          "EconomicCommitmentsMonthly": EconomicCommitmentsMonthly,
          "EstimatedIncome": EstimatedIncome,
          "InvestmentEntityId": InvestmentEntityId,
          "InvestmentObjective": InvestmentObjective,
          "InvestmentOnBehalfOfOtherEntity": InvestmentOnBehalfOfOtherEntity,
          "LonsAndOtherObligations": LonsAndOtherObligations,
          "OriginOfInvestedAssests": OriginOfInvestedAssests,
          "TotalAssets": TotalAssets
      }
    return this.httpClient.post<any>(url, postData);
  }

  getStoreKYC(
    Age,
    AgreeToUpgrade,
    AgreementOnUnderstandingTheRisk,
    AgreementToAdvice,
    BadNewsTolerance,
    EntityId,
    InvestmentHorison,
    InvestmentPlanToTheseFunds,
    InvestmentSize,
    IsPrivate,
    IsProfessional,
    IsSemiprofessional,
    KYCId,
    LoanSize,
    OpinionOnExperience,
    QuantitiveRequirements,
    ReturnGoal,
    RiskTolerance,
    TotalInvestedAssets,
    UserId,
    VolatilityGoal,
    WhealthAndTotalAssets
  ): Observable<any> {
      const url = '/api/v2/StoreKYC/'
      const postData = {
        "Age":Age,
        "AgreeToUpgrade": AgreeToUpgrade,
        "AgreementOnUnderstandingTheRisk": AgreementOnUnderstandingTheRisk,
        "AgreementToAdvice": AgreementToAdvice,
        "BadNewsTolerance": BadNewsTolerance,
        "EntityId": EntityId,
        "InvestmentHorison": InvestmentHorison,
        "InvestmentPlanToTheseFunds": InvestmentPlanToTheseFunds,
        "InvestmentSize": InvestmentSize,
        "IsPrivate": IsPrivate,
        "IsProfessional": IsProfessional,
        "IsSemiprofessional": IsSemiprofessional,
        "KYCId": KYCId,
        "LoanSize": LoanSize,
        "OpinionOnExperience": OpinionOnExperience,
        "QuantitiveRequirements": QuantitiveRequirements,
        "ReturnGoal": ReturnGoal,
        "RiskTolerance": RiskTolerance,
        "TotalInvestedAssets": TotalInvestedAssets,
        "UserId": UserId,
        "VolatilityGoal": VolatilityGoal,
        "WhealthAndTotalAssets": WhealthAndTotalAssets
      }
  return this.httpClient.post<any>(url, postData);
  }

  getStoreCompanies(
    Active,
    Address,
    BussinessId,
    City,
    CountryCode,
    Domicile,
    DomicileCode,
    Email,
    EntitiesMainBusinessAccordingToTheArticlesOfAssociation,
    EntityId,
    UserId ,
    KYC,
    NameOfTheEntity,
    RepresentativeContact,
    Telephone,
    Zip,
    ): Observable<any> {
      const url = '/api/v2/StoreCompanies/'
      const postData = {
        "Active": Active,
        "Address": Address,
        "BussinessId": BussinessId,
        "City": City,
        "CountryCode": CountryCode,
        "Domicile": Domicile,
        "DomicileCode": DomicileCode,
        "Email": Email,
        "EntitiesMainBusinessAccordingToTheArticlesOfAssociation": EntitiesMainBusinessAccordingToTheArticlesOfAssociation,
        "EntityId": EntityId,
        "UserId": UserId,
        "KYC": KYC,
        "NameOfTheEntity": NameOfTheEntity,
        "RepresentativeContact": RepresentativeContact,
        "Telephone": Telephone,
        "Zip": Zip
  }
    return this.httpClient.post<any>(url, postData);
  }
  */
  getStartCombinedIndexAchillesRiskCalculation(
    userId: number,
    calculationId: guid,
    members: MemberIndexWeight[],
    fromDate: string,
    toDate: string
  ): any {
    const url = '/api/v2/StartCombinedIndexAchillesRiskCalculation/';
    const postParams = {
      userId,
      calculationId,
      members,
      fromDate,
      toDate,
    };
    return this.httpClient.post<void>(url, postParams);
  }

  updateDateTime() {
      this.currentDateTime = new Date();
      this.dateChange.next(this.currentDateTime);
  }
/*
  createUserApi(userId, email, company, password): Observable<CreateUser[]> {
    const url = `/api/v1/createAccount/`;
    const data = {
      userId,
      email,
      company,
      password
    };
    return this.httpClient.post<void>(url, data).pipe(
      map(response => {
        return response
        //return success or something?
      }));
  }*/

  getAccountAssetClassesCumulativeReturns(userId, accountId, fromInclusive, toInclusive): Observable<AssetClassCumulativeReturns[]> {
    const url = '/api/v2/AccountAssetClassesCumulativeReturns/';
    const postData = {
      userId,
      accountId,
      fromInclusive,
      toInclusive
    };
    return this.httpClient.post<AssetClassCumulativeReturns>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          return null;
        }
        this.updateDateTime();
        const value: AssetClassCumulativeReturns[] = new Array<AssetClassCumulativeReturns>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountAssetClassesDeltas(
    userId: int, accountId: string, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<AssetClassDeltas[]> {
    const url = '/api/v2/AccountAssetClassesDeltas/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassDeltas[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassDeltas[] = new Array<AssetClassDeltas>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountAssetClassesExposures(userId: int, accountId: string, date: datetime): Observable<AssetClassExposures[] | Error> {
    const url = '/api/v2/AccountAssetClassesExposures/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AssetClassExposures[]>(url, postData).pipe(
      tap(data => {
        this.updateDateTime();
      }),
    );
  }

  getAccountAssetClassesGammas(
    userId: int,
    accountId: string,
    fromDateInclusive: datetime,
    toDateInclusive: datetime
  ): Observable<AssetClassGammas[]> {
    const url = '/api/v2/AccountAssetClassesGammas/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassGammas[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassGammas[] = new Array<AssetClassGammas>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesHoldings(
    userId: int,
    accountId: string,
    fromDateInclusive: datetime,
    toDateInclusive: datetime
  ): Observable<AssetClassDailyHoldings[]> {
    const url = '/api/v2/AccountAssetClassesHoldings/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassDailyHoldings[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassDailyHoldings[] = new Array<AssetClassDailyHoldings>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesIntradayDetails(userId: int, accountId: string): Observable<AssetClassIntradayDetails[]> {
    const url = '/api/v2/AccountAssetClassesIntradayDetails/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<AssetClassIntradayDetails[]>(url, postData).pipe(
      catchError((err, caught) => {
        const cleanData = handleHttpError(err);
        return of(cleanData);
      }),
      map(data => {
        this.updateDateTime();
        const value: AssetClassIntradayDetails[] = new Array<AssetClassIntradayDetails>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountAssetClassesIntradayPerformances(userId: int, accountId: string): Observable<AssetClassIntradayPerformances[]> {
    const url = '/api/v2/AccountAssetClassesIntradayPerformances/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<AssetClassIntradayPerformances[]>(url, postData).pipe(
      catchError((err, caught) => {
        const cleanData = handleHttpError(err);
        return of(cleanData);
      }),
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassIntradayPerformances[] = new Array<AssetClassIntradayPerformances>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesMarginRequirements(userId: int, accountId: string, date): Observable<AssetClassMarginRequirement[]> {
    const url = '/api/v2/AccountAssetClassesMarginRequirements/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AssetClassMarginRequirement[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassMarginRequirement[] = new Array<AssetClassMarginRequirement>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesSignalStrengths(
    userId: int, accountId: string, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<AssetClassSignalStrength[]> {
    const url = '/api/v2/AccountAssetClassesSignalStrengths/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassSignalStrength[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassSignalStrength[] = new Array<AssetClassSignalStrength>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesVaRAndDirection(userId: int, accountId: string, date: datetime): Observable<AssetClassVaRAndDirection[]> {
    const url = '/api/v2/AccountAssetClassesVaRAndDirection/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AssetClassVaRAndDirection[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassVaRAndDirection[] = new Array<AssetClassVaRAndDirection>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesValueAtRiskDetails(
    userId: int,
    accountId: string,
    date: datetime
  ): Observable<AssetClassVaRDetails[]> {
    const url = '/api/v2/AccountAssetClassesValueAtRiskDetails/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AssetClassVaRDetails[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassVaRDetails[] = new Array<AssetClassVaRDetails>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesValueAtRiskHistorical(
    userId: number,
    accountId: string,
    fromDateInclusive: string,
    toDateInclusive: string
  ): Observable<AssetClassValueAtRiskHistorical[]> {
    const url = '/api/v2/AccountAssetClassesValueAtRiskHistorical/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassValueAtRiskHistorical[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassValueAtRiskHistorical[] = new Array<AssetClassValueAtRiskHistorical>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountAssetClassesVolatilityDetails(
    userId: int, accountId: string, date: datetime
  ): Observable<AssetClassVolatilityDetails[]> {
    const url = '/api/v2/AccountAssetClassesVolatilityDetails/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AssetClassVolatilityDetails[]>(url, postData).pipe(
      map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: AssetClassVolatilityDetails[] = new Array<AssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountCumulativeSlippage(
    userId: number,
    accountId: string,
    referencePriceTypeId: number,
    costTypeId: number,
    fromDateInclusive: string,
    toDateInclusive: string
  ): Observable<CumulativeSlippage[]> {
    const url = '/api/v2/AccountCumulativeSlippage/';
    const postData = {
      userId,
      accountId,
      referencePriceTypeId,
      costTypeId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<CumulativeSlippage[]>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CumulativeSlippage[] = new Array<CumulativeSlippage>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountCumulativeSlippageTotal(userId, accountId, referencePriceTypeId, costTypeId): Observable<CumulativeSlippage[]> {
    const url = '/api/v2/AccountCumulativeSlippageTotal/';
    const postData = {
      userId,
      accountId,
      referencePriceTypeId,
      costTypeId
    };
    return this.httpClient.post<CumulativeSlippage[]>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CumulativeSlippage[] = new Array<CumulativeSlippage>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDailyPerformanceOverview(
      userId,
      accountId,
      dateInclusive,
      nrOfTradingDaysBackDailyPerf?,
      startDatePerformanceAndDrawdown?,
      startDateBenchmarkInfo?
    ): Observable<AccountDailyPerformanceOverview> {
    const url = '/api/v2/AccountDailyPerformanceOverview/';
    const postData: any = {
      userId,
      accountId,
      dateInclusive,
    };
    if (nrOfTradingDaysBackDailyPerf) {
      postData.nrOfTradingDaysBackDailyPerf = nrOfTradingDaysBackDailyPerf;
    }
    if (startDatePerformanceAndDrawdown) {
      postData.startDatePerformanceAndDrawdown = startDatePerformanceAndDrawdown;
    }
    if (startDateBenchmarkInfo) {
      postData.startDateBenchmarkInfo = startDateBenchmarkInfo;
    }
    return this.httpClient.post<AccountDailyPerformanceOverview>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountDailyPerformanceOverview = new AccountDailyPerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDailyReturnData(userId, accountId, fromDateInclusive, toDateInclusive): Observable<DailyReturnData> {
    const url = '/api/v2/AccountDailyReturnData/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<DailyReturnData>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyReturnData = new DailyReturnData();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDailyRiskOverview(userId, accountId, date): Observable<AccountDailyRiskOverview> {
    const url = '/api/v2/AccountDailyRiskOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AccountDailyRiskOverview>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountDailyRiskOverview = new AccountDailyRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDelta(userId, accountId, date): Observable<DailyDelta> {
    const url = '/api/v2/AccountDelta/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyDelta = new DailyDelta();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDeltas(userId, accountId, fromDateInclusive: string, toDateInclusive: string): Observable<DailyDelta[]> {
    const url = '/api/v2/AccountDeltas/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive,
    };
    return this.httpClient.post<DailyDelta[]>(url, postData).pipe(
      map((data) => {
        if (data instanceof Error) {
          throw data;
        }
        const value = new Array<DailyDelta>();
        return copyObjectDataToInstance(data, value);
      }),
      tap(data => {
        this.updateDateTime();
      }),
    );
  }

  getAccountDeltaOverview(userId, accountId, date): Observable<DeltaOverview> {
    const url = '/api/v2/AccountDeltaOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<DeltaOverview>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DeltaOverview = new DeltaOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountDrawdown(userId, accountId, fromInclusive, toInclusive): Observable<DrawdownPoint[]> {
    const url = '/api/v2/AccountDrawdown/';
    const postData = {
      userId,
      accountId,
      fromInclusive,
      toInclusive
    };
    return this.httpClient.post<DrawdownPoint[]>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DrawdownPoint[] = new Array<DrawdownPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountExAnteVolatility(userId: number, accountId: string, date: datetime): Observable<ExAnteVolatility> {
    const url = '/api/v2/AccountExAnteVolatility/';
    const postData = {
      userId,
      accountId,
      date,
    };
    return this.httpClient.post<ExAnteVolatility>(url, postData).pipe(
      catchError((err, caught) => {
        const cleanData = handleHttpError<ExAnteVolatility>(err);
        return of(cleanData);
      }),
      map((data) => {
        if (data instanceof Error) {
          throw data;
        }
        const value = new ExAnteVolatility();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountExAnteVolatilityHistorical(
    userId: number, accountId: string, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<VolatilityPoint[]> {
    const url = '/api/v2/AccountExAnteVolatilityHistorical/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<VolatilityPoint[]>(url, postData).pipe(
      catchError((err, caught) => {
        const cleanData = handleHttpError<VolatilityPoint[]>(err);
        return of(cleanData);
      }),
      map(data => {
        this.updateDateTime();
        const value: VolatilityPoint[] = new Array<VolatilityPoint>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountExAnteVolatilityOverview(userId: number, accountId: string, date: datetime): Observable<AccountVolatilityOverview> {
    const url = '/api/v2/AccountExAnteVolatilityOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AccountVolatilityOverview>(url, postData).pipe(
      catchError((err, caught) => {
        const cleanData = handleHttpError<AccountVolatilityOverview>(err);
        return of(cleanData);
      }),
      map(data => {
        this.updateDateTime();
        const value: AccountVolatilityOverview = new AccountVolatilityOverview();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountGamma(userId, accountId, date): Observable<DailyGamma> {
    const url = '/api/v2/AccountGamma/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<DailyGamma>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyGamma = new DailyGamma();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountGammas(
    userId: number,
    accountId: string,
    fromDateInclusive: string,
    toDateInclusive: string
  ): Observable<DailyGamma[] | Error> {
    const url = '/api/v2/AccountGammas/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive,
    };
    return this.httpClient.post<DailyGamma[]>(url, postData).pipe(
      tap(data => {
        this.updateDateTime();
      }),
    );
  }

  getAccountGammaAndDeltaOverview(userId, accountId, date): Observable<AccountGammaAndDeltaOverview> {
    const url = '/api/v2/AccountGammaAndDeltaOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountGammaAndDeltaOverview = new AccountGammaAndDeltaOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountGammaOverview(userId, accountId, date): Observable<GammaOverview> {
    const url = '/api/v2/AccountGammaOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: GammaOverview = new GammaOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountHoldingsOverview(userId, accountId, date): Observable<AccountHoldingsOverview> {
    const url = '/api/v2/AccountHoldingsOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountHoldingsOverview = new AccountHoldingsOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountInstrumentIntradayPerformanceDetails(userId, accountId, instrumentId): Observable<IntradayPerformanceDetails[]> {
    const url = '/api/v2/AccountInstrumentIntradayPerformanceDetails/';
    const postData = {
      userId,
      accountId,
      instrumentId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IntradayPerformanceDetails[] = new Array<IntradayPerformanceDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountInstrumentLastIntradayPerformance(userId, accountId, instrumentId): Observable<AccountInstrumentIntradayPerformance> {
    const url = '/api/v2/AccountInstrumentLastIntradayPerformance/';
    const postData = {
      userId,
      accountId,
      instrumentId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountInstrumentIntradayPerformance = new AccountInstrumentIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountInstrumentsIntrdayPerformance(userId, accountId): Observable<AccountInstrumentIntradayPerformance[]> {
    const url = '/api/v2/AccountInstrumentsIntrdayPerformance/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountInstrumentIntradayPerformance[] = new Array<AccountInstrumentIntradayPerformance>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountIntradayLastPerformanceByAssetClasses(userId, accountId): Observable<AssetClassIntradayPerformance[]> {
    const url = '/api/v2/AccountIntradayLastPerformanceByAssetClasses/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassIntradayPerformance[] = new Array<AssetClassIntradayPerformance>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountIntradayLastPerformanceByStrategies(userId, accountId): Observable<AccountStrategyIntradayPerformance[]> {
    const url = '/api/v2/AccountIntradayLastPerformanceByStrategies/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStrategyIntradayPerformance[] = new Array<AccountStrategyIntradayPerformance>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountIntradayPerformanceRiskOverview(userId, accountId): Observable<AccountIntradayPerformanceRiskOverview> {
    const url = '/api/v2/AccountIntradayPerformanceRiskOverview/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountIntradayPerformanceRiskOverview = new AccountIntradayPerformanceRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountIntradayPerformances(userId: number, accountId: string): Observable<IntradayPerformancePoint[]> {
    const url = '/api/v2/AccountIntradayPerformances/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IntradayPerformancePoint[] = new Array<IntradayPerformancePoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountLastIntradayPerformance(userId, accountId): Observable<AccountIntradayPerformance> {
    const url = '/api/v2/AccountLastIntradayPerformance/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountIntradayPerformance = new AccountIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountMarginOverview(userId, accountId, date): Observable<AccountMarginOverview> {
    const url = '/api/v2/AccountMarginOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountMarginOverview = new AccountMarginOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountMarginRequirements(userId, accountId, fromDateInclusive, toDateInclusive): Observable<MarginRequirement[]> {
    const url = '/api/v2/AccountMarginRequirements/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: MarginRequirement[] = new Array<MarginRequirement>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountOverviewInstruments(userId, accountId, numberOfInstruments): Observable<AccountOverviewInstruments> {
    const url = '/api/v2/AccountOverviewInstruments/';
    const postData = {
      userId,
      accountId,
      numberOfInstruments
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountOverviewInstruments = new AccountOverviewInstruments();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountPerformanceOverview(userId, accountId, date): Observable<AccountDailyPerformanceOverview> {
    const url = '/api/v2/AccountPerformanceOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountDailyPerformanceOverview = new AccountDailyPerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductDeltas(userId, accountId, productId, fromDateInclusive, toDateInclusive): Observable<DailyDelta[]> {
    const url = '/api/v2/AccountProductDeltas/';
    const postData = {
      userId,
      accountId,
      productId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyDelta[] = new Array<DailyDelta>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductNetExposures(userId, accountId, productId, fromDateInclusive, toDateInclusive): Observable<DailyNetExposure[]> {
    const url = '/api/v2/AccountProductNetExposures/';
    const postData = {
      userId,
      accountId,
      productId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyNetExposure[] = new Array<DailyNetExposure>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductsDelta(userId, accountId, date): Observable<ProductDelta[]> {
    const url = '/api/v2/AccountProductsDelta/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductDelta[] = new Array<ProductDelta>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductsExposures(userId, accountId, date): Observable<ProductExposure[] | Error> {
    const url = '/api/v2/AccountProductsExposures/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<ProductExposure[]>(url, postData).pipe(
      tap(data => this.updateDateTime())
    );
  }

  getAccountProductsMarginRequirements(userId, accountId, date): Observable<ProductMarginRequirement[]> {
    const url = '/api/v2/AccountProductsMarginRequirements/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductMarginRequirement[] = new Array<ProductMarginRequirement>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductsPerformanceAggregations(userId, accountId, date): Observable<ProductPerformanceAggregations[]> {
    const url = '/api/v2/AccountProductsPerformanceAggregations/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductPerformanceAggregations[] = new Array<ProductPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductsValueAtRisk(userId, accountId, date): Observable<ProductVaR[]> {
    const url = '/api/v2/AccountProductsValueAtRisk/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductVaR[] = new Array<ProductVaR>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountProductsVolatilities(userId, accountId, date): Observable<ProductVolatility[]> {
    const url = '/api/v2/AccountProductsVolatilities/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductVolatility[] = new Array<ProductVolatility>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStrategyCumulativeReturns(userId, accountId, startDateInclusive, endDateInclusive): Observable<StrategyCumulativeReturns[]> {
    const url = '/api/v2/AccountStrategyCumulativeReturns/';
    const postData = {
      userId,
      accountId,
      startDateInclusive,
      endDateInclusive
    };
    return this.httpClient.post<StrategyCumulativeReturns>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: StrategyCumulativeReturns[] = new Array<StrategyCumulativeReturns>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountStrategyInstrumentLastIntradayPerformance(
    userId: int,
    accountId: int | string,
    strategyId: int,
    instrumentId: int
  ): Observable<AccountStrategyInstrumentIntradayPerformance> {
    const url = '/api/v2/AccountStrategyInstrumentLastIntradayPerformance/';
    const postData = {
      userId,
      accountId,
      strategyId,
      instrumentId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStrategyInstrumentIntradayPerformance = new AccountStrategyInstrumentIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStrategyLastIntradayPerformance(userId, accountId, strategyId): Observable<AccountStrategyIntradayPerformance> {
    const url = '/api/v2/AccountStrategyLastIntradayPerformance/';
    const postData = {
      userId,
      accountId,
      strategyId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStrategyIntradayPerformance = new AccountStrategyIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStrategyStyleCumulativeReturns(
    userId: int,
    accountId: int | string,
    fromDateInclusive: string,
    toDateInclusive: string
  ): Observable<StrategyStyleCumulativeReturns[]> {
    const url = '/api/v2/AccountStrategyStyleCumulativeReturns/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: StrategyStyleCumulativeReturns[] = new Array<StrategyStyleCumulativeReturns>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStrategyStyleIntradayPerformanceRiskOverview(
    userId: int,
    accountId: int | string,
    strategyStyleId: int
  ): Observable<StrategyStyleIntradayPerformanceRiskOverview> {
    const url = '/api/v2/AccountStrategyStyleIntradayPerformanceRiskOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: StrategyStyleIntradayPerformanceRiskOverview = new StrategyStyleIntradayPerformanceRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStrategyStyleIntradayPerformances(userId, accountId): Observable<StrategyStyleIntradayPerformances[]> {
    const url = '/api/v2/AccountStrategyStyleIntradayPerformances/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: StrategyStyleIntradayPerformances[] = new Array<StrategyStyleIntradayPerformances>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleAssetClassesCumulativeReturns(
    userId: int,
    accountId: int | string,
    strategyStyleId: int,
    fromInclusive: string,
    toInclusive: string
  ): Observable<AssetClassCumulativeReturns[]> {
    const url = '/api/v2/AccountStyleAssetClassesCumulativeReturns/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      fromInclusive,
      toInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassCumulativeReturns[] = new Array<AssetClassCumulativeReturns>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleAssetClassesIntradayPerformances(userId, accountId, strategyStyleId): Observable<AssetClassIntradayPerformances[]> {
    const url = '/api/v2/AccountStyleAssetClassesIntradayPerformances/';
    const postData = {
      userId,
      accountId,
      strategyStyleId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassIntradayPerformances[] = new Array<AssetClassIntradayPerformances>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleDailyPerformanceOverview(
    userId: int,
    accountId: int | string,
    strategyStyleId: int,
    dateInclusive: string,
    nrOfTradingDaysBackDailyPerf: int,
    startDatePerformanceAndDrawdown: string,
    startDateBenchmarkInfo: string
  ): Observable<AccountStylePerformanceOverview> {
    const url = '/api/v2/AccountStyleDailyPerformanceOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      dateInclusive,
      nrOfTradingDaysBackDailyPerf,
      startDatePerformanceAndDrawdown,
      startDateBenchmarkInfo
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStylePerformanceOverview = new AccountStylePerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleDailyReturnData(userId, accountId, strategyStyleId, fromDateInclusive, toDateInclusive): Observable<DailyReturnData> {
    const url = '/api/v2/AccountStyleDailyReturnData/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyReturnData = new DailyReturnData();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleDailyRiskOverview(userId, accountId, strategyStyleId, date): Observable<AccountStyleRiskOverview> {
    const url = '/api/v2/AccountStyleDailyRiskOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleRiskOverview = new AccountStyleRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleHoldingsOverview(userId, accountId, strategyStyleId, date): Observable<AccountStyleHoldingsOverview> {
    const url = '/api/v2/AccountStyleHoldingsOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleHoldingsOverview = new AccountStyleHoldingsOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleMarginOverview(userId, accountId, strategyStyleId, date): Observable<AccountStyleMarginOverview> {
    const url = '/api/v2/AccountStyleMarginOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleMarginOverview = new AccountStyleMarginOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStylePerformanceOverview(userId, accountId, strategyStyleId, date): Observable<AccountStylePerformanceOverview> {
    const url = '/api/v2/AccountStylePerformanceOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStylePerformanceOverview = new AccountStylePerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleProductNetExposures(
    userId,
    accountId,
    strategyStyleId,
    productId,
    fromDateInclusive,
    toDateInclusive
  ): Observable<DailyNetExposure[]> {
    const url = '/api/v2/AccountStyleProductNetExposures/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      productId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyNetExposure[] = new Array<DailyNetExposure>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleProductsPerformanceAggregations(userId, accountId, strategyStyleId, date): Observable<ProductPerformanceAggregations[]> {
    const url = '/api/v2/AccountStyleProductsPerformanceAggregations/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductPerformanceAggregations[] = new Array<ProductPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleSubAssetClassesPerformanceAggregations(
    userId,
    accountId,
    strategyStyleId,
    date
  ): Observable<SubAssetClassPerformanceAggregations[]> {
    const url = '/api/v2/AccountStyleSubAssetClassesPerformanceAggregations/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<Array<SubAssetClassPerformanceAggregations>>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: SubAssetClassPerformanceAggregations[] = new Array<SubAssetClassPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountStyleVaROverview(userId, accountId, strategyStyleId, date): Observable<AccountStyleVaROverview> {
    const url = '/api/v2/AccountStyleVaROverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleVaROverview = new AccountStyleVaROverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStyleVolatilityOverView(userId, accountId, strategyStyleId, date): Observable<AccountStyleVolatilityOverview> {
    const url = '/api/v2/AccountStyleVolatilityOverView/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleVolatilityOverview = new AccountStyleVolatilityOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountStylesGammaAndDeltaOverview(userId, accountId, strategyStyleId, date): Observable<AccountStyleGammaAndDeltaOverview> {
    const url = '/api/v2/AccountStylesGammaAndDeltaOverview/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountStyleGammaAndDeltaOverview = new AccountStyleGammaAndDeltaOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountSubAssetClassesExAnteVolatilityDetails(
    userId: number, accountId: string, date: string
  ): Observable<SubAssetClassVolatilityDetails[]> {
    const url = '/api/v2/AccountSubAssetClassesExAnteVolatilityDetails/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<SubAssetClassVolatilityDetails[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: SubAssetClassVolatilityDetails[] = new Array<SubAssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getAccountSubAssetClassesExposures(userId, accountId, date): Observable<SubAssetClassExposures[] | Error> {
    const url = '/api/v2/AccountSubAssetClassesExposures/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<SubAssetClassExposures[]>(url, postData).pipe(
      tap(data => this.updateDateTime()));
  }

  getAccountSubAssetClassesGammaAndDelta(userId, accountId, date): Observable<SubAssetClassGammaAndDelta[]> {
    const url = '/api/v2/AccountSubAssetClassesGammaAndDelta/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassGammaAndDelta[] = new Array<SubAssetClassGammaAndDelta>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountSubAssetClassesMarginRequirements(userId, accountId, date): Observable<SubAssetClassMarginRequirement[]> {
    const url = '/api/v2/AccountSubAssetClassesMarginRequirements/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassMarginRequirement[] = new Array<SubAssetClassMarginRequirement>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountSubAssetClassesPerformanceAggregations(userId, accountId, date): Observable<SubAssetClassPerformanceAggregations[]> {
    const url = '/api/v2/AccountSubAssetClassesPerformanceAggregations/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassPerformanceAggregations[] = new Array<SubAssetClassPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountSubAssetClassesValueAtRiskDetails(userId, accountId, date): Observable<SubAssetClassVaRDetails[]> {
    const url = '/api/v2/AccountSubAssetClassesValueAtRiskDetails/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassVaRDetails[] = new Array<SubAssetClassVaRDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountSubAssetClassesVolatilityDetails(userId, accountId, date): Observable<SubAssetClassVolatilityDetails[]> {
    const url = '/api/v2/AccountSubAssetClassesVolatilityDetails/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassVolatilityDetails[] = new Array<SubAssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountTradingLevel(userId: int, accountId: string, date: datetime): Observable<AccountTradingLevel | Error> {
    const url = '/api/v2/AccountTradingLevel/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<AccountTradingLevel>(url, postData);
  }

  getAccountVaROverview(userId, accountId, date): Observable<AccountVaROverview> {
    const url = '/api/v2/AccountVaROverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountVaROverview = new AccountVaROverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountValueAtRisk(userId, accountId, date): Observable<VaRPoint> {
    const url = '/api/v2/AccountValueAtRisk/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VaRPoint = new VaRPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountValueAtRiskHistorical(userId, accountId, fromDateInclusive, toDateInclusive): Observable<VaRPoint[]> {
    const url = '/api/v2/AccountValueAtRiskHistorical/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VaRPoint[] = new Array<VaRPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountVolatility(userId, accountId, date): Observable<VolatilityPoint> {
    const url = '/api/v2/AccountVolatility/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VolatilityPoint = new VolatilityPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccountVolatilityHistorical(
    userId: number, accountId: string, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<VolatilityPoint[]> {
    const url = '/api/v2/AccountVolatilityHistorical/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<VolatilityPoint[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: VolatilityPoint[] = new Array<VolatilityPoint>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAccountVolatilityOverview(userId, accountId, date): Observable<AccountVolatilityOverview> {
    const url = '/api/v2/AccountVolatilityOverview/';
    const postData = {
      userId,
      accountId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AccountVolatilityOverview = new AccountVolatilityOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAccounts(userId): Observable<Account[]> {
    const url = '/api/v2/Accounts/';
    const postData = {
      userId
    };
    return this.httpClient.post<Account[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: Account[] = new Array<Account>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getAddIndexToPortfolioProfile(userId, profileId, indexId): Observable<PortfolioProfile> {
    const url = '/api/v2/AddIndexToPortfolioProfile/';
    const postData = {
      userId,
      profileId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PortfolioProfile = new PortfolioProfile();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getAssetClasses(userId): Observable<AssetClass[]> {
    const url = '/api/v2/AssetClasses/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClass[] = new Array<AssetClass>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getBenchmarkThreeMonthCorrelations(userId, accountId, fromDateInclusive, toDateInclusive): Observable<CorrelationPoint[]> {
    const url = '/api/v2/BenchmarkThreeMonthCorrelations/';
    const postData = {
      userId,
      accountId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CorrelationPoint[] = new Array<CorrelationPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getBenchmarkThreeMonthVolatility(userId, fromDateInclusive, toDateInclusive): Observable<VolatilityPoint[]> {
    const url = '/api/v2/BenchmarkThreeMonthVolatility/';
    const postData = {
      userId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VolatilityPoint[] = new Array<VolatilityPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getCombinableIndices(userId): Observable<Index[]> {
    const url = '/api/v2/CombinableIndices/';
    const postData = {
      userId
    };
    return this.httpClient.post<Index[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: Index[] = new Array<Index>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getCombinedIndex(userId, combinedIndexId): Observable<CombinedIndex> {
    const url = '/api/v2/CombinedIndex/';
    const postData = {
      userId,
      combinedIndexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CombinedIndex = new CombinedIndex();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getCostTypes(userId, accountId): Observable<CostType[]> {
    const url = '/api/v2/CostTypes/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CostType[] = new Array<CostType>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getCreatePortfolioProfile(userId, name): Observable<PortfolioProfile> {
    const url = '/api/v2/CreatePortfolioProfile/';
    const postData = {
      userId,
      name
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PortfolioProfile = new PortfolioProfile();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getCreateTemporaryCombinedIndex(userId, name, description, members): Observable<TemporaryCombinedIndex> {
    const url = '/api/v2/CreateTemporaryCombinedIndex/';
    const postData = {
      userId,
      name,
      description,
      members
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: TemporaryCombinedIndex = new TemporaryCombinedIndex();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getCreateTemporaryModifiedIndex(userId, name, description, originalIndexId, modifiedIndexContracts): Observable<TemporaryModifiedIndex> {
    const url = '/api/v2/CreateTemporaryModifiedIndex/';
    const postData = {
      userId,
      name,
      description,
      originalIndexId,
      modifiedIndexContracts
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: TemporaryModifiedIndex = new TemporaryModifiedIndex();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getDailyTrades(userId, accountId, dateFromInclusive, dateToInclusive): Observable<ExecutedTrade[]> {
    const url = '/api/v2/DailyTrades/';
    const postData = {
      userId,
      accountId,
      dateFromInclusive,
      dateToInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ExecutedTrade[] = new Array<ExecutedTrade>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getDataFrequencies(userId): Observable<DataFrequency[]> {
    const url = '/api/v2/DataFrequencies/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DataFrequency[] = new Array<DataFrequency>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getDeletePortfolioProfile(userId: number, profileId: number): Observable<boolean> {
    const url = '/api/v2/DeletePortfolioProfile/';
    const postData = {
      userId,
      profileId
    };
    return this.httpClient.post<boolean>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        return !!data;
      })
    );
  }

  getIndexAssetClassesCumulativeReturns(userId, indexId, fromInclusive, toInclusive): Observable<AssetClassCumulativeReturns[]> {
    const url = '/api/v2/IndexAssetClassesCumulativeReturns/';
    const postData = {
      userId,
      indexId,
      fromInclusive,
      toInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassCumulativeReturns[] = new Array<AssetClassCumulativeReturns>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesDeltas(userId, indexId, fromDateInclusive, toDateInclusive): Observable<AssetClassDeltas[]> {
    const url = '/api/v2/IndexAssetClassesDeltas/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassDeltas[] = new Array<AssetClassDeltas>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesExposures(userId, indexId, date): Observable<AssetClassExposures[]> {
    const url = '/api/v2/IndexAssetClassesExposures/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassExposures[] = new Array<AssetClassExposures>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesGammas(userId, indexId, fromDateInclusive, toDateInclusive): Observable<AssetClassGammas[]> {
    const url = '/api/v2/IndexAssetClassesGammas/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassGammas[] = new Array<AssetClassGammas>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesIntradayPerformances(userId, indexId): Observable<AssetClassIntradayPerformances[]> {
    const url = '/api/v2/IndexAssetClassesIntradayPerformances/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassIntradayPerformances[] = new Array<AssetClassIntradayPerformances>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesVaRAndDirection(userId, indexId, date): Observable<AssetClassVaRAndDirection[]> {
    const url = '/api/v2/IndexAssetClassesVaRAndDirection/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassVaRAndDirection[] = new Array<AssetClassVaRAndDirection>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesValueAtRiskDetails(userId, indexId, date): Observable<AssetClassVaRDetails[]> {
    const url = '/api/v2/IndexAssetClassesValueAtRiskDetails/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassVaRDetails[] = new Array<AssetClassVaRDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClassesValueAtRiskHistorical(
    userId: int,
    indexId: int,
    fromDateInclusive: string,
    toDateInclusive: string
  ): Observable<AssetClassValueAtRiskHistorical[]> {
    const url = '/api/v2/IndexAssetClassesValueAtRiskHistorical/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<AssetClassValueAtRiskHistorical[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: AssetClassValueAtRiskHistorical[] = new Array<AssetClassValueAtRiskHistorical>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getIndexAssetClassesVolatilityDetails(userId, indexId, date): Observable<AssetClassVolatilityDetails[]> {
    const url = '/api/v2/IndexAssetClassesVolatilityDetails/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassVolatilityDetails[] = new Array<AssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexAssetClasssesHoldings(userId, indexId, fromDateInclusive, toDateInclusive): Observable<AssetClassDailyHoldings[]> {
    const url = '/api/v2/IndexAssetClasssesHoldings/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: AssetClassDailyHoldings[] = new Array<AssetClassDailyHoldings>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexContracts(userId, indexId): Observable<IndexContract[]> {
    const url = '/api/v2/IndexContracts/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexContract[] = new Array<IndexContract>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexCorrelationTo(
    userId, indexId, correlationToIndexId, fromDateInclusive, toDateInclusive, dataFrequencyId
  ): Observable<CorrelationPoint> {
    const url = '/api/v2/IndexCorrelationTo/';
    const postData = {
      userId,
      indexId,
      correlationToIndexId,
      fromDateInclusive,
      toDateInclusive,
      dataFrequencyId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CorrelationPoint = new CorrelationPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexDailyPerformanceOverview(
      userId,
      indexId,
      dateInclusive,
      nrOfTradingDaysBackDailyPerf?,
      startDatePerformanceAndDrawdown?,
      startDateBenchmarkInfo?): Observable<IndexDailyPerformanceOverview> {
    const url = '/api/v2/IndexDailyPerformanceOverview/';
    const postData = {
      userId,
      indexId,
      dateInclusive,
      nrOfTradingDaysBackDailyPerf,
      startDatePerformanceAndDrawdown,
      startDateBenchmarkInfo
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexDailyPerformanceOverview = new IndexDailyPerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexDailyReturnData(userId, indexId, fromDateInclusive, toDateInclusive): Observable<DailyReturnData> {
    const url = '/api/v2/IndexDailyReturnData/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyReturnData = new DailyReturnData();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexDailyRiskOverview(userId, indexId, date): Observable<IndexDailyRiskOverview> {
    const url = '/api/v2/IndexDailyRiskOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexDailyRiskOverview = new IndexDailyRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexDateRanges(userId: number, indexIds: int[]): Observable<IndexDateRange[]> {
    const url = '/api/v2/IndexDateRanges/';
    const postData = {
      userId,
      indexIds,
    };
    return this.httpClient.post<IndexDateRange[]>(url, postData).pipe(
      map((range) => {
        if (range instanceof Error) {
          throw range;
        } else {
          this.updateDateTime();
          return range;
        }
      }),
    );
  }

  getIndexDelta(userId, indexId, date): Observable<DailyDelta> {
    const url = '/api/v2/IndexDelta/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyDelta = new DailyDelta();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexDrawdown(userId, indexId, fromInclusive, toInclusive): Observable<DrawdownPoint[]> {
    const url = '/api/v2/IndexDrawdown/';
    const postData = {
      userId,
      indexId,
      fromInclusive,
      toInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DrawdownPoint[] = new Array<DrawdownPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexExAnteVolatility(userId: number, indexId: int, date: datetime): Observable<ExAnteVolatility | Error> {
    const url = '/api/v2/IndexExAnteVolatility/';
    const postData = {
      userId,
      indexId,
      date,
    };
    return this.httpClient.post<ExAnteVolatility>(url, postData).pipe(
      catchError((err: Error) => {
        this.logger.error(err);
        return throwError(err);
      })
    );
  }

  getIndexExAnteVolatilityHistorical(
    userId: number, indexId: number, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<VolatilityPoint[]> {
    const url = '/api/v2/IndexExAnteVolatilityHistorical/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<VolatilityPoint[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: VolatilityPoint[] = new Array<VolatilityPoint>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getIndexExAnteVolatilityOverview(userId: number, indexId: number, date: datetime): Observable<IndexVolatilityOverview> {
    const url = '/api/v2/IndexExAnteVolatilityOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<IndexVolatilityOverview>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexVolatilityOverview = new IndexVolatilityOverview();
        return copyObjectDataToInstance(data, value);
      }
    ));
  }


  getIndexGamma(userId, indexId, date): Observable<DailyGamma> {
    const url = '/api/v2/IndexGamma/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyGamma = new DailyGamma();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexGammaAndDeltaOverview(userId, indexId, date): Observable<IndexGammaAndDeltaOverview> {
    const url = '/api/v2/IndexGammaAndDeltaOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexGammaAndDeltaOverview = new IndexGammaAndDeltaOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexHoldingsOverview(userId, indexId, date): Observable<IndexHoldingsOverview> {
    const url = '/api/v2/IndexHoldingsOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexHoldingsOverview = new IndexHoldingsOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexInstrumentLastIntradayPerformance(userId, indexId, instrumentId): Observable<IndexInstrumentIntradayPerformance> {
    const url = '/api/v2/IndexInstrumentLastIntradayPerformance/';
    const postData = {
      userId,
      indexId,
      instrumentId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexInstrumentIntradayPerformance = new IndexInstrumentIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexIntradayPerformanceRiskOverview(userId, indexId): Observable<IndexIntradayPerformanceRiskOverview> {
    const url = '/api/v2/IndexIntradayPerformanceRiskOverview/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexIntradayPerformanceRiskOverview = new IndexIntradayPerformanceRiskOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexLastIntradayPerformance(userId, indexId): Observable<IndexIntradayPerformance> {
    const url = '/api/v2/IndexLastIntradayPerformance/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexIntradayPerformance = new IndexIntradayPerformance();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexMarginOverview(userId, indexId, date): Observable<IndexMarginOverview> {
    const url = '/api/v2/IndexMarginOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexMarginOverview = new IndexMarginOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexMarginRequirements(userId, indexId, fromDateInclusive, toDateInclusive): Observable<MarginRequirement[]> {
    const url = '/api/v2/IndexMarginRequirements/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<MarginRequirement[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: MarginRequirement[] = new Array<MarginRequirement>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getIndexPerformanceOverview(userId, indexId, date): Observable<IndexDailyPerformanceOverview> {
    const url = '/api/v2/IndexPerformanceOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(
        catchError((err, caught) => {
          const cleanData = handleHttpError(err);
          return of(cleanData);
        }),
        map(data => {
        this.updateDateTime();
        const value: IndexDailyPerformanceOverview = new IndexDailyPerformanceOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexProductNetExposures(userId, indexId, productId, fromDateInclusive, toDateInclusive): Observable<DailyNetExposure[]> {
    const url = '/api/v2/IndexProductNetExposures/';
    const postData = {
      userId,
      indexId,
      productId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: DailyNetExposure[] = new Array<DailyNetExposure>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexProductsDelta(userId, indexId, date): Observable<ProductDelta[]> {
    const url = '/api/v2/IndexProductsDelta/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductDelta[] = new Array<ProductDelta>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexProductsPerformanceAggregations(userId, indexId, date): Observable<ProductPerformanceAggregations[]> {
    const url = '/api/v2/IndexProductsPerformanceAggregations/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductPerformanceAggregations[] = new Array<ProductPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexProductsValueAtRisk(userId, indexId, date): Observable<ProductVaR[]> {
    const url = '/api/v2/IndexProductsValueAtRisk/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductVaR[] = new Array<ProductVaR>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexProductsVolatilities(userId: int, indexId: int): Observable<RealTrackStartDate[]> {
    const url = '/api/v2/IndexRealTrackStartDate/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<RealTrackStartDate[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: RealTrackStartDate[] = new Array<RealTrackStartDate>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getIndexRealTrackStartDates(userId: int, indexId: int): Observable<RealTrackStartDate[]> {
    const url = '/api/v2/IndexRealTrackStartDates/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<RealTrackStartDate[]>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: RealTrackStartDate[] = new Array<RealTrackStartDate>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexSubAssetClassesExAnteVolatilityDetails(
    userId: number, indexId: number, date: string
  ): Observable<SubAssetClassVolatilityDetails[]> {
    const url = '/api/v2/IndexSubAssetClassesExAnteVolatilityDetails/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<SubAssetClassVolatilityDetails[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: SubAssetClassVolatilityDetails[] = new Array<SubAssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      })
    );
  }



  getIndexSubAssetClassesExposures(userId, indexId, date): Observable<SubAssetClassExposures[]> {
    const url = '/api/v2/IndexSubAssetClassesExposures/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassExposures[] = new Array<SubAssetClassExposures>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexSubAssetClassesGammaAndDelta(userId, indexId, date): Observable<SubAssetClassGammaAndDelta[]> {
    const url = '/api/v2/IndexSubAssetClassesGammaAndDelta/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassGammaAndDelta[] = new Array<SubAssetClassGammaAndDelta>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexSubAssetClassesPerformanceAggregations(userId, indexId, date): Observable<SubAssetClassPerformanceAggregations[]> {
    const url = '/api/v2/IndexSubAssetClassesPerformanceAggregations/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassPerformanceAggregations[] = new Array<SubAssetClassPerformanceAggregations>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexSubAssetClassesValueAtRiskDetails(userId, indexId, date): Observable<SubAssetClassVaRDetails[]> {
    const url = '/api/v2/IndexSubAssetClassesValueAtRiskDetails/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassVaRDetails[] = new Array<SubAssetClassVaRDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexSubAssetClassesVolatilityDetails(userId, indexId, date): Observable<SubAssetClassVolatilityDetails[]> {
    const url = '/api/v2/IndexSubAssetClassesVolatilityDetails/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SubAssetClassVolatilityDetails[] = new Array<SubAssetClassVolatilityDetails>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexVaROverview(userId, indexId, date): Observable<IndexVaROverview> {
    const url = '/api/v2/IndexVaROverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexVaROverview = new IndexVaROverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexValueAtRisk(userId, indexId, date): Observable<VaRPoint> {
    const url = '/api/v2/IndexValueAtRisk/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VaRPoint = new VaRPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexValueAtRiskHistorical(userId, indexId, fromDateInclusive, toDateInclusive): Observable<VaRPoint[]> {
    const url = '/api/v2/IndexValueAtRiskHistorical/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VaRPoint[] = new Array<VaRPoint>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexVolatility(userId, indexId, date): Observable<VolatilityPoint> {
    const url = '/api/v2/IndexVolatility/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: VolatilityPoint = new VolatilityPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndexVolatilityHistorical(
    userId: number, indexId: number, fromDateInclusive: datetime, toDateInclusive: datetime
  ): Observable<VolatilityPoint[]> {
    const url = '/api/v2/IndexVolatilityHistorical/';
    const postData = {
      userId,
      indexId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<VolatilityPoint[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: VolatilityPoint[] = new Array<VolatilityPoint>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getIndexVolatilityOverview(userId, indexId, date): Observable<IndexVolatilityOverview> {
    const url = '/api/v2/IndexVolatilityOverview/';
    const postData = {
      userId,
      indexId,
      date
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexVolatilityOverview = new IndexVolatilityOverview();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIndices(userId): Observable<Index[]> {
    const url = '/api/v2/Indices/';
    const postData = {
      userId
    };
    return this.httpClient.post<Index[]>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: Index[] = new Array<Index>();
        return copyObjectDataToInstance(data, value);
      }),
    );
  }

  getIntradayOrders(userId, accountId): Observable<Order[]> {
    const url = '/api/v2/IntradayOrders/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: Order[] = new Array<Order>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getIntradayPriceSeriesLastFiveDays(userId, productId): Observable<InstrumentIntradayPrices[]> {
    const url = '/api/v2/IntradayPriceSeriesLastFiveDays/';
    const postData = {
      userId,
      productId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: InstrumentIntradayPrices[] = new Array<InstrumentIntradayPrices>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getKeyMarketPerformances(userId): Observable<MarketIntradayReturn[]> {
    const url = '/api/v2/KeyMarketPerformances/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: MarketIntradayReturn[] = new Array<MarketIntradayReturn>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getLandingPage(userId): Observable<LandingPageData> {
    const url = '/api/v2/LandingPage/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: LandingPageData = new LandingPageData();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getLandingPageDaily(userId: number, date: datetime): Observable<LandingPageDailyData> {
    const url = '/api/v2/LandingPageDaily/';
    const postData = {
      userId,
      date
    };
    return this.httpClient.post<LandingPageDailyData>(url, postData)
      .pipe(map(data => {
        if (data instanceof Error) {
          throw data;
        }
        this.updateDateTime();
        const value: LandingPageDailyData = new LandingPageDailyData();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getMarketLastIntradayReturn(userId, instrumentId): Observable<MarketIntradayReturn> {
    const url = '/api/v2/MarketLastIntradayReturn/';
    const postData = {
      userId,
      instrumentId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: MarketIntradayReturn = new MarketIntradayReturn();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getModifiableBaseIndices(userId): Observable<Index[]> {
    const url = '/api/v2/ModifiableBaseIndices/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: Index[] = new Array<Index>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getPortfolioWeightsToRiskWeights(userId, portfolioWeights): Observable<MemberRiskWeight[]> {
    const url = '/api/v2/PortfolioWeightsToRiskWeights/';
    const postData = {
      userId,
      portfolioWeights
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: MemberRiskWeight[] = new Array<MemberRiskWeight>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getProductIntradayPrices(userId, productId): Observable<ProductIntradayPrices> {
    const url = '/api/v2/ProductIntradayPrices/';
    const postData = {
      userId,
      productId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductIntradayPrices = new ProductIntradayPrices();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getProductsIntradayDetails(userId, accountId): Observable<ProductIntradayDetails[]> {
    const url = '/api/v2/ProductsIntradayDetails/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<ProductIntradayDetails[]>(url, postData)
      .pipe(
        catchError((err, caught) => {
          const cleanData = handleHttpError(err);
          return of(cleanData);
        }),
        map(data => {
          this.updateDateTime();
          const value: ProductIntradayDetails[] = new Array<ProductIntradayDetails>();
          return copyObjectDataToInstance(data, value);
        })
      );
  }

  getRatioPerformanceAndCorrelationInfoIndex(
    userId, indexId, ratioToIndexId, ratioCorrelationToIndexId, fromDateInclusive, toDateInclusive, dataFrequencyId
  ): Observable<PerformanceAndCorrelationInfo> {
    const url = '/api/v2/RatioPerformanceAndCorrelationInfoIndex/';
    const postData = {
      userId,
      indexId,
      ratioToIndexId,
      ratioCorrelationToIndexId,
      fromDateInclusive,
      toDateInclusive,
      dataFrequencyId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PerformanceAndCorrelationInfo = new PerformanceAndCorrelationInfo();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getRatioPerformanceAndCorrelationInfoTemporaryIndex(
    userId, temporaryIndexGuid, ratioToIndexId, rationCorrelationToIndexId, fromDateInclusive, toDateInclusive, dataFrequencyId
  ): Observable<PerformanceAndCorrelationInfo> {
    const url = '/api/v2/RatioPerformanceAndCorrelationInfoTemporaryIndex/';
    const postData = {
      userId,
      temporaryIndexGuid,
      ratioToIndexId,
      rationCorrelationToIndexId,
      fromDateInclusive,
      toDateInclusive,
      dataFrequencyId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PerformanceAndCorrelationInfo = new PerformanceAndCorrelationInfo();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getReferencePriceTypes(userId, accountId): Observable<ReferencePriceType[]> {
    const url = '/api/v2/ReferencePriceTypes/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ReferencePriceType[] = new Array<ReferencePriceType>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getRemoveIndexFromPortfolioProfile(userId, profileId, indexId): Observable<PortfolioProfile> {
    const url = '/api/v2/RemoveIndexFromPortfolioProfile/';
    const postData = {
      userId,
      profileId,
      indexId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PortfolioProfile = new PortfolioProfile();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getRiskWeightsToPortfolioWeights(userId, riskWeights): Observable<MemberIndexWeight[]> {
    const url = '/api/v2/RiskWeightsToPortfolioWeights/';
    const postData = {
      userId,
      riskWeights
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: MemberIndexWeight[] = new Array<MemberIndexWeight>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getRolledDailyClose(userId, productId, fromDateInclusive, toDateInclusive): Observable<ProductRolledDailyClose> {
    const url = '/api/v2/RolledDailyClose/';
    const postData = {
      userId,
      productId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ProductRolledDailyClose = new ProductRolledDailyClose();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getSandboxIndices(userId): Observable<Index[]> {
    const url = '/api/v2/SandboxIndices/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: Index[] = new Array<Index>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getSandboxStatistics(userId, indexIds, dataFrequencyId, fromDateInclusive, toDateInclusive): Observable<IndexPerformanceInfo[]> {
    const url = '/api/v2/SandboxStatistics/';
    const postData = {
      userId,
      indexIds,
      dataFrequencyId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexPerformanceInfo[] = new Array<IndexPerformanceInfo>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getSignalDirections(userId): Observable<SignalDirection[]> {
    const url = '/api/v2/SignalDirections/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: SignalDirection[] = new Array<SignalDirection>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getStoreCombinedIndex(userId, name, description, members): Observable<CombinedIndex> {
    const url = '/api/v2/StoreCombinedIndex/';
    const postData = {
      userId,
      name,
      description,
      members
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CombinedIndex = new CombinedIndex();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getStoreModifiedIndex(userId, name, description, originalIndexId, modifiedIndexContracts): Observable<ModifiedIndex> {
    const url = '/api/v2/StoreModifiedIndex/';
    const postData = {
      userId,
      name,
      description,
      originalIndexId,
      modifiedIndexContracts
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ModifiedIndex = new ModifiedIndex();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getStrategyStyleDailyTrades(userId, accountId, strategyStyleId, dateFromInclusive, dateToInclusive): Observable<ExecutedTrade[]> {
    const url = '/api/v2/StrategyStyleDailyTrades/';
    const postData = {
      userId,
      accountId,
      strategyStyleId,
      dateFromInclusive,
      dateToInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: ExecutedTrade[] = new Array<ExecutedTrade>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getStrategyStyleIntradayOrders(userId, accountId, strategyStyleId): Observable<Order[]> {
    const url = '/api/v2/StrategyStyleIntradayOrders/';
    const postData = {
      userId,
      accountId,
      strategyStyleId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: Order[] = new Array<Order>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getSubAssetClassesIntradayDetails(userId, accountId): Observable<SubAssetClassIntradayDetails[]> {
    const url = '/api/v2/SubAssetClassesIntradayDetails/';
    const postData = {
      userId,
      accountId
    };
    return this.httpClient.post<SubAssetClassIntradayDetails[]>(url, postData)
      .pipe(
        catchError((err, caught) => {
          const cleanData = handleHttpError<SubAssetClassIntradayDetails[]>(err);
          return of(cleanData);
        }),
        map(data => {
          this.updateDateTime();
          const value: SubAssetClassIntradayDetails[] = new Array<SubAssetClassIntradayDetails>();
          return copyObjectDataToInstance(data, value);
        })
      );
  }

  getTemporaryIndexCorrelationTo(
    userId: int,
    temporaryIndexGuid: guid,
    correlationToIndexId: int,
    fromDateInclusive: string,
    toDateInclusive: string,
    dataFrequencyId: int
  ): Observable<CorrelationPoint> {
    const url = '/api/v2/TemporaryIndexCorrelationTo/';
    const postData = {
      userId,
      temporaryIndexGuid,
      correlationToIndexId,
      fromDateInclusive,
      toDateInclusive,
      dataFrequencyId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: CorrelationPoint = new CorrelationPoint();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getTemporaryIndexPerformanceInfo(
    userId, temporaryIndexGuid, dataFrequencyId, fromDateInclusive, toDateInclusive
  ): Observable<IndexPerformanceInfo> {
    const url = '/api/v2/TemporaryIndexPerformanceInfo/';
    const postData = {
      userId,
      temporaryIndexGuid,
      dataFrequencyId,
      fromDateInclusive,
      toDateInclusive
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: IndexPerformanceInfo = new IndexPerformanceInfo();
        return copyObjectDataToInstance(data, value);
      }));
  }

  getTryDeleteUserCreatedIndex(userId, indexId): Observable<boolean> {
    const url = '/api/v2/TryDeleteUserCreatedIndex/';
    const postData = {
      userId,
      indexId
    };
    return this.httpClient.post<boolean>(url, postData).pipe(
      map((response) => {
        if (response instanceof Error) {
          throw response;
        }
        return response;
      }),
      tap(() => this.updateDateTime()),
    );
  }

  getUpdateCombinedIndex(userId, combinedIndexId, updatedMembers): Observable<CombinedIndex> {
    const url = '/api/v2/UpdateCombinedIndex/';
    const postData = {
      userId,
      combinedIndexId,
      updatedMembers
    };
    return this.httpClient.post<CombinedIndex>(url, postData).pipe(
      map(data => {
        this.updateDateTime();
        const value: CombinedIndex = new CombinedIndex();
        return copyObjectDataToInstance(data, value);
      })
    );
  }

  getUserPortfolioProfiles(userId): Observable<PortfolioProfile[]> {
    const url = '/api/v2/UserPortfolioProfiles/';
    const postData = {
      userId
    };
    return this.httpClient.post<any>(url, postData)
      .pipe(map(data => {
        this.updateDateTime();
        const value: PortfolioProfile[] = new Array<PortfolioProfile>();
        return copyObjectDataToInstance(data, value);
      }));
  }

  // START Sandbox 2.0 Operations

  calculateSandboxPortfolios(
    userId: int, portfolios: SandboxPortfolioParameters[], fromDateInclusive: string, toDateInclusive: string, dataFrequencyId: int
  ) {
    const url = '/api/v2/CalculateSandboxPortfolios/';
    const postData = {
      userId,
      portfolios: portfolios.map(p => {
        p.Members = p.Members.map(m => {
          m.CapitalAllocation = (m.CapitalAllocation == null || m.CapitalAllocation === 0) ? 0.0 : Number(m.CapitalAllocation.toFixed(8));
          m.RiskAllocation = (m.RiskAllocation == null ||  m.RiskAllocation === 0) ? 0.0 : Number(m.RiskAllocation.toFixed(8));
          return m;
        });
        return p;
      }),
      dataFrequencyId,
      fromDateInclusive,
      toDateInclusive,
    };
    return this.httpClient.post<TemporarySandboxPortfolioInfo[]>(url, postData).pipe(
      map((response) => {
        if (response instanceof Error) {
          throw response;
        }
        if (Array.isArray(response)) {
          return response.map((obj) => {
            const info = new TemporarySandboxPortfolioInfo();
            copyObjectDataToInstance(obj, info);
            return info;
          });
        } else {
          return response;
        }
      })
    );
  }

  listSandboxAssets(): Observable<SandboxAsset[] | Error> {
    const url = '/api/v2/SandboxAssets/';
    return this.httpClient.get<SandboxAsset[]>(url).pipe(
      map((assets) => {
        /* Test
        const dummyAsset = `{
          "CapitalRequirementFactor":1,"Index":{"Description": "Test asset.","FundingType":{"FundingTypeId":1,"Name":"Fully-funded"},"IndexId":999999,"IndexType":{"IndexTypeId":1,"Name":"TheoreticalIndex"},"Name":"Dummy Asset","SandboxType":"Reference Investment"},"LongTermVolatilities":[{"Volatility":0.07,"VolatilityHorizon":{"Name":"Lifetime","VolatilityHorizonId":1}}],"PairedIndexId":[],"Region":{"Name":"Global","RegionId":4},"ReturnType":{"Name":"Total Return","ReturnTypeId":1},"SubAssetClass":{"AssetClass":{"AssetClassId":1,"Name":"Fixed Income"},"Name":"Emerging"}}`;
        if (Array.isArray(assets)) {
          assets.push(JSON.parse(dummyAsset));
        }
        */
        return assets;
      })
    );
  }

  listSandboxPortfolios(): Observable<SandboxPortfolio[] | Error> {
    const url = '/api/v2/SandboxPortfolio/';
    return this.httpClient.get<SandboxPortfolio[]>(url);
  }

  getSandboxPortfoliosCorrelations(
    userId: int,
    portfolios: SandboxPortfolioParameters[],
    dataFrequencyId: int,
    fromDateInclusive: string,
    toDateInclusive: string
  ) {
    const url = '/api/v2/SandboxPortfoliosCorrelations/';
    const postData = {
      userId,
      portfolios,
      dataFrequencyId,
      fromDateInclusive,
      toDateInclusive,
    };
    return this.httpClient.post<SandboxPortfoliosCorrelations>(url, postData).pipe(
      map(response => {
        const obj = new SandboxPortfoliosCorrelations();
        return copyObjectDataToInstance(response, obj);
      })
    );
  }

  getSandboxPortfolio(indexId: int): Observable<SandboxPortfolio | Error> {
    const url = `/api/v2/SandboxPortfolio/${indexId}/`;
    return this.httpClient.get<SandboxPortfolio>(url);
  }

  storeSandboxPortfolio(userId: int, portfolio: SandboxPortfolioParameters): Observable<CombinedIndex | Error> {
    const url = `/api/v2/SandboxPortfolio/`;
    const data = {
      userId,
      portfolio,
    };
    console.table(data);
    return this.httpClient.post<CombinedIndex>(url, data).pipe(
      map(response => {
        if ('Index' in response) {
          const savedCI = new CombinedIndex();
          savedCI.Index = new Index();
          savedCI.MemberRiskWeights = response.MemberRiskWeights.map(mw => copyObjectDataToInstance(mw, new MemberRiskWeight()));
          savedCI.Members = response.Members.map(m => copyObjectDataToInstance(m, new MemberAssetWeight()));
          copyObjectDataToInstance(response.Index, savedCI.Index);
          return savedCI;
        } else {
          return response;
        }
      })
    );
  }

  getForecastSets$() {
    const url = `/api/v2/ForecastSets/`;
    return this.httpClient.get<ForecastSet[]>(url).pipe(
      map((response) => {
        if (response instanceof Error) {
          throw response;
        }
        const setList = [] as ForecastSet[];
        if (Array.isArray(response)) {
          for (const data of response) {
            const set = new ForecastSet();
            copyObjectDataToInstance(data, set);
            setList.push(set);
          }
        }
        return setList;
      }),
    );
  }

  getSandboxPortfolioForecasts(userId: int, requests: SandboxPortfolioForecastsRequest[], dataFrequencyId: DATA_FREQ_ID) {
    const url = `/api/v2/SandboxPortfoliosForecasts/`;
    return this.httpClient.post<ForecastResult[]>(url, {
      userId,
      requests,
      dataFrequencyId,
    }).pipe(
      map((data) => {
        if (data instanceof Error) {
          throw data;
        }
        return data;
      })
    );
  }

  getSandboxAssetExpectedReturn(userId: int, indexIds: int[]) {
    const url = `/api/v2/SandboxAssetExpectedReturn/`;
    return this.httpClient.post<ForecastSet[]>(url, {
      userId,
      indexIds,
    }).pipe(
      map((data) => {
        if (data instanceof Error) {
          throw data;
        }
        return data;
      })
    );
  }

  // END Sandbox 2.0 Operations
}

/**
 * Check if data is an error, and throw the error as an exception if it is.
 * If the error is a SyntaxError from JSON, try parsing with json5 instead, in case
 * there are NaN or Infinity values in the response.
 *
 * @param data the response from HttpClient, parsed as JSON
 */
function handleHttpError<T>(data: T | Error | HttpErrorResponse): T {
  if (data instanceof Error || data instanceof HttpErrorResponse) {
    if ('error' in data &&
      'error' in data['error'] &&
      'name' in data['error']['error'] &&
      data['error']['error']['name'] === 'SyntaxError' &&
      'text' in data['error']) {
      // Invalid JSON. Try JSON5 parser instead
      const parsed = json5.parse(data['error']['text']) as T;
      data = parsed;
    } else {
      throw data;
    }
  }
  return data;
}

