export class PortfolioSet {
    id: number;
    userId: number;
    name: string;
    portfolios: number[];
    lastModifiedDate: Date;
    starred: boolean;

    constructor(init?: { [k in keyof PortfolioSet]?: PortfolioSet[k] }) {
        if (init != null) {
            for (const key of ['id', 'userId', 'name', 'portfolios', 'lastModifiedDate', 'starred']) {
                if (key in init) {
                    this[key] = init[key];
                }
            }
        }
    }

    /** check that the name is not 'Portfolio Set 1' or empty space */
    static validSetName(name: string): boolean {
        return !/^(?:(?:\s*Portfolio Set 1\s*)|\s*)$/.test(name);
    }
}

