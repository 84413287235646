import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AccountDataService } from './account-data/account-data.service';
import { AcctIdx } from './account-data/acct-idx';
import { Account, Index } from './api/webservice.service';
import { AuthenticationService } from './auth/authentication.service';
import { MainMenuItem } from './main-menu-item';
import { environment } from 'environments/environment';
import { LoggingService } from './utils/logging/logging.service';

@Injectable()
export class MenuService {
  public activeAccountId$: Observable<string | number>;
  public performanceAndRiskUrl$: Observable<string>;
  private acctId: string | number;
  private _activeAccountIdSubj = new Subject<string | number>();
  private activeUrl: string;
  private disclaimerUrl = '/portal/disclaimer';
  private _homeUrl = '/portal/dashboard';
  private hiddenItems = {
    dynamics$: null // : BehaviorSubject<boolean>,
  };
  private _mainMenuItems = [];
  private _mainMenuItemsSubj: BehaviorSubject<MainMenuItem[]>;
  private performanceRiskUrlRoot  = '/portal/account';
  private _performanceAndRiskUrlSubj = new BehaviorSubject<string>(null);

  constructor(
    private accountDataService: AccountDataService,
    private authService: AuthenticationService,
    private logger: LoggingService,
    private router: Router,
  ) {
    this.activeAccountId$ = this._activeAccountIdSubj.asObservable();
    this._mainMenuItemsSubj = new BehaviorSubject<MainMenuItem[]>([]);

    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
       if (isLoggedIn) {
          this.acctId = null;
          this.fetchAccountId();
        }
      }
    );
    this.fetchAccountId();

    this.activeAccountId$.subscribe(acctId => {
      if (acctId != null) {
        this.acctId = acctId;
        this.updateMenu(this.activeUrl, acctId);
      }
      this._performanceAndRiskUrlSubj.next(this.performanceAndRiskUrl);
    });
    this.performanceAndRiskUrl$ = this._performanceAndRiskUrlSubj.asObservable()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = event.url;
        if (this.activeUrl != null ) {
          this.updateMenu(this.activeUrl, this.acctId);
        }
      }
    });
  }

  itemPath(item) {
    return item.fragment != null ? item.url + '#' + item.fragment : item.url;
  }

  public highlightItem(path: string, menuItems: MainMenuItem[] = this._mainMenuItems, foundItem?: MainMenuItem) {
    this.updateMenu(path, this.acctId);
  }

  public get mainMenuItems$() {
    return this._mainMenuItemsSubj.asObservable();
  }

  public get performanceAndRiskUrl(): string {
    let performanceRiskUrl: string;
    if (this.acctId) {
      performanceRiskUrl = `${this.performanceRiskUrlRoot}/${this.acctId}`;
    } else {
      performanceRiskUrl = this._homeUrl;
    }
    return performanceRiskUrl;
  }

  public setActiveAccountId(value: string | number) {
    this._activeAccountIdSubj.next(value);
  }

  public showDisclaimer() {
    return !this.activeUrl.includes(this.performanceRiskUrlRoot)
      && !this.activeUrl.includes('/portal/sandbox')
      && !this.activeUrl.includes(this.disclaimerUrl)
      && !this.activeUrl.includes('/portal/kyc'
    );
  }

  updateMenu(activeUrl: string, acctId: number | string = null): void {
    const disclaimerUrl       = this.disclaimerUrl;
    const documentsUrl        = '/portal/documents';
    const KYCUrl	            = '/portal/kyc';
    const performanceRiskUrl = this.performanceAndRiskUrl;
    const sandboxAnalyticsUrl = '/portal/sandbox';
    const sandboxHidden$ = this.authService.sandboxAllowed().pipe(map(val => !val));
    const sandboxProtoHidden$ = this.authService.sandboxProtoAllowed().pipe(map(val => !val));
    const signalDynamicsHidden$ = this.accountDataService.signalDynamicsAvailable().pipe(map(val => !val));
    const settingsUrl = '/portal/settings';

    const mainMenuItems: MainMenuItem[] = [
      {
        url: this._homeUrl,
        label: 'Performance overview',
        icon: 'desktop',
        highlighted: activeUrl != null && activeUrl.includes(this._homeUrl),
      },
      {
        url: performanceRiskUrl,
        label: 'Performance & risk',
        icon: 'bar-chart',
        highlighted: activeUrl != null && activeUrl !== this._homeUrl && activeUrl.includes(performanceRiskUrl),
            /*
        subMenuItems: [
          {
            url: performanceRiskUrl,
            fragment: 'overview',
            label: 'Overview',
            icon: 'none',
            highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#overview'),
          },
          {
            url: performanceRiskUrl,
            fragment: 'performance',
            label: 'Performance',
            icon: 'none',
            highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#performance'),
          },
          {
            url: performanceRiskUrl,
            fragment: 'risk',
            label: 'Risk',
            icon: 'none',
            highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk'),
            subMenuItems: [
              {
                url: performanceRiskUrl,
                fragment: 'risk-headline',
                label: 'Headline figures',
                icon: 'none',
                highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk-headline'),
              },
              {
                url: performanceRiskUrl,
                fragment: 'risk-signal-dynamics',
                label: 'Signal dynamics',
                icon: 'none',
                hidden: signalDynamicsHidden$,
                highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk-signal-dynamics'),
              },
              {
                url: performanceRiskUrl,
                fragment: 'risk-assetclass-status',
                label: 'Asset class status',
                icon: 'none',
                highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk-assetclass-status'),
              },
              {
                url: performanceRiskUrl,
                fragment: 'risk-positions',
                label: 'Positions',
                icon: 'none',
                highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk-positions'),
              },
              {
                url: performanceRiskUrl,
                fragment: 'risk-timeseries',
                label: 'Time series risk',
                icon: 'none',
                highlighted: activeUrl != null && activeUrl.endsWith(performanceRiskUrl + '#risk-timeseries'),
              },
            ], 
          },
        ] */
      },
      {
        url: sandboxAnalyticsUrl,
        label: 'Portfolio builder',
        icon: 'flask',
        highlighted: activeUrl != null && activeUrl.startsWith(sandboxAnalyticsUrl),
        hidden: sandboxProtoHidden$,
      },
      {
        url: documentsUrl,
        label: 'Documents',
        icon: 'files-o',
        highlighted: activeUrl != null && activeUrl.includes(documentsUrl),
      },
      {
        url: settingsUrl,
        label: 'Settings',
        icon: 'cog',
        highlighted: activeUrl != null && activeUrl.includes(settingsUrl),
        subMenuItems: [
          {
            url: settingsUrl + '/landing-page',
            label: 'Home page settings',
            icon: 'home',
            highlighted: activeUrl != null && activeUrl.includes(settingsUrl + '/landing-page'),
          },
          {
            url: settingsUrl + '/performance-breakdown',
            label: 'Performance & risk',
            icon: 'bar-chart',
            highlighted: activeUrl != null && activeUrl.includes(settingsUrl + '/performance-breakdown'),
          },
        ],
      },
      {
        url: disclaimerUrl,
        label: 'Legal & privacy',
        icon: 'gavel',
        highlighted: activeUrl != null && activeUrl.includes(disclaimerUrl),
      },
      {
        url: KYCUrl,
        label: 'KYC',
        icon: 'search',
        highlighted: activeUrl != null && activeUrl.includes(KYCUrl),
        hidden: of(!environment.showKYC),
      }
    ];
    Promise.resolve(null).then(
      () => {
        this._mainMenuItems = mainMenuItems;
        this._mainMenuItemsSubj.next(this._mainMenuItems);
      }
    );
  }

  private fetchAccountId() {
    if (this.acctId != null) {
      return;
    }
    this.accountDataService.loadAcctIdxs().pipe(
      takeUntil(this.activeAccountId$), // cancel if another update comes in first. (i.e. from AccountOverview)
    ).subscribe(
      (data: [AcctIdx[], Account[], Index[]]) => {
        if (data === null) {
          return;
        }
        const acctIdxs = data[0];
        if (acctIdxs.length > 0) {
          this._activeAccountIdSubj.next(acctIdxs[0].Id);
        }
      },
      (err) => {
        this.logger.error('Menu service: Could not load default account:', err);
        return;
      }
    );
  }
}
