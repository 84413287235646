import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Pipe({name: 'signedPercent'})
export class SignedPercentPipe implements PipeTransform {
    constructor(private percentPipe: PercentPipe) {}

    transform(value: any, digits?: string): string {
        let out = this.percentPipe.transform(value, digits) || '';
        if (out && value > 0) {
            out = `+${out}`;
        }
        return out;
    }
}