import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AppRefreshService } from '@app/app-refresh.service';
import { Sandbox3DataService } from '@app/sandbox3/services/sandbox3-data.service';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, mergeMap, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Index, SandboxAsset } from '../api/webservice.service';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpClientService } from '../http-client.service';
import naturalSort from '../natural-sort';
import { SandboxDataService } from '../sandbox-data.service';
import { LoggingService } from '../utils/logging/logging.service';
import { ActivePortfolio } from './active-portfolio';
import { assetGroupContainsAsset, filterAssetGroup, SandboxAssetGroup } from './sandbox-asset-group';
import { SandboxIndex, sortSandboxIndices } from './sandbox-index';
import { SandboxIndexGroup, sortSandboxIndexGroups } from './sandbox-index-group';
import { UserSettings } from './user-settings';

interface UserSettingsResponse {
  user: number;
  sandbox_indices: SandboxIndexGroup[]|SandboxIndex[]|'';
  sandbox_assets: SandboxAssetGroup[]|SandboxAsset[]|'';
  sandbox_hide_alloc_numbers: boolean;
  active_portfolios: ActivePortfolio[]|'';
  default_performance_period: string;
  achilles_enabled: boolean;
}

@Injectable()
export class SettingsDataService implements OnDestroy, OnInit {
  public availablePerformancePeriods = [
    'Daily', 'MTD', 'YTD', '3 months', '6 months', '1 year', 'All'
  ];
  public isLoading$: BehaviorSubject<boolean>;
  public userSettingsUpdate$: Observable<UserSettings>;

  private _destroy: Subject<void>;
  private _systemDefaultPeriod = '3 months';
  private _userSettings$: Observable<UserSettings>;
  private _userSettingsSubject: BehaviorSubject<UserSettings>;
  private _userSettingsUrl = '/api/v1/user-settings/';
  private _userSettingsCache: { expires: number, settings: UserSettings };

  constructor(
    private _auth: AuthenticationService,
    private _httpClient: HttpClientService,
    private _logger: LoggingService,
    private _refresh: AppRefreshService,
    private _sboxData: SandboxDataService,
    private _sbox3data: Sandbox3DataService,
  ) {
    this._init();
  }


  public getActivePortfoliosSettings(): Observable<ActivePortfolio[]> {
    return this.getUserSettings().pipe(
      take(1),
      map(settings => settings.ActivePortfolios)
    );
  }

  public getDefaultPerformancePeriodSetting$(): Observable<string> {
    return this.getUserSettings().pipe(
      map(settings => settings.DefaultPerfomancePeriod || this._systemDefaultPeriod),
    );
  }

  public getSandboxIndexSettings(): Observable<SandboxIndexGroup[]> {
    return this.getUserSettings().pipe(
      take(1),
      map(settings => settings.SandboxIndices)
    );
  }


  /** Get an array of available SandboxAssets
   * @param limited - only return assets that are enabled in settings if true.
   * @param bypassCache - refresh assets from server
   */
  public getSandboxAssets$(limited = true, bypassCache = false): Observable<SandboxAsset[]> {
    const assets$ = this._sbox3data.loadAssets$(bypassCache);
    if (limited) {
      return assets$.pipe(
        switchMap<SandboxAsset[], Observable<SandboxAsset[]>>(
         (assetList) => this.getSandboxAssetSettings$().pipe(
          takeUntil(this._destroy),
          map<SandboxAssetGroup[], SandboxAsset[]>(
            (settings) => {
              if (settings == null) {
                return assetList;
              }
              return assetList.filter(a => settings.some(assetGrp => assetGroupContainsAsset(assetGrp, a)));
            }
          ),
        ),
        )
      );
    } else {
      return assets$;
    }
  }

  /** Get selecrted assets */
  public getSandboxAssetGroups$(bypassCache = false): Observable<SandboxAssetGroup[]> {
    const assets$ = this._sbox3data.loadAssets$(bypassCache).pipe(
        switchMap((assetList) => this.getSandboxAssetSettings$().pipe(
          takeUntil(this._destroy),
          map(
            (settings) => {
              if (settings == null) {
                return null;
              }
              return settings.map(grp => filterAssetGroup(grp, assetList));
            }
          ),
        )),
      );
    return assets$;
  }

  /** Get current asset settings */
  public getSandboxAssetSettings$(): Observable<SandboxAssetGroup[]> {
    return this.getUserSettings().pipe(
      takeUntil(merge(this._destroy, this._refresh.refresh$)),
      mergeMap((settings) => {
        if (settings.SandboxAssets == null) {
          return this._sbox3data.loadAssets$().pipe(
            map((assetList) => {
              if (Array.isArray(assetList)) {
                const groupedAssets: SandboxAssetGroup[] = [];
                assetList.forEach((asset) => {
                  const subAssetClass = asset.SubAssetClass;
                  const assetClass = subAssetClass.AssetClass;
                  let acGrp = groupedAssets.find(grp => grp.Name === assetClass.Name);
                  let subGrp: SandboxAssetGroup;
                  if (acGrp == null) {
                    subGrp = {
                      Name: subAssetClass.Name,
                      Order: 1,
                      Assets: [asset],
                      SubGroups: [],
                    } as SandboxAssetGroup;
                    acGrp = {
                      Name: assetClass.Name,
                      Order: groupedAssets.length + 1,
                      Assets: [],
                      SubGroups: [subGrp],
                    } as SandboxAssetGroup;
                    groupedAssets.push(acGrp);
                    return;
                  }
                  subGrp = acGrp.SubGroups.find(sub => sub.Name === subAssetClass.Name);
                  if (subGrp == null) {
                    subGrp = {
                      Name: subAssetClass.Name,
                      Order: acGrp.SubGroups.length + 1,
                      Assets: [asset],
                      SubGroups: [],
                    } as SandboxAssetGroup;
                    acGrp.SubGroups.push(subGrp);
                  }
                  subGrp.Assets.push(asset);
                  return;
                });
                settings.SandboxAssets = groupedAssets;
              }
              return settings;
            })
          );
        }
        return of(settings);
      }),
      map(settings => {
        if (settings == null || settings.SandboxAssets == null) {
          return null;
        }
        // create live SandboxAsset objects from generic object data
        const hydrated: SandboxAssetGroup[] = settings.SandboxAssets.map(grp => {
          if (Array.isArray(grp.Assets) && grp.Assets.length > 0) {
            grp.Assets = grp.Assets.map(data => new SandboxAsset(data));
          }
          if (Array.isArray(grp.SubGroups) && grp.SubGroups.length > 0) {
            grp.SubGroups = grp.SubGroups.map(sub => {
              if (Array.isArray(sub.Assets) && sub.Assets.length > 0) {
                sub.Assets = sub.Assets.map(data => new SandboxAsset(data));
              }
              return sub;
            });
          }
          return grp;
        });
        return hydrated;
      }),
    );
  }

  public getCombinableIndices(forceUpdate = false) {
    return this._sboxData.getCombinableIndices(forceUpdate);
  }

  public getUserSettings(forceUpdate = false): Observable<UserSettings> {
    const now = Date.now();
    if (forceUpdate || this._userSettingsCache == null || this._userSettingsCache.expires < now) {
      this._userSettings$ = this._refresh.refresh$.pipe(
        takeUntil(this._destroy),
        startWith(null),
        switchMap(
          () => this._auth.getCurrentUser(true).pipe(
            switchMap(user => this._httpClient.get<UserSettingsResponse>(`${this._userSettingsUrl}${user.userId}/`).pipe(
              catchError((err, caught) => {
                if (err.status === 404) {
                  return of(null as UserSettingsResponse);
                } else {
                  throw err;
                }
              }),
              map<UserSettingsResponse, UserSettingsResponse>(
                (response) => {
                  if (response == null) {
                    const emptyResponse = <UserSettingsResponse>{
                      user: user.userId,
                      sandbox_indices: null,
                      sandbox_hide_alloc_numbers: false,
                      active_portfolios: null,
                      default_performance_period: this._systemDefaultPeriod,
                      achilles_enabled: false,
                    };
                    return emptyResponse;
                  } else {
                    for (const key of ['sandbox_indices', 'active_portfolios', 'sandbox_assets']) {
                      if (!Array.isArray(response[key]) || response[key].length === 0) {
                        response[key] = [];
                      }
                    }
                    return response;
                  }
                }
              ),
              mergeMap<UserSettingsResponse, Observable<UserSettingsResponse>>((response) => this.hydrateSandboxIndices(response)),
              map<UserSettingsResponse, UserSettings>(
                response => {
                  const settings = new UserSettings();
                  settings.UserId = response['user'];
                  settings.SandboxHideAllocNumbers = response['sandbox_hide_alloc_numbers'] || false;
                  settings.SandboxIndices = (response['sandbox_indices'] == null || response['sandbox_indices'] === '') ? [] :
                    response['sandbox_indices'] as SandboxIndexGroup[];
                  settings.SandboxAssets = (response['sandbox_assets'] == null  || response['sandbox_assets'] === '') ? null :
                    this.hydrateSandboxAssets(response['sandbox_assets'] as SandboxAssetGroup[]);
                  settings.ActivePortfolios = (response['active_portfolios'] == null || response['active_portfolios'] === '') ? [] :
                    response['active_portfolios'];
                  settings.DefaultPerfomancePeriod = response['default_performance_period'];
                  settings.AchillesEnabled = response['achilles_enabled'] == null ? false : response.achilles_enabled;
                  return settings;
                }
              ),
              tap(settings => {
                this._userSettingsSubject.next(settings);
                const now = Date.now();
                this._userSettingsCache = { expires: now + 60 * 60000, settings }
              }),
              ),
            ),
          ),
        ),
      );
      return this._userSettings$;
    }
    return of(this._userSettingsCache.settings);
  }

  /**
   * Turn the objects in SandboxAssetGroup.Assets into SandboxAsset instances
   * @param assetGroups the 
   */
  hydrateSandboxAssets(assetGroups: SandboxAssetGroup[] | SandboxAssetGroup): SandboxAssetGroup[] {
    if (!Array.isArray(assetGroups)) {
      assetGroups = [assetGroups];
    }
    for (const grp of assetGroups) {
      if (Array.isArray(grp.Assets) && grp.Assets.length > 0) {
        grp.Assets = grp.Assets.map((obj) => new SandboxAsset(obj));
      }
      if (Array.isArray(grp.SubGroups) && grp.SubGroups.length > 0) {
        grp.SubGroups = this.hydrateSandboxAssets(grp.SubGroups);
      }
    }
    return assetGroups;
  }

  ngOnDestroy() {
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this._userSettings$ = null;
    this._userSettingsSubject.complete();
    this._userSettingsSubject = null;
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
  }

  reset() {
    this.ngOnDestroy();
    this._init();
  }

  public saveAchillesEnabled$(newAchilles: boolean) {
    return this.getUserSettings().pipe(
      take(1),
      mergeMap(settings => {
        settings.AchillesEnabled = newAchilles;
        return this.saveUserSettings$(settings);
      }),
    );
  }

  public saveActivePortfolios$(portfolios: Array<ActivePortfolio>) {
    const activePortfolios = portfolios.map((ap) => new ActivePortfolio(ap.PortfolioId, ap.Order, ap.NumberDisplayType));

    return this.getUserSettings().pipe(
      take(1),
      mergeMap(settings => {
        settings.ActivePortfolios = activePortfolios;
        return this.saveUserSettings$(settings);
     }),
    );
  }

  public saveDefaultPerformancePeriod$(newDefaultPeriod: string) {
    return this.getUserSettings().pipe(
      take(1),
      mergeMap(settings => {
        settings.DefaultPerfomancePeriod = newDefaultPeriod;
        return this.saveUserSettings$(settings);
      }),
    );
  }

  public saveSandboxHideAllocNumbers$(newValue: boolean) {
    return this.getUserSettings().pipe(
      take(1),
      mergeMap(settings => {
        settings.SandboxHideAllocNumbers = Boolean(newValue);
        return this.saveUserSettings$(settings);
      }),
    );
  }

  public saveSelectedSandboxIndices$(indexIds: number[]) {
    const sandboxIndices = indexIds.map((id, i) => <SandboxIndex>{ IndexId: id, Order: i});

    return this.buildSandboxGroupsFromSandboxIndices$(sandboxIndices).pipe(
      mergeMap(sandboxGroups => this.getUserSettings().pipe(
        take(1),
        mergeMap(settings => {
          settings.SandboxIndices = sandboxGroups;
          return this.saveUserSettings$(settings);
        }),
      )),
    );
  }

  public saveSelectedSandboxAssets$(assetGrps: SandboxAssetGroup[]) {
    return this.getUserSettings().pipe(
      take(1),
      mergeMap(settings => {
        settings.SandboxAssets = assetGrps;
        return this.saveUserSettings$(settings);
      }),
    );
  }

  public filterAndSortIndices(idxList: Index[]): Observable<Index[]> {
    return this.getSandboxIndexSettings().pipe(
      map(groups => {
        const orderedIndices = [];
        // groups already sorted in buildSandboxGroupsFromSandboxIndices(), so we just flatten the indices from group list
        for (const grp of groups) {
          for (const sbidx of grp.Indices) {
            const found = idxList.find(idx => idx.IndexId === sbidx.IndexId);
            if (found) {
              orderedIndices.push(found);
            }
          }
        }
        return orderedIndices;
      }),
    );
  }


  private buildSandboxGroupsFromSandboxIndices$(sandboxIndices: SandboxIndex[]): Observable<SandboxIndexGroup[]> {
    if (sandboxIndices == null || !Array.isArray(sandboxIndices) || sandboxIndices.length === 0) {
      return of([]);
    }
    return this.getCombinableIndices().pipe(
      map(combinableIndices => {
        if (!Array.isArray(combinableIndices)) {
          this._logger.warn('CombinableIndecis is not a list:', combinableIndices);
          return;
        }
        const indexData = sandboxIndices.map(si => <{SandboxIndex: SandboxIndex, Index: Index}>{
          'SandboxIndex': si,
          'Index': combinableIndices.find(i => i.IndexId === si.IndexId)
        }).filter(obj => obj.Index != null);

        const sandboxGroups: SandboxIndexGroup[] = [];
        let groupCounter = 1;
        for (const ixdObj of indexData) {
          let grp = sandboxGroups.find(g => g.Name === ixdObj.Index.SandboxType);
          if (grp == null) {
            grp = <SandboxIndexGroup>{
              Indices: [],
              Name: ixdObj.Index.SandboxType,
              Order: groupCounter++,
            };
            sandboxGroups.push(grp);
          }
          grp.Indices.push(ixdObj.SandboxIndex);
        }
        sandboxGroups.sort(sortSandboxIndexGroups);
        for (const group of sandboxGroups) {
          group.Indices.sort((a, b) => {
            const orderSort = sortSandboxIndices(a, b);
            if (orderSort === 0) {
              const aCombi = combinableIndices.find(combi => combi.IndexId === a.IndexId);
              const bCombi = combinableIndices.find(combi => combi.IndexId === b.IndexId);
              if (aCombi == null || bCombi == null) {
                return 0;
              } else {
                return naturalSort(aCombi.Name, bCombi.Name);
              }
            } else {
              return orderSort;
            }
          });
        }
        return sandboxGroups;
      }),
    );
  }

  private hydrateSandboxIndices(response: UserSettingsResponse): Observable<UserSettingsResponse> {
    const responseIndices = response['sandbox_indices'];
    if (Array.isArray(responseIndices) && responseIndices.length > 0) {
      if (responseIndices[0]['Name'] === undefined) {
        // old settings format
        return this.buildSandboxGroupsFromSandboxIndices$(
          <SandboxIndex[]>response['sandbox_indices']).pipe(
            map<SandboxIndexGroup[], UserSettingsResponse>(
              sandboxGroups => {
                response['sandbox_indices'] = sandboxGroups;
                return response;
              }
            ),
        );
      }
    }
    return of(response);
  }

  private _init() {
    this._destroy = new Subject<void>();
    this._userSettingsSubject = new BehaviorSubject<UserSettings>(null);
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.userSettingsUpdate$ = this._userSettingsSubject.asObservable();
    this._refresh.refresh$.pipe(takeUntil(this._destroy)).subscribe(() => this.reset());
  }

  private saveUserSettings$(newSettings: UserSettings): Observable<UserSettings> {
    this.isLoading$.next(true);
    const data = {
      user: newSettings.UserId,
      sandbox_hide_alloc_numbers: newSettings.SandboxHideAllocNumbers,
      sandbox_indices: newSettings.SandboxIndices,
      sandbox_assets: newSettings.SandboxAssets,
      active_portfolios: newSettings.ActivePortfolios,
      default_performance_period: newSettings.DefaultPerfomancePeriod,
      achilles_enabled: newSettings.AchillesEnabled,
    };
    return this._httpClient.put<UserSettingsResponse>(`${this._userSettingsUrl}${newSettings.UserId}/`, data).pipe(
      map(response => {
        if (response instanceof Error) {
          throw response;
        }
        return response;
      }),
      mergeMap((response) => this.hydrateSandboxIndices(response)),
      map(response => {
        const outSettings = new UserSettings();
        outSettings.UserId = response['user'];
        outSettings.SandboxHideAllocNumbers = response['sandbox_hide_alloc_numbers'];
        outSettings.SandboxIndices = (response.sandbox_indices === '' || response.sandbox_indices == null) ? [] :
          response.sandbox_indices as SandboxIndexGroup[];
        outSettings.SandboxAssets = (response.sandbox_assets === '' || response.sandbox_assets == null) ? [] :
          response.sandbox_assets as SandboxAssetGroup[];
        outSettings.ActivePortfolios = (response.active_portfolios === '' || response.active_portfolios == null) ? [] :
          response.active_portfolios;
        outSettings.DefaultPerfomancePeriod = response['default_performance_period'];
        outSettings.AchillesEnabled = response['achilles_enabled'] == null ? false : response.achilles_enabled;
        return outSettings;
      }),
      tap(settings => { this._userSettings$ = null; this._userSettingsSubject.next(settings); }),
      finalize(() => {
        this.isLoading$.next(false);
      }),
    );
  }

}
