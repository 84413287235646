import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataTableColumn, DataTableBreadcrumb } from '../../shared/data-table/data-table-models';
import { MarketBrowserTableData, MarketBrowserSubAssetClassItem } from './market-browser-table-data';
import { sortSubAssetClassColumn } from 'app/account-data/asset-class-sorting';

function capFirst(s: string) {
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

@Component({
  selector: 'app-market-browser',
  templateUrl: './market-browser.component.html',
  styleUrls: ['./market-browser.component.css']
})
export class MarketBrowserComponent implements OnInit {

  @Input() set tableData(inData: MarketBrowserTableData) {
    this.initTables(inData);
  }
  @Input() tableOptions = {};
  @Input() showBreadCrumbs = true;
  @Output() selectedMarket = new EventEmitter<number>();

  breadcrumbs: DataTableBreadcrumb[] = null;
  inData: MarketBrowserTableData = null;

  assetClassesColumns: DataTableColumn[];
  assetClassesItemsOut: Object[];

  subAssetClassesColumns: DataTableColumn[];
  subAssetClassesItemsOut: MarketBrowserSubAssetClassItem[];

  productColumns: DataTableColumn[];
  productItemsOut: Object[];

  selectedAssetClassIds: Set<number>;
  selectedSubAssetClassIds: Set<string>;
  selectedProductIds: Set<number>;

  constructor() { }

  /**
   * Respond to clicked breadcrumb
   */
  breadcrumbClicked(crumb: DataTableBreadcrumb) {
    switch (crumb.type) {
      case 'assetClass': {
        this.selectedAssetClassIds.clear();
        this.selectedSubAssetClassIds.clear();
        this.selectedProductIds.clear();
        this.filterTables();
        this.updateBreadCrumbs();
      } break;
      case 'subAssetClass': {
        this.selectAllAssetClasses();
        this.selectedSubAssetClassIds.clear();
        this.selectedProductIds.clear();
        this.filterTables();
        this.updateBreadCrumbs();
      } break;
      case 'product': {
        this.selectAllAssetClasses();
        this.selectAllSubAssetClasses();
        this.selectedProductIds.clear();
        this.filterTables();
        this.updateBreadCrumbs();
      } break;
      case 'assetClassProducts': {
        // Show only products in asset class <id>
        this.selectedAssetClassIds.clear();
        this.selectedSubAssetClassIds.clear();
        // select asset class
        const theAc = this.inData.assetClasses.items.find(acRow => acRow.id === Number(crumb.id));
        this.selectedAssetClassIds.add(Number(theAc.id));
        // select sub asset classes in asset class
        for (const sub of this.inData.subAssetClasses.items) {
          if (sub.assetClassId === Number(crumb.id)) {
            this.selectedSubAssetClassIds.add(sub.id.toString());
          }
        }
        this.productColumns[0].label = `All ${theAc.assetClassName.toLowerCase()} markets`;
        this.selectedProductIds.clear();
        this.filterTables();
        this.updateBreadCrumbs();
      } break;
    }
  }

  /**
   * Rebuild table contents based on selected* sets
   */
  filterTables() {
    if (this.inData == null) {
      return;
    }

    // Asset classes
    this.assetClassesItemsOut = this.inData.assetClasses.items;

    if (this.selectedAssetClassIds.size > 0) {
      this.subAssetClassesItemsOut = this.inData.subAssetClasses.items.filter(sub => this.selectedAssetClassIds.has(sub['assetClassId']));
      sortSubAssetClassColumn(this.subAssetClassesItemsOut);
    }

    if (this.selectedSubAssetClassIds.size > 0) {
      this.productItemsOut = this.inData.products.items.filter(prod => this.selectedSubAssetClassIds.has(prod['subAssetClassId']));
    }
    this.updateBreadCrumbs();
  }

  /**
   * Reset and initialize market browser tables
   */
  initTables(inData: MarketBrowserTableData) {
    if (inData == null) {
      this.reset();
      return;
    }
    this.inData = inData;
    this.assetClassesColumns = inData.assetClasses.columns;
    this.assetClassesItemsOut = inData.assetClasses.items;
    this.selectedAssetClassIds = new Set<number>();

    this.subAssetClassesColumns = inData.subAssetClasses.columns;
    this.subAssetClassesItemsOut = inData.subAssetClasses.items;
    this.selectedSubAssetClassIds = new Set<string>();

    this.productColumns = inData.products.columns;
    this.productItemsOut = inData.products.items;
    this.selectedProductIds = new Set<number>();

    if (this.showBreadCrumbs) {
      this.updateBreadCrumbs();
    }
  }

  ngOnInit() {
  }

  selectItemFromTable(table: string, selection: Object) {
    switch (table) {
      case 'assetClass': {
        for (const [id, value] of Object.entries(selection)) {
          const idNum = Number(id);
          if (value === 'on' && !isNaN(idNum)) {
            this.selectedAssetClassIds.add(idNum);
          } else {
            if (this.selectedAssetClassIds.has(idNum)) {
              this.selectedAssetClassIds.delete(idNum);
            }
          }
        }
      } break;
      case 'subAssetClass': {
        for (const [id, value] of Object.entries(selection)) {
          if (value === 'on' && id) {
            this.selectedSubAssetClassIds.add(id);
          } else {
            if (this.selectedSubAssetClassIds.has(id)) {
              this.selectedSubAssetClassIds.delete(id);
            }
          }
        }
        if (this.selectedSubAssetClassIds.size === 1) {
          const subId = this.selectedSubAssetClassIds.values().next().value;
          const subName: string = this.inData.subAssetClasses.items.find(subRow => subRow.id === subId).subAssetClassName;
          this.productColumns[0].label =  `${capFirst(subName)} markets`;
        }
      } break;
      case 'product': {
        for (const [id, value] of Object.entries(selection)) {
          const idNum = Number(id);
          if (value === 'on' && !isNaN(idNum)) {
            this.selectedProductIds.add(idNum);
            this.selectedMarket.emit(idNum);
          } else {
            if (this.selectedProductIds.has(idNum)) {
              this.selectedProductIds.delete(idNum);
            }
          }
        }
      } break;
    }
    this.filterTables();
  }

  showAssetClassTable() {
    return this.selectedAssetClassIds == null || this.selectedAssetClassIds.size === 0;
  }

  showSubAssetClassTable() {
    return this.selectedSubAssetClassIds != null && this.selectedAssetClassIds.size > 0 && this.selectedSubAssetClassIds.size === 0;
  }

  showProductTable() {
    return this.selectedSubAssetClassIds != null && this.selectedSubAssetClassIds.size > 0;
  }

  updateBreadCrumbs() {
    if (this.inData === null) {
      // Clear and abort
      this.reset();
      return;
    }

    const acBreadcrumb = { id: 'all', label: 'All asset classes', selected: false, type: 'assetClass' };
    const subBreadcrumb = { id: 'all', label: 'All sectors', selected: false, type: 'subAssetClass' };
    const prodBreadcrumb = { id: 'all', label: 'All markets', selected: false, type: 'product' };
    this.breadcrumbs = [acBreadcrumb];
    if (this.selectedAssetClassIds.size === 0) {
      this.breadcrumbs[0].selected = true;
      this.assetClassesColumns[0].label = 'All asset classes';
    } else {
      this.assetClassesColumns[0].label = 'Asset classes';
    }
    this.breadcrumbs.push(subBreadcrumb);
    if (
      this.inData.assetClasses.items != null
      && this.selectedAssetClassIds.size === this.inData.assetClasses.items.length
      && this.selectedSubAssetClassIds.size === 0
    ) {
      // Showing all sectors
      this.breadcrumbs[1].selected = true;
      this.subAssetClassesColumns[0].label = 'All sectors';
    } else {
      if (this.selectedAssetClassIds.size === 1) {
        if (this.selectedSubAssetClassIds.size === 1) {
          // showing markets for one sub asset class
          const subId = this.selectedSubAssetClassIds.values().next().value;
          const subName: string = this.inData.subAssetClasses.items.find(subRow => subRow.id === subId).subAssetClassName;
          this.productColumns[0].label =  `${capFirst(subName)} markets`;
        } else {
          // showing sectors for one asset class
          const acId = this.selectedAssetClassIds.values().next().value;
          const acName: string = this.inData.assetClasses.items.find(acRow => acRow.id === acId).assetClassName;
          this.subAssetClassesColumns[0].label = `${capFirst(acName)} sectors`;

          // Show 'All <sector> markets button' only in sector table
          if (this.selectedSubAssetClassIds.size === 0) {
            // showing sectors for one asset class
            this.breadcrumbs.push({ id: acId, label: `All ${acName.toLowerCase()} markets`, selected: false, type: 'assetClassProducts'});
          }
        }
      } else {
        this.subAssetClassesColumns[0].label = 'Sectors';
      }
    }
    if (this.breadcrumbs.length === 2) {
      // Only show 'All markets' if 'All <sector> markets' not in list
      this.breadcrumbs.push(prodBreadcrumb);
    }
    if (
      this.inData.subAssetClasses.items != null
      && this.selectedSubAssetClassIds.size === this.inData.subAssetClasses.items.length
      && this.selectedProductIds.size === 0
    ) {
      this.breadcrumbs[this.breadcrumbs.length - 1].selected = true;
      this.productColumns[0].label = 'All markets';
    } else {
      // this.productColumns[0].label = 'Products';
    }
  }

  private reset() {
    if (this.selectedAssetClassIds) {
      this.selectedAssetClassIds.clear();
    }
    if (this.selectedSubAssetClassIds) {
      this.selectedSubAssetClassIds.clear();
    }
    if (this.selectedProductIds) {
      this.selectedProductIds.clear();
    }
    this.assetClassesItemsOut = null;
    this.subAssetClassesItemsOut = null;
    this.productItemsOut = null;
    this.breadcrumbs = null;
  }

  private selectAllSubAssetClasses() {
    this.inData.subAssetClasses.items.map(sub => this.selectedSubAssetClassIds.add(sub.id.toString()));
  }

  private selectAllAssetClasses() {
    this.inData.assetClasses.items.map(ac => this.selectedAssetClassIds.add(Number(ac.id)));
  }


}
