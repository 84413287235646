import * as moment from 'moment-timezone';

export { buildReturnChartParams } from './charts';
export { TZ } from './constants';
import { TZ } from './constants';


export const beginningOfTime = moment.tz('1949-12-31T00:00:00', TZ);

export function getOffsetDays(date: Date) {
  switch (date.getDay()) {
    case 0: return 2;
    case 1: return 3;
    default: return 1;

  }
}

export function getOffsetStartDays(date: Date) {
  switch (date.getDay()) {
    case 0: return 2;
    case 6: return 1;
    default: return 0;
  }
}

export function getOffsetPeriodStartDate(endDate: Date, period: string) {
  let startDate: Date = null;
  switch (period) {
    case 'Today' : {
      startDate = null;
    } break;
    case 'Last day': {
      startDate = moment.tz(endDate, TZ).toDate();
    } break;
    case 'MTD': {
      startDate = moment.tz(endDate, TZ).startOf('month').toDate();
    } break;
    case 'YTD': {
      startDate = moment.tz(endDate, TZ).startOf('year').toDate();
    } break;
    case '3 months': {
      startDate = moment.tz(endDate, TZ).subtract(3, 'months').add(1, 'day').toDate();
    } break;
    case '6 months': {
      startDate = moment.tz(endDate, TZ).subtract(6, 'months').add(1, 'day').toDate();
    } break;
    case '1 year': {
      startDate = moment.tz(endDate, TZ).subtract(1, 'year').add(1, 'day').toDate();
    } break;
    case 'All': {
      startDate = beginningOfTime.toDate();
    } break;
  }
  return startDate;
}

export function numDigits(x: number): number {
  const a = Number(x);
  const exp_s = a.toExponential();
  const n = parseInt(exp_s.substring(exp_s.indexOf('e') + 1), 10);
  return n < 0 ? n : n + 1;
}

