import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as json5 from 'json5';

import { Account, Index, HoldingsOverview, RiskOverview, LandingPageData, DailyReturnData, LandingPageDailyData, AssetClassSignalStrength } from '../api/webservice.service';
import { AcctIdx } from './acct-idx';
import { RemoteFileInfo } from '@app/api/psp-documents.service';
import { AccountIndexDescription } from './account-data.service';

interface DatedCacheItem<T> {
  date: number;
  data: T;
}

function escapePilcrow(value: string) {
  return value.replace('¶', '\U00BG');
}

const CACHE_EXPIRATION_TIME_24H = 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class AccountDataCacheService {
  public _loadAcctIdxCache$: Observable<[AcctIdx[], Account[], Index[]]> = null;
  public _loadAcctIdxFilteredCache$: Observable<[AcctIdx[], Account[], Index[]]> = null;


  riskOverviewCache = new Map<string, RiskOverview>();
  holdingsCache = new Map<string, HoldingsOverview>();

  /** The in memory cache table
   * cacheId -> userId -> key -> cacheItem -> data
  */
  private caches = new Map<string, Map<number, Map<string, DatedCacheItem<any>>>>();

  constructor() {
    
  }

  clearCache(cacheId: string) {
    return this.caches.delete(cacheId);
  }

  clearUserCache() {
    this._loadAcctIdxCache$ = null;
    this._loadAcctIdxFilteredCache$ = null;
    this.caches.clear();
    this.holdingsCache = new Map();
    this.riskOverviewCache = new Map();
  }

  genHoldingsCacheId(userId: number, accountId: number | string, dateStr: string) {
    return `${userId}_${accountId}_${dateStr}`;
  }

  getAccountAssetClassSignalStrengths(userId: number, accountId: string, startDate: string, endDate: string): AssetClassSignalStrength[] {
    const itemKey = this._genCacheId(accountId, startDate, endDate);
    return this.getCacheItem<AssetClassSignalStrength[]>('acStrengths', userId, itemKey, CACHE_EXPIRATION_TIME_24H);
  }

  getAccountIndexDescriptions(userId: number) {
    return this.getCacheItem<AccountIndexDescription[]>('accountDescriptions', userId, '_single_', CACHE_EXPIRATION_TIME_24H, true);
  }
  
  getCachedAccounts(userId: number, maxAge: number) {
    return this.getCacheItem<Account[]>('accounts', userId, '_single_', maxAge, true);
  }

  getCachedAccountDocuments(userId: number, accountId: string, maxAge = CACHE_EXPIRATION_TIME_24H) {
    return this.getCacheItem<RemoteFileInfo[]>('accountDocuments', userId, accountId, maxAge);
  }

  getCachedDailyReturnData(userId: number, accountId: string | number, fromDate: string, toDate: string): DailyReturnData {
    return this.getCacheItem<DailyReturnData>(
      'dailyReturnData',
      userId,
      this._genCacheId(accountId, fromDate, toDate),
      8 * 60 * 60 * 1000 // 8h
    );
  }

  getCachedIndices(userId: number, maxAge: number): Index[] {
    return this.getCacheItem<Index[]>('indices', userId, '_single_', maxAge, true);
  }

  getCachedLandingPage(userId, maxAge: number): LandingPageData {
    return this.getCacheItem<LandingPageData>('landingPage', userId, '_single_', maxAge);
  }

  getCachedLandingPageDaily(userId: number, dataDate: string, maxAge: number) {
    return this.getCacheItem<LandingPageDailyData>('landingPageDaily', userId, this._genCacheId(dataDate), maxAge);
  }

  getCacheItem<T>(cacheId: string, userId: number, key = '_default_', maxAge: number, useStorage = false): T {
    const cache = this.getCache<T>(cacheId, userId);
    let cacheItem = cache.get(key);
    if (cacheItem === undefined) {
      if (useStorage) {
        cacheItem = this.getSessionCacheItem(userId, cacheId, key);
        if (cacheItem == null) {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
    if (new Date().valueOf() - cacheItem.date < maxAge) {
      return cacheItem.data;
    } else {
      cache.delete(key);
      return undefined;
    }
  }

  getSessionCacheItem(userId: number, cacheId: string, key: string): any {
    if (window.sessionStorage == null) {
      return null;
    }
    const sessionCacheKey = `${userId}¶${cacheId}¶${key}`;
    const itemJson = sessionStorage.getItem(sessionCacheKey);
    if (itemJson != null) {
      return  json5.parse(itemJson);
    } else {
      return undefined;
    }
  }
  // this._cache.updateAccountAssetClassessSignalStrengths(userId, accountId, startDate, endDate, acStrengths)

  updateAccountAssetClassSignalStrengths(userId: number, accountId: string, startDate: string, endDate: string,  acStrengths: AssetClassSignalStrength[]) {
    const itemKey = this._genCacheId(accountId, startDate, endDate);
    this.storeCacheItem<AssetClassSignalStrength[]>('acStrenghts', userId, itemKey, acStrengths, false);
  }

  updateAccountIndexDescriptions(userId: number, descriptions: AccountIndexDescription[]) {
    this.storeCacheItem<AccountIndexDescription[]>('accountDescriptions', userId, '_single_', descriptions, true);
  }
  
  updateCachedAccounts(userId: number, accounts: Account[]) {
    this.storeCacheItem<Account[]>('accounts', userId, '_single_', accounts, true);
  }

  updateCachedAccountDocuments(userId: number, accountId: string, documents: RemoteFileInfo[]) {
    this.storeCacheItem<RemoteFileInfo[]>('accountDocuments', userId, accountId, documents, false);
  }

  updateCachedDailyReturnData(userId: number, accountId: string | number, fromDate, toDate, data: DailyReturnData) {
    this.storeCacheItem<DailyReturnData>(
      'dailyReturnData',
      userId,
      this._genCacheId(accountId, fromDate, toDate),
      data
    );
  }

  updateCachedIndices(userId: number, indices: Index[]) {
    this.storeCacheItem<Index[]>('indices', userId, '_single_', indices, true);
  }

  updateLandingPage(userId: number, landingPage: LandingPageData) {
    this.storeCacheItem<LandingPageData>('landingPage', userId, '_single_', landingPage);
  }

  updateLandingPageDaily(userId: number, dataDate: string, landingPage: LandingPageDailyData) {
    this.storeCacheItem<LandingPageDailyData>('landingPageDaily', userId, this._genCacheId(dataDate), landingPage);
  }

  storeCacheItem<T>(cacheId: string, userId: number, key = '_default_', data: T, storeSession = false) {
    const cache = this.getCache<T>(cacheId, userId);
    const cacheItem = {
      date: new Date().valueOf(),
      data: data,
    } as DatedCacheItem<T>;
    cache.set(key, cacheItem);
    if (storeSession) {
      this.storeSessionCacheItem(userId, cacheId, key, cacheItem);
    }
  }

  storeSessionCacheItem(userId: number, cacheId: string, key: string, value: any): any {
    if (window.sessionStorage == null) {
      return null;
    }
    const sessionCacheKey = `${userId}¶${cacheId}¶${key}`;
    const itemJson = json5.stringify(value);
    try {
      sessionStorage.setItem(sessionCacheKey, itemJson);
    } catch (DOMException) {
      // storage full or private mode on in Safari
    }
  }

  private _genCacheId(...args: Array<string|number>): string {
    return args.map(arg => arg.toString()).join('_');
  }

  private getCache<T>(cacheId: string, userId: number) {
    let cacheTable = this.caches.get(cacheId);
    if (cacheTable === undefined) {
      cacheTable = new Map<number, Map<string, DatedCacheItem<T>>>();
      this.caches.set(cacheId, cacheTable);
    }
    let userCache = cacheTable.get(userId);
    if (userCache === undefined) {
      userCache = new Map<string, DatedCacheItem<T>>();
      cacheTable.set(userId, userCache);
    }

    return userCache;
  }
}
