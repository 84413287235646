import { SandboxIndexGroup } from './sandbox-index-group';
import { ActivePortfolio } from './active-portfolio';
import { SandboxAssetGroup } from './sandbox-asset-group';

export class UserSettings {
    UserId: number;
    SandboxIndices: SandboxIndexGroup[];
    SandboxAssets: SandboxAssetGroup[];
    SandboxHideAllocNumbers: boolean;
    ActivePortfolios: ActivePortfolio[];
    DefaultPerfomancePeriod: string;
    AchillesEnabled: boolean;
}
