import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable()
export class AuthFailedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(event => {}, err => {
        if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)
          && !(err.headers['url'] != null && err.headers['url'].match(/\/(logout|login)\/$/))) {
          if (err.error['detail'] != null && err.error['detail'] === 'Authentication credentials were not provided.') {
            this.authService.logout(true); // clear session and make user log in again
          }
        }
      }),
    )
  }
}