// Angular
import { LayoutModule } from '@angular/cdk/layout';
import { PercentPipe, CurrencyPipe, registerLocaleData } from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgModule, ErrorHandler, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Third-party
import { SpinnerModule, SpinnerService } from '@chevtek/angular-spinners';
import { NgProgressModule } from '@ngx-progressbar/core';
import { SimpleNotificationsModule, optionsFactory } from 'angular2-notifications';
import { HighchartsChartModule } from 'highcharts-angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CustomFormsModule } from 'ng2-validation';

// Local
import { AccountDataCacheService } from './account-data/account-data-cache.service';
import { AccountDataService } from './account-data/account-data.service';
import { AdminPortalRootComponent } from './admin-portal/admin-portal-root/admin-portal-root.component';
import { PspDocumentsService } from './api/psp-documents.service';
import { PspWebService } from './api/webservice.service';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app-service.service';
import { AppComponent } from './app.component';
import { AssetclassPositioningComponent } from './assetclass-positioning/assetclass-positioning.component';
import { AuthenticationService } from './auth/authentication.service';
import { CanActivateSandboxGuard } from './auth/can-activate-sandbox.guard';
import { CanActivateSandboxProtoGuard } from './auth/can-activate-sandbox-proto.guard';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { MustBeAuthenticatedGuard } from './auth/must-be-authenticated.guard';
import { HttpClientService } from './http-client.service';
import { httpInterceptorProviders } from './http/interceptor-providers';
import { MarketChartComponent } from './market-chart/market-chart.component';
import { MenuService } from './menu.service';
import { NavbarButtonService } from './shared/navbar-button/navbar-button.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AccountOverviewComponent } from './portal/account-overview/account-overview.component';
import { AccountPositionsComponent } from './portal/account-positions/account-positions.component';
import { AccountTradingLevelComponent } from './portal/account-trading-level/account-trading-level.component';
import { AccountComponent } from './portal/account/account.component';
import { AssetClassSignalStrengthComponent } from './portal/asset-class-signal-strength/asset-class-signal-strength.component';
import { DashboardComponent } from './portal/dashboard/dashboard.component';
import { DisclaimerComponent } from './portal/disclaimer/disclaimer.component';
import { DocumentsComponent } from './portal/documents/documents.component';
import { MarketBrowserComponent } from './portal/market-browser/market-browser.component';
import { PortalRootComponent } from './portal/portal-root/portal-root.component';
import { RiskBreakdownComponent } from './risk-breakdown/risk-breakdown.component';
import { OrderBreakdownComponent } from './order-breakdown/order-breakdown.component';
import { RiskNavbarComponent } from './risk-navbar/risk-navbar.component';
import { SandboxDataService } from './sandbox-data.service';
import { SettingsDataModule } from './settings-data/settings-data.module';
import { SharedModule } from './shared/shared.module';
import { WindowRefService } from './window-ref.service';
import { StickyBitsDirective } from './directives/sticky-bits.directive';
import { AccountSignalDynamicsComponent } from './portal/account-signal-dynamics/account-signal-dynamics.component';
import { WstestComponent } from './wstest/wstest.component';
import { AccountOverviewModule } from './account-overview/account-overview.module';
import { GlobalErrorHandler } from './utils/logging/global-error-handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PositivePipe } from './shared/pipes/positive.pipe';
import { FirstTimeComponent } from './auth/first-time/first-time.component';
import { CookieService } from 'ngx-cookie-service';
import { AppRefreshService } from './app-refresh.service';
import { KYCComponent } from './portal/kyc/kyc.component';

// @angular/material components
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';



import localeFI from '@angular/common/locales/fi';
import { KycSubQuizComponent } from './portal/kyc/components/kyc-sub-quiz/kyc-sub-quiz.component';
import { KycSustainabilityEstimateComponent } from './portal/kyc/components/kyc-sustainability-estimate/kyc-sustainability-estimate.component';

export function hostFactory() {
  return window.location.hostname;
}

export function localStorageFactory() {
  return window.localStorage;
}

registerLocaleData(localeFI);


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    DashboardComponent,
    AccountOverviewComponent,
    AdminPortalRootComponent,
    PortalRootComponent,
    PageNotFoundComponent,
    AccountComponent,
    RiskNavbarComponent,
    RiskBreakdownComponent,
    OrderBreakdownComponent,
    AssetclassPositioningComponent,
    DocumentsComponent,
    DisclaimerComponent,
    MarketChartComponent,
    AssetClassSignalStrengthComponent,
    AccountTradingLevelComponent,
    AccountPositionsComponent,
    MarketBrowserComponent,
    StickyBitsDirective,
    AccountSignalDynamicsComponent,
    WstestComponent,
    FirstTimeComponent,
    KYCComponent,
    KycSubQuizComponent,
    KycSustainabilityEstimateComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    FormsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    NgProgressModule.withConfig({ color: '#eb6e21'}),
    SharedModule.forRoot(),
    SpinnerModule,
    SettingsDataModule,
    SimpleNotificationsModule.forRoot(),
    SharedModule,
    AccountOverviewModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AppService,
    AppRefreshService,
    AuthenticationService,
    AccountDataCacheService,
    AccountDataService,
    CanActivateSandboxGuard,
    CanActivateSandboxProtoGuard,
    CookieService,
    CurrencyPipe,
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: 'HOSTNAME',
      useFactory: hostFactory,
    },
    {
      provide: 'LOCAL_STORAGE',
      useFactory: localStorageFactory,
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    MenuService,
    HttpClientService,
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MustBeAuthenticatedGuard,
    NavbarButtonService,
    PercentPipe,
    PositivePipe,
    PspDocumentsService,
    PspWebService,
    SandboxDataService,
    SpinnerService,
    WindowRefService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
