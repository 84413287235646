<i 
  class="help-icon fa fa-info-circle origin" 
  #overlayOrigin="cdkOverlayOrigin"
  cdkOverlayOrigin
  [class.active]="showPopup && centeredOn == null"
  (click)="showHelpPopup($event, true)"
  (mouseover)="showHelpPopup($event, false)"
  (mouseout)="leaveHelpPopup()"
></i>

<ng-template #helpMessage 
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="showPopup && centeredOn == null"
  cdkConnectedOverlayFlexibleDimensions="true"
  (detach)="connectedOverlayDetach()"
>
  <aside class="message help-popup-message" (mouseenter)="leaveHelpPopup()">
    <div class="icon">
      <i class="fa fa-info-circle" title="Help"></i>
    </div>
    <section class="message-body">
        <button *ngIf="clicked" class="is-pulled-right delete" (click)="closeHelpPopup()" title="Close"></button>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </section>
  </aside>
</ng-template>

<ng-template #centeredMessage>
  <aside class="message help-popup-message">
    <div class="icon">
      <i class="fa fa-info-circle" title="Help"></i>
    </div>
    <section class="message-body">
        <button class="is-pulled-right delete" (click)="closeHelpPopup()" title="Close"></button>
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </section>
  </aside>
</ng-template>

<!-- can only use ng-content once in view so we break it out into separate template -->
<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
