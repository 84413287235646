import { CombinedIndex, datetime } from '../../api/webservice.service';
import { SandboxPortfolioMetaResponse } from './sandbox-portfolio-meta-response';

export class SandboxCombinedIndexMeta {
    Id?: number;
    CombinedIndex: CombinedIndex;
    LastModifiedDate: datetime;
    Starred: boolean;
    Hidden: boolean;

    get Name() {
        if (this.CombinedIndex != null) {
            return this.CombinedIndex.Index.Name;
        } else {
            return null;
        }
    }

    constructor(init?: SandboxCombinedIndexMeta | SandboxPortfolioMetaResponse, index?: CombinedIndex) {
        if (init && 'CombinedIndex' in init) {
            this.Id = init.Id;
            this.CombinedIndex = init.CombinedIndex;
            this.LastModifiedDate = init.LastModifiedDate;
            this.Starred = init.Starred;
            this.Hidden = init.Hidden;
        }
        if (init instanceof SandboxPortfolioMetaResponse) {
            this.Id = init.id;
            this.Hidden = init.hidden;
            this.LastModifiedDate = init.lastModifiedDate;
            this.Starred = init.starred;
        }
        if (index instanceof CombinedIndex) {
            this.CombinedIndex = index;
        }
    }

    toString() {
        return this.Name || 'Unnamed portfolio';
    }
}
