class QuestionOptionConfigSlider {
    digitInfo = '1.0-0';
    max: number;
    maxLabel?: string;
    private _middle: number;
    middleLabel?: string;
    min = 0;
    minLabel?: string;
    unit?: string;

    constructor(init?: Partial<QuestionOptionConfigSlider>) {
        if (init['digitInfo']) {
            this.digitInfo = init.digitInfo;
        }
        if (init['max']) {
            this.max = init.max;
        }
        if (init['middle']) {
            this.middle = init.middle;
        }
        if (init['min']) {
            this.min = init.min;
        }
        if (init['maxLabel']) {
            this.maxLabel = init.maxLabel;
        }
        if (init['minLabel']) {
            this.minLabel = init.minLabel;
        }
        if (init['middleLabel']) {
            this.middleLabel = init.middleLabel;
        }
        if (init['unit']) {
            this.unit = init.unit;
        }
    }

    get middle(): number {
        if (this._middle == null) {
            return (this.min + this.max) / 2;
        }
        return this._middle;
    }

    set middle(val: number) {
        this._middle = val;
    }
}

export class Option {
    id: number;
    questionId: number;
    name: string;
    selected: boolean;
    linkedQuestionId?: number;

    /** Config object - used for slider */
    config?: QuestionOptionConfigSlider;

    /** default value of option */
    value?: string | number | boolean;

    constructor(data: Partial<Option>) {
        data = data || {};
        this.id = data.id;
        this.questionId = data.questionId;
        this.name = data.name;
        this.selected = data.selected;
        if (data['linkedQuestionId'] != null) {
            this.linkedQuestionId = data.linkedQuestionId;
        }
        if (data['value'] != null) {
            this.value = data.value;
        }
        if (data['config'] != null) {
            this.config = new QuestionOptionConfigSlider(data.config);
        }
    }
}
