import { Injectable } from '@angular/core';

function getWindow(): any {
  return window;
}

@Injectable()
export class WindowRefService {
  nativeWindow: Window;
  passiveSupported = false;
  constructor() {
    this.nativeWindow = getWindow();
    let _passiveSupported;
    try {
      const options = Object.defineProperty({}, 'passive', {
        get: function() {
          _passiveSupported = true;
        }
      });

      this.nativeWindow.addEventListener('test', options, options);
      this.nativeWindow.removeEventListener('test', options, options);
    } catch (err) {
      _passiveSupported = false;
    }
    this.passiveSupported = _passiveSupported;
  }
}
