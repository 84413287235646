<ng-template #loadingIcon>
  <app-ui-loading-indicator-inline></app-ui-loading-indicator-inline>
</ng-template>
<ng-template #box_placeholder>
  <div class="box placeholder">
    <h3 class="box-title">&nbsp;</h3>
    <div class="box-body">&nbsp;</div>
  </div>
</ng-template>
<app-overview-menu #portfolioNavigationBar></app-overview-menu>

<div 
  class="performanceandrisk-container-after-fixed-div"
  [ngStyle]="{ marginTop: 'calc(' + (overviewMenuHeight$|async) + 'px + 2rem)' }"
  >
   <!-- Overview section -->
  <app-ui-card  >
    <div class="card-title">
      <h1>Overview<span *ngIf="activeAcctIdx$|async as acctIdx"> &mdash; {{acctIdx?.Name}}
        <app-help-popup *ngIf="(activeAcctIdxDescription$|async) as aiDesc">{{aiDesc?.portfolio_description}}</app-help-popup>
      </span></h1>
      <app-overview-date-picker></app-overview-date-picker>
    </div>
    <a class="anchor" id="overview"></a>
    <div class="overview">
      <div class="overview-grid">
        <ng-container *ngIf="showIntraday">
          <header>
            <h2 class="subtitle is-marginless">Today's status</h2>
            <p style="margin: 0.25rem 0; font-size: 0.67rem;">(All times are in CET + 1)</p>
          </header>
          <div class="box intraday">
            <h3 class="box-title">{{ accountPerformance?.time | date: 'dd-MMM-yyyy HH:mm' || 'Loading...' }}
            </h3>
            <div class="box-body">
              {{ fmtReturn(accountPerformance?.performance, '+1.2-2') }}
              <spinner name="account-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
            </div>
          </div>
          <div class="box intraday" *ngIf="!isIndex(accountId) && showIntraday && this.intradayOrders?.length > 0">
            <h3 class="box-title">Number of orders</h3>
            <div class="box-body">{{ intradayOrders?.length }}
              <spinner name="intraday-orders"><span *ngIf="false; else loadingIcon"></span></spinner>
            </div>
          </div>
          <div class="box intraday" *ngIf="!isIndex(accountId) && showIntraday && this.intradayOrders?.length > 0">
            <h3 class="box-title">Filled percentage 
              <app-help-popup>The ratio of completed orders to total orders placed today</app-help-popup></h3>
            <div class="box-body">{{ intradayOrdersFullfillmentRatio | percent:'1.1-1' }}
              <spinner name="intraday-orders"><span *ngIf="false; else loadingIcon"></span></spinner>
            </div>
          </div>
          <app-account-trading-level
            *ngIf="!isIndex(accountId)"
            class="box intraday"
            [userId]="userId"
            [accountId]="accountId"
            [date]="selectedDate$|async"
          >
          </app-account-trading-level>
        </ng-container>
        <header>
          <h2>Performance 
          <span *ngIf="accountDailyPerformanceOverview != null; else loadingIcon">as of
            {{ accountDailyPerformanceOverview?.PerformanceDate | date: 'MMMM-dd-yyyy' }}</span></h2>
        </header>
        <div class="box">
          <h3 class="box-title">Daily</h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.Last, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box">
          <h3 class="box-title"><abbr title="Month-to-date performance.
Since inception if history begins after start of the month.">MTD</abbr></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.MonthToDate, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box">
          <div class="box-title"><abbr title="Year-to-date performance.
Since inception if history begins after start of the year.">YTD</abbr></div>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.YearToDate, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box">
          <h3 class="box-title"><abbr title="Performance over the previous three months.
Since inception if history shorter than three months.">3 months</abbr></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.ThreeMonth, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box">
          <h3 class="box-title"><abbr title="Performance over the previous six months.
Since inception if history shorter than six months.">6 months</abbr></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.SixMonth, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box">
          <h3 class="box-title"><abbr title="Performance over the previous twelve months.
Since inception if history shorter than twelve months.">1 year</abbr></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyPerformanceOverview?.DailyPerformanceAggregations.OneYear, '+1.2-2') }}
            <spinner name="account-daily-performance"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <header>
          <h2>Risk</h2>
        </header>
        <div class="box risk" *ngIf="isNumber(accountDailyRiskOverview?.LastRiskInfo?.Delta.Delta)">
          <h3 class="box-title">Delta (0 - 100%) 
              <app-help-popup>Indication of the relative positioning of the strategy versus a theoretical maximum 100%</app-help-popup></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo?.Delta.Delta, '1.0-0') }}
            <spinner name="account-daily-risk"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box risk" *ngIf="isNumber(accountDailyRiskOverview?.LastRiskInfo?.Gamma.Gamma)">
          <h3 class="box-title">Gamma (reactivity)
              <app-help-popup>Indicates the estimated change in delta from a one standard deviation return in underlying markets.</app-help-popup></h3>
          <div class="box-body">
            {{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo?.Gamma.Gamma, '1.1-1') }}
            <spinner name="account-daily-risk"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
        <div class="box risk">
          <h3 class="box-title">Historical volatility 
              <app-help-popup>Daily return standard deviation over the last 365 calendar days annualized with the factor squareroot of 260 (260 trading days).</app-help-popup></h3>
          <div class="box-body">
              {{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo?.HistoricalVolatiltiy.Volatility, '1.1-1') }}
            <spinner name="account-daily-risk"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
          <div class="box risk">
            <h3 class="box-title">95% VaR
                <app-help-popup>Based on a historical simulation methodology assuming recent (30 day) volatility.</app-help-popup></h3>
            <div class="box-body">
              {{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo?.VaR95.VaR, '1.2-2') }}
              <spinner name="account-daily-risk"><span *ngIf="false; else loadingIcon"></span></spinner>
            </div>
        </div>
        <div class="box risk">
          <div class="box-title">Margin requirement 
              <app-help-popup>Estimation of the futures margin relative to the Account NAV.</app-help-popup></div>
          <div class="box-body">
            {{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo?.MarginRequirement.MarginRequirementToTradingLevel, '1.1-1') }}
              <spinner name="account-daily-risk"><span *ngIf="false; else loadingIcon"></span></spinner>
          </div>
        </div>
      </div>
    </div>
  </app-ui-card>

  <!-- Performance section -->
  <app-ui-card #perfCard [title]="'Performance'" [minimized]="perfCardCollapsed$|async" (minimizeToggle)="toggleSectionCollapsed('performance')">
    <a class="anchor" id="performance"></a>
    <div 
        #performanceNavbarWrapper
        class="navbar-wrapper"
        [hidden]="perfNavbarVisible$|async"
        [ngClass]="{ 
          'floating': navbarIsFloating(performanceNavbarWrapper)
        }"
        [ngStyle]="perfNavStyle$|async"
        stickybits
        [stickyOffset]="(overviewMenuHeight$|async) + 14"
    >
      <div class="navbar-inner-wrapper">
        <div class="navbar-content">
          <navbar-button #performanceBreakdownNavbar class="flex" 
            [disabled]="['Today']"
            [dropdownMode]="1"
            [initial]="performanceBreakdownTabsInitial" 
            [tabs]="performanceBreakdownTabs" 
            (selectionChanged)="ClickPerformancePeriodSelect($event)">
          </navbar-button>
        </div>
      </div>
    </div>
    <app-ui-collapsible-section #pfPerfSect subtitle="Portfolio performance" (collapsedChange)="updatePerfNavbarVisible()">
      <div *ngIf="dailyReturnData != null" style="position: relative">
        <spinner name="portfolio-daily-return">
          <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
        </spinner>
        <div class="chart-container">
          <div id="portfolio-daily-return" class="chart-element"></div>
        </div>
      </div>
    </app-ui-collapsible-section>

    <app-ui-collapsible-section
      #acPerfSect
      *ngIf="(assetClassesCumulativeReturns != null && assetClassesCumulativeReturns?.length != 0) || 
        (accountDailyPerformanceOverview != null && accountDailyPerformanceOverview?.AssetClassCumulativeReturns?.length != 0)"
      (collapsedChange)="updatePerfNavbarVisible()"
      style="position: relative; display: block;"
      subtitle="Asset class performance"
    >
      <spinner name="performance-breakdown" group="overview">
        <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
      </spinner>
      <div class="columns is-desktop">
        <div class="column" *ngIf="(layoutMode$|async) === 'wide'">
          <div class="chart-container">
            <div id="asset-class-cumulative-return" class="chart-element"></div>
          </div>
        </div>
        <div class="column">
          <div class="chart-container">
            <div id="asset-class-return"  class="chart-element"></div>
          </div>
        </div>
      </div>

      <div class="market-performance-tables" style="overflow-x:auto">
        <spinner name="asset-class-performance-table" group="overview">
          <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
        </spinner>
        <data-table 
          *ngIf="subAssetClassPerformanceTableData?.length === 0 && marketPerformanceTableData?.length === 0"
          [breadcrumbs]="marketPerformanceTableBreadcrumbs"
          [items]="assetClassPerformanceTableData" 
          [columns]="assetClassPerformanceTableColumns" 
          [options]="assetClassPerformanceTableOptions"
          [selected]="selectedItems['assetClassPerformance']" 
          (breadcrumbSelected)="marketPerformanceTableBreadcrumbClicked($event)"
          (selectionChanged)="tableSelectionChanged('assetClassPerformance', $event)"
          (sortChanged)="assetClassBreakdownPerformanceTableSort = $event">
        </data-table>
        <data-table 
          *ngIf="subAssetClassPerformanceTableData?.length > 0 && marketPerformanceTableData?.length === 0" 
          [breadcrumbs]="marketPerformanceTableBreadcrumbs"
          [items]="subAssetClassPerformanceTableData" 
          [columns]="subAssetClassPerformanceTableColumns"
          [maxItems]="this._marketPerformanceTableData?.length || 0"
          [options]="subAssetClassPerformanceTableOptions" 
          [selected]="selectedItems['subAssetClassPerformance']" 
          (breadcrumbSelected)="marketPerformanceTableBreadcrumbClicked($event)"
          (selectionChanged)="tableSelectionChanged('subAssetClassPerformance', $event)"
          (sortChanged)="assetClassBreakdownPerformanceTableSort = $event">
        </data-table>
        <data-table 
          *ngIf="marketPerformanceTableData?.length > 0" 
          [breadcrumbs]="marketPerformanceTableBreadcrumbs"
          [columns]="marketPerformanceTableColumns"
          [items]="marketPerformanceTableData" 
          [maxItems]="1" 
          [options]="marketPerformanceTableOptions" 
          [selected]="selectedItems['marketPerformance']" 
          (breadcrumbSelected)="marketPerformanceTableBreadcrumbClicked($event)"
          (selectionChanged)="tableSelectionChanged('market', $event)"
          (sortChanged)="assetClassBreakdownPerformanceTableSort = $event">
        </data-table>
      </div>
      <ng-template #no_breakdown_for_index>
        <table class="table" style="width:100%">
          <thead>
            <tr><th>Intra-day market beakdown is not available for this portfolio type.</th></tr>
          </thead>
        </table>
      </ng-template>
    </app-ui-collapsible-section>
    
    <!-- Style return-->
    <app-ui-collapsible-section #stylePerfSect
      *ngIf="(styleCumulativeReturns != null && styleCumulativeReturns?.length > 0 && flags.get('showStyles'))"
      (collapsedChange)="updatePerfNavbarVisible()"
      subtitle="Style performance" style="position: relative; display: block;">
      <spinner name="strategy-style-return">
        <div class="spinner-shield" (click)="spinnerService.hide('strategy-style-return')">
          <div class="spinner-indicator">
            <span>Loading...</span>
          </div>
        </div>
      </spinner>
      <div *ngIf="styleCumulativeReturns?.length === 0"><em>No strategy style performance data available.</em></div>
      <div class="columns">
        <div class="column" *ngIf="(layoutMode$|async) === 'wide'">
          <div class="chart-container">
            <div id="style-cumulative-return" class="chart-element"></div>
          </div>
        </div>
        <div class="column">
          <div class="chart-container">
            <div id="style-return" class="chart-element"></div>
          </div>
        </div>
      </div>
    </app-ui-collapsible-section>
  <!-- </div></div> -->
  </app-ui-card>

  <app-market-chart 
    style="display: block; width: 100%;"
    *ngIf="(selectedProduct$|async) as selectedProdId"
    [userId]="userId"
    [accountId]="accountId"
    (closed)="unselectMarkets($event)"
    [productId]="selectedProdId"
    [selectedDate]="selectedDate"
    [showIntraday]="showIntraday"
    >
  </app-market-chart>

  <!-- risk section-->
  <app-ui-card [title]="'Risk'" [minimized]="riskCardCollapsed$|async" (minimizeToggle)="toggleSectionCollapsed('risk')">
    <a class="anchor" id="risk"></a>
    <app-ui-collapsible-section [subtitle]="headlineFiguresTitle(accountDailyRiskOverview)">
      <a class="anchor" id="risk-headline"></a>
      <table id="instrument-table" class="table is-fullwidth">
        <thead>
          <tr>
            <th class="has-vertical-separator">Measure</th>
            <th class="has-vertical-separator has-text-centered"><span *ngIf="accountDailyRiskOverview; else loadingIcon">{{tzDate(accountDailyRiskOverview?.LastRiskInfo.Date) | date:'dd-MMM-yyyy'}}</span></th>
            <th class="has-text-centered"><span *ngIf="accountDailyRiskOverview; else loadingIcon">{{tzDate(accountDailyRiskOverview?.LastMonthEndRiskInfo.Date) | date:'dd-MMM-yyyy'}}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Volatility, historical 
              <app-help-popup>Daily return standard deviation over the last 365 calendar days annualized with 
              the factor square root of 260 (260 trading days).</app-help-popup>
            </th>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo.HistoricalVolatiltiy.Volatility, '1.1-1') }}</span></td>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastMonthEndRiskInfo.HistoricalVolatiltiy.Volatility, '1.1-1')}}</span></td>
          </tr>
          <tr>
            <th>Volatility, ex ante
              <app-help-popup>Ex-ante Volatility based on estimated volatility from end-of-day positions.</app-help-popup>
            </th>
            <td class="has-text-centered"><span *ngIf="volatilityExAnte != null">{{ fmtReturn(volatilityExAnte?.Last, '1.1-1') }}</span></td>
            <td class="has-text-centered"><span *ngIf="volatilityExAnte != null">{{ fmtReturn(volatilityExAnte?.LastMonth, '1.1-1') }}</span></td>
          </tr>
          <tr>
            <th>95% VaR 
              <app-help-popup>Based on a historical simulation methodology assuming recent (30 day) volatility.</app-help-popup>
            </th>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo.VaR95.VaR, '1.2-2') }}</span></td>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastMonthEndRiskInfo.VaR95.VaR, '1.2-2') }}</span></td>
          </tr>
          <tr>
            <th>Margin requirement
              <app-help-popup>Estimation of the futures margin relative to the Account NAV.</app-help-popup>
            </th>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo.MarginRequirement.MarginRequirementToTradingLevel, '1.1-1') }}</span></td>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastMonthEndRiskInfo.MarginRequirement.MarginRequirementToTradingLevel, '1.1-1') }}</span></td>
          </tr>
          <tr *ngIf="isNumber(accountDailyRiskOverview?.LastRiskInfo.Delta.Delta)">
            <th>Signal delta (% of max) 
                <app-help-popup>Indication of the relative positioning of the strategy versus a theoretical maximum 100%.</app-help-popup></th>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo.Delta.Delta, '1.1-1') }}</span></td>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastMonthEndRiskInfo.Delta.Delta, '1.1-1') }}</span></td>
          </tr>
          <tr *ngIf="isNumber(accountDailyRiskOverview?.LastRiskInfo.Gamma.Gamma)">
            <th>Signal gamma (reactivity)
              <app-help-popup>Indicates the estimated change in delta from a one standard deviation return in underlying markets.</app-help-popup>
              </th>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastRiskInfo.Gamma.Gamma, '1.1-1') }}</span></td>
            <td class="has-text-centered	"><span *ngIf="accountDailyRiskOverview">{{ fmtReturn(accountDailyRiskOverview?.LastMonthEndRiskInfo.Gamma.Gamma, '1.1-1') }}</span></td>
          </tr>
        </tbody>
      </table>
      <br>
    </app-ui-collapsible-section>

    <app-ui-collapsible-section *ngIf="accountId==='IGNIS'">
      <a class="anchor" id="risk-signal"></a>
      <app-asset-class-signal-strength
        [accountId]="accountId"
        [defaultPeriod]="defaultPeriod"
        [userId]="userId"
        [selectedDate]="getSelectedDate$()|async"
      ></app-asset-class-signal-strength>
    </app-ui-collapsible-section>

    <app-ui-collapsible-section subtitle="Signal dynamics" *ngIf="accountDailyRiskOverview?.AssetClassesDelta?.length > 0 || accountDailyRiskOverview?.AssetClassesGamma?.length > 0">
      <a class="anchor" id="risk-signal-dynamics"></a>
      <app-account-signal-dynamics 
      [accountId]="accountId"
      [endDate]="getSelectedDate()"
      [userId]="userId"
      (selectedMarket)="selectedProduct = $event"
      ></app-account-signal-dynamics>
    </app-ui-collapsible-section>

    <app-ui-collapsible-section subtitle="Asset class status">
      <a class="anchor" id="risk-assetclass-status"></a>
      <risk-breakdown-component
      [accountId]="accountId"
      [selectedDate]="selectedDate$|async"
      [userId]="userId"
      (selectedMarket)="selectedProduct = $event"
      ></risk-breakdown-component>
    </app-ui-collapsible-section>

    <app-ui-collapsible-section>
      <a class="anchor" id="risk-positions"></a>
      <app-account-positions
        [accountId]="accountId"
        [selectedDate]="selectedDate$|async"
        [userId]="userId"
        (selectedMarket)="selectedProduct = $event"
        ></app-account-positions>
    </app-ui-collapsible-section> 
    
    <app-ui-collapsible-section subtitle="Portfolio time series risk">
      <a class="anchor" id="risk-timeseries"></a>
      <app-account-ts-risk
        [userId]="userId"
        [accountId]="accountId"
        [selectedDate]="selectedDate$|async"
      >
      </app-account-ts-risk>
    </app-ui-collapsible-section>
  </app-ui-card>

  <!-- Orders section -->
  <app-ui-card #orderCard [title]="'Orders'" [minimized]="ordersCardCollapsed$|async" (minimizeToggle)="toggleSectionCollapsed('orders')"
               *ngIf="showIntradayOrders$|async">
    <a class="anchor" id="orders"></a>
    <app-ui-collapsible-section subtitle="Todays orders">
      <a class="anchor" id="order-assetclass-status"></a>
      <order-breakdown-component
        [accountId]="accountId"
        [selectedDate]="selectedDate$|async"
        [userId]="userId"
        (selectedMarket)="selectedProduct = $event"
      ></order-breakdown-component>
    </app-ui-collapsible-section>
  </app-ui-card>

  <!-- Resources & Documents -->
  <app-ui-card
    *ngIf="showDocuments$|async"
    [title]="'Resources & Documents'"
    [minimized]="docsCardCollapsed$|async"
    (minimizeToggle)="toggleSectionCollapsed('documents')">
    <a class="anchor" id="documents"></a>
    <app-fund-documents [accountId]="accountId"></app-fund-documents>
  </app-ui-card>
  
  <app-ui-card class="disclaimer is-hidden-touch">
    <p><strong>IMPORTANT NOTICE</strong>: The contents of this website are made available subject to <a routerLink="/portal/disclaimer">THIS DISCLAIMER</a>.</p>
    <p *ngIf="!isIndex(accountId)">Performance and figures are shown for the fund’s trading account gross of fees. Such performance and figures are not necessarily equivalent to the performance of the fund net of management fees, other fees and charges. Historical performance is no guarantee for future results.</p>
  </app-ui-card>
</div>
