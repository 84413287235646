<spinner name="loadDocuments">
  <div class="spinner-shield">
    <div class="spinner-badge">
      <div class="spinner-indicator"></div>
      <div class="spinner-message"><p>Loading documents...</p></div>
    </div>
  </div>
</spinner>
<spinner name="fetchDocument">
  <div class="spinner-shield">
    <div class="spinner-badge">
      <div class="spinner-indicator"></div>
      <div class="spinner-message"><p>Downloading document...</p></div>
    </div>
  </div>
</spinner>
<ul>
  <li *ngFor="let cat of catList">
    <h2>{{cat?.name}}</h2>
    <ul>
      <li *ngFor="let doc of displayDocuments?.get(cat?.id)">
        <a href="#" (click)="fetchDocument(doc?.DocumentId); false">
          <span [innerHtml]="doc?.DisplayIcon"></span>
          <span>{{doc?.DisplayName}}</span>
          <app-ui-loading-indicator-inline *ngIf="fetchLoading && fetchLoading == doc?.DocumentId"></app-ui-loading-indicator-inline>
        </a>
      </li>
    </ul>
  </li>
</ul>

<div *ngIf="noDocumentsAvailable">
  <p>No documents available at this time.</p>
</div>
