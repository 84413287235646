import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpPopupService {
  public closeAllPopups$: Observable<void>;
  private _closeAllPopupsSubj = new Subject<void>();

  constructor() {
    this.closeAllPopups$ = this._closeAllPopupsSubj.asObservable();
  }

  public closeOtherPopups() {
    this._closeAllPopupsSubj.next();
  }
}
