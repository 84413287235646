import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppRefreshService {
  refresh$: Observable<void>;
  private _refresh$ = new Subject<void>();
  constructor() {
    this.refresh$ = this._refresh$.asObservable();
  }

  refresh() {
    this._refresh$.next();
  }
}
