import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-loading-indicator-inline',
  templateUrl: './ui-loading-indicator-inline.component.html',
  styleUrls: ['./ui-loading-indicator-inline.component.scss']
})
export class UiLoadingIndicatorInlineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
