import { QuizConfig } from './quiz-config';
import { Question } from './question';

export class Quiz {
    id: number;
    config: QuizConfig;
    description: string;
    name: string;
    pages: number[][];
    questions: Question[];
    questionsPerPage?: number;
    reqAns: number[];

    constructor(data: any) {
        if (data && data.default) {
            data = data.default;
        }
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.description = data.description;
            this.config = new QuizConfig(data.config);
            this.reqAns = data.reqAns
            this.questions = [];
            data.questions.forEach(q => {
                this.questions.push(new Question(q));
            });
            this.questionsPerPage = data.questionsPerPage;
        }
    }

    isRequired(question) {
        if (question == null || !Array.isArray(this.questions) || !Array.isArray(this.reqAns)) {
            return false;
        }
        const foundIndex = this.questions.findIndex(q => q.id === question.id);
        return this.reqAns.includes(foundIndex);
    }
}
