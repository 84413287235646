import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TZ } from '@app/account-overview/utils';
import {
  ForecastResult,
  ForecastSet,
  MemberAssetWeight,
  PortfolioCapital,
  PortfolioPositionalIndices,
  Region,
  ReturnType,
  SandboxAsset,
  SandboxPortfolio,
  SandboxPortfolioForecastsRequest,
  SandboxPortfolioParameters,
  SandboxPortfoliosCorrelations, TemporarySandboxPortfolioInfo } from '@app/api/sandbox3.models';
import {
  AssetClass,
  CombinedIndex,
  DATA_FREQ_ID,
  FundingType,
  Index,
  IndexType,
  PspWebService,
  SubAssetClass,
  IndexDateRange
} from '@app/api/webservice.service';
import { AuthenticationService, User } from '@app/auth/authentication.service';
import { HttpClientService } from '@app/http-client.service';
import { LoggingService } from '@app/utils/logging/logging.service';
import { NotificationsService } from 'angular2-notifications';
import { add, cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
import { forkJoin, interval, Observable, of, Subject, throwError, timer, } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap, mergeMap, refCount, publishReplay, timeout, retry, filter, } from 'rxjs/operators';
import * as uuid from 'uuid';
import { PortfolioSet } from '../models/portfolio-set';
import { SandboxPortfolioMetaResponse } from '../models/sandbox-portfolio-meta-response';
import { Sandbox3PortfolioMeta } from '../models/sandbox3-portfolio-meta';
import { pfNamesEqual } from '../validation.functions';
import { Sandbox3StateService } from './sandbox3-state.service';
import { decodePortfolioName, encodePortfolioName } from '@app/shared/utils';
import { retryBackoff } from 'backoff-rxjs';
import { SandboxPortfolioSetNameError } from '../models/sandbox-portfolio-set-name-error';
import { SandboxPortfolioSetNullError } from '../models/sandbox-portfolio-set-null-error';

export const SANDBOX_DEFAULT_CASH_ASSET_ID = 1977;
export const defaultCashAsset = new SandboxAsset({
  'Region': Object.assign(new Region(), {
      'RegionId': 4,
      'Name': 'Global'
  }),
  'LongTermVolatilities': null,
  'CapitalRequirementFactor': 1.0,
  'SubAssetClass': Object.assign(new SubAssetClass(), {
      'AssetClass': Object.assign(new AssetClass(), {
          'AssetClassId': 6,
          'Name': 'Cash'
      }),
      'Name': 'EUR'
  }),
  'Index': Object.assign(new Index(), {
      'SandboxType': 'Reference Investment',
      'Name': 'Libor 3M EUR Index TotRet',
      'FundingType': Object.assign(new FundingType(), {
          'Name': 'Fully-funded',
          'FundingTypeId': 1
      }),
      'IndexType': Object.assign(new IndexType(), {
          'IndexTypeId': 1,
          'Name': 'TheoreticalIndex'
      }),
      'IndexId': SANDBOX_DEFAULT_CASH_ASSET_ID,
      'Description': 'Total return index based on EUR Libor 3M Index (EE0003M Index).'
  }),
  'PairedIndexId': null,
  'ReturnType': Object.assign(new ReturnType(), {
      'ReturnTypeId': 1,
      'Name': 'Total Return'
  }),
});

// from MDN: encodeURIComponent
function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'\(\)\.*_-]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

type AssetDateRangeData = [[string, string], IndexDateRange[]];

@Injectable({
  providedIn: 'root'
})
export class Sandbox3DataService implements OnDestroy {
  private _cancelCalculateSandboxPortfoliosSubj = new Subject<void>();
  private _assetsCache: { expires: moment.Moment, assets: SandboxAsset[] };
  private _assetExpectedReturnCache$: Observable<ForecastSet[]>;
  private _forecastSetsCache: { expires: moment.Moment, data: ForecastSet[] };
  private readonly _assetCacheExpirationTime = 10 * 60 * 1000; // Ten minutes in ms
  private _destroy = new Subject<void>();
  private _portfolioMetadataUrl = '/api/v2/Sandbox3PortfolioMetadata/';
  private _sandboxPortfolioSetUrl = '/api/v2/Sandbox3PortfolioSet/';
  private _forecastSetsRetrievalTime: Date;

  constructor(
    private authSvc: AuthenticationService,
    private httpClient: HttpClientService,
    private logger: LoggingService,
    private notifySvc: NotificationsService,
    private sboxState: Sandbox3StateService,
    private ws: PspWebService,
  ) {

  }

  calculatePortfolioStatistics$(
    origPortfolios: SandboxPortfolio[],
    fromDate: string,
    toDate: string,
    dataFrequencyId?: DATA_FREQ_ID,
  ) {
    let freqId$: Observable<DATA_FREQ_ID>;
    if (dataFrequencyId == null) {
      freqId$ = this.sboxState.activeDataFrequencyId$.pipe(take(1));
    } else {
      freqId$ = of(dataFrequencyId);
    }

    const portfolios = cloneDeep(origPortfolios).map(pf => {
      pf.CombinedIndex.Name = fixedEncodeURIComponent(pf.CombinedIndex.Name);
      pf.Members = pf.Members.filter(m => m.CapitalAllocation >= 0.001 || m.RiskAllocation >= 0.001);
      return pf;
    }).filter(pf => Array.isArray(pf.Members) && pf.Members.length > 0);

    this.sboxState.portfolioStatisticsLoading = true;
    return this.loadAssets$().pipe(
      take(1),
      switchMap(
        (assetList: SandboxAsset[]) => {
          const validationErrors = portfolios.map((p) => {
            let validationResult = p.validate();
            const volErrors = p.Members.map(
              (mem) => {
                const asset = assetList.find(a => mem.IndexId === a.Index.IndexId);
                let memError = null;
                if (asset == null) {
                  this.notifySvc.error('An asset in the portfolio is not available. Try reloading the website.');
                  return { UnknownAsset: 'Could not find asset specified in portfolio. Asset id: ' + mem.IndexId };
                }
                if (
                  asset != null &&
                  asset.SubAssetClass != null &&
                  asset.SubAssetClass.AssetClass.AssetClassId !== 6 &&
                  (mem.LongTermVolatility == null || mem.LongTermVolatility <= 0)
                ) {
                  const ltError = { LongTermVolatility: 'LongTermVolatility must be > 0 for all non-cash assets.'};
                  memError = [ltError];
                }
                return memError;
              }
            );
            if (volErrors.length > 0 && volErrors.some(ve => ve != null)) {
              // Add errors to validationResult
              if (validationResult == null) {
                validationResult = [];
              }
              const pfMemErrors = validationResult.find(v => 'Members' in v);
              if (pfMemErrors == null) {
                validationResult.push({ Members: volErrors });
              } else {
                for (let i = 0; i < pfMemErrors.Members.length; i++) {
                  if (pfMemErrors.Members[i] == null) {
                    pfMemErrors.Members[i] = volErrors[i];
                  } else {
                    pfMemErrors.Members[i].push(volErrors[i][0]);
                  }
                }
              }
            }
            return validationResult;
          });
          // If there are no validation errors, build a list of SandboxPortfolioParameters with data from SandboxPortfolios.
          if (validationErrors.every(ve => ve == null) ) {
            const portfolioParamsList = portfolios.map(
              portfolio => {
                const p = cloneDeep(portfolio);
                const capital = { Currency: p.PortfolioCapital.Currency, Amount: p.PortfolioCapital.Amount * 1e6 };
                const pfGuid = uuid();
                // p['IdentifierGuid'] = pfGuid;
                return {
                  Description: p.CombinedIndex.Description.slice(0, 510),
                  Members: p.Members,
                  Name: p.CombinedIndex.Name,
                  PortfolioCapital: capital,
                  IdentifierGuid: pfGuid,
                } as SandboxPortfolioParameters;
              }
            );
            return this.authSvc.getCurrentUser().pipe(
              take(1),
              switchMap(
                (user) => freqId$.pipe(
                  switchMap(
                    (freqId) => {
                      const assetIndexIds = [].concat(
                        ...Array.isArray(portfolioParamsList) ? portfolioParamsList.map(
                          params => Array.isArray(params.Members) ? params.Members.map(mem => mem.IndexId) : [],
                        ) : []
                      );
                      return this._getAssetsDateRange(fromDate, toDate, assetIndexIds, user, portfolioParamsList).pipe(
                        switchMap(
                          ([dateRange, ranges]) => {
                            if (moment.tz(dateRange[0], TZ).isSameOrAfter(moment.tz(dateRange[1], TZ))) {
                              this.logger.error('Invalid date range for calculateSandboxPortfolios', dateRange);
                              const err = new Error('No stats available for requested time period');
                              err.name = 'InvalidDateRangeError';
                              return throwError(err);
                            }
                            return this.ws.calculateSandboxPortfolios(
                              user.userId, portfolioParamsList, dateRange[0], dateRange[1], freqId
                            ).pipe(
                              retryBackoff({ initialInterval: 1000, maxRetries: 5 } ),
                              map((tempPfInfos) => {
                                if (!Array.isArray(tempPfInfos)) {
                                  return [];
                                }
                                let finalCompleteDate = dateRange[1];
                                if (freqId === DATA_FREQ_ID.MONTHLY) {
                                  let finalMoment: moment.Moment = moment.tz(finalCompleteDate, TZ);
                                  for (const tpf of tempPfInfos) {
                                    if (
                                      tpf.PerformanceInfo != null &&
                                      Array.isArray(tpf.PerformanceInfo.NAVSeries) &&
                                      tpf.PerformanceInfo.NAVSeries.length > 0 &&
                                      tpf.PerformanceInfo.Statistics != null
                                    ) {
                                      const thisEnd = moment.tz(
                                        tpf.PerformanceInfo.NAVSeries[tpf.PerformanceInfo.NAVSeries.length - 1].Timestamp, TZ
                                      );
                                      if (
                                        thisEnd.isBefore(finalMoment)
                                      ) {
                                        finalMoment = thisEnd;
                                        finalCompleteDate = tpf.PerformanceInfo.NAVSeries[
                                          tpf.PerformanceInfo.NAVSeries.length - 1
                                        ].Timestamp;
                                      }
                                    }
                                  }
                                }
                                return tempPfInfos.map(
                                  tpf => {
                                    // Decode Portfolio name.
                                    tpf.SandboxPortfolioParameters.Name = decodeURIComponent(tpf.SandboxPortfolioParameters.Name);

                                    // if freqId is MONTHLY, ToInclusive is set to last day of the month even if actual data is only good up
                                    // to the previous trading day. This sets ToInclusive to the last day in NAVSeries.
                                    if (
                                      freqId === DATA_FREQ_ID.MONTHLY &&
                                      tpf.PerformanceInfo != null &&
                                      Array.isArray(tpf.PerformanceInfo.NAVSeries) &&
                                      tpf.PerformanceInfo.NAVSeries.length > 0 &&
                                      tpf.PerformanceInfo.Statistics != null
                                    ) {
                                      tpf.PerformanceInfo.Statistics.ToInclusive = finalCompleteDate;
                                    }
                                    return tpf;
                                  }
                                );
                              }),
                              switchMap(
                                (tempPfInfos) => {
                                  if (portfolioParamsList == null) {
                                    return null;
                                  } else if (portfolioParamsList.length === 1) {
                                    // skip correlations call if only one portfolio

                                    // create a dummy correlation record for single portfolio
                                    const correlations = new SandboxPortfoliosCorrelations();
                                    correlations.Correlations = [[1]];
                                    const dummyPosIndex = new PortfolioPositionalIndices();
                                    dummyPosIndex.IdentifierGuid = portfolioParamsList[0].IdentifierGuid;
                                    dummyPosIndex.PositionalIndex = 0;
                                    correlations.PortfolioPositionalIndices = [dummyPosIndex];
                                    return of<[TemporarySandboxPortfolioInfo[], SandboxPortfoliosCorrelations]>(
                                      [tempPfInfos, correlations]
                                    );
                                  } else {
                                    return this.ws.getSandboxPortfoliosCorrelations(
                                      user.userId, portfolioParamsList, freqId, dateRange[0], dateRange[1]
                                    ).pipe(
                                      map(
                                        (correlations) => {
                                          if (
                                            Array.isArray(tempPfInfos) &&
                                            tempPfInfos.length > 0 &&
                                            correlations instanceof SandboxPortfoliosCorrelations
                                          ) {
                                            const originId = tempPfInfos[0].IdentifierGuid;
                                            tempPfInfos.forEach((pfInfo, i) => {
                                              if (i === 0) {
                                                return;
                                              }
                                              const corrPos = correlations.PortfolioPositionalIndices.find(
                                                corrIdx => corrIdx.IdentifierGuid ===  pfInfo.IdentifierGuid
                                              );
                                              if (corrPos != null) {
                                                pfInfo['CorrelationToFirst'] = correlations.Correlations[0][corrPos.PositionalIndex];
                                              }
                                            });
                                          }
                                          return [tempPfInfos, correlations, ranges] as [
                                            TemporarySandboxPortfolioInfo[], SandboxPortfoliosCorrelations, IndexDateRange[]];
                                        }
                                      ),
                                    );
                                  }
                                }
                              ),
                            );
                          }
                        ),
                      );
                    }
                  ),
                ),
              ),
              takeUntil(this._cancelCalculateSandboxPortfoliosSubj),
              finalize(() => this.sboxState.portfolioStatisticsLoading = false),
            );
          } else {
            this.sboxState.portfolioStatisticsLoading = false;
            return throwError(validationErrors);
          }
        },
      ),
    );
  }

  cancelStatsUpdate(): void {
    this._cancelCalculateSandboxPortfoliosSubj.next();
  }

  deleteCombinedIndex$(combinedIndexId: number) {
    return this.authSvc.getCurrentUser().pipe(
      switchMap(user => this.ws.getTryDeleteUserCreatedIndex(user.userId, combinedIndexId)),
    );
  }

  deletePortfolioSet$(foundSet: PortfolioSet): Observable<void> {
    if (foundSet == null) {
      return throwError(new Error('Empty portfolio set'));
    }
    return this.httpClient.delete(
      `${this._sandboxPortfolioSetUrl}${foundSet.id}/`,
    );
  }

  deleteSandboxPortfolioMetadata$(pfMeta: Sandbox3PortfolioMeta) {
    return this.httpClient.delete(this._portfolioMetadataUrl + pfMeta.Portfolio.CombinedIndex.IndexId + '/');
  }

  getIndexDateRange$(indexId: number): Observable<IndexDateRange> {
    return this.authSvc.getCurrentUser().pipe(
      switchMap((user) => this.ws.getIndexDateRanges(user.userId, [indexId])),
      map((ranges) => {
        if (Array.isArray(ranges) && ranges.length > 0) {
          return ranges[0];
        } else {
          return null;
        }
      })
    );
  }

  /** Build `SandboxPortfolioRequest`s for selected portfolios from  Sandbox3StateService.portfolioStatistics$ */
  getPortfolioForecasts$(forecastSet: ForecastSet): Observable<ForecastResult[]> {
    return forkJoin([
      this.authSvc.getCurrentUser().pipe(take(1)),
      this.sboxState.activeDataFrequencyId$.pipe(take(1)),
      this.sboxState.portfolioStatistics$.pipe(take(1)),
    ]).pipe(
      takeUntil(this._destroy),
      switchMap(
        ([user, dataFrequencyId, pfStats]) => {
          if (pfStats == null) {
            return of(null);
          }
          const requests: SandboxPortfolioForecastsRequest[] = pfStats.map(
            (stats) => {
              const request = new SandboxPortfolioForecastsRequest();
              request.ForecastSets = [forecastSet];
              request.NavStart = stats.PerformanceInfo.NAVSeries[stats.PerformanceInfo.NAVSeries.length - 1];
              request.NavStart.NAV = +request.NavStart.NAV.toFixed(8); // Value must have no more than 8 decimal numbers
              request.SandboxPortfolio = stats.SandboxPortfolioParameters;
              request.ToDateInclusive = moment.tz(request.NavStart.Timestamp, TZ).add(1, 'year').toISOString(true);
              return request;
            }
          );

          return this.ws.getSandboxPortfolioForecasts(user.userId, requests, dataFrequencyId);
        }
      ),
    );
  }

  getPortfolioSetList$(refreshCache = false): Observable<PortfolioSet[]> {
    return this.httpClient.get<PortfolioSet[]>(this._sandboxPortfolioSetUrl).pipe(
      catchError((err, caught) => {
        this.logger.error('Cannot load portfolio sets', err);
        return of(undefined);
      }),
      map<PortfolioSet[], PortfolioSet[]>(setList => {
        return setList.map(set => {
          if (!(set.lastModifiedDate instanceof Date)) {
            set.lastModifiedDate = moment.tz(set.lastModifiedDate, TZ).toDate();
          }
          return set;
        });
      }),
    );
  }

  /** Fetch a single sandbox portfolio by id.
   * @param id - id of portfolio to get - matched against SandboxPortfolio.CombinedIndex.IndexId.
   */
  getSandboxPortfolio$(id: number): Observable<SandboxPortfolio> {
    if (id == null) {
      return throwError(new Error('No portfolio id supplied'));
    }
    return this.ws.getSandboxPortfolio(id).pipe(
      map(response => {
        if (response instanceof Error) {
          throw response;
        }
        response.CombinedIndex.Name = decodePortfolioName(response.CombinedIndex.Name);
        const newPf = new SandboxPortfolio(response);
        return newPf;
      }),
    );
  }

  /** Fetch list of all available assets from webservice
   *
   * Use the SettingsDataService.getSandboxAssets$() method to get a list filtered by
   * user settings.
   */
  loadAssets$(refreshCache = false): Observable<SandboxAsset[]> {
    if (this._assetsCache == null || moment().isAfter(this._assetsCache.expires) || refreshCache) {
      return this.ws.listSandboxAssets().pipe(
        catchError((err, response: Observable<SandboxAsset[]>) => {
          if (err != null) {
            this.notifySvc.error('Error', 'Could not retrieve sandbox assets from network. Please try again later.');
            this.logger.error('Error fetching SandboxAssets', err, response);
          }
          return throwError(response);
        }),
        map(response => {
          const assets = response.map(
            obj => {
              if (obj.PairedIndexId != null && Array.isArray(obj.PairedIndexId) && obj.PairedIndexId.length === 0) {
                obj.PairedIndexId = null;
              }
              return new SandboxAsset(obj);
            }
          );
          const cashAsset = assets.find(a => a.Index.IndexId === defaultCashAsset.Index.IndexId);
          if (!cashAsset) {
            assets.push(defaultCashAsset);
          }
          return assets;
        }),
        retryBackoff({
          initialInterval: 2000,
          maxRetries: 4,
        }),
        tap((assetList) => this._assetsCache = { expires: moment().add(this._assetCacheExpirationTime, 'ms'), assets: assetList }),
      );
    }
    return of(this._assetsCache.assets);
  }

  /** Fetch list of expected returns for assets from webservice
   * 
   * @param assetIds list of IndexIds for requested Assets
   * @param refreshCache Bypass cache if true
   */
  loadAssetExpectedReturn$(assetIds: number[], refreshCache = false): Observable<ForecastSet[]> {
    if (this._assetExpectedReturnCache$ == null || refreshCache) {
      this._assetExpectedReturnCache$ = this.authSvc.getCurrentUser().pipe(
        switchMap((user) => this.ws.getSandboxAssetExpectedReturn(user.userId, assetIds).pipe(
          catchError((err, response: Observable<ForecastSet[]>) => {
            if (err != null) {
              this.notifySvc.error('Error', 'Could not retrieve sandbox asset expected returns from network. Please try again later.');
              this.logger.error('Error fetching SandboxAssetExpectedReturn', err, response);
            }
            return throwError(response);
          }),
          
        )),
        publishReplay(1),
        refCount(),
      );
    }
    return this._assetExpectedReturnCache$;
  }


  loadAvailablePortfolios$(): Observable<SandboxPortfolio[]> {
    return this.ws.listSandboxPortfolios().pipe(
      retryBackoff({
        initialInterval: 2000,
        maxRetries: 4,
      }),
      catchError((err, response: Observable<SandboxPortfolio[]>) => {
        if (err != null) {
          this.notifySvc.error('Error', 'Could not retrieve sandbox portfolios from network. Please try again later.');
          this.logger.error('Error fetching SandboxPortfolio', err, response);
        }
        return throwError(response);
      }),
      map(
        (pfList) => {
          if (Array.isArray(pfList) && pfList.length > 0) {
            return pfList.map((data) => {
              // Convert JSON data to class instances.
              const pf = new SandboxPortfolio();
              pf.CombinedIndex = new Index();
              Object.assign(pf.CombinedIndex, data.CombinedIndex);
              // decode encoded pf name
              pf.CombinedIndex.Name = decodePortfolioName(pf.CombinedIndex.Name);
              pf.PortfolioCapital = new PortfolioCapital();
              Object.assign(pf.PortfolioCapital, data.PortfolioCapital);
              pf.Members = data.Members.map(mData => {
                const newM = new MemberAssetWeight();
                Object.assign(newM, mData);
                return newM;
              });
              return pf;
            });
          } else {
            return pfList;
          }
        }
      ),
    );
  }

  loadAvailablePortfolioMetas$(): Observable<Sandbox3PortfolioMeta[]> {
    const url = this._portfolioMetadataUrl;
    return this.loadAvailablePortfolios$().pipe(
      retryBackoff({ initialInterval: 5000, maxRetries: 5, shouldRetry: (error) => 'code' in error && error.code >= 500 }),
      catchError((err, response: Observable<SandboxPortfolio[]>) => {
        if (err != null) {
          this.notifySvc.error('Error', 'Could not retrieve sandbox portfolios from network. Please try again later.');
          this.logger.error('Error fetching SandboxPortfolio', err, response);
        }
        return throwError(err);
      }),
      switchMap((pfList) => this.httpClient.get<SandboxPortfolioMetaResponse[]>(url).pipe(
        map((metaList) => {
          if (metaList instanceof Error) {
            throw metaList;
          }
          const pfMetaList: Observable<Sandbox3PortfolioMeta>[] = [];
          for (const pf of pfList) {
            let meta: SandboxPortfolioMetaResponse;
            if (Array.isArray(pfList)) {
              meta = metaList.find(m => pf.CombinedIndex.IndexId === m.combinedIndexId);
            }
            if (meta != null) {
              const newPfM = new Sandbox3PortfolioMeta(meta, pf);
              pfMetaList.push(of(newPfM));
            } else {
              this.logger.warn(`Could not find metadata for CombinedIndex #${pf.CombinedIndex.IndexId}. Trying to create one.`);

              const newMeta = new Sandbox3PortfolioMeta({
                id: null,
                userId: null,
                combinedIndexId: pf.CombinedIndex.IndexId,
                hidden: false,
                starred: false,
                lastModifiedDate: moment.tz(TZ).toISOString(true),
              }, pf);
              pfMetaList.push(
                this.saveSandboxPortfolioMeta$(newMeta, null)
              );
            }
          }
          return pfMetaList;
        }),
          mergeMap((listOfObservables) => {
            if (listOfObservables == null || Array.isArray(listOfObservables) && listOfObservables.length === 0) {
              // if list is empty, just return an empty list
              return of([]);
            }
            return forkJoin(listOfObservables);
          })
        ),
      ),
    );
  }

  /** Fetch list of all available forecast sets from webservice */
  loadForecastSets$(refreshCache = false): Observable<ForecastSet[]> {
    const now = new Date();
    const expirationTime = 10 * 60 * 1000;
    if (
      this._forecastSetsRetrievalTime == null ||
      (now.valueOf() - this._forecastSetsRetrievalTime.valueOf()) > expirationTime
    ) {
      this._forecastSetsCache = null;
      this._forecastSetsRetrievalTime = new Date();
    }
    let forecastSets$: Observable<ForecastSet[]>
    if (this._forecastSetsCache == null || refreshCache) {
      forecastSets$ = this.ws.getForecastSets$().pipe(
        takeUntil(this._destroy),
        retryBackoff({
          initialInterval: 2000,
          maxRetries: 4
        }),
        catchError((err, response: Observable<ForecastSet[]>) => {
          if (err != null) {
            this.notifySvc.error('Error', 'Could not retrieve forecast sets from network. Please try again later.');
            this.logger.error('Error fetching ForecastSets', err, response);
            this._forecastSetsRetrievalTime = null;
          }
          return throwError(response);
        }),
        tap((forecastSets) => {
          this._forecastSetsRetrievalTime = new Date();
          this._forecastSetsCache = { expires: moment(this._forecastSetsRetrievalTime).add(expirationTime, 'ms'), data: forecastSets };
          this.logger.debug('Updated forecast sets', forecastSets);
        }),
      );
    } else {
      forecastSets$ = of(this._forecastSetsCache['data'])
    }
    return forecastSets$;
  }

  ngOnDestroy() {
    this._cancelCalculateSandboxPortfoliosSubj.next();
    this._cancelCalculateSandboxPortfoliosSubj.complete();
    this._destroy.next();
    this._destroy.complete();
    this._assetsCache = null;
    this._forecastSetsCache = null;
  }

  savePortfolioSet$(newSet: PortfolioSet, update?: PortfolioSet): Observable<PortfolioSet|Error> {
    this.sboxState.portfolioSetSaving = true;

    let request: Observable<PortfolioSet | Error>;
    if (!PortfolioSet.validSetName(newSet.name)) {
      throw new SandboxPortfolioSetNameError();
    }
    if (newSet.portfolios.length === 0) {
      throw new SandboxPortfolioSetNullError('Cannot save portfolio set with 0 portfolios');
    }

    return forkJoin([
      this.authSvc.getCurrentUser().pipe(take(1)),
      this.getPortfolioSetList$(true)
    ]).pipe(
      // takeUntil(this.sboxState.portfolioSetSaving$.pipe(filter(s => s === false))), 
      switchMap(([user, savedSets]) => {
        const sameNameSet = savedSets.find(set => pfNamesEqual(newSet.name, set.name));
        newSet.userId = user.userId;
        if (sameNameSet != null || update != null) {
          if (update == null) {
            update = sameNameSet;
          }
          newSet.id = update.id;
          request = this.httpClient.patch<PortfolioSet>(`${this._sandboxPortfolioSetUrl}${update.id}/`, newSet).pipe(
            catchError((error, caught) => {
              if (error instanceof HttpErrorResponse && error.status === 404) {
                delete newSet.id;
                return this.httpClient.post<PortfolioSet>(this._sandboxPortfolioSetUrl, newSet).pipe(
                  catchError((err2, resp2) => {
                    if (err2 instanceof Error) {
                      throw err2;
                    }
                    return resp2;
                  }),
                );
              } else if (error instanceof HttpErrorResponse && error.status === 409) {
                return this.deletePortfolioSet$(update).pipe(
                  switchMap(
                    () => this.httpClient.post<PortfolioSet>(this._sandboxPortfolioSetUrl, newSet).pipe(
                      catchError((err2, resp2) => {
                        if (err2 instanceof Error) {
                          throw err2;
                        }
                        return resp2;
                      }),
                    ),
                  ),
                );
              } else {
                this.logger.error('Cannot update portfolio set', error);
                return throwError(error);
              }
            }),
            tap((savedSet) => {
              if (savedSet != null) {
                this.notifySvc.success('Updated portfolio set', `Updated set “${savedSet.name}”`);
              }
            }),
          );
        } else {
          // save a new set
          delete newSet.id; // remove id so a new id is generated
          request = this.httpClient.post<PortfolioSet>(this._sandboxPortfolioSetUrl, newSet).pipe(
            catchError((err, caught) => {
              this.logger.error('Cannot save portfolio set', err);
              return of(null);
            }),
            tap((savedSet) => {
              if (savedSet != null) {
                this.notifySvc.success('Saved portfolio set', `Saved set “${savedSet.name}”`);
              }
            }),
          );
        }
        return request.pipe(
          tap(set => {
            if (set != null) {
              this.getPortfolioSetList$().subscribe(
                (updatedSets) => this.sboxState.availablePortfolioSets = updatedSets
              );
            }
            this.sboxState.portfolioSetSaving = false;
          }),
        );
      }),
      finalize(() => this.sboxState.portfolioSetSaving = false),
    );
  }

  saveSandboxPortfolio$(pfParams: SandboxPortfolioParameters): Observable<CombinedIndex> {
    // encode name, cutting it down so the encoded name fits in 50 characterss
    let name = pfParams.Name;
    let encodedName = encodePortfolioName(name);
    let cut = 1;
    while (encodedName.length > 50) {
      let suffix = '';
      if (name.endsWith(' (copy)')) {
        name = name.substring(0, name.length - (cut + 7)) + '… (copy)';
      } else {
        name = name.substring(0, name.length - (cut + 1)) + '…';
      }
      encodedName = encodePortfolioName(name);
      cut += 1;
    }
    pfParams.Name = encodedName;
    return this.authSvc.getCurrentUser().pipe(
      switchMap(user => this.ws.storeSandboxPortfolio(user.userId, pfParams).pipe(
        map((ci) => {
          if (ci instanceof Error) {
            throw ci;
          } else {
            if ('Index' in ci && 'Name' in ci.Index) {
              ci.Index.Name = decodePortfolioName(ci.Index.Name);
            }
            return ci;
          }
        }),
      )),
    );
  }

  saveSandboxPortfolioMeta$(pfMeta: Sandbox3PortfolioMeta, replacing: number): Observable<Sandbox3PortfolioMeta> {
    // 'userId', 'combinedIndexId', 'lastModifiedDate', 'starred', 'hidden'
    const url = this._portfolioMetadataUrl;
    return this.authSvc.getCurrentUser().pipe(
      switchMap(
        user => {
          const data = {
            userId: user.userId,
            combinedIndexId: pfMeta.Portfolio.CombinedIndex.IndexId,
            lastModifiedDate: pfMeta.LastModifiedDate,
            starred: pfMeta.Starred,
            hidden: pfMeta.Hidden,
          } as SandboxPortfolioMetaResponse;
          if (replacing == null) {
            return this.httpClient.post<SandboxPortfolioMetaResponse>(url, data).pipe(
              catchError((error, caught) => {
                if (error instanceof HttpErrorResponse && error.status === 409) {
                  return this.deleteSandboxPortfolioMetadata$(pfMeta).pipe(
                    switchMap(
                      () => this.httpClient.post<SandboxPortfolioMetaResponse>(url, data).pipe(
                        catchError((err2, resp2) => {
                          if (err2 instanceof Error) {
                            throw err2;
                          }
                          return resp2;
                        }),
                      ),
                    ),
                  );
                } else {
                  throw error;
                }
              })
            );
          } else {
            // Update Metadata with new combinedIndexId
            return this.httpClient.put<SandboxPortfolioMetaResponse>(url + replacing + '/', data).pipe(
              catchError((error, caught) => {
                if (error instanceof HttpErrorResponse && error.status === 409) {
                  // if Metadata object with this combinedIndexId already exists, force override by deleting from server and try again
                  return this.deleteSandboxPortfolioMetadata$(pfMeta).pipe(
                    switchMap(
                      // Now recreate PortfolioMeta with the updated info
                      () => this.httpClient.post<SandboxPortfolioMetaResponse>(url, data).pipe(
                        catchError((err2, resp2) => {
                          if (err2 instanceof Error) {
                            throw err2;
                          }
                          return resp2;
                        }),
                      ),
                    ),
                  );
                } else {
                  return throwError(error);
                }
              })
            );
          }
        }
      ),
      map((response) => {
        if (response instanceof Error) {
          throw response;
        }
        return new Sandbox3PortfolioMeta(response, pfMeta.Portfolio);
      })
    );
  }

  /** Update portfolio Starred value.
   * @param portfolioMeta the portfolio to be updated
   * @param newValue the desired Starred value. If null, the value of portfolioMeta.Starred will be inverted.
   */
  starPortfolio$(portfolioMeta: Sandbox3PortfolioMeta, newValue?: boolean): Observable<Sandbox3PortfolioMeta> {
    if (newValue == null) {
      // toggle
      portfolioMeta.Starred = !portfolioMeta.Starred;
    } else {
      portfolioMeta.Starred = !!newValue;
    }
    return this.saveSandboxPortfolioMeta$(portfolioMeta, portfolioMeta.Portfolio.CombinedIndex.IndexId).pipe(map(
      (responseData) => {
        if ('combinedIndexId' in responseData) {
          return new Sandbox3PortfolioMeta(responseData, portfolioMeta.Portfolio);
        } else {
          throw responseData;
        }
      }
    ));
  }

  /** Update portfolio Starred value.
   * @param portfolioMeta the portfolio to be updated
   * @param newValue the desired Starred value. If null, the value of portfolioMeta.Starred will be inverted.
   */
  starPortfolioSet$(portfolioSet: PortfolioSet, newValue?: boolean): Observable<PortfolioSet> {
    if (newValue == null) {
      // toggle
      portfolioSet.starred = !portfolioSet.starred;
    } else {
      portfolioSet.starred = !!newValue;
    }
    return this.savePortfolioSet$(portfolioSet, portfolioSet).pipe(map(
      (responseData) => {
        if ('portfolios' in responseData) {
          return new PortfolioSet(responseData);
        } else {
          throw responseData;
        }
      }
    ));
  }

  private _getAssetsDateRange(
    fromDate: string,
    toDate: string,
    assetIndexIds: number[],
    user: User,
    portfolioParamsList: SandboxPortfolioParameters[]
  ): Observable<AssetDateRangeData> {
    const defaultRange = [fromDate, toDate] as [string, string];
    let dateRangeData$: Observable<AssetDateRangeData>;
    if (assetIndexIds.length > 0) {
      dateRangeData$ = this.ws.getIndexDateRanges(user.userId, assetIndexIds).pipe(
        map<IndexDateRange[], AssetDateRangeData>(
          (ranges) => {
            if (Array.isArray(ranges)) {
              let lastStartDate: moment.Moment = moment.tz(fromDate, TZ);
              let firstEndDate: moment.Moment = moment.tz(toDate, TZ);
              for (const range of ranges) {
                const first = moment.tz(range.FirstDate, TZ);
                const last = moment.tz(range.LastDate, TZ);
                if (lastStartDate == null || first.isAfter(lastStartDate)) {
                  lastStartDate = first;
                }
                if (firstEndDate == null || firstEndDate.isAfter(last)) {
                  firstEndDate = last;
                }
              }
              return [[lastStartDate.toISOString(true), firstEndDate.toISOString(true)], ranges];
            } else {
              this.logger.error('Invalid Index Date Ranges', ranges, assetIndexIds, portfolioParamsList);
              return [defaultRange, null] as AssetDateRangeData;
            }
          }
        )
      );
    } else {
      dateRangeData$ = of<AssetDateRangeData>([defaultRange, null]);
    }
    return dateRangeData$;
  }
}

