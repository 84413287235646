<p>Based on your previous answers, you are investing a sum that is <strong>{{calculateInvestmentRatio() > 0.5 ? 'unsustainable' : 'sustainable'}}</strong>.</p>
<div class="slider">
<mat-slider
    #slider
    [attr.aria-label]="calculateInvestmentRatio() * 100 + '% of estimated future income'"
    [disabled]="false"
    [min]="0"
    [max]="100"
    [step]="0.1"
    [thumbLabel]="true"
    [value]="calculateInvestmentRatio() * 100"
></mat-slider>
<img class="slider-overlay" src="/static/assets/kyc-sustainability-estimate-slider-overlay.png" draggable="false">
<p class="min-label">Sustainable</p>
<p class="max-label">Unsustainable</p>

</div>