import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { NavbarButtonService } from './navbar-button.service';
import { NavbarButtonResetMessage } from './navbar-button-reset-message';
import { AppService } from 'app/app-service.service';
import { LoggingService } from 'app/utils/logging/logging.service';

export interface NavbarTab { disabled?: boolean; label: string; value: any; }

@Component({
  selector: 'navbar-button',
  templateUrl: './navbar-button.component.html',
  styleUrls: ['./navbar-button.component.css']
})
export class NavbarButtonComponent implements AfterViewInit, OnInit {

  @Input('initial') public set initial(initial: number) {
    if (isNaN(Number(initial))) {
      return;
    }
    this._initial = Number(initial);
    if (!this.selectedValue && this.tabs != null) {
      this.selectedValue = this.tabs[this._initial];
    }
  }

  @Input() public set disabled(disabledVals: Array<string>) {
    this._disabled = disabledVals;
  }


  @Input('tabs') public set tabs(tabs: (string | NavbarTab)[]) {
    if (tabs === null || tabs === undefined) {
      return;
    }
    if (this._tabs == null || this._tabs.find(
      (tab: string | NavbarTab): boolean => {
        if (typeof tab === 'string') {
          return this.selectedValue === <string>tab && !isNaN(this._initial);
        } else  {
          return (<NavbarTab>tab).value === this.selectedValue && !isNaN(this._initial);
        }
      }
    ) == null) {
      this.selectedValue = tabs[this._initial] == null ? undefined : (<NavbarTab>tabs[this._initial]).value || tabs[this._initial];
    }
    this._tabs = tabs;
  }

  public get tabs() {
    return this._tabs;
  }
  /** dropdownMode directs when to switch to dropdown instead of a row of buttons
   * Values:
   *  0 - never use dropdown
   *  1 - automatically switch to dropdown when buttons would wrap
   *  2 - always use dropdown
   */
  @Input() dropdownMode = 1;
  @Input() id: string;

  public _selectedValue;
  public _tabs: (string |NavbarTab)[];
  public _initial: number;
  public _disabled: Array<string> = [];
  public useDropdown;
  public selectedValue;

  @Output() selectionChanged = new EventEmitter();

  constructor(
    private appService: AppService,
    private logger: LoggingService,
    private navbarButtonService: NavbarButtonService,
    private ref: ElementRef,
  ) {
    this._selectedValue = '';
  }

  changeSelection(value: any, event?: MouseEvent): boolean {
    if (this.isDisabled(value)) {
      this.logger.debug(`Selection '${value}' is disabled. Aborting.`);
      return false;
    } else {
      if (event) {
        this.selectionChanged.emit({selected: value, event: event});
      } else {
        this.selectionChanged.emit({selected: value});
      }
      this.selectedValue = value;
      return true;
    }
}

  disable(value: string): boolean {
    if (this._tabs.findIndex((tab: string | NavbarTab) => {
        if ((<NavbarTab>tab).value) {
          return (<NavbarTab>tab).value === value;
        } else if ((<string>tab).toLowerCase) {
          return <string>tab === value;
        }
        return false;
      }) >= 0) {
      if (this._disabled.indexOf(value) < 0) {
        const copy = [...this._disabled];
        copy.push(value);
        this._disabled = copy;
      }
      return true;
    } else {
      return false;
    }
  }

  enable(value: string): boolean {
    const i = this._disabled.indexOf(value);
    if (i >= 0) {
      this._disabled = this._disabled.filter(e => e !== value);
      return true;
    } else {
      return false;
    }
  }

  isDisabled(value: string): boolean {
    return this._disabled.indexOf(value) >= 0;
  }

  ngOnInit() {
    this.navbarButtonService.getReset().subscribe((resetMsg: NavbarButtonResetMessage) => {
      if ( resetMsg.targetId === 'all' || resetMsg.targetId === this.id) {
        this.reset();
      }
    });
  }

  ngAfterViewInit() {
    this.appService.layoutMode.subscribe(
      mode => {
        if (mode === 'narrow') {
          setTimeout(() => {
            const rect = this.ref.nativeElement.getBoundingClientRect();
            if (this.dropdownMode > 0 && rect != null && rect.height > 60) {
              this.useDropdown = true;
            }
            this.useDropdown = this.useDropdown || this.dropdownMode > 1;
          });
        } else {
          // In wide mode don't use dropdown unless mandated by dropdownMode
          this.useDropdown = this.dropdownMode > 2;
        }
      }
    );
  }

  // set tab back to initial selection
  reset() {
    setTimeout(
      () => this.changeSelection(this._tabs[this._initial])
    );
  }

}
