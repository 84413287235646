import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SpinnerService} from '@chevtek/angular-spinners';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil,groupBy} from 'rxjs/operators';
import {AccountDataService, dt2midnights} from '../account-data/account-data.service';
import {cmpAssetClasses} from '../account-data/asset-class-sorting';
import {Order, PspWebService} from '../api/webservice.service';
import {AppService} from '../app-service.service';
import naturalSort from '../natural-sort';
import {DataTableColumn} from '@shared/data-table/data-table-models';
import {LoggingService} from '../utils/logging/logging.service';
import {NavbarButtonService} from '@app/shared/navbar-button/navbar-button.service';
import * as moment from 'moment-timezone';

function cmpValue(a: any, b: any): number {
  const x = a['value'] === undefined ? a : a.value;
  const y = b['value'] === undefined ? b : b.value;
  return naturalSort(x, y);
}

function bsForDir(dir: number) {
  if (dir < 0) {
    return 'Sell';
  } else if (dir === 0) {
    return '';
  } else {
    return 'Buy';
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'order-breakdown-component',
  templateUrl: './order-breakdown.component.html',
  styleUrls: ['./order-breakdown.component.scss']
})
export class OrderBreakdownComponent implements OnInit, OnDestroy {

  @Input() selectedDate: Date;
  @Input() accountId: number | string;
  @Input() userId: number;
  @Output() selectedMarket = new EventEmitter<number>();


  assetClassOrderBreakdownTableData: any = [];
  assetClassOrderBreakdownTableColumns: DataTableColumn[] = [];
  mainMenuMode$: Observable<'icons' | 'labels'>;
  category = [];

  isOverviewNavbarScrolledUp: Observable<boolean>;

  private _destroySubject: Subject<void>;

  public get TZ() {
    return this.appService.TZ;
  }

  private subscription: Subscription;

  constructor(
    private acctDataSvc: AccountDataService,
    private appService: AppService,
    private logger: LoggingService,
    private _navbarSvc: NavbarButtonService,
    private spinnerService: SpinnerService,
    private ws: PspWebService,
  ) {
    this.isOverviewNavbarScrolledUp = this.appService.isNavbarScrolledUp.asObservable();
    this.mainMenuMode$ = this.appService.menuMode$;
  }

  generateOrderTable(orders, trades) {

    let currencySymbol: string;
    let magnitudeSymbol: string;

    // Group orders by instrumentid & buysellcode and join result with trades (which are grouped by instrumentid & buysellcode from backend)
    this.assetClassOrderBreakdownTableData =
      orders
        .reduce((groupedOrdersAndTrades, order) =>
          {
            const existingOrderGroup = groupedOrdersAndTrades.filter(got =>
              got.Order.Instrument.InstrumentId === order.Instrument.InstrumentId
              && bsForDir(got.Order.Quantity) === bsForDir(order.Quantity));

            if (!existingOrderGroup || existingOrderGroup.length == 0)
            {
              groupedOrdersAndTrades = groupedOrdersAndTrades.concat([{
                Order: order,
                Trades: trades.filter((t) =>
                  t.Instrument.InstrumentId === order.Instrument.InstrumentId && bsForDir(t.Quantity) === bsForDir(order.Quantity))
                }]);
            }
            else
            {
              existingOrderGroup[0].Order.Quantity += order.Quantity;
              existingOrderGroup[0].Order.FilledQuantity += order.FilledQuantity;
            }

            return groupedOrdersAndTrades;
          }
      , [])
      .map(item => {

        return {
          id: item.Order.Instrument.InstrumentId,
          assetClassName: item.Order.Instrument.ProductLongName,
          LS: bsForDir(item.Order.Quantity),
          OrderQuantity: item.Order.Quantity,
          FilledQuantity: item.Order.FilledQuantity,
          FilledProportion: item.Order.FilledQuantity / item.Order.Quantity,
          ShortName: item.Order.Instrument.ShortName,
          AvgPrc: ((!item.Trades || item.Trades.length == 0) ?  NaN : item.Trades[0].AveragePrice),
          currencySymbol,
          magnitudeSymbol,
        };
      });
  }

  ngOnInit() {
    this._destroySubject = new Subject<void>();

    //TL not used for anything, but we're hooking up to the subscription so we're notified when
    //AccountId & UserId are set
    this.acctDataSvc.tradingLevel$.pipe(
      takeUntil(this._destroySubject),)
        .subscribe(ignoreTL =>
          {
            if (!this.userId || !this.accountId) {
              return;
            }
            this.componentInitiated();
          }
        );
    // this.spinnerService.hide('order-breakdown');

    this.assetClassOrderBreakdownTableColumns = [
      {
        name: 'assetClassName',
        label: 'Asset',
        type: 'text',
        cmp: function (a: any, b: any): number {
          return cmpAssetClasses(a, b, null);
        },
      },
      {
        name: 'ShortName',
        label: 'Ticker',
        type: 'text',
        cmp: cmpValue,
      },
      {
        name: 'LS',
        label: 'Buy/Sell',
        type: 'text',
        cmp: cmpValue,
      },
      {
        name: 'OrderQuantity',
        label: 'Order Quantity',
        type: 'number',
        cmp: cmpValue,
      },
      {
        name: 'FilledQuantity',
        label: 'Filled Quantity',
        type: 'number',
        cmp: cmpValue,
      },
      {
        name: 'AvgPrc',
        label: 'Average Price',
        type: 'number',
        cmp: cmpValue,
      }
    ];
  }

  private componentInitiated() {
    this.ws.getIntradayOrders(this.userId, this.accountId)
      .subscribe(orders => {
          this.ws.getDailyTrades(this.userId, this.accountId, dt2midnights(moment(new Date())), dt2midnights(moment(new Date())))
            .subscribe(
              trades => {
                this.generateOrderTable(orders, trades);
              }
            );
        }
      );
  }

  ngOnDestroy() {
    //this.subscription.unsubscribe();
    this._destroySubject.next();
    this._destroySubject.complete();
    this._destroySubject = null;
    this.spinnerService.hide('order-breakdown');
  }

}
