import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSliderChange } from '@angular/material/slider';
import { Router } from "@angular/router";
import { Companies, Contacts, CorporateKYC, KYC, OnBehalfOfEntities, PrivateKYC } from '@app/api/kyc.models';
import { PspWebService } from '@app/api/webservice.service';
import { AppService } from '@app/app-service.service';
import { AuthenticationService } from "@app/auth/authentication.service";
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { retryBackoff } from 'backoff-rxjs';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { retryWhen } from 'rxjs-compat/operator/retryWhen';
import { catchError, map, tap } from 'rxjs/operators';
import { Option, Question, Quiz, QuizAnswer, QuizConfig, QuizId } from './models/index';
import { QuestionTypeId } from './models/question-type-id.enum';
//import { KycDataService } from './services/kyc-data.service'
import { QuizService } from './services/quiz.service';


enum ProgressSection {
  InvestorType = 1,
  BasicInfo = 2,
  AML = 3,
  Upgrade = 4,
}

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
  providers: [QuizService, PspWebService, AuthenticationService]
})
export class KYCComponent implements OnInit {

  config: QuizConfig = {
    'allowBack': true,
    'allowReview': false,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 0,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': true,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };
  DEBUG = false;
  duration = '';
  ellapsedTime = '00:00';
  endTime: Date;
  firstQuiz = QuizId.Investor;
  history: [QuizId, number][];
  loading: boolean;
  mode = 'quiz';
  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  QuestionTypeId = QuestionTypeId;
  quizes: Quiz[];
  quiz: Quiz = null;
  QuizId = QuizId;
  quizName: string;
  
  /** When user selects an option with a linked question the linked question pops up in a modal by setting this to the question id. */
  showQuestionModal: number;

  startTime: Date;
  subQuiz: Quiz;
  timer: any = null;
  OldContact: Contacts;
  NewContact: Contacts;
  OldCorp: Companies;
  NewCorp: Companies;
  OldKYC: KYC;
  NewKYC: KYC;
  UserId: number;

  private _allAnswers: QuizAnswer[];
  private _experiencePoints: number;
  private _subQuizResults = new Map<number, QuizAnswer[][]>();
  private _existingCompanies: Companies[];
  private _entityId: any;

  constructor(
    private _appService: AppService,
    private _authSvc: AuthenticationService,
    private _changeDetector: ChangeDetectorRef,
    private _notify: NotificationsService,
    private _router: Router,
    private _quizService: QuizService,
    private _ws: PspWebService
  )
  {
    this.allAnswers = [];
    this.DEBUG = !environment.production;
  }

  get allAnswers() {
    return this._allAnswers;
  }

  set allAnswers(answers: QuizAnswer[]) {
    if (this.DEBUG) {
      console.debug('Setting allAnswers', answers);
    }
    this._allAnswers = answers;
  }

  /**
   * Check if user has answered investor type question and if so, is the answer private
   * 
   * @returns true if answer is Private, false if answer is Corporate, undefined if not yet answered
   */
  isPrivate() {
    const investorType = this._quizService.getCachedAnswer(QuizId.Investor, 1);
    return investorType && investorType.answer === 'Private person';
  }

  /** Navigate to next page
   * Moves to next page in quiz if there are more questions.
   * Submits quiz if no more questions.
   */
  nextPage() {
    const pager = this.pager;
    if (pager.index + pager.size >= pager.count) {
      this.onSubmit();
    }  else {
      const nextP = pager?.index + pager?.size
      this.history.push([this.quiz.id, nextP])
      this.goTo(nextP);
    }
  }

  ngOnInit() {
    this.history = [];

    this._authSvc.getCurrentUser().subscribe(user => {
      this.UserId = user.userId;
      this.quizes = this._quizService.getAll().map((item) => {
        return item.quiz as Quiz;
      });
      this._ws.KycGetContact(this.UserId).subscribe({
        next: (contact: Contacts) => {
          console.info('Contact', contact);
          this.OldContact = contact;
          this.NewContact = new Contacts();
          this.NewContact.EntityId = contact.EntityId;
          this.NewContact.UserId = contact.UserId || this.UserId;
          if (contact.KYC != null) {
            this.OldKYC = contact.KYC;
            this.NewKYC = new KYC();
            this.NewKYC.EntityId = this.OldKYC.EntityId;
            this.NewKYC.KYCId = this.OldKYC.KYCId;
            this.NewKYC.UserId = this.UserId;
          }
          this.loadQuiz([], this.firstQuiz);
        },
        error: (err) => {
          this._appService.showError(
            'Connection error',
            'Cannot connect to database. You can enter information but it may not save correctly.'
          );
          if (this.DEBUG) {
            console.error('Cannot fetch contact:', err);
          }
        }
      });
    });
  }

  /** Try to get Contact value, first from NewContact, then from OldContact */
  getContactValue(key: string) {
    if (this.NewContact !== undefined && this.NewContact[key] !== undefined) {
      return this.NewContact[key];
    }
    if (this.OldContact !== undefined && this.OldContact[key] !== undefined) {
      return this.OldContact[key];
    }
    return undefined;
  }

  /** Try to get KYC value, first from NewKYC, then from OldKYC */
  getKycValue(key: string) {
    if (this.NewKYC !== undefined && this.NewKYC[key] !== undefined) {
      return this.NewKYC[key];
    }
    if (this.OldKYC !== undefined && this.OldKYC[key] !== undefined) {
      return this.OldKYC[key];
    }
    return undefined;
  }

  loadQuiz(answers: QuizAnswer[], quizId: number, checkReqs = true) {
    const quiz = this._quizService.get(quizId);
    if (quiz == null) {
      return;
    }

    const keyMap = this._quizService.questionToKycMap.get(quizId);
    if (keyMap){
      for (const q of quiz.questions) {
        let qKey = keyMap.get(q.id);
        if (qKey) {
          let getter: (key: string) => string|number|boolean;
          if (qKey.startsWith('Contacts:')) {
            getter = this.getContactValue;
          } else {
            getter = this.getKycValue;
          }
          const lastColon = qKey.lastIndexOf(':');
          if (lastColon >= 0) {
            qKey = qKey.substring(lastColon+1);
          }
          
          if ([
            QuestionTypeId.SelectDropdown,
            QuestionTypeId.RadioButtonGroup,
          ].includes(q.questionTypeId)) {
            let val = getter.call(this, qKey);
            switch (qKey) {
              case 'InvestmentObjective': {
                  const linebreak = val.indexOf("\n");
                  let first: string;
                  let rest: string;
                  if (linebreak >= 0) {
                    first = val.substring(0, linebreak) ;
                    rest = val.substring(linebreak + 1);
                  }
                  val = first || '';
                }
                break;
              case 'OriginOfInvestedAssests': {
                  const linebreak = val.indexOf("\n");
                  let first: string;
                  let rest: string;
                  if (linebreak >= 0) {
                    first = val.substring(0, linebreak) ;
                    rest = val.substring(linebreak + 1);
                  }
                  val = first || '';
                }
                break;
              default:
                // nothing
            }
            if (val === true) {
              val = 'Yes';
            }
            if (val === false) {
              val = 'No';
            }
            for (const o of q.options) {
              const oVal = o.value != null ? o.value : o.name;
              o.selected = oVal === val;
            }
          } else if (q.questionTypeId === QuestionTypeId.ToggleButtonGroup) {
            let val = getter.call(this, qKey);
            if (val === true) {
              val = 'Yes';
            }
            if (val === false) {
              val = 'No';
            }
            for (const o of q.options) {
              if (quizId === QuizId.Investor) {
                o.selected = val === 'Yes' && o.name === 'Private person'
                  || val === 'No' && o.name === 'Company or entity';
              } else {
                o.selected = o.name === val;
              }
            }
          } else if (q.questionTypeId === QuestionTypeId.Slider) {
            let val = getter.call(this, qKey);
            q.options[0].value = +val;
          } else {
            let val = getter.call(this, qKey);
            switch (qKey) {
              case 'OriginOfInvestedAssests': 
              case 'InvestmentObjective': {
                  const linebreak = val.indexOf("\n");
                  let first: string;
                  let rest: string;
                  if (linebreak >= 0) {
                    first = val.substring(0, linebreak) ;
                    rest = val.substring(linebreak + 1);
                  }
                  val = rest || '';
                }
                break;
              default:
                // nothing
            }
            for (const o of q.options) {
              o.name = val != null ? val.toString() : '';
            }
          }
        }
      }
    }
    this.quizName = quiz.name;
    if (this.quiz && this.quiz.reqAns != null && this.quiz.reqAns.length > 0 && checkReqs) {
      try {
        for(let i of this.quiz.reqAns){
          if(answers[i] == null || answers[i].answer === "") {
            this._notify.error("Error", "One or more required fields not filled.");
            return false;
          }}
      } catch(error) {
        console.log(error)
      }
    }

    if (quiz.id != null) {
      this.history.push([quiz.id, 0]);
    }
    if (this.DEBUG) {
      console.info('History', this.history);
    }
    this.quiz = quiz // new Quiz(quiz);
    if (quizId === QuizId.Company1) {

      // Load pre-created companies for this user from server
      this.loading = true;
      this._ws.KycGetCompanies({"UserId": this.UserId}).pipe(
        map(thing => {
          // separate errors passed through from HTTP service
          if (thing instanceof Error) {
            throw thing;
          } else {
            return thing;
          }
        }),
        retryBackoff({
          initialInterval: 500,
          maxRetries: 5,
          resetOnSuccess: true,
        }),
      ).subscribe({
          complete: () => this.loading = false,
          error: (err) => {
            this._appService.showError(
              'Cannot load companies from backend',
              `<p>An error occured while loading companies from the CRM system.<br>
              Please try reloading the page and try again.
              <p>If it doesn't work contact customer support.`  
            );
          },
          next: (ArrayOfCompanies) => {
            this.loading = false;
            this._entityId = null;
            this._existingCompanies = null;
            if (Array.isArray(ArrayOfCompanies) && ArrayOfCompanies.length>0) {
              this._existingCompanies = ArrayOfCompanies;
              for (let i = 0; i < ArrayOfCompanies.length; i++) {
                const nameQ = this.quiz.questions.find(q => q.id === 1);
                nameQ.options.unshift({
                  id: i,
                  questionId: 1,
                  name: ArrayOfCompanies[i].NameOfTheEntity,
                  selected: false,
                });
                const idQ = this.quiz.questions.find(q => q.id === 2);
                idQ.options.unshift({
                  id: i,
                  questionId: 2,
                  name: ArrayOfCompanies[i].BussinessId,
                  selected: false
                });
              }
              if (this.DEBUG) {
                console.debug('Quiz', this.quiz);
              }
            } else {
              this._appService.showError("Error", "No connected companies found, please contact <a href=mailto:clarity@estlanderpartners.com>clarity@estlanderpartners.com</a> if you are a representative.");
              return false;
            }
          },
        }
      );
    }

    if (this.DEBUG) {
      this._quizService.dumpAnswerCache();
    }
    // initialize questions with cached answers
    for (const q of quiz.questions) {
      const cachedAnswer = this._quizService.getCachedAnswer(quiz.id, q.id) as QuizAnswer;
      if (cachedAnswer != null) {
        switch(q.questionTypeId) {
          case QuestionTypeId.CheckboxGroup:
          case QuestionTypeId.CheckboxGroupNoTitle:
            // answer is group of selected options
            q.options.forEach((opt) => {
              const answerOpt = (cachedAnswer.answer as Option[]).find(cOpt => cOpt.id === opt.id);
              if (answerOpt) {
                opt.selected = answerOpt.selected
              } else {
                opt.selected = false;
              }
            });
            break;
          case QuestionTypeId.CheckboxGroupExclusive:
          case QuestionTypeId.ToggleButtonGroup:
          case QuestionTypeId.RadioButtonGroup:
          case QuestionTypeId.SelectDropdown:
            q.options.forEach((opt) => {
              if (opt.value != null && opt.value === cachedAnswer.answer) {
                opt.selected = true;
              } else {
                opt.selected = opt.name === cachedAnswer.answer;
              }
            });
            break;
          case QuestionTypeId.Slider:
            q.options[0].value = cachedAnswer.answer as number;
            break;
          default:
            q.options[0].name = cachedAnswer.answer.toString();
        }
      }
    }
    if (quiz.config && Array.isArray(quiz.config.pages)) {
      this.pager = {
        count: quiz.config.pages.length,
        index: 0,
        size: 1,
      }
    } else {
      this.pager.count = this.quiz.questions.filter(q => q.questionTypeId !== QuestionTypeId.PopupTextbox).length;
      if (quiz.config != null && quiz.config.pageSize != null) {
        this.pager.size = quiz.config.pageSize;
      } else {
        this.pager.size = 4
      }
    }
    this.mode = 'quiz';
    this.goTo(0)

    // Test filteredQuestion
    const origPager = Object.assign({}, this.pager);
    while (this.pager.index - this.pager.size <= this.pager.count) {
      // console.debug('filtered', this.filteredQuestions);
      this.pager.index += this.pager.size;
    }
    this.pager = origPager;
    
    const isGerman = this.isGerman();
    if (
      quizId == QuizId.AMLCompany2
      && isGerman
    ) {
      this.quiz.questions[0].options.unshift({id: 5, questionId: 12, name: "Semi professional (Germany only)", selected: false})
    }
    if (quizId == QuizId.Alternative && isGerman){
      this.quiz.questions[0].options.unshift({id: 5, questionId: 12, name: "Semi professional (Germany only)", selected: false})
    }

  }

  private isGerman() {
    const nationality = this._quizService.getCachedAnswer(QuizId.Private1, 4);
    const domicile = this._quizService.getCachedAnswer(QuizId.Company1, 3);

    return nationality && nationality.answer === 'DE' || domicile && domicile.answer === 'DE';
  }

  get filteredQuestions() {
    if (this.quiz == null || !Array.isArray(this.quiz.questions)) {
      return [];
    }

    const quiz = this.quiz;
    const questions = quiz.questions;
    const pager = this.pager;
    let filtered = [];
    if (quiz.config && quiz.config.pages != null && quiz.config.pages.length > 0) {
      const pages = quiz.config.pages;
      let pageIndex = 0;
      if (pager.index >= 0 && pager.index / pager.size < pages.length) {
        pageIndex = pager.index / pager.size;
        const pageQuestionIds = pages[pageIndex];
        return pageQuestionIds.map((i) => {
          return questions.find(q => q.id === i);
        }).filter((q) => q != null);
      }
    }
    let count = 0; // count non-popup questions
    let i = 0;
    while (i < questions.length) {
      const q = questions[i];
      i += 1;
      if (count >= pager.index && count < pager.index + pager.size) {
        filtered.push(q);
      }
      if (q.questionTypeId !== QuestionTypeId.PopupTextbox) {
        count += 1;
      }
      if (count > pager.count) {
        break;
      }
    }
    // filtered = this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size);
    return filtered;
  }

  onSelect(question: Question, option?: Option, event?: MatSelectChange | InputEvent) {
    const quizId = this.quiz.id;
    switch(question.questionTypeId){
      case QuestionTypeId.CheckboxGroupExclusive:
      case QuestionTypeId.RadioButtonGroup:
        question.options.forEach((opt) => {
          opt.selected = opt.id === option.id;
        });
        break;
      case QuestionTypeId.CheckboxGroup:
      case QuestionTypeId.CheckboxGroupNoTitle: 
        if (option.name === "None of the above" && !option.selected) {
          question.options.forEach((opt) => {
            opt.selected = opt.id === option.id;
          });
        } else {
          option.selected = !option.selected;
          const noneOfTheAbove = question.options.find(opt => opt.name === 'None of the above');
          if (noneOfTheAbove != null && option.selected) {
            noneOfTheAbove.selected = false;
          }
        }
        break;
      case QuestionTypeId.SelectDropdown: {
        if (event && 'value' in event) {
          question.options.forEach((opt) => {
            const selected = opt.id === event.value;
            opt.selected = selected;
            if (selected) {
              question.selected = opt.id;
            }
          });

          if (quizId === QuizId.Company1 && question.id === 1) {
            // link NameOfEntity and BussinessId value
            const index = question.options.findIndex(opt => opt.selected);
            const otherQ = this.quiz.questions.find(q => q.id === 2);
            if (otherQ && otherQ.options.length > index) {
              otherQ.options.forEach((opt, i) => {
                const selected = i === index;
                opt.selected = selected;
                if (selected) {
                  otherQ.selected = opt.id;
                }
              });
              this._changeDetector.markForCheck();
            }
          }
          if (quizId === QuizId.Company1 && question.id === 2) {
            // link NameOfEntity and BussinessId value
            const index = question.options.findIndex(opt => opt.selected);
            const otherQ = this.quiz.questions.find(q => q.id === 1);
            if (otherQ && otherQ.options.length > index) {
              otherQ.options.forEach((opt, i) => {
                const selected = i === index;
                opt.selected = selected;
                if (selected) {
                  otherQ.selected = opt.id;
                }
              });
              this._changeDetector.markForCheck();
            }
          }
        }
      }
      break;
    }

    let answer = this._extractAndCacheAnswerFromQuestion(this.quiz, question);

    if( question.id === 274) {
      // From AmateurDisclaimer
      if(answer.answer === 'I understand'){
        this.onSubmit()
      }
    }
    if (option && option.linkedQuestionId != null) {
      this.showQuestionModal = option.linkedQuestionId;
    }
  }

  /** Used to autosubmit single question quizes (questionType: 7) */
  onClick(question: Question, clickedOption: Option) {
    // console.log(question, clickedOption);
    switch(question.questionTypeId){
      case QuestionTypeId.ToggleButtonGroup:
        if (question.options.map((opt) => opt.id).includes(clickedOption.id)) {
          question.options.forEach((x) => {
            x.selected = x.id === clickedOption.id;
          });
          this._extractAndCacheAnswerFromQuestion(this.quiz, question);
        }
        break;
      default:
        return;
    }
    const qIndex = this.quiz.questions.findIndex(q => q.id === question.id);
    if( this.quiz.questions.length === 1 || qIndex === this.quiz.questions.length - 1) {
      // submit if only one question or last question
      this.onSubmit();
    }
  }

  onRadio(question: Question, event: MatRadioChange) {
    const options = question.options;
    options.forEach((opt) => {
      opt.selected = opt.id === event.value;
      if (opt.selected && opt.linkedQuestionId != null) {
        if (opt.linkedQuestionId === -1) {
          // show sub quiz form in modal
          const subQuiz = this._quizService.get(opt.value as number);
          this.subQuiz = subQuiz;
        } else {
          this.showQuestionModal = opt.linkedQuestionId;
        }
      }
    });
    this._extractAndCacheAnswerFromQuestion(this.quiz, question);
  }

  onSlider(question: Question, event: MatSliderChange) {
    question.options[0].value = this.calculateSliderDisplayValue(
      event.value,
      question.options[0].config.min,
      question.options[0].config.middle,
      question.options[0].config.max,
    );
    this._extractAndCacheAnswerFromQuestion(this.quiz, question);
  }

  onSubQuizSubmit(subQuiz: Quiz, answers: QuizAnswer[][]) {
    switch (subQuiz.id) {
      case QuizId.Behalfofother:
        if (this.DEBUG)
          console.debug('Save Behalfofother', answers);
        this._subQuizResults.set(QuizId.Behalfofother, answers);
        break;
      case QuizId.LargerShareHolder:
        if (this.DEBUG)
          console.debug('Save LargeShareHolders', answers);
        this._subQuizResults.set(QuizId.LargerShareHolder, answers);
        break;
      case QuizId.NonprofitBoard:
        if (this.DEBUG)
          console.debug('Save Nonprofitboard', answers);
        this._subQuizResults.set(QuizId.NonprofitBoard, answers);
        break;
    }
    this.subQuiz = null;
  }

  onText(question: Question, text: string) {
    question.options[0].name = text;
    this._extractAndCacheAnswerFromQuestion(this.quiz, question);
  }

  goToQuestion(quizId: number, questionId: number) {
    if (this.loadQuiz([], quizId)) {
      let pageIndex = 0;
      if (this.quiz.config != null && this.quiz.config.pages != null) {
        const pages = this.quiz.config.pages;
        pageIndex = pages.findIndex(p => p.includes(questionId));
      }
      if (pageIndex < 0) {
        pageIndex = 0;
      }
      this.pager.index = pageIndex;
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
    else {
      if (index === -1) {
        if (this.history && this.history.length > 1) {
          const [currQuizId, currPage] = this.history.pop();
          const [prevQuizId, prevPage] = this.history.pop();
          if (currQuizId !== prevQuizId) {
            console.info('Going back to quiz', prevQuizId);
            let answers = [] as QuizAnswer[];
            /*
            {
              'name': this.quiz.name,
              'quizId': this.quiz.id,
              'questionId': x.id,
              'answer': this.findAnswer(x)
            }
            */
            answers = this._quizService.getCachedAnswerList(prevQuizId);
            this.loadQuiz(answers, prevQuizId, false);
            this.pager.index = prevPage;
          } else {
            console.info('Going back to page', prevPage, 'of quiz', prevQuizId);
            this.pager.index = prevPage;
          }
          return;
        }
      }
      this.pager.index = 0;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  findAnswerInQuestion(question: Question | Quiz | number, questionId?: number, nocache = false): boolean | string | number | Option[] {
    let quizId: number;
    if (typeof question === 'number') {
      if (questionId != null) {
        quizId = question;
        const isCurrent = quizId === this.quiz.id;
        if (!isCurrent && !nocache) {
          const cached = this._quizService.getCachedAnswer(quizId, questionId);
          if (cached != null) {
            return cached.answer;
          }
        }
        const quiz = isCurrent ? this.quiz : this._quizService.get(quizId);
        const actualQuestion = quiz.questions.find(q => q.id === questionId);
        question = actualQuestion;
      } else {
        const actualQuestion = this.quiz.questions.find(q => q.id === questionId);
        question = actualQuestion;
      }
    }
    if (question != null && question instanceof Quiz && 'questions' in question) {
      // question is a Quiz object. Get the questioon from the quiz and quiestionId parameters
      quizId = question.id;
      const actualQuestion = question.questions.find(q => q.id === questionId);
      question = actualQuestion;
    }
    if (quizId === undefined) {
      quizId = this.quiz.id;
    }
    // Extract answer from question object if not cached
    return this._quizService.findAnswerInQuestion(question as Question);
  };

  quizIndex() {
    return this.quizes.findIndex((q) => this.quiz != null && this.quiz.id === q.id);
  }

  nextQuiz(answers: QuizAnswer[], currentQuizId: number){
    // console.log(this.allAnswers)
    let alternative = this._quizService.getCachedAnswer(QuizId.AMLCompany2, 14)?.answer;
    if (alternative == null) {
      alternative = this.findAnswerInQuestion(QuizId.AMLCompany2, 14);
    }

    if (alternative === "Semi professional (Germany only)" && this.isGerman()) {
      this.storeCorporateKYC({
        UserId: this.UserId,
        IsProfessional: false,
        IsSemiprofessional: true,
        IsPrivate: false
      });
      this.loadQuiz(answers, QuizId.Semiprofessional);
    }
    else {
      if (alternative === "None of the above") {
        this.loadQuiz(answers, QuizId.Upgrade)} //load Upgrade to pro question
      else {
        if (alternative === "Large Undertaking") {
          let nmr = 0;
          const turnover = +this.findAnswerInQuestion(QuizId.AMLCompany, 7);
          const equity = +this.findAnswerInQuestion(QuizId.AMLCompany, 8);
          const balance = +this.findAnswerInQuestion(QuizId.AMLCompany, 9);
          if (balance >= 20) {nmr = nmr + 1}
          if (equity >= 2) {nmr = nmr + 1}
          if (turnover > 40) {nmr = nmr + 1}
          if (nmr >= 2) {
            {
              this.storeCorporateKYC({
                UserId: this.UserId,
                EntityId: this._entityId,
                IsProfessional: true,
                IsSemiprofessional: false,
                IsPrivate: false
              });
            }
            this.proDone(answers);
          }
          else {
            this.loadQuiz(answers, QuizId.Upgrade);
          }
        }
        else
        {
          this.storeCorporateKYC({
            UserId: this.UserId,
            EntityId: this._entityId,
            IsProfessional: true,
            IsSemiprofessional: false,
            IsPrivate: false
          });
          this.proDone(answers);
        }
      }
    }
  }

  progress(quiz?: Quiz) {
    if (quiz == null) {
      if (this.quiz == null) {
        return null;
      }
      quiz = this.quiz;
    }

    // Map quiz id to section id
    const quizToSectionMap = new Map<number, number>([
      [ QuizId.NonprofitBoard, ProgressSection.AML ],
      [ QuizId.Investor, ProgressSection.InvestorType ],
      [ QuizId.Private1, ProgressSection.BasicInfo ],
      [ QuizId.Company1, ProgressSection.BasicInfo ],
      [ QuizId.AMLCompany, ProgressSection.AML ],
      [ QuizId.AMLPrivate1, ProgressSection.AML ],
      [ QuizId.Upgrade, ProgressSection.Upgrade ],
      [ QuizId.Experience, ProgressSection.Upgrade ],
      [ QuizId.Semiprofessional, ProgressSection.Upgrade ],
      [ QuizId.Amateur, ProgressSection.Upgrade ],
      [ QuizId.LargerShareHolder, ProgressSection.AML ],
      [ QuizId.Behalfofother, ProgressSection.AML ],
      [ QuizId.Done, ProgressSection.Upgrade ],
      [ QuizId.UpgradedToProfessional, ProgressSection.Upgrade ],
      [ QuizId.UpgradedToSemiProfessional, ProgressSection.Upgrade, ],
      [ QuizId.AreYouSemiprofessional, ProgressSection.Upgrade ],
      [ QuizId.AmateurDisclaimer, ProgressSection.Upgrade ],
      [ QuizId.Alternative, ProgressSection.AML],
      [ QuizId.ProDone, ProgressSection.Upgrade ],
      [ QuizId.Private2, ProgressSection.BasicInfo ],
      [ QuizId.Company2, ProgressSection.BasicInfo ],
      [ QuizId.AMLCompany2, ProgressSection.AML ],
    ]);

    // A map of section id to quizes in this part, first for private investor , second for comapnies
    const sectionPartsMap = new Map<number, [number[], number[]]>([
      [ ProgressSection.InvestorType, 
        [
          [ QuizId.Investor, ],
          []
        ],
      ],
      [ ProgressSection.BasicInfo,
        [ 
          [ QuizId.Private1, QuizId.Private2, QuizId.AMLPrivate1, ],
          [ QuizId.Company1, QuizId.Company2, QuizId.AMLCompany, QuizId.AMLCompany2, QuizId.Alternative, ],
        ],
      ],
      [ ProgressSection.Upgrade, 
        [
          [ 
            QuizId.Experience,
            QuizId.Upgrade,
            QuizId.AreYouSemiprofessional,
            QuizId.Semiprofessional,
            QuizId.UpgradedToSemiProfessional,
            QuizId.UpgradedToProfessional,
            QuizId.AmateurDisclaimer,
            QuizId.Done,
            QuizId.ProDone
          ],
          []
        ],
      ],
    ]);

    const sections = [ 1 , 2, 3, 4 ];
    let sectionParts = [1,2,3,4]
    let sectionIndex = 0;
    let partIndex = -1;
    const isPrivate = this.isPrivate();
    switch (quiz.id) {
      case QuizId.Investor:
        sectionIndex = 0;
        sectionParts = [ 1, 2 ];
        partIndex = 0;
        break;
      case QuizId.Private1:
        sectionIndex = 1;
        partIndex = 0;
        break;
      case QuizId.Private2:
        sectionIndex = 1;
        partIndex = this.pager.index + 1;
        break;
      case QuizId.AMLPrivate1:
        sectionIndex = this.pager.index < 2 ? 1 : 2;
        partIndex = this.pager.index < 2 ? this.pager.index + 3 : this.pager.index - 1;
        break;
      case QuizId.Amateur:
        sectionIndex = 3;
        switch (this.pager.index) {
          case 0:
          case 1:
            partIndex = 1;
            break;
          case 2:
          case 3:
            partIndex = 2;
            break;
          case 4:
          case 5:
            partIndex = 3;
          break;
        }
        break;
      case QuizId.AreYouSemiprofessional:
        sectionIndex = 3;
        sectionParts = [1, 2];
        partIndex = 0;
        break;
      case QuizId.Semiprofessional:
        sectionIndex = 3;
        partIndex = this.pager.index + 1;
        break;
      case QuizId.Experience:
        sectionIndex = 3;
        partIndex = 2 + this.pager.index;
        break;
      case QuizId.ExperiencePro:
        sectionIndex = 3;
        partIndex = 3;
        break;
      case QuizId.Upgrade:
        sectionIndex = 3;
        sectionParts = [1, 2];
        partIndex = 0;
        break;
      case QuizId.UpgradedToProfessional:
      case QuizId.Done:
      case QuizId.ProDone:
        sectionIndex = 3;
        partIndex = 4;
        break;
      case QuizId.Company1:
        sectionIndex = 1;
        partIndex = this.pager.index;
        break;
      case QuizId.Company2:
        sectionIndex = 1;
        partIndex = this.pager.index + 2;
        break;
      case QuizId.AMLCompany:
        sectionIndex = 2;
        partIndex = this.pager.index;
        break;
      case QuizId.AMLCompany2:
        sectionIndex = 2;
        partIndex = this.pager.index + 3;
        if (partIndex > 4) {
          partIndex = 4;
        }
        break;
      case QuizId.Alternative:
        sectionIndex = 2;
        partIndex = 4;
        break;
    }
    return { sections, sectionIndex, sectionParts, partIndex};
  }

  storePrivateKYC(postData: object) {
    let url = '/api/v2/StorePrivateKYC/';
    this.postStoreData(url, postData);
  }

  storeCorporateKYC(postData: object) {
    let url = '/api/v2/StorePrivateKYC/';
    this.postStoreData(url, postData);
  }

  postStoreData(url: string, postData: Object){
    return this._ws.KycStoreData(url, postData).subscribe((data) => console.log(data))
  }

  postStoreData$(url: string, postData: Object){
    return this._ws.KycStoreData(url, postData).pipe(
      tap((data) => console.log(data)),
    );
  }

  /** Non linear slider scale - creates a curve given min, middle and max values
   * calculates the value of the slider from an actual slider value form 0 to 1
   * 
   * Based on https://stackoverflow.com/a/17102320/735750
   */
  calculateSliderDisplayValue(inputValue: number, min: number, middle: number, max: number) {
    const curve = min - 2 * middle + max;
    if (curve === 0) {
      // this is a linear scale so we give simple linear answer
      return ((max - min) * inputValue).toPrecision(3);
    }
    const a = (min * max - middle * middle) / curve;
    const b = Math.pow( (middle - min), 2) / curve;
    const c = 2 * Math.log((max - middle) / (middle- min));

    return (a + b * Math.exp(c * inputValue)).toPrecision(3);
  }

  /** The inverse of calculateSliderDisplayValue() - calculates a slider position from 0 to 1 that fits displayValue
   * on the curve definedd by min, middle, max.
   */
  calculateSliderActualValue(displayValue: number, min: number, middle: number, max: number) {
    const curve = min - 2 * middle + max;
    if (curve === 0) {
      // this is a linear scale so we give simple linear answer
      return displayValue / (max - min);
    }
    const a = (min * max - middle * middle) / curve;
    const b = Math.pow( (middle - min), 2) / curve;
    const c = 2 * Math.log((max - middle) / (middle- min));

    return Math.log((displayValue - a) / b) / c;
  }

  onSubmit() {
    const quiz = this.quiz;
    let answers: QuizAnswer[] = [];
    const questions = quiz.questions.sort((p, q) => p.id < q.id ? -1 : p.id === q.id ? 0 : 1);
    questions.forEach(
      (q) => { 
        const answer = this._extractAndCacheAnswerFromQuestion(quiz, q);
        answers.push(answer);
    });
    questions.forEach(q => {
      const foundIndex = this.allAnswers.findIndex((a) => a.questionId === q.id && a.quizId ===this.quiz.id );
      const value = this.findAnswerInQuestion(q);
      if (foundIndex < 0) {
        // add new answer
        this.allAnswers.push({
          'name': quiz.name,
          'quizId': quiz.id,
          'questionId': q.id,
          'answer': value,
        });
      } else {
        // update existing answer
        this.allAnswers[foundIndex].answer = value;
      }
    });
    if (this.DEBUG) {
      console.debug('Answers for quiz', this.quiz.id, answers, 'All answers', this.allAnswers);
    }
    switch (answers[0].quizId) {
      case QuizId.Investor: { // Investor: select company or private investor
        if (answers[0].answer === "Private person") {
          this.NewKYC.IsPrivate = true;
          this.loadQuiz(answers, QuizId.Private1)
        }
        else {
          this.NewKYC.IsPrivate = false;
          this.loadQuiz(answers, QuizId.Company1)
        }
        break;
      }
      case QuizId.Private1: { // if quiz is Private1
        this.NewContact.FirstName = this.findAnswerInQuestion(quiz, 2).toString();
        this.NewContact.LastName = this.findAnswerInQuestion(quiz, 3).toString();
        this.NewContact.NationalityCode = this.findAnswerInQuestion(quiz, 4).toString();
        this.NewContact.SocialSecurityNumber = this.findAnswerInQuestion(quiz, 5).toString();
        this.postStoreData('/api/v2/StoreContacts/', this.NewContact)
        this.loadQuiz(answers, this.quizes[19].id)//load AMLPrivate
        break;
      }
      case QuizId.Private2:
        this.NewContact.Address = this.findAnswerInQuestion(quiz, 9).toString(),
        this.NewContact.City = this.findAnswerInQuestion(quiz, 7).toString();
        this.NewContact.CountryCode = this.findAnswerInQuestion(quiz, 6).toString();
        this.NewContact.Email = this.findAnswerInQuestion(quiz, 11).toString();
        this.NewContact.Telephone = this.findAnswerInQuestion(quiz, 10).toString();
        this.NewContact.Zip = this.findAnswerInQuestion(quiz, 8).toString();
        this.postStoreData('/api/v2/StoreContacts/', this.NewContact);
        this.loadQuiz(answers, this.quizes[5].id)//load AMLPrivate
        break;
      case QuizId.Company1: { //if quiz is company1
        const activity = this.findAnswerInQuestion(quiz, 4).toString();
        const businessId = this.findAnswerInQuestion(quiz, 2).toString();
        const businessName = this.findAnswerInQuestion(quiz, 1).toString();
        const domicile = this.findAnswerInQuestion(quiz, 3).toString();
        const repName = this.findAnswerInQuestion(quiz, 5).toString().trim().toString();
        const repId = this.findAnswerInQuestion(quiz, 6).toString();

        // TODO: separate first, last name fields
        const lastSpace = repName.lastIndexOf(' ');
        let repFirst = repName;
        let repLast = repName;
        if (lastSpace >= 0 && lastSpace < repName.length) {
          repFirst = repName.substring(0, lastSpace);
          repLast = repName.substring(lastSpace + 1, repName.length);
        }

        const oldCorp = this._existingCompanies.find(c => c.BussinessId === businessId.toString());
        if (oldCorp) {
          this.OldCorp = oldCorp;
          this.NewCorp = new Companies();
          this.NewCorp.EntityId = this.OldCorp.EntityId;
          this._entityId = this.OldCorp.EntityId;
          this.NewCorp.UserId = this.UserId;
          this.OldKYC = oldCorp.KYC;
          this.NewKYC = new CorporateKYC();
          this.NewKYC.UserId = this.UserId;
          this.NewKYC.EntityId = this.OldCorp.EntityId;
          this.NewKYC.KYCId = isEmpty(this.OldKYC.KYCId) ? undefined : this.OldKYC.KYCId;
        } else {
          this.OldCorp = new Companies();
          this.NewCorp = new Companies();
          this.NewCorp.UserId = this.UserId;
          this.OldKYC = new CorporateKYC();
          this.NewKYC = new CorporateKYC();
          this.NewKYC.UserId = this.UserId;
        }
        const newCorp = this.NewCorp as Companies;
        newCorp.Active = true;
        newCorp.BussinessId = businessId.toString();
        newCorp.Domicile = domicile.toString();
        newCorp.DomicileCode = domicile.toString();
        newCorp.EntitiesMainBusinessAccordingToTheArticlesOfAssociation = activity.toString();
        newCorp.NameOfTheEntity = businessName.toString();
        const repContact = {
          "FirstName": repFirst,
          "LastName": repLast,
          "SocialSecurityNumber": repId,
        } as Contacts;
        newCorp.RepresentativeContact = repContact;
        let entity: Object = null;
        if (Array.isArray(this._existingCompanies)) {
          entity = this._existingCompanies.find(co => co.BussinessId === businessId);
        }
        if (entity && entity['EntityId']) {
          newCorp.EntityId = entity['EntityId'];
          this._entityId = entity['EntityId'];
        }
        this.postStoreData$('/api/v2/StoreCompanies/', newCorp).subscribe({
          next: (result) => {
            // ...
          },
          error: (err) => {
            this._appService.showNotification('Save error', 
              'An error occured when saving company information.',
              NotificationType.Error,
            );
            console.error(err);
          }
        });
        this.loadQuiz(answers, QuizId.Company2) //load AMLCompany
        break;
      }
      case QuizId.Company2: { //if quiz is company2
        const address = this.findAnswerInQuestion(QuizId.Company2, 10);
        const city = this.findAnswerInQuestion(QuizId.Company2, 8);
        const postalCode = this.findAnswerInQuestion(QuizId.Company2, 9);
        const country = this.findAnswerInQuestion(QuizId.Company2, 7);
        const tel = this.findAnswerInQuestion(QuizId.Company2, 11);
        const email = this.findAnswerInQuestion(QuizId.Company2, 12);
        const newCorp = this.NewCorp;
        newCorp.Active = true;
        newCorp.Address = address.toString();
        newCorp.City = city.toString();
        newCorp.CountryCode = country.toString();
        newCorp.Email = email.toString();
        newCorp.Telephone = tel.toString();
        newCorp.Zip = postalCode.toString();
        this.postStoreData('/api/v2/StoreCompanies/', newCorp)
        this.loadQuiz(answers, QuizId.AMLCompany) //load AMLCompany
        break;
      }
      case QuizId.AMLCompany2: { //if quiz is companyAML2
        const moreThan25PercentOwners = this.findAnswerInQuestion(QuizId.AMLCompany2, 11);
        const isNonProfit = this.findAnswerInQuestion(QuizId.AMLCompany2, 12)
        
        let postData = {
          "UserId": this.UserId,
          "DoesSomebodyOwnMoreThen25Percent": moreThan25PercentOwners === 'Yes',
          "NonProfitOrganisation": isNonProfit === 'Yes'
        }

        if (moreThan25PercentOwners === "Yes") {
          const shareholderAnswers = this._subQuizResults.get(QuizId.LargerShareHolder);
          let majorShareholders = [];
          if (shareholderAnswers != null && shareholderAnswers.length > 0) {
            majorShareholders = shareholderAnswers.map(
              (contactData) => {
                return {
                  "FirstName": contactData[0].answer,
                  "LastName": contactData[1].answer,
                  "SocialSecurityNumber": contactData[2].answer,
                  "OwnershipPercentOfCompany": this.normalizeNumber(contactData[3].answer),
                }
              }
            );
            postData["MoreThen25PercentOwners"] = majorShareholders;
          } else {
            this._appService.showNotification(
              'Error',
              'You stated that one or more shareholders own 25% or more of the organisation, but no share holders were specified.',
              NotificationType.Error
            );
            this.goToQuestion(QuizId.AMLCompany2, 11);
          }
        } else {
          postData["MoreThen25PercentOwners"] = [];
        }
        if (isNonProfit === 'Yes') {
          const boardMembersAnswers = this._subQuizResults.get(QuizId.NonprofitBoard);
          let boardMembers = [];
          boardMembers = boardMembersAnswers.map(
            (contactData) => {
              return {
                "FirstName": contactData[0].answer,
                "LastName": contactData[1].answer,
                "SocialSecurityNumber": contactData[2].answer,
              }
            }
          );
          postData["MembersOfTheBord"] = boardMembers;
        } else {
          postData["MembersOfTheBord"] = [];
        }
        this.postStoreData('/api/v2/StoreCorporateKYC/', postData);
        this.nextQuiz(answers, QuizId.AMLCompany2)
        break;
      }
      case QuizId.AMLCompany: { //if quiz is companyAML1
        let balance = this.findAnswerInQuestion(QuizId.AMLCompany, 9);
        balance = this.normalizeNumber(balance);
        let equity = this.findAnswerInQuestion(QuizId.AMLCompany, 8);
        equity = this.normalizeNumber(equity);
        let profitLoss = this.findAnswerInQuestion(QuizId.AMLCompany, 10);
        profitLoss = this.normalizeNumber(profitLoss);
        let turnover = this.findAnswerInQuestion(QuizId.AMLCompany, 7);
        turnover = this.normalizeNumber(turnover);
        let objective = this.findAnswerInQuestion(QuizId.AMLCompany, 2);
        const onBehalfOfOthers = this.findAnswerInQuestion(QuizId.AMLCompany, 4);
        const objectiveExplanation = this.findAnswerInQuestion(QuizId.AMLCompany, 3) as string;
        if (objective === 'Other') {
          if (objectiveExplanation != null && objectiveExplanation.trim().length > 0) {
            objective = `${objective}, ${objectiveExplanation.trim()}`;
          }
        }

        const isPEP = this.findAnswerInQuestion(QuizId.AMLCompany, 5) === 'Yes';
        const PepDescription = this.findAnswerInQuestion(QuizId.AMLCompany, 6);
        let sourceOfAssets = this.findAnswerInQuestion(QuizId.AMLCompany, 0);
        if (sourceOfAssets === 'Other') {
          const sourceOther = this.findAnswerInQuestion(QuizId.AMLCompany, 1) as string;
          if (sourceOther != null && sourceOther.trim().length > 0) {
            sourceOfAssets = `${sourceOfAssets}, ${sourceOther.trim()}`;
          }
        }

        const onBehalfOfContacts = [] as OnBehalfOfEntities[];
        const subquizAnswer = this._subQuizResults.get(QuizId.Behalfofother);
        if (subquizAnswer != null) {
          subquizAnswer.forEach(
            (contactAnswers) => {
              const contactType = contactAnswers.find(a => a.questionId === 0);
              const IsCompany = contactType.answer !== 'Private person';
              const first = contactAnswers.find(a => a.questionId === 1);
              const FirstName = first != null ? first.answer : '';
              const last = contactAnswers.find(a => a.questionId === 2);
              const LastName = last != null ? last.answer : '';
              const idAnswer = contactAnswers.find(a => a.questionId === 3);
              const contactId = idAnswer != null ? idAnswer.answer.toString() : '';
              const contact: OnBehalfOfEntities = {
                IsCompany,
               ID: contactId,
              };
              if (IsCompany) {
                contact.Name = FirstName.toString();
              } else {
                contact.FirstName = FirstName.toString();
                contact.LastName = LastName.toString();
              }
              onBehalfOfContacts.push(contact);
            }
          );
        }

        const newKYC = this.NewKYC as CorporateKYC;

        newKYC.Balance = +balance;
        newKYC.Equity = +equity;
        newKYC.InvestmentObjective = objective.toString();
        newKYC.InvestmentOnBehalfOfTheseEntities =  onBehalfOfContacts;
        newKYC.IsSomeoneOfTheBeneficiariesPEP = isPEP; //explantion for company
        newKYC.ProfitLoss = +profitLoss;
        newKYC.SourceOfAssetsUsedForTheInvestment = sourceOfAssets.toString(),
        newKYC.OwnInvestment = onBehalfOfOthers !== 'Yes',
        newKYC.Turnover = turnover;
        this.postStoreData('/api/v2/StoreCorporateKYC/', newKYC);
        if (isPEP && PepDescription) {
          const companyPepData = {
            EntityId: this._entityId,
            UserId: this.UserId,
            PepDescription,
          }
          this.postStoreData('/api/v2/StoreCompanies/', companyPepData);
        }
        this.loadQuiz(answers, QuizId.AMLCompany2);
        break;
      }
      case QuizId.AMLPrivate1: { // if quiz is AMLPrivate
        // gather answers
        let aIncome = this.findAnswerInQuestion(quiz, 1);
        let aCommitments = this.findAnswerInQuestion(quiz, 2);
        let aNetWorth = this.findAnswerInQuestion(quiz, 3);
        if (aIncome === '') {
          aIncome = null;
        }
        if (aCommitments === '') {
          aCommitments = null;
        }
        if (aNetWorth === '') {
          aNetWorth = null;
        }
        // 4 is removed
        const aOnBehalfOfOther = this.findAnswerInQuestion(quiz, 5) === 'Yes';
        const onBehalfOfContacts = [] as OnBehalfOfEntities[];
        const subquizAnswer = this._subQuizResults.get(QuizId.Behalfofother);
        if (subquizAnswer != null) {
          subquizAnswer.forEach(
            (contactAnswers) => {
              const contactType = contactAnswers.find(a => a.questionId === 0);
              const IsCompany = contactType.answer !== 'Private person';
              const first = contactAnswers.find(a => a.questionId === 1);
              const FirstName = first != null ? first.answer : '';
              const last = contactAnswers.find(a => a.questionId === 2);
              const LastName = last != null ? last.answer : '';
              const idAnswer = contactAnswers.find(a => a.questionId === 3);
              const contactId = idAnswer != null ? idAnswer.answer.toString() : '';
              const contact: OnBehalfOfEntities = {
                IsCompany,
                ID: contactId,
              };
              if (IsCompany) {
                contact.Name = FirstName.toString() + (LastName.toString().length > 0) ? ' ' + LastName : '';
              } else {
                contact.FirstName = FirstName.toString();
                contact.LastName = LastName.toString();
              }
              onBehalfOfContacts.push(contact);
            }
          );
        }
        const aSourceOfAssets = this.findAnswerInQuestion(quiz, 8);
        const aSourceOfAssetsOther = this.findAnswerInQuestion(quiz, 9);
        const aObjective = this.findAnswerInQuestion(quiz, 10);
        const aObjectiveOther  = this.findAnswerInQuestion(quiz, 11);
        const aInvestmentSize = this.findAnswerInQuestion(quiz, 12);
        const investmentSize = this.normalizeNumber(aInvestmentSize);
        
        const newKYC = this.NewKYC as PrivateKYC;
        newKYC.EconomicCommitmentsMonthly = aCommitments.toString();
        newKYC.EstimatedIncome = aIncome.toString();
        newKYC.InvestmentObjective = aObjective + "\n" + aObjectiveOther;
        newKYC.InvestmentOnBehalfOfOtherEntity = aOnBehalfOfOther;
        newKYC.InvestmentOnBehalfOfTheseEntities = onBehalfOfContacts;
        newKYC.OriginOfInvestedAssests = aSourceOfAssets + "\n" + aSourceOfAssetsOther;
        newKYC.TotalAssets = aNetWorth.toString();
        newKYC.InvestmentSize = investmentSize;

        const aFirstName = this._quizService.getCachedAnswer(QuizId.Private1, 2);
        const firstName = aFirstName && 'answer' in aFirstName ? aFirstName.answer : "";
        const aLastName = this._quizService.getCachedAnswer(QuizId.Private1, 3);
        const lastName = aLastName && 'answer' in aLastName ? aLastName.answer : "";
        const aIsPEP = this.findAnswerInQuestion(quiz, 6) === 'Yes';
        const aPEPOther = this.findAnswerInQuestion(quiz, 7);

        const newContact = this.NewContact;
        newContact.Pep = aIsPEP;
        newContact.PepDescription = aPEPOther.toString();

        this._ws.KycUpdatePrivateKyc(newKYC).subscribe({
          next: (response) => {
            if (!response) {
              this._appService.showNotification('Error', 'Saving Private AML failed', NotificationType.Error);
            }
          },
          error: (err) => {
            if (err) {
              this._appService.showNotification('Error', 'Saving Private AML failed\n'+err, NotificationType.Error);
              console.error(err);
            }
          }
        });
        this.postStoreData('/api/v2/StoreContacts/', newContact);
        
        if (
          (this.isGerman())
          && investmentSize >= 200000
        ) {
          this.loadQuiz(answers, QuizId.AreYouSemiprofessional) // AreYouSemiprofessional
        }
        else {
          this.loadQuiz(answers, QuizId.Upgrade) //load upgrade
        }
        break;
      }
      case QuizId.Upgrade: {// upgrade
        let url = '/api/v2/StorePrivateKYC/';
        if (!this.isPrivate()) {
          url = '/api/v2/StoreCorporateKYC/';
        }
        const newKYC = this.NewKYC;
        if (answers[0].answer === "No") {//answers No
          newKYC.IsPrivate = true;
          newKYC.IsProfessional =false;
          newKYC.IsSemiprofessional = false;
          this.postStoreData(url, newKYC);
          this.loadQuiz(answers, QuizId.AmateurDisclaimer) // AmateurDisclaimer
        } else {
          newKYC.IsPrivate = true;
          newKYC.IsProfessional = true;
          newKYC.IsSemiprofessional = false;
          this.postStoreData(url, newKYC);
          this.loadQuiz(answers, QuizId.Experience) // Experience
        } //else load upgrade questionnaire
        break;
      }
      case QuizId.Experience: { // experience quiz TODO: ADD TO DATABASE THE CLASSFICIATIONS NO NEED TO SAVE ANSWERS
        const isGerman = this.isGerman();
        const semipro = this.getKycValue('IsSemiprofessional') as boolean;
        const isSemipro = semipro && semipro && this.isPrivate();

        this._experiencePoints = 0
        let fail = false
        if (answers[0] != null && Array.isArray(answers[0].answer)) {
          for (const i of answers[0].answer) {
            if (i.id != 5) {
              this._experiencePoints = this._experiencePoints + 1
            }
          }
        }
        if (answers[1] != null && Array.isArray(answers[1].answer)) {
          for (const j of answers[1].answer) {
            if (j.id != 8) {
              // one point for each correct answer
              this._experiencePoints = this._experiencePoints + 1
            } else {
              // The only hard fail point in the experience quiz is question 2, option 8
              // "Futures are capital guaranteed".
              fail = true;
              this._appService.showNotification(
                'Wrong answer',
                'Futures do <strong>not</strong> have capital guarantees. You will have to take part of the quiz again to continue.',
                NotificationType.Error,
              );
              if (isGerman && isSemipro) {
                this.loadQuiz(answers, QuizId.AreYouSemiprofessional);
              } else {
                this.loadQuiz(answers, QuizId.Upgrade);
              }
              return;
            }
          }
        }

        if (!fail && this._experiencePoints >= 1) {
          // Professional category and information regarding it
          if (isGerman || isSemipro) {
            // Upgrade to Semiprofessional
            let postData = {
              "UserId": this.UserId,
              "IsProfessional": false,
              "IsSemiprofessional": true
            }
            if (this.isPrivate()) {
              let url = '/api/v2/StorePrivateKYC/'
              this.postStoreData(url, postData)
            }
            else {
              let url = '/api/v2/StoreCorporateKYC/'
              this.postStoreData(url, postData)
            }
            this.done(answers);
          } else {
            // professional
            let postData = {
              "UserId": this.UserId,
              "IsProfessional": true,
              "IsSemiprofessional": false
            }
            if (this.isPrivate()) {
              let url = '/api/v2/StorePrivateKYC/'
              this.postStoreData(url, postData)
            }
            else {
              let url = '/api/v2/StoreCorporateKYC/'
              this.postStoreData(url, postData);
            }
            this.loadQuiz(answers, QuizId.ExperiencePro);
          }
          return;
        }
        else {
          // Failed Experience quiz, restart upgrade quiz
          console.log('points or nmbr failed');
          if (this._experiencePoints === 0) {
            this._appService.showNotification(
              'Wrong answer',
              'You must indicate that you have at least some undestanding of investment by checking at least one option',
              NotificationType.Error,
            );
          }
          if (isGerman && isSemipro) {
            this.loadQuiz(answers, QuizId.AreYouSemiprofessional);
          } else {
            this.loadQuiz(answers, QuizId.Upgrade)
          }
          return;
        }
        break;
      }
      case QuizId.ExperiencePro:
        // This is the last question in the Experience quiz, but split into its own Quiz
        // because it is only relevant for pro investors, while the previous two should be shown top both 
        // pro and semi-pro investors.
        let nmr = -1;
        const investmentLevel = this.findAnswerInQuestion(QuizId.ExperiencePro, 1) as Option[];
        if (investmentLevel != null && Array.isArray(investmentLevel)) {
          nmr = investmentLevel.length;
        }
        if ((this._experiencePoints >= 1) && (nmr >= 2)) {
          let postData = {
            "UserId": this.UserId,
            "IsProfessional": true,
            "IsSemiprofessional": false
          }
          if (this.isPrivate()) {
            let url = '/api/v2/StorePrivateKYC/'
            this.postStoreData(url, postData)
          }
          else {
            let url = '/api/v2/StoreCorporateKYC/'
            this.postStoreData(url, postData)
          }
          this.loadQuiz(answers, QuizId.UpgradedToProfessional);
        }
        else {
          this._appService.showNotification(
            'Wrong answer',
            'At least two of these options must be true in order to qualify as a professional investor ',
            NotificationType.Error
          );
          this.loadQuiz(answers, QuizId.Upgrade);
        }
        break;
      case QuizId.Semiprofessional: { //Semi pro fälten i KYC ; TODO: SAVE FIELDS
        if (answers[0].answer >= 10e6 || answers[1].answer !== "Disagree") {
          if (answers[0].answer >= 10e6) {
            this._appService.showError(
              'Required upgrade to semi-professional',
              'If you have total investments greater than 10 million euro you must be classed as semi-professional under German law.'
            );
            this.loadQuiz(answers, QuizId.UpgradedToSemiProfessional) // UpgradeToSemiprofessional
          } else {
            this.loadQuiz(answers, QuizId.Experience);
          }
        }
        else {
          if (answers[1].answer === "Disagree") {
            if (this.isPrivate()) {
              this.loadQuiz(answers, QuizId.AreYouSemiprofessional);
              // Restart investment questions
              this.allAnswers = this.allAnswers.filter(i => i.quizId <= QuizId.AMLPrivate1)
              this._quizService.clearAnswerCache(QuizId.AMLPrivate1);
            } else {
              this.loadQuiz(answers, QuizId.Alternative);
              // remove Semiprofessional related questions
              this.allAnswers = this.allAnswers.filter(
                i => ([QuizId.Semiprofessional, QuizId.UpgradedToSemiProfessional, QuizId.Alternative]).includes(i.quizId)
              );
            }
          } else {
            this.loadQuiz(answers, QuizId.Experience)
          }
        }
        break;
      }
      case QuizId.Amateur: { //amateur
        const newKYC = this.NewKYC;
        newKYC.Age = Math.round(+this.findAnswerInQuestion(quiz, 1)),
        newKYC.AgreeToUpgrade = false,
        newKYC.OpinionOnExperience = this.findAnswerInQuestion(quiz, 3).toString();
        newKYC.RiskTolerance = this.findAnswerInQuestion(quiz, 4).toString(),
        newKYC.ReturnGoal = Math.round(+this.findAnswerInQuestion(quiz, 5) * 10000) / 10000;
        newKYC.VolatilityGoal = Math.round(+this.findAnswerInQuestion(quiz, 6) * 10000) / 10000;
        newKYC.InvestmentHorison = this.findAnswerInQuestion(quiz, 7).toString();
        newKYC.BadNewsTolerance = this.findAnswerInQuestion(quiz, 8).toString();
        if (this.isPrivate()) {
          let url = '/api/v2/StorePrivateKYC/'
          this.postStoreData(url, newKYC);
        }
        else {
          let url = '/api/v2/StoreCorporateKYC/'
          this.postStoreData(url, newKYC)
        }
        this.done(answers);
        break;
      }
      case QuizId.UpgradedToProfessional: {
        if (answers[0].answer) {
          this.proDone(answers);
        } else {
          this._appService.showError('Answer required', 'You must answer the question to continue');
        }
        break;
      }
      case QuizId.AreYouSemiprofessional: {
        let url = '/api/v2/StorePrivateKYC/';
        const newKYC = this.NewKYC;
        if (answers[0].answer === "No") {//answers No
          newKYC.IsPrivate = true;
          newKYC.IsProfessional = false;
          newKYC.IsSemiprofessional = false;
          this.postStoreData(url, newKYC);
          this.loadQuiz(answers, QuizId.Upgrade) // Upgrade
        } else {
          // Is semi pro
          newKYC.IsPrivate = true;
          newKYC.IsProfessional = false;
          newKYC.IsSemiprofessional = true;
          this.postStoreData(url, newKYC);
          this.loadQuiz(answers, QuizId.Semiprofessional) // Semiprofessional
        }

        break;
      }
      case QuizId.AmateurDisclaimer: {
        const agreement = this.findAnswerInQuestion(quiz, 274);
        const newKYC = this.NewKYC;
        newKYC.AgreementToAdvice = agreement === 'I understand';
        let url = '/api/v2/StorePrivateKYC/';
        this.postStoreData(url, newKYC);
        if (agreement === 'I understand') {
          this.loadQuiz(answers, QuizId.Amateur);
        }
        break;
      }
      case QuizId.Alternative: {
        console.log(this.allAnswers)
        if (answers[0].answer === "Semi professional (Germany only)" && (this.isGerman())) {
          this.loadQuiz(answers, QuizId.Semiprofessional) // Semiprofessional
        }
        else {
          if (answers[0].answer === "None of the above") {
            this.loadQuiz(answers, QuizId.Upgrade) // Upgrade
          } //load Upgrade
          else {
            if (answers[0].answer === "Large Undertaking") {
              let nmr = 0;
              const aBalance = this._quizService.getCachedAnswer(QuizId.AMLCompany, 9);
              const balance = aBalance != null ? +aBalance.answer : 0;
              const aTurnover = this._quizService.getCachedAnswer(QuizId.AMLCompany, 7);
              const turnover = aTurnover != null ? +aTurnover.answer : 0;
              const aOwnFunds = this._quizService.getCachedAnswer(QuizId.AMLCompany, 8);
              const ownFunds = aOwnFunds != null ? +aOwnFunds.answer : 0;

              if (balance >= 20) {
                nmr = nmr + 1
              }
              if (turnover >= 40) {
                nmr = nmr + 1
              }
              if (ownFunds >= 2) {
                nmr = nmr + 1
              }
              if (nmr >= 2) {
                this.loadQuiz(answers, QuizId.UpgradedToProfessional) // UpgradedToProfessional
              }
              else {
                this.loadQuiz(answers, QuizId.Upgrade) // Upgrade
              }
            }
            else {
              this.loadQuiz(answers, QuizId.Upgrade) // Upgrade
            }
          }
        }
        break;
      }
      case QuizId.Done:
      case QuizId.ProDone:
      // case QuizId.UpgradedToSemiProfessional:
        if (this.findAnswerInQuestion(quiz, 0) === 'OK') {
          this._router.navigate(['/']);
        }
        break;
    }
  }

  private normalizeNumber(answer: string | number | boolean | Option[]) {
    let numVal = 0;
    if (typeof answer === 'number') {
      numVal = answer;
    }
    if (typeof answer === 'string') {
      numVal = parseFloat(answer);
      if (isNaN(numVal)) {
        numVal = null;
      } else {
        numVal = Math.round(numVal);
      }
    }
    return numVal;
  }

  done(answers: QuizAnswer[]) {
    this.loadQuiz(answers, QuizId.Done);
    this.getLastUpdate$({userId: this.UserId}).subscribe({
      next: (lastUpdate) => {
        const expirationDate = (lastUpdate ? lastUpdate : moment.tz(this._appService.TZ)).add(2, 'years');
        setTimeout(() => {
          this.quiz.description = this.quiz.description.replace('XX-XX-XXXX', expirationDate.format('DD-MMM-YYYY'));
        });
      }
    });
  }
  
  proDone(answers: QuizAnswer[]) {
    this.loadQuiz(answers, QuizId.ProDone);
    this.getLastUpdate$({userId: this.UserId, entityId: this._entityId}).subscribe({
      next: (lastUpdate) => {
        const expirationDate = (lastUpdate ? lastUpdate : moment.tz(this._appService.TZ)).add(2, 'years');
        setTimeout(() => {
          this.quiz.description = this.quiz.description.replace('XX-XX-XXXX', expirationDate.format('DD-MMM-YYYY'));
        });
      }
    });
  }
  
  getLastUpdate$({userId, entityId } : {userId: number, entityId?: number}): Observable<moment.Moment> {
    let contact$: Observable<any>;
    if (entityId != null) {
      contact$ = this._ws.KycGetCompany(userId, entityId);
    } else {
      contact$ = this._ws.KycGetContact(userId);
    }
    return contact$.pipe(
      map((contact) => {
        if (contact['KYC'] && contact.KYC['UpdatedTimestamp']) {
          return moment.tz(contact.KYC.UpdatedTimestamp, this._appService.TZ);
        }
      }));
  }
  
  private _extractAndCacheAnswerFromQuestion(quiz: Quiz, q: Question) {
    let cache = this._quizService.getCachedAnswer(quiz.id, q.id) as QuizAnswer;
    if (cache != null) {
      cache.answer = this.findAnswerInQuestion(q);
      if (this.DEBUG) {
        console.debug('Question', q);
        console.debug('Cached answer', cache)
      }
      return cache;
    } else {
      const newAnswer = {
        'name': q.name,
        'quizId': quiz.id,
        'questionId': q.id,
        'answer': this.findAnswerInQuestion(q),
      } as QuizAnswer;
      this._quizService.setCachedAnswer(quiz.id, q.id, newAnswer);
      if (this.DEBUG) {
        console.debug('Cached answer', newAnswer)
      }
      return newAnswer;
    }
  }

/*
  postPrivateContacts(answers) {
    return this.authSvc.getCurrentUser().subscribe(user =>
      this.ws.getStoreContacts(user.userId, answers[7].answer, answers[5].answer,  answers[4].answer, answers[9].answer, 0,
        answers[0].answer, null, answers[1].answer, answers[2].answer, null,"none", answers[3].answer, answers[8].answer, answers[6].answer).subscribe((data) => console.log(data)))
      ;
  };
  postCompanyContacts(answers) {
    return this.authSvc.getCurrentUser().subscribe(user =>
      this.ws.getStoreContacts(user.userId, answers[7].answer, answers[5].answer,  answers[4].answer, answers[9].answer, 0,
        answers[0].answer, null, answers[1].answer, answers[2].answer, null,"none", answers[3].answer, answers[8].answer, answers[6].answer).subscribe((data) => console.log(data)))
      ;
  };


  postPrivateKYC(answers) {
    this.authSvc.getCurrentUser().subscribe(user => //userid, monthly, income, entityid, objective, behalf, loans, origin, total
      this.ws.getStorePrivateKYC(user.userId, answers[1].answer, answers[0].answer, 0, answers[8].answer.concat(answers[9].answer),
        answers[10].answer, answers[2].answer, answers[6].answer.concat(answers[7].answer), answers[3].answer).subscribe((data) => console.log(data)))
    if (answers[5].answer === "") {
      answers[5].answer = "none"
    }
    return this.authSvc.getCurrentUser().subscribe(user =>
      this.ws.getStoreContacts(user.userId, "","",  "", "", 0,
        "", "", "", "", answers[4].answer,answers[5].answer, "", "","").subscribe((data) => console.log(data)))
      ;
  };


  postCompany(answers) {
    return this.authSvc.getCurrentUser().subscribe(user => //true, 9, 1, 7, 6, 2, 11,3, entityID, UserId, KYC, 0, 4, 10, 8
      this.ws.getStoreCompanies(true, answers[9].answer, answers[1].answer, answers[7].answer,
        answers[6].answer, answers[2].answer, answers[2].answer, answers[11].answer, answers[3].answer,
        0, user.userId, null,answers[0].answer, answers[4].answer, answers[10].answer, answers[8].answer)
        .subscribe((data) => console.log(data)))
      ;
  };
  testPostCompanies() {
    this.authSvc.getCurrentUser().subscribe(user =>
      this.ws.getStoreCompanies(true,"Address","BussinessId","City","FI","Domicile","FI","Email","EntitiesMainBusinessAccordingToTheArticlesOfAssociation",
        0,user.userId,null,"NameOfTheEntity",12345,"Telephone","Zip").subscribe((data) => console.log(data)))
  }
  testPostContacts(){
    this.authSvc.getCurrentUser().subscribe(user => //true, 9, 1, 7, 6, 2, 11,3, entityID, UserId, KYC, 0, 4, 10, 8
      this.ws.getStoreContacts(user.userId,"Address2","City2","SE","Email2",0,
        "FirstName2",null,"LastName","FI",
        false,"none","SocialSecNumber","Telephone","Zip")
        .subscribe((data) => console.log(data)))
  }
  testPostKYC() {
    this.authSvc.getCurrentUser().subscribe(user =>
      this.ws.getStoreKYC(25,false,true,true,
        "BadNewsTolerance",0,"InvestmentHorizon",123.45,67890,true,
        false,false,0,1234.56,"OpinionOnExperience",7890,
        12.3,"RiskTolerance",123456,user.userId,789.0,1234)
        .subscribe((data) => console.log(data)))
  }
  testPostCorporateKYC () {
    this.authSvc.getCurrentUser().subscribe(user => //userid, monthly, income, entityid, objective, behalf, loans, origin, total
      this.ws.getStorePrivateKYC(user.userId,1234.56,789.01,0,
        "InvestmentObjective",false,
        2345.67,"OriginOfInvestedAssests",8901.23).subscribe((data) => console.log(data)))
  }
  testPOstCorporateKYC2 () {
    this.authSvc.getCurrentUser().subscribe(user => //true, 9, 1, 7, 6, 2, 11,3, entityID, UserId, KYC, 0, 4, 10, 8
      this.ws.getStoreCorporateKYC(user.userId, 1234.56,false,"EntityType",
        7890.12,3456,"InvestmentObjective",false,
        false,false,true,null,8901.23,
        "SourceOfAssetsUsedForTheInvestment",4567.89)
        .subscribe((data) => console.log(data)))
  }
  /*
  postCorporateKYC(answers) {
    return this.authSvc.getCurrentUser().subscribe(user => //true, 9, 1, 7, 6, 2, 11,3, entityID, UserId, KYC, 0, 4, 10, 8
      this.ws.getStoreCorporateKYC(true, answers[9].answer, answers[1].answer, answers[7].answer,
        answers[6].answer, answers[2].answer, "FI", answers[11].answer, answers[3].answer,
        0, user.userId, null,answers[0].answer, answers[4].answer, answers[10].answer, answers[8].answer)
        .subscribe((data) => console.log(data)))
  };
  postKYC(answers) {
    return this.authSvc.getCurrentUser().subscribe(user => //true, 9, 1, 7, 6, 2, 11,3, entityID, UserId, KYC, 0, 4, 10, 8
      this.ws.getStoreKYC(0,answers[9].answer, answers[1].answer, answers[7].answer,
        answers[6].answer, answers[2].answer, "FI", answers[11].answer, answers[3].answer,
        false, user.userId, null,answers[0].answer, answers[4].answer, answers[10].answer, answers[8].answer)
        .subscribe((data) => console.log(data)))
  };

  this.authSvc.getCurrentUser().subscribe(user =>
        this.ws.getStoreKYC(25,false,true,true,
          "BadNewsTolerance",0,"InvestmentHorizon",123.45,67890,true,
          false,false,0,1234.56,"OpinionOnExperience",7890,
          12.3,"RiskTolerance",123456,user.userId,789.0,1234)
          .subscribe((data) => console.log(data)))*/
}

