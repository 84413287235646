import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TZ } from '@app/account-overview/utils';
import { SpinnerService } from '@chevtek/angular-spinners';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment-timezone';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountDataService, AccountIndexDescription } from '../../account-data/account-data.service';
import { AccountPerformance, cmpAcctPerfs } from '../../account-data/account-performance';
import { AcctIdx, cmpAcctIdxs } from '../../account-data/acct-idx';
import {
  BenchmarkIntradayPerformance, LandingPageDailyData, LandingPageData, MarketIntradayReturn, PspWebService, ToDatePerformances
} from '../../api/webservice.service';
import { AppService } from '../../app-service.service';
import { AuthenticationService } from '../../auth/authentication.service';
import { DataTableColumn } from '../../shared/data-table/data-table-models';
import { LoggingService } from '../../utils/logging/logging.service';

function copyObjectDataToInstance(objectData: object, instance) {
  Object.keys(objectData).map(key => {
    // XXX: A bit naughty side-effecting here
    const value = objectData[key];
    instance[key] = objectData[key];
  });
  return instance;
}


function updateList(originalList, newList) {
  for (let i = 0; i < newList.length; i++) {
    if (i >= originalList.length) {
      originalList.push({});
    }
    copyObjectDataToInstance(newList[i], originalList[i]);
  }
  const d = Math.max(0, originalList.length - newList.length);
  originalList.splice(newList.length + d);
}

enum DataPeriods { Today = 'today', LastDay = 'last-day', MTD = 'MTD', YTD = 'YTD' }

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  landingPageData: LandingPageData;
  landingPageDailyData: LandingPageDailyData;

  accountIndexDescriptions$: Observable<AccountIndexDescription[]>;
  accountPerformances: AccountPerformance[] = [];
  accountPerformanceMax = 0;
  accountSelected = {};
  allAcctPerfs: AccountPerformance[] = [];
  accountTableColumns: DataTableColumn[];
  accountTableOptions = {};
  benchmarkPerformance: {
    name: string,
    performance: ToDatePerformances | number,
    date: moment.Moment;
  };
  DataPeriods = DataPeriods;
  KeyMarketPerformances = [];

  layoutMode$: Observable<'narrow'|'wide'>;

  selectedPeriod = DataPeriods.Today;
  benchmarkAccount;

  isPeriodDrawdownVisible = false;

  asOfDate: moment.Moment;
  showHiddenAcctsHelp = false;
  percentbarWidth: number;

  private readonly accountIndexDescriptionColumn = { name: 'desc', label: '', type: 'popup-text' };

  constructor(
    private accountDataService: AccountDataService,
    private appService: AppService,
    private breakpoints: BreakpointObserver,
    private logger: LoggingService,
    private notificationsService: NotificationsService,
    private spinnerService: SpinnerService,
    private router: Router,
    private el: ElementRef,
  ) {
    this.layoutMode$ = this.appService.layoutMode.asObservable();
    this.accountIndexDescriptions$ = this.accountDataService.getAccountIndexDescriptions$();
  }

  ngOnInit() {
    this.asOfDate = moment.tz(TZ).subtract(1, 'days').startOf('day');

    this.breakpoints.observe(['(min-width: 769px)', ]).subscribe(
      (state) => {
        if (state.matches) {
          this.percentbarWidth = 167;
        } else {
          this.percentbarWidth = 80;
        }
      }
    );
    this.breakpoints.observe(['(max-width: 400px)', ]).subscribe(
      (state) => {
        if (state.matches) {
          this.percentbarWidth = 60;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.changePeriod(this.selectedPeriod);
  }


  /**
   * The default minimum scale used for the bars, based on this.selectedPeriod.
   * Uses this.accountPerformanceMax if it is greater than the default.
   */
  get performanceScale(): number {
    const scale = {
      [DataPeriods.Today]: 1.5,
      [DataPeriods.LastDay]: 1.5,
      [DataPeriods.MTD]: 5.0,
      [DataPeriods.YTD]: 10.0
    }[this.selectedPeriod];
    return Math.max(this.accountPerformanceMax, scale / 100);
  }

  getPeriodValue(data: ToDatePerformances): number {
    const periodValue = {
      [DataPeriods.LastDay]: data.Last,
      [DataPeriods.MTD]: data.MTD,
      [DataPeriods.YTD]: data.YTD
    }[this.selectedPeriod];
    return periodValue || data.Last;
  }

  showIntradayPrice(forceReload = false) {
    setTimeout(() => this.spinnerService.show('dashboard-portfolios'));
    this.showHiddenAcctsHelp = false;
    forkJoin([
      this.accountDataService.getLandingPage$(forceReload),
      this.accountIndexDescriptions$.pipe(take(1)),
    ]).subscribe(([landingPageData, accountIndexDecriptions]: [LandingPageData, AccountIndexDescription[]]) => {
      // Update account performances
      let acctPerfList = landingPageData.AccountInfos.map(accountOverview => {
        try {
          const account = accountOverview.Account;
          const value = accountOverview.AccountIntradayPerformance.IntradayPerformance.Performance;
          return new AccountPerformance(
            account.AccountId,
            account.Name,
            value,
            accountOverview.AccountIntradayPerformance.IntradayPerformance.Time,
          );
        } catch (error) {
          if (error instanceof TypeError) {
            console.warn('Skipping empty account:', error);
            return;
          }
        }
      });

      if (landingPageData.IndexInfos !== null && landingPageData.IndexInfos.length) {
        const idxPerf = landingPageData.IndexInfos.map(idxOverview => {
          try {
            const idx = idxOverview.Index;
            const value = idxOverview.IndexIntradayPerformance.IntradayPerformance.Performance;
            return new AccountPerformance(
              idx.IndexId.toString(),
              idx.Name,
              value,
              idxOverview.IndexIntradayPerformance.IntradayPerformance.Time,
            );
          } catch (error) {
            if (error instanceof TypeError) {
              console.warn('Skipping empty index:', error);
              return;
            }
          }
        });
        acctPerfList = acctPerfList.concat(idxPerf);
      }
      acctPerfList = acctPerfList.filter(perf => perf != null);
      acctPerfList.sort((a, b) => cmpAcctPerfs(a, b) );
      this.allAcctPerfs = acctPerfList.slice();

      this.accountDataService.loadAcctIdxs(undefined, forceReload).subscribe(
        // Select and order acctPerf according to settings
        (aiData) => {
          if (aiData == null) {
            console.error('No account data recieved');
            acctPerfList = [];
          } else {
            const acctIdxs = aiData[0] as AcctIdx[];
            if (acctIdxs != null) {
              const newAcctPerf: AccountPerformance[] = [];
              acctIdxs.sort(cmpAcctIdxs);
              for (const ai of acctIdxs) {
                const found = acctPerfList.find(ap => ap.accountId === ai.Id);
                if (found) {
                  found['order'] = ai.Order;
                  newAcctPerf.push(found);
                }
              }
              acctPerfList = newAcctPerf;
              if (acctPerfList.length === 0) {
                this.showHiddenAcctsHelp = true;
              }
            }
          }
        },
        (err) => this.notificationsService.warn('Could not load home page settings', 'Using default portfolio selection.'),
        // Update tables. Will use original acctPerf if settings errors out.
        () => setTimeout(() => {
          this.updateIntradayTables(
            acctPerfList, landingPageData.BenchmarkPerformance, landingPageData.KeyMarketPerformances, accountIndexDecriptions
          );
          if (acctPerfList.length > 0) {
            this.asOfDate = moment.tz(acctPerfList[0].time, this.appService.TZ);
	  }
          this.appService.updateTime = (acctPerfList.length > 0 ? moment.tz(acctPerfList[0].time, this.appService.TZ).toDate() : null);
          this.appService.updateTimeFormat = 'default';
          this.spinnerService.hide('dashboard-portfolios');
        })
      );
    }, err => {
      this.logger.error('Cannot load landing page data', err);
      this.notificationsService.error('Error', 'Error while downloading data from the server. Try refreshing the page.');
      this.spinnerService.hide('dashboard-portfolios');
    });
  }

  updateIntradayTables(
    acctPerfs: AccountPerformance[],
    benchmarkPerf: BenchmarkIntradayPerformance,
    keyMarketPerfs: MarketIntradayReturn[],
    accountIndexDecriptions: AccountIndexDescription[]
  ) {

    acctPerfs.forEach((acctPerf) => {
      const description = accountIndexDecriptions.find((d) => d.portfolio_id === acctPerf.accountId);
      let desc = null;
      if (description && 'portfolio_description' in description) {
        desc = description.portfolio_description;
      }
      acctPerf['desc'] = desc;
    });

    // Update benchmark performance
   if (benchmarkPerf && benchmarkPerf.Benchmark && benchmarkPerf.Benchmark.Index) {
      this.benchmarkPerformance = {
        name: benchmarkPerf.Benchmark.Index.Name,
        performance: Number(benchmarkPerf.IntradayPerformance.Performance),
        date: moment.tz(benchmarkPerf.IntradayPerformance.Time, 'Europe/Helsinki'),
      };
    } else {
      this.benchmarkPerformance = null;
    }

    // update account performances
    // updateList(this.accountPerformances, acctPerfs);
    this.accountPerformances = acctPerfs;

    // Update key market performances
    // updateList(this.KeyMarketPerformances, keyMarketPerfs);
    this.KeyMarketPerformances = keyMarketPerfs;

    // update performance max
    this.accountPerformanceMax = Math.max(
      acctPerfs.reduce((acc, item) => Math.max(acc, Math.abs(item.performance)), 0),
        this.benchmarkPerformance!=null ? this.benchmarkPerformance.performance as number : 0
    );

    // set up table columns
    this.accountTableColumns = [
      {
        name: 'accountName',
        label: 'Portfolios',
        type: 'text',
        sort: (list: AccountPerformance[], key, ascending = false, cmp) => {
          list.sort((a, b) => cmpAcctPerfs(a, b) * (ascending ? 1 : -1));
          return list;
        }
      } as  DataTableColumn,
      this.accountIndexDescriptionColumn,
      { name: 'performance', label: 'Performance', type: 'percentbar', scale: this.performanceScale, } as DataTableColumn,
    ];
    this.accountTableOptions = { id : 'accountId' };

  }

  /**
   * Set the width of the performance column in the key markets table to the same as in the portfolio DataTable. Called by
   * DataTableComponent.columnWidths Output()
   *
   * @param columnWidths - widths of data cells in pf table (in pixels)
   */
  public setMarketTableColumnWidths(columnWidths: number[]) {
    let lastCol;
    if (columnWidths != null && columnWidths.length > 0) {
      lastCol = `${columnWidths.pop()}px`;
    } else {
      lastCol = 'auto';
    }

    const cells: NodeListOf<HTMLTableDataCellElement> = this.el.nativeElement.querySelectorAll('div.km-table .performance');
    if (cells.length > 0) {
      Array.from(cells).forEach((cell) => cell.style.width = lastCol);
    }
  }

  public showDailyData(dataPeriod: DataPeriods, forceReload = false): void {
    setTimeout(() => this.spinnerService.show('dashboard-portfolios'));
    this.showHiddenAcctsHelp = false;
    const dataDate = moment().tz(TZ).subtract(1, 'day').startOf('day');
    forkJoin([
      this.accountDataService.getLandingPageDaily$(dataDate.format('YYYY-MM-DD'), forceReload),
      this.accountIndexDescriptions$.pipe(take(1)),
    ]).subscribe(([landingPageData, accountIndexDecriptions]: [LandingPageDailyData, AccountIndexDescription[]]) => {
        let acctPerf = landingPageData.AccountPerformances.map(accountOverview => {
          try {
            const value = this.getPeriodValue(accountOverview.ToDatePerformances);
            return new AccountPerformance(
              accountOverview.Account.AccountId,
              accountOverview.Account.Name,
              value,
              accountOverview.ToDatePerformances.Date,
            );
          } catch (error) {
            if (error instanceof TypeError) {
              console.warn('Skipping empty account:', error);
              return;
            }
          }
        });

        if (acctPerf.length > 0) {
          this.asOfDate =  moment.tz(acctPerf[0].time, this.appService.TZ);
        }
        const updateTime = acctPerf.length > 0 ? moment.tz(acctPerf[0].time, this.appService.TZ) : null;

	if (landingPageData.BenchmarkPerformances && landingPageData.BenchmarkPerformances.Benchmark
            && landingPageData.BenchmarkPerformances.Benchmark.Index) {
          this.benchmarkPerformance = {
	    name: landingPageData.BenchmarkPerformances.Benchmark.Index.Name,
	    performance: this.getPeriodValue(landingPageData.BenchmarkPerformances.ToDatePerformances),
	    date: updateTime,
          };
	} else {
	  this.benchmarkPerformance = null;
	}
        if (updateTime.isValid()) {
          this.appService.updateTime = updateTime.toDate();
          this.appService.updateTimeFormat = 'DD-MMM-YYYY';
        } else {
          this.appService.updateTime = null;
        }

        if (landingPageData.IndexPerformances !== null && landingPageData.IndexPerformances.length) {
          const idxPerf = landingPageData.IndexPerformances.map(idxOverview => {
            try {
              const value = this.getPeriodValue(idxOverview.ToDatePerformances);
              return new AccountPerformance(idxOverview.Index.IndexId.toString(), idxOverview.Index.Name, value);
            } catch (error) {
              if (error instanceof TypeError) {
                console.warn('Skipping empty index:', error);
                return;
              }
            }
          });
          acctPerf = acctPerf.concat(idxPerf);
        }
        acctPerf = acctPerf.filter(perf => perf != null);
        acctPerf.sort((a, b) => cmpAcctPerfs(a, b) );
        this.allAcctPerfs = acctPerf.slice();

        this.accountDataService.loadAcctIdxs().subscribe(
          // Select and order acctPerf according to settings
          (aiData) => {
            const acctIdxs = aiData[0] as AcctIdx[];
            if (acctIdxs != null) {
              const newAcctPerf: AccountPerformance[] = [];
              acctIdxs.sort(cmpAcctIdxs);
              for (const ai of acctIdxs) {
                const found = acctPerf.find(ap => ap.accountId === ai.Id);
                if (found) {
                  newAcctPerf.push(found);
                }
              }
              acctPerf = newAcctPerf;
              if (acctPerf.length === 0) {
                this.showHiddenAcctsHelp = true;
              }
            }
          },
          (err) => this.notificationsService.warn('Could not load home page settings', 'Using default portfolio selection.'),
          // Update tables. Will use original acctPerf if settings errors out.
          () => {
            setTimeout(() => {
              this.updateDailyTables(acctPerf, accountIndexDecriptions);
              this.spinnerService.hide('dashboard-portfolios');
            });
          }
        );
      }, err => {
        this.logger.error('Cannot load LandingPageDaily data', err);
        this.notificationsService.error('Error', 'Error while downloading data from the server. Try refreshing the page.');
        this.spinnerService.hide('dashboard-portfolios');
      });
  }

  updateDailyTables(
    acctPerfs: AccountPerformance[],
    accountIndexDecriptions: AccountIndexDescription[]
  ) {
    acctPerfs.forEach((acctPerf) => {
      const description = accountIndexDecriptions.find((d) => d.portfolio_id === acctPerf.accountId);
      let desc = null;
      if (description && 'portfolio_description' in description) {
        desc = description.portfolio_description;
      }
      acctPerf['desc'] = desc;
    });
    this.accountPerformances = acctPerfs;
    this.accountPerformanceMax = Math.max(
      acctPerfs.reduce((acc, item) => Math.max(acc, Math.abs(item.performance)), 0),
        this.benchmarkPerformance!=null ? this.benchmarkPerformance.performance as number : 0
    );
    this.accountTableColumns = [
      {
        name: 'accountName',
        label: 'Portfolios',
        type: 'text',
        sort: (list: AccountPerformance[], key, ascending, cmp) => {
          list.sort((a, b) => cmpAcctPerfs(a, b) * (ascending ? 1 : -1));
          return list;
        }
      } as DataTableColumn,
      this.accountIndexDescriptionColumn,
      { name: 'performance', label: 'Performance', type: 'percentbar', scale: this.performanceScale, },
    ];
    this.accountTableOptions = { id : 'accountId' };
  }

  changePeriod(period: DataPeriods, forceReload = false) {
    if (this.selectedPeriod == null) {
      forceReload = true;
    }
    this.selectedPeriod = period == null ? DataPeriods.Today : period;
    this.isPeriodDrawdownVisible = false;

    if (period === DataPeriods.Today) {
      this.showIntradayPrice(forceReload);
    } else {
      this.showDailyData(period, forceReload);
    }
  }

  openPortfolio(event) {
    if (event && Object.keys(event).length) {
      const id = Object.keys(event)[0];
      if (this.accountPerformances.find(item => item.accountId === id)) {
        this.router.navigate(['/portal/account/', id]);
      }
    }
  }

}
