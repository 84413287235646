import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from '@app/app-service.service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { NotificationType } from 'angular2-notifications';

const COUNTRY_TEXT = {
  'FINLAND': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority, only to and it is directed solely at persons who meet the requirements of
  professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU or at other persons reasonably believed to have a level of experience of and information on, as well as the ability to bear, the risks of investments similar to
  the level of professional investors (sophisticated investors).</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'SWEDEN': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority and notified for cross-border services in Sweden, only to and it is directed
  solely at persons who meet the requirements of professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'DENMARK': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority and notified for cross-border services in Denmark, only to and it is directed
  solely at persons who meet the requirements of professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'SWITZERLAND': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority, only to and it is directed solely at persons who meet the requirements of
  regulated qualified investors as set out in the Swiss Federal Act on Collective Investment Schemes.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors other than sophisticated investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'THE NETHERLANDS': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority and notified for cross-border services in the Netherlands, only to and it is
  directed solely at persons who meet the requirements of professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'UNITED KINGDOM': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority and notified for cross-border services in the U.K., only to and it is directed
  solely at persons who meet the requirements of professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>
  <p>Any person who has any doubt as to whether it fulfills the above criteria for an investor to whom the Clarity Platform may be lawfully directed to and accessed by should seek professional advice in the matter prior to proceeding to the platform.</p>`,
  'UNITED ARAB EMIRATES': `<p>The Clarity Platform is held available by Estlander & Partners Ltd, a limited liability company authorized and regulated in Finland by the Finnish Financial Supervisory Authority and notified for cross-border services in the United Arab Emirates, only to and it is directed solely at persons who meet the requirements of professional investors or eligible counterparties as set out in the MiFID II Directive 2014/65/EU.</p>
  <p>The Clarity Platform as well as the analysis tools made available through the Clarity Platform are not available to, directed to and must not be relied upon by persons who do not fall within the definition of the target group mentioned in the previous
  paragraph, including but not limited to retail investors.</p>`,
}

@Component({
  selector: 'app-first-time',
  templateUrl: './first-time.component.html',
  styleUrls: ['./first-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstTimeComponent implements OnInit {
  countries: string[];
  countrySpecific = COUNTRY_TEXT;
  acceptForm: FormGroup;
  selectedCountry: string;
  showMore = false;

  constructor(
    private _appService: AppService,
    private _authService: AuthenticationService,
    private _changeDetRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _router: Router,
  ) {
    this.countries = Object.keys(COUNTRY_TEXT);
    this.selectedCountry = this.countries[0];
  }

  acceptTerms(accept: boolean) {
    if (accept) {
      if (this.acceptForm.valid) {
        // save acceptance to user model
        this._authService.acceptTerms$().subscribe(
          (agreed) => {
            this._router.navigate(['/']);
            this._appService.showNotification('Welcome', 'Thank you for using our service.', NotificationType.Success);
          },
          (error) => this._appService.showError('Update failed', 'Could not complete agreement. Please try again.'),
        );
      } else {
        this._appService.showError('Check all boxes', 'Please check all the boxes to indicate your acceptance of all terms & conditions.');
      }
    } else {
      this._appService.showError('Acceptance required', 'You must accept the terms & conditions to use this application.');
    }
  }

  ngOnInit() {
    this.acceptForm = this._fb.group({
      confirmUserData: [false, Validators.requiredTrue],
      confirmPrivacyPolicy: [false, Validators.requiredTrue],
      confirmClarityTerms: [false, Validators.requiredTrue],
      confirmEPTerms: [false, Validators.requiredTrue],
    });
  }

  selectCountry(country: string) {
    this.selectedCountry = country;
    this._changeDetRef.markForCheck();
  }
}
