<div #dropdownMain="cdkOverlayOrigin" cdkOverlayOrigin class="dropdown control" [ngClass]="{ 'is-right': isRight, 'show': dropdownShown }">
  <div class="dropdown-trigger field">
    <button type="button" class="button is-primary selector" [ngClass]="{'is-loading': (isLoading$|async)}" aria-haspopup="true" 
      (click)="toggleDropDown()">
        <span class="button-text"> {{ (activeItem$|async) || ' ' }}</span>
        <span class="icon is-small">
          <i [class]="'fa ' + (dropdownShown ? 'fa-angle-up' : 'fa-angle-down')" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  <ng-template 
    #dropdownMenu
    (backdropClick)="toggleDropDown()"
    cdkConnectedOverlay
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="dropdownShown"
    [cdkConnectedOverlayOrigin]="dropdownMain"
    cdkConnectedOverlayPanelClass="dropdownOverlayPane"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayScrollStrategy]="scrollStrat"
    [cdkConnectedOverlayViewportMargin]="10"
  >
    <div *ngIf="items$|async as items" class="dropdown-menu" [ngClass]="{ 'show': dropdownShown }" role="menu">
      <div class="dropdown-content">
        <div *ngIf="items?.length > 0">
          <a *ngFor="let item of items"
            (click)="activate(item, $event)"
            class="dropdown-item"
            [ngClass]="{ 'active': (activeItem$ | async) === item }"
            role="button"
            >
            {{ item }}
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
