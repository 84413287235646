
<div style="position:relative;">
 <div style="overflow-x:auto">
   <data-table
     [items]="assetClassOrderBreakdownTableData"
     [columns]="assetClassOrderBreakdownTableColumns">
   </data-table>
 </div>
</div>

