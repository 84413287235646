import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class MustBeAuthenticatedGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  canActivate(): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      // Navigate to the login page with extras
      this.router.navigate(['login']);
      return false;
    }
  }

}
