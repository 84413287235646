import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Question, Quiz, QuizAnswer } from '../../models';
import { QuizService } from '../../services/quiz.service';

@Component({
  selector: 'app-kyc-sub-quiz',
  templateUrl: './kyc-sub-quiz.component.html',
  styleUrls: ['./kyc-sub-quiz.component.scss']
})
export class KycSubQuizComponent implements OnInit {
  @Input() quiz: Quiz;
  @Input() startCount: number = 3;
  @Output() answered = new EventEmitter<QuizAnswer[][]>();

  questionGroups: Question[][] = []

  constructor(
    private _quizSvc: QuizService,
  ) {
  }

  addGroup(count = 1) {
    for (let i = 0; i < count; i++) {
      const questionsClone: Question[] = [];
      for (const q of this.quiz.questions) {
        questionsClone.push(new Question(q));
      }
      this.questionGroups.push(questionsClone);
    }
  }

  ngOnInit(): void {
    this.addGroup(this.startCount);
  }

  onInput(question: Question, value: string) {
    const option = question.options[0];
    option.name = value;
  }

  onRadio(question: Question, event: MatRadioChange) {
    const options = question.options;
    options.forEach((opt) => {
      opt.selected = opt.id === event.value;
    });
  }

  onText(question: Question, text: string) {
    this.onInput(question, text);
  }


  submit() {
    const answerGroups = [];
    for (const group of this.questionGroups) {
      const answers = [] as QuizAnswer[];
      for (const question of group) {
        const answer = this._quizSvc.findAnswerInQuestion(question);
        answers.push({
          name: question.name,
          questionId: question.id,
          quizId: this.quiz.id,
          answer
        });
      }
      answerGroups.push(answers);
    }
    this.answered.emit(answerGroups);
  }

}
