<div class="modal" [class.is-active]="true">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="quiz">
            <h2 class="quiz-name">{{quiz.name}}</h2>
            <div *ngIf="quiz?.description" class="quiz-description" [innerHtml]="quiz.description"></div>
            <div class="questions">
                <ng-container *ngFor="let group of questionGroups; index as groupIndex">
                    <fieldset class="group">
                        <div class="group-index">#{{groupIndex + 1}}</div>
                        <ng-container
                            *ngFor="let question of group">
                            <ng-container [ngTemplateOutlet]="questionTemplate"
                            [ngTemplateOutletContext]="{question: question, quiz: quiz, groupIndex: groupIndex}"></ng-container>
                        </ng-container>
                    </fieldset>
                </ng-container>
            </div>
        </div>
        <div class="buttons">
            <button class="button" type="button" (click)="addGroup()"><span class="icon"><i class="fa fa-plus"></i></span></button>
            <button type="button" class="button" (click)="submit()">Done</button>
        </div>
    </div>
</div>


<ng-template #questionTemplate let-question="question" let-quiz="quiz" let-quizIndex="groupIndex">
    <div class="question">
        <ng-template [ngIf]="question.questionTypeId === 8">
            <!-- radio button list-->
            <h3 class="question-name">
                <label id="question-{{question.id}}-group-label-{{quizIndex}}" [innerHTML]="question.name"></label>
                <app-help-popup *ngIf="question && question.infoPopup" [centeredOn]="quizWrapper">
                <div [innerHtml]="question.infoPopup" class="question-info-popup"></div>
                </app-help-popup>
                <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
            </h3>
            <div class="fields">
                <mat-radio-group
                aria-labelled-by="question-{{question.id}}-group-label-{{quizIndex}}"
                (change)="onRadio(question, $event)"
                name="question-{{question?.id}}-group-{{quizIndex}}"
                >
                    <mat-radio-button
                    *ngFor="let option of question.options"
                    #radioBtn
                    id="radio-{{question?.id}}-{{option?.id}}-{{quizIndex}}"
                    class="field"
                    [checked]="option.selected"
                    [value]="option.id"
                    ><span class="wrap-mat-label">{{option?.name}}</span>
                </mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-template>
        <ng-template [ngIf]="question.questionTypeId === 2">
            <!-- text input field -->
            <div class="fields">
              <div class="field">
                <label class="label question-name" for="question-{{ question.id }}-input">
                  <span [innerHtml]="question.name"></span>
                  <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>
                </label>
                <input
                  class="input"
                  id="question-{{ question.id }}-input"
                  [required]="quiz.isRequired(question)"
                  type="text"
                  [(ngModel)]="question.options[0].name"
                  placeholder="{{ question.placeholdertext }}"
                  (change)="onText(question, question.options[0].name);" />
              </div>
            </div>
        </ng-template>
        <!--
        <div class="question-name">
            <label for="quiz-{{quizIndex}}-question-{{question.id}}-input">
                {{question?.name}}
            </label>
            <span *ngIf="quiz.isRequired(question)" class="required" title="You must answer this question"><i class="fa fa-asterisk"></i></span>            
        </div>
        <div class="field">
            <input #input class="input"
                id="quiz-{{quizIndex}}-question-{{question.id}}-input"
                type="text" (input)="onInput(question, input.value)">
        </div>
        -->
    </div>
</ng-template>
