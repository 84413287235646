<div style="position:relative;">
  <div
    #acStatusNavbar
    class="navbar-wrapper"
    [ngClass]="{ 
      'menu-mode-icons': (mainMenuMode$|async) === 'icons',
      'floating': navbarIsFloating(acStatusNavbar)
    }"
    [ngStyle]="navbarStyle$|async"
    stickybits
    [stickyOffset]="(overviewMenuHeightAdjusted$|async)"
  >
    <div class="navbar-inner-wrapper">
      <div class="navbar-content">
        <navbar-button 
          #riskBreakdownNavbar
          [initial]="0"
          [tabs]="breakdownCategories"
          (selectionChanged)="changeRiskBreakdownCategory($event)"
          class="flex">
        </navbar-button>
      </div>
    </div>
  </div>
  <spinner name="risk-breakdown">
    <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
  </spinner>
  <navbar-button class="is-pulled-right"
    [initial]="0"
    [tabs]="['All asset classes', 'All sectors']"
    (selectionChanged)="switchAssetClassesOrSectors($event.selected)"
  >
  </navbar-button>
  <h2 class="chart-title">Risk level and direction <span *ngIf="lastDateStr"> as of {{ lastDateStr }}</span>
    &nbsp;<app-help-popup>
      <p>Direction determined from sign of Net Exposure.</p>
      <p *ngIf="currentTab === 'Volatility'">Ex-ante volatility based on estimated volatility from end-of-day positions</p>
    </app-help-popup></h2>
  <div class="chart-container">
    <div #riskLastVsMonth id="riskLastVsMonth" class="chart-element"></div>
  </div>
  <h2 class="chart-title" *ngIf="lastDateStr">Risk level, long and short positions as of {{ lastDateStr }}</h2>
  <div class="chart-container">
    <div #riskLongVsShort id="riskLongVsShort" class="chart-element"></div>
  </div>

  <div style="overflow-x:auto">
    <data-table *ngIf="subAssetClassRiskBreakdownTableData.length == 0 && marketsRiskBreakdownTableData.length == 0"
      [breadcrumbs]="tableBreadcrumbs"
      [items]="assetClassRiskBreakdownTableData" 
      [selected]="_assetClassSelected" 
      [columns]="assetClassRiskBreakdownTableColumns" 
      [options]="assetClassRiskBreakdownTableOptions" 
      (breadcrumbSelected)="marketsTableBreadcrumbClicked($event)"
      (selectionChanged)="selectAssetClassFromTable('assetClass', $event, currentTab)">
    </data-table> 
    <data-table *ngIf="subAssetClassRiskBreakdownTableData?.length > 0 && marketsRiskBreakdownTableData?.length == 0" 
      [breadcrumbs]="tableBreadcrumbs"
      [items]="subAssetClassRiskBreakdownTableData" 
      [maxItems]="isMarketDataAvailable(riskBreakdownData) ? null : 0"
      [selected]="_subAssetClassSelected" 
      [columns]="subAssetClassRiskBreakdownTableColumns" 
      [options]="subAssetClassRiskBreakdownTableOptions" 
      (breadcrumbSelected)="marketsTableBreadcrumbClicked($event)"
      (selectionChanged)="selectAssetClassFromTable('subAssetClass', $event, currentTab)">
    </data-table> 
    <data-table *ngIf="marketsRiskBreakdownTableData?.length > 0"
      [breadcrumbs]="tableBreadcrumbs"
      [columns]="marketsRiskBreakdownTableColumns" 
      [items]="marketsRiskBreakdownTableData" 
      [maxItems]="1"
      [options]="subAssetClassRiskBreakdownTableOptions" 
      [selected]="_marketsSelected" 
      (breadcrumbSelected)="marketsTableBreadcrumbClicked($event)"
      (selectionChanged)="selectAssetClassFromTable('market', $event, currentTab)" 
      >
    </data-table> 
  </div>

  
</div>