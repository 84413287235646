export class QuizConfig {
    allowBack: boolean;
    allowReview: boolean;
    autoMove: boolean;  // if boolean; it will move to next question automatically when answered.
    duration: number;  // indicates the time in which quiz needs to be completed. 0 means unlimited.
    pageSize: number;

    /** Explicit pagination if set to a list of question ids per page. If non-empty only questions listed here will be shown. */
    pages?: number[][]; 

    requiredAll: boolean;  // indicates if you must answer all the questions before submitting.

    /** If this quiz is used as a subquiz it will be repeated this many times in the popup */
    repeat?: number;

    /** Show text is HTML formatted. */
    richText: boolean;

    /** For single-question quiz. Hides quiz name and description. */
    simplifiedDisplay?: boolean;
    shuffleQuestions: boolean;
    shuffleOptions: boolean;
    showClock: boolean;
    showPager: boolean;
    theme: string;

    constructor(data: Partial<QuizConfig>) {
        data = data || {};
        this.allowBack = data.allowBack;
        this.allowReview = data.allowReview;
        this.autoMove = data.autoMove;
        this.duration = data.duration;
        this.pageSize = data.pageSize;
        this.pages = data.pages;
        this.requiredAll = data.requiredAll;
        this.repeat = data.repeat;
        this.richText = data.richText;
        this.simplifiedDisplay = data.simplifiedDisplay;
        this.shuffleQuestions = data.shuffleQuestions;
        this.shuffleOptions = data.shuffleOptions;
        this.showClock = data.showClock;
        this.showPager = data.showPager;
        this.theme = data.theme;
    }
}
