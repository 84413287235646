import { Option } from './option';

export class Question {
    id: number;

    /** A description explaining the question if the name isn't clear enough. */
    description: string;

    /** Contents of an info popup opened by clicking a (i) icon.
     * HTML formatted.
     */
    infoPopup?: string;
    name: string;
    nextQuiz?: string;
    options: Option[];
    questionTypeId: number;
    placeholdertext:string;
    selected: number;

    constructor(data: Partial<Question>) {
        data = data || {};
        this.id = data.id;
        this.description = data.description;
        this.infoPopup = data.infoPopup;
        this.name = data.name;
        this.nextQuiz = data.nextQuiz;
        this.placeholdertext = data.placeholdertext;
        this.questionTypeId = data.questionTypeId;
        this.options = [];
        data.options.forEach(o => {
            this.options.push(new Option(o));
        });
        this.selected = data.selected;
    }

    selectedOption() {
        return this.options.find(opt => opt.selected);
    }
}
