import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/auth/authentication.service';
import { map, take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { HttpClientService } from '../../http-client.service';
import { HttpHeaders } from '@angular/common/http';
import { LoggingMonitor } from './logging-monitor';

function fmtLogMsg(userId: number, message: string) {
  return `[${userId}] ${message}`;
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private auth: AuthenticationService,
    private httpClient: HttpClientService,
    private logger: NGXLogger
  ) {
    this.httpClient.getCsrfToken().subscribe(
      csrfToken => this.logger.setCustomHttpHeaders(new HttpHeaders({'X-CSRFToken': csrfToken}))
    );
    this.logger.registerMonitor(new LoggingMonitor(auth, httpClient));
  }

  debug(message, ...args) {
    this.logger.debug(message, [...args]);
  }

  error(message, ...args) {
    this.logger.error(message, [...args]);
  }

  fatal(message, ...args) {
    this.logger.fatal(message, [...args]);
  }

  info(message, ...args) {
    this.logger.info(message, [...args]);
  }

  log(message, ...args) {
    this.logger.log(message, [...args]);
  }

  warn(message, ...args) {
    this.logger.warn(message, [...args]);
  }

  private getUserId$() {
    return this.auth.getCurrentUser().pipe(take(1), map(user => user.userId));
  }
}
