<div class="performance-overview-account-name">
    <div class="navigation-bar">
        <div class="shortcut-buttons field has-addons" *ngIf="{
          'perfRiskUrl': performanceAndRiskUrl$|async,
          'selectedSection': selectedSection$|async
        } as shortcutVars">
            <div class="control">
                <a [href]="shortcutVars?.perfRiskUrl + '#overview'" class="button"
                [class.is-primary]="shortcutVars?.selectedSection === 'overview'" (click)="selectSection('overview')">Overview</a>
            </div>
            <div class="control">
                <a [href]="shortcutVars?.perfRiskUrl + '#performance'" class="button"
                [class.is-primary]="shortcutVars?.selectedSection === 'performance'" (click)="selectSection('performance')">Performance</a>
            </div>
            <div class="control">
                <a [href]="shortcutVars?.perfRiskUrl + '#risk'" class="button"
                [class.is-primary]="shortcutVars?.selectedSection === 'risk'" (click)="selectSection('risk')">Risk</a>
            </div>
            <div *ngIf="showIntradayOrders$|async" class="control">
                <a [href]="shortcutVars?.perfRiskUrl + '#orders'" class="button"
                [class.is-primary]="shortcutVars?.selectedSection === 'orders'" (click)="selectSection('orders')">Orders</a>
            </div>
            <div *ngIf="showDocuments$|async"class="control">
                <a [href]="shortcutVars?.perfRiskUrl + '#documents'" class="button"
                [class.is-primary]="shortcutVars?.selectedSection === 'documents'" (click)="selectSection('documents')">Resources & documents</a>
            </div>
        </div>
        <app-epp-basic-dropdown
            class="is-fullwidth-touch slim"
            [items$]="acctIdxs$"
            [activeItem$]="activeAcctIdx$"
            [isLoading$]="isLoading$"
            [isRight]="true"
            (activateItem)="changeAcctIdx($event.Id)"
            >
        </app-epp-basic-dropdown>
    </div>
</div>
<nav
    class="navbar"
    *ngIf="!(isNavbarVisible$|async)"
    [ngClass]="{'scrolled-up': isNavbarScrolledUp|async}">
    <div class="navbar-burger burger is-hidden-tablet is-pulled-right" data-target="navMenubdExample" (click)="toggleSidebar()">
        <span></span>
        <span></span>
        <span></span>
    </div>
</nav>
