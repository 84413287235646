import { Component } from '@angular/core';
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Memoize } from 'lodash-decorators';
import { Observable } from 'rxjs/Observable';
import { AppService } from '../../app-service.service';
import { AuthenticationService, User } from '../../auth/authentication.service';
import { MainMenuItem } from '../../main-menu-item';
import { MenuService } from '../../menu.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

interface MainMenuItemClasses {
  'navbar-highlight': boolean;
  'sub-items-available': boolean;
  'sub-item-highlighted': boolean;
  'sub-items-collapsed': boolean;
}

@Component({
  selector: 'app-portal-root',
  templateUrl: './portal-root.component.html',
  styleUrls: ['./portal-root.component.scss'],
})
export class PortalRootComponent implements OnInit, OnDestroy {
  public appVersion: string;
  public currentYear = new Date().getFullYear();
  public floatingDisclaimerClosed$: Observable<boolean>;
  public menuItemClasses$: Observable<Map<string, Object>>;
  public mainMenuItems$: Observable<MainMenuItem[]>;
  public menuMode$: Observable<'icons'|'labels'>;
  public subMenuCollapsed: Map<string, boolean>;
  public user$: Observable<User>;

  public get showDisclaimer() {
    return this.menuService.showDisclaimer();
  }

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    public appService: AppService,
    private menuService: MenuService,
    private _cookies: CookieService,
  ) {
    this.appVersion = appService.appVersion;
    this.floatingDisclaimerClosed$ = this.appService.floatingDisclaimerClosed$;
    this.mainMenuItems$ = this.menuService.mainMenuItems$;
    this.menuItemClasses$ = this.mainMenuItems$.pipe(
      map((items) => {
        const classMap = new Map<string, object>();
        for (const it of items) {
          classMap.set(it.url + it.fragment, this.itemClasses(it));
        }
        return classMap;
      })
    );
    this.menuMode$ = this.appService.menuMode$;
    this.subMenuCollapsed = new Map<string, boolean>();
    this.user$ = this.authenticationService.getCurrentUser();
  }

  /** remove floating disclaimer */
  closeFloatingDisclaimer() {
    this.appService.closeFloatingDisclaimer();
  }

  collapseIcon(item) {
    return {
      'fa-angle-double-down': this.subMenuCollapsed.get(item.url),
      'fa-angle-double-up': !this.subMenuCollapsed.get(item.url),
    };
  }

  isSubMenuItemHighlighted(item) {
    const subMenuItems = (item['subMenuItems'] || []);
    if (subMenuItems.length > 0) {
      if (subMenuItems.find(subItem => subItem['highlighted'])) {
        return true;
      }
    }
    return false;
  }

  // @Memoize((item) => item.url + '#' + item.fragment)
  itemClasses(item: MainMenuItem) {
    return {
      'navbar-highlight': item.highlighted && !this.isSubMenuItemHighlighted(item),
      'sub-items-available': item['subMenuItems'] && item.subMenuItems.length > 0,
      'sub-item-highlighted': this.isSubMenuItemHighlighted(item),
      'sub-items-collapsed': this.subMenuCollapsed.get(item.url),
    };
  }

  logout() {
    return this.authenticationService.logout();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // nothing
  }

  scrollToTop(event) {
    window.scrollTo(0, 0);
  }

  setMenuMode(val: 'icons' | 'labels') {
    this.appService.menuMode = val;
  }

  toggleSubMenuCollapsed(url: string, $event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    const value = this.subMenuCollapsed.has(url) ? this.subMenuCollapsed.get(url) : false;
    this.subMenuCollapsed.set(url, !value);
  }

}
