<ng-container *ngIf="tradingLevel as tl; then tlBox; else overviewBox"></ng-container>
<ng-template let-tl #tlBox>
  <h3 class="box-title">Account NAV <app-help-popup>Account NAV estimated from last official NAV adjusted with performance, subscriptions and redemptions.</app-help-popup></h3>
  <div class="box-body">
    <span>{{tl.TL | currency:tl.Currency:currencySymbol(tl.Currency):'1.0-0'}}</span>
  </div>
</ng-template>
<ng-template #overviewBox>
  <h3 class="box-title">&nbsp;</h3>
  <div class="box-body">&nbsp;</div>
</ng-template>
  
