import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AccountOverviewService } from '@app/account-overview/services/account-overview.service';
import { NavbarButtonService } from '@app/shared/navbar-button/navbar-button.service';
import { SpinnerService } from '@chevtek/angular-spinners';
import { checkboxLabelFormatter, deleteLegendSymbols } from 'app/account-overview/utils/charts';
import { sigFigs, TL_tooltipPointFormatter } from 'app/shared/utils';
import * as Highcharts from 'highcharts';
import * as lodash from 'lodash';
import { debounce } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AccountDataService } from '../account-data/account-data.service';
import { cmpAssetClasses, cmpSubAssetClassNames, sortSubAssetClassColumn } from '../account-data/asset-class-sorting';
import {
  AccountTradingLevel,
  AssetClass,
  AssetClassExposures,
  AssetClassVolatilityDetails,
  Product,
  ProductExposure,
  SubAssetClass,
  SubAssetClassExposures
} from '../api/webservice.service';
import { AppService } from '../app-service.service';
import naturalSort from '../natural-sort';
import { DataTableColumn } from '../shared/data-table/data-table-models';
import { NavbarButtonComponent } from '../shared/navbar-button/navbar-button.component';
import { LoggingService } from '../utils/logging/logging.service';
import { RiskBreakdownData } from './risk-breakdown-data';


interface AssetClassDirections {
    latest: {
        AssetClassId: number;
        Direction: number;
    }[];
    lastMonthEnd: {
        AssetClassId: number;
        Direction: number;
    }[];
}

function cmpValue(a: any, b: any): number {
  const x = a['value'] === undefined ? a : a.value;
  const y = b['value'] === undefined ? b : b.value;
  return naturalSort(x, y);
}

function isSeriesColumnOptions(object: any): object is Highcharts.SeriesOptions {
  return 'data' in object;
}

function lsForDir(dir: number) {
  if (dir < 0) {
    return 'S';
  } else if (dir === 0) {
    return '';
  } else {
    return 'L';
  }
}

enum TableClass { AssetClass = 'assetClass', SubAssetClass = 'subAssetClass', Market = 'market' }
enum RiskType { Volatility = 'Volatility', ValueAtRisk = 'VaR', Margin = 'Margin' }

interface DataTableSelection {
  [n: number]: boolean | 'on';
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'risk-breakdown-component',
  templateUrl: './risk-breakdown.component.html',
  styleUrls: ['./risk-breakdown.component.scss']
})
export class RiskBreakdownComponent implements OnInit, OnDestroy {

  @Input() selectedDate: Date;
  @Input() accountId: number | string;
  @Input() userId: number;
  @Output() selectedMarket = new EventEmitter<number>();

  @ViewChild('riskLastVsMonth', { static: true }) riskLastVsMonth: ElementRef;
  @ViewChild('riskLongVsShort', { static: true }) riskLongVsShort: ElementRef;
  @ViewChild('acStatusNavbar', { static: true }) navbarWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('riskBreakdownNavbar', { static: true }) navbar: NavbarButtonComponent;

  // latest
  assetClassExposure: Map<number, number>; // AssetClassId -> Net exposure
  subAssetClassExposure: Map<string, number>; // SubAssetClass.Name -> Net exposure
  productExposure: Map<number, number>;
  // last month end
  assetClassExposureLM: Map<number, number>; // AssetClassId -> Net exposure
  subAssetClassExposureLM: Map<string, number>; // SubAssetClass.Name -> Net exposure
  productExposureLM: Map<number, number>;

  assetClassColumn = {
    name: 'assetClassName',
    label: 'All asset classes',
    type: 'text',
    cmp: function (a: any, b: any): number { return cmpAssetClasses(a, b, null); },
  };
  assetClassRiskBreakdownTableData: any = [];
  assetClassRiskBreakdownTableColumns: DataTableColumn[] = [];
  _defaultBreakdownCategories = [ RiskType.Volatility, RiskType.ValueAtRisk, RiskType.Margin, ];
  breakdownCategories = this._defaultBreakdownCategories.slice();
  chartRegistry = new Map<string, Highcharts.Chart>();
  currentTab: RiskType = RiskType.Volatility;
  currentBreakdownClass: 'asset classes' | 'sectors' = 'asset classes';
  lastDate: moment.Moment;
  lastMonthDate: moment.Moment;
  lastDateStr: string;
  lastMonthDateStr: string;
  mainMenuMode$: Observable<'icons'|'labels'>;
 
  navbarIsFloating$: Observable<boolean>;
  navbarStyle$: Observable<object>;
  overviewMenuHeightAdjusted$: Observable<number>;
  subAssetColumn = { name: 'subAssetClassName', label: 'Asset Class Breakdown', type: 'text', cmp: cmpSubAssetClassNames };
  subAssetClassRiskBreakdownTableData = [];
  subAssetClassRiskBreakdownTableColumns = [];
  marketColumn = { name: 'market', label: 'Market', type: 'text' };
  marketsRiskBreakdownTableData = [];
  marketsRiskBreakdownTableColumns = [];
  assetClassRiskBreakdownTableOptions = [];
  readonly scaleFactor = 1.5;
  subAssetClassRiskBreakdownTableOptions = {
    sort: {
      subAssetClassName: sortSubAssetClassColumn,
    }
  };
  tableBreadcrumbs = [];

  isAnyMarketsSelected = false;
  chartDateFormat = 'DD-MM-YYYY';
  textDateFormat = 'DD-MMM-YYYY';
  lineColors = ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'];
  category = [];
  seriesOne: Highcharts.SeriesColumnOptions | Highcharts.Point[] = [];
  seriesTwo: Highcharts.SeriesColumnOptions | Highcharts.Point[] = [];
  nameOne: string;
  nameTwo: string;
  readySubject = new ReplaySubject<boolean>(1);

  _assetClassDirections: AssetClassDirections;
  _assetClassSelected: DataTableSelection = {};
  _subAssetClassSelected: DataTableSelection = {};
  _marketsSelected: DataTableSelection = {};

  riskBreakdownData: RiskBreakdownData;
  percentbarOptions = {
    direction: 'positive',
    positiveColor: '#009c90',
  };

  private _destroySubject: Subject<void>;
  private _tradingLevel: AccountTradingLevel;

  public get TZ() {
    return this._appSvc.TZ;
  }

  private subscription: Subscription;

  constructor(
    private _acctDataSvc: AccountDataService,
    private _acctOverviewSvc: AccountOverviewService,
    private _appSvc: AppService,
    private _changeDet: ChangeDetectorRef,
    private _logger: LoggingService,
    private _navbarSvc: NavbarButtonService,
    private _spinnerService: SpinnerService,
  ) {
    this.mainMenuMode$ = this._appSvc.menuMode$;
  }

  /**
   * Determine if navbar specified by ref is floating
   */
  readonly navbarIsFloating = debounce(function(ref: HTMLDivElement) {
    return ref != null && ref.offsetTop > 0;
  }, 0);
 

  buildNetExposureTables(
    exposureTime: 'latest' | 'lastMonthEnd',
    acExposures: AssetClassExposures[],
    subAcExposures: SubAssetClassExposures[],
    prodExposures: ProductExposure[]
  ) {
    const acMap = new Map<number, number>();
    const subMap = new Map<string, number>();
    const prodMap = new Map<number, number>();
    if (acExposures != null) {
      for (const ac of acExposures) {
        acMap.set(ac.AssetClass.AssetClassId, ac.NetExposure);
      }
    }
    if (subAcExposures != null) {
      for (const sub of subAcExposures) {
        subMap.set(sub.SubAssetClass.Name, sub.NetExposure);
      }
    }
    if (prodExposures != null) {
      for (const prodExp of prodExposures) {
        prodMap.set(prodExp.Product.ProductId, prodExp.NetExposure);
      }
    }
    if (exposureTime === 'latest') {
      this.assetClassExposure = acMap;
      this.subAssetClassExposure = subMap;
      this.productExposure = prodMap;
    } else {
      this.assetClassExposureLM = acMap;
      this.subAssetClassExposureLM = subMap;
      this.productExposureLM = prodMap;
    }
  }

  getTradingLevelFactors(): [string, number, string] {
    let currencySymbol: string;
    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this._tradingLevel != null) {
      switch (this._tradingLevel.Currency) {
        case 'EUR':
          currencySymbol = '€';
          break;
        case 'USD':
          currencySymbol = '$';
          break;
        default:
          currencySymbol = this._tradingLevel.Currency;
      }

      if (this._tradingLevel.TL > 1000000) {
        scalingFactor = 1 / 1e6;
        magnitudeSymbol = 'M';
      } else if (this._tradingLevel.TL > 1000) {
        scalingFactor = 1 / 1e3;
        magnitudeSymbol = 'K';
      } else {
        scalingFactor = 1;
        magnitudeSymbol = '';
      }
    }
    return [magnitudeSymbol, scalingFactor, currencySymbol];
  }

  loadLastMonthExposures$() {
    return this._acctDataSvc.getAllExposures$(
      this.userId, this.accountId, this.lastMonthDate.toISOString(true)
    ).pipe(
      tap(([acExps, subExps, prodExps]) => {
        this.buildNetExposureTables('lastMonthEnd', acExps, subExps, prodExps);
      }),
    );
  }

  dirForAssetClass(expTime: 'latest' | 'lastMonthEnd', ac: AssetClass) {
    let dir = 0;
    const exposures = expTime === 'latest' ? this.assetClassExposure : this.assetClassExposureLM;
    if (exposures.get(ac.AssetClassId) > 0) {
      dir = 1;
    }
    if (exposures.get(ac.AssetClassId) < 0) {
      dir = -1;
    }
    return dir;
  }

  lsForAssetClass(expTime: 'latest' | 'lastMonthEnd', ac: AssetClass) {
    return lsForDir(this.dirForAssetClass(expTime, ac));
  }

  dirForSubAssetClass(expTime: 'latest' | 'lastMonthEnd', subAc: SubAssetClass) {
    let dir = 0;
    const exposures = expTime === 'latest' ? this.subAssetClassExposure : this.subAssetClassExposureLM;
    if (exposures.get(subAc.Name) > 0) {
      dir = 1;
    }
    if (exposures.get(subAc.Name) < 0) {
      dir = -1;
    }
    return dir;
  }
  lsForSubAssetClass(expTime: 'latest' | 'lastMonthEnd', subAc: SubAssetClass) {
    return lsForDir(this.dirForSubAssetClass(expTime, subAc));
  }

  dirForProduct(expTime: 'latest' | 'lastMonthEnd', prod: Product) {
    let dir = 0;
    const exposures = expTime === 'latest' ? this.productExposure : this.productExposureLM;
    if (exposures.get(prod.ProductId) > 0) {
      dir = 1;
    }
    if (exposures.get(prod.ProductId) < 0) {
      dir = -1;
    }
    return dir;
  }

  lsForProduct(expTime: 'latest' | 'lastMonthEnd', prod: Product) {
    return lsForDir(this.dirForProduct(expTime, prod));
  }

  ngOnInit() {
    this.riskBreakdownData = null;
    this._destroySubject = new Subject<void>();
    this.assetClassExposure = new Map<number, number>();
    this.subAssetClassExposure = new Map<string, number>();
    this.subscription = this._acctOverviewSvc.riskBreakdown$.pipe(
      takeUntil(this._destroySubject),
    ).subscribe(
      (res) => {
        this.riskBreakdownData = res;
        this.lastDate = moment.tz(res.RiskOverview.Date, this.TZ);
        this.lastMonthDate = moment.tz(res.RiskOverview.LastMonthEndRiskInfo.Date, this.TZ);
        this.lastDateStr = this.lastDate.format(this.textDateFormat);
        this.lastMonthDateStr = this.lastMonthDate.format(this.textDateFormat);
        this.readySubject.next(true);
        setTimeout(() => {
          // delay to allow values to settle
          this.buildNetExposureTables('latest', res.AssetClassExposures, res.SubAssetClassExposures, res.ProductExposures);
          setTimeout(() => this.changeRiskBreakdownCategory({selected: this.currentTab}));
          this._spinnerService.hide('risk-breakdown');
        });
      },
      (err) => this._logger.error('Cannot load risk breakdown data', err),
      () => this._spinnerService.hide('risk-breakdown')
    );
    this._acctDataSvc.tradingLevel$.pipe(
      takeUntil(this._destroySubject),
    ).subscribe(
      tl => {
        if (tl != null && tl.AccountId === this.accountId) {
          this._tradingLevel = tl;
        }
      }
    );

    this.overviewMenuHeightAdjusted$ = this._acctOverviewSvc.overviewMenuHeight$.pipe(
      map((height) => height != null ? height + 14 : height),
      tap((height) => {
        setTimeout(() => this._changeDet.markForCheck());
      }),
    );
    this.navbarIsFloating$ = this._navbarSvc.isNavbarFloating$('riskBrkdwn', this.navbarWrapper.nativeElement);
    this.navbarStyle$ = this.overviewMenuHeightAdjusted$.pipe(
      map((height) => {
        return { 
          // top: 'calc(' + (height) + 'px + 0rem)',
        };
      }),
      // tap((styleObj) => console.table(styleObj)),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._destroySubject.next();
    this._destroySubject.complete();
    this._destroySubject = null;
    this._spinnerService.hide('risk-breakdown');
  }

  isMarketDataAvailable(riskBreakdownData: RiskBreakdownData): boolean {
    if (
      riskBreakdownData != null
      && riskBreakdownData.VolatilityOverview != null
      && riskBreakdownData.VolatilityOverview.ProductsVolatility != null
      ) {
      const flag = riskBreakdownData.VolatilityOverview.ProductsVolatility.length;
      return flag > 0;
    } else {
      return false;
    }
  }

  fixDate(date) {
    return date.setDate(date.getDate() + 1);
  }

  assetClassFromId(acId: number): AssetClass {
    return this.riskBreakdownData.MarginOverview.LastAssetClassesMarginRequirements.find(
      acMarginReq => acMarginReq.AssetClass.AssetClassId === acId
    ).AssetClass;
  }

  showTradingLevelBasedFigures(): boolean {
    return this._acctDataSvc.showTradingLevelBasedFigures(this.userId, this.accountId, this._tradingLevel);
  }

  subAssetClassFromName(subName: string): SubAssetClass {
    return this.riskBreakdownData.MarginOverview.SubAssetClassesMarginRequirements.find(
      subMarginReq => subMarginReq.SubAssetClass.Name === subName
    ).SubAssetClass;
  }

  selectAssetClassFromTable(table: TableClass, event: DataTableSelection, type: RiskType) {
    switch (table) {
      case TableClass.AssetClass: {
        this._assetClassSelected = event;
        let assName = this.assetClassFromId(+Object.keys(this._assetClassSelected)[0]).Name;
        assName = assName.toLowerCase();
        assName = assName[0].toUpperCase() + assName.slice(1);
        this.subAssetClassRiskBreakdownTableColumns[0].label = `${assName} sectors`;
      } break;
      case TableClass.SubAssetClass: {
        this._subAssetClassSelected = event;
        let subName = Object.keys(this._subAssetClassSelected).find(name => this._subAssetClassSelected[name] === 'on');
        subName = subName.toLowerCase();
        subName = subName[0].toUpperCase() + subName.slice(1);
        this.marketsRiskBreakdownTableColumns[0].label = `${subName} markets`;
      } break;
      case TableClass.Market: {
        this._marketsSelected = event;
        const prodId = +(Object.keys(this._marketsSelected).find(mId => this._marketsSelected[mId] === 'on'));
        if (prodId > 0) {
          this.selectedMarket.emit(prodId);
        }
      } break;
    }
    this.loadLastMonthExposures$().subscribe(() => {
      // we need directions to make the tables, so we wait until they are available
      this.regenTables(type);
      this.updateMarketTableBreadcrumbs();
    });
  }

  initDataForTable() {
    this.category = [];
    this.seriesOne = [];
    this.seriesTwo = [];

    this._assetClassSelected = {};
    this._subAssetClassSelected = {};
    this._marketsSelected = {};
  }

  selectMarginTab() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    const [monthChartId, longShortChartId] = this.wipeCharts();
    let maxY = 0;
    let minY = 0;


    const marginOverview = this.riskBreakdownData.MarginOverview;
    const marginOverviewlast = marginOverview.LastAssetClassesMarginRequirements;
    const marginOverviewmonth = marginOverview.LastMonthEndAssetClassesMarginRequirements;

    let y = 0;

    let series1opts: Highcharts.SeriesColumnOptions;
    let series2opts: Highcharts.SeriesColumnOptions;
    let series1data = [] as Highcharts.Point[];
    series1opts = {
      data: series1data,
      name: this.lastDate.format(this.textDateFormat),
      type: 'column',
      visible: true,
    };
    let series2data = [] as Highcharts.Point[];
    series2opts = {
      data: series2data,
      name: this.lastMonthDate.format(this.textDateFormat),
      type: 'column',
      visible: false,
    };

    let magnitudeSymbol: string;
    let scalingFactor: number;

    let tooltipPointFormatter: () => string;

    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      tooltipPointFormatter = TL_tooltipPointFormatter.bind({ magnitudeSymbol })();
      scalingFactor *= this._tradingLevel.TL;
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    } else {
      scalingFactor = 1;
    }

    if (this.currentBreakdownClass === 'asset classes') {
      // sort by asset class
      marginOverviewmonth.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      marginOverviewlast.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));

        for (const reqLast of marginOverviewlast) {
          this.category.push(reqLast.AssetClass.Name);
          y = reqLast.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor
            * this.dirForAssetClass('latest', reqLast.AssetClass);
          maxY = Math.max(y, maxY);
          minY = Math.min(y, minY);
          series1data.push({y, name: ''} as Highcharts.Point);
        }

        for (const reqMonth of marginOverviewmonth) {
          y = reqMonth.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor
            * this.dirForAssetClass('lastMonthEnd', reqMonth.AssetClass);
          maxY = Math.max(y, maxY);
          minY = Math.min(y, minY);
          series2data.push({y, name: ''} as Highcharts.Point);
        }
        this.createBarChart(
          monthChartId,
          this.category,
          series1opts,
          series2opts,
          null,
          null,
          'Margin requirement',
          this.yAxisSettings(maxY, minY),
        );
    } else {
      y = 0;

      this._acctDataSvc.getSubAssetClassMarginRequirement(
        this.userId, this.accountId, this.lastMonthDate.toISOString(true)
      ).subscribe(
        (lastMonthReqs) => {
          const noLastMonth = (lastMonthReqs == null || lastMonthReqs.length === 0);
          const categories = [];
          let sub1opts: Highcharts.SeriesColumnOptions;
          let sub2opts: Highcharts.SeriesColumnOptions;
          const sub1data = [];
          sub1opts = {
            data: sub1data,
            name: this.lastDate.format(this.textDateFormat),
            type: 'column',
            visible: true,
          };
          const sub2data = [];
          sub2opts = {
            data: sub2data,
            name: this.lastMonthDate.format(this.textDateFormat),
            type: 'column',
            visible: false,
          };
          let chartMaxY = 0;
          let chartMinY = 0;

          const subClassOverviewLatest = marginOverview.SubAssetClassesMarginRequirements;

          if (this.showTradingLevelBasedFigures()) {
            sub1opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
            sub2opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
          }

          // sort by asset class
          subClassOverviewLatest.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));
          for (const item of subClassOverviewLatest) {
            categories.push(item.SubAssetClass.Name);
            y = item.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor
              * this.dirForSubAssetClass('latest', item.SubAssetClass);
            chartMaxY = Math.max(y, chartMaxY);
            chartMinY = Math.min(y, chartMinY);
            sub1data.push({y, name: ''});
          }

          if (!noLastMonth) {
            // sort by asset class
            lastMonthReqs.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));
            for (const item of lastMonthReqs) {
              y = item.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor
                * this.dirForSubAssetClass('lastMonthEnd', item.SubAssetClass);
              chartMaxY = Math.max(Math.abs(y), chartMaxY);
              chartMinY = Math.min(y, chartMinY);
              sub2data.push({y, name: ''});
            }
          } else {
            sub2opts = null;
          }

          this.createBarChart(
            monthChartId,
            categories,
            sub1opts,
            sub2opts,
            null,
            null,
            'Margin requirement',
            this.yAxisSettings(chartMaxY, chartMinY),
          );
        }
      );

    }

    // **** Long v short chart

    series1data = [];
    series2data = [];
    series1opts = {
      data: series1data,
      name: 'Long positions',
      type: 'column',
      visible: true,
    };
    series2opts = {
      data: series2data,
      name: 'Short positions',
      type: 'column',
      visible: false,
    };
    maxY = 0;
    minY = 0;
    y = 0;

    if (this.showTradingLevelBasedFigures()) {
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    }

    this.initDataForTable();
    if (this.currentBreakdownClass === 'asset classes') {
      for (const reqLast of marginOverviewlast) {
        this.category.push(reqLast.AssetClass.Name);
        y = reqLast.MarginRequirement.MarginRequirementToTradingLevelLong * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y, name: ''} as Highcharts.Point);
        y = reqLast.MarginRequirement.MarginRequirementToTradingLevelShort * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y, name: ''} as Highcharts.Point);
      }
    } else {
      const subClassMarginOverviewLast = marginOverview.SubAssetClassesMarginRequirements;
      for (const subClassReq of subClassMarginOverviewLast) {
        this.category.push(subClassReq.SubAssetClass.Name);
        y = subClassReq.MarginRequirement.MarginRequirementToTradingLevelLong * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y: subClassReq.MarginRequirement.MarginRequirementToTradingLevelLong * scalingFactor, name: ''} as Highcharts.Point);
        y = subClassReq.MarginRequirement.MarginRequirementToTradingLevelShort * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y, name: ''} as Highcharts.Point);
      }
    }

    this.createBarChart(
      longShortChartId,
      this.category,
      series1opts,
      series2opts,
      null,
      null,
      'Margin requirement',
      this.yAxisSettings(maxY, minY)
    );
    this.generateMarginTable();
  }

  generateMarginTable() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    const marginOverview = this.riskBreakdownData.MarginOverview;

    let currencySymbol: string;
    let magnitudeSymbol: string;
    let scalingFactor: number;
    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor, currencySymbol] = this.getTradingLevelFactors();
      scalingFactor *= this._tradingLevel.TL;
    } else {
      scalingFactor = 1;
    }


    // Asset Classes
    this.assetClassRiskBreakdownTableData = marginOverview.LastAssetClassesMarginRequirements.map(item => {
      return {
        id: item.AssetClass.AssetClassId,
        assetClassName: item.AssetClass.Name,
        LS:  this.lsForAssetClass('latest', item.AssetClass),
        margin: {
          value: item.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor,
          options: this.percentbarOptions,
        },
        marginLong: item.MarginRequirement.MarginRequirementToTradingLevelLong * scalingFactor,
        marginShort: item.MarginRequirement.MarginRequirementToTradingLevelShort * scalingFactor,
        magnitudeSymbol,
        currencySymbol,
      };
    });
    this.assetClassRiskBreakdownTableData.sort((a, b) => cmpAssetClasses(a, b, 'assetClassName'));

    const maxMargin = this.assetClassRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.margin.value)), 0.0);
    const maxMarginLong = this.assetClassRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.marginLong)), 0.0);
    const maxMarginShort = this.assetClassRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.marginShort)), 0.0);

    const acCols: DataTableColumn[] = [
      this.assetClassColumn,
      {
        name: 'margin',
        label: 'Margin',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxMargin,
        cmp: cmpValue,
      },
    ];
    if (this.assetClassExposure.size > 0) {
      acCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.assetClassRiskBreakdownTableColumns = acCols;

    // Sub Asset Classes
    marginOverview.SubAssetClassesMarginRequirements.filter(item => {
      if (this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId]) {
        return false;
      }
      return true;
    }).map(item => {
      this._subAssetClassSelected[item.SubAssetClass.Name] = false;
    });

    this.subAssetClassRiskBreakdownTableData = marginOverview.SubAssetClassesMarginRequirements.filter(item => {
      return this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId] === 'on';
    }).map(item => {
      return {
        id: item.SubAssetClass.Name,
        assetClassId: item.SubAssetClass.AssetClass.AssetClassId,
        assetClassName: item.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.SubAssetClass.Name,
        LS: this.lsForSubAssetClass('latest', item.SubAssetClass),
        margin: {
          value: item.MarginRequirement.MarginRequirementToTradingLevel * scalingFactor,
          options: this.percentbarOptions,
        },
        marginLong: item.MarginRequirement.MarginRequirementToTradingLevelLong * scalingFactor,
        marginShort: item.MarginRequirement.MarginRequirementToTradingLevelShort * scalingFactor,
        magnitudeSymbol,
        currencySymbol,
      };
    });
    sortSubAssetClassColumn(this.subAssetClassRiskBreakdownTableData);
    const maxSubMargin = this.subAssetClassRiskBreakdownTableData.reduce((acc, item)  => Math.max(acc, Math.abs(item.margin.value)), 0.0);
    const maxSubMarginLong = this.subAssetClassRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.marginLong)), 0.0);
    const maxSubMarginShort = this.subAssetClassRiskBreakdownTableData.reduce(
      (acc, item) => Math.max(acc, Math.abs(item.marginShort)), 0.0
    );

    const subAcCols: DataTableColumn[] = [
      this.subAssetColumn,
      {
        name: 'margin',
        label: 'Margin',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxSubMargin,
        cmp: cmpValue,
      },
    ];
    if (this.subAssetClassExposure.size > 0) {
      subAcCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.subAssetClassRiskBreakdownTableColumns = subAcCols;

    // Products
    marginOverview.ProductsMarginRequirements.filter(item => {
      if (this._subAssetClassSelected[item.Product.SubAssetClass.Name]) {
        return false;
      }
      return true;
    }).map(item => {
      this._marketsSelected[item.Product.ProductId] = false;
    });

    this.marketsRiskBreakdownTableData = marginOverview.ProductsMarginRequirements.filter(item => {
      return this._subAssetClassSelected[item.Product.SubAssetClass.Name];
    }).map(item => {
      return {
        id: item.Product.ProductId,
        assetClassName: item.Product.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.Product.SubAssetClass.Name,
        market: item.Product.LongName,
        LS: this.lsForProduct('latest', item.Product),
        margin: {
          value: item.MarginRequirementToTradingLevel * scalingFactor,
          options: this.percentbarOptions,
        },
        magnitudeSymbol,
        currencySymbol,
      };
    });
    const maxProdMargin = this.marketsRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.margin.value)), 0.0);
    this.marketsRiskBreakdownTableColumns = [
      this.marketColumn,
      { name: 'LS', label: 'L/S', type: 'text' },
      {
        name: 'margin',
        label: 'Margin',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxProdMargin,
        cmp: cmpValue,
      },
    ];
    this._spinnerService.hide('risk-breakdown');
  }

  selectVaRTab() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    this.initDataForTable();
    const [monthChartId, longShortChartId] = this.wipeCharts();

    const varOverview = this.riskBreakdownData.VaROverview;

    let series1opts: Highcharts.SeriesColumnOptions;
    let series2opts: Highcharts.SeriesColumnOptions;
    let series1data = [];
    series1opts = {
      data: series1data,
      name: this.lastDate.format(this.textDateFormat),
      type: 'column',
      visible: true,
    };
    let series2data = [];
    series2opts = {
      data: series2data,
      name: this.lastMonthDate.format(this.textDateFormat),
      type: 'column',
      visible: false,
    };
    let maxY = 0;
    let minY = 0;

    let magnitudeSymbol: string;
    let scalingFactor: number;

    let tooltipPointFormatter: () => string;

    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      tooltipPointFormatter = TL_tooltipPointFormatter.bind({ magnitudeSymbol })();
      scalingFactor *= this._tradingLevel.TL;
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    } else {
      scalingFactor = 100;
    }

    let y = 0;
    if (this.currentBreakdownClass === 'asset classes') {
      // sort by asset class
      varOverview.LastAssetClassesVaR.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      varOverview.LastMonthEndAssetClassesVaR.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));

      for (const item of varOverview.LastAssetClassesVaR) {
        this.category.push(item.AssetClass.Name);
        y = item.VaRDetails.TotalVaR.VaR * scalingFactor
          * -this.dirForAssetClass('latest', item.AssetClass);
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y});
      }
      for (const item of varOverview.LastMonthEndAssetClassesVaR) {
        y = item.VaRDetails.TotalVaR.VaR * scalingFactor
          * -this.dirForAssetClass('lastMonthEnd', item.AssetClass);
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y});
      }
      this.createBarChart(
        monthChartId,
        this.category,
        series1opts,
        series2opts,
        null,
        null,
        'VaR',
        this.yAxisSettings(maxY, minY)
      );
    } else {
      y = 0;

      this._acctDataSvc.getSubAssetClassVaRDetails(
        this.userId, this.accountId, this.lastMonthDate.toISOString(true)
      ).subscribe(
        (lastMonthData) => {
          const noLastMonth = (lastMonthData == null || lastMonthData.length === 0);
          const categories = [];
          let sub1opts: Highcharts.SeriesColumnOptions;
          let sub2opts: Highcharts.SeriesColumnOptions;
          const sub1data = [];
          sub1opts = {
            data: sub1data,
            name: this.lastDate.format(this.textDateFormat),
            type: 'column',
            visible: true,
          };
          const sub2data = [];
          sub2opts = {
            data: sub2data,
            name: this.lastMonthDate.format(this.textDateFormat),
            type: 'column',
            visible: false,
          };
          let chartMaxY = 0;
          let chartMinY = 0;

          if (this.showTradingLevelBasedFigures()) {
            sub1opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
            sub2opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
          }

          const subClassOverviewLatest = varOverview.SubAssetClassesVaR;
          // sort by asset class
          subClassOverviewLatest.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));

          for (const latestItem of subClassOverviewLatest) {
            categories.push(latestItem.SubAssetClass.Name);
            y = latestItem.VaRDetails.TotalVaR.VaR * scalingFactor * -this.dirForSubAssetClass('latest', latestItem.SubAssetClass);
            chartMaxY = Math.max(y, chartMaxY);
            chartMinY = Math.min(y, chartMinY);
            sub1data.push({y});
          }

          if (!noLastMonth) {
            lastMonthData.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));

            for (const monthEndItem of lastMonthData) {
              y = monthEndItem.VaRDetails.TotalVaR.VaR * scalingFactor *
                -this.dirForSubAssetClass('lastMonthEnd', monthEndItem.SubAssetClass);
              chartMaxY = Math.max(y, chartMaxY);
              chartMinY = Math.min(y, chartMinY);
              sub2data.push({y});
            }
          } else {
            sub2opts = null;
          }

          this.createBarChart(
            monthChartId,
            categories,
            sub1opts,
            sub2opts,
            null,
            null,
            'VaR',
            this.yAxisSettings(chartMaxY, chartMinY)
          );

        }
      );
    }


    this.initDataForTable();

    series1data = [];
    series2data = [];
    this.category = [];
    series1opts = {
      name: 'Long positions',
      data: series1data,
      type: 'column',
    };
    series2opts = {
      name: 'Short positions',
      data: series2data,
      type: 'column',
    };

    y = 0;
    maxY = 0;
    minY = 0;

    if (this.showTradingLevelBasedFigures()) {
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    }


    if (this.currentBreakdownClass === 'asset classes') {
      for (const item of varOverview.LastAssetClassesVaR) {
        this.category.push(item.AssetClass.Name);
        y = item.VaRDetails.LongPositionVaR.VaR * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y});
        y = item.VaRDetails.ShortPositionVaR.VaR * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y});
      }
    } else {
      for (const item of varOverview.SubAssetClassesVaR) {
        this.category.push(item.SubAssetClass.Name);
        y = item.VaRDetails.LongPositionVaR.VaR * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y});
        y = item.VaRDetails.ShortPositionVaR.VaR * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y});
      }
    }

    this.createBarChart(
      longShortChartId, this.category, series1opts, series2opts, null, null, 'VaR',
      this.yAxisSettings(maxY, minY)
    );

    this.generateVaRTable();
  }

  generateVaRTable() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    let currencySymbol: string;
    let magnitudeSymbol: string;
    let scalingFactor: number;
    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor, currencySymbol] = this.getTradingLevelFactors();
      scalingFactor *= this._tradingLevel.TL;
    } else {
      scalingFactor = 1;
    }

    this.assetClassRiskBreakdownTableData = this.riskBreakdownData.VaROverview.LastAssetClassesVaR.map(item => {
      return {
        id: item.AssetClass.AssetClassId,
        assetClassName: item.AssetClass.Name,
        LS: this.lsForAssetClass('latest', item.AssetClass),
        VaR: {
          value: item.VaRDetails.TotalVaR.VaR * scalingFactor,
          options: this.percentbarOptions,
        },
        VaRLong: item.VaRDetails.LongPositionVaR.VaR * scalingFactor,
        VaRShort: item.VaRDetails.ShortPositionVaR.VaR * scalingFactor,
        magnitudeSymbol,
        currencySymbol,
      };
    });
    this.assetClassRiskBreakdownTableData.sort((a, b) => cmpAssetClasses(a, b, 'assetClassName'));

    const maxAssetClassVaR = this.assetClassRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.VaR.value)), 0.0);

    const acCols: DataTableColumn[] = [
      this.assetClassColumn,
      {
        name: 'VaR',
        label: 'VaR',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxAssetClassVaR,
        cmp: cmpValue,
      },
    ];
    if (this.assetClassExposure.size > 0) {
      acCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.assetClassRiskBreakdownTableColumns = acCols;


    this.riskBreakdownData.VaROverview.SubAssetClassesVaR.filter(
      item => this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId] !== 'on'
    ).map(item => { this._subAssetClassSelected[item.SubAssetClass.Name] = false; });

    this.subAssetClassRiskBreakdownTableData = this.riskBreakdownData.VaROverview.SubAssetClassesVaR.filter(
      item => this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId] === 'on'
    ).map(item => {
      return {
        id: item.SubAssetClass.Name,
        assetClassId: item.SubAssetClass.AssetClass.AssetClassId,
        assetClassName: item.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.SubAssetClass.Name,
        LS: lsForDir(-this.dirForSubAssetClass('latest', item.SubAssetClass)),
        VaR: {
          value: item.VaRDetails.TotalVaR.VaR * scalingFactor,
          options: this.percentbarOptions,
        },
        VaRLong: item.VaRDetails.LongPositionVaR.VaR * scalingFactor,
        VaRShort: item.VaRDetails.ShortPositionVaR.VaR * scalingFactor,
        magnitudeSymbol,
        currencySymbol,
      };
    });
    sortSubAssetClassColumn(this.subAssetClassRiskBreakdownTableData);

    const maxSubAssetClassVaR = this.subAssetClassRiskBreakdownTableData.reduce(
      (acc, item) => Math.max(acc, Math.abs(item.VaR.value)),
      0.0
    );
    const subAcCols: DataTableColumn[] = [
      this.subAssetColumn,
      {
        name: 'VaR',
        label: 'VaR',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxSubAssetClassVaR,
        cmp: cmpValue,
      },
    ];
    if (this.subAssetClassExposure.size > 0) {
      subAcCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.subAssetClassRiskBreakdownTableColumns = subAcCols;

    this.riskBreakdownData.VaROverview.ProductsVaR.filter(
      item => this._subAssetClassSelected[item.Product.SubAssetClass.Name] !== 'on'
    ).map( item => { this._marketsSelected[item.Product.ProductId] = false; } );

    this.marketsRiskBreakdownTableData = this.riskBreakdownData.VaROverview.ProductsVaR.filter(
      item => this._subAssetClassSelected[item.Product.SubAssetClass.Name] === 'on'
    ).map( item => {
      return {
        id: item.Product.ProductId,
        assetClassName: item.Product.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.Product.SubAssetClass.Name,
        LS: this.lsForProduct('latest', item.Product),
        market: item.Product.LongName,
        VaR: {
          value: item.ValueAtRisk.VaR * scalingFactor,
          options: this.percentbarOptions,
        },
        magnitudeSymbol,
        currencySymbol,
      };
    });
    const maxProductVaR = this.marketsRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.VaR.value)), 0.0);
    this.marketsRiskBreakdownTableColumns = [
      this.marketColumn,
      { name: 'LS', label: 'L/S', type: 'text' },
      {
        name: 'VaR',
        label: 'VaR',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxProductVaR,
        cmp: cmpValue,
      },
    ];

    this._spinnerService.hide('risk-breakdown');
  }

  selectVolatilityTab() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    const [monthChartId, longShortChartId] = this.wipeCharts();

    const volOverview = this.riskBreakdownData.VolatilityOverview;

    let series1opts: Highcharts.SeriesColumnOptions;
    let series2opts: Highcharts.SeriesColumnOptions;
    let series1data = [];
    series1opts = {
      data: series1data,
      name: this.lastDate.format(this.textDateFormat),
      type: 'column',
      visible: true,
    };
    let series2data = [];
    series2opts = {
      data: series2data,
      name: this.lastMonthDate.format(this.textDateFormat),
      type: 'column',
      visible: false,
    };
    let maxY = 0;
    let minY = 0;

    let y = 0;

    let magnitudeSymbol: string;
    let scalingFactor: number;

    let tooltipPointFormatter: () => string;

    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      tooltipPointFormatter = TL_tooltipPointFormatter.bind({ magnitudeSymbol })();
      scalingFactor *= this._tradingLevel.TL;
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    } else {
      scalingFactor = 100;
    }
    if (this.currentBreakdownClass === 'asset classes') {
      // sort by asset class
      volOverview.LastAssetClassesVolatility.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      volOverview.LastMonthEndAssetClassesVolatility.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      for (const item of volOverview.LastAssetClassesVolatility) {
        this.category.push(item.AssetClass.Name);
        if (isNaN(item.VolatilityDetails.Volatility)) {
          y = null;
        } else {
          y = item.VolatilityDetails.Volatility * scalingFactor
            * this.dirForAssetClass('latest', item.AssetClass);
          maxY = Math.max(y, maxY);
          minY = Math.min(y, minY);
        }
        series1data.push({y});
      }
      for (const item of volOverview.LastMonthEndAssetClassesVolatility) {
        if (isNaN(item.VolatilityDetails.Volatility)) {
          y = null;
        } else {
          y = item.VolatilityDetails.Volatility * scalingFactor
            * this.dirForAssetClass('lastMonthEnd', item.AssetClass);
          maxY = Math.max(y, maxY);
          minY = Math.min(y, minY);
        }
        series2data.push({y});
      }
      this.createBarChart(
        monthChartId,
        this.category,
        series1opts,
        series2opts,
        null,
        null,
        'Volatility',
        this.yAxisSettings(maxY, minY)
      );
      this.generateVolatilityTable();
    } else {
      const subClassOverviewLatest = volOverview.SubAssetClassesVolatility;

      // sort by asset class
      subClassOverviewLatest.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));

      this._acctDataSvc.getSubAssetClassExAnteVolatilityDetails(
        this.userId, this.accountId, this.lastMonthDate.toISOString(true)
      ).subscribe(
        (lastMonthData) => {
          const noLastMonth = (lastMonthData == null || lastMonthData.length === 0);
          let sub1opts: Highcharts.SeriesColumnOptions;
          let sub2opts: Highcharts.SeriesColumnOptions;
          const sub1data = [];
          sub1opts = {
            data: sub1data,
            name: this.lastDate.format(this.textDateFormat),
            type: 'column',
            visible: true,
          };
          const sub2data = [];
          sub2opts = {
            data: sub2data,
            name: this.lastMonthDate.format(this.textDateFormat),
            type: 'column',
            visible: false,
          };
          let chartMaxY = 0;
          let chartMinY = 0;

          if (this.showTradingLevelBasedFigures()) {
            sub1opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
            sub2opts.tooltip = {
              pointFormatter: tooltipPointFormatter,
            };
          }

          for (const item of subClassOverviewLatest) {
            this.category.push(item.SubAssetClass.Name);
            if (isNaN(item.VolatilityDetails.Volatility)) {
              y = null;
            } else {
                y = item.VolatilityDetails.Volatility * scalingFactor * this.dirForSubAssetClass('latest', item.SubAssetClass);
                chartMaxY = Math.max(y, chartMaxY);
                chartMinY = Math.min(y, chartMinY);
            }
            sub1data.push({y});
          }
          if (!noLastMonth) {
            lastMonthData.sort((a, b) => cmpAssetClasses(a.SubAssetClass.AssetClass, b.SubAssetClass.AssetClass, 'Name'));

            for (const item of lastMonthData) {
              y = item.VolatilityDetails.Volatility * scalingFactor * this.dirForSubAssetClass('lastMonthEnd', item.SubAssetClass);
              chartMaxY = Math.max(y, chartMaxY);
              chartMinY = Math.min(y, chartMinY);
              sub2data.push({y});
            }
          } else {
            sub2opts = null;
          }

          this.createBarChart(
            monthChartId,
            this.category,
            sub1opts,
            sub2opts,
            null,
            null,
            'Volatility',
            this.yAxisSettings(chartMaxY, chartMinY)
          );
          this.generateVolatilityTable();
        }
      );
    }


    this.initDataForTable();

    series1data = [];
    series2data = [];
    this.category = [];
    series1opts = {
      name: 'Long positions',
      data: series1data,
      type: 'column',
    };
    series2opts = {
      name: 'Short positions',
      data: series2data,
      type: 'column',
    };
    y = 0;
    maxY = 0;
    minY = 0;

    if (this.showTradingLevelBasedFigures()) {
      series1opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
      series2opts.tooltip = {
        pointFormatter: tooltipPointFormatter,
      };
    }

    if (this.currentBreakdownClass === 'asset classes') {
      for (const item of volOverview.LastAssetClassesVolatility) {
        this.category.push(item.AssetClass.Name);
        [y, maxY, minY] = this.scaleAndUpdateLimits(item.VolatilityDetails.VolatilityLongPositions, scalingFactor, maxY, minY);
        series1data.push({y});
        [y, maxY, minY] = this.scaleAndUpdateLimits(item.VolatilityDetails.VolatilityShortPositions, scalingFactor, maxY, minY);
        series2data.push({y});
      }
    } else {
      for (const item of volOverview.SubAssetClassesVolatility) {
        this.category.push(item.SubAssetClass.Name);
        y = item.VolatilityDetails.VolatilityLongPositions * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series1data.push({y});
        y = item.VolatilityDetails.VolatilityShortPositions * scalingFactor;
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
        series2data.push({y});
      }
    }

    this.createBarChart(
      longShortChartId, this.category, series1opts, series2opts, null, null, 'Volatility',
      this.yAxisSettings(maxY, minY)
    );
  }

  private scaleAndUpdateLimits(x: number, scalingFactor: number, max: number, min: number) {
    if (x != null && !isNaN(x)) {
      x = x * scalingFactor;
      max = Math.max(x, max);
      min = Math.min(x, min);
    }
    return [x, max, min];
  }

  generateVolatilityTable() {
    if (!this.riskBreakdownData) {
      this._spinnerService.hide('risk-breakdown');
      return;
    }

    let currencySymbol: string;
    let magnitudeSymbol: string;
    let scalingFactor: number;
    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor, currencySymbol] = this.getTradingLevelFactors();
      scalingFactor *= this._tradingLevel.TL;
    } else {
      scalingFactor = 1;
    }

    this.seriesOne = [];
    this.seriesTwo = [];

    this.assetClassRiskBreakdownTableData = this.riskBreakdownData.VolatilityOverview.LastAssetClassesVolatility.map(item => {
      return {
        id: item.AssetClass.AssetClassId,
        assetClassName: item.AssetClass.Name,
        LS: this.lsForAssetClass('latest', item.AssetClass),
        volatility: {
          value: item.VolatilityDetails.Volatility * scalingFactor,
          options: this.percentbarOptions,
        },
        volatilityLong: item.VolatilityDetails.VolatilityLongPositions * scalingFactor,
        volatilityShort: item.VolatilityDetails.VolatilityShortPositions * scalingFactor,
        currencySymbol,
        magnitudeSymbol,
      };
    });
    this.assetClassRiskBreakdownTableData.sort((a, b) => cmpAssetClasses(a, b, 'assetClassName'));

    const maxAssetClassVol = this.assetClassRiskBreakdownTableData.reduce(
      (acc, item) => Math.max(acc, Math.abs(item.volatility.value)),
      0.0
    );

    const acCols: DataTableColumn[] = [
      this.assetClassColumn,
      {
        name: 'volatility',
        label: 'Volatility',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxAssetClassVol,
        cmp: cmpValue,
      },
    ];
    if (this.assetClassExposure.size > 0) {
      acCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.assetClassRiskBreakdownTableColumns = acCols;

    this.riskBreakdownData.VolatilityOverview.SubAssetClassesVolatility.filter(item => {
      if (this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId]) {
        return false;
      }
      return true;
    }).map(item => {
      this._subAssetClassSelected[item.SubAssetClass.Name] = false;
    });

    this.subAssetClassRiskBreakdownTableData = this.riskBreakdownData.VolatilityOverview.SubAssetClassesVolatility.filter(item => {
      return this._assetClassSelected[item.SubAssetClass.AssetClass.AssetClassId];
    }).map(item => {
      return {
        id: item.SubAssetClass.Name,
        assetClassId: item.SubAssetClass.AssetClass.AssetClassId,
        assetClassName: item.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.SubAssetClass.Name,
        LS: this.lsForSubAssetClass('latest', item.SubAssetClass),
        volatility: {
          value: item.VolatilityDetails.Volatility * scalingFactor,
          options: this.percentbarOptions,
        },
        volatilityLong: item.VolatilityDetails.VolatilityLongPositions * scalingFactor,
        volatilityShort: item.VolatilityDetails.VolatilityShortPositions * scalingFactor,
        currencySymbol,
        magnitudeSymbol,
      };
    });

    sortSubAssetClassColumn(this.subAssetClassRiskBreakdownTableData);

    const maxSubClassVol = this.subAssetClassRiskBreakdownTableData.reduce(
      (acc, item) => Math.max(acc, Math.abs(item.volatility.value)),
      0.0
    );

    const subAcCols: DataTableColumn[] = [
      this.subAssetColumn,
      {
        name: 'volatility',
        label: 'Volatility',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxSubClassVol,
        cmp: cmpValue,
      },
    ];
    if (this.subAssetClassExposure.size > 0) {
      subAcCols.splice(1, 0, { name: 'LS', label: 'L/S', type: 'text' });
    }
    this.subAssetClassRiskBreakdownTableColumns = subAcCols;

    this.riskBreakdownData.VolatilityOverview.ProductsVolatility.filter(item => {
      if (this._subAssetClassSelected[item.Product.SubAssetClass.Name]) {
        return false;
      }
      return true;
    }).map(item => {
      this._marketsSelected[item.Product.ProductId] = false;
    });

    this.marketsRiskBreakdownTableData = this.riskBreakdownData.VolatilityOverview.ProductsVolatility.filter(item => {
      return this._subAssetClassSelected[item.Product.SubAssetClass.Name];
    }).map(item => {
      return {
        id: item.Product.ProductId,
        assetClassName: item.Product.SubAssetClass.AssetClass.Name,
        subAssetClassName: item.Product.SubAssetClass.Name,
        market: item.Product.LongName,
        LS: this.lsForProduct('latest', item.Product),
        volatility: {
          value: item.VolatilityPoint.Volatility * scalingFactor,
          options: this.percentbarOptions,
        },
        currencySymbol,
        magnitudeSymbol,
      };
    });
    const maxProdVol = this.marketsRiskBreakdownTableData.reduce((acc, item) => Math.max(acc, Math.abs(item.volatility.value)), 0.0);
    this.marketsRiskBreakdownTableColumns = [
      this.marketColumn,
      { name: 'LS', label: 'L/S', type: 'text' },
      {
        name: 'volatility',
        label: 'Volatility',
        type: magnitudeSymbol === undefined ? 'percentbar' : 'currencybar',
        scale: maxProdVol,
        cmp: cmpValue,
      },
      { name: 'id', label: 'Id', type: 'text' },
    ];
    this._spinnerService.hide('risk-breakdown');
  }


  updateMarketTableBreadcrumbs(): void {
    const breadcrumbs = [];

    // determine selected items
    const selectedAssetClassIds = Object.keys(
      this._assetClassSelected
    ).filter(
      x => !!this._assetClassSelected[x]
    ).map(strId => Number(strId));
    const selectedSectorIds = Object.keys(
      this._subAssetClassSelected
    ).filter(
      x => !!this._subAssetClassSelected[x]
    );

    // Add breadcrumbs
    breadcrumbs.push({ label: 'All asset classes', type: 'assetClassPerformance', id: 'all'});
    breadcrumbs.push({ label: 'All sectors', type: 'subAssetClassPerformance', id: 'all'});
    if (this.isMarketDataAvailable(this.riskBreakdownData)) {
      if (selectedAssetClassIds.length === 1 && this.assetClassRiskBreakdownTableData.length > 1
        && this.getSelectedSubAssetClassIds().length === 0) {
        // If one asset class selected add 'All <sector> markets' button
        const selectedClassId = selectedAssetClassIds[0];
        const selectedClassData = this.assetClassRiskBreakdownTableData.find(tData => tData.id === selectedClassId);
        if (selectedClassData !== undefined) {
          const selectedClassName = selectedClassData.assetClassName;
          breadcrumbs.push({ label: `All ${selectedClassName.toLowerCase()} markets`, type: 'assetClassMarkets', id: selectedClassId});
        }
      }
      if (breadcrumbs.length === 2) {
        // Add "All markets" button if no "All <sector> markets" button
        breadcrumbs.push({ label: 'All markets', type: 'marketPerformance', id: 'all'});
      }
    }

    if (selectedSectorIds.length > 0 && breadcrumbs.length > 2
      && this.isMarketDataAvailable(this.riskBreakdownData)) {
      if (selectedAssetClassIds.length === this.assetClassRiskBreakdownTableData.length
        && selectedSectorIds.length === this.subAssetClassRiskBreakdownTableData.length) {
        // "All markets" selected
        breadcrumbs[breadcrumbs.length - 1].selected = true;
      } /* else if (breadcrumbs.length > 3 && selectedAssetClassIds.length === 1 && this.getSelectedMarketIds().find(
          marketId => this.getSelectedSubAssetClassIds().indexOf(this.getMarketTableDataByProductId(marketId).subAssetClassId) < 0
        ) == null
      ) {
        // "All <sector> markets" selected
        breadcrumbs[breadcrumbs.length-2].selected = true;
      } */
    } else if (
        Object.values(this._assetClassSelected).filter(x => !!x).length === this.assetClassRiskBreakdownTableData.length
        && Object.values(this._subAssetClassSelected).filter(x => !!x).length === 0) {
      breadcrumbs[1].selected = true;
    } else if (Object.values(this._assetClassSelected).filter(x => !!x).length === 0) {
      breadcrumbs[0].selected = true;
    }
    this.tableBreadcrumbs = breadcrumbs;
  }


  public marketsTableBreadcrumbClicked(crumb: {id: string, type: string}) {
    const id = crumb.id;
    const type = crumb.type;
    switch (type) {
      case 'assetClassPerformance': {
        // reset everything
        this._assetClassSelected = {};
        this._subAssetClassSelected = {};
        this._marketsSelected = {};
        this.subAssetClassRiskBreakdownTableData = [];
        this.marketsRiskBreakdownTableData = [];
        this.regenTables();
      } break;
      case 'assetClassMarkets': {
        this._assetClassSelected = { [Number(id)]: 'on' };
        this._subAssetClassSelected = this.subAssetClassRiskBreakdownTableData.filter(
          row => row.assetClassId === Number(id)
        ).reduce((obj, row) => {
          obj[row.id] = 'on';
          return obj;
        }, {});
        this._marketsSelected = {};
        this.subAssetClassRiskBreakdownTableData = [];
        this.marketsRiskBreakdownTableData = [];
        this.marketsRiskBreakdownTableColumns[0]['label'] = `All ${this.assetClassFromId(Number(id)).Name.toLowerCase()} markets`;
        this.regenTables();
      } break;
      case 'subAssetClassPerformance': {
        for (let i = 0; i < this.assetClassRiskBreakdownTableData.length; i++) {
          this._assetClassSelected[this.assetClassRiskBreakdownTableData[i].id] = 'on';
        }
        this._subAssetClassSelected = {};
        this._marketsSelected = {};
        this.subAssetClassRiskBreakdownTableData = [];
        this.marketsRiskBreakdownTableData = [];
        this.regenTables();
        this.subAssetClassRiskBreakdownTableColumns[0]['label'] = 'All sectors';
      } break;
      case 'marketPerformance': {
        for (let i = 0; i < this.assetClassRiskBreakdownTableData.length; i++) {
          this._assetClassSelected[this.assetClassRiskBreakdownTableData[i].id] = 'on';
        }
        this._subAssetClassSelected = {};
        this.regenTables();
        for (let i = 0; i < this.subAssetClassRiskBreakdownTableData.length; i++) {
          this._subAssetClassSelected[this.subAssetClassRiskBreakdownTableData[i].id] = 'on';
        }
        this._marketsSelected = {};
        this.regenTables();
        this.marketsRiskBreakdownTableColumns[0]['label'] = 'All markets';
      } break;
    }
    this.updateMarketTableBreadcrumbs();
  }

  regenTables(selected: string = this.currentTab) {
    switch (selected) {
      case 'Margin': {
        this.generateMarginTable();
      } break;
      case 'VaR': {
        this.generateVaRTable();
      } break;
      case 'Volatility': {
        this.generateVolatilityTable();
      } break;
      default: { this._spinnerService.hide('risk-breakdown'); } break;
    }
  }

  switchAssetClassesOrSectors(selected: string) {
    if (selected === 'All asset classes') {
      this.currentBreakdownClass = 'asset classes';
    } else {
      this.currentBreakdownClass = 'sectors';
    }
    this.changeRiskBreakdownCategory({selected: this.currentTab});
  }

  changeRiskBreakdownCategory(event: { selected: RiskType }) {
    this.currentTab = event.selected;
    if (this.lastDate == null || this.accountId == null || this.userId == null || this.lastMonthDate == null) {
      return;
    }
    this._spinnerService.show('risk-breakdown');
    this.loadLastMonthExposures$().subscribe(() => {
      switch (event.selected) {
        case RiskType.Margin: {
          this.selectMarginTab();
        } break;
        case RiskType.ValueAtRisk: {
          this.selectVaRTab();
        } break;
        case RiskType.Volatility: {
          this.selectVolatilityTab();
        } break;
        default: { this._spinnerService.hide('risk-breakdown'); } break;
      }
      this.updateMarketTableBreadcrumbs();
    },
    (err) => this._logger.error('Cannot update risk exposures', err),
    () => this._spinnerService.hide('risk-breakdown'));
  }

  createBarChart(
    id: string,
    categories: string[],
    seriesOne: Highcharts.SeriesOptions[] | Highcharts.SeriesColumnOptions,
    seriesTwo: Highcharts.SeriesOptions[] | Highcharts.SeriesColumnOptions,
    nameOne: string,
    nameTwo: string,
    yTitle = 'Values',
    extraOptions = {} as Highcharts.Options
  ) {
    const chartOptions = {
      chart: {
        events: {
          load: deleteLegendSymbols,
        },
        type: 'column'
      },
      colors: this.lineColors,
      legend: { enabled: true, labelFormatter: checkboxLabelFormatter, useHTML: true, },
      plotOptions: {
        series: <Highcharts.SeriesColumnOptions> {
          minPointLength: 3,
        }
      },
      tooltip: {
        valueSuffix: ' %',
        valueDecimals: 2,
      },
      title: {
          text: ''
      },
      xAxis: {
          categories: categories
      },
      credits: {
          enabled: false
      },
      yAxis: {
        labels: {
          format: '{value:.f} %',
        },
        title: {
          text: yTitle,
        },
      },
    } as Highcharts.Options;
    const seriesOpts = [];
    if (isSeriesColumnOptions(seriesOne)) {
      seriesOpts.push(seriesOne as Highcharts.SeriesOptions);
    } else {
      seriesOpts.push(<Highcharts.SeriesOptions> {
        name: nameOne,
        data: seriesOne,
        dataLabels: {
          format: '{y:.f} %',
        },
        type: 'column',
      });
    }
    if ( seriesTwo != null ) {
      if (isSeriesColumnOptions(seriesTwo)) {
        seriesOpts.push(seriesTwo as Highcharts.SeriesOptions);
      } else {
        seriesOpts.push(<Highcharts.SeriesOptions> {
          name: nameTwo,
          data: seriesTwo,
          dataLabels: {
            format: '{y:.f} %',
          },
          type: 'column'
        });
      }
    }

    chartOptions.series = seriesOpts;
    lodash.merge(chartOptions, extraOptions);
    this.chartRegistry.set(id, Highcharts.chart(id, chartOptions));
  }

  private getMarketTableDataByProductId(marketId: number): any {
    return this.marketsRiskBreakdownTableData.find(mTabData => mTabData.id === marketId);
  }

  private getMarketTableDataBySubAssetClassId(subAssetClassId: string): any[] {
    return this.marketsRiskBreakdownTableData.filter(mTabData => mTabData.subAssetClassId === subAssetClassId);
  }

  private getAssetClassTableDataByAssetClassId(assClassId: number): any {
    return this.assetClassRiskBreakdownTableData.find(acTabData => acTabData.id === assClassId);
  }

  private getAvailableMarketIds(): number[] {
    return this.marketsRiskBreakdownTableData.map(mTabData => mTabData.id);
  }

  private getSelectedAssetClassIds(): number[] {
    return Object.entries(this._assetClassSelected).filter(([key, value]: [string, any]) => !!value).map(([key, value]) => Number(key));
  }

  private getSelectedMarketIds(): number[] {
    return Object.entries(this._marketsSelected).filter(([key, value]: [string, any]) => !!value).map(([key, value]) => Number(key));
  }

  private getSelectedSubAssetClassIds(): string[] {
    return Object.entries(this._subAssetClassSelected).filter(([key, value]: [string, any]) => !!value).map(([key, value]) => key);
  }
/*
  /**
   * Set width of floating navbars to equal grandparent, because parent width is 0.
   * /
  @HostListener('window:resize', [])
  @debounce(100, { leading: true })
  private resizeStickyNavbars() {
    const navbars = this.elem.nativeElement.querySelectorAll(':scope div.navbar-content');
    navbars.forEach(function(barDiv: HTMLElement) {
      const gp = barDiv.parentElement.parentElement;
      barDiv.style.width = gp.offsetWidth + 'px';
    });
  }
 */
  private yAxisSettings(maxY: number, minY: number): Highcharts.Options {
    const opts = {
      yAxis: {
        labels: {
          format: '{value:.f} %'
        },
        max: this.absMax(maxY, minY) * this.scaleFactor,
        min: -this.absMax(maxY, minY) * this.scaleFactor,
      }
    } as Highcharts.Options;
    if (this.showTradingLevelBasedFigures()) {
      const [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      (opts.yAxis as Highcharts.AxisOptions).labels.formatter = function() {
        return `€${sigFigs(this.value as number, 3)}${magnitudeSymbol}`;
      };
    }
    return opts;
  }

  private wipeCharts() {
    this.category = [];
    this.seriesOne = null;
    this.seriesTwo = null;
    this.nameOne = null;
    this.nameTwo = null;
    this.initDataForTable();
    const monthChartId = this.riskLastVsMonth.nativeElement.id;
    const longShortChartId = this.riskLongVsShort.nativeElement.id;
    const monthChart = this.chartRegistry.get(monthChartId) as Highcharts.Chart;
    const longShortChart = this.chartRegistry.get(longShortChartId) as Highcharts.Chart;
    try {
      if (monthChart) {
        monthChart.destroy();
      }
    } catch (TypeError) {
      // suppress
    }
    try {
      if (longShortChart) {
        longShortChart.destroy();
      }
    } catch (TypeError) {
      // suppress
    }
    return [monthChartId, longShortChartId];
  }

  private absMax(maxY: number, minY: number) {
    return Math.max(Math.abs(maxY), Math.abs(minY));
  }
}
