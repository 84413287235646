import { Injectable } from '@angular/core';
import { AccountDataService } from '@app/account-data/account-data.service';
import { AcctIdx } from '@app/account-data/acct-idx';
import { AccountHoldingsOverview } from '@app/api/psp-backend.models';
import { AppService } from '@app/app-service.service';
import { RiskBreakdownData } from '@app/risk-breakdown/risk-breakdown-data';
import { LoggingService } from '@app/utils/logging/logging.service';
import { NotificationsService } from 'angular2-notifications';
import *  as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getOffsetStartDays } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AccountOverviewService {
  acctIdxs$: Observable<AcctIdx[]>;
  activeAcctIdx$: Observable<AcctIdx>;

  docsCardCollapsed$: Observable<boolean>;
  holdingPosition$: Observable<AccountHoldingsOverview>;
  perfCardCollapsed$: Observable<boolean>;
  orderCardCollapsed$: Observable<boolean>;
  overviewCardCollapsed$: Observable<boolean>;
  overviewMenuHeight$: Observable<number>;
  riskCardCollapsed$: Observable<boolean>;
  riskBreakdown$: Observable<RiskBreakdownData>;

  scrollToSection$: Observable<string>;
  selectedDate$: Observable<Date>;
  selectedSection$: Observable<string>;
  showDocuments$: Observable<boolean>;
  showIntradayOrders$: Observable<boolean>;
  updateOverviewHeight$: Observable<void>;

  private _acctIdxsSubj = new BehaviorSubject<AcctIdx[]>(null);
  private _activeAcctIdxSubj = new BehaviorSubject<AcctIdx>(null);
  private _selectedDateSubj = new BehaviorSubject<Date>(null);
  private _selectSectionSubj = new BehaviorSubject<string>('overview');
  
  private _docsCardCollapsed = new BehaviorSubject<boolean>(true);
  private _orderCardCollapsed = new BehaviorSubject<boolean>(true);
  private _overviewCardCollapsed = new BehaviorSubject<boolean>(false);
  private _overviewMenuHeight = new BehaviorSubject<number>(null);
  private _perfCardCollapsed = new BehaviorSubject<boolean>(true);
  private _riskCardCollapsed = new BehaviorSubject<boolean>(true);

  private _holdingPositionSubj = new Subject<AccountHoldingsOverview>();
  private _riskBreakdownSubject = new Subject<RiskBreakdownData>()
  private _showDocumentsSubj = new BehaviorSubject(false);
  private _showIntradayOrders = new BehaviorSubject<boolean>(false);
  private _scrollToSectionSubj = new Subject<string>();
  private _updateOverviewMenuHeightSubj = new Subject<void>();


  constructor(
    private _appSvc: AppService,
    private _acctDataSvc: AccountDataService,
    private _logger: LoggingService,
    private _notify: NotificationsService,
  ) { 
    this.acctIdxs$ = this._acctDataSvc.loadAcctIdxs().pipe(
      map(
        (aiData) => Array.isArray(aiData) && Array.isArray(aiData[0]) ? aiData[0] : null
      )
    );
    this.activeAcctIdx$ = this._activeAcctIdxSubj.asObservable();

    this.holdingPosition$ = this._holdingPositionSubj.asObservable();
    this.scrollToSection$ = this._scrollToSectionSubj.asObservable();
    this.selectedDate$ = this._selectedDateSubj.asObservable();
    this.selectedSection$ = this._selectSectionSubj.asObservable();
    this.showDocuments$ = this._showDocumentsSubj.asObservable();
    this.showIntradayOrders$ = this._showIntradayOrders.asObservable();

    this.docsCardCollapsed$ = this._docsCardCollapsed.asObservable();
    this.orderCardCollapsed$ = this._orderCardCollapsed.asObservable();
    this.overviewCardCollapsed$ = this._overviewCardCollapsed.asObservable();
    this.overviewMenuHeight$ = this._overviewMenuHeight.asObservable();

    this.perfCardCollapsed$ = this._perfCardCollapsed.asObservable();
    this.riskCardCollapsed$ = this._riskCardCollapsed.asObservable();

    this.riskBreakdown$ = this._riskBreakdownSubject.asObservable();
    
    this.updateOverviewHeight$ = this._updateOverviewMenuHeightSubj.asObservable();
  }

  set acctIdxs(aList: AcctIdx[]) {
    this._acctIdxsSubj.next(aList);
  }

  set activeAcctIdx(a: AcctIdx) {
    this._activeAcctIdxSubj.next(a);
    this.resetCardCollapseState();
  }

  changeAcctIdx(id: string): void {
    // this.hideDropDown('dropdown-menu-change-account');
    forkJoin([
      this.acctIdxs$.pipe(take(1)),
      this.activeAcctIdx$.pipe(take(1)),
    ]).subscribe(
      ([aiList, active]) => {
        if (id !== active.Id) {
          const newAI = aiList.find(ai => ai.Id === id);
          if (newAI) {
            this.activeAcctIdx = newAI;
          } else {
            this._notify.error('Unknown account', `The account ${id} is not available`);
          }
        }
      }
    );
  }

  collapseDocsCard(val?: boolean) {
    if (val != null) {
      this._docsCardCollapsed.next(val);
    } else {
      this.docsCardCollapsed$.pipe(take(1)).subscribe(
        (currVal) => this._docsCardCollapsed.next(!currVal)
      );
    }
  }
  collapseOrderCard(val?: boolean) {
    if (val != null) {
      this._orderCardCollapsed.next(val);
    } else {
      this.orderCardCollapsed$.pipe(take(1)).subscribe(
        (currVal) => this._orderCardCollapsed.next(!currVal)
      );
    }
  }
  collapseOverviewCard(val?: boolean) {
    if (val != null) {
      this._overviewCardCollapsed.next(val);
    } else {
      this.overviewCardCollapsed$.pipe(take(1)).subscribe(
        (currVal) => this._overviewCardCollapsed.next(!currVal)
      );
    }
  }
  collapsePerfCard(val?: boolean) {
    if (val != null) {
      this._perfCardCollapsed.next(val);
    } else {
      this.perfCardCollapsed$.pipe(take(1)).subscribe(
        (currVal) => this._perfCardCollapsed.next(!currVal)
      );
    }
  }
  collapseRiskCard(val?: boolean) {
    if (val != null) {
      this._riskCardCollapsed.next(val);
    } else {
      this.riskCardCollapsed$.pipe(take(1)).subscribe(
        (currVal) => this._riskCardCollapsed.next(!currVal)
      );
    }
  }
  
  getLastDay(): Date {
      const TZ = this._appSvc.TZ;
      const lastDay = moment().tz(TZ).subtract(1, 'day').endOf('day');
      return lastDay.subtract(getOffsetStartDays(lastDay.toDate()), 'days').toDate();
  }

  isLastDay$(): Observable<boolean> {
    return this.selectedDate$.pipe(map((selectedDate) => {
      const lastDay = this.getLastDay();
      const lastDayCheck = moment(lastDay).isSame(moment(selectedDate), 'day');
      return lastDayCheck;
    }));
  }

  getLastMonth(): Date {
    const endOfMonth = moment().tz(this._appSvc.TZ).subtract(1, 'month').endOf('month');
    return endOfMonth.subtract(getOffsetStartDays(endOfMonth.toDate()), 'days').toDate();
  }

  isLastMonth$(): Observable<boolean> {
    return this.selectedDate$.pipe(map((selectedDate) => moment(this.getLastMonth()).isSame(moment(selectedDate), 'day')));
  }

  set overviewMenuHeight(val: number) {
    setTimeout(() => this._overviewMenuHeight.next(val));
  }

  resetCardCollapseState() {
    this.collapseDocsCard(true);
    this.collapseOrderCard(true);
    this.collapseOverviewCard(false);
    this.collapsePerfCard(true);
    this.collapseRiskCard(true);
    this.updateOverviewMenuHeight();
  }
  scrollToSection(sectionId: string) {
    this._scrollToSectionSubj.next(sectionId);
  }

  set selectedDate(d: Date) {
    this._selectedDateSubj.next(d);
  }

  selectSection(sectionId: string, scrollTo = true) {
    this._selectSectionSubj.next(sectionId);
    if (scrollTo) {
      this.scrollToSection(sectionId);
    }
  }

  set showDocuments(val: boolean) {
    this._showDocumentsSubj.next(val);
  }

  set showIntradayOrders(val: boolean) {
    this._showIntradayOrders.next(val);
  }

  updateOverviewMenuHeight() {
    this._updateOverviewMenuHeightSubj.next();
  }

  updateRiskBreakdown(breakdown: RiskBreakdownData) {
    this._riskBreakdownSubject.next(breakdown);
  }
}
