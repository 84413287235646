import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface UiSnackbarOptions {
  message: string;
  action: {
    label: string;
    value: boolean;
  };
  actionCallback: (result: boolean) => void,
}

@Injectable({
  providedIn: 'root'
})
export class UiSnackbarService implements OnDestroy {
  public snackbar$: Observable<UiSnackbarOptions>;
  private _snackbarSubj = new Subject<UiSnackbarOptions>();

  constructor() {
    this.snackbar$ = this._snackbarSubj.asObservable();
  }

  hide() {
    this._snackbarSubj.next(null);
  }

  ngOnDestroy() {
    this._snackbarSubj.complete();
    this._snackbarSubj = null;
  }

  open(options: UiSnackbarOptions) {
    this._snackbarSubj.next(options);
  }
}
