<data-table
  #assetClassTable
  *ngIf="showAssetClassTable()"
  [breadcrumbs]="breadcrumbs"
  [columns]="assetClassesColumns"
  [items]="assetClassesItemsOut"
  [options]="tableOptions"
  (breadcrumbSelected)="breadcrumbClicked($event)"
  (selectionChanged)="selectItemFromTable('assetClass', $event)">
</data-table>
<data-table
  #subAssetClassTable
  *ngIf="showSubAssetClassTable()"
  [breadcrumbs]="breadcrumbs"
  [columns]="subAssetClassesColumns"
  [items]="subAssetClassesItemsOut"
  [options]="tableOptions"
  (breadcrumbSelected)="breadcrumbClicked($event)"
  (selectionChanged)="selectItemFromTable('subAssetClass', $event)">
</data-table>
<data-table
  #productTable
  *ngIf="showProductTable()"
  [breadcrumbs]="breadcrumbs"
  [columns]="productColumns"
  [items]="productItemsOut"
  [maxItems]="1"
  [options]="tableOptions"
  (breadcrumbSelected)="breadcrumbClicked($event)"
  (selectionChanged)="selectItemFromTable('product', $event)">
</data-table>
