<div style="position: relative;">
  <div #dynamicsNavbar
    class="navbar-wrapper"  
    [ngClass]="{ 
      'nav-scrolled-up': isOverviewNavbarScrolledUp|async,
      'floating': (navbarIsFloating$|async)
    }"
    stickybits>
    <div class="navbar-inner-wrapper">
      <div class="navbar-content">
          <navbar-button #dynamicsTypeNavbar
          class="flex"
          [initial]="0"
          [tabs]="dynamicsTypeOptions"
          (selectionChanged)="dynamicsChangeType($event.selected)">
        </navbar-button>
        <navbar-button #dynamicsPeriodNavbar class="flex" 
          [initial]="dynamicsTimePeriodInitial" 
          [tabs]="dynamicsTimePeriodOptions" 
          (selectionChanged)="dynamicsSelectTimePeriod($event.selected)">
        </navbar-button>
      </div>
    </div>
  </div>
  <div class="chart">
    <spinner name="portfolio-dynamics-chart">
      <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
    </spinner>
    <h2>Portfolio signal dynamics</h2>
    <div class="chart-container">
      <div id="account-signal" class="chart-element"></div>
    </div>
  </div>
  <div class="chart">
    <spinner name="asset-classes-dynamics-chart">
      <div class="spinner-shield"><div class="spinner-indicator"><span>Loading...</span></div></div>
    </spinner>
    <h2>Asset class signal dynamics</h2>
    <div class="chart-container">
      <div id="asset-classes-signal" class="chart-element"></div>
    </div>
  </div>
  <h2 class="subtitle">
    {{dynamicsTypeLabel(selectedType) || '' }}
    <ng-container *ngIf="tableDataDate">as of {{tableDataDate.format('DD-MMM-YYYY')}}</ng-container>
  </h2>
  <app-market-browser
  [tableData]="tableData"
  [tableOptions]="tableOptions"
  (selectedMarket)="selectMarket($event)"
  ></app-market-browser>
</div>
