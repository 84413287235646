<div class="container">
  <div class="columns">
    <div class="column is-offset-2 is-8">
      <div class="card">
        <div class="card-content">

          <div class="has-text-centered">
            <div class="login-icon"><img src="../../static/assets/ep-logo-symbol_x2.png" alt=""></div>
          </div>

          <h1>Estlander & Partners offers investment strategies through the Clarity Platform</h1>
          <div class="field">
            <div class="control">
              <label clasS="label">SELECT YOUR COUNTRY OF DOMICILE:</label>
              <app-epp-basic-dropdown [activeItem$]="selectedCountry" (activateItem)="selectCountry($event)"
                class="is-fullwidth" [items$]="countries">
              </app-epp-basic-dropdown>
            </div>
          </div>
          <section>
            <div class="initial">
              <p>The Clarity Platform is held available by Estlander & Partners Ltd.</p>
              <p>The information and tools contained on and made available and accessible through the Clarity Platform are
                intended
                for and directed solely at persons
                reasonably believed to be within one of the exemptions contained in the act on mutual funds (48/1999, as
                amended)
                and act on investment services
                (sijoituspalvelulaki 747/2012, as amended) in Finland (“professional investor exemption”), other persons
                reasonably
                believed to have a level of
                experience of and information on, as well as the ability to bear, the risks of investments similar to the
                level of
                professional investors (“sophisticated
                investors”), or to whom such information may otherwise lawfully be communicated to give preliminary
                information
                about the investment strategies
                described herein. Outside Finland the Clarity Platform is solely held available for and accessible by
                persons who
                are domiciled in and accessing from a
                country listed in the dropdown list and who meet the investor criteria stated for the relevant country, or
                persons
                to whom such information may otherwise
                lawfully be communicated to give preliminary information about the investment strategies described herein.
                It is
                confidential communication to, and
                solely for the use of, such persons. Any access or use of the Clarity Platform by any person beyond what
                is stated
                herein or that do not fulfill the
                investor criteria for its jurisdiction is strictly prohibited. The information contained on the Clarity
                Platform is
                not directed at person in any jurisdiction
                where (by reason of that person’s nationality, residence, client classification or otherwise) availability
                of it is
                prohibited. Each person receiving any
                information from the Clarity Platform is for its own part responsible for ensuring the full observance of
                laws of
                any relevant country. Estlander & Partners
                Ltd cannot be held liable for any access to the Clarity Platform in breach of the access requirements.</p>
              <p>If your country of location is not listed in the list of countries above or you do not meet the investor
                criteria
                relating to your jurisdiction, the Clarity
                Platform is not intended for you and you are not entitled to access the platform.</p>
              <p>Furthermore, any access to the Clarity Platform is subject to your confirmation on that you have read and
                understood the <a target="_blank" href="/static/assets/Privacy.Policy.pdf">PRIVACY POLICY</a> on how
                personal
                data is processed on the Clarity Platform.</p>
            </div>
            <div class="show-more"><button class="button" (click)="showMore = !showMore" type="button">
              {{ showMore ? 'HIDE': 'SEE MORE' }}</button></div>
            <div *ngIf="showMore" class="more-info">
              <div class="more-general">
                  <p>The information provided herein is provided “as is” for information purposes only and subject to change without notice. No information contained on the
                  Clarity Platform shall/should be construed as a solicitation, an offer, an invitation or recommendation to acquire or dispose of any investment in any
                  product managed or advised by Estlander & Partners Ltd nor does it constitute an offer for sale of any such product or to provide advisory services or
                  to engage in any other transaction.</p>
                  <p>The Clarity Platform and the information on it is proprietary information of Estlander & Partners Ltd and intended solely for the personal use of those
                  who have been granted access to the platform. It is not addressed to any other person and may not be used by them for any purpose whatsoever.
                  Consequently, the information contained herein may not be reproduced or disseminated in whole or in part by any recipient hereof or any other third
                  party without the prior written consent of Estlander & Partners Ltd.</p>
                  <p>The information on the Clarity Platform cannot disclose all the risks and other significant aspects relating to the trading programs described herein or to
                  alternative investments and it expresses no views as to the suitability of the services or other matters described herein to the individual circumstances
                  of any recipient. The Clarity Platform may be used as a source of information in relation to investment services if Estlander & Partners Ltd in its sole
                  discretion deems it fit and if such use is in accordance with applicable law. Potential investors should note that alternative investments can involve
                  significant risks, including that any invested capital is at risk, and the value of an investment may go down as well as up. There is no guarantee of
                  trading performance and past or projected performance is not a guarantee for or indication of future results.</p>
                  <p>While Estlander & Partners Ltd endeavors to keep the information up to date and correct, neither Estlander & Partners Ltd nor any of the other
                  companies in the within the same company group make any representations or warranties of any kind, express or implied, about the completeness,
                  accuracy, reliability, suitability or availability with respect to the information, products, services or related graphics contained on the Clarity Platform for
                  any purpose. Any reliance you place on such information is therefore strictly at your own risk. Any data on the on the Clarity Platform is presented as
                  derived data and is displayed with a 15 minutes delay.</p>
              </div>
              <div class="more-country-specific" [innerHTML]="countrySpecific[selectedCountry]">
                <!-- Country specific HTML message -->
              </div>
            </div>
          </section>
          <form class="form consent-form" [formGroup]="acceptForm">
            <div class="field">
              <input class="is-checkradio is-medium" formControlName="confirmClarityTerms" id="confirm_terms" type="checkbox">
              <label for="confirm_terms">
                I confirm that I meet the relevant investor criteria for the jurisdiction I have stated, that I have read, fully
                understand and agree to the terms
                outlined above and that my access is in accordance with the laws and regulations applicable to me.
              </label>
            </div>
            <div class="field">
              <input class="is-checkradio is-medium" formControlName="confirmPrivacyPolicy" id="confirm_privpolicy" type="checkbox">
              <label for="confirm_privpolicy">
                I confirm that I have read and that I fully understand the privacy policy and the manner in which my personal data
                is being processed under such policy.
              </label>
            </div>
            <div class="field">
              <input class="is-checkradio is-medium" formControlName="confirmUserData" id="confirm_data" type="checkbox">
              <label for="confirm_data">
                I confirm that the personal data provided by me to Estlander & Partners Ltd for setting up the user account are
                correct and commit to
                promptly inform Estlander & Partners Ltd of any changes thereto
              </label>
            </div>
            <div class="field">
              <input class="is-checkradio is-medium" formControlName="confirmEPTerms" id="confirm_services" type="checkbox">
              <label for="confirm_services">
                I confirm that I have read and fully understood the <a
                  href="https://www.estlanderpartners.fi/?page=privacy#body367">information about Estlander & Partners Ltd and its
                  services</a>.
              </label>
            </div>
            <div class="field is-grouped-right">
              <div class="control">
                <button
                  class="button"
                  (click)="acceptTerms(true)"
                  [ngClass]="{ 'is-primary' : acceptForm.valid }"
                  type="button">ACCEPT</button>
              </div>
              <div class="control"><button class="button" (click)="acceptTerms(false)" type="button">DECLINE</button></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>