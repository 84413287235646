import { Component, OnInit, Input, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-ui-collapsible-section',
  templateUrl: './ui-collapsible-section.component.html',
  styleUrls: ['./ui-collapsible-section.component.scss']
})
export class UiCollapsibleSectionComponent implements OnInit {
  @Input() set collapsed(val: boolean) {
    Promise.resolve(null).then(() => {
      this._collapsed = val;
      if (val && this.renderer != null) {
        this.renderer.addClass(this.el.nativeElement, 'collapsed');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'collapsed');
      }
      this.collapsedChange.emit(val);
    });
  }
  @Input() direction = 'horizontal';
  @Input() subtitle = null;
  @Output() collapsedChange = new EventEmitter<boolean>();

  get collapsed() { return this._collapsed; }
  private _collapsed = false;
  private _componentId = uniqueId();

  get offsetTop(): number {
    if (this.el != null) {
      return this.el.nativeElement.offsetTop;
    }
  }

  constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {
  }

  // Used to give collapse button a site-wide unique id. (Undefined behavior if multiple sections with same id.)
  collapseButtonId() {
    return `collapse-button-${this._componentId}`;
  }

  ngOnInit() {
  }

  toggleCollapsed(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.collapsed = !this.collapsed;
  }
}
