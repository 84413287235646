<h2>Signal strength <i class="fa fa-info-circle" 
  title="Signal strength is defined as the
  risk-weighted model exposure to
  maximum exposure. The measure
  excludes short-term models."></i></h2>

  <navbar-button #portfolioDailyReturnNavbar class="flex" 
    [initial]="initialPeriodTab" 
    [tabs]="availablePeriods" 
    (selectionChanged)="selectPeriod($event)">
  </navbar-button>
  <app-ep-linechart
    [options]="chartOptions"
    [series]="signalSeries$|async"
  >
  </app-ep-linechart>