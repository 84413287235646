import { cloneDeep, isNumber, merge as mergeObjects } from 'lodash';
import * as moment from 'moment-timezone';
import * as Highcharts from 'highcharts';
import * as Highstock from 'highcharts/highstock';

import { TZ } from './constants';

export const defaultLineChartScalingFactor = 1.5;

export const lineColors = ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'];
export const columnColors = ['#92500F', '#BE7F33', '#DFC180', '#FFD800', '#FD9734', '#EB6E21', '#39978F', '#542F09'];

/**
 * Draw legend with FontAwesome checkbox icons instead of default Highcharts symbols.
 *  Expects to be called from LegendOptions.labelFormatter.
 */
export function checkboxLabelFormatter() {
  const series = this as Highcharts.Series;
  if (series.visible) {
    let color = series['color'];
    if (!color) {
      color = (series.options as Highcharts.SeriesLineOptions)['color'];
    }
    return `<i class="fa fa-check-square" style="color: ${color}"></i> ${series.name}`;
  } else {
    return `<i class="fa fa-square"></i> ${series.options.name}`;
  }
}

/**
 * Hide default legend symbols and draw preferred symbols with checkboxLabelFormatter()
 *
 * Note: can't hide column series symbols here, so call deleteLegendSymbols() from chart.events.load
 * on charts with such series.
 */
export const checkboxLegendOptions =  {
  enabled: true,
  labelFormatter: checkboxLabelFormatter,
  symbolHeight: 0,
  symbolRadius: 0,
  symbolWidth: 0,
  useHTML: true,
} as Highcharts.LegendOptions;

export const defaultChartParams: Highcharts.Options = {
  colors: lineColors,

  plotOptions: {
    line: {
      marker: { enabled: false },
    }
  },

  legend: checkboxLegendOptions, /* {
    enabled: true,
    labelFormatter: function () {
      const series = this as Highcharts.Series;
      if (series.visible) {
        return `<i class="fa fa-check-square" style="color: ${
          (series.options as Highcharts.SeriesLineOptions)['color']
        }"></i> ${series.name}`;
      } else {
        return `<i class="fa fa-square"></i> ${series.options.name}`;
      }
    },
    symbolHeight: 0,
    symbolPadding: 0,
    symbolRadius: 0,
    symbolWidth: 0,
    useHTML: true,
  }, */

  tooltip: {
    valueDecimals: 2,
    valueSuffix: ' %',
    pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
    split: false,
    shared: true,
  },
  xAxis: {
    type: 'datetime',
    // crosshair: true,
    labels: {
      enabled: true,
      format: '{value:%d-%b-%Y}',
    },

  },
  exporting: {
    enabled: false
  },
  credits: {
    enabled: false
  },

  yAxis: {
    labels: {
      format: '{value} %'
    },
    opposite: false,
    tickAmount: 5,
    title: {
      text: 'Return',
    }
  },
};


/**
 * This function is used as the xAxis.tickPositioner callback in Highstocks.Options to position the date ticks in YTD charts.
 * The data series should have a dummy value inserted at the end of the year, eg. [endOfYearTimestamp, null].
 *
 * The function puts a tick every friday between the first and last date in the xAxis.
 *
 * Note: xAxis.ordinal should be false for this to work well. This is only available on Highstocks charts.
 */
function bimonthlyTickPositioner() {
  const positions: number[] = [];
  const startPoint = this.min;
  const endPoint = this.max;

  // loop over days between min and max, marking start of odd months
  let current = startPoint;
  while (current < endPoint) {
    const thisDay = moment.tz(current, TZ);
    // Moment.month() starts from 0, Moment.date() starts from 1
    if (thisDay.date() === 2 && thisDay.month() % 2 === 0) {
      positions.push(current);
    }
    current += 24 * 60 * 60 * 1000;
  }
  return positions;
}

/**
 * This function is used as the xAxis.tickPositioner callback in Highstocks.Options to position the date ticks in MTD charts.
 * The data series should have a dummy value inserted at the end of the month, eg. [endOfMonthTimestamp, null].
 *
 * The function puts a tick every friday between the first and last date in the xAxis.
 *
 * Note: xAxis.ordinal should be false for this to work well. This is only available on Highstocks charts.
 */
function weeklyTickPositioner() {
  const positions: number[] = [];
  const startPoint = this.min;
  const endPoint = this.max;

  // loop over days between min and max, marking every friday
  let current = startPoint;
  while (current < endPoint) {
    const thisDay = moment.tz(current, TZ);
    if (thisDay.date() === 5) {
      // Friday
      positions.push(current);
    }
    current += 24 * 60 * 60 * 1000;
  }
  return positions;
}

export function buildReturnChartParams(
  period: string,
  series?: Highcharts.SeriesLineOptions[],
  min?: number,
  max?: number): Highcharts.Options {
  const chartPeriodStepMap = {
    'Today':    0.5,
    'Daily':    0.5,
    'MTD':      1.0,
    'YTD':      2.0,
    '3 months':  1.0,
    '6 months': 2.0,
    '1 year':   2.0,
    'All':      2.0,
  };

  let maxVal = 0;
  let minVal = 0;
  if (series != null && (min == null || max == null)) {
    for (let i = 0; i < series.length; i++) {
      for (let j = 0; j < series[i].data.length; j++) {
        maxVal = Math.max(maxVal, Math.abs(series[i].data[j][1]));
      }
    }
    /* skip this for now
    maxVal = Math.ceil(maxVal / chartPeriodStepMap[period]) * chartPeriodStepMap[period]; // round up to nearest 0.5
    maxVal = Math.max(maxVal, chartPeriodStepMap[period]);
    maxVal = isNil(max) ? maxVal : max;
    minVal = isNil(min) ? -maxVal : min;
    */
  }

  maxVal = Math.ceil(defaultLineChartScalingFactor * maxVal);
  minVal = -maxVal;

  const chartParams: Highcharts.Options = {
    chart: {
        events: {
          load: deleteLegendSymbols,
        },
    },
    series,
    legend: checkboxLegendOptions,
    time: {
      timezone: TZ,
      useUTC: false,
    },
    xAxis: {
      dateTimeLabelFormats: {
        hour: '%H:%M',
        day: '%d-%b-%Y',
        week: '%d-%b-%Y',
        month: '%b-%Y',
      },
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      labels: {
        format: '{value:f} %',
        y: +5,
      },
      max: isNumber(max) ? max : maxVal,
      min: isNumber(min) ? min : minVal,
      opposite: false,
      showLastLabel: true,
      tickAmount: 5,
      title: {
        text: 'Return',
      }
    },
  };

  if (period === 'Today') {
    Object.assign(chartParams, {
      xAxis: {
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: '%H:%M',
        },
        endOnTick: false,
        format: '{value:%H:%M}',
        labels: {
          rotation: 270,
        },
        min: +(moment.tz(TZ).startOf('day')), // 00:00:00
        maxPadding: 0.2,
        max: +(moment.tz(TZ).endOf('day')), // 23:59:59
        ordinal: false,
        startOnTick: false,
        tickInterval: 3600 * 1000, // 1 hour
        units : [['hour', [1, ]]],
      },
    });
  }

  if (period === 'Last day') {
    Object.assign(chartParams, {
      xAxis: {
        dateTimeLabelFormats: {
          day: '%d-%b-%Y',
        },
        labels: {
          y: -75,
        },
        minPadding: 0.20,
        maxPadding: 0.20,
        tickWidth: 0,
      }
    });
  }

  if (period === 'MTD') {
    Object.assign(chartParams, {
      xAxis: {
        labels: {
          format: '{value:%d-%b-%Y}',
        },
        ordinal: false,
        tickPositioner: weeklyTickPositioner,
      },
    });
  }

  if (period === 'YTD') {
    Object.assign(chartParams, {
      xAxis: {
        labels: {
          format: '{value:%d-%b-%Y}',
        },
        ordinal: false,
        tickPositioner: bimonthlyTickPositioner,
      },
    });
  }
  const newParams = cloneDeep(defaultChartParams);
  return mergeObjects(newParams, chartParams);
}

/**
 * Delete symbols from Highcharts legend (because column chart symbols can't be hidden declaratively.)
 * Expects to be called from ChartOptions.events.load or .render callbacks (or anything where `this` is a ChartObject.)
 */
export function deleteLegendSymbols(event: Event | Highcharts.Chart) {
  let chart: Highcharts.Chart;
  if (event == null) {
    chart = this as Highcharts.Chart;
  } else if ('preventDefault' in event) {
    chart = event.target as unknown as Highcharts.Chart;
  } else {
    chart = event;
  }
  if (chart && chart.container) {
    // TODO this fucked up portfolio return chart on perf&risk also
    const symbols = chart.container.querySelectorAll<SVGRectElement>(':scope .highcharts-legend-item rect.highcharts-point');
    if (symbols.length > 0) {
      symbols.forEach( function(symbol) {
        symbol.style.visibility = 'hidden';
      });
    }
  } else {
    console.warn('Could not clear SVG legend');
  }
}
