import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsDataService } from './settings-data.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [ SettingsDataService ],
})
export class SettingsDataModule { }
