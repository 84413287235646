
export function stripThousandsSeparator(s: string) {
  return s.toString().replace(/[, ]/g, '');
}

export function commaToPoint(s: string) {
  if (s == null) {
    return null;
  }
  return s.toString().replace(/,/g, '.');
}

export function sigFigs(n: number, sig: number): number {
  if (n === 0) {
    return 0;
  }
  const mult = Math.pow(10, sig - Math.floor(Math.log(Math.abs(n)) / Math.LN10) - 1);
  return Math.round(n * mult) / mult;
}


export const TL_tooltipPointFormatter = function(): () => string {
  const magnitudeSymbol = this.magnitudeSymbol;
  return function() {
    return `<b><span style="color:${this.color}">•</span> €${sigFigs(this.y, 3)}${magnitudeSymbol}</b><br>`;
  };
};

/** Select all text in control when focused */
export function selectTextOnFocus(event: FocusEvent, start?: number, end?: number) {
  const input = event.target as HTMLInputElement;
  if (start == null) {
    start = 0;
  }
  if (end == null) {
    end = input.value.length;
  }
  if (input.setSelectionRange != null) {
    input.setSelectionRange(start, end);
  }
}

/** Mirror of encodePortfolioName */
export function decodePortfolioName(str) {
  try {
    return decodeURIComponent(str);
  } catch (err) {
    if (err instanceof URIError) {
      return str;
    } else {
      throw err;
    }
  }
}

/**
 * Based on example from MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * Added hyphen character.
 */
export function encodePortfolioName(str) {
  return encodeURIComponent(str).replace(/[!'()*-]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

/** If number has too many decimals (probably due to float inaccuracy), cut off excess decimal figures */
export function roundNumberIfTooManyDecimals(val: number | string, maxDecimals = 2) {
  const num = Number(val);
  if (isNaN(num)) {
    return val;
  } else {
    const precisionFactor = Math.pow(10, maxDecimals);
    const rounded = (Math.round(num * precisionFactor) / precisionFactor);
    let str = rounded.toString();
    const pointPos = str.lastIndexOf('.');
    if (pointPos >= 0 && pointPos < str.length - maxDecimals) {
      str = str.slice(0, pointPos + maxDecimals + 1);
    }
    return str;
  }
}

/**
 * Move an item to a new poition in array. Changes the array in place.
 * @param theList The array we are operating on
 * @param prevIndex current position in array
 * @param newIndex new position in array
 */
export function swapInArray<T>(theList: Array<T>, prevIndex: number, newIndex: number) {
  // remove moving item
  const [movingItem] = theList.splice(prevIndex, 1);
  // insert into target position
  theList.splice(newIndex, 0, movingItem);
}