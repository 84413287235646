import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { AccessDenied, AuthenticationFailed } from '@app/http-client.service';
import { AppService } from '../../app-service.service';
import { AuthenticationService, User } from '../authentication.service';
import { tap } from 'rxjs/operators';


export enum LoginState {
  cancelled,
  success,
  timeout,
  failure,
  error,
  empty,
}

interface SiteBranding {
  login_cookie_warning?: string;
  login_font_url?: string;
  login_logo?: string;
  login_note_title?: string;
  login_note_body?: string;
  login_purpose_statement?: string;
  login_styles?: object;
  login_support?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  get isLoading(): boolean {
    return this._isLoading;
  }

  get loginState(): LoginState {
    return this._loginState;
  }

  set loginState(loginState: LoginState) {
    this._loginState = loginState;
  }

  branding$: Observable<SiteBranding>;
  @ViewChild('loginCard', {static: false})
  card: ElementRef<HTMLDivElement>;
  cookiesAccepted$: Observable<boolean>;
  username = '';
  password = '';
  rememberMe = false;
  LoginState = LoginState;
  showLostPasswordMessage = false;

  errorMessage: string;
  showNav = false;

  private _brandingSubj = new BehaviorSubject<SiteBranding>(null);
  private _cookiesAcceptedSubj = new BehaviorSubject<boolean>(false);
  private _isLoading = false;
  private _loginState: LoginState = null;

  constructor(
    public appService: AppService,
    private authenticationService: AuthenticationService,
    @Inject('HOSTNAME') private _hostname: string,
    private _cookies: CookieService,
    private router: Router,
  ) {
    this.cookiesAccepted$ = this._cookiesAcceptedSubj.asObservable();
    if (this._cookies.get('ACCEPT_COOKIES') === 'true') {
      this._cookiesAcceptedSubj.next(true);
    }
  }

  dismissCookieWarning() {
    this._cookies.set('ACCEPT_COOKIES', 'true', moment().add(10, 'years').toDate(), '/');
    this._cookiesAcceptedSubj.next(true);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.branding$ = this._brandingSubj.asObservable().pipe(
      tap((branding) => {
        if (branding != null) {
          if (branding.login_styles != null) {
            this.setBrandedStyles(branding.login_styles);
          }
          if (branding.login_font_url != null) {
            this.loadBrandedFont(branding.login_font_url);
          }
        }
      }),
    );
    setTimeout(() => {
      if (this._hostname.match(/^jam/i)) {
      this._brandingSubj.next(
        {
          login_cookie_warning: `<p>This website uses cookies to help us improve the browsing experience for you.
          The cookie is stored locally on your computer or mobile device. By continuing to use this website you are
          giving consent to the use of cookies.</p>`,
          login_font_url: 'https://fonts.googleapis.com/css?family=Didact+Gothic&display=swap',
          login_logo: 'jamadvisors_login_logo.png',
          login_note_body: null,
          login_note_title: null,
          login_purpose_statement: null,
          login_styles: {
            '--col-primary': '#f15755',
            '--col-text': '#4c4b4b',
            '--font-main-family': `'Didact Gothic', sans-serif`,
            '--font-main-size': '16px',
          },
          login_support: 'Email support to have your password reset.',
        }
      );
    }
      this.appService.hideNavbar();
    });
  }

  onLoginClicked() {
    const username = this.username;
    const password = this.password;
    const rememberMe = this.rememberMe;

    if (Math.min(username.length, password.length) === 0) {
      return;
    }

    this.setIsLoading(true);

    const timer = setTimeout(() => { this.onLoginTimeout(); }, 10000); // 10s timeout

    this.authenticationService.login(username, password, rememberMe).subscribe(user => {
      if (user === null) {
        return this.onLoginFailure();
      }
      clearTimeout(timer);
      this.setIsLoading(false);
      this.onLoginSuccess(user);
    }, err => {
      clearTimeout(timer);
      this.setIsLoading(false);
      if (err instanceof AuthenticationFailed) {
        this.onLoginFailure();
      } else if (err instanceof AccessDenied) {
        this.onLoginFailure();
      } else {
        this.onLoginError();
      }
    });

  }

  onCancelClicked() {
    this.setIsLoading(false);
    this.loginState = LoginState.cancelled;
  }

  onLoginSuccess(user: User) {
    this.loginState = LoginState.success;
    this.authenticationService.resetCsrfToken();
    if (user.termsAccepted) {
      this.router.navigate(['/portal/dashboard/']);
      // window.location.href = '/app/portal/dashboard/';
    } else {
      this.router.navigate(['/first_time/']);
      // window.location.href = '/app/portal/first_time/';
    }
    // this.router.navigate(['/portal/dashboard/']);
  }

  onLoginError() {
    this.loginState = LoginState.error;
  }

  onLoginFailure() {
    this.loginState = LoginState.failure;
  }

  onLoginTimeout() {
    this.loginState = LoginState.timeout;
    this.setIsLoading(false);
  }

  onLostPasswordClicked(): boolean {
    this.showLostPasswordMessage = true;
    return false;
  }

  onLostPasswordMessageDismissed(): boolean {
    this.showLostPasswordMessage = false;
    return false;
  }

  focusElement() {

  }

  setBrandedStyles(styles: object) {
    if (this.card != null) {
      for (const [prop, value] of Object.entries(styles)) {
        this.card.nativeElement.style.setProperty(prop, value);
      }
    }
  }

  loadBrandedFont(url: string) {
    const links = document.getElementsByTagName('link');
    if (links) {
      for (const l of Array.from(links)) {
        if (l.getAttribute('href') === url) {
          // font already loaded, abort
          return;
        }
      }
    }
    const newLink = document.createElement('link');
    newLink.setAttribute('href', url);
    newLink.setAttribute('rel', 'stylesheet');
    document.head.appendChild(newLink);
  }

  private setIsLoading(value: boolean) {
    this._isLoading = value;
  }

}
