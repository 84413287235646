export enum QuizId {
    NonprofitBoard = 0,
    Investor = 1,
    Private1 = 2,
    Company1 = 3,
    AMLCompany = 4,
    AMLPrivate1 = 5,
    Upgrade = 6,
    Experience = 7,
    Semiprofessional = 8,
    Amateur = 9,
    LargerShareHolder = 10,
    Behalfofother = 11,
    Done = 12,
    UpgradedToProfessional = 13,
    UpgradedToSemiProfessional = 14,
    AreYouSemiprofessional = 15,
    AmateurDisclaimer = 16,
    Alternative = 17,
    ProDone = 18,
    Private2 = 19,
    Company2 = 20,
    AMLCompany2 = 21,
    ExperiencePro = 22,
  }