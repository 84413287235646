  <navbar-button
    class="flex"
    [disabled]="tsMeasureTypesDisabled"
    [initial]="initialType"
    [tabs]="tsMeasureTypes"
    (selectionChanged)="selectTsType($event.selected)"
  ></navbar-button>
  <navbar-button
    class="flex"
    [initial]="initialPeriod"
    [tabs]="tsPeriods"
    (selectionChanged)="selectTsPeriod($event.selected)"
  ></navbar-button>
  <p *ngIf="selectedType === CommonRiskMeasures.Volatility" class="help">Ex-ante volatility based on estimated volatility from end-of-day positions</p>
  <div class="chart-wrapper">
    <spinner [name]="spinnerId">
        <div class="spinner-shield"><div class="spinner-indicator" style="top: min( calc(50vh - 140px) 50% )"><span>Loading...</span></div></div>
    </spinner>
  
    <p class="ts-data-empty" *ngIf="tsDataEmpty; else tsRiskChart">(No time series risk data of this type available for this account.)</p>
    <ng-template #tsRiskChart>
      <highcharts-chart *ngIf="chartOptions != null"
      [Highcharts]="Highcharts"
      [callbackFunction]="setChartObj"
      [options]="chartOptions"
      [(update)]="updateChart"
      ></highcharts-chart>
    </ng-template>
  </div>
