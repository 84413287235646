import { Component, Input, OnInit, OnChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import naturalSort from '../../natural-sort';

import { HoldingsOverview, AccountTradingLevel, } from '../../api/webservice.service';
import { AppService } from '../../app-service.service';
import { DataTableColumn } from '../../shared/data-table/data-table-models';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { MarketBrowserTableData, MarketBrowserSubAssetClassItem } from '../market-browser/market-browser-table-data';
import { cmpAssetClasses, cmpSubAssetClassNames, sortSubAssetClassColumn } from '../../account-data/asset-class-sorting';
import { AccountDataService, isIndex } from '../../account-data/account-data.service';

@Component({
  selector: 'app-account-positions',
  templateUrl: './account-positions.component.html',
  styleUrls: ['./account-positions.component.css']
})
export class AccountPositionsComponent implements OnDestroy, OnInit, OnChanges {
  @Input() accountId: number | string;
  @Input() selectedDate: Date;
  @Input() userId: number;
  @Output() selectedMarket = new EventEmitter<number>();

  browserData: MarketBrowserTableData = null;

  assetClassExposures$: Observable<any[]>;
  assetClassExposureData: any[] = [];
  subAssetClassExposures$: Observable<any[]>;
  subAssetClassExposureData: any[] = [];
  productsExposures$: Observable<any[]>;
  productsExposureData: any[] = [];

  assetClassPositionsTableColumns: DataTableColumn[] = [
    { label: 'Asset classes', name: 'assetClassName', type: 'text', cmp: (a, b) => cmpAssetClasses(a, b, null) },
    { label: 'Net', name: 'net', type: 'percent' },
    { label: 'Gross', name: 'gross', type: 'percent' },
  ];
  subAssetClassPositionsTableColumns: DataTableColumn[] = [
    { label: 'Sub asset classes', name: 'subAssetClassName', type: 'text', cmp: cmpSubAssetClassNames },
    { label: 'Net', name: 'net', type: 'percent' },
    { label: 'Gross', name: 'gross', type: 'percent' },
  ];
  productsPositionsTableColumns: DataTableColumn[] = [
    { label: 'Markets', name: 'productName', type: 'text' },
    { label: 'Net', name: 'net', type: 'percentbar' },
  ];

  tableOptions = {
    numberFormat: '1.0',
    sort: {
      subAssetClassName: sortSubAssetClassColumn,
    }
  };

  get destroySubject(): Subject<void> {
    if (this._destroySubject == null) {
      this._destroySubject = new Subject<void>();
    }
    return this._destroySubject;
  }

  private _destroySubject: Subject<void>;
  private _tradingLevel: AccountTradingLevel;

  constructor(private acctDataSvc: AccountDataService, private appService: AppService) {

  }

  getTradingLevelFactors(): [string, number, string] {
    let currencySymbol: string;
    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this._tradingLevel != null) {
      switch (this._tradingLevel.Currency) {
        case 'EUR':
          currencySymbol = '€';
          break;
        case 'USD':
          currencySymbol = '$';
          break;
        default:
          currencySymbol = this._tradingLevel.Currency;
      }

      if (this._tradingLevel.TL > 1000000) {
        scalingFactor = 1 / 1e6;
        magnitudeSymbol = 'M';
      } else if (this._tradingLevel.TL > 1000) {
        scalingFactor = 1 / 1e3;
        magnitudeSymbol = 'K';
      } else {
        scalingFactor = 1;
        magnitudeSymbol = '';
      }
    }
    return [magnitudeSymbol, scalingFactor, currencySymbol];
  }

  loadData() {
    const indexId = Number(this.accountId);
    let holdingOverview$: Observable<HoldingsOverview>;
    const time = moment.tz(this.selectedDate, this.appService.TZ).format('YYYY-MM-DDT00:00:00');
    holdingOverview$ = this.acctDataSvc.getHoldingsOverview$(this.userId, this.accountId, time);
    holdingOverview$.pipe(
      takeUntil(this.destroySubject)
    ).subscribe(
      (hOverview) => {
        let currencySymbol: string;
        let magnitudeSymbol: string;
        let scalingFactor: number;
        if (this.showTradingLevelBasedFigures()) {
          [magnitudeSymbol, scalingFactor, currencySymbol] = this.getTradingLevelFactors();
          scalingFactor *= this._tradingLevel.TL;
          this.assetClassPositionsTableColumns[1].type = 'currency';
          this.assetClassPositionsTableColumns[2].type = 'currency';
          this.subAssetClassPositionsTableColumns[1].type = 'currency';
          this.subAssetClassPositionsTableColumns[2].type = 'currency';
          this.productsPositionsTableColumns[1].type = 'currencybar';
        } else {
          this.assetClassPositionsTableColumns[1].type = 'percent';
          this.assetClassPositionsTableColumns[2].type = 'percent';
          this.subAssetClassPositionsTableColumns[1].type = 'percent';
          this.subAssetClassPositionsTableColumns[2].type = 'percent';
          this.productsPositionsTableColumns[1].type = 'percentbar';
          scalingFactor = 1;
        }

        this.assetClassExposureData = hOverview.AssetClassesExposures.map(acExp => {
          return {
            id: acExp.AssetClass.AssetClassId,
            assetClassName: acExp.AssetClass.Name,
            net: acExp.NetExposure * scalingFactor,
            gross: acExp.GrossExposure * scalingFactor,
            currencySymbol,
            magnitudeSymbol,
          };
        });
        this.assetClassExposureData.sort((a, b) => cmpAssetClasses(a.assetClassName, b.assetClassName, null));

        this.subAssetClassExposureData = hOverview.SubAssetClassesExposures.map(subAcExp => {
          return {
            id: subAcExp.SubAssetClass.Name,
            assetClassId: subAcExp.SubAssetClass.AssetClass.AssetClassId,
            assetClassName: subAcExp.SubAssetClass.AssetClass.Name,
            subAssetClassName: subAcExp.SubAssetClass.Name,
            net: subAcExp.NetExposure * scalingFactor,
            gross: subAcExp.GrossExposure * scalingFactor,
            currencySymbol,
            magnitudeSymbol,
          };
        });
        this.subAssetClassExposureData.sort((a, b) => naturalSort(a.subAssetClassName, b.subAssetClassName));

        // determine max product net exposure for scaling of percentbars
        let max_prod_net = 0;
        this.productsExposureData = hOverview.ProductsExposure.map(prodExp => {
          max_prod_net = Math.max(Math.abs(prodExp.NetExposure), max_prod_net);
          return {
            id: prodExp.Product.ProductId,
            assetClassId: prodExp.Product.SubAssetClass.AssetClass.AssetClassId,
            subAssetClassId: prodExp.Product.SubAssetClass.Name,
            productName: prodExp.Product.LongName,
            net: prodExp.NetExposure * scalingFactor,
            position: prodExp.Position * scalingFactor,
            currencySymbol,
            magnitudeSymbol,
          };
        });
        this.productsExposureData.sort((a, b) => naturalSort(a.productName, b.productName));
        this.productsPositionsTableColumns[this.productsPositionsTableColumns.length - 1].scale = max_prod_net * scalingFactor;

        this.browserData = {
          assetClasses: { columns: this.assetClassPositionsTableColumns, items: this.assetClassExposureData },
          subAssetClasses: { columns: this.subAssetClassPositionsTableColumns, items: this.subAssetClassExposureData },
          products: { columns: this.productsPositionsTableColumns, items: this.productsExposureData },
        };

      }
    );
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
    this._destroySubject = null;
  }

  ngOnInit() {
    this.browserData = {
      assetClasses: { columns: this.assetClassPositionsTableColumns, items: null },
      subAssetClasses: { columns: this.subAssetClassPositionsTableColumns, items: null },
      products: { columns: this.productsPositionsTableColumns, items: null },
    };

    this.acctDataSvc.tradingLevel$.pipe(
      takeUntil(this.destroySubject),
    ).subscribe(
      tl => {
        if (tl != null && tl.AccountId === this.accountId) {
          const oldTL = this._tradingLevel;
          this._tradingLevel = tl;
          if (oldTL == null || oldTL.Date !== tl.Date || oldTL.AccountId !== tl.AccountId) {
            this.ngOnChanges();
          }
        }
      }
    );
  }

  ngOnChanges() {
    if (this.accountId != null && this.userId != null && this.selectedDate != null) {
      this.browserData = {
        assetClasses: { columns: this.assetClassPositionsTableColumns, items: null },
        subAssetClasses: { columns: this.subAssetClassPositionsTableColumns, items: null },
        products: { columns: this.productsPositionsTableColumns, items: null },
      };
      this.loadData();
    }
  }

  selectMarket(prodId: number) {
    this.selectedMarket.emit(prodId);
  }

  showTradingLevelBasedFigures(): boolean {
    return this.acctDataSvc.showTradingLevelBasedFigures(this.userId, this.accountId, this._tradingLevel);
  }
}
