<app-ui-collapsible-section>
<h2 class="subtitle">Positions as of {{selectedDate|date:"dd-MMM-yyyy"}} 
    <app-help-popup>
      <p>Positions are reported in underlying value of positions relative to Account NAV. Gross exposure is the absolute level of exposure. Net exposure is defined as long positions-short positions.</p>
      
      <p>For Currencies, Gross Exposure refers to positions in all currency-pairs. Net exposure includes USD-based positions, where a positive sign indicates a long USD-position.</p>
      
      <p>For Fixed income positions are reported in ten-year equivalent terms.</p>
    </app-help-popup>
  </h2>
  <app-market-browser
    [tableData]="browserData"
    [tableOptions]="tableOptions"
    (selectedMarket)="selectMarket($event)"
    ></app-market-browser>
</app-ui-collapsible-section>