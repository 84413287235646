import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotSandboxOnlyGuard implements CanActivate, CanLoad {
  constructor(
    private _authService: AuthenticationService,
    private _router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._authService.isSandboxOnly$().pipe(
      tap((sandboxOnly) => {
        if (sandboxOnly) {
          this._router.navigate(['/sandbox']);
        }
      }),
      map(sandboxOnly => !sandboxOnly)
    );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.isSandboxOnly$().pipe(
      map(sandboxOnly => !sandboxOnly)
    );
  }
}
