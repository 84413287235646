
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';

import { HttpClientService } from '../http-client.service';
import { AppService } from '../app-service.service';
import { map } from 'rxjs/operators';

export type guid = string;
export type base64binary = string;
export type datetime = string;
export type long = number;
export type int = number;

export class RemoteFileBuffer {
    /* Object model for {http://schemas.datacontract.org/2004/07/PspBackendSharedComponents}RemoteFileBuffer. */
    DocumentId: guid;
    FileData: base64binary;
}


export class RemoteFileInfo {
    /* Object model for {http://schemas.datacontract.org/2004/07/PspBackendSharedComponents}RemoteFileInfo. */
    DisplayName: string;
    DocumentCategory: DocumentCategory;
    DocumentId: guid;
    FileDate: datetime;
    FileExtension: string;
    LenghtBytes: long;
}


export class DocumentCategory {
    /* Object model for {http://schemas.datacontract.org/2004/07/PspBackendSharedComponents}DocumentCategory. */
    CategoryId: int;
    CategoryName: string;
}


export class ArrayOfRemoteFileInfo {
    /* Object model for {http://schemas.datacontract.org/2004/07/PspBackendSharedComponents}ArrayOfRemoteFileInfo. */
    RemoteFileInfo: RemoteFileInfo[];
}

export class DocumentsError extends Error {
    ErrorData: any;
}

function copyObjectDataToInstance(objectData: object, instance) {
    Object.keys(objectData).map(key => {
        const value = objectData[key];
        instance[key] = objectData[key];
    });
    return instance;
}

@Injectable()
export class PspDocumentsService {

    currentDateTime: Date;
    dateChange: Subject<Date> = new Subject<Date>();

    constructor(
        private httpClient: HttpClientService,
        private appService: AppService
    ) {
        this.updateDateTime();
    }

    updateDateTime() {
        this.currentDateTime = new Date();
        this.dateChange.next(this.currentDateTime);
    }


    getDocumentBuffered$(userId: int, documentId: guid): Observable<RemoteFileBuffer> {
        const url = '/api/documents/v1/GetDocumentBuffered/';
        const postData = {
            userId,
            documentId,
        };
        return this.httpClient.post<RemoteFileBuffer>(url, postData).pipe(
            map(data => {
                this.updateDateTime();
                const value: RemoteFileBuffer = new RemoteFileBuffer();
                return copyObjectDataToInstance(data, value);
            }),
        );
    }

    getDocuments$(userId: int): Observable<RemoteFileInfo[]> {
        const url = '/api/documents/v1/Documents/';
        const postData = {
            userId,
        };
        return this.httpClient.post<RemoteFileInfo[]>(url, postData).pipe(
            map(data => {
                this.updateDateTime();
                const value: RemoteFileInfo[] = new Array<RemoteFileInfo>();
                return copyObjectDataToInstance(data, value);
            }),
        );
    }

    getAccountDocuments$(userId: number, accountId: string): Observable<RemoteFileInfo[] | Error> {
        const url = '/api/documents/v1/AccountDocuments/';
        const postData = {
            userId,
            accountId
        };
        return this.httpClient.post<RemoteFileInfo[]>(url, postData).pipe(
            map(data => {
                this.updateDateTime();
                const docInfoList: RemoteFileInfo[] = new Array<RemoteFileInfo>();
                if (!Array.isArray(data)) {
                    const error = new DocumentsError('Invalid response for AccountDocuments');
                    error.ErrorData = data;
                    return error;
                }
                for (const docInfoData of data) {
                    const docInfo = new RemoteFileInfo();
                    copyObjectDataToInstance(docInfoData, docInfo);
                    docInfoList.push(docInfo);
                }
                return docInfoList;
            }),
        );
    }

    getFundDocuments$(userId: number, fundId: number): Observable<RemoteFileInfo[] | Error> {
        const url = '/api/documents/v1/FundDocuments/';
        const postData = {
            userId,
            fundId
        };
        return this.httpClient.post<RemoteFileInfo[]>(url, postData).pipe(
            map(data => {
                this.updateDateTime();
                const docInfoList: RemoteFileInfo[] = new Array<RemoteFileInfo>();
                if (!Array.isArray(data)) {
                    const error = new DocumentsError('Invalid response for FundDocuments');
                    error.ErrorData = data;
                    return error;
                }
                for (const docInfoData of data) {
                    const docInfo = new RemoteFileInfo();
                    copyObjectDataToInstance(docInfoData, docInfo);
                    docInfoList.push(docInfo);
                }
                return docInfoList;
            }),
        );
    }

}

