import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild, TemplateRef, HostBinding, ChangeDetectorRef } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-epp-basic-dropdown',
  templateUrl: './epp-basic-dropdown.component.html',
  styleUrls: ['./epp-basic-dropdown.component.scss']
})
export class EppBasicDropdownComponent implements OnInit {
  public dropdownShown = false;
  scrollStrat: ScrollStrategy;
  @Input() set activeItem$(value: Observable<any> | any) {
    if (value === undefined) {
      return;
    }
    if (value instanceof Observable) {
      this._activeItem$ = value;
    } else {
      this._activeItem$ = of(value);
    }
  }
  get activeItem$(): Observable<any> | any { return this._activeItem$; }
  @Input() set isLoading$(value: Observable<boolean> | boolean) {
    if (value === undefined) {
      return;
    }
    if (value instanceof Observable) {
      this._isLoading$ = value;
    } else {
      this._isLoading$ = of(value);
    }
  }
  get isLoading$(): Observable<boolean> | boolean { return this._isLoading$; }
  @Input() isRight = false;
  @Input() set items$(value: Observable<any[]> | any[]) {
    if (value === undefined) {
      return;
    }
    if (value instanceof Observable) {
      this._items$ = value;
    } else {
      this._items$ = of(value);
    }
  }
  get items$(): Observable<any[]> | any[] { return this._items$; }

  @Output() activateItem = new EventEmitter<Object>();

  private _isLoading$: Observable<any> = EMPTY;
  private _items$: Observable<any[]> = EMPTY;
  private _activeItem$: Observable<any> = EMPTY;

  constructor(
    private el: ElementRef,
    public overlay: Overlay,
    private _changeDet: ChangeDetectorRef,
  ) {
    this.scrollStrat = this.overlay.scrollStrategies.block();
  }

  activate(item: any, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.activateItem.emit(item);
    this.hideDropDown();
  }

  hideDropDown() {
    setTimeout(() => {
      this.dropdownShown = false;
      this._changeDet.markForCheck();
    });
  }

  ngOnInit() {
  }

  toggleDropDown(val?: boolean) {
    setTimeout(() => {
      this.dropdownShown = val !== undefined ? val : !this.dropdownShown;
      this._changeDet.markForCheck();
    });
  }
}
