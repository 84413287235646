import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UiSnackbarOptions, UiSnackbarService } from './ui-snackbar.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-ui-snackbar',
  templateUrl: './ui-snackbar.component.html',
  styleUrls: ['./ui-snackbar.component.scss'],
  animations: [
    trigger('openSnackbar', [
      state('inital', style({
        outline: '5px solid #98330cff',
      })),
      state('open', style({
        outline: '0px solid #98330c00',
      })),
      transition('initial=>open', animate('1000ms')),
      transition('open=>initial', animate('1000ms')),
    ]),
  ]
})
export class UiSnackbarComponent implements OnInit {

  @Input() options: UiSnackbarOptions;
  @Output() actionActivated = new EventEmitter<boolean>();

  animationState = 'initial';

  constructor(private snackbarSvc: UiSnackbarService) {
  }

  clicked() {
    if (this.options != null && this.options.action != null) {
      let output: boolean;
      if (this.options.action.value != null) {
        output = this.options.action.value;
      } else {
        output = true;
      }
      this.actionActivated.emit(output);
      if (this.options.actionCallback != null) {
        this.options.actionCallback(output);
      }
    }
    this.snackbarSvc.hide();
  }

  ngOnInit() {
    this.animationState = 'open';
  }

}
