
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty } from 'lodash';
import { debounce } from 'lodash-decorators';
import naturalSort from '../../natural-sort';
import { sigFigs } from '../utils';
import { DataTableColumn } from './data-table-models';
import { DataTableSortOrder } from './data-table-sort-order';


function sort(obj, sortBy, isAscending, cmp = naturalSort) {
  // inplace sort
  obj.sort(
    (a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      const orderDirection = cmp(valueA, valueB);
      if (isAscending) {
        return orderDirection;
      }
      return -1 * orderDirection;
    }
  );
  return obj;
}


/**
 * Compare items for sorting, by trying to convert the items to numbers.
 * Numbers sort mathematically and before non-numbers. Two non-numbers are sorted with naturalSort.
 */
function numCmp(a: any, b: any) {
  const numA = Number(a);
  const numB = Number(b);

  if (isNaN(numA) && isNaN(numB)) {
    // neither is a number, fall back to naturalSort
    return naturalSort(numA, numB);
  }
  if (isNaN(numA) && !isNaN(numB)) {
    // b is a number, sorts before non-number
    return 1;
  }
  if (!isNaN(numA) && isNaN(numB)) {
    // a is a number, sorts before non-number
    return -1;
  }
  // both numbers, do a mathematical compare
  if (numA === numB) {
    return 0;
  }
  if (numA < numB) {
    return 1;
  }
  if (numA > numB) {
    return -1;
  }
}

interface DataGroup {
  Name: string;
  Items: Object[];
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {

  @Input() public set breadcrumbs(crumbs) {
    this._breadcrumbs = crumbs;
  }
  @Input() public set columns(columns) {
    if (columns === null || columns === undefined) {
      return;
    }
    this._columns = columns;
  }
  @Input() public set items(items) {
    if (items === null || items === undefined) {
      this._items = [];
    }
    this._items = items;
    this.sortItems(undefined, false);

    if (this.options != null && this.options['dataGroups']) {
      this.setupDataGroups();
    }
    if (items != null && items.length > 0) {
      setTimeout(() => {
        const widths = this.getTableColumnWidths();
        if (widths != null) {
          this.columnWidths.emit();
        }
      }, 100);
    }
  }
  @Input() public set maxItems(maxItems) {
    if (maxItems === null || maxItems === undefined) {
      return;
    }
    this._maxItems = maxItems;
  }
  @Input() public set options(options) {
    if (options == null) {
      this._options = {};
    } else {
      this._options = options;
    }

    if ( options && 'id' in options ) {
      this.id_field = this._options['id'];
    }
    if (this.options != null && this.options['dataGroups']) {
      this.setupDataGroups();
    }

    /* Set up sorting */
    if (options['defaultSort'] != null) {
      if (options['defaultSort']['columnName'] != null) {
        const columnName = options['defaultSort']['columnName'];
        let sortByColumn: DataTableColumn | string;
        if (this.columns != null && this.columns[columnName] != null) {
          sortByColumn = this.columns[columnName];
        } else {
          sortByColumn = columnName;
        }
        this.sortItems(sortByColumn, false);
      }
    }
  }
  @Input() set selected(val: Object) {
    if (val == null) {
      this._selected = {};
      return;
    }
    this._selected = val;
  }

  @Output() breadcrumbSelected = new EventEmitter();
  @Output() columnWidths = new EventEmitter<number[]>();
  @Output() marketChartUpdated = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter<DataTableSortOrder>();

  public id_field = 'id';
  public percentbarWidth = 80; // width in pixels of sparkbar
  public showAllBreadcrumbs = false;
  public readonly sigFigs = sigFigs;
  private _breadcrumbs = [];
  private _columns;
  private _collapsedDataGroups = new Set<string>();
  private _dataGroups: DataGroup [];
  private isColumnAscending;
  private _items;
  private _maxItems;
  private _options = {};
  private _selected = {};
  private selectedQueue;
  private sortByColumn;

  get collapsedDataGroups(): Set<string> {
    if (this._collapsedDataGroups != null) {
      return this._collapsedDataGroups;
    } else {
      const newSet = new Set<string>();
      return newSet;
    }
  }

  set collapsedDataGroups(inSet: Set<string>) {
    if (inSet != null) {
      this._collapsedDataGroups = inSet;
    }
  }

  public get breadcrumbs() {
    if (!this.showAllBreadcrumbs) {
      if (this._breadcrumbs != null && this._breadcrumbs.length > 1) {
        return this._breadcrumbs.slice(0, 1);
      }
    }
    return this._breadcrumbs;
  }

  public get columns() {
    return this._columns;
  }

  public get items() {
    return this._items;
  }

  public get options() { return this._options; }

  public get dataGroups() {
    return this._dataGroups;
  }

  get selected() { return this._selected; }

  constructor(private breakpoints: BreakpointObserver, private el: ElementRef) {
    this._breadcrumbs = [];
    this._maxItems = Infinity;
    this._items = [];
    this._columns = [];
    this.selectedQueue = [];
    this.selected = {};
    this.isColumnAscending = {};
    this.sortByColumn = '';
  }

  ngOnInit() {
    this.breakpoints.observe(['(min-width: 769px)', ]).subscribe(
      (state) => {
        if (state.matches) {
          this.percentbarWidth = 167;
          this.showAllBreadcrumbs = true;
        } else {
          this.percentbarWidth = 80;
          this.showAllBreadcrumbs = false;
        }
      }
    );
    this.breakpoints.observe(['(max-width: 400px)', ]).subscribe(
      (state) => {
        if (state.matches) {
          this.percentbarWidth = 60;
        }
      }
    );
  }

  /**
   * Create format string for DecimalPipe based on column/table options
   * @param columnName: name of column to search for format
   */
  numberFormat(columnName: string): string  {
    let format = '1.2-2';

    if (this.options != null && this.options['numberFormat'] != null) {
      format = this.options['numberFormat'];
    }

    if (this.columns[columnName] != null && this.columns[columnName]['numberFormat'] != null) {
      format = this.columns[columnName]['numberFormat'];
    }

    return format;
  }

  /**
   * Sorts table by the value in sortByColumn.
   *
   * @param sortByColumn the column to sort by
   * @param invertOrder use the opposite order as the previously selected (toggle sort evey time column header is clicked)
   */
  sortItems(sortByColumn?: DataTableColumn | string, invertOrder = true) {
    if (isEmpty(sortByColumn)) {
      if (!isEmpty(this.columns) ) {
        sortByColumn = this.columns.find(c => c.name === this.sortByColumn);
        if (sortByColumn == null) {
          return;
        }
      } else {
        return;
      }
    } else {
      if (typeof sortByColumn === 'string') {
        this.sortByColumn = sortByColumn;
        if (!isEmpty(this.columns) ) {
          sortByColumn = this.columns.find(c => c.name === this.sortByColumn);
          
        }
      }
    }
    if (sortByColumn == null || typeof sortByColumn === 'string') {
      return;
    }

    if (this.isColumnAscending[sortByColumn.name] === undefined) {
      if (this.options['defaultSort'] != null
        && sortByColumn.name === this.options['defaultSort']['columnName']
        && this.options['defaultSort']['ascending'] != null
      ) {
        this.isColumnAscending[sortByColumn.name] = this.options['defaultSort']['ascending'];
      } else {
        const isText = this.columns != null && this.columns[sortByColumn.name] != null && this.columns[sortByColumn.name].type === 'text';
        const defaultSortAscending = !isText;
        this.isColumnAscending[sortByColumn.name] = defaultSortAscending;
      }
    } else if (invertOrder) {
      this.isColumnAscending[sortByColumn.name] = !(this.isColumnAscending[sortByColumn.name]);
    }


    // naturalSort is the default
    let cmpFunc = naturalSort;
    if ( ['number', 'percent', 'percentbar'].indexOf(sortByColumn.type) >= 0) {
      // numeric comparison for these column types
      cmpFunc = numCmp;
    }
    if (this.options['cmp'] != null && this.sortByColumn in this.options['cmp']) {
      cmpFunc = this.options['cmp'][this.sortByColumn];
    }
    if (sortByColumn['cmp'] && (typeof(sortByColumn['cmp']) === 'function')) {
      cmpFunc = sortByColumn.cmp;
    }

    let sortFunc = sort;
    if (this.options['sort'] && this.sortByColumn in this.options['sort']) {
      sortFunc = this.options['sort'][this.sortByColumn];
    }
    if (sortByColumn['sort'] && (typeof(sortByColumn['sort']) === 'function')) {
      sortFunc = sortByColumn.sort;
    }

    // Sorting happens in-place
    sortFunc(this._items, sortByColumn.name, this.isColumnAscending[sortByColumn.name], cmpFunc);
    this.sortChanged.emit({columnName: sortByColumn.name, ascending: this.isColumnAscending[sortByColumn.name]});
  }

  isSelected(itemId) {
    if ( this.selected == null || itemId == null ) {
      return false;
    }
    return this.selected[itemId] != null && this.selected[itemId] === 'on';
  }

  checkUnselect(size, itemId, value) {
    if (this._maxItems === Infinity) {
      return;
    }
    if (!value) { // If the event is uncheck
      delete this.selected[itemId];
      this.selectedQueue.splice(this.selectedQueue.indexOf(itemId), 1);
      this.marketChartUpdated.emit(this.selected);
      return;
    }
    if (size > this._maxItems) {
      const item = this.selectedQueue.shift();
      delete this.selected[item];
    }
    if (this._maxItems > 0) {
      this.selectedQueue.push(itemId);
    }
    this.marketChartUpdated.emit(this.selected);
  }

  getTableColumnWidths(): number[] {
    const tableElem = this.el.nativeElement as Element;
    if (tableElem == null) {
      return null;
    }
    const firstDataRow = tableElem.querySelector('tr.item-row');
    if (firstDataRow == null) {
      return null;
    }
    const dataCells = firstDataRow.querySelectorAll('td.data-cell');
    const widths: number[] = [];
    Array.from(dataCells).forEach((cell: HTMLTableDataCellElement) => {
      widths.push(cell.offsetWidth);
    });
    return widths;
  }

  getOptionNegativeColor(column: DataTableColumn, item: any): string {
    return this.getItemOrColumnOption(column, item, 'negativeColor')
      || this.getOptionPositiveColor(column, item);
  }

  getOptionPositiveColor(column: DataTableColumn, item: any): string {
    return this.getItemOrColumnOption(column, item, 'positiveColor');
  }

  getItemOrColumnOption(column: DataTableColumn, item: any, optionId: string): any {
    if (column == null || item == null || item[column.name] == null) {
      return;
    }
    if (item[column.name]['options'] === undefined || item[column.name].options[optionId] === undefined) {
      return column[optionId];
    } else {
      return item[column.name].options[optionId];
    }
  }

  isDataGroupCollapsed(group: DataGroup) {
    return this._collapsedDataGroups.has(group.Name);
  }

  itemSelectionChanged(itemId, value, event: Event = null) {
    if (this._maxItems <= 0) {
      return;
    }
    this.selected[itemId] = value ? 'on' : undefined;

    this.checkUnselect(Object.keys(this.selected).length, itemId, value ? 'on' : undefined);
    this.selectionChanged.emit(this.selected);

    if (event) {
      event.stopPropagation();
    }
  }

  numSelectedInDataGroup(group: DataGroup): number {
    const found = this._dataGroups.find(grp => grp.Name === group.Name);
    if (found) {
      return found.Items.reduce<number>(
        (acc: number, item: Object): number => acc + (this.isSelected(item[this.id_field]) === true ? 1 : 0)
      , 0);
    } else {
      return 0;
    }
  }

  onBreadcrumbButtonClicked(event: MouseEvent, type: string, id: string) {
    event.stopPropagation();
    this.breadcrumbSelected.emit({type: type, id: id});
  }

  setupDataGroups() {
    if ( this.options != null && this.options['dataGroups'] != null && this.items != null && this.items.length > 0 ) {
      const newDataGroups = [];

      // copy set
      const newCollapsedGrps = new Set<string>();

      // this._collapsedDataGroups.clear();
      for (const dgOpt of this.options['dataGroups']) {
        const newDg = { Name: dgOpt['Name'], Items: [] };

        // if data group options object has Collapsed set to true, add it to collapsedDataGroups
        if (dgOpt['Collapsed'] != null && dgOpt['Collapsed'] === true) {
          newCollapsedGrps.add(newDg.Name);
        }

        for (const id of dgOpt['Items']) {
          const found = this.items.find(item => item[this.id_field] === id);
          if (found != null) {
            newDg['Items'].push(found);
          }
        }

        if (newDg['Items'].length > 0) {
          newDataGroups.push(newDg);
        }
      }
      this.collapsedDataGroups = newCollapsedGrps;
      this._dataGroups = newDataGroups;
    }
  }

  @debounce(100)
  toggleDataGroupVis(group: DataGroup) {
    const newCollapsedGrps = new Set(this.collapsedDataGroups);
    const dgname = group.Name;
    if (newCollapsedGrps.has(dgname)) {
      newCollapsedGrps.delete(dgname);
    } else {
      newCollapsedGrps.add(dgname);
    }
    this.collapsedDataGroups = newCollapsedGrps;
  }
}
