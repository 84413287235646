import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positive'
})
export class PositivePipe implements PipeTransform {

  /**
   * Restrict to positive numbers. If value is less than zero return 0, else value
   * @param value - the number to check
   * @param args - unused
   */
  transform(value: string | number, args?: any): any {
    const num = Number(value);
    if (isNaN(num)) {
      return value;
    }
    return Math.max(num, 0);
  }

}
