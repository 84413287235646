import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject, Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, tap, take, } from 'rxjs/operators';

import { HttpClientService, AccessDenied } from '../http-client.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { datetime } from '@app/api/psp-backend.models';
import { AppRefreshService } from '@app/app-refresh.service';

export class User {
  userName: string;
  email: string;
  lastName: string;
  firstName: string;
  organization: string;
  permissions: string[];
  termsAccepted: datetime;
  userId: number;
  url: string;

  public static userFromObject(data: object): User {
    const user: User = new User();
    Object.keys(data).forEach(key => {
      const value = data[key];
      user[key] = data[key];
    });
    return user;
  }
}

interface LogoutStatus {
  status: string | Error;
}

export const SANDBOX_ONLY_USER_LIST = [
  'jani.laitinen@jamadvisors.fi',
  'jonatan.lehtinen@jamadvisors.fi',
  'mikael.lofberg@jamadvisors.fi',
];

@Injectable()
export class AuthenticationService implements OnDestroy {
  private _isLoggedIn = false;
  private _isLoggedInSubj: BehaviorSubject<boolean>;
  private canAccessSandbox: boolean;
  private canAccessSandboxCache$: Observable<boolean>;
  private user: User = null;
  private userCache$: Observable<User> = null;
  private _userSubj: ReplaySubject<User>;
  private _canAccessSandboxCache: boolean;
  private _canAccessSandboxProtoCache: boolean;

  constructor(
    private httpClient: HttpClientService,
    private logger: NGXLogger,
    private router: Router,
    private _refresh: AppRefreshService,
  ) { }

  acceptTerms$() {
    return this.httpClient.post<{status: string, termsAccepted: datetime }>('/api/v1/agree-terms/', {agree_terms: true}).pipe(
      map((response) => {
        if (response instanceof Error) {
          this.logger.error('Failed to update user with terms & conditions acceptance.', response);
          throw response;
        }
        return response.termsAccepted;
      }),
      tap((response) => {
        if (response) {
          this.user.termsAccepted = response;
        }
      }),
    );
  }

  set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
    try {
      window.localStorage.setItem('auth.isLoggedIn', value ? 'true' : 'false');
    } catch (error) {
      // quota exceeded
    }
    if (this._isLoggedInSubj != null) {
      this._isLoggedInSubj.next(value);
    } else {
      this._isLoggedInSubj = new BehaviorSubject(value);
    }
  }

  get isLoggedIn(): boolean {
    const value = window.localStorage.getItem('auth.isLoggedIn') === 'true';
    if (value === undefined) {
      return this._isLoggedIn;
    }
    return value;
  }

  get isLoggedIn$() {
    if (this._isLoggedInSubj == null) {
      this._isLoggedInSubj = new BehaviorSubject(this.isLoggedIn);
    }
    return this._isLoggedInSubj.asObservable();
  }

  getCurrentUser(stream = false): Observable<User> {
    if (this._userSubj == null) {
      this._userSubj = new ReplaySubject<User>();
      this.fetchCurrentUser().pipe(
        tap(user => {
          this.user = user;
          this.userCache$ = null;
        }),
        catchError((err) => {
          if ( err instanceof AccessDenied || err.status === 403 ) {
            this.isLoggedIn = false;
            this._refresh.refresh();
            this.router.navigate(['/login/']);
          } else {
            this.logger.error('Cannot load user', err);
          }
          return throwError('Current user invalid.');
        }),
      ).pipe(take(1)).subscribe((user) => {
        this.user = user;
        if (this._userSubj != null && !this._userSubj.isStopped) {
          this._userSubj.next(user);
        }
      });
    }
    if (stream) {
      return this._userSubj.asObservable();
    }
    return this._userSubj.asObservable().pipe(take(1));
  }

  isSandboxOnly$(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map(user => SANDBOX_ONLY_USER_LIST.includes(user.userName)),
    );
  }

  login(username, password, rememberMe): Observable<User> {
    const postData = {
      username: username,
      password: password,
      rememberMe: rememberMe,
    };
    return this.httpClient.post<User>('/api/v1/login/', postData).pipe(
      map(this.makeUserFromResponse),
      tap(user => {
        // this._refresh.refresh();
        this.isLoggedIn = true;
        this._userSubj.complete();
        this._userSubj = null;
        this.getCurrentUser().subscribe();
      }),
      // flatMap(() => this.getCurrentUser()),
      // finalize(() => setTimeout(() => this.resetCsrfToken())),
    );
  }

  logout(autoLogout = false) {
    if (autoLogout && this.isLoggedIn === false) {
      return false;
    }
    this.httpClient.post<LogoutStatus>('/api/v1/logout/', null).pipe(
      catchError((err: Error) => of({ status: err.message } as LogoutStatus)),
      map(response => {
        return (<LogoutStatus>response).status;
      }),
      tap(response => {
        if (response === 'OK') {
          this._reset();
          document.cookie = `sessionid=;expires=${new Date(0).toUTCString()}`;
        }
      }),
    ).subscribe(
      (status) => {
        if ( status === 'OK') {
        this.logger.info('Logged out');
        this.resetCsrfToken();
        // this._refresh.refresh();
        window.location.reload();
        return true;
      } else {
        this.logger.error(status);
        return false;
      }
    });
  }

  ngOnDestroy(): void {
    this._reset();
  }

  resetCsrfToken() {
    this.httpClient.resetCsrfToken();
  }

  sandboxAllowed(): Observable<boolean> {
    if (this._canAccessSandboxCache === undefined) {
      return this.fetchCanAccessSandbox().pipe(
        tap((hasPermission) => this._canAccessSandboxCache === hasPermission)
      );
    } else {
      return of (this._canAccessSandboxCache);
    }
  }

  sandboxProtoAllowed(): Observable<boolean> {
    if (this._canAccessSandboxProtoCache === undefined) {
      return this.getCurrentUser().pipe(
        map(user => user.permissions.includes('access_sandbox_proto')),
        tap((hasPermission) => this._canAccessSandboxProtoCache === hasPermission)
      );
    } else {
      return of(this._canAccessSandboxProtoCache);
    }
  }

  private fetchCurrentUser(): Observable<User> {
    return this.httpClient.get('/api/v1/current-user/').pipe(
      map(this.makeUserFromResponse),
    );
  }

  private fetchCanAccessSandbox(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      flatMap(
        user => this.httpClient.get<boolean>('/api/v1/can-access-sandbox/').pipe(
          catchError(
            (err, src) => {
              if (!(err instanceof AccessDenied)) {
                this.logger.error(`[${user.userId}] Cannot fetch sandbox access permission`, err);
              }
              return of(false);
            },
          ),
          map(response => response as boolean),
        ),
      ),
      tap(val => { this.canAccessSandbox = val; }),
    );
  }

  private makeUserFromResponse(response: any) {
    return User.userFromObject(response);
  }

  /** Wipe out all cached data */
  private _reset() {
    if(typeof this._userSubj == undefined){
    this._userSubj.complete()}else {this.logger.error('_userSubj is undefined')};
    this._userSubj = null;
    this.user = null;
    this.userCache$ = null;
    this.canAccessSandbox = null;
    this.canAccessSandboxCache$ = null;
    if(typeof this._isLoggedInSubj == undefined){
    this._isLoggedInSubj.complete()}else {this.logger.error('_isLoggedInSubj is undefined')};
    this._isLoggedInSubj = null;
    this._isLoggedIn = null;
  }
}
