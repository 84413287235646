import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { retryBackoff } from 'backoff-rxjs';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';



export class AccessDenied implements Error {
  public name: string;
  public message: string;

  constructor(message?: string, name: string = 'Access denied') {
    this.message = message;
    this.name = name;
  }
}

export class AuthenticationFailed extends AccessDenied {
  public set_name: string;
  public message: string;

  constructor(message?: string, name: string = 'Authentication failure') {
    super(message, name);
  }
}

@Injectable()
export class HttpClientService {

  // private _isLoading: boolean = false;

  private _isLoading = false;
  private _csrfToken: { expires: number, data: string };
  private _progressRef: NgProgressRef;

  private defaultHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
      });

  constructor(
    private client: HttpClient,
    private logger: NGXLogger,
    private ngProgress: NgProgress,
  ) {
    this._progressRef = this.ngProgress.ref('app-http');
  }

  get<T>(url, extraHeaders?: HttpHeaders): Observable<T | Error> {
    this.setIsLoading(true);
    const options = this.buildHeaders(extraHeaders);
    return this.client.get<T>(url, options).pipe(
        catchError((err) => this.handleError(err)),
        tap(() => {
            this.setIsLoading(false);
        }),
    );
  }

  delete(url, extraHeaders?): Observable<void> {
    this.setIsLoading(true);
    return this.getCsrfToken().pipe(
      switchMap((csrftoken) => {
        const options = this.buildHeaders(extraHeaders, csrftoken);
        options['observe'] = 'response';
        return this.client.delete<void>(url, options).pipe(
          catchError((err) => {
            this.handleError(err);
            throw err;
          }),
          tap(() => {
            this.setIsLoading(false);
          }),
        );
      })
    );
  }

  post<T>(url, postData, options?: object): Observable<T | Error> {
    this.setIsLoading(true);
    return this.getCsrfToken().pipe(
      switchMap(csrftoken => {
        const headerOptions = this.buildHeaders(options ? options['headers'] : null, csrftoken);
        if (options) {
          options['headers'] = headerOptions['headers'];
        } else {
          options = headerOptions;
        }
        return this.client.post<T>(url, postData, options).pipe(
          delay(1000),
          catchError((err, caught) => this.handleError(err)),
          tap(() => {
            this.setIsLoading(false);
          }),
        );
      }),
    );
  }

  patch<T>(url, dataParams, extraHeaders?): Observable<Error | T> {
    this.setIsLoading(true);
    return this.getCsrfToken().pipe(
      switchMap(csrftoken => {
        const options = this.buildHeaders(extraHeaders, csrftoken);
        return this.client.patch<T>(url, dataParams, options).pipe(
          tap(() => {
            this.setIsLoading(false);
          }),
          catchError((err, caught) => this.handleError(err))
        );
      }),
    );
  }

  put<T>(url, dataParams, extraHeaders?): Observable<Error | T> {
    this.setIsLoading(true);
    return this.getCsrfToken().pipe(
      switchMap(csrftoken => {
        const options = this.buildHeaders(extraHeaders, csrftoken);
        return this.client.put<T>(url, dataParams, options).pipe(
          tap(() => {
            this.setIsLoading(false);
          }),
          catchError((err, caught) => this.handleError(err))
        );
      }),
    );
  }


  handleError(error: Error | any): Observable<Error> {
    // TODO: proper error handling
    this.setIsLoading(false);
    if ('status' in error && error.status === 403) {
      if (error['detail'] === 'CSRF Failed: CSRF token missing or incorrect.' ) {
        this.resetCsrfToken();
      }
      if ('error' in error && error.error != null) {
        if (error.error['detail'] === 'Login failed: Invalid credentials.') {
          return throwError(new AuthenticationFailed(error.error['detail'], 'Bad credentials'));
        }
        if (error.error['detail'] === 'Authentication credentials were not provided.' ) {
          return throwError(new AuthenticationFailed(error.error['detail'], 'Missing credentials'));
        }
      }
      return throwError(new AccessDenied());
    }
    return throwError(error);
  }


  isLoading(): boolean {
    return this._isLoading;
  }

  getCsrfToken(): Observable<string> {
    const url = '/api/v1/csrf-token/';
    const now = Date.now();
    let token$ : Observable<string>;
    if (this._csrfToken == null || this._csrfToken.expires < now) {
      token$ = this.client.post<{csrftoken: string}>(url, {}, {headers: this.defaultHeaders}).pipe(
        map(response => response.csrftoken),
        retryBackoff({
          initialInterval: 250,
          maxRetries: 10,
          shouldRetry: (error) => 'code' in error && error.code >= 500,
        }),
        tap(token => {
          this._csrfToken = { data: token, expires: now + 60 * 1000 };
          this.logger.setCustomHttpHeaders(new HttpHeaders({
            'X-CSRFToken': token
          }));
        }),
      );
    } else {
      token$ = of(this._csrfToken.data);
    }
    return token$;
  }

  resetCsrfToken() {
    this.logger.debug('Wiping CSRF token.');
    this._csrfToken = null;
    // this._csrfTokenSubj.complete();
    // this._csrfTokenSubj = null;
  }

  private buildHeaders(extraHeaders?: HttpHeaders, csrfToken?: string): { headers: HttpHeaders } {
    let headers = this.defaultHeaders;
    if (extraHeaders) {
      for (const key of extraHeaders.keys()) {
          headers = headers.set(key, extraHeaders.get(key));
      }
    }
    if (csrfToken) {

        headers = headers.set('X-CSRFToken', csrfToken);
    }
    return { headers };
  }

  private setIsLoading(value: boolean) {
    this._isLoading = value;
    if ( value ) {
      this._progressRef.start();
    } else {
      this._progressRef.complete();
    }
  }

}
