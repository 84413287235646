 import { Injectable } from '@angular/core';

import { Observable, Subject, ReplaySubject, BehaviorSubject, forkJoin , throwError } from 'rxjs';
import { of, combineLatest, concat } from 'rxjs';
import {
  catchError,
  concatMap,
  flatMap,
  filter,
  finalize,
  map,
  mergeAll,
  mergeMap,
  take,
  tap,
  switchMap,
  share,
  takeUntil,
  delay,
  publish,
  refCount,
} from 'rxjs/operators';
import naturalSort from './natural-sort';
import * as moment from 'moment-timezone';
import { cloneDeep, isArray, isEmpty, merge as objMerge } from 'lodash';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { AuthenticationService, User } from './auth/authentication.service';
import {
  PspWebService,
  CombinedIndex,
  Index,
  IndexPerformanceInfo,
  DataFrequency,
  int,
  CorrelationPoint,
  MemberIndexWeight,
  IndexDateRange,
  DATA_FREQ_ID,
  INDEX_TYPE_ID,
  FUNDING_TYPE_ID,
} from './api/webservice.service';

export { DATA_FREQ_ID, FUNDING_TYPE_ID, INDEX_TYPE_ID };

import { AppService } from './app-service.service';
import { HttpClientService } from './http-client.service';

import { PortfolioSet } from './sandbox/models/portfolio-set';
import { SandboxAnalyticsUIUpdate } from './sandbox/models/sandbox-analytics-ui-update';
import { SandboxCombinedIndexMeta } from './sandbox/models/sandbox-combined-index-meta';
import { SandboxPortfolioInfo } from './sandbox/models//sandbox-portfolio-info';
import { SandboxPortfolioCorrelationInfo } from './sandbox/models/sandbox-portfolio-correlation-info';
import { SandboxPortfolioMetaResponse } from './sandbox/models/sandbox-portfolio-meta-response';
import { LoggingService } from './utils/logging/logging.service';
import { beginningOfTime } from './account-overview/utils';
import { retryBackoff } from 'backoff-rxjs';
import { validPortfolioName, validPortfolioSetName, } from '@shared/sandbox-utils';
import { SandboxPortfolioMetadata } from './sandbox3/models/sandbox-portfolio-metadata';

export { validPortfolioName, validPortfolioSetName };

function escapeHTML(unsafeText: string) {
  const div = document.createElement('div');
  div.innerText = unsafeText.replace(/&€\$!\(\)+\-\/\*\\=\?@/g, function(m) {
    switch (m) {
      case '&':
        return '&amp;';
      case '€':
        return '&euro;';
      case '$':
        return '&#36;';
      case '!':
        return '&#33;';
      case '(':
        return '&#40;';
      case ')':
        return '&#41;';
      case '+':
        return '&#43;';
      case '-':
        return '&#45;';
      case '/':
        return '&#47;';
      case '*':
        return '&#43;';
      case '\\':
        return '&#92;';
      case '=':
        return '&#61;';
      case '?':
        return '&#63;';
      case '@':
        return '&#64;';
      default:
        return '&#039;';
    }
  });
  return div.innerHTML;
}


// from MDN: encodeURIComponent
function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'\(\)\.*_-]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

/** Truncate string to 250 chars and add an ellipsis if truncated */
export function truncIndexDescription(description: string) {
  if (description.length > 250) {
    description = description.substr(0, 250);
    if (!(description.endsWith('...') || description.endsWith('…'))) {
      description.concat('…');
    }
  }
  return description;
}

@Injectable()
export class SandboxDataService {
  public activeDataFrequencyId = DATA_FREQ_ID.MONTHLY;
  public blockValueChart$: Observable<boolean>;

  // unmodified copies of portfolios loaded by SandboxPortfolioAllocationComponent.replacePortfolio
  public _portfolioOrigs: CombinedIndex[] = [];

  isPortfolioSetLoadingSubj = new BehaviorSubject<boolean>(false);
  isPortfolioSetSavingSubj = new BehaviorSubject<boolean>(false);
  isPortfolioSetStarringSubj = new BehaviorSubject<boolean>(false);
  isPortfolioSetToolbarVisibleSubj = new BehaviorSubject<boolean>(true);
  newSandboxCalculation: boolean;

  public get combinedIndecis$(): Observable<CombinedIndex[]> {
    if (this._combinedIndecis) {
      // if we've already fetched it use the cached copy
      return of(this._combinedIndecis);
    } else {
      // get indecis from ws
      return this.user$.pipe(
        take(1),
        // fetch all indecis in sandbox
        flatMap(user => this.ws.getSandboxIndices(user.userId).pipe(
          // filter out non-CombinedIndex indecis
          map(indecis => indecis.filter(index => index.IndexType.IndexTypeId === INDEX_TYPE_ID.CombinedIndex)),
          // abort if no indecis left
          filter(indecis => indecis.length > 0),
          // fetch CombinedIndex info
          mergeMap(indecis => {
            const arrayOfObservables = indecis.map(index => this.getCombinedIndex(user.userId, index.IndexId));
            return combineLatest(arrayOfObservables);
          })
        )),
        tap(combinedIndecis => {
          // cache combined indecis
          this._combinedIndecis = combinedIndecis;
          this._activePortfolioInfos = null;
        })
      );
    }
  }

  public savingPortfolioSubj: BehaviorSubject<int>;
  public savingPortfolio$: Observable<int>;
  public savingPortfolioStatus$: Observable<{
    position: number,
    status: 'saving' | 'success' | 'failed',
  }>;
  public selectedIndecis$: Observable<Index[]>;
  public showAssetSelection$: Observable<SandboxAnalyticsUIUpdate>;
  public showPortfolioAllocation$: Observable<SandboxAnalyticsUIUpdate>;
  public showPortfolioStatistics$: Observable<SandboxAnalyticsUIUpdate>;
  public showRiskAllocationCharts$: Observable<SandboxAnalyticsUIUpdate>;
  public starringPortfolioSubj: BehaviorSubject<int>;
  public starringPortfolio$: Observable<int>;
  public statisticsAreLoading$: Observable<boolean>;


  private _activePortfolioInfos: SandboxPortfolioCorrelationInfo[];
  private _activePortfolioInfos$: Observable<SandboxPortfolioCorrelationInfo[]>;
  private _activePortfolioInfosSubj: Subject<SandboxPortfolioCorrelationInfo[]>;
  private _availablePortfolios: SandboxCombinedIndexMeta[];
  private _availablePortfolios$: Observable<SandboxCombinedIndexMeta[]>;
  private _availablePortfoliosSubj: ReplaySubject<SandboxCombinedIndexMeta[]>;

  /** Prevent zooming on value chart while loadSandboxStatistics is running */
  private _blockValueChartSubj = new BehaviorSubject<boolean>(false);

  /** Used to cancel loadSandboxPerformance when a new update is requested*/
  private _cancelStatsUpdateSubj =  new Subject<void>();
  private _combinedIndecis: CombinedIndex[];
  private _combinableIndices: Index[];
  private _combinedIndexCache = new Map<int, CombinedIndex>();
  private _dataFrequencies: DataFrequency[] = [];
  private _dataFrequencies$: Observable<DataFrequency[]>;
  private _portfolioSetListCache: PortfolioSet[];
  private _portfolioSetListSubj = new ReplaySubject<PortfolioSet[]>(1);
  private _progressRef: NgProgressRef;
  private _sandboxPortfolioMetadataUrl = '/api/v1/sandbox-portfolio-metadata/';
  private _sandboxPortfolioSetUrl = '/api/v1/sandbox-portfolio-set/';
  private _savingPortfolioStatusSubj = new Subject<{position: number, status: 'saving' | 'success' | 'failed' }>();
  private _selectedIndicesList: Index[] = [];
  private _selectedIndecis: ReplaySubject<Index[]>;
  private _showAssetSelectionBacking = new SandboxAnalyticsUIUpdate();
  private _showAssetSelection: Subject<SandboxAnalyticsUIUpdate>;
  private _showPortfolioAllocationBacking = new SandboxAnalyticsUIUpdate();
  private _showPortfolioAllocation: Subject<SandboxAnalyticsUIUpdate>;
  private _showPortfolioStatistics: Subject<SandboxAnalyticsUIUpdate>;
  private _showPortfolioStatisticsBacking: SandboxAnalyticsUIUpdate;
  private _showRiskAllocationChartsSubj: Subject<SandboxAnalyticsUIUpdate>;
  private _showRiskAllocationChartsBacking: SandboxAnalyticsUIUpdate;
  private _statisticsAreLoadingSubj: Subject<boolean>;
  private _starringPortfolio: int;
  private _temporaryIndices: Set<CombinedIndex> = new Set();
  private _user$: Observable<User>;
  private _user: User;


  constructor(
    private appService: AppService,
    private authService: AuthenticationService,
    private httpClient: HttpClientService,
    private logger: LoggingService,
    private ngProgress: NgProgress,
    private notificationsService: NotificationsService,
    private ws: PspWebService,
  ) {
    this._user$ = this.authService.getCurrentUser().pipe(
      tap(user => this._user = user)
    );

    this._activePortfolioInfosSubj = new Subject<SandboxPortfolioCorrelationInfo[]>();
    this._activePortfolioInfos$ = this._activePortfolioInfosSubj.asObservable();
    this._availablePortfoliosSubj = new ReplaySubject<SandboxCombinedIndexMeta[]>(1);

    this.blockValueChart$ = this._blockValueChartSubj.asObservable();

    this._dataFrequencies$ = this.user$.pipe(
      flatMap(user => this.ws.getDataFrequencies(user.userId)),
      tap<DataFrequency[]>(freqs => this._dataFrequencies = freqs),
    );
    this.savingPortfolioSubj = new BehaviorSubject<int>(null);
    this.savingPortfolio$ = this.savingPortfolioSubj.asObservable().pipe(share());
    this.savingPortfolioStatus$ = this._savingPortfolioStatusSubj.asObservable();
    this._selectedIndecis = new ReplaySubject<Index[]>(1);
    this.selectedIndecis$ = this._selectedIndecis.asObservable();
    this._showAssetSelection = new Subject<SandboxAnalyticsUIUpdate>();
    this.showAssetSelection$ = this._showAssetSelection.asObservable();
    this._showPortfolioAllocation = new Subject<SandboxAnalyticsUIUpdate>();
    this.showPortfolioAllocation$ = this._showPortfolioAllocation.asObservable();
    this._showPortfolioStatistics = new Subject<SandboxAnalyticsUIUpdate>();
    this.showPortfolioStatistics$ = this._showPortfolioStatistics.asObservable();
    this._showRiskAllocationChartsSubj = new Subject<SandboxAnalyticsUIUpdate>();
    this.showRiskAllocationCharts$ = this._showRiskAllocationChartsSubj.asObservable();
    this._statisticsAreLoadingSubj = new Subject<boolean>();
    this.statisticsAreLoading$ = this._statisticsAreLoadingSubj.asObservable();
    this.starringPortfolioSubj = new BehaviorSubject<int>(null);
    this.starringPortfolio$ = this.starringPortfolioSubj.asObservable().pipe(
      tap(val => this._starringPortfolio = val)
    );

    this._progressRef = this.ngProgress.ref('sandbox-progress');

  }

  public get user$(): Observable<User> {
    if (this._user != null) {
      return of(this._user);
    } else {
      return this._user$;
    }
  }

  public get activePortfolios$(): Observable<SandboxPortfolioCorrelationInfo[]> {
    return this._activePortfolioInfos$;
  }

  addSelectedIndex(id: string) {
    if (this._selectedIndicesList.find(index => index.IndexId.toString() === id)) {
      return;
    } else {
      this.getCombinableIndices().subscribe(indecis => {
        const newIndex = indecis.find(index => index.IndexId.toString() === id);
        if (newIndex) {
          const newSelIndList = this._selectedIndicesList.slice();
          newSelIndList.push(newIndex);
          this._selectedIndicesList = newSelIndList;
          this.triggerSelectedIndicesUpdate();
        } else {
          // handle error
          this.logger.error(`Tried to add index ${id} but it does not exist.`);
        }
      });
    }
  }

  cancelStatsUpdate() {
    this._cancelStatsUpdateSubj.next();
  }

  deletePortfolio(indexMeta: SandboxCombinedIndexMeta): Observable<SandboxCombinedIndexMeta> {
    let toBeDeleted = indexMeta;
    let delPos;
    if (this._availablePortfolios != null) {
      delPos = this._availablePortfolios.findIndex(
        portfolio => portfolio.CombinedIndex.Index.IndexId === indexMeta.CombinedIndex.Index.IndexId
      );
      if (delPos >= 0) {
        toBeDeleted = cloneDeep(this._availablePortfolios[delPos]);
      }
    }

    // Delete from backend
    const deleteNotification = new Subject<SandboxCombinedIndexMeta>();
    const deleteRequest$ = this.httpClient.delete(
      this.getSandboxCombinedIndexMetaUrl(toBeDeleted)
    ).pipe(
      map(
        response => {
          return toBeDeleted;
        }
      ),
      delay(1000 / 60), // delay a tick so StateHelper has time to attach state update
    );
    deleteRequest$.subscribe(
      response => {
        deleteNotification.next(toBeDeleted);
        if (response != null && delPos != null) {
          // Delete from frontend
          this.getAvailablePortfolios$().pipe(take(1)).subscribe(available => {
            const newAvail = cloneDeep(available);
            newAvail.splice(delPos, 1);
            this._availablePortfolios = newAvail;
            this._availablePortfoliosSubj.next(newAvail);
          });
        }
        this.notificationsService.success('Portfolio deleted', `Successfully deleted portfolio “${toBeDeleted.CombinedIndex.Index.Name}”`);
      },
      (err) => deleteNotification.next(null),
      () => deleteNotification.complete()
    );
    return deleteNotification.asObservable();
  }

  public clear() {
    this._selectedIndicesList = [];
  }

  unselectIndices(indexIds: number | number[]) {
    if ( indexIds == null || isArray(indexIds) && indexIds.length === 0 ) {
      // reject empty input
      return;
    }
    if (!isArray(indexIds)) {
      // 'fix' singleton input
      indexIds = [indexIds];
    }

    const selectedIndecis = this._selectedIndicesList.slice();

    for (const id of indexIds) {
      const idxPos = selectedIndecis.findIndex(index => index.IndexId === id);
      if (idxPos < 0) {
        continue;
      } else {
        selectedIndecis.splice(idxPos, 1);
      }
    }
    this._selectedIndicesList = selectedIndecis;
    this.triggerSelectedIndicesUpdate();
  }

  public getAvailablePortfolios$(refresh = false): Observable<SandboxCombinedIndexMeta[]> {
    if (refresh || this._availablePortfolios$ == null) {
      this.logger.debug('Fetching available portfolios from server');
      // Fetch CombinedIndex data from backend and combine with metadata
      this._availablePortfolios$ = this.httpClient.get<SandboxPortfolioMetaResponse[]>(this._sandboxPortfolioMetadataUrl).pipe(
        map(result => {
          if (result instanceof Error) {
            return [];
          } else {
            return result;
          }
        }),
        concatMap((metaList) => {
            if (metaList.length === 0) {
              return of<CombinedIndex[]>([]);
            }
            // combine CombinedIndexes into one list
            return combineLatest(metaList.map(
              // fetch CombinedIndex from backend
              metaItem => this.getCombinedIndex(metaItem.userId, metaItem.combinedIndexId)
            ));
          },
          // build SandboxCombinedIndexMeta objects
          (metaList, ciList) => {
            const finalList: SandboxCombinedIndexMeta[] = [];
            for (let i = 0; i < metaList.length; i++) {
              const meta = metaList[i];
              const ci = ciList[i];
              finalList.push(new SandboxCombinedIndexMeta({
                Id: meta.id,
                CombinedIndex: ci,
                LastModifiedDate: meta.lastModifiedDate,
                Starred: meta.starred,
                Hidden: meta.hidden,
              } as SandboxCombinedIndexMeta));
            }
            return finalList;
          }
        ),
        tap(metaIndexList => this._availablePortfolios = metaIndexList)
      );
      this._availablePortfolios$.pipe(take(1)).subscribe(
        (result) => this._availablePortfoliosSubj.next(result),
        (error) => this._availablePortfoliosSubj.error(error),
      );
    }

    return this._availablePortfoliosSubj.asObservable();
  }

  getPortfolioSetList$(refresh = false): Observable<PortfolioSet[]> {
    if (refresh || this._portfolioSetListCache == null) {
      this.httpClient.get<PortfolioSet[]>('/api/v1/sandbox-portfolio-set/').pipe(
        take(1),
        catchError((err, caught) => {
          this.logger.error('Cannot load portfolio sets', err);
          return of(undefined);
        }),
        map<PortfolioSet[], PortfolioSet[]>(setList => {
          if (setList == null || !Array.isArray(setList)) {
            this.logger.error('getPortfolioSetList$(): invalid data recieved from /api/v1/sandbox-portfolio-set/', setList);
            throw new Error('Invalid data recieved from server');
          }
          return setList.map(set => {
            if (!(set.lastModifiedDate instanceof Date)) {
              set.lastModifiedDate = moment.tz(set.lastModifiedDate, 'Europe/Helsinki').toDate();
            }
            return set;
          });
        }),
        tap(() => {
          this.isPortfolioSetLoadingSubj.next(false);
        }),
      ).subscribe(
        setList => this._portfolioSetListSubj.next(setList),
        error => this._portfolioSetListSubj.error(error),
      );
    } else {
      setTimeout(() => this._portfolioSetListSubj.next(this._portfolioSetListCache));
    }
    return this._portfolioSetListSubj.asObservable();
  }

  // Tries to decode URI encoded CombinedIndex.Index.Name, if it is encoded
  decodeCombinedIndexName(ci: CombinedIndex): CombinedIndex {
    let decodedName: string;
    try {
      // try decoding
      decodedName = decodeURIComponent(ci.Index.Name);
    } catch (e) {
      // fallback to using the original value
      decodedName = ci.Index.Name;
    }
    ci.Index.Name = decodedName;
    return ci;
  }

  deletePortfolioSet(theSet: PortfolioSet): Observable<boolean> {
    if (theSet.id == null) {
      return of(false);
    }
    return this.httpClient.delete(`${this._sandboxPortfolioSetUrl}${theSet.id}/`).pipe(
      catchError((err, caught) => {
        this.logger.error('Could not delete portfolio sets', err);
        return of(undefined);
      }),
      tap((deleted: boolean) => {
        if (deleted) {
          this.getPortfolioSetList$();
          this.notificationsService.success('Portfolio set deleted', `Deleted portfolio set “${theSet.name}”.`);
        }
      }),
    );
  }

  getCombinedIndex(userId: number, combinedIndexId: number, refresh?: boolean): Observable<CombinedIndex> {
    const lsCacheId = `CombinedIndex__u${userId}__i${combinedIndexId}`;
    if (!refresh) {
      if (this._combinedIndexCache.has(combinedIndexId)) {
        return of(this._combinedIndexCache.get(combinedIndexId));
      } else {
        const cached = localStorage.getItem(lsCacheId);
        if (cached != null) {
          try {
            const decoded = JSON.parse(cached) as CombinedIndex;
            const newCI = new CombinedIndex();
            return of(objMerge(newCI, decoded));
          } catch (err) {
            if (err instanceof SyntaxError) {
              // Invalid JSON. Ignore value.
            } else {
              this.logger.warn('Error retrieving CombinedIndex from cache', err);
            }
          }
        }
      }
    }
    return this.ws.getCombinedIndex(userId, combinedIndexId).pipe(
      map(ci => this.decodeCombinedIndexName(ci)),
      tap(ci => {
        this._combinedIndexCache.set(ci.Index.IndexId, ci);
        localStorage.setItem(lsCacheId, JSON.stringify(ci));
      }),
    );
  }

  getPortfoliosMetadataIds$(user: User, portfolios: CombinedIndex[], refresh = false): Observable<number[]> {
    if (portfolios != null && portfolios.length > 0 && portfolios.find(pf => pf != null && pf['New'] !== true) != null) {
      return this.getAvailablePortfolios$(refresh).pipe(
        take(1),
        map(available => {
          const metaIds: number[] = [];
          for (let i = 0; i < portfolios.length; i++) {
            const found = available.find(meta => meta.CombinedIndex.Index.IndexId === portfolios[i].Index.IndexId);
            if (found) {
              metaIds.push(found.Id);
            }
          }
          return metaIds;
        })
      );
    } else {
      return of<number[]>([]);
    }
  }

  storeCombinedIndex(userId: number, name: string, description: string, members: MemberIndexWeight[]) {
    const encodedName = fixedEncodeURIComponent(name);
    members = members.map(mb => {
      // cut down index description to size that StoreCombinedIndex will accept
      mb.Index.Description = truncIndexDescription(mb.Index.Description);
      return mb;
    });

    return this.ws.getStoreCombinedIndex(
      userId,
      encodedName,
      description,
      members
      ).pipe(
        tap(ci => console.log('Undecoded', ci.Index.Name)),
        map(ci => this.decodeCombinedIndexName(ci)),
        tap(ci => {
          // update cache
          if (this._combinedIndexCache.has(ci.Index.IndexId)) {
            this._combinedIndexCache.set(ci.Index.IndexId, ci);
          }
        }),
      );
  }

  /** Save temporary copies of current portfolio allocation and fetch performance statistics
   *
   * @param inPortfolios - The portfolios to be evaluated
   * @param dataFrequencyId - data frequency of request
   * @param fromDate - start date of evaluation period ( beginningOfTime if null )
   * @param endDate - last day of evaluation period ( beginningOfTime if null )
   * @param callback - a function that will be called with the result of the calculation
  */
  loadSandboxPerformance(
    inPortfolios: CombinedIndex[],
    dataFrequencyId: DATA_FREQ_ID = this.activeDataFrequencyId,
    fromDate = null,
    toDate = null,
    callback?: (infos: SandboxPortfolioCorrelationInfo[]) => void
  ): void {
    let endDate: Date;
    let startDate: Date;
    if (fromDate == null && toDate == null) {
      this.newSandboxCalculation = true;
    }

    this._blockValueChartSubj.next(true);

    if ( dataFrequencyId === DATA_FREQ_ID.MONTHLY) {
      endDate = (
        toDate == null ?
          moment.tz(this.appService.TZ).startOf('month').subtract(1, 'day').startOf('day')
          : moment.tz(toDate, this.appService.TZ)
      ).toDate();
      startDate = fromDate == null ?
        beginningOfTime.toDate()
        : moment.tz(fromDate, this.appService.TZ).toDate();
    } else {
      endDate = (toDate == null ?
        moment.tz(this.appService.TZ).subtract(1, 'day').startOf('day').toDate()
        : moment.tz(toDate, this.appService.TZ).toDate());
      startDate = fromDate == null ?
        beginningOfTime.toDate()
        : moment.tz(fromDate, this.appService.TZ).toDate();
    }

    let _correlationToIndexId: int;
    const idxOrderMap = new Map<int, int>();

    // cancel previous requests
    this._cancelStatsUpdateSubj.next();

    // trigger loading indicators
    this._progressRef.start();
    const progressCounter = 1 / 8;
    this._statisticsAreLoadingSubj.next(true);
    let tmpIndices$: Observable<CombinedIndex>[] = [];

    // create scratch CombinedIndecis for portfolios
    tmpIndices$ = inPortfolios.map((p: CombinedIndex, pCounter: number) => {
      let tmpIdx$: Observable<CombinedIndex>;

      p.Members.map(m => {
        if ( Array.isArray(m.Index.Description ) ) {
          m.Index.Description = '';
        } else if (m.Index.Description.length > 0) {
          m.Index.Description = truncIndexDescription(m.Index.Description);
        }
      });

      tmpIdx$ = this.storeCombinedIndex(
        this._user.userId,
        p.Index.Name,
        p.Index.Description,
        p.Members
      ).pipe(
        tap(cmbIdx => {
          if (pCounter === 0) {
            // save id of first Index for IndexCorrelationTo
            _correlationToIndexId = cmbIdx.Index.IndexId;
          }
          idxOrderMap.set(cmbIdx.Index.IndexId, pCounter);
          this._temporaryIndices.add(cmbIdx);
        }),
      );

      return tmpIdx$;
    });

    const perfStats$ = forkJoin<CombinedIndex>(...tmpIndices$).pipe(
      mergeMap((indices: CombinedIndex[]) => {
        const baseIndexIds = new Set<int>();
        for (const ci of indices) {
          if (isEmpty(ci.Members)) {
            this.notificationsService.warn('Invalid portfolio', 'Skipped empty portfolio.');
            continue;
          }
          for (const member of ci.Members) {
            if (!baseIndexIds.has(member.Index.IndexId)) {
              baseIndexIds.add(member.Index.IndexId);
            }
          }
        }
        return this.ws.getIndexDateRanges(this._user.userId, Array.from(baseIndexIds.values())).pipe(
          map((dateRanges: IndexDateRange[]) => {
            if (dateRanges.length === 0) {
              return {
                indices,
                firstDate: startDate,
                lastDate: endDate
              };
            } else {
              return {
                indices,
                firstDate: new Date(dateRanges.reduce(
                  (acc, dr) => Math.max(moment.tz(dr.FirstDate, this.appService.TZ).valueOf(), acc), startDate.valueOf())
                ),
                lastDate: new Date(dateRanges.reduce(
                  (acc, dr) => Math.min(moment.tz(dr.LastDate, this.appService.TZ).valueOf(), acc), endDate.valueOf()
                )),
              };
            }
          }),
        );
      }),
      map((data: {indices: CombinedIndex[], firstDate: Date, lastDate: Date }) => {
        data.indices.sort((a, b) => idxOrderMap.get(a.Index.IndexId) - idxOrderMap.get(b.Index.IndexId));
        this._progressRef.inc(progressCounter);
        let result$: Observable<IndexPerformanceInfo[]>;
        result$ = this.ws.getSandboxStatistics(
          this._user.userId,
          data.indices.map(idx => idx.Index.IndexId),
          dataFrequencyId,
          moment.tz(data.firstDate, this.appService.TZ).toISOString(true),
          moment.tz(data.lastDate, this.appService.TZ).toISOString(true)
        );
        return result$.pipe(
          // Build SandboxPortfolioInfo from SandboxStatistics and stored portfolios.
          map((perfInfos: IndexPerformanceInfo[]) => perfInfos.map((perfInfo: IndexPerformanceInfo) => {
            const ci = data.indices.find(cIdx => cIdx.Index.IndexId === perfInfo.Index.IndexId);
            return <SandboxPortfolioInfo>{
              Guid: `scratch-CombinedIndex-${ci.Index.IndexId}`,
              CombinedIndex: ci,
              PerformanceInfo: perfInfo.PerformanceInfo
            };
          })),
          map((sbxInfos: SandboxPortfolioInfo[]) => {
            const corrPoints$ = sbxInfos.map((sbxInfo: SandboxPortfolioInfo) => {
              let infoResult$: Observable<CorrelationPoint>;
              if (sbxInfo.CombinedIndex.Index.IndexId === _correlationToIndexId) {
                infoResult$ = of<CorrelationPoint>(new CorrelationPoint());
              } else {
                infoResult$ = this.ws.getIndexCorrelationTo(
                  this._user.userId,
                  sbxInfo.CombinedIndex.Index.IndexId,
                  _correlationToIndexId,
                  startDate,
                  endDate,
                  dataFrequencyId
                );
              }
              this._progressRef.inc(progressCounter);
              return infoResult$.pipe(
                map((corrPoint: CorrelationPoint) => {
                  return <SandboxPortfolioCorrelationInfo> {
                    Guid: sbxInfo.Guid,
                    CombinedIndex: sbxInfo.CombinedIndex,
                    PerformanceInfo: sbxInfo.PerformanceInfo,
                    Correlation: corrPoint
                  };
                }),
              );
            });
            return corrPoints$;
          }),
          mergeMap(corrSources$ => combineLatest(corrSources$)),
        );
      }),
      mergeAll(),
      tap(infos => {
        if (callback != null) {
          callback(infos);
        }
      }),
      takeUntil(this._cancelStatsUpdateSubj),
      retryBackoff({
        initialInterval: 20000,
        maxRetries: 3,
      }),
    );

    perfStats$.subscribe(
      (infos: SandboxPortfolioCorrelationInfo[]) => {
        infos.sort((a, b) => idxOrderMap.get(a.CombinedIndex.Index.IndexId) - idxOrderMap.get(b.CombinedIndex.Index.IndexId));
        this._activePortfolioInfos = infos;
        this._activePortfolioInfosSubj.next(infos);
        this._statisticsAreLoadingSubj.next(false);
        this._blockValueChartSubj.next(false);
      },
      (error) => {
        this.notificationsService.error(
          'Could not update portfolios statistics',
          'Check the input values, or try again in case of network error.'
        );
        this.logger.error('Error updating statistics', error);
        // this._sandboxStatisticsErrorsSubj.next(error);
        this._statisticsAreLoadingSubj.next(false);
      },
      () => {
        this._progressRef.complete();
        this._statisticsAreLoadingSubj.next(false);
        this.deleteScratchCombinedIndices();
        this._blockValueChartSubj.next(false);
      }
    );
  }

  getCombinableIndices(forceUpdate = false): Observable<Index[]> {
    if (!forceUpdate && this._combinableIndices != null && this._combinableIndices.length > 0) {
      return of<Index[]>(this._combinableIndices);
    }
    return this
      .user$.pipe(
        switchMap<User, Observable<Index[]>>(user => this.ws.getCombinableIndices(user.userId)),
        map<Index[], Index[]>(indices => indices.sort((a, b) => naturalSort(a.Name, b.Name))),
        tap<Index[]>(indices => {
          this._combinableIndices = indices;
          // force update of contingent indecis
          this._combinedIndecis = null;
          this._availablePortfolios = null;
         })
      );
  }

  get dataFrequencies$(): Observable<DataFrequency[]> {
    if (this._dataFrequencies.length > 0) {
      return of<DataFrequency[]>(this._dataFrequencies);
    } else {
      return this._dataFrequencies$;
    }
  }
  public getSandboxCombinedIndexMetaUrl(meta: SandboxCombinedIndexMeta) {
      if (meta.CombinedIndex == null) {
          return undefined;
      } else {
          return `${this._sandboxPortfolioMetadataUrl}${meta.CombinedIndex.Index.IndexId}/`;
      }
  }

  savePortfolio(position: number, portfolio: CombinedIndex, toggleStar?: boolean) {
    this.logger.debug('Saving portfolio', portfolio);
    const saveSubject = new Subject<SandboxCombinedIndexMeta>();
    this.savingPortfolioSubj.next(position);
    this._savingPortfolioStatusSubj.next({position: position, status: 'saving'});
    this.getAvailablePortfolios$(true).pipe(take(1)).subscribe(availMetaList => {
      const old = cloneDeep(availMetaList.find(availMeta => availMeta.CombinedIndex.Index.Name === portfolio.Index.Name));
      // save new portfolio
      this.user$.pipe(
        take(1),
        flatMap((user: User) => {
          return this.storeCombinedIndex(
            user.userId,
            portfolio.Index.Name,
            'Saved sandbox index',
            portfolio.Members
          ).pipe(
            map(cbIdx => {
            if (isArray(cbIdx.Index) && cbIdx.Index.length === 0) {
                return throwError('Combined index failed to save correctly. Index empty.');
              }
              // save metadata
              if (old === undefined ) {
                // create new entry
                return this.httpClient.post<SandboxPortfolioMetadata>(this._sandboxPortfolioMetadataUrl, {
                  userId: user.userId,
                  combinedIndexId: cbIdx.Index.IndexId,
                  lastModifiedDate: (new Date()).toISOString(),
                  starred: toggleStar === true ? true : false,
                  hidden: false,
                }).pipe(
                  map(response => {
                    const metaResp = new SandboxPortfolioMetaResponse(response);
                    return new SandboxCombinedIndexMeta(metaResp, cbIdx);
                  }),
                  tap(meta => {
                    // notify success
                    this.appService.showNotification(
                      'Save succeeded', `Saved sandbox portfolio “${cbIdx.Index.Name}”.`, NotificationType.Success);
                  })
                );
              } else {
                // update existing entry
                return this.httpClient.put<SandboxPortfolioMetadata>(this.getSandboxCombinedIndexMetaUrl(old), {
                  userId: user.userId,
                  combinedIndexId: cbIdx.Index.IndexId,
                  lastModifiedDate: (new Date()).toISOString(),
                  starred: toggleStar ? !old.Starred : old.Starred,
                  hidden: old.Hidden,
                }).pipe(
                  map(response => {
                    const metaResp = new SandboxPortfolioMetaResponse(response);
                    return new SandboxCombinedIndexMeta(metaResp, cbIdx);
                  }),
                  tap(meta => {
                    // notify success
                    let notification: string;
                    notification = `Updated sandbox portfolio “${cbIdx.Index.Name}”.`;
                    this.appService.showNotification('Save succeeded', notification, NotificationType.Success);
                  })
                );
              }
            }),
          );
        }),
        flatMap(meta => meta),
      ).subscribe(
        (sbxPfMeta: SandboxCombinedIndexMeta) => {
          saveSubject.next(sbxPfMeta);
          saveSubject.complete();
          this.getAvailablePortfolios$(true).pipe(
            take(1),
            finalize(() => {
              if (this._starringPortfolio === position) {
                this.starringPortfolioSubj.next(null);
              }
            }),
          ).subscribe(oldAvail => {
            const avail = cloneDeep(oldAvail);
            const newMeta = cloneDeep(sbxPfMeta);
            newMeta['Replacing'] = position;
            const newPos = avail.findIndex(meta => meta.CombinedIndex.Index.Name === newMeta.CombinedIndex.Index.Name);
            if (newPos >= 0) {
              avail.splice(newPos, 1, newMeta);
              avail.push(newMeta);
            }
            this._availablePortfolios = avail;
            this._availablePortfoliosSubj.next(avail);
            this._savingPortfolioStatusSubj.next({position: position, status: 'success'});
          });
        },
        (err) => {
          this.notificationsService.error(
            `Failed to save portfolio #${position + 1}`,
            `An error occurred while saving portfolio “${portfolio.Index.Name}”.`
          );
          this.logger.error('Failed to save portfolio', err);
          this._savingPortfolioStatusSubj.next({position: position, status: 'failed'});
          saveSubject.next(null);
          saveSubject.complete();
        },
        () => {
          this.savingPortfolioSubj.next(null);
        }
      );
    });
    return saveSubject.asObservable();
  }

  savePortfolioSet(newSet$: Observable<PortfolioSet>, update?: PortfolioSet): Observable<PortfolioSet> {
    this.isPortfolioSetSavingSubj.next(true);

    let request: Observable<PortfolioSet>;
    return newSet$.pipe(
      switchMap(newSet => {
        if (!validPortfolioSetName(newSet.name)) {
          return throwError('Invalid portfolio set name');
        }
        if (newSet.portfolios.length === 0) {
          return throwError('Cannot save portfolio set with 0 portfolios');
        }
        return this.getPortfolioSetList$().pipe(
          take(1),
          switchMap(savedSets => {
            const sameNameSet = savedSets.find(set => newSet.name.toLowerCase() === set.name.toLowerCase());
            if (sameNameSet != null || update != null) {
              if (update == null) {
                update = sameNameSet;
              }
              request = this.httpClient.put<PortfolioSet>(`${this._sandboxPortfolioSetUrl}${update.id}/`, newSet).pipe(
                tap((savedSet) => this.notificationsService.success('Updated portfolio set', `Updated set “${savedSet.name}”`)),
                catchError((err, caught) => {
                  this.logger.error('Cannot update portfolio set', err);
                  return of(null);
                }),
              );
            } else {
              // save a new set
              delete newSet.id; // remove id so a new id is generated
              request = this.httpClient.post<PortfolioSet>(this._sandboxPortfolioSetUrl, newSet).pipe(
                tap((savedSet) => this.notificationsService.success('Saved portfolio set', `Saved set “${savedSet.name}”`)),
                catchError((err, caught) => {
                  this.logger.error('Cannot save portfolio set', err);
                  return of(null);
                }),
              );
            }
            return request.pipe(
              tap(set => {
                if (set != null) {
                  this.getPortfolioSetList$(true);
                }
                this.isPortfolioSetSavingSubj.next(false);
              }),
            );
          }),
        );
      }),
      finalize(() => this.isPortfolioSetSavingSubj.next(false)),
    );
  }

  setShowAssetSelection(value: SandboxAnalyticsUIUpdate) {
    this._showAssetSelectionBacking = value;
    this._showAssetSelection.next(value);
  }

  setShowPortfolioAllocation(value: SandboxAnalyticsUIUpdate) {
    this._showPortfolioAllocationBacking = value;
    this._showPortfolioAllocation.next(value);
  }

  setShowPortfolioStatistics(value: SandboxAnalyticsUIUpdate) {
    this._showPortfolioStatisticsBacking = value;
    this._showPortfolioStatistics.next(value);
  }

  setShowRiskAllocationCharts(value: SandboxAnalyticsUIUpdate) {
    this._showRiskAllocationChartsBacking = value;
    this._showRiskAllocationChartsSubj.next(value);
  }

  starPortfolio(portfolio: CombinedIndex, value?: boolean) {
    let starredPortfolio$: Observable<SandboxCombinedIndexMeta>;
    const spSubj = new Subject<SandboxCombinedIndexMeta>(); // Subject to allow multicasting result
    starredPortfolio$ = this.getAvailablePortfolios$().pipe(
      take(1),
      flatMap<SandboxCombinedIndexMeta[], Observable<SandboxCombinedIndexMeta>>(metaList => {
        const found = metaList.find(meta => meta.CombinedIndex.Index.Name === portfolio.Index.Name);
        if (found == null) {
          return null;
        } else {
          if (value === undefined) {
            value = !found.Starred;
          }
          return this.httpClient.get<SandboxPortfolioMetaResponse>(this.getSandboxCombinedIndexMetaUrl(found)).pipe(
            flatMap(response => {
              if (response instanceof Error) {
                throw response;
              }
              response.starred = value;
              return this.httpClient.put<SandboxPortfolioMetaResponse>(this.getSandboxCombinedIndexMetaUrl(found), response).pipe(
                flatMap(
                  modResponse => this.getCombinedIndex(modResponse['userId'], modResponse['combinedIndexId']).pipe(
                    take(1),
                    map((ci) => {
                      const input = new SandboxPortfolioMetaResponse(modResponse);
                      const output = new SandboxCombinedIndexMeta();
                      output.CombinedIndex = ci;
                      output.Starred = input.starred;
                      output.Hidden = input.hidden;
                      output.LastModifiedDate = input.lastModifiedDate;
                      return output;
                    }),
                  ),
                )
              );
            }),
          );
        }
      }),
      publish(),
      refCount(),
    );

    starredPortfolio$.subscribe(
      indexMeta => {
        this.getAvailablePortfolios$(true).pipe(
          take(1),
        ).subscribe(oldAvail => {
          const avail = cloneDeep(oldAvail);
          const newMeta = cloneDeep(indexMeta);
          const newPos = avail.findIndex(meta => meta.CombinedIndex.Index.Name === newMeta.CombinedIndex.Index.Name);
          if (newPos >= 0) {
            avail.splice(newPos, 1, newMeta);
            this.logger.info('Updated portfolio', newMeta.CombinedIndex.Index.Name);
          } else {
            avail.push(newMeta);
            this.logger.info('Added portfolio', newMeta.CombinedIndex.Index.Name);
          }
          this._availablePortfolios = avail;
          this._availablePortfoliosSubj.next(avail);
          this.notificationsService.success(
            'Portfolio updated',
            `Portfolio “${newMeta.CombinedIndex.Index.Name}” ${newMeta.Starred ? 'marked as favorite' : 'unmarked'}`
          );
        });
      },
      (err) => {
        this.notificationsService.error('Error', 'Could not mark portfolio.');
        this.logger.error('Could not star portfolio', err);
      },
      () => {
        this.starringPortfolioSubj.next(null);
        spSubj.complete();
      }
    );

    return starredPortfolio$;
  }

  /**
   * Star-mark portfolio set
   *
   * @param theSet - the PortfolioSet to save
   * @param value - explicit value of mark. Inverts theSet.starred otherwise.
   * @returns - Observable of saved PortfolioSet
   */
  starPortfolioSet(theSet: PortfolioSet, value?: boolean): Observable<PortfolioSet> {
    const newSet = cloneDeep(theSet);
    if (value === undefined) {
      value = !newSet.starred;
    }
    newSet.starred = value;
    this.isPortfolioSetStarringSubj.next(true);
    return this.savePortfolioSet(of(newSet), theSet).pipe(
      finalize(() => this.isPortfolioSetStarringSubj.next(false))
    );
  }

  toggleShowAssetSelection() {
    this.setShowAssetSelection(new SandboxAnalyticsUIUpdate(this._showAssetSelectionBacking.show));
  }

  toggleShowPortfolioAllocation() {
    this.setShowPortfolioAllocation(new SandboxAnalyticsUIUpdate(this._showPortfolioAllocationBacking.show));
  }

  triggerSelectedIndicesUpdate() {
    this.logger.info('Updated selected indecis:', this._selectedIndicesList);
    this._selectedIndecis.next(this._selectedIndicesList);
  }

  updateSelectedAssets(selectedIds: string[]) {
    const newList = [];
    for (const id of selectedIds) {
      const index = this._combinableIndices.find(ci => ci.IndexId.toString() === id);
      newList.push(index);
    }
    this._selectedIndicesList = newList;
    this.triggerSelectedIndicesUpdate();
  }

  private deleteScratchCombinedIndices() {
    // delete scratch indecis
    for (const ci of Array.from(this._temporaryIndices.values())) {
      if (ci == null || ci.Index.IndexId == null) {
        continue;
      }
      this.ws.getTryDeleteUserCreatedIndex(this._user.userId, ci.Index.IndexId).subscribe(
        (success) => {
          if (success) {
            // drop deleted index
            this._temporaryIndices.delete(ci);

            this.logger.info('Deleted scratch CombinedIndex', ci.Index.IndexId);
          } else {
            this.logger.error('Failed to delete scratch CombinedIndex', ci.Index.IndexId);
          }
        },
        (err) => this.logger.error(`Failed to delete scratch CombinedIndex ${ci.Index.IndexId}:`, err)
      );
    }
  }
}

