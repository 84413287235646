<div class="performance-day-navigator">
    <div class="date-buttons field has-addons">
        <div class="control">
        <button #lastDayBtn 
            type="button" 
            class="button" 
            value="last-day" 
            [attr.title]="getLastDay()|date:'dd-MMM-YYYY'"
            [ngClass]="{'is-primary': lastDayBtnActive$|async}"
            (click)="onSetActiveDate(lastDayBtn.value)">Last day</button>
        </div>
        <div class="control">
        <button #lastMonthBtn 
            type="button" 
            class="button" 
            value="last-month" 
            title="Show last day of last month"
            [ngClass]="{'is-primary': lastMonthBtnActive$|async}"
            (click)="onSetActiveDate(lastMonthBtn.value)">{{ getLastMonth() | date:"MMM-y"}}</button>
        </div>
    </div>
    <div class="date-selector">
        <input
            class="date-input" 
            angular-mydatepicker
            #dp="angular-mydatepicker"
            [options]="myDatePickerOptions"
            [(ngModel)]="myDatePickerModel"
            (focusin)="openDatePicker(dp)"
            (dateChanged)="onMyDatePickerDateChanged($event)" required>
        <div class="control">
            <button class="button picker-btn" (click)="openDatePicker(dp)" >
                <span class="icon">
                    <i class="fa fa-calendar"></i>
                </span>
            </button>
        </div>
    </div>
</div>
