import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewEncapsulation, ViewRef } from '@angular/core';
import { AppService } from '@app/app-service.service';
import { MenuService } from '@app/menu.service';
import { Observable, Subject, merge } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AccountOverviewService } from '../services/account-overview.service';
import { IMyDate, IMyDateModel, IAngularMyDpOptions, AngularMyDatePickerDirective } from 'angular-mydatepicker';
import { map, takeUntil } from 'rxjs/operators';
import { AccountDataService } from '@app/account-data/account-data.service';
import { AcctIdx } from '@app/account-data/acct-idx';
import { LoggingService } from '@app/utils/logging/logging.service';

@Component({
  selector: 'app-overview-menu',
  templateUrl: './overview-menu.component.html',
  styleUrls: ['./overview-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None,
})
export class OverviewMenuComponent implements OnDestroy, OnInit {
  @Input() accountId: number|string;
  activeAcctIdx$: Observable<AcctIdx>;
  acctIdxs$: Observable<AcctIdx[]>;
  @HostBinding('class.menu-mode-icons')
  menuModeIcons: boolean;
  isLoading$: Observable<boolean>;
  isNavbarVisible$: Observable<boolean>;
  mainMenuMode$: Observable<string>;
  performanceAndRiskUrl$: Observable<string>;
  selectedSection$: Observable<string>;
  showDocuments$: Observable<boolean>;
  showIntradayOrders$: Observable<boolean>;

  private _destroy$ = new Subject<void>();
  
  constructor(
    private _acctDataSvc: AccountDataService,
    private _acctOverviewSvc: AccountOverviewService,
    private _appService: AppService,
    private _changeDet: ChangeDetectorRef,
    private _element: ElementRef,
    private _logger: LoggingService,
    private _menuService: MenuService,
  ) {
    this.isLoading$ = this._acctDataSvc.isLoading$.asObservable();
    this.isNavbarVisible$ = this._appService.isNavbarVisible$;
    this.mainMenuMode$ = this._appService.menuMode$;
    this.mainMenuMode$.pipe(
      map((mode) => mode === 'icons'),
      takeUntil(this._destroy$),
    ).subscribe({
      next: (isIcons) => this.menuModeIcons = isIcons,
    });
    this.selectedSection$ = this._acctOverviewSvc.selectedSection$.pipe(tap((sectionId) => this._logger.debug('selectedSection:', sectionId)));

    this.activeAcctIdx$ = this._acctOverviewSvc.activeAcctIdx$;
    this.performanceAndRiskUrl$ = this._menuService.performanceAndRiskUrl$;
    this.showDocuments$ = this._acctOverviewSvc.showDocuments$;
    this.showIntradayOrders$ = this._acctOverviewSvc.showIntradayOrders$;
  }

  changeAcctIdx(id: string) {
    this._acctOverviewSvc.changeAcctIdx(id);
    this._changeDet.markForCheck();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit() {
    this.acctIdxs$ = this._acctDataSvc.loadAcctIdxs().pipe(
      map((data) => Array.isArray(data) && Array.isArray(data[0]) ? data[0] : null)
    );
  }

  ngAfterViewInit() {
    /** Update overviewMenuHeight to account for lines wrapping */
    merge(
      this._acctOverviewSvc.updateOverviewHeight$,
      this._appService.viewportSize$,
    ).pipe(takeUntil(this._destroy$)).subscribe({
      next: () => {
        const divCollection = (this._element.nativeElement as HTMLDivElement).getElementsByClassName('performance-overview-account-name');
        if (divCollection != null && divCollection.length > 0) {
          const bounds = divCollection.item(0).getBoundingClientRect();
          this._acctOverviewSvc.overviewMenuHeight = bounds.height;
        }
      }
    });
  }

  selectSection(sectionId: string): boolean {
    this._acctOverviewSvc.selectSection(sectionId);
    
    return false;
  }

  toggleSidebar() {
    this._appService.toggleSidebar();
  }

  update() {
    this._changeDet.markForCheck();
  }
}
