export type double = number;
export type datetime = string;
export type int = number;
export type decimal = number;
export type guid = string;
export type dateTime = string;

export enum DATA_FREQ_ID { DAILY = 1, MONTHLY = 2 }
export enum INDEX_TYPE_ID { TheoreticalIndex = 1, AttributionStrategy = 3, CombinedIndex = 5, Carveout = 8, AggregatedIndex = 10, }
export enum FUNDING_TYPE_ID { FullyFunded = 1, MarginFunded = 2 }

export class IndexType {
  IndexTypeId: INDEX_TYPE_ID;
  Name: string;
}
export class FundingType {
FundingTypeId: FUNDING_TYPE_ID;
Name: string;
}
export class Index {
  Description: string;
  FundingType: FundingType;
  IndexId: int;
  IndexType: IndexType;
  Name: string;
  SandboxType: string;

  equals: (ixB: Index) => boolean = (ixB) => {
     return (this.IndexType.IndexTypeId === INDEX_TYPE_ID.CombinedIndex || // CombinedIndex doesn't have a real FundingType
       this.FundingType.FundingTypeId === ixB.FundingType.FundingTypeId) &&
     this.IndexType.IndexTypeId === ixB.IndexType.IndexTypeId &&
     this.Name === ixB.Name;
  }
  toString: () => string = () => this.Name;
}
export class ModifiedIndexContract {
  OriginalIndexContractId: int;
  RiskAdjustmentFactor: decimal;
  SignalDirectionId: int;
}
export class ModifiedIndex {
  Index: Index;
  ModifiedIndexContracts: ModifiedIndexContract[];
  UnderlyingIndex: Index;
}
export class TemporaryModifiedIndex {
  Guid: guid;
  ModifiedIndex: ModifiedIndex;
}
export class MemberIndexWeight {
  Index: Index;
  IndexWeight: decimal;
  PortfolioExposureEur: decimal;
  RiskExposure: decimal;
}
export class MemberRiskWeight {
  Index: Index;
  RiskWeight: decimal;
}
export class CombinedIndex {
  Index: Index;
  MemberRiskWeights: MemberRiskWeight[];
  Members: MemberIndexWeight[];
  TotalFundingRequirement: decimal;
  TotalLeverage: decimal;
}
export class TemporaryCombinedIndex {
  CombinedIndex: CombinedIndex;
  Guid: guid;
}
export class VolatilityDetails {
  Date: dateTime;
  Volatility: double;
  VolatilityLongPositions: double;
  VolatilityShortPositions: double;
}
export class AssetClass {
  AssetClassId: int;
  Name: string;
}
export class SubAssetClass {
  AssetClass: AssetClass;
  Name: string;
}
export class SignalStrength {
Date: dateTime;
Signal: double;
}
export class AssetClassSignalStrength {
AssetClass: AssetClass;
SignalStrengths: SignalStrength[];
}
export class SubAssetClassVolatilityDetails {
  SubAssetClass: SubAssetClass;
  VolatilityDetails: VolatilityDetails;
}
export class VaRPoint {
  Date: dateTime;
  VaR: double;
}
export class VaRDetails {
  LongPositionVaR: VaRPoint;
  ShortPositionVaR: VaRPoint;
  TotalVaR: VaRPoint;
}
export class SubAssetClassVaRDetails {
  Date: dateTime;
  SubAssetClass: SubAssetClass;
  VaRDetails: VaRDetails;
}
export class DailyPerformanceAggregations {
  Last: double;
  MonthToDate: double;
  OneYear: double;
  PerformanceDate: dateTime;
  SixMonth: double;
  ThreeMonth: double;
  YearToDate: double;
  OneMonth: double;
  All: double;
}
export class SubAssetClassPerformanceAggregations {
  DailyPerformanceAggregations: DailyPerformanceAggregations;
  SubAssetClass: SubAssetClass;
}
export class StrategyStyle {
  Name: string;
  StrategyStyleId: int;
}
export class IntradayPerformancePoint {
  Performance: double;
  Time: dateTime;
}
export class IntradayVolatilityAndPerformance {
  Performance: IntradayPerformancePoint;
  Volatility: double;
  VolatilityDirection: int;
}
export class SubAssetClassIntradayDetails {
  SubAssetClass: SubAssetClass;
  VolatilityAndPerformance: IntradayVolatilityAndPerformance;
}
export class Product {
  BloombergSymbol: string;
  BloombergType: string;
  LongName: string;
  ProductId: int;
  SubAssetClass: SubAssetClass;
}
export class ProductIntradayDetails {
  Product: Product;
  VolatilityAndPerformance: IntradayVolatilityAndPerformance;
}
export class AssetClassIntradayPerformance {
  AssetClass: AssetClass;
  IntradayPerformance: IntradayPerformancePoint;
}
export class AssetClassIntradayDetails {
  AssetClass: AssetClass;
  VolatilityAndPerformance: IntradayVolatilityAndPerformance;
}
export class IntradayPerformanceRiskOverview {
  AssetClassesIntradayDetails: AssetClassIntradayDetails[];
  AssetClassesLastPerformances: AssetClassIntradayPerformance[];
  LastPerformance: IntradayPerformancePoint;
  Performances: IntradayPerformancePoint[];
  ProductsIntradayDetails: ProductIntradayDetails[];
  SubAssetClassesIntradayDetails: SubAssetClassIntradayDetails[];
}
export class StrategyStyleIntradayPerformanceRiskOverview {
  IntradayPerformanceRiskOverview: IntradayPerformanceRiskOverview;
  StrategyStyle: StrategyStyle;
}
export class Strategy {
  StrategyId: string;
  StrategyStyle: StrategyStyle;
}
export class PerformancePoint {
  Date: dateTime;
  Performance: double;
}
export class StrategyCumulativeReturns {
  PerformancesOrdered: PerformancePoint[];
  Strategy: Strategy;
}
export class SignalDirection {
  Name: string;
  SignalDirectionId: int;
}
export class ReferencePriceType {
  Name: string;
  ReferencePriceTypeId: int;
}
export class VolatilityPoint {
  Date: dateTime;
  Volatility: double;
}
export class ProductVolatility {
  Product: Product;
  TotalWeightSign: int;
  VolatilityPoint: VolatilityPoint;
}
export class ProductVaR {
  Product: Product;
  TotalWeightSign: int;
  ValueAtRisk: VaRPoint;
}
export class ProductPerformanceAggregations {
  DailyPerformanceAggregations: DailyPerformanceAggregations;
  Product: Product;
}
export class PricePoint {
  DateTime: dateTime;
  Price: decimal;
}
export class Instrument {
  BloombergTicker: string;
  InstrumentId: int;
  ProductLongName: string;
  ShortName: string;
  Product: Product;
  ExpirationYear: int;
  ExpirationMonth: int;
  ExpirationDate: dateTime;
}
export class InstrumentIntradayPrices {
  Instrument: Instrument;
  PricesOrdered: PricePoint[];
}
export class ProductIntradayPrices {
  InstrumentPrices: InstrumentIntradayPrices[];
  MostActiveInstrumentId: int;
  Product: Product;
}
export class PortfolioWeight {
  Date: dateTime;
  Weight: decimal;
}
export class TheoreticalIndex {
  Index: Index;
}
export class PortfolioProfile {
  AllIndices: Index[];
  CombinedIndices: CombinedIndex[];
  Created: dateTime;
  Modified: dateTime;
  ModifiedIndices: ModifiedIndex[];
  Name: string;
  OtherIndices: Index[];
  ProfileId: int;
  TheoreticalIndices: TheoreticalIndex[];
}
export class StrategyStyleCumulativeReturns {
  PerformancesOrdered: PerformancePoint[];
  StrategyStyle: StrategyStyle;
}
export class DrawdownPoint {
  Drawdown: double;
  Timestamp: dateTime;
}
export class DataFrequency {
  DataFrequencyId: DATA_FREQ_ID;
  Name: string;
}
export class PerformanceStatistics {
  AnnualizedReturn: double;
  AnnualizedVolatility: double;
  AverageThreeMonthReturn: double;
  DataFrequency: DataFrequency;
  FromInclusive: dateTime;
  MARratio: double;
  MaxDailyReturn: PerformancePoint;
  MaxDrawdown: DrawdownPoint;
  MaxDrawdownDuration: int;
  MaxDrawdownToVolatility: double;
  MaxMonthlyReturn: PerformancePoint;
  MaxThreeMonthReturn: PerformancePoint;
  MaxThreeMonthVolatility: VolatilityPoint;
  MedianThreeMonthReturn: double;
  MinDailyReturn: PerformancePoint;
  MinMonthlyReturn: PerformancePoint;
  MinThreeMonthReturn: PerformancePoint;
  MinThreeMonthVolatility: VolatilityPoint;
  Skewness: double;
  ToInclusive: dateTime;
}
export class NAVPoint {
  NAV: double;
  Timestamp: dateTime;
}
export class PerformanceInfo {
  NAVSeries: NAVPoint[];
  Statistics: PerformanceStatistics;
}
export class CorrelationPoint {
  Correrlation: double;
  Date: dateTime;
}
export class PerformanceAndCorrelationInfo {
  CorrelationPoint: CorrelationPoint;
  PerformanceInfo: PerformanceInfo;
}
export class Order {
  FilledQuantity: decimal;
  Instrument: Instrument;
  Quantity: decimal;
}
export class Account {
  AccountId: string;
  Currency: string;
  Name: string;
}
export class MarginRequirement {
  Account: Account;
  Date: dateTime;
  MarginRequirementToTradingLevel: double;
  MarginRequirementToTradingLevelLong: double;
  MarginRequirementToTradingLevelShort: double;
}
export class SubAssetClassMarginRequirement {
  MarginRequirement: MarginRequirement;
  SubAssetClass: SubAssetClass;
}
export class ProductMarginRequirement {
  MarginRequirementToTradingLevel: double;
  Product: Product;
  TotalWeightSign: int;
}
export class MarketIntradayReturn {
  Instrument: Instrument;
  IntradayPerformance: IntradayPerformancePoint;
  Time: dateTime;
}
export class IndexIntradayPerformance {
  Index: Index;
  IntradayPerformance: IntradayPerformancePoint;
}
export class IndexIntradayOverview {
  Index: Index;
  IndexIntradayPerformance: IndexIntradayPerformance;
}
export class Benchmark {
  Index: Index;
}
export class BenchmarkIntradayPerformance {
  Benchmark: Benchmark;
  IntradayPerformance: IntradayPerformancePoint;
}
export class StrategyStyleIntradayPerformance {
  IntradayPerformance: IntradayPerformancePoint;
  StrategyStyle: StrategyStyle;
}
export class AccountIntradayPerformance {
  AccountId: string;
  IntradayPerformance: IntradayPerformancePoint;
}
export class AccountIntradayOverview {
  Account: Account;
  AccountIntradayPerformance: AccountIntradayPerformance;
  StrategyStylesIntradayPerformances: StrategyStyleIntradayPerformance[];
}
export class LandingPageData {
  AccountInfos: AccountIntradayOverview[];
  BenchmarkPerformance: BenchmarkIntradayPerformance;
  KeyMarketPerformances: MarketIntradayReturn[];
  IndexInfos: IndexIntradayOverview[];
}
export class ToDatePerformances {
  Date: dateTime;
  Last: double;
  MTD: double;
  YTD: double;
}
export class IndexToDatePerformances {
  Index: Index;
  ToDatePerformances: ToDatePerformances;
}
export class BenchmarkToDatePerformances {
  Benchmark: Benchmark;
  ToDatePerformances: ToDatePerformances;
}
export class StrategyStyleToDatePerformances {
  StrategyStyle: StrategyStyle;
  ToDatePerformances: ToDatePerformances;
}
export class AccountToDatePerformances {
  Account: Account;
  StrategyStylesToDatePerformances: StrategyStyleToDatePerformances[];
  ToDatePerformances: ToDatePerformances;
}
export class LandingPageDailyData {
  AccountPerformances: AccountToDatePerformances[];
  BenchmarkPerformances: BenchmarkToDatePerformances;
  IndexPerformances: IndexToDatePerformances[];
}
export class Trade {
  AverageTradePrice: decimal;
  PacketId: int;
  Quantity: int;
}
export class IntradayPerformanceDetails {
  AccountId: string;
  ActiveContractId: int;
  ContractSize: decimal;
  Instrument: Instrument;
  LastPrice: PricePoint;
  MidnightPosition: int;
  NoPerformanceReason: string;
  Performance: double;
  SettlementPrice: PricePoint;
  Trades: Trade[];
  TradingLevel: decimal;
  Xrate: decimal;
}
export class AssetClassVolatilityDetails {
  AssetClass: AssetClass;
  VolatilityDetails: VolatilityDetails;
}
export class VolatilityOverview {
  LastAssetClassesVolatility: AssetClassVolatilityDetails[];
  LastMonthEndAssetClassesVolatility: AssetClassVolatilityDetails[];
  ProductsVolatility: ProductVolatility[];
  SubAssetClassesVolatility: SubAssetClassVolatilityDetails[];
}
export class IndexVolatilityOverview {
  Index: Index;
  VolatilityOverview: VolatilityOverview;
}
export class AssetClassVaRDetails {
  AssetClass: AssetClass;
  Date: dateTime;
  VaRDetails: VaRDetails;
}
export class VaROverview {
  LastAssetClassesVaR: AssetClassVaRDetails[];
  LastMonthEndAssetClassesVaR: AssetClassVaRDetails[];
  ProductsVaR: ProductVaR[];
  SubAssetClassesVaR: SubAssetClassVaRDetails[];
}
export class IndexVaROverview {
  Index: Index;
  VaROverview: VaROverview;
}
export class IndexPerformanceInfo {
  Index: Index;
  PerformanceInfo: PerformanceInfo;
}
export class AssetClassMarginRequirement {
  AssetClass: AssetClass;
  MarginRequirement: MarginRequirement;
}
export class MarginOverview {
  LastAssetClassesMarginRequirements: AssetClassMarginRequirement[];
  LastMonthEndAssetClassesMarginRequirements: AssetClassMarginRequirement[];
  ProductsMarginRequirements: ProductMarginRequirement[];
  SubAssetClassesMarginRequirements: SubAssetClassMarginRequirement[];
}
export class IndexMarginOverview {
  Index: Index;
  MarginOverview: MarginOverview;
}
export class IndexIntradayPerformanceRiskOverview {
  Index: Index;
  IntradayPerformanceRiskOverview: IntradayPerformanceRiskOverview;
}
export class IndexInstrumentIntradayPerformance {
  IndexId: int;
  Instrument: Instrument;
  IntradayPerformance: IntradayPerformancePoint;
}
export class SubAssetClassExposures {
  GrossExposure: decimal;
  NetExposure: decimal;
  SubAssetClass: SubAssetClass;
}
export class ProductExposure {
  NetExposure: decimal;
  Position: int;
  Product: Product;
}
export class ProductDailyHolding {
  Product: Product;
  Weights: PortfolioWeight[];
}
export class ProductRolledDailyClose {
  ClosePricesOrdered: PricePoint[];
  Product: Product;
}
export class AssetClassDailyHoldings {
  AssetClass: AssetClass;
  KeyMarketPrices: ProductRolledDailyClose;
  ProductDailyHoldings: ProductDailyHolding[];
}
export class AssetClassExposures {
  AssetClass: AssetClass;
  GrossExposure: decimal;
  NetExposure: decimal;
}
export class HoldingsOverview {
  AssetClassesExposures: AssetClassExposures[];
  AssetClassesHoldings: AssetClassDailyHoldings[];
  ProductsExposure: ProductExposure[];
  SubAssetClassesExposures: SubAssetClassExposures[];
}
export class IndexHoldingsOverview {
  HoldingsOverview: HoldingsOverview;
  Index: Index;
}
export class DailyGamma {
  Date: dateTime;
  Gamma: decimal;
}
export class DailyDelta {
  Date: dateTime;
  Delta: decimal;
}
export class SubAssetClassGammaAndDelta {
  DailyDelta: DailyDelta;
  DailyGamma: DailyGamma;
  Date: dateTime;
  SubAssetClass: SubAssetClass;
}
export class ProductDelta {
  DailyDelta: DailyDelta;
  PositionSign: int;
  Product: Product;
}
export class AssetClassGammaAndDelta {
  AssetClass: AssetClass;
  DailyDelta: DailyDelta;
  DailyGamma: DailyGamma;
  Date: dateTime;
}
export class GammaAndDeltaOverview {
  AssetClassesGammaAndDelta: AssetClassGammaAndDelta[];
  Date: dateTime;
  ProductsDelta: ProductDelta[];
  SubAssetClassesGammaAndDelta: SubAssetClassGammaAndDelta[];
}
export class IndexGammaAndDeltaOverview {
  GammaAndDeltaOverview: GammaAndDeltaOverview;
  Index: Index;
}
export class DailyRiskInfo {
  Date: dateTime;
  Delta: DailyDelta;
  Gamma: DailyGamma;
  HistoricalVolatiltiy: VolatilityPoint;
  MarginRequirement: MarginRequirement;
  VaR95: VaRPoint;
}
export class AssetClassValueAtRiskHistorical {
  AssetClass: AssetClass;
  ValueAtRiskSeries: VaRPoint[];
}
export class VaRAndDirection {
  Direction: int;
  ValueAtRisk: VaRPoint;
}
export class AssetClassVaRAndDirection {
  AssetClass: AssetClass;
  VaRAndDirection: VaRAndDirection;
}
export class AssetClassGammas {
  AssetClass: AssetClass;
  Gammas: DailyGamma[];
}
export class AssetClassDeltas {
  AssetClass: AssetClass;
  Deltas: DailyDelta[];
}
export class RiskOverview {
  AssetClassesDelta: AssetClassDeltas[];
  AssetClassesGamma: AssetClassGammas[];
  AssetClassesVaRAndDirection: AssetClassVaRAndDirection[];
  AssetClassesVaRHistorical: AssetClassValueAtRiskHistorical[];
  Date: dateTime;
  LastMonthEndRiskInfo: DailyRiskInfo;
  LastRiskInfo: DailyRiskInfo;
  MarginRequirements: MarginRequirement[];
  ValueAtRiskHistorical: VaRPoint[];
}
export class IndexDailyRiskOverview {
  Index: Index;
  RiskOverview: RiskOverview;
}
export class IndexCorrelationsAndVolatilities {
  Correlations: CorrelationPoint[];
  Index: Index;
  Volatilities: VolatilityPoint[];
}
export class AssetClassPerformanceAggregations {
  AssetClass: AssetClass;
  DailyPerformanceAggregations: DailyPerformanceAggregations;
}
export class AssetClassCumulativeReturns {
  AssetClass: AssetClass;
  PerformancesOrdered: PerformancePoint[];
}
export class PerformanceOverview {
  AssetClassCumulativeReturns: AssetClassCumulativeReturns[];
  AssetClassPerformanceAggregations: AssetClassPerformanceAggregations[];
  BenchmarkCorrelationsAndVolatilities: IndexCorrelationsAndVolatilities;
  CumulativeReturns: PerformancePoint[];
  DailyPerformanceAggregations: DailyPerformanceAggregations;
  DailyPerformances: PerformancePoint[];
  Drawdowns: DrawdownPoint[];
  PerformanceDate: dateTime;
  StrategyStyleCumulativeReturns: StrategyStyleCumulativeReturns[];
  StrategyStyleToDateReturns: StrategyStyleToDatePerformances[];
}
export class IndexDailyPerformanceOverview {
  Index: Index;
  PerformanceOverview: PerformanceOverview;
}
export class IndexContract {
  ContractName: string;
  IndexContractId: int;
}
export class SubAssetClassGamma {
  Gamma: DailyGamma;
  SubAssetClass: SubAssetClass;
}
export class ProductGamma {
  DailyGamma: DailyGamma;
  PositionSign: int;
  Product: Product;
}
export class AssetClassGammaDetails {
  AssetClass: AssetClass;
  Date: dateTime;
  LongPositionGamma: decimal;
  ShortPositionGamma: decimal;
  TotalGamma: decimal;
}
export class GammaOverview {
  AssetClassesGammas: AssetClassGammaDetails[];
  Date: dateTime;
  LastMonthEndAssetClassesGammas: AssetClassGammaDetails[];
  ProductsGamma: ProductGamma[];
  SubAssetClassesGamma: SubAssetClassGamma[];
}
export class ExecutedTrade {
  AveragePrice: decimal;
  Date: dateTime;
  Instrument: Instrument;
  Quantity: decimal;
  Settlement: decimal;
  SettlementTMinusOne: decimal;
}
export class SubAssetClassDelta {
  DailyDelta: DailyDelta;
  SubAssetClass: SubAssetClass;
}
export class AssetClassDeltaDetails {
  AssetClass: AssetClass;
  Date: dateTime;
  LongPositionDelta: decimal;
  ShortPositionDelta: decimal;
  TotalDelta: decimal;
}
export class DeltaOverview {
  AssetClassesDeltas: AssetClassDeltaDetails[];
  Date: dateTime;
  LastMonthEndAssetClassesDeltas: AssetClassDeltaDetails[];
  ProductsDelta: ProductDelta[];
  SubAssetClassesDelta: SubAssetClassDelta[];
}
export class DailyReturnData {
  CumulativePerformances: PerformancePoint[];
  DailyPerformances: PerformancePoint[];
}
export class DailyNetExposure {
  Date: dateTime;
  NetExposure: double;
}
export class CumulativeSlippage {
  Date: dateTime;
  Slippage: double;
}
export class CostType {
  CostTypeId: int;
  Name: string;
}
export class AssetClassIntradayPerformances {
  AssetClass: AssetClass;
  Performances: IntradayPerformancePoint[];
}
export class AccountVolatilityOverview {
  Account: Account;
  VolatilityOverview: VolatilityOverview;
}
export class AccountVaROverview {
  Account: Account;
  VaROverview: VaROverview;
}
export class AccountStyleVolatilityOverview {
  Account: Account;
  StrategyStyle: StrategyStyle;
  VolatilityOverview: VolatilityOverview;
}
export class AccountStyleVaROverview {
  Account: Account;
  StrategyStyle: StrategyStyle;
  VaROverview: VaROverview;
}
export class AccountStyleRiskOverview {
  Account: Account;
  RiskOverview: RiskOverview;
  StrategyStyle: StrategyStyle;
}
export class AccountStylePerformanceOverview {
  Account: Account;
  PerformanceOverview: PerformanceOverview;
  StrategyStyle: StrategyStyle;
}
export class AccountStyleMarginOverview {
  Account: Account;
  MarginOverview: MarginOverview;
  StrategyStyle: StrategyStyle;
}
export class AccountStyleHoldingsOverview {
  Account: Account;
  HoldingsOverview: HoldingsOverview;
  StrategyStyle: StrategyStyle;
}
export class AccountStyleGammaAndDeltaOverview {
  Account: Account;
  GammaAndDeltaOverview: GammaAndDeltaOverview;
  StrategyStyle: StrategyStyle;
}
export class AccountStrategyIntradayPerformance {
  AccountId: string;
  IntradayPerformance: IntradayPerformancePoint;
  Strategy: Strategy;
}
export class AccountStrategyInstrumentIntradayPerformance {
  Account: Account;
  Instrument: Instrument;
  IntradayPerformance: IntradayPerformancePoint;
  Strategy: string;
}
export class AccountInstrumentIntradayPerformance {
  AccountId: string;
  Instrument: Instrument;
  IntradayPerformance: IntradayPerformancePoint;
}
export class AccountOverviewInstruments {
  Account: Account;
  AccountIntradayPerformance: AccountIntradayPerformance;
  BiggestLosers: AccountInstrumentIntradayPerformance[];
  BiggestWinners: AccountInstrumentIntradayPerformance[];
}
export class AccountMarginOverview {
  Account: Account;
  MarginOverview: MarginOverview;
}
export class StrategyStyleIntradayPerformances {
  Performances: IntradayPerformancePoint[];
  StrategyStyle: StrategyStyle;
}
export class AccountIntradayPerformanceRiskOverview {
  Account: Account;
  AccountStrategyStyles: StrategyStyle[];
  IntradayPerformanceRiskOverview: IntradayPerformanceRiskOverview;
  StrategyStylesPerformances: StrategyStyleIntradayPerformances[];
}
export class AccountHoldingsOverview {
  Account: Account;
  HoldingsOverview: HoldingsOverview;
}
export class AccountGammaAndDeltaOverview {
  Account: Account;
  GammaAndDeltaOverview: GammaAndDeltaOverview;
}
export class AccountDailyRiskOverview {
  Account: Account;
  RiskOverview: RiskOverview;
}
export class AccountDailyPerformanceOverview {
  Account: Account;
  PerformanceOverview: PerformanceOverview;
}
export class AccountTradingLevel {
  AccountId: string;
  Currency: string;
  Date: dateTime;
  TL: decimal;
}
export class RealTrackStartDate {
  HasRealTrackStartDate: boolean;
  Index: Index;
  StartDate: dateTime;
}
export class IndexDateRange {
  Index: Index;
  FirstDate: dateTime;
  LastDate: dateTime;
}

export class ExAnteVolatility {
  Date: dateTime;
  Volatility: number;
}

