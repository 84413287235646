import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from '@app/app-service.service';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDate, IMyDateModel } from 'angular-mydatepicker';
import { AccountOverviewService } from '../services/account-overview.service';
import * as moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-overview-date-picker',
  templateUrl: './overview-date-picker.component.html',
  styleUrls: ['./overview-date-picker.component.scss']
})
export class OverviewDatePickerComponent implements OnInit {
  myDatePickerModel: IMyDateModel = null;
  myDatePickerOptions: IAngularMyDpOptions = {};

  private _destroy$ = new Subject<void>();
  
  constructor(
    private _acctOverviewSvc: AccountOverviewService,
    private _appService: AppService,
    private _changeDet: ChangeDetectorRef,

  ) { }

  getLastDay() {
    return this._acctOverviewSvc.getLastDay();
  }

  getLastMonth() {
    return this._acctOverviewSvc.getLastMonth();
  }

  get lastDayBtnActive$() {
    return this._acctOverviewSvc.isLastDay$();
  }

  get lastMonthBtnActive$() {
    return this._acctOverviewSvc.isLastMonth$();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit() {
    this._acctOverviewSvc.selectedDate$.pipe(
      takeUntil(this._destroy$),
    ).subscribe((selectedDate) => this.setPickerDate(selectedDate));
    const now = moment().tz(this._appService.TZ).toDate();
    this.myDatePickerOptions = {
      // other options...
      dateFormat: 'dd-mmm-yyyy',
      dateRange: false,
      disableSince: new Object({
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate()
      }) as IMyDate,
      disableWeekends: true,
      firstDayOfWeek: 'mo',
      markCurrentDay: true,
      openSelectorTopOfInput: false,
      // selectorHeight: '24px', 
      // showClearDateBtn: false,
      // showTodayBtn: true,
      showWeekNumbers: true,
      stylesData: {
        selector: 'dp1',
        styles: `
        .ng-mydp {
          // line-height: 2.45!important;
          border: 1px solid $button-border-color !important;
      }
      
      .ng-mydp .btnpicker {
          border-left: 1px solid $button-border-color!important;
          color: $brown-dark!important;
      }
      
      .ng-mydp .selection {
          font-family: $family-primary!important;
          height: 38px!important;
          color: $brown-dark!important;
          text-align: center;
      }
      
      .ng-mydp .selectorarrowleft:after, .mydp .selectorarrowleft:before {
          left: 150px!important;
      }
      
      .ng-mydp .selectorarrow {
          width: 300px!important;
      }
      
      .ng-mydp table td.myDpDayCell {
          text-align: center ! important;
      }`
      }
    };

  }

  onMyDatePickerDateChanged($event: IMyDateModel) {
    if ($event == null) {
      return;
    }
    this.myDatePickerModel = $event;
    this._acctOverviewSvc.selectedDate = moment($event.singleDate.jsDate).endOf('day').toDate();
  }


  onSetActiveDate(value) {
    switch (value) {
      case 'last-day': {
        this._acctOverviewSvc.selectedDate = this._acctOverviewSvc.getLastDay();
      } break;
      case 'last-month': {
        this._acctOverviewSvc.selectedDate = this._acctOverviewSvc.getLastMonth();
      } break;
    }
    return;
  }

  openDatePicker(dp: AngularMyDatePickerDirective) {
    if (dp != null) {
      dp.toggleCalendar();
      this.update();
    }
  }

  setPickerDate(pickerDate: Date) {
    if (pickerDate == null) {
      this.myDatePickerModel = null;
      return;
    }
    this.myDatePickerModel = {
      isRange: false,
      singleDate: { date: new Object({
        year: pickerDate.getFullYear(),
        month: pickerDate.getMonth() + 1,
        day: pickerDate.getDate()
      })},
    } as IMyDateModel;
  }

  update() {
    this._changeDet.markForCheck();
   }
}
