import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { MustBeAuthenticatedGuard } from './auth/must-be-authenticated.guard';
import { MustBeAdminGuard } from './auth/must-be-admin.guard';
import { CanActivateSandboxGuard } from './auth/can-activate-sandbox.guard';
import { DashboardComponent } from './portal/dashboard/dashboard.component';
import { AccountOverviewComponent } from './portal/account-overview/account-overview.component';
import { AdminPortalRootComponent } from './admin-portal/admin-portal-root/admin-portal-root.component';
import { PortalRootComponent } from './portal/portal-root/portal-root.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DocumentsComponent } from './portal/documents/documents.component';
import { DisclaimerComponent } from './portal/disclaimer/disclaimer.component';
import { KYCComponent } from './portal/kyc/kyc.component';
// import { WstestComponent } from './wstest/wstest.component';
// import { LoggerTestComponent } from './shared/logger-test/logger-test.component';
import { CanActivateSandboxProtoGuard } from './auth/can-activate-sandbox-proto.guard';
import { NotSandboxOnlyGuard } from './auth/not-sandbox-only.guard';
import { FirstTimeComponent } from './auth/first-time/first-time.component';
import { AgreedToTermsGuard } from './auth/agreed-to-terms.guard';

// import { SettingsModule } from './settings/settings.module';


export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/portal/dashboard',
        pathMatch: 'full'
    },
    { path: 'login', component: LoginComponent, data: { showNavbar: false } },
    {
        canActivate: [MustBeAuthenticatedGuard, NotSandboxOnlyGuard],
        path: 'first_time',
        component: FirstTimeComponent,
        data: { showNavbar: false },
    },
    {
        component: PortalRootComponent,
        path: 'portal',
        canActivate: [MustBeAuthenticatedGuard, NotSandboxOnlyGuard, AgreedToTermsGuard],
        canActivateChild: [AgreedToTermsGuard],
        children: [
        {
            path: '',
            redirectTo: '/portal/dashboard',
            pathMatch: 'full'
        },
        {
            path: 'dashboard',
            component: DashboardComponent,
        },
        {
            path: 'account/:id',
            component: AccountOverviewComponent,
            data: { showNavbar: false },
        },
        {
            path: 'account',
            redirectTo: '/portal/dashboard',
            pathMatch: 'full',
            // component: AccountComponent,
        },
        {
            path: 'sandbox',
            loadChildren: () => import('./sandbox3/sandbox3.module').then(m => m.Sandbox3Module),
            canActivate: [ CanActivateSandboxProtoGuard, ],
        },
        {
            path: 'sandbox-proto',
            redirectTo: '/portal/sandbox'
        },
        {
            path: 'documents',
            component: DocumentsComponent,
        },
        {
            path: 'disclaimer',
            component: DisclaimerComponent,
        },
        {
          path: 'kyc',
          component: KYCComponent
          // loadChildren: () => import('./kyc/kyc.module').then(m => m.KycModule),
        },
        {
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        },
        {
            path: 'admin',
            component: AdminPortalRootComponent,
            canActivate: [MustBeAuthenticatedGuard, MustBeAdminGuard],
            children: [
            {
                path: 'users',
                component: AdminPortalRootComponent,
            },
            {
                path: 'users/create',
                component: AdminPortalRootComponent,
            },
            {
                path: 'users/view/:id',
                component: AdminPortalRootComponent,
            },
            ],
        },
        ],
    },
    {
        path: 'sandbox',
        loadChildren: () => import('./sandbox3/sandbox3.module').then(m => m.Sandbox3Module),
        canActivate: [ CanActivateSandboxProtoGuard, ],
    },
    // { path: 'logger-test', component: LoggerTestComponent, canActivate: [MustBeAuthenticatedNotSandboxOnlyGuard]},
    // { path: 'wstest', component: WstestComponent, },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
imports: [
    RouterModule.forRoot(
        appRoutes,
        {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
        } as ExtraOptions,
    ),
],
exports: [
    RouterModule,
]
})
export class AppRoutingModule {}
