<ng-progress id="app-http" [spinner]="false"></ng-progress>
<nav #appNavbar
    class="navbar has-shadow"
    *ngIf="appService.isNavbarVisible$|async"
    [ngClass]="{'menu-mode-icons': (menuMode$|async) === 'icons', 'scrolled-up': appService.isNavbarScrolledUp|async}">
    <!--
    <div class="navbar-item navbar-brand">
        <div class="clarity-logo">

        </div>
    </div>
-->
    <div class="navbar-brand navbar-item is-block-touch">
        <div class="navbar-burger burger is-hidden-tablet is-pulled-right" data-target="navMenubdExample" (click)="toggleSideBar()">
                <span></span>
                <span></span>
                <span></span>
        </div>
    </div>
</nav>

<div class="hero" [class.topNavbar]="appService.isNavbarVisible$|async">
    <div *ngIf="isSidebarVisible$|async" class="mobile-sidebar-menu">
        <div class="clarity-logo">
            <div class="top-row">
                <a routerLink="/" (click)="toggleSideBar()">
                    Clarity Platform
                </a>
                <button class="close button is-pulled-right" (click)="toggleSideBar()" title="Close">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>
            <div class="purpose-statement">
                Helping people prepare for tomorrow
            </div>
        </div>
        <div class="menu">
            <div class="menu-item" *ngFor="let item of (mainMenuItems$|async)">
                <a  class="sidebar-item navbar-item"
                [ngClass]="itemClasses(item)"
                [routerLink]="[item.url]">
                    <i class="fa fa-{{item.icon}} menu-item-icon" aria-hidden="true"></i>
                    <span class="label">{{item.label}}</span>
                    <i *ngIf="item?.highlighted && item?.subMenuItems?.length === 0" class="high-light-logo fa fa-caret-left"></i>
                <ng-container *ngIf="item?.highlighted && item?.subMenuItems?.length > 0">
                    <i class="high-light-logo fa" (click)="toggleSubMenuCollapsed(item?.url, $event)" [ngClass]="collapseIcon(item)"></i>
                </ng-container>
                </a>
                <ul *ngIf="item?.highlighted && !subMenuCollapsed.get(item.url) && item?.subMenuItems?.length > 0" class="sub-items">
                    <ng-container *ngFor="let subItem of item?.subMenuItems">
                    <li *ngIf="!(subItem?.hidden|async)"
                        class="sidebar-item navbar-item sub-item"
                        [ngClass]="{'navbar-highlight': subItem.highlighted}">
                        <div class="item-wrapper">
                            <div class="item">
                                <a [routerLink]="[subItem?.url]" [fragment]="subItem?.fragment || null">
                                    <i class="fa fa-square menu-item-icon" aria-hidden="true"></i>
                                    <span class="label">{{subItem.label}}</span>
                                    <i *ngIf="subItem.highlighted" class="high-light-logo fa fa-caret-left"></i>
                                </a>
                            </div>
                        </div>
                        <ul *ngIf="subItem?.subMenuItems?.length > 0" class="sub-items">
                                <ng-container *ngFor="let subSubItem of subItem?.subMenuItems">
                                <li *ngIf="!(subSubItem?.hidden|async)"
                                    class="sidebar-item navbar-item sub-item"
                                    [ngClass]="{'navbar-highlight': subSubItem.highlighted}">
                                    <div class="item-wrapper">
                                        <div class="item">
                                            <a [routerLink]="[subSubItem?.url]" [fragment]="subSubItem?.fragment || null">
                                                <i class="fa fas fa-angle-double-right menu-item-icon" aria-hidden="true"></i>
                                                <span class="label">{{subSubItem.label}}</span>
                                                <i *ngIf="subSubItem.highlighted" class="high-light-logo fa fa-caret-left"></i>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ng-container>
                </ul>
            
            </div>
        </div>
        <div class="control logout">
            <a class="button" (click)="logout()">
                <span>Log out</span>
            </a>
        </div>
    </div>



    <router-outlet></router-outlet>
</div>


<div *ngIf="appService.isErrorVisible" class="modal" [ngClass]="{'is-active': appService.isErrorVisible}">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title" [innerHTML]="appService.error.title"></p>
            <button class="delete" (click)="appService.hideError()" aria-label="close"></button>
        </header>
        <section class="modal-card-body" [innerHTML]="appService.error.message">
        </section>
        <footer class="modal-card-foot">
            <button class="button" (click)="appService.hideError()">Close</button>
        </footer>
    </div>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>

<app-ui-snackbar
    *ngIf="snackbar$ | async as snackbar"
    [options]="snackbar">
</app-ui-snackbar>
