import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';

import { SpinnerService } from '@chevtek/angular-spinners';
import * as mime from 'mime';

import { AuthenticationService, User } from '../../auth/authentication.service';
import { AppService } from '../../app-service.service';
import { PspDocumentsService, int, RemoteFileInfo, RemoteFileBuffer, guid } from '../../api/psp-documents.service';
import b64toBlob from 'b64-to-blob';
import naturalSort from '../../natural-sort';

import { DocumentDisplayInfo } from './document-display-info';
import { throwError } from 'rxjs';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-fund-documents',
  templateUrl: './fund-documents.component.html',
  styleUrls: ['./fund-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundDocumentsComponent implements OnInit, AfterViewInit {
  @Input() accountId: string;

  public availableDocuments: RemoteFileInfo[];
  public displayDocuments: Map<number, Array<DocumentDisplayInfo>> = new Map();
  public catList: { name: string, id: number }[] = [];
  public fetchLoading: string;
  public filteredDocuments: Array<DocumentDisplayInfo> = [];
  public noDocumentsAvailable = false;

  private docListSelectedTab = 0;
  private docCategoryIdToTabMapping = new Map<number, number>([
    [ 2, 3, ], // Monthly Fund Report
    [ 3, 0, ], // Company Presentation
    [ 4, 0, ], // Marketing Presentation
    [ 5, 2, ], // Research Report
    [ 6, 1, ], // Risk Premia Presentation
    [ 7, 0, ], // User Guide
    [ 8, 1, ], // Risk Premia One-pager
  ]);
  private user: User;

  constructor(
    private _appService: AppService,
    private _authService: AuthenticationService,
    private _changeDetRef: ChangeDetectorRef,
    private _pspDocService: PspDocumentsService,
    private _spinnerService: SpinnerService
  ) { }

  // Get Font Awesome icon for file extension
  public docFaIcon(ext: string): string {
    const extIconMap = new Map<string, string>([
      ['doc', 'file-word-o'],
      ['docx', 'file-word-o'],
      ['gif', 'file-image-o'],
      ['jpg', 'file-image-o'],
      ['jpeg', 'file-image-o'],
      ['m4a', 'file-audio-o'],
      ['mp3', 'file-audio-o'],
      ['mp4', 'file-video-o'],
      ['pdf', 'file-pdf-o'],
      ['png', 'file-image-o'],
      ['ppt', 'file-powerpoint-o'],
      ['pptx', 'file-powerpoint-o'],
      ['svg', 'file-image-o'],
      ['xls', 'file-excel-o'],
      ['xlsx', 'file-excel-o'],
    ]);
    const defaultIcon = 'file-o';

    if (extIconMap.has(ext.toLowerCase())) {
      return extIconMap.get(ext.toLowerCase());
    } else {
      return defaultIcon;
    }
  }

  selectDocument(selectedDocs: any) {
    const ids = Object.keys(selectedDocs);
    for (let i = 0; i < ids.length; i++) {
      if ( selectedDocs[ids[i]] === 'on') {
        this.fetchDocument(ids[i]);
      }
    }
  }

  fetchDocument(docId: guid): void {
    this.fetchLoading = docId;
    this._pspDocService.getDocumentBuffered$(this.user.userId, docId).subscribe({
      next: (data: RemoteFileBuffer) => {
        let mimeType = 'application/octet-stream';
        let fileName: string;
        const docInfo = this.findFileInfo(docId);
        if (docInfo) {
          mimeType = mime.getType(docInfo.FileExtension);
          fileName = `${docInfo.DisplayName}.${docInfo.FileExtension}`;
        }

        const blob = b64toBlob(data['FileData'], mimeType);

        // The rest off this function is based on
        // https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        if (fileName) {
          link.download = fileName;
        }

        // special case for Chrome for iOS (checked with version 64.0.3282.112)
        if (window.navigator && window.navigator.userAgent.match('CriOS')) {
          const reader = new FileReader();
          reader.addEventListener('load', function(e) {
            window.location.href = reader.result as string;
          });
          reader.readAsDataURL(blob);
        }

        // Have to add link to DOM for Firefox.
        document.body.appendChild(link);
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 250);
      },
      error: (err) => {
        this._appService.showError('Error', 'Error while downloading data from the server. Try refreshing the page.');
        this.fetchLoading = null;
        this._changeDetRef.markForCheck();
      },
      complete: () => {
        this.fetchLoading = null;
        this._changeDetRef.markForCheck();
      }
    });
  }

  public loadAccountDocuments(userId: int, accountId: string): void {
    this._spinnerService.show('loadDocuments');
    this._pspDocService.getAccountDocuments$(userId, accountId).subscribe({
      next: (data: RemoteFileInfo[]) => {
        this.availableDocuments = data;
        let docs = this.availableDocuments.map(item => {
          return <DocumentDisplayInfo>{
            DisplayIcon: `<div class="doc-icon"><i class="fa fa-${this.docFaIcon(item.FileExtension)}" aria-hidden="true"></i></div>`,
            DisplayName: item.DisplayName,
            DocumentId: item.DocumentId,
            DocumentCategoryName: item.DocumentCategory.CategoryName,
            DocumentCategoryId: item.DocumentCategory.CategoryId,
            FileExtension: item.FileExtension,
            LengthBytes: item.LenghtBytes,
            FileDate: item.FileDate
          };
        });
        const docMap = new Map<number, DocumentDisplayInfo[]>();
        for (const d of docs) {
          let catDocs: DocumentDisplayInfo[];
          if (docMap.has(d.DocumentCategoryId)) {
             catDocs = docMap.get(d.DocumentCategoryId);
          } else {
            this.catList.push({name: d.DocumentCategoryName, id: d.DocumentCategoryId});
            catDocs = [];
          }
          catDocs.push(d);
          docMap.set(d.DocumentCategoryId, catDocs);
        }
        for (const docList of docMap.values()) {
          docList.sort((a, b) => naturalSort(a.DisplayName, b.DisplayName));
        }
        this.displayDocuments = docMap;
        this.noDocumentsAvailable = (data.length === 0);
        this._spinnerService.hide('loadDocuments');
      },
      error: (err) => {
        this._spinnerService.hide('loadDocuments');
        this._appService.showError('Error', 'Error while downloading data from the server. Try refreshing the page.');
      },
      complete: () => this._changeDetRef.markForCheck()
    });
  }

  ngOnInit() {
    // this._spinnerService.show('loadDocuments');
    this.docListSelectedTab = 0;
    this._authService.getCurrentUser().subscribe((user: User) => {
      if (user.userId === null) {
        this._appService.showError('Account error', 'Your account has a configuration error. Please contact Clarity platform support.');
        return throwError('userId is not set for the current user!');
      }
      this.user = user;
      this.loadAccountDocuments(this.user.userId, this.accountId);
    }, (err) => {
        this._spinnerService.hide('loadDocuments');
        this._appService.showError('Error', 'Error while downloading data from the server. Try refreshing the page.');
      });
  }

  ngAfterViewInit() {
  }

  updateFilter() {
    const docCatIdSet = new Set<number>();
    const documentCats = this.availableDocuments.map(doc => doc.DocumentCategory).filter(cat => {
      if (docCatIdSet.has(cat.CategoryId) ) {
        return false;
      } else {
        docCatIdSet.add(cat.CategoryId);
        return true;
      }
    });
    documentCats.sort((c1, c2) => naturalSort(c1.CategoryName, c2.CategoryName));
    const newMap = new Map<number, number>();
    const newTabs = [];
    documentCats.forEach((cat, i) => {
      newTabs.push(`${cat.CategoryName}s`);
      newMap.set(cat.CategoryId, i + 1);
    });
    this.catList = newTabs;
    this.docCategoryIdToTabMapping = newMap;
    this._changeDetRef.markForCheck();
  }


  private findFileInfo(docId: guid): RemoteFileInfo {
    return this.availableDocuments.find(fi => {
      return fi.DocumentId === docId;
    });
  }
}
