export class SandboxPortfolioMetaResponse {
  id?: number;
  userId: number;
  combinedIndexId: number;
  lastModifiedDate: string;
  starred: boolean;
  hidden: boolean;
  
  constructor(json?: any) {
    if (json) {
      Object.assign(this, json);
    }
  }
}
