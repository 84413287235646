import { decimal, int } from "./psp-backend.models";

export class Contacts{
    Address: string;
    City: string;
    CountryCode: string;
    Email: string;
    EntityId: int;
    FirstName: string;
    KYC: PrivateKYC;
    LastName: string;
    NationalityCode: string;
    Pep: boolean;
    PepDescription: string;
    SocialSecurityNumber: string;
    Telephone: string;
    UserId: int;
    Zip: string;
  }

export class OnBehalfOfEntities {
    EntityId?: int;
    FirstName?: string;
    ID?: string;
    IsCompany?: boolean;
    LastName?: string;
    Name?: string;
    OnBehalfOfEntityId?: int;
}


  export class KYC{
    Age: int;
    AgreeToUpgrade: boolean;
    AgreementOnUnderstandingTheRisk: boolean;
    AgreementToAdvice: boolean;
    BadNewsTolerance: string;
    EntityId: int;
    InvestmentHorison: string;
    InvestmentOnBehalfOfTheseEntities: OnBehalfOfEntities[];
    InvestmentPlanToTheseFunds: decimal;
    InvestmentSize: int;
    IsPrivate: boolean;
    IsProfessional: boolean;
    IsSemiprofessional: boolean;
    KYCId: int;
    LoanSize: decimal;
    OpinionOnExperience: string;
    QuantitiveRequirements: int;
    ReturnGoal: decimal;
    RiskTolerance: string;
    TotalInvestedAssets: int;
    UserId: int;
    VolatilityGoal: decimal;
    WhealthAndTotalAssets: decimal;
  }
  export class PrivateKYC extends KYC {
    EconomicCommitmentsMonthly: string;
    EstimatedIncome: string;
    InvestmentEntityId: int;
    InvestmentObjective: string;
    InvestmentOnBehalfOfOtherEntity: boolean;
    LonsAndOtherObligations: decimal;
    OriginOfInvestedAssests: string;
    TotalAssets: string;
  }
  export class Companies{
    Active: boolean;
    Address: string;
    BussinessId: string;
    City: string;
    CountryCode: string;
    Domicile: string;
    DomicileCode: string;
    Email: string;
    EntitiesMainBusinessAccordingToTheArticlesOfAssociation: string;
    EntityId: int;
    KYC: CorporateKYC;
    NameOfTheEntity: string;
    RepresentativeContact: Contacts;
    Telephone: string;
    UserId: int;
    Zip: string;
  }
  export class CorporateKYC extends KYC {
    Balance: decimal;
    DoesSomebodyOwnMoreThen25Percent: boolean;
    EntityType: string;
    Equity: decimal;
    HowMuchDoYouPlanToInvest: int;
    InvestmentObjective: string;
    IsSomeoneOfTheBeneficiariesPEP: boolean;
    MoreThen25PercentOwners: Contacts[];
    NonProfitOrganisation: boolean;
    OwnInvestment: boolean;
    PepOwners: Contacts[];
    ProfitLoss: decimal;
    SourceOfAssetsUsedForTheInvestment: string;
    Turnover: decimal;
  }
  