import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { AppService } from '../app-service.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class CanActivateSandboxProtoGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private appService: AppService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.authService.sandboxProtoAllowed().pipe(
        tap( allowed => {
          if (!allowed) {
            this.appService.showError(
              'Access denied',
              '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i> You do not have access to this feature.'
            );
            this.router.navigate(['/portal/dashboard']);
          }
        }),
      );
  }
}
