<div class=" is-marginless is-paddingless">
    <div  *ngIf="(menuMode$ | async) as menuMode" class="columns is-marginless is-paddingless" [ngClass]="'menu-mode-'+menuMode">
        <div class="column portal-sidebar is-hidden-mobile" [ngClass]="menuMode === 'icons' ? 'is-narrow' : 'is-2'">
            <aside class="aside is-marginless is-paddingless">
                <div *ngIf="menuMode === 'labels'" class="clarity-logo">
                    <button class="menu-collapse" (click)="setMenuMode('icons')" title="Minimize menu" type="button">
                        <i class="fa fa-minus"></i>
                    </button>
                    <a routerLink="/">
                        <img alt="Clarity Platform" src="/static/assets/clarity-logo.png" srcset="/static/assets/clarity-logo_x2.png" width="122">
                    </a>
                    <div class="purpose-statement">
                        Helping people prepare for tomorrow
                    </div>
                </div>
                <div *ngIf="menuMode === 'icons'" class="sidebar-item navbar-item menu-item-expand">
                    <i class="fa fa-bars" (click)="setMenuMode('labels')" title="Expand menu"></i>
                </div>
                <ng-container *ngFor="let item of mainMenuItems$|async">
                    <ng-container *ngIf="menuItemClasses$|async as itemClassMap">
                        <div *ngIf="!(item?.hidden|async)"
                            class="sidebar-item navbar-item"
                            [ngClass]="itemClassMap.get(item?.url + item?.fragment)">
                            <div class="item-wrapper">
                                <div class="item">
                                    <a routerLink="{{item?.url}}" [fragment]="item?.fragment || null">
                                        <i class="fa fa-{{item?.icon || 'circle'}} menu-item-icon" aria-hidden="true" [attr.title]="item.label"></i>
                                        <span>{{item.label}}</span>
                                        <i *ngIf="item?.highlighted && (item?.subMenuItems?.length === 0 || menuMode === 'icons')" class="high-light-logo fa fa-caret-left"></i>
                                        <!--
                                        <ng-container *ngIf="item?.highlighted && (item?.subMenuItems?.length > 0 && menuMode === 'labels')">
                                            <i class="high-light-logo fa" (click)="toggleSubMenuCollapsed(item?.url, $event)" [ngClass]="collapseIcon(item)"></i>
                                        </ng-container>
                                        -->
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!--
                    <ul *ngIf="item?.highlighted && !subMenuCollapsed.get(item.url) && item?.subMenuItems?.length > 0" class="sub-items">
                        <ng-container *ngFor="let subItem of item?.subMenuItems">
                        <li *ngIf="!(subItem?.hidden|async)"
                            class="sidebar-item navbar-item sub-item"
                            [ngClass]="{'navbar-highlight': subItem.highlighted}">
                            <div class="item-wrapper">
                                <div class="item">
                                    <a [routerLink]="[subItem?.url]" [fragment]="subItem?.fragment || null">
                                        <i class="fa fa-square menu-item-icon" aria-hidden="true"></i>
                                        <span>{{subItem.label}}</span>
                                        <i *ngIf="subItem.highlighted" class="high-light-logo fa fa-caret-left"></i>
                                    </a>
                                </div>
                            </div>
                            <ul *ngIf="subItem?.subMenuItems?.length > 0" class="sub-items">
                                    <ng-container *ngFor="let subSubItem of subItem?.subMenuItems">
                                    <li *ngIf="!(subSubItem?.hidden|async)"
                                        class="sidebar-item navbar-item sub-item"
                                        [ngClass]="{'navbar-highlight': subSubItem.highlighted}">
                                        <div class="item-wrapper">
                                            <div class="item">
                                                <a [routerLink]="[subSubItem?.url]" [fragment]="subSubItem?.fragment || null">
                                                    <i class="fa fas fa-angle-double-right menu-item-icon" aria-hidden="true"></i>
                                                    <span>{{subSubItem.label}}</span>
                                                    <i *ngIf="subSubItem.highlighted" class="high-light-logo fa fa-caret-left"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>
                    -->
                </ng-container>
                <div class="info-item">
                    <div *ngIf="(appService.updateTime$|async) as updTime" class="data-update-time">
                        Data as of <span style="white-space:nowrap">{{ updTime }}</span>
                    </div>
                    <div class="clarity-version">
                        Clarity platform <span>beta v{{appVersion}}</span>
                    </div>
                    <div class="logged-in" *ngIf="authenticationService.isLoggedIn">
                        <div class="logged-in-username">Logged in as &#8220;{{(user$|async)?.userName}}&#8221;</div>
                        <button class="button" (click)="logout()" type="button">
                            <i class="fa fa-sign-out menu-item-icon"></i>
                            <span>Log out</span>
                        </button>
                        <button class="button is-small" (click)="logout()" type="button" title="Log out">
                            <span class="icon"><i class="fa fa-sign-out menu-item-icon"></i></span>
                        </button>
                    </div>
                </div>
            </aside>
        </div>
        <div class="column is-12-mobile is-paddingless" [ngClass]="menuMode === 'labels' ? 'is-10' : 'is-12'">
            <div>
                <router-outlet></router-outlet>
                <footer class="all-page-footer">
                    <div class="card session-info is-hidden-desktop">
                        <div class="card-content">
                            <span *ngIf="(user$|async)?.userName as userName" class="logged-in-user">Logged in as &#8220;{{userName }}&#8221;</span><br>
                            <span *ngIf="(appService?.updateTime$)|async as updTime" class="update-time">Data as of {{ updTime }}<br></span>
                            <span class="app-version">Clarity platform beta v{{appVersion}}</span>
                        </div>
                    </div>
                    <div class="card" *ngIf="showDisclaimer">
                        <div class="card-content">
                            <div [ngClass]="{ 'floating' : !(floatingDisclaimerClosed$|async) }">
                                <button class="delete close-floating" (click)="closeFloatingDisclaimer()" title="Acknowledge & close disclaimer">
                                    Acknowledge & close disclaimer
                                </button>
                                <p>Compliance statement: Returns may be ESTIMATIONS and are subject to uncertainty and errors, e.g. fees may not be included in NAV calculations, therefore the performance may not reflect actual client account performance.
                                  Past performance is not necessarily indicative of future results. Source: Estlander & Partners
                                  For further information on respective portfolios, click <i class="fa fa-info-circle"></i>.
                                </p>
                                <p>
                                    <strong>IMPORTANT NOTICE</strong>:
                                    The contents of this website are made available subject to <a routerLink="/portal/disclaimer">THIS DISCLAIMER</a>.
                                </p>
                            </div>
                            <p class="has-centered-text">&copy;{{currentYear}} Estlander & Partners Oy | Unioninkatu 20-22, FIN-00100 Helsinki, Finland</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>

    </div>
</div>
