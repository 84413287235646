import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { PspDocumentsService } from '@app/api/psp-documents.service';
import { PspWebService } from '@app/api/webservice.service';
import { HttpClientService } from '@app/http-client.service';
import { retryBackoff } from 'backoff-rxjs';
import * as Highcharts from 'highcharts';
import { isArray, isEmpty } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, merge, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { debounceTime, finalize, map, mergeMap, pluck, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { TZ } from '../account-overview/utils/constants';
import {
  Account,
  AccountDailyPerformanceOverview,
  AccountDailyRiskOverview,
  AccountHoldingsOverview,
  AccountTradingLevel,
  AssetClassCumulativeReturns,
  AssetClassDeltas,
  AssetClassExposures,
  AssetClassGammas,
  AssetClassIntradayPerformances,
  AssetClassSignalStrength,
  DailyDelta, DailyGamma, DailyReturnData,
  DeltaOverview,
  ExAnteVolatility,
  GammaAndDeltaOverview,
  GammaOverview,
  HoldingsOverview,
  Index,
  IndexDailyPerformanceOverview,
  IndexDailyRiskOverview,
  IndexHoldingsOverview,
  int,
  IntradayPerformancePoint,
  MarginRequirement,
  PerformanceOverview,
  Product,
  ProductExposure,
  ProductIntradayPrices,
  ProductPerformanceAggregations,
  RealTrackStartDate,
  RiskOverview,
  StrategyStyleCumulativeReturns,
  StrategyStyleIntradayPerformances,
  SubAssetClassExposures,
  SubAssetClassMarginRequirement,
  SubAssetClassPerformanceAggregations,
  SubAssetClassVaRDetails,
  VaRPoint,
  VolatilityOverview,
  VolatilityPoint
} from '../api/psp-backend.models';
import { AuthenticationService } from '../auth/authentication.service';
import { ActivePortfolio, BreakdownNumberDisplayType } from '../settings-data/active-portfolio';
import { SettingsDataService } from '../settings-data/settings-data.service';
import { LoggingService } from '../utils/logging/logging.service';
import { AccountDataCacheService } from './account-data-cache.service';
import { AcctIdx, cmpAcctIdxs } from './acct-idx';
import { cmpAssetClasses } from './asset-class-sorting';


export interface AccountIndexDescription {
  portfolio_id: string;
  portfolio_description: string;
}

export function dt2midnight(d: Date | string | moment.Moment): moment.Moment {
  return moment.tz(d, TZ).startOf('day');
}

export function dt2midnights(d: Date | string | moment.Moment): string {
  return dt2string(dt2midnight(d));
}

/** Convert a date-ish object to a string in TZ time (without tz specifier) */
export function dt2string(d: Date | string | moment.Moment) {
  return moment.tz(d, TZ).format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * Determine if portfolio is of type Index or Account
 *
 * @param pfId - portfolio id
 */
export function isIndex(pfId: string | number): boolean {
  // assumes that AccountId always contains at least one letter, so any id that successfully converts into a Number is an IndexId
  const number = Number(pfId);
  return !isNaN(number);
}

export function isHttpError(error: {}): error is HttpErrorResponse {
  return (error as HttpErrorResponse).status !== undefined;
}

export const ONE_DAY_MS = moment.duration(1, 'days').asMilliseconds();

@Injectable()
export class AccountDataService implements OnDestroy {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public cancelAccountOverviewSubj = new Subject<void>();
  public cancelPerformanceAggregationSubj = new Subject<void>();
  public cancelPerformanceBreakdown$: Observable<void>;
  public showTradingLevelBasedFiguresUpdateSignal$: Observable<void>;
  public tradingLevel$ = new BehaviorSubject<AccountTradingLevel>(null);

  private _accountIndexDescriptions$: Observable<AccountIndexDescription[]>;
  private dateFormat = 'YYYY-MM-DD';
  private dateTimeFormat = 'YYYY-MM-DDThh:mm:ss';
  private destroy = new Subject<void>();
  private _refreshAccountIndexDescriptions = new BehaviorSubject<void>(undefined);
  private _signalDynamicsAvailableSubj = new ReplaySubject<boolean>(1);
  private _showTradingLevelBasedFiguresAccountIds: (number|string)[] = [];
  private tz = 'Europe/Helsinki';
  private _showTradingLevelBasedFiguresUpdateSignalSubj = new Subject<void>();

  constructor(
    private _cache: AccountDataCacheService,
    private _auth: AuthenticationService,
    private _docSvc: PspDocumentsService,
    private _http: HttpClientService,
    private _logger: LoggingService,
    private _settingsService: SettingsDataService,
    private _ws: PspWebService,
  ) {
      this.showTradingLevelBasedFiguresUpdateSignal$ = this._showTradingLevelBasedFiguresUpdateSignalSubj.asObservable();
      this.updateShowTradingLevelBasedFiguresAccountIds();
      this._signalDynamicsAvailableSubj.next(false);

      // combine cancel triggers for whole account overview and for performance section
      this.cancelPerformanceBreakdown$ = merge(
        this.cancelAccountOverviewSubj,
        this.cancelPerformanceAggregationSubj
      ).pipe(takeUntil(this.destroy), debounceTime(200));
      this.cancelPerformanceBreakdown$.subscribe(() => {
        this._logger.log('Cancelling performance breakdown update.');
      });
  }

  get defaultPeriod$() {
    return this._settingsService.getDefaultPerformancePeriodSetting$();
  }

  public getDailyPerformanceOverview(
    userId: number,
    accountId: number | string,
    date: Date,
    statsDays: number,
    drawdownStart: Date,
    benchmarkStart: Date
  ): Observable<AccountDailyPerformanceOverview | IndexDailyPerformanceOverview> {
    if (isIndex(accountId)) {
      return this._ws.getIndexDailyPerformanceOverview(
        userId,
        +accountId,
        dt2midnights(date),
        statsDays,
        drawdownStart && dt2midnights(drawdownStart) || null,
        benchmarkStart && dt2midnights(benchmarkStart) || null,
      );
    } else {
      return this._ws.getAccountDailyPerformanceOverview(
        userId,
        accountId,
        dt2midnights(date),
        statsDays,
        drawdownStart && dt2midnights(drawdownStart) || null,
        benchmarkStart && dt2midnights(benchmarkStart) || null,
      );
    }
  }

  /**
   * Retrieve list of documents attached to account from documents service
   * @param userId user id number
   * @param accountId account id - will always return null if number
   */
  getAttachedDocuments$(userId: number, accountId: string | number, forceUpdate = false) {
    if (userId == null || accountId == null || isIndex(accountId)) {
      return of(null);
    }
    const aId = accountId.toString();
    const dayMs = ONE_DAY_MS;
    const cachedDocs = this._cache.getCachedAccountDocuments(userId, aId, dayMs);
    const docs$ = cachedDocs != null && !forceUpdate ? of(cachedDocs) : this._docSvc.getAccountDocuments$(userId, aId).pipe(
      tap((docs) => {
        if(!(docs instanceof Error)) {
          this._cache.updateCachedAccountDocuments(userId, aId, docs);
        }
      }),
    );
    return docs$;
  }

  /**
   * Get AssetClassSignalStrengths in format suitable for use in Highchart
   * @param userId
   * @param accountId
   * @param startDate
   * @param endDate
   */
  public getAssetClassesSignalStrengthSeries(
    userId: number,
    accountId: string,
    startDate: moment.Moment,
    endDate: moment.Moment
  ): Observable<Highcharts.SeriesOptions[]> {
    const startDateStr = startDate.tz(this.tz).format(this.dateTimeFormat);
    const endDateStr = endDate.tz(this.tz).format(this.dateTimeFormat);
    
    const cachedValue = this._cache.getAccountAssetClassSignalStrengths(userId, accountId, startDateStr, endDateStr);

    let strengths$: Observable<AssetClassSignalStrength[]>;
    if (cachedValue !== undefined ) {
      strengths$ =  of(cachedValue);
    } else {
      this._ws.getAccountAssetClassesSignalStrengths(
        userId,
        accountId,
        startDateStr,
        endDateStr
      );
    }

    return strengths$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
      tap((acStrengths) => {
        this._cache.updateAccountAssetClassSignalStrengths(userId, accountId, startDateStr, endDateStr, acStrengths)
      }),
      map(acStrengths =>  {
        acStrengths.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
        return acStrengths.map((acStrength, i) => {
          return {
            name: acStrength.AssetClass.Name,
            data: acStrength.SignalStrengths.map(strength => {
              return {
                x: moment.tz(strength.Date, this.tz).utc().valueOf(),
                y: strength.Signal * 100, // percent
              } as Highcharts.Point;
            }),
            type: 'line',
            visible: i === 0, // Show only the first series
          } as Highcharts.SeriesLineOptions;
        });
      }),
    );
  }

  getExAnteVolatilityHistorical(userId: number, accountId: string | number, startDate: Date, endDate: Date): Observable<VolatilityPoint[]> {
    let request$: Observable<VolatilityPoint[]>;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexExAnteVolatilityHistorical(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountExAnteVolatilityHistorical(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(takeUntil(this.cancelAccountOverviewSubj));
  }

  // Fetch lists of accounts and indices from backend.
  // If `all` === false acctIdxs will be filtered by active_portfolios setting
  public loadAcctIdxs(all = false, forceUpdate = false): Observable<[AcctIdx[], Account[], Index[]]> {
    this.isLoading$.next(true);
    const all$ = this._cache._loadAcctIdxCache$ = this._auth.getCurrentUser().pipe(
      mergeMap(user => {
        if ( user == null) {
          return throwError('No valid user');
        }
        const dayMs = ONE_DAY_MS;
        const cachedAccounts = this._cache.getCachedAccounts(user.userId, dayMs);
        const accounts$ = cachedAccounts && !forceUpdate ? of(cachedAccounts) : this._ws.getAccounts(user.userId).pipe(
          tap((accounts) => this._cache.updateCachedAccounts(user.userId, accounts)),
        );
        const cachedIndices = this._cache.getCachedIndices(user.userId, dayMs);
        const indices$ = cachedIndices && !forceUpdate ? of(cachedIndices) : this._ws.getIndices(user.userId).pipe(
          tap(indices => this._cache.updateCachedIndices(user.userId, indices)),
        );
        return forkJoin<Account[], Index[]>([
          accounts$,
          indices$.pipe(
            // remove Sandbox indices
            map(indcies => indcies.filter(idx => idx.IndexType.IndexTypeId !== 5) )
          ),
        ]).pipe(
          takeUntil(this.cancelAccountOverviewSubj),
          map<[Account[], Index[]], [AcctIdx[], Account[], Index[]]>((data) => {
            const newAcctIdxs: AcctIdx[] = [];
            let accounts: Account[];
            let indecis: Index[];
            [accounts, indecis] = data as [Account[], Index[]];

            if (Array.isArray(accounts) && accounts.length > 0) {
              accounts.map(obj => {
                newAcctIdxs.push(new AcctIdx(obj));
              });
            }
            if (Array.isArray(indecis) && indecis.length > 0) {
              indecis.filter(idx => idx.IndexType.IndexTypeId !== 5).map(obj => {
                newAcctIdxs.push(new AcctIdx(obj));
              });
            }
            newAcctIdxs.sort(cmpAcctIdxs);

            return [newAcctIdxs, accounts, indecis];
          }),
        );
      }),
      finalize(() => this.isLoading$.next(false)),
    );
    if (all) {
      return all$;
    } else {
      return all$.pipe(
        // filter and sort by settings
        switchMap<[AcctIdx[], Account[], Index[]], Observable<[AcctIdx[], Account[], Index[]]>>(
          (data: [AcctIdx[], Account[], Index[]]): Observable<[AcctIdx[], Account[], Index[]]> => {
          if (data == null) {
            return of(null);
          }
          const [acctIdxs, accts, idxs] = data;
          return this._settingsService.getActivePortfoliosSettings().pipe(
            map((activePortfolios: ActivePortfolio[]): [AcctIdx[], Account[], Index[]] => {
              const inAcctIdxs = acctIdxs;
              const outAcctIdxs: AcctIdx[] = [];
              const outAccts: Account[] = [];
              const outIdxs: Index[] = [];

              if (Array.isArray(activePortfolios)) {
                activePortfolios.sort((a, b) => a.Order - b.Order);

                for (const ap of activePortfolios) {
                  const found = inAcctIdxs.find(ai => ai.Id === ap.PortfolioId.toString());
                  if (found) {
                    found.Order = ap.Order;
                    outAcctIdxs.push(found);
                    if (found.Type === 'account') {
                      outAccts.push(accts.find(a => a.AccountId === found.Id));
                    }
                    if (found.Type === 'index') {
                      outIdxs.push(idxs.find(i => i.IndexId.toString() === found.Id));
                    }
                  }
                }
              }
              return [outAcctIdxs, outAccts, outIdxs];
            }),
          );
        }),
      );
    }
  }

  getAccountTradingLevel(userId: number, accountId: string, date: Date | string) {
    if (typeof date === 'object') {
      date = date.toISOString();
    }

    const cacheId = 'AccountTradingLevel';
    const cacheKey = `${userId}_${accountId}_${date}`;
    const cachedValue = this._cache.getCacheItem<AccountTradingLevel>(cacheId, userId, cacheKey, 24 * 60 * 60);
    if (cachedValue !== undefined) {
      return of(cachedValue);
    }
    return this._ws.getAccountTradingLevel(userId, accountId, date).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
      map((tl: AccountTradingLevel) => { if (isArray(tl.TL) && tl.TL.length === 0) {
        return null;
      } else {
        return tl;
      }}),
      tap(tl => {
        this._cache.storeCacheItem<AccountTradingLevel>(cacheId, userId, cacheKey, tl);
        this.tradingLevel$.next(tl);
      }),
    );
  }

  getAccountDeltas(
    userId: number, accountId: number | string, startDate: Date, endDate: Date
  ): Observable<DailyDelta[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = of(null);
    } else {
      request$ = this._ws.getAccountDeltas(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getAccountIndexDescriptions$(refresh = false) {

    const descriptions$: Observable<AccountIndexDescription[]> = this._auth.getCurrentUser().pipe(
      switchMap((user) => {
        const cachedDescriptions = this._cache.getAccountIndexDescriptions(user.userId);
        if (cachedDescriptions === undefined) {
          return this._http.get<AccountIndexDescription[]>('/api/v1/AccountIndexMetadata/').pipe(
            takeUntil(this.cancelAccountOverviewSubj),
            map((response) => {
              if (response instanceof Error) {
                throw response;
              } else {
                return response;
              }
            }),
            tap((descriptions) => {
              this._cache.updateAccountIndexDescriptions(user.userId, descriptions);
            }),
          );
        } else {
          return of(cachedDescriptions);
        }
      })
    );
    
    if (refresh) {
      this._refreshAccountIndexDescriptions.next();
    }
    return this._refreshAccountIndexDescriptions.pipe(
      switchMapTo(descriptions$),
    );
  }


  getAssetClassesDeltas(
    userId: number, accountId: number | string, startDate: Date, endDate: Date
  ): Observable<AssetClassDeltas[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexAssetClassesDeltas(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountAssetClassesDeltas(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getAccountGammas(
    userId: number, accountId: number | string, startDate: Date, endDate: Date
  ): Observable<DailyGamma[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = of(null);
    } else {
      request$ = this._ws.getAccountGammas(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getAssetClassesGammas(
    userId: number, accountId: number | string, startDate: Date, endDate: Date
  ): Observable<AssetClassGammas[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexAssetClassesGammas(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountAssetClassesGammas(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getAssetClassCumulativeReturns$(userId: number, accountId: string | number, fromDate: string, toDate: string) {
    let assetClassReturns$: Observable<AssetClassIntradayPerformances[] | AssetClassCumulativeReturns[]>;
    if (fromDate == null) {
      // intraday
      if (isIndex(accountId)) {
        assetClassReturns$ = this._ws.getIndexAssetClassesIntradayPerformances(userId, accountId as int);
      } else {
        assetClassReturns$ = this._ws.getAccountAssetClassesIntradayPerformances(userId, accountId as string);
      }
    } else {
      // const adjustedDate = dt2midnights(moment.tz(fromDate, TZ).add(1, 'day'));
      if (isIndex(accountId)) {
        assetClassReturns$ = this._ws.getIndexAssetClassesCumulativeReturns(userId, accountId, fromDate, toDate);
      } else {
        assetClassReturns$ = this._ws.getAccountAssetClassesCumulativeReturns(userId, accountId, fromDate, toDate);
      }
    }
    return assetClassReturns$.pipe(
      takeUntil(this.cancelPerformanceBreakdown$),
      map(result => {
        if (result == null || Array.isArray(result) && result.length === 0) {
          throw new Error('empty');
        } else {
          return result;
        }
      }),
  );
  }

  getHoldingsOverview$(userId: number, accountId: number | string, date: string | Date): Observable<HoldingsOverview> {
    let overviewWrapper: Observable<AccountHoldingsOverview | IndexHoldingsOverview>;

    const dateStr = typeof date === 'string' ? dt2midnights(moment.tz(date, TZ).toDate()) : dt2midnights(date);
    const holdingsCacheId = this._cache.genHoldingsCacheId(userId, accountId, dateStr);
    if (this._cache.holdingsCache.has(holdingsCacheId)) {
      return of(this._cache.holdingsCache.get(holdingsCacheId));
    } else {
      if (isIndex(accountId)) {
        overviewWrapper = this._ws.getIndexHoldingsOverview(+userId, +accountId, dateStr);
      } else {
        overviewWrapper = this._ws.getAccountHoldingsOverview(+userId, accountId, dateStr);
      }
      return overviewWrapper.pipe(
        takeUntil(this.cancelAccountOverviewSubj),
        pluck<AccountHoldingsOverview | IndexHoldingsOverview, HoldingsOverview>('HoldingsOverview'),
        tap(holdingsOverview => this._cache.holdingsCache.set(holdingsCacheId, holdingsOverview as HoldingsOverview)),
      );
    }
  }

  /** Fetch landing page data  */
  getLandingPage$(forceUpdate = false) {
    return this._auth.getCurrentUser().pipe(
      switchMap(user => {
        const cached = this._cache.getCachedLandingPage(user.userId, 5 * 60000);
        if (cached != null && cached.AccountInfos != null && !forceUpdate) {
          return of(cached);
        }
        return this._ws.getLandingPage(user.userId).pipe(
          takeUntil(this.cancelAccountOverviewSubj),
          retryBackoff({
            initialInterval: 2000,
            maxRetries: 5,
            resetOnSuccess: true,
            shouldRetry: (error) => {
              if (isHttpError(error)) {
                this._logger.error('HTTP Error', error.status, error);
                return error.status !== 404;
              }
              return true;
            }
          }),
          tap((landingPage) => this._cache.updateLandingPage(user.userId, landingPage)),
        );
      }),
    );
  }

  /** Fetch landing page daily data  */
  getLandingPageDaily$(dataDate: string, forceUpdate = false) {
    return this._auth.getCurrentUser().pipe(
      switchMap(user => {
        const cached = this._cache.getCachedLandingPageDaily(user.userId, dataDate, 60 * 60000);
        if (cached && !forceUpdate) {
          return of(cached);
        }

        return this._ws.getLandingPageDaily(user.userId, dataDate).pipe(
          takeUntil(this.cancelAccountOverviewSubj),
          retryBackoff({
            initialInterval: 2000,
            maxRetries: 5,
            resetOnSuccess: true,
            shouldRetry: (error) => {
              if (isHttpError(error)) {
                this._logger.error('HTTP Error', error.status, error);
                return error.status !== 404;
              }
              return true;
            }
          }),
          tap((landingPageData) => this._cache.updateLandingPageDaily(user.userId, dataDate, landingPageData)),
        );
      }),
    );
  }


  getAllExposures$(userId, accountId, date) {
    return this.getHoldingsOverview$(userId, accountId, date).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
      map(hOverview => {
        return [
          hOverview.AssetClassesExposures, hOverview.SubAssetClassesExposures, hOverview.ProductsExposure
        ] as [AssetClassExposures[], SubAssetClassExposures[], ProductExposure[]];
      }),
    );
  }

  getPerformanceOverview(userId: number, accountId: string | number, date: Date): any {
    let perfOverview$: Observable<PerformanceOverview>;
    if (isIndex(accountId)) {
      perfOverview$ = this._ws.getIndexPerformanceOverview(
        userId,
        +accountId,
        dt2midnights(date)
      ).pipe(pluck('PerformanceOverview'));
    } else {
      perfOverview$ = this._ws.getAccountPerformanceOverview(
        userId,
        accountId,
        dt2midnights(date)
      ).pipe(pluck('PerformanceOverview'));
    }
    return perfOverview$.pipe(
      takeUntil(this.cancelPerformanceBreakdown$),
      map(result => {
        if (
          isEmpty(result)
        ) {
          throw new Error('empty');
        } else {
          return result;
        }
      }),
      retryBackoff({
        initialInterval: 20000,
        maxRetries: 2,
      }),
    );
  }


  /**
   * Return an Observable of a list of all Products for this account
   */
  getProducts$(userId: number, accountId: string | number, date: Date): Observable<Product[]> {
    return this.getHoldingsOverview$(userId, accountId, date).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
      map(holdingsOverview => {
        return holdingsOverview.ProductsExposure.map(prodExp => prodExp.Product);
      }),
    );
  }

  getProductNetExposures$(userId, accountId, productId, dateStart, dateEnd) {
    const request$ = isIndex(accountId) ?
      this._ws.getIndexProductNetExposures(
          userId,
          +accountId,
          productId,
          dt2midnights(dateStart),
          dt2midnights(dateEnd)
      ) :
      this._ws.getAccountProductNetExposures(
        userId,
        accountId,
        productId,
        dt2midnights(dateStart),
        dt2midnights(dateEnd)
      );
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }


  getDailyReturnData(userId: number, accountId: int | string, fromDate: string, toDate: string): Observable<[DailyReturnData, Date]> {
    let data$: Observable<DailyReturnData>;
    let realDate$: Observable<Date>;
    const cachedData = this._cache.getCachedDailyReturnData(userId, accountId, fromDate, toDate);
    if (isIndex(accountId)) {
      if (cachedData != null) {
        data$ = of(cachedData);
      } else {
        data$ = this._ws.getIndexDailyReturnData(userId, +accountId, fromDate, toDate);
      }
      realDate$ = this.getIndexRealTrackStartDates(userId, +accountId).pipe(
        map((realTrackStartDate: RealTrackStartDate[]) => {
          if (realTrackStartDate.length > 0 && realTrackStartDate[0].HasRealTrackStartDate) {
            return moment.tz(realTrackStartDate[0].StartDate, 'Europe/Helsinki').toDate();
          } else {
            return null;
          }
        }),
      );
    } else {
      if (cachedData != null) {
        data$ = of(cachedData);
      } else {
        data$ = this._ws.getAccountDailyReturnData(userId, accountId, fromDate, toDate);
      }
      realDate$ = of(null);
    }
    return forkJoin<DailyReturnData, Date>(
      data$.pipe(
        map(result => {
          if (
            isEmpty(result)
            || (isEmpty(result.CumulativePerformances)
              || isEmpty(result.DailyPerformances)
            )
          ) {
            throw new Error('empty');
          } else {
            return result;
          }
        }),
        retryBackoff({
          initialInterval: 20000,
          maxRetries: 2,
        }),
        tap(
          returnData => this._cache.updateCachedDailyReturnData(userId, accountId, fromDate, toDate, returnData)
        ),
      ),
      realDate$
    ).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getDailyRiskOverview(userId: number, accountId: number | string, date: Date | string): Observable<RiskOverview> {
    const dateStr = typeof date === 'string' ? dt2midnights(moment.tz(date, this.tz).toDate()) : dt2midnights(date);
    const cacheId = this._cache.genHoldingsCacheId(userId, accountId, dateStr);
    let request$: Observable<RiskOverview>;
    if (this._cache.riskOverviewCache.has(cacheId)) {
      request$ = of(this._cache.riskOverviewCache.get(cacheId));
    } else {
      if (isIndex(accountId)) {
        request$ = this._ws.getIndexDailyRiskOverview(userId, +accountId, dateStr).pipe(
          map((wrapper: IndexDailyRiskOverview): RiskOverview => {
            return wrapper.RiskOverview;
          }),
          tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview.AssetClassesDelta))),
        );
      } else {
        request$ = this._ws.getAccountDailyRiskOverview(userId, accountId, dateStr).pipe(
          map((wrapper: AccountDailyRiskOverview): RiskOverview => {
            return wrapper.RiskOverview;
          }),
          tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview.AssetClassesDelta))),
        );
      }
      request$ = request$.pipe(
        tap(riskOverview => this._cache.riskOverviewCache.set(cacheId, riskOverview))
      );
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getDeltaOverview(userId: number, accountId: string, date: Date | string): Observable<DeltaOverview> {
    if (isIndex(accountId)) {
      return throwError('Not supported for indices!');
    } else {
      return this._ws.getAccountDeltaOverview(userId, accountId as string, date).pipe(
        takeUntil(this.cancelAccountOverviewSubj),
        tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview.ProductsDelta))),
      );
    }
  }

  getExAnteVolatilityOverview(userId: number, accountId: number | string, date: Date | string): Observable<VolatilityOverview> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexExAnteVolatilityOverview(userId, Number(accountId), dt2midnights(date)).pipe(
        map(overview => overview.VolatilityOverview),
      );
    } else {
      request$ = this._ws.getAccountExAnteVolatilityOverview(userId, accountId.toString(), dt2midnights(date)).pipe(
        map(overview => overview.VolatilityOverview),
      );
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }


  getGammaOverview(userId: number, accountId: string, date: Date| string): Observable<GammaOverview> {
    if (isIndex(accountId)) {
      return throwError('Not supported for indices!');
    } else {
      return this._ws.getAccountGammaOverview(userId, accountId as string, date).pipe(
        takeUntil(this.cancelAccountOverviewSubj),
        tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview.ProductsGamma))),
      );
    }
  }

  getGammaAndDeltaOverview(userId: number, accountId: number | string, date: Date): Observable<GammaAndDeltaOverview> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexGammaAndDeltaOverview(userId, accountId as number, date).pipe(
        map(overview => overview.GammaAndDeltaOverview),
        tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview))),
      );
    } else {
      request$ = this._ws.getAccountGammaAndDeltaOverview(userId, accountId as string, date).pipe(
        map(overview => overview.GammaAndDeltaOverview),
        tap(overview => this._signalDynamicsAvailableSubj.next(!isEmpty(overview))),
      );
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }


  getIndexRealTrackStartDates(userId: int, indexId: int) {
    return this._ws.getIndexRealTrackStartDates(userId, indexId).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getIntradayPerformances$(userId: number, accountId: string | number) {
    let result$: Observable<IntradayPerformancePoint[]>;
    if (isIndex(accountId)) {
      result$ = this._ws.getIndexIntradayPerformanceRiskOverview(userId, accountId).pipe(
        map(riskOverview => riskOverview.IntradayPerformanceRiskOverview.Performances),
      );
    } else {
      result$ = this._ws.getAccountIntradayPerformances(userId, accountId as string);
    }
    return result$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getMarginRequirements(userId: number, accountId: string | number, startDate: Date, endDate: Date): Observable<MarginRequirement[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexMarginRequirements(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountMarginRequirements(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getAccountStyleCumulativeReturn(userId: number, accountId: string, fromDate: Date | string, toDate: Date | string) {
    let stylePerf$: Observable<StrategyStyleIntradayPerformances[] | StrategyStyleCumulativeReturns[]>;
    if (fromDate === null) {
      // Intraday
      stylePerf$ = this._ws.getAccountStrategyStyleIntradayPerformances(userId, accountId);
    } else {
      // Multi-day
      stylePerf$ = this._ws.getAccountStrategyStyleCumulativeReturns(userId, accountId, dt2midnights(fromDate), dt2midnights(toDate));
    }
    return stylePerf$.pipe(
      takeUntil(this.cancelPerformanceBreakdown$),
      map(result => {
        if (result == null || Array.isArray(result) && result.length === 0) {
          throw new Error('empty');
        } else {
          return result;
        }
      }),
      retryBackoff({
        initialInterval: 20000,
        maxRetries: 2,
      }),
    );
  }

  getSubAssetClassAndProductPerformanceAggregations(userId: number, accountId: number | string, date: string) {
    let subPerfAgg$: Observable<[SubAssetClassPerformanceAggregations[], ProductPerformanceAggregations[]]>;
    if (isIndex(accountId)) {
      subPerfAgg$ = forkJoin([
        this._ws.getIndexSubAssetClassesPerformanceAggregations(+userId, +accountId, date),
        this._ws.getIndexProductsPerformanceAggregations(+userId, +accountId, date),
      ]);
    } else {
      subPerfAgg$ = forkJoin([
        this._ws.getAccountSubAssetClassesPerformanceAggregations(+userId, accountId, date),
        this._ws.getAccountProductsPerformanceAggregations(+userId, accountId, date),
      ]);
    }
    return subPerfAgg$.pipe(
      takeUntil(this.cancelPerformanceBreakdown$),
      map(([subAssetAggs, productAggs]) => {
        if (
          (subAssetAggs == null || Array.isArray(subAssetAggs) && subAssetAggs.length === 0)
          || (productAggs == null || Array.isArray(productAggs) && productAggs.length === 0)
        ) {
          throw new Error('empty');
        } else {
          return [subAssetAggs, productAggs];
        }
      }),
      retryBackoff({
        initialInterval: 20000,
        maxRetries: 2,
      }),
    );
  }


  getSubAssetClassExAnteVolatilityDetails(userId, accountId: number | string, date: Date | string) {
    const dateStr = dt2midnights(date);
    if (isIndex(accountId)) {
      return this._ws.getIndexSubAssetClassesExAnteVolatilityDetails(userId, accountId as number, dateStr);
      /* return this.ws.getIndexExAnteVolatilityOverview(userId, accountId as number, dt2midnights(date)).pipe(
        map((overview) => {
          return overview.VolatilityOverview.SubAssetClassesVolatility;
        }),
      ); */
    } else {
      return this._ws.getAccountSubAssetClassesExAnteVolatilityDetails(userId, accountId as string, dateStr);
    }
  }

  getSubAssetClassMarginRequirement(userId, accountId, date): Observable<SubAssetClassMarginRequirement[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexMarginOverview(userId, accountId, date).pipe(
        map((overview) => {
          return overview.MarginOverview.SubAssetClassesMarginRequirements;
        }),
      );
    } else {
      request$ = this._ws.getAccountSubAssetClassesMarginRequirements(userId, accountId, date);
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getSubAssetClassVaRDetails(userId, accountId, date): Observable<SubAssetClassVaRDetails[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexVaROverview(userId, accountId, date).pipe(
        map((overview) => {
          return overview.VaROverview.SubAssetClassesVaR;
        }),
      );
    } else {
      request$ = this._ws.getAccountSubAssetClassesValueAtRiskDetails(userId, accountId, date);
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getSubAssetClassVolatilityDetails(userId, accountId: number | string, date) {
    if (isIndex(accountId)) {
      return this._ws.getIndexVolatilityOverview(userId, accountId, date).pipe(
        map((overview) => {
          return overview.VolatilityOverview.SubAssetClassesVolatility;
        }),
      );
    } else {
      return this._ws.getAccountSubAssetClassesVolatilityDetails(userId, accountId, date);
    }
  }

  getAssetClassesVaRAndDirection(userId: number, accountId: string | number, date: string) {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexAssetClassesVaRAndDirection(userId, accountId as number, date).pipe(
      );
    } else {
      request$ = this._ws.getAccountAssetClassesVaRAndDirection(userId, accountId as string, date).pipe(
      );
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getProductDeltas$(
    userId: number,
    accountId: string | number,
    productId: number,
    dateStart: Date,
    dateEnd: Date
  ): Observable<DailyDelta[]> {
    if (isIndex(accountId)) {
      return of([]);
    } else {
      return this._ws.getAccountProductDeltas(userId, accountId, productId, dt2midnights(dateStart), dt2midnights(dateEnd)).pipe(
        takeUntil(this.cancelAccountOverviewSubj),
      );
    }
  }

  getProductIntradayPrices$(userId: number, productId: number): Observable<ProductIntradayPrices> {
    return this._ws.getProductIntradayPrices(userId, productId).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getRolledDailyClose$(userId: number, productId: number, dateStart: Date, dateEnd: Date) {
    return this._ws.getRolledDailyClose(userId, productId, dt2midnights(dateStart), dt2midnights(dateEnd)).pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getValueAtRiskHistorical(userId: number, accountId: string | number, startDate: Date, endDate: Date): Observable<VaRPoint[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexValueAtRiskHistorical(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountValueAtRiskHistorical(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getVolatilityExAnteOverview$(
    userId: number,
    accountId: string | number,
    lastDate: string,
    lastMonthEndDate: string
  ): Observable<(ExAnteVolatility|Error)[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = forkJoin<ExAnteVolatility|Error, ExAnteVolatility|Error>(
        this._ws.getIndexExAnteVolatility(userId, accountId as number, lastDate),
        this._ws.getIndexExAnteVolatility(userId, accountId as number, lastMonthEndDate)
      );
    } else {
      request$ = forkJoin<ExAnteVolatility|Error, ExAnteVolatility|Error>(
        this._ws.getAccountExAnteVolatility(userId, accountId as string, lastDate),
        this._ws.getAccountExAnteVolatility(userId, accountId as string, lastMonthEndDate)
      );
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  getVolatilityHistorical(userId: number, accountId: string | number, startDate: Date, endDate: Date): Observable<VolatilityPoint[]> {
    let request$;
    if (isIndex(accountId)) {
      request$ = this._ws.getIndexVolatilityHistorical(userId, accountId as number, dt2midnights(startDate), dt2midnights(endDate));
    } else {
      request$ = this._ws.getAccountVolatilityHistorical(userId, accountId as string, dt2midnights(startDate), dt2midnights(endDate));
    }
    return request$.pipe(
      takeUntil(this.cancelAccountOverviewSubj),
    );
  }

  ngOnDestroy() {
    this.cancelAccountOverviewSubj.next();
    this.cancelAccountOverviewSubj.complete();
    this.cancelAccountOverviewSubj = null;
    this.tradingLevel$.next(null);
    this.tradingLevel$.complete();
    this.tradingLevel$ = null;
    this.destroy.next();
    this.destroy.complete();
  }

  showTradingLevelBasedFigures(userId: number, accountId: number | string, tradingLevel: AccountTradingLevel): boolean {
    return (
      this._showTradingLevelBasedFiguresAccountIds.includes(accountId)
    ) && tradingLevel != null && tradingLevel.TL > 0;
  }

  signalDynamicsAvailable(setVal?: boolean) {
    if (setVal != null) {
      this._signalDynamicsAvailableSubj.next(setVal);
    }
    return this._signalDynamicsAvailableSubj.asObservable();
  }

  public updateShowTradingLevelBasedFiguresAccountIds() {
    this._settingsService.getUserSettings().pipe(
      
    ).subscribe((settings) => {
      if (settings == null || settings.ActivePortfolios == null) {
        return;
      }
      const ids = [];
      for (const ap of settings.ActivePortfolios) {
        if (ap.NumberDisplayType === BreakdownNumberDisplayType.NAVFraction) {
          ids.push(ap.PortfolioId);
        }
      }
      this._showTradingLevelBasedFiguresAccountIds = ids;
      this._showTradingLevelBasedFiguresUpdateSignalSubj.next();
    });
  }
}
