import naturalSort from '../natural-sort';
import { SandboxIndex } from './sandbox-index';

export interface SandboxIndexGroup {
    Name: string;
    Order: number;
    Indices: SandboxIndex[];
}

export function sortSandboxIndexGroups(grpA: SandboxIndexGroup, grpB: SandboxIndexGroup): number {
    if (grpA.Order !== grpB.Order) {
        return grpA.Order - grpB.Order;
    } else {
        return naturalSort(grpA.Name, grpB.Name);
    }
}

