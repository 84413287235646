<div #card class="card" [ngClass]="{ 'minimized': minimized$|async }">
  <ng-content select=".card-title"></ng-content>
  <div #defaultTitle [ngClass]="{'is-invisible': defaultTitle.previousSibling != null }" class="card-title" (click)="toggleMinimizeCard(card)">
      <button
      class="minimize"
      [title]="(minimized$|async) ? 'Expand card' : 'Minimize card'"
      type="button">
      <i class="fa" [ngClass]="{ 'fa-minus' : !(minimized$|async), 'fa-plus': (minimized$|async) }"></i>
    </button>
    <h1 [ngStyle]="{ 'max-height': title == null ? '0' : 'initial' }">{{title}}</h1>
  </div>
  <div class="card-content">
    <ng-content></ng-content>
  </div>
</div>

