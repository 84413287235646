import naturalSort from '../natural-sort';
import { SandboxAsset } from '@app/api/webservice.service';

export interface SandboxAssetGroup {
    Name: string;
    Order: number;
    Assets: SandboxAsset[];
    SubGroups?: SandboxAssetGroup[];
}

export function sortSandboxIndexGroups(grpA: SandboxAssetGroup, grpB: SandboxAssetGroup): number {
    if (grpA.Order !== grpB.Order) {
        return grpA.Order - grpB.Order;
    } else {
        return naturalSort(grpA.Name, grpB.Name);
    }
}

export function assetGroupContainsAsset(assetGroup: SandboxAssetGroup, asset: SandboxAsset) {
    let isFound = false;
    if (Array.isArray(assetGroup.Assets) && assetGroup.Assets.length > 0) {
        isFound = assetGroup.Assets.find(a => a.Index.IndexId === asset.Index.IndexId) != null;
    } else if (Array.isArray(assetGroup.SubGroups) && assetGroup.SubGroups.length > 0) {
        isFound = assetGroup.SubGroups.some(subGrp => assetGroupContainsAsset(subGrp, asset));
    }
    return isFound;
}

/** Filter `assetGroup` to only those Assets and SubGroups that contain Assets that are in the `availableAssets`.
 * The point is to handle the case where user has enabled an asset in settings, but later the user's access to the
 * asset is removed by admin.
 *
 * @param assetGroup - the group to be filtered
 * @param availableAssets - the list of avilable assets.
*/
export function filterAssetGroup(assetGroup: SandboxAssetGroup, availableAssets: SandboxAsset[]): SandboxAssetGroup {
    const filteredGrp = { Name: assetGroup.Name, Order: assetGroup.Order } as SandboxAssetGroup;
    filteredGrp.Assets = assetGroup.Assets.filter(grpAsset => availableAssets.find(a => a.Index.IndexId === grpAsset.Index.IndexId));
    if (Array.isArray(assetGroup.SubGroups) ) {
        filteredGrp.SubGroups = assetGroup.SubGroups.map(
            subGrp => filterAssetGroup(subGrp, availableAssets)
        ).filter(
            subGrp => (Array.isArray(assetGroup.Assets) && Array.isArray(subGrp.Assets) && subGrp.Assets.length > 0) ||
                (Array.isArray(subGrp.SubGroups) && subGrp.SubGroups.length > 0)
        );
    }
    return filteredGrp;
}
