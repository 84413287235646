import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterContentInit, ViewChildren, QueryList, ChangeDetectorRef, NgZone } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { SpinnerService } from '@chevtek/angular-spinners';
import { NotificationsService } from 'angular2-notifications';
import * as Highcharts from 'highcharts';
import { stockChart } from 'highcharts/highstock';
import * as Highstock from 'highcharts/highstock';
import { cloneDeep, isArray, isNil, isNumber, merge } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of, throwError, Subject, combineLatest } from 'rxjs';
import { catchError, delay, filter, map, retryWhen, switchMap, takeUntil, timeout, pairwise, distinct, flatMap, take, mergeMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { AccountDataService, dt2midnights, isIndex, AccountIndexDescription } from '../../account-data/account-data.service';
import { AccountPerformance } from '../../account-data/account-performance';
import { AcctIdx } from '../../account-data/acct-idx';
import {
  cmpAssetClasses,
  sortSubAssetClassColumn,
  cmpSubAssetClassNames
} from '../../account-data/asset-class-sorting';
import { beginningOfTime, getOffsetDays, getOffsetStartDays, numDigits, TZ, getOffsetPeriodStartDate } from '../../account-overview/utils';
// APIv2
import {
  Account,
  AccountDailyPerformanceOverview,
  AccountGammaAndDeltaOverview,
  AccountMarginOverview,
  AccountVaROverview,
  AccountVolatilityOverview,
  AssetClassCumulativeReturns,
  AssetClassExposures,
  AssetClassIntradayDetails,
  AssetClassIntradayPerformances,
  AssetClassPerformanceAggregations,
  DailyReturnData,
  HoldingsOverview,
  Index,
  IndexCorrelationsAndVolatilities,
  IndexDailyPerformanceOverview,
  IndexIntradayPerformanceRiskOverview,
  IndexMarginOverview,
  IndexVaROverview,
  int,
  IntradayPerformancePoint,
  LandingPageData,
  Order,
  PerformanceOverview,
  PerformancePoint,
  Product,
  ProductExposure,
  ProductIntradayDetails,
  ProductPerformanceAggregations,
  PspWebService,
  RiskOverview,
  StrategyStyleCumulativeReturns,
  StrategyStyleIntradayPerformances,
  SubAssetClassExposures,
  SubAssetClassIntradayDetails,
  SubAssetClassPerformanceAggregations,
  VolatilityOverview,
  AccountTradingLevel
 } from '../../api/webservice.service';

import { AppService } from '../../app-service.service';
import { AuthenticationService, User } from '../../auth/authentication.service';
import { MenuService } from '../../menu.service';
import { DataTableColumn } from '../../shared/data-table/data-table-models';
import { DataTableSortOrder } from '../../shared/data-table/data-table-sort-order';
import { debounce } from 'lodash';
import { NavbarButtonComponent } from '../../shared/navbar-button/navbar-button.component';
import { NavbarButtonService } from '../../shared/navbar-button/navbar-button.service';
import { sigFigs } from '../../shared/utils';
import { checkboxLegendOptions, deleteLegendSymbols } from '../../account-overview/utils/charts';
import { LoggingService } from '../../utils/logging/logging.service';
import { SignedPercentPipe } from '../../shared/pipes/signed-percent.pipe';
import { UiCollapsibleSectionComponent } from 'app/shared/ui-components/ui-collapsible-section/ui-collapsible-section.component';
import { retryBackoff } from 'backoff-rxjs';
import { AccountOverviewService } from '@app/account-overview/services/account-overview.service';


interface IAssetClassReturn {
  id: number;
  assetClassName: string;
  return: number;
}

interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

function isIAssetClassReturn(obj: any): obj is IAssetClassReturn {
  return obj !== undefined && (<IAssetClassReturn>obj).assetClassName !== undefined;
}

interface ISubAssetClassReturn {
  id: string;
  subAssetClassName: string;
  return: number;
  assetClassId: number;
  assetClassName: string;
}
function isISubAssetClassReturn(obj: any): obj is ISubAssetClassReturn {
  return obj !== undefined && (<ISubAssetClassReturn>obj).subAssetClassName !== undefined;
}

interface IProductReturn {
  id: number;
  productName: string;
  return: number;
  subAssetClassId: string;
  subAssetClassName: string;
  assetClassId: number;
  assetClassName: string;
}
function isIProductReturn(obj: any): obj is IProductReturn {
  return obj !== undefined && (<IProductReturn>obj).productName !== undefined;
}

function zeroPad(num, places) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

const retryAfter20 = () => <T>(source: Observable<T>): Observable<T> =>
  source.pipe(
    retryBackoff(
      {
        initialInterval: 20000,
        maxRetries: 2,
      }
    ),
  );

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss']
})
export class AccountOverviewComponent implements OnDestroy, OnInit, AfterContentInit {
  accountDailyPerformanceOverview: PerformanceOverview = null;
  accountDailyRiskOverview: RiskOverview = null;
  accountIntradayRiskOverview: RiskOverview = null;
  accounts: Account[] = [];
  cumulativeReturns: PerformancePoint[];
  accountId: string;
  accountPerformance: AccountPerformance = null;
  accountVaROverview: AccountVaROverview;
  accountMarginOverview: AccountMarginOverview;
  accountVolatilityOverview: AccountVolatilityOverview;
  accountGammaAndDeltaOverview: AccountGammaAndDeltaOverview;
  acctIdxs$: Observable<AcctIdx[]>;
  activeAcctIdx$: Observable<AcctIdx>;
  activeAcctIdxDescription$: Observable<AccountIndexDescription>;
  correlationsAndVolatilities: IndexCorrelationsAndVolatilities;
  documentsCardCollapsed$ = new BehaviorSubject<boolean>(true);
  flags = new Map<string, boolean>();
  indecis: Index[] = [];
  intradayOrders: Order[] = [];
  intradayOrdersFullfillmentRatio = 0;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isNavbarScrolledUp$: Observable<boolean>;
  landingPageData: LandingPageData;

  lastMonthBtnActive = false;
  lastDayBtnActive = false;

  layoutMode$: Observable<string>;

  @ViewChild('portfolioNavigationBar', { static: true }) portfolioNavigationBar: ElementRef;
  @ViewChild('mainContentContainer', { static: true }) mainContentContainer: ElementRef;
  @ViewChild('performanceBreakdownNavbar', { static: true }) performanceBreakdownNavbar: NavbarButtonComponent;
  @ViewChild('performanceNavbarWrapper', { static: true }) perfNavbarWrapper;
  @ViewChildren('pfPerfSect, acPerfSect, stylePerfSect') perfSubsections: QueryList<UiCollapsibleSectionComponent>;

  mainMenuMode$: Observable<'icons'|'labels'>;

  navbarIsFloating$: Observable<boolean>;

  /**
   * Is Overview card collapsed.
   */
   docsCardCollapsed$: Observable<boolean>;

   /**
   * Is Overview card collapsed.
   */
  overviewCardCollapsed$: Observable<boolean>;

  /**
   * Collapse state of performance ui-card
   */
  perfCardCollapsed$: Observable<boolean>;

  /**
   * Collapse state of orders ui-card
   */
  ordersCardCollapsed$: Observable<boolean>;

  /** Height of overview menu, changes based on viewport width. Set in OverviewMenuComponent */
  overviewMenuHeight$: Observable<number>;

  perfNavStyle$: Observable<object>;

  /**
   * Visibility state of performanceBreakdwonNavbar. False if all subsections collpased.
   */
  perfNavbarVisible$ = new BehaviorSubject<boolean>(true);

  portfolioIntradayReturn: IntradayPerformancePoint[] = null;
  productName: string;
  productOneReturn: [number, number][];
  productTwoReturn: object;
  selectedItems = {
    assetClassPerformance: {},
    marketPerformance: {},
    subAssetClassPerformance: {}
  };

  /** Determines if documents section should be shown */
  showDocuments$: Observable<boolean>;

  /** Account Trading Level */
  tradingLevel: AccountTradingLevel;
  userId: int;

  /**
   * Collapse state of risk ui-card
   */
  riskCardCollapsed$: Observable<boolean>;

  // module for tracking subscriptions for loading indicators
  subscriptions: { [id: string]: Subscription };
  showIntraday = false;
  showIntradayOrders$: Observable<boolean>;
  showTodayButtons = false;
  initFlag = false;

  lineColors = ['#92500F', '#FFD800', '#DFC180', '#FD9734', '#39978F', '#EB6E21', '#BE7F33', '#542F09'];
  columnColors = ['#92500F', '#BE7F33', '#DFC180', '#FFD800', '#FD9734', '#EB6E21', '#39978F', '#542F09'];

  defaultLineChartScalingFactor = 1.25;

  assetClassPerformanceAggregations: AssetClassPerformanceAggregations[] = [];
  accountAssetClassesIntradayPerformances: AssetClassIntradayPerformances[] = [];
  accountSubAssetClassesPerformanceAggregations: SubAssetClassPerformanceAggregations[] = [];
  accountProductsPerformanceAggregations: ProductPerformanceAggregations[] = [];

  assetClassesCumulativeReturnBarChart: Highcharts.Chart;
  assetClassesCumulativeReturnLineChart: Highstock.StockChart;
  dailyReturnData: DailyReturnData = null;
  assetClassesCumulativeReturns: AssetClassCumulativeReturns[] = [];
  styleCumulativeReturns: StrategyStyleCumulativeReturns[] | StrategyStyleIntradayPerformances[] = [];
  selectedProduct$: Observable<number>;

  assetClassPerformanceTableData = null;
  assetClassBreakdownPerformanceTableSort: DataTableSortOrder = null;
  defaultPeriod: string;

  performanceBreakdownTabs = [
    'Today', 'Last day', 'MTD', 'YTD', '3 months', '6 months', '1 year', 'All'
  ];
  performanceBreakdownTabsInitial: int;

  portfolioDailyReturnTabs = this.performanceBreakdownTabs;
  portfolioDailyReturnTabsInitial: int;

  strategyStyleReturnTabs = this.performanceBreakdownTabs;
  strategyStyleReturnTabsInitial: int;

  assetClassReturnTabs = this.performanceBreakdownTabs;
  assetClassReturnTabsInitial: int;

  assetClassCumulativeReturnPeriod: string;

  assetClassDeltaGammaTabs = this.performanceBreakdownTabs.slice(2, -1);
  assetClassDeltaGammaTabsInitial = this.assetClassDeltaGammaTabs.indexOf('3 months');

  assetClassDeltaChart: Highstock.Chart;
  assetClassGammaChart: Highstock.Chart;

  // map period names to DailyPerformanceAggregation property names
  assetClassCumlativeReturnPropMap = {
    'Last day': 'Last',
    'MTD': 'MonthToDate',
    'YTD': 'YearToDate',
    '3 months': 'ThreeMonth',
    '6 months': 'SixMonth',
    '1 year': 'OneYear',
    'All': 'All',
  };

  lastMTDYtdreturnTabs = ['Last', 'MTD', 'YTD'];

  assetClassPerformanceTableColumns: DataTableColumn[] = [
    { name: 'assetClassName', label: 'Asset Class', type: 'text', cmp: cmpAssetClasses, },
    { name: 'return', label: 'Return', type: 'percentbar' },
  ];

  assetClassPerformanceTableOptions = {
    itemId: 'id',  // not implemented. Always have 'id' field!
    defaultSort: this.assetClassBreakdownPerformanceTableSort,
    sort: {
      assetClassName: (items, fieldName, isAscending, cmp = cmpAssetClasses) => {
        if (Array.isArray(items) && items.length > 1) {
          items.sort((a, b) => cmp(a, b, 'assetClassName') * (isAscending ? 1 : -1));
        }
        return items;
      },
    },
  };

  subAssetClassPerformanceTableData = null;
  _subAssetClassPerformanceTableData = null;

  subAssetClassPerformanceTableColumns: DataTableColumn[] = [
    { name: 'subAssetClassName', label: 'All sectors', type: 'text', cmp: cmpSubAssetClassNames, },
    { name: 'return', label: 'Return', type: 'percentbar', },
  ];

  subAssetClassPerformanceTableOptions = {
    itemId: 'id',  // not implemented. Always have 'id' field!
    sort: {
      subAssetClassName: sortSubAssetClassColumn,
    },
  };


  marketPerformanceTableBreadcrumbs = [];
  marketPerformanceTableOptions = {
    itemId: 'id',  // not implemented. Always have 'id' field!
    defaultSort: this.assetClassBreakdownPerformanceTableSort,
  };

  marketPerformanceTableData = null;
  _marketPerformanceTableData = [];

  marketPerformanceTableColumns: DataTableColumn[] = [
    { name: 'productName', label: 'Market', type: 'text' },
    { name: 'return', label: 'Return', type: 'percentbar' },
  ];

  assetClassBreakdownPerformanceTableData = [];

  assetClassBreakdownPerformanceTableColumns = [];


  subAssetBreakdownClassPerformanceTableData = [];
  _subAssetBreakdownClassPerformanceTableData = [];

  subAssetBreakdownClassPerformanceTableColumns = [];

  marketPerBreakdownTableOptions = {
    itemId: 'id',  // not implemented. Always have 'id' field!
  };

  marketBreakdownTableData = [];
  _marketBreakdownTableData = [];

  marketBreakdownTableColumns = [ ];

  public assetClass: any = { };
  public assetClassBreakdown: any = { };
  public assetClassMarket: any = {
    // the model is used for data binding for checkbox
    'model': {

    },
    // the queue is used for limiting the total amount of markets
    'queue': [

    ]
  };

  isAnyMarketsSelected = false;

  allProducts: Product[];
  nextProduct: Product;
  prevProduct: Product;
  volatilityExAnte: { Last: number, LastMonth: number };

  /**
   * Determine if navbar specified by ref is floating
   */
  readonly navbarIsFloating = debounce(function(ref: HTMLDivElement) {
    return ref != null && ref.offsetTop > 0;
  }, 0);


  /** Blocks scroll if greater than current timestamp (milliseconds)  */
  private _blockScrollUntilMs: number = 0;

  private _destroySubj = new Subject<void>();
  private _destroy$: Observable<void> = this._destroySubj.asObservable();
  private _selectedProductSubj: Subject<number>;
  private _showTradingLevelBasedFigures = false;

  /**
  * Set width of floating navbars to equal grandparent, because parent width is 0.
  */
  private readonly resizeStickyNavbars = debounce(() => {
    try {
      const staticNavbars = this.elem.nativeElement.querySelectorAll(
        ':scope div.navbar-wrapper:not(.floating):not(.is-stuck) div.navbar-content'
      );
      staticNavbars.forEach(function(barDiv: HTMLElement) {
        // const ggp = barDiv.parentElement.parentElement.parentElement;
        barDiv.style.width = 'auto'; // ggp.offsetWidth + 3 * 14 + 'px';
      });
    } catch {

    }
    try { 
      const floatingNavbars = this.elem.nativeElement.querySelectorAll(
        ':scope div.navbar-wrapper.is-stuck div.navbar-content, :scope div.navbar-wrapper.floating div.navbar-content'
      );
      floatingNavbars.forEach(function(barDiv: HTMLElement) {
        const overviewComponentDiv = this.elem.nativeElement as HTMLDivElement;
        barDiv.style.width = overviewComponentDiv.offsetWidth + 'px';
        // barDiv.style.width = '83.3vw';
      });
    } catch {

    }
  }, 200, {leading: true});

  /**
   * Highlight sub menu item for section as the section scrolls into view
   */
  private readonly updateMenuHighlightOnScroll = debounce((event: UIEvent) => {
    const now = (new Date()).getTime();
    // Don't do anything if we are already scrolling
    if (this._blockScrollUntilMs > now) {
      return;
    }
    const top = 0;
    const clientBottom = document.documentElement.clientHeight;
    const triggerPoint = clientBottom / 2;
    const anchors: NodeList = this.elem.nativeElement.querySelectorAll('a.anchor');
    let active: HTMLAnchorElement;
    if (anchors != null && anchors.length > 0) {
      for (const node of Array.from(anchors)) {
        const a = node as HTMLAnchorElement;
        let parent: HTMLElement;
        if (a != null) {
          parent = a.parentElement;
          while (!(['app-ui-collapsible-section', 'app-ui-card', 'app-account-overview', 'body']).includes(parent.tagName.toLowerCase())) {
            parent = parent.parentElement;
          }
        } else {
          return;
        }

        /*
        // ignore minimized sections
        if (parent == null) {
          let uiCard = parent;
          if (uiCard.tagName.toLowerCase() !== 'app-ui-card') {
            while (!(['app-ui-card', 'app-account-overview', 'body']).includes(uiCard.tagName.toLowerCase())) {
              uiCard = uiCard.parentElement;
            }
          }
          if (uiCard.tagName.toLowerCase() === 'app-ui-card' && uiCard.classList != null && uiCard.classList.contains('minimized')) {
            continue;
          }
        }
        */
        const rect = parent.getBoundingClientRect();

        // top of anchor is in top half of vp
        if (rect.top > top && rect.top < triggerPoint) {
          active = a; // set as active and exit loop
        }

        if (rect.top >= triggerPoint) {
          // top is below middle of vp, use previous anchor as active
          break;
        }

        // top of anchor is above vp
        if (rect.top < top) {
          active = a; // set as active, but keep looking for lower anchor
        }
      }
    }
    if (active != null && active.id != null) {
      const newLink = document.createElement('a');
      newLink.href = window.location.href;
      newLink.hash = active.id.replace(/^\#+/, '');
      // remove leading /app prefix
      const path = newLink.pathname.replace(/^\/app/, '');

      if (path != null && path.length > 0) {
        this.menuService.highlightItem(path + newLink.hash);
        // Update selectedSection to match scroll position.
        // Needs to run inside ngZone because it triggers an Angular router navigation.
        let higlightSectionId = newLink.hash.substring(1)
        if (newLink.hash.startsWith('#risk-')) {
          // special case for risk subsections
          higlightSectionId = 'risk';
        }
        this._ngZone.run(() => {
          this._acctOverviewSvc.selectedSection$.pipe(take(1)).subscribe({
            next: (sectionId) => {
              if (sectionId !== higlightSectionId) {
                this._blockScrollUntilMs = (new Date()).getTime() + 1000;
                this._acctOverviewSvc.selectSection(higlightSectionId, false);
              }                  
            },
          });
        });
      }
    }
  }, 200);

  constructor(
    private acctDataSvc: AccountDataService,
    private _acctOverviewSvc: AccountOverviewService,
    private _appService: AppService,
    private authenticationService: AuthenticationService,
    private _changeDetRef: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private elem: ElementRef,
    public logger: LoggingService,
    private menuService: MenuService,
    private _navbarSvc: NavbarButtonService,
    private _ngZone: NgZone,
    private notificationsService: NotificationsService,
    private percentPipe: PercentPipe,
    private pspWebService: PspWebService,
    private route: ActivatedRoute,
    private router: Router,
    private signedPercentPipe: SignedPercentPipe,
    public spinnerService: SpinnerService,
  ) {

    this.layoutMode$ = this._appService.layoutMode.asObservable();
    this.mainMenuMode$ = this._appService.menuMode$;

    this.acctDataSvc.isLoading$.subscribe(this.isLoading$);
    this.activeAcctIdx$ = this._acctOverviewSvc.activeAcctIdx$;

    this.setSelectedDate(this.getTradingDay(new Date()), false);

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      pairwise(),
    ).subscribe(([prev, curr]: [NavigationStart, NavigationStart]) => {
      const urlRe = /^\/portal\/account\/(\w+)(?:#(\w+))?/i;
      const prevMatch = prev.url.match(urlRe);
      if (prevMatch) {
        const currMatch = curr.url.match(urlRe);
        if (!currMatch || prevMatch[1] !== currMatch[1]) {
          this.acctDataSvc.cancelAccountOverviewSubj.next();
          if (currMatch != null) {
            if (currMatch.length > 1 && currMatch[1] != 'overview') {
              this._openUrlFragment(currMatch[1]);
            } else {
              this._acctOverviewSvc.selectSection('overview');
            }
          }
        }
      }
    });

    // Open card if fragment included in route
    this.route.fragment.pipe(
      map((frag) => frag != null && frag.startsWith('#') ? frag.replace(/^\#+/, '') : frag),
      distinctUntilChanged(),
      takeUntil(this._destroy$),
    ).subscribe(fragment => {
      this._openUrlFragment(fragment);
    });

    this._acctOverviewSvc.scrollToSection$.pipe(
      takeUntil(this._destroy$),
    ).subscribe((sectionId) => {
      setTimeout(() => {
        const anchor = (this.elem.nativeElement as HTMLLinkElement).querySelector('#' + sectionId);
        const now = (new Date()).getTime();
        if (anchor && this._blockScrollUntilMs < now) {
          let topMargin = 28; // pixels above anchor to scroll to
          if (anchor.id === 'overview') {
            topMargin = 72;
          }
          const aBounds = anchor.getBoundingClientRect();
          window.scrollTo({ top: window.scrollY + aBounds.top - topMargin, left: 0, behavior: 'smooth'});
          setTimeout(() => {
            // repeat for case where section was collapsed and anchor was hidden.
            const aBounds = anchor.getBoundingClientRect();
            window.scrollTo({ top: window.scrollY + aBounds.top - topMargin, left: 0, behavior: 'smooth'});
          }, 100);

          // block scroll for 1000 ms
          this._blockScrollUntilMs = now + 1000;
        }
      }, 100);
    });

    this.isNavbarScrolledUp$ = this._appService.isNavbarScrolledUp.asObservable();
    this._appService.addScrollEventListener(this.updateMenuHighlightOnScroll);
    this._appService.addScrollEventListener(this.resizeStickyNavbars);

    this.docsCardCollapsed$ = this._acctOverviewSvc.docsCardCollapsed$;
    this.ordersCardCollapsed$ = this._acctOverviewSvc.orderCardCollapsed$;
    this.overviewCardCollapsed$ = this._acctOverviewSvc.overviewCardCollapsed$;
    this.perfCardCollapsed$ = this._acctOverviewSvc.perfCardCollapsed$;
    this.riskCardCollapsed$ = this._acctOverviewSvc.riskCardCollapsed$;

    this.showIntradayOrders$ = this._acctOverviewSvc.showIntradayOrders$;
  }

  private _openUrlFragment(fragment: string) {
    if (fragment == null || fragment === '') {
      return;
    }
    let cardOpenFunc: (boolean) => void;
    if (fragment.startsWith('documents')) {
      cardOpenFunc = this._acctOverviewSvc.collapseDocsCard;
      this._acctOverviewSvc.selectSection('documents');
    } else if (fragment.startsWith('order')) {
      cardOpenFunc = this._acctOverviewSvc.collapseOrderCard;
      this._acctOverviewSvc.selectSection('orders');
    } else if (fragment.startsWith('risk')) {
      cardOpenFunc = this._acctOverviewSvc.collapseRiskCard;
      if (fragment === 'risk') {
        this._acctOverviewSvc.selectSection('risk', false);
        this._acctOverviewSvc.scrollToSection(fragment);
      }
    } else if (fragment.startsWith('overview')){
      cardOpenFunc = this._acctOverviewSvc.collapseOverviewCard;
      this._acctOverviewSvc.selectSection('overview');
    } else if (fragment.startsWith('performance')) {
      cardOpenFunc = this._acctOverviewSvc.collapsePerfCard;
      this._acctOverviewSvc.selectSection('performance');
    }

    if (cardOpenFunc != null) {
      cardOpenFunc.call(this._acctOverviewSvc, false);
    }

  }

  get selectedDate$(): Observable<Date> {
    return this._acctOverviewSvc.selectedDate$;
  }

  set selectedDate(val: Date) {
    if (val.getTime() > this._acctOverviewSvc.getLastDay().getTime()) {
      val = this._acctOverviewSvc.getLastDay();
    }
    this._acctOverviewSvc.selectedDate = val;
  }

  /**
   * Format value for display in template. Necessary because of special case for ALAN2.
  */
  fmtReturn(value: number, fmtSpec = '1.1-1') {
    if (value == null) {
      return '';
    }
    const signed = fmtSpec.startsWith('+');
    if (signed) {
      fmtSpec = fmtSpec.slice(1);
    }
    if (this.showTradingLevelBasedFigures()) {
      let magnitudeSymbol = '';
      const absLevel = Math.abs(this.tradingLevel.TL);
      if (absLevel > 1000000) {
        value = value / 1000000;
        magnitudeSymbol = 'M';
      } else if (absLevel > 1000)  {
        value = value / 1000;
        magnitudeSymbol = 'K';
      }
      return this.currencyPipe.transform(this.tradingLevel.TL * value, this.tradingLevel.Currency, 'symbol', fmtSpec) + magnitudeSymbol;
    } else {
      if (signed) {
        return this.signedPercentPipe.transform(value, fmtSpec);
      }
      return this.percentPipe.transform(value, fmtSpec);
    }
  }

  getSelectedDate$(): Observable<Date> {
    return this._acctOverviewSvc.selectedDate$.pipe(
      take(1),
    );
  }

  setSelectedDate(newDate: Date, init = false) {
    const theDate = newDate; // moment(newDate).endOf('day').toDate();
    this._acctOverviewSvc.selectedDate = theDate;
    if (!init) { // we don't execute update if init is set because userid and accountid are still being loaded in onAfterViewInit
      this.acctDataSvc.cancelAccountOverviewSubj.next();
      this.accountDailyPerformanceOverview = null;
      this.updateAccountOverview(this.userId, this.accountId, theDate);
    }

    return this._acctOverviewSvc.selectedDate$;
  }

  set selectedProduct(id: int) {
    this._selectedProductSubj.next(id);
  }

  /** Set the navbarInitialSelections on init in order to support user configurable default period */
  setNavbarInitialSelection(period: string) {
    this.performanceBreakdownTabsInitial = this.performanceBreakdownTabs.indexOf(period);
    if (this.performanceBreakdownNavbar != null) {
      this.performanceBreakdownNavbar.reset();
    }
    this.portfolioDailyReturnTabsInitial = this.portfolioDailyReturnTabs.indexOf(period);
    this.strategyStyleReturnTabsInitial = this.strategyStyleReturnTabs.indexOf(period);
    this.assetClassReturnTabsInitial = this.assetClassReturnTabs.indexOf(period);
  }

  public tableSelectionChanged(tableName: string, event: Event) {
    this.selectedItems[tableName] = event;

    switch (tableName) {
      case 'assetClassPerformance':
        this.updateSubAssetClassPerformanceTableFiltering();
      // break; // fall through by design!
      // tslint:disable-next-line:no-switch-case-fall-through
      case 'subAssetClassPerformance':
        this.updateMarketPerformanceTableFiltering();
        this.updateMarketPerformanceTableBreadcrumbs();
        break;
      case 'market': {
        // open market-chart by setting selectedProduct
        const prodId = Object.keys(event)[0];
        if (prodId && !isNaN(Number(prodId))) {
          this._selectedProductSubj.next(Number(prodId));
        } else {
          this._selectedProductSubj.next(null);
        }
      } break;
    }
  }

  public marketPerformanceTableBreadcrumbClicked(crumb: {id: string, type: string}) {
    const id = crumb.id;
    const type = crumb.type;
    switch (type) {
      case 'assetClassPerformance': {
        // reset everything
        for (const classType of Object.keys(this.selectedItems)) {
          this.selectedItems[classType] = {};
        }
        this.marketPerformanceTableData = [];
        this.updateSubAssetClassPerformanceTableFiltering();
        this.updateMarketPerformanceTableFiltering();
      } break;
      case 'assetClassMarkets': {
        this.subAssetClassPerformanceTableData = [];
        this.marketPerformanceTableData = [];
        let acName: string;
        for (let i = 0; i < this.accountSubAssetClassesPerformanceAggregations.length; i++) {
          if (this.accountSubAssetClassesPerformanceAggregations[i].SubAssetClass.AssetClass.AssetClassId === Number(crumb.id)) {
            this.selectedItems['subAssetClassPerformance'][this.accountSubAssetClassesPerformanceAggregations[i].SubAssetClass.Name] = true;
            acName = this.accountSubAssetClassesPerformanceAggregations[i].SubAssetClass.AssetClass.Name;
          }
        }
        // this.updateSubAssetClassPerformanceTableFiltering();
        this.updateMarketPerformanceTableFiltering();
        this.marketPerformanceTableColumns[0]['label'] = `All ${acName.toLowerCase()} markets`;
      } break;
      case 'subAssetClassPerformance': {
        for (let i = 0; i < this.assetClassPerformanceAggregations.length; i++) {
          this.selectedItems['assetClassPerformance'][this.assetClassPerformanceAggregations[i].AssetClass.AssetClassId] = true;
        }
        this.selectedItems['subAssetClassPerformance'] = {};
        this.selectedItems['marketPerformance'] = {};
        this.subAssetClassPerformanceTableData = this._subAssetClassPerformanceTableData.slice();
        this.marketPerformanceTableData = [];
        this.subAssetClassPerformanceTableColumns[0]['label'] = 'All sectors';
        this.updateSubAssetClassPerformanceTableFiltering();
        this.updateMarketPerformanceTableFiltering();
      } break;
      case 'marketPerformance': {
        for (let i = 0; i < this.assetClassPerformanceAggregations.length; i++) {
          this.selectedItems['assetClassPerformance'][this.assetClassPerformanceAggregations[i].AssetClass.AssetClassId] = true;
        }
        this.selectedItems['subAssetClassPerformance'] = {};
        for (let i = 0; i < this.accountSubAssetClassesPerformanceAggregations.length; i++) {
          this.selectedItems['subAssetClassPerformance'][this.accountSubAssetClassesPerformanceAggregations[i].SubAssetClass.Name] = true;
        }
        this.selectedItems['marketPerformance'] = {};
        this.marketPerformanceTableData = this._marketPerformanceTableData.slice();
        this.marketPerformanceTableColumns[0]['label'] = 'All markets';
        this.updateMarketPerformanceTableFiltering();
      } break;
    }
  }

  fixDate(date: Date): number {
    const newDate = new Date(date);
    return newDate.setDate(date.getDate() + 1);
  }

  getOffsetDays(date) {
    return getOffsetDays(date);
  }

  getOffsetStartDays(date) {
    return getOffsetStartDays(date);
  }

  // adjust to most recent week day: friday if date is on the weekend
  getTradingDay(date: Date): Date {
    return moment(date).subtract(this.getOffsetDays(date), 'days').toDate();
  }

  getTradingStartDay(date: Date) {
    return moment(date).subtract(this.getOffsetStartDays(date), 'days').toDate();
  }

  headlineFiguresTitle(riskOverview) {
    if (riskOverview != null) {
      return `Headline risk figures as of ${moment.tz(
        riskOverview.LastRiskInfo.Date, this._appService.TZ
      ).format('DD-MMM-YYYY')} vs previous month-end`;
    } else {
      return `Headline risk figures`;
    }
  }

  resizeNavigationBar(viewportSize: {height: number, width: number}) {
    if (this.portfolioNavigationBar.nativeElement
      && this.mainContentContainer.nativeElement
    ) {
      const navHeight = this.portfolioNavigationBar.nativeElement.clientHeight;
      this.mainContentContainer.nativeElement.style.marginTop = `${navHeight}`;
    }
  }

  public marketSelect(event, market) {
    if (event === false) {
      // if it is an uncheck event, remove the 'market' from list and also remove the element in dictionary
      const index = this.assetClassMarket['queue'].indexOf(market);
      if (index >= 0) {
        delete this.assetClassMarket['model'][market];
        this.assetClassMarket['queue'].splice(index, 1);
      }

      return;
    }
    // if it is a check event, remove the first one in list and update dictionary
    this.assetClassMarket['queue'].push(market);
    if (this.assetClassMarket['queue'].length > 2) {
      const value = this.assetClassMarket['queue'][0];
      delete this.assetClassMarket['model'][value];
      this.assetClassMarket['queue'].shift();
    }
  }

  public assetClassChange(event) {
    for (let i = 0; i < this.assetClass.length; i++) {
      if (this.assetClass[i] === false) {
        for (let j = 0; j < this.assetClassBreakdown[i]; j++) {
          this.assetClassBreakdown[i][j] = false;
        }
      }
    }
  }

  public ClickPerformancePeriodSelect(event: { selected: string }) {
    this.acctDataSvc.cancelPerformanceAggregationSubj.next();
    this.PerformanceBreakdownPeriodSelect(event);
  }

  public PerformanceBreakdownPeriodSelect(event: { selected: string }) {
    if (this.performanceBreakdownTabs.indexOf(event.selected) >= 0) {
      this.PortfolioDailyReturnPeriodSelect(event);
      this.StrategyStyleReturnPeriodSelect(event);
      this.AssetClassReturnPeriodSelect(event);
      return true;
    } else {
      return false;
    }
  }

  /* Convert IntradayPerformancePoints to DailyReturnData for PortfolioDailyReturn chart */
  public createCumulativeIntradayPerformance(intraPerfPoints: IntradayPerformancePoint[]): DailyReturnData  {
    const cumulativePoints: PerformancePoint[] = [];
    const perfPoints: PerformancePoint[] = [];
    const intradayReturnData: DailyReturnData = { CumulativePerformances: [], DailyPerformances: [] };
    let hourlyPerf = 0.0;
    for (let i = 0; i < intraPerfPoints.length; i++) {
      const perf = intraPerfPoints[i].Performance;
      const time = intraPerfPoints[i].Time;
      cumulativePoints.push({Performance: perf, Date: time} as PerformancePoint);
      if (new Date(time).getMinutes() === 0) {
        perfPoints.push({Performance: perf - hourlyPerf, Date: time});
        hourlyPerf = perf;
      }
    }
    intradayReturnData.CumulativePerformances = cumulativePoints;
    intradayReturnData.DailyPerformances = perfPoints;
    return intradayReturnData;
  }

  public PortfolioDailyReturnPeriodSelect(event) {
    this.getSelectedDate$().pipe(
      take(1)
    ).subscribe(
      (selDate) => {
        if (selDate == null) {
          this.logger.debug('PortfolioDailyReturnPeriodSelect date is null');
          return;
        }
        const date = moment.tz(selDate, TZ)
          .subtract(this.getOffsetStartDays(selDate), 'days');
        let fromDate: moment.Moment = null;
        switch (event.selected) {
          case 'Today': {
            fromDate = null;
            this.spinnerService.show('portfolio-daily-return');
            if (this.portfolioIntradayReturn != null && this.portfolioIntradayReturn.length > 0) {
              this.createPortfolioDailyReturn(this.portfolioIntradayReturn);
              this.portfolioIntradayReturn = null; // wipe cache so it's possible to update without reloading whole page.
            } else {
              this.loadPortfolioIntradayReturn$(this.userId, this.accountId, event.selected).pipe(
                takeUntil(this.acctDataSvc.cancelPerformanceBreakdown$),
              ).subscribe(
                (data) => {
                  this.portfolioIntradayReturn = data;
                  this.createPortfolioDailyReturn(data);
                },
                (err) =>  {},
                () => this.spinnerService.hide('portfolio-daily-return')
              );
            }
          } break;
          case 'Last day': {
            fromDate = date.clone();
          } break;
          case 'MTD': {
            fromDate = date.clone().startOf('month');
          } break;
          case 'YTD': {
            fromDate = date.clone().startOf('year');
          } break;
          case '3 months': {
            // const offset = this.getOffsetStartDays(date.clone().subtract(3, 'months').toDate());
            fromDate = moment(date.clone()).subtract(3, 'months').add(1, 'day'); // .subtract(offset, 'days');
          }break;
          case '6 months': {
            const offset = this.getOffsetStartDays(moment(date).subtract(6, 'months').toDate());
            fromDate = moment(date.clone()).subtract(6, 'months').add(1, 'day'); // .subtract(offset, 'days');
          }break;
          case '1 year': {
            const offset = this.getOffsetStartDays(moment(date).subtract(1, 'years').toDate());
            fromDate = moment(date.clone()).subtract(1, 'years').add(1, 'day'); // .subtract(offset, 'days');
          }break;
          case 'All': {
            const offset = this.getOffsetStartDays(beginningOfTime.toDate());
            fromDate = beginningOfTime.clone().add(1, 'day'); // .subtract(offset, 'days');
          }break;
        }
        if (fromDate) {
          this.spinnerService.show('portfolio-daily-return');
          this.loadDailyReturnData(this.userId, this.accountId, fromDate.toDate(), date.toDate(), event.selected);
        }
      }
    );
  }

  public loadStyleCumulativeReturn(userId: number|string, accountId: string | number, fromDate: Date, toDate: Date, period: string): void {
    const handleError = (err: Error | string) => {
      let errMessage = 'Could not fetch style cumulative returns data';
      if (typeof err === 'string') {
        errMessage += ' ' + err;
      } else {
        if ('message' in err) {
          errMessage += ' ' + err.message;
        }
      }
      this.notificationsService.error('Error', errMessage);
      this.logger.error(errMessage, [err]);

      const defaultPeriod = this.strategyStyleReturnTabs[this.strategyStyleReturnTabsInitial];
      if (period !== defaultPeriod) {
        this.StrategyStyleReturnPeriodSelect({selected: defaultPeriod});
      }
    };

    if (accountId === 'DYNF') {
      this.styleCumulativeReturns = null;
      return;
    }

    this.styleCumulativeReturns = null;

    if (!this.isIndex(accountId)) {
      this.spinnerService.show('strategy-style-return');
      this.acctDataSvc.getAccountStyleCumulativeReturn(+userId, accountId as string, fromDate, toDate).subscribe(
        data => {
          if ((data != null) && (data.length > 1)) { // show if more than one style
            this.styleCumulativeReturns = data;
            Promise.resolve(null).then(
              () => this.flags.set('showStyles', true)
            );
            setTimeout(
              () => this.createStyleCumulativeReturnChart(data, period)
            );
          } else {
            if (data.length === 0) {
              this.notificationsService.warn('Style return data was empty', 'Maybe you do not have access to this data?');
            }
            this.styleCumulativeReturns = null;
            this.flags.set('showStyles', false);
            return null;
          }
        },
        handleError,
        () => this.spinnerService.hide('strategy-style-return')
      );
    } else {
      this.styleCumulativeReturns = null; // no style* for indices
    }
  }


  public StrategyStyleReturnPeriodSelect(event: {selected: string}) {
    this.getSelectedDate$().pipe(take(1)).subscribe(
      (selDate) => {
        if (selDate == null) {
          this.logger.debug('StrategyStyleReturnPeriodSelect date is null');
          return;
        }
        const toDate: Date = moment(selDate).subtract(this.getOffsetStartDays(selDate), 'days').toDate();
        const fromDate = getOffsetPeriodStartDate(toDate, event.selected);
    
        this.spinnerService.show('strategy-style-return');
    
        this.loadStyleCumulativeReturn(this.userId, this.accountId, fromDate, toDate, event.selected);
      }
    );
  }

  public loadAssetClassesCumulativeReturns(
    userId: number | string,
    accountId: number | string,
    fromDate: Date,
    toDate: Date,
    period: string): void {

    if (this.assetClassesCumulativeReturnBarChart != null) {
      try {
        this.assetClassesCumulativeReturnBarChart.destroy();
      } catch {
        // ...
      }
    }
    if (this.assetClassesCumulativeReturnLineChart != null) {
      try {
        this.assetClassesCumulativeReturnLineChart['destroy']();
      } catch {
        // ...
      }
    }

    const handleError = (err: Error | string) => {
      let errMessage = 'Could not fetch asset class cumulative returns data!';
      if (typeof err === 'string') {
        errMessage += ' ' + err;
      } else {
        if ('message' in err) {
          errMessage += ' ' + err.message;
        }
      }
      this.notificationsService.error('Error', errMessage);
      this.logger.error(errMessage, [err]);

      const defaultPeriod = this.assetClassReturnTabs[this.assetClassReturnTabsInitial];
      if (period !== defaultPeriod) {
        this.AssetClassReturnPeriodSelect({selected: defaultPeriod});
      }
    };

    this.spinnerService.show('performance-breakdown');
    const assetClassReturns$: Observable<
      AssetClassIntradayPerformances[] | AssetClassCumulativeReturns[]
    > = this.acctDataSvc.getAssetClassCumulativeReturns$(
      +userId,
      accountId,
      fromDate == null ? null : dt2midnights(fromDate),
      dt2midnights(toDate),
    );
    assetClassReturns$.subscribe(
      data => {
        if (data == null || data.length === 0) {
          this.notificationsService.warn('Asset class returns is empty', `<p>Maybe you do not have access to this data?</p>
          <p>Resetting performance section.</p>`);
          return;
        }
        if (data[0]['Performances']) {
          this.accountAssetClassesIntradayPerformances = data as AssetClassIntradayPerformances[];
        } else {
          this.assetClassesCumulativeReturns = data as AssetClassCumulativeReturns[];
        }
        Promise.resolve(null).then(() => {
          // need a delay to allow chart element to display
          this.layoutMode$.subscribe(
            layout => {
              if (layout === 'wide') {
                this.createAssetClassesCumulativeReturnChart(data, period);
              } else {
                if (this.assetClassesCumulativeReturnLineChart != null) {
                  try {
                    this.assetClassesCumulativeReturnLineChart['destroy']();
                  } catch (error) {
                    // sometimes destroy throws an error to do with exporting module. Doesn't matter.
                  }
                }
              }
            }
          );
          this.createAssetClassesCumulativeReturnsBarChart(data, period);
        });
      },
      err => handleError,
      () => setTimeout(() => this.spinnerService.hide('performance-breakdown'), 5000),
    );
  }

  public setPerformanceTableReturnLabel(label: string): void {
    this.assetClassPerformanceTableColumns[1].label = label;
    this.subAssetClassPerformanceTableColumns[1].label = label;
    this.marketPerformanceTableColumns[1].label = label;
  }

  public AssetClassReturnPeriodSelect(event) {
    this.getSelectedDate$().pipe(
      take(1),
    ).subscribe(
      (selDate) => {
        if (selDate == null) {
          this.logger.debug('AssetClassReturnPeriodSelect date is null');
          return;
        }
        this.assetClassBreakdownPerformanceTableData = null;
        this.subAssetBreakdownClassPerformanceTableData = null;
        this.marketBreakdownTableData = null;
        const date = moment.tz(selDate, TZ).subtract(this.getOffsetStartDays(selDate), 'days').toDate();
        let fromDate = null;
        function returnLabel(period?: string) {
          const periodToLabelMap = {
            Today: 'Today\'s return',
            Daily: 'Daily return',
            MTD: 'MTD return',
            YTD: 'YTD return',
            '3 months': 'Three months return',
            '6 months': 'Six month return',
            '1 year': 'One year return',
            All: 'All return',
          };
    
          return periodToLabelMap[period] || 'Return';
        }
        fromDate = getOffsetPeriodStartDate(date, event.selected);
        const label = returnLabel(event.selected);
        // update performance tables with period data
        this.assetClassCumulativeReturnPeriod = event.selected;
        this.loadAssetClassesCumulativeReturns(this.userId, this.accountId, fromDate, date, event.selected);
    
        const handleError = (err: Error | string) => {
          const errMessage = 'Could not fetch asset class cumulative returns data!';
          this.notificationsService.error('Error', errMessage);
          this.logger.error(errMessage, [err]);
    
          const defaultPeriod = this.assetClassReturnTabs[this.assetClassReturnTabsInitial];
          if (event.selected !== defaultPeriod) {
            this.AssetClassReturnPeriodSelect({selected: defaultPeriod});
          }
        };
    
        this.assetClassPerformanceTableData = null;
        this.subAssetClassPerformanceTableData = null;
        this.marketPerformanceTableData = null;
    
        if (event.selected === 'Today' ) {
          this.spinnerService.show('asset-class-performance-table');
          let intradayDetails$: Observable<[AssetClassIntradayDetails[], SubAssetClassIntradayDetails[], ProductIntradayDetails[]]>;
          if (this.isIndex(this.accountId)) {
            intradayDetails$ = this.pspWebService.getIndexIntradayPerformanceRiskOverview(this.userId, this.accountId).pipe(
              takeUntil(this.acctDataSvc.cancelPerformanceBreakdown$),
              map((data: IndexIntradayPerformanceRiskOverview) => {
                return [
                  data.IntradayPerformanceRiskOverview.AssetClassesIntradayDetails,
                  data.IntradayPerformanceRiskOverview.SubAssetClassesIntradayDetails,
                  data.IntradayPerformanceRiskOverview.ProductsIntradayDetails,
                ] as [AssetClassIntradayDetails[], SubAssetClassIntradayDetails[], ProductIntradayDetails[]];
              })
            );
          } else {
            intradayDetails$ = forkJoin<AssetClassIntradayDetails[], SubAssetClassIntradayDetails[], ProductIntradayDetails[]>  ([
              this.pspWebService.getAccountAssetClassesIntradayDetails(this.userId, this.accountId),
              this.pspWebService.getSubAssetClassesIntradayDetails(this.userId, this.accountId),
              this.pspWebService.getProductsIntradayDetails(this.userId, this.accountId),
            ]);
          }
          intradayDetails$.pipe(
            takeUntil(this.acctDataSvc.cancelPerformanceBreakdown$),
            // retryAfter20(),
          ).subscribe(
            ([acDetails, subAcDetails, prodDetails]) => {
              this.updateAssetClassPerformanceTableData(acDetails);
              this.updateSubAssetClassPerformanceTableData(subAcDetails);
              this.updateMarketPerformanceTableData(prodDetails);
              this.setPerformanceTableReturnLabel(label);
            },
            handleError,
          ).add(() => {
            this.spinnerService.hide('asset-class-performance-table');
          });
        } else {
          if (this.assetClassPerformanceAggregations != null && this.assetClassPerformanceAggregations.length > 0) {
            this.updateAssetClassPerformanceTableData(this.assetClassPerformanceAggregations);
            this.updateSubAssetClassPerformanceTableData(this.accountSubAssetClassesPerformanceAggregations);
            this.updateMarketPerformanceTableData(this.accountProductsPerformanceAggregations);
            this.setPerformanceTableReturnLabel(returnLabel(event.selected));
          } else {
            this.loadPerformanceOverview(this.userId, this.accountId, date);
            this.setPerformanceTableReturnLabel(returnLabel(event.selected));
          }
        }
      }
    );
  }

  public createAssetClassesCumulativeReturnsBarChart(
    data: AssetClassIntradayPerformances[] | AssetClassCumulativeReturns[],
    period: string
  ) {
    const assetClassReturnCategory: string[] = [];
    const assetClassReturnSeriesData: Highcharts.Point[] = [];

    if (data == null) {
      return null;
    }
    if ( period === 'Today' ) {
      const performances = data as AssetClassIntradayPerformances[];
      performances.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      for (let i = 0; i < performances.length; i++) {
        const className = performances[i].AssetClass.Name;
        assetClassReturnCategory.push(className);
        const latestPerf = performances[i].Performances[performances[i].Performances.length - 1];
        if (latestPerf) {
          assetClassReturnSeriesData.push({ name: className, y: latestPerf.Performance * 100} as Highcharts.Point);
        }
      }

    } else {
      const performances = data as AssetClassCumulativeReturns[];
      performances.sort((a, b) => cmpAssetClasses(a.AssetClass, b.AssetClass, 'Name'));
      for (let i = 0; i < performances.length; i++) {
        assetClassReturnCategory.push(performances[i].AssetClass.Name);
        assetClassReturnSeriesData.push({
          name: performances[i].AssetClass.Name,
          y: performances[i].PerformancesOrdered[performances[i].PerformancesOrdered.length - 1].Performance * 100
        } as Highcharts.Point);
      }
    }

    const gridStepMap = {
      'Today': 0.25,
      'Last day': 0.25,
      '3 months': 0.25,
      '6 months': 1.0,
      '1 year': 1.0,
      'MTD': 0.25,
      'YTD': 1.0,
    };

    setTimeout(() => {
      const chart = this.createVerticalBarChart(
        'asset-class-return',
        assetClassReturnCategory,
        assetClassReturnSeriesData,
        'bar',
        gridStepMap[period],
      );
      this.assetClassesCumulativeReturnBarChart = chart;
      this.spinnerService.hide('performance-breakdown');
    });
  }

  // This function is used to create vertical BarCharts
  createVerticalBarChart(
    id: string,
    categories: string[],
    series: Highcharts.Point[],
    type: 'bar' | 'column',
    gridStep = 1.0,
    minParam = null,
    maxParam = null,
  ): Highcharts.Chart {
    // const colors = ['#05635B', '#EA9632', '#5E3B17', '#C74308'];
    series = this.sortAssetClasses(series);
    categories = this.sortAssetClasses(categories, null);
    series.forEach((item, i) => {
      if (item['color'] === undefined) {
        const colorList = (type === 'bar') ? this.lineColors : this.columnColors;
        item['color'] = colorList[i % colorList.length];
      }
    });
    if (categories == null) {
      categories = series.map((item, i) => {
        return item.name;
      });
    }

    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this.showTradingLevelBasedFigures()) {
       [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      for (const point of series) {
        point.y = point.y * this.tradingLevel.TL * scalingFactor;
      }
    }

    const maxAbsValue = series.map(item => {
      return Math.abs(item.y);
    }).reduce((a, b) => Math.max(a, b), 0);
    let maxVal = 0;
    let minVal = 0;
    if (series != null && (minParam == null || maxParam == null)) {
      maxVal = Math.ceil(maxAbsValue / gridStep) * gridStep; // round up to nearest 0.5
      maxVal = Math.max(maxVal, gridStep);
      maxVal = isNil(maxParam) ? maxVal : maxParam;
      minVal = isNil(minParam) ? -maxVal : minParam;
    }
    try {
      return Highcharts.chart(id, {
        chart: {
          type: type
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: categories,
          tickWidth: 1,
          title: { text: null, },
        },
        yAxis: {
          labels: {
            formatter: function() {
              if (this.value === undefined) {
                console.error('yAxis value undefined in formatter()', this);
                return 'N/A';
              }
              if (magnitudeSymbol === undefined) {
                return `${sigFigs(this.value as number, 2)} %`;
              } else {
                return `€${sigFigs(this.value as number, 2)}${magnitudeSymbol}`;
              }
            }
          },
          min: minVal,
          max: maxVal,
          tickAmount: 5,
          title: { text: null, },
        },
        credits: {
          enabled: false
        },
        colors: (type === 'bar') ? this.lineColors : this.columnColors,
        plotOptions: {
          bar: {
            minPointLength: 3,
          },
          column: {
            minPointLength: 3,
          },
        },
        series: [ {
          showInLegend: false,
          data: series,
        } as Highcharts.SeriesOptionsType],
        tooltip: {
          pointFormatter: function() {
            const chartOpts = this.series.chart.options;
            if (magnitudeSymbol === undefined) {
              return `<b><span style="color: ${this.color}">•</span>
              ${this.y.toFixed(chartOpts.tooltip.valueDecimals)}${chartOpts.tooltip.valueSuffix}</b><br/>`;
            } else {
              return `<b><span style="color: ${this.color}">•</span>
              €${sigFigs(this.y, chartOpts.tooltip.valueDecimals)}${magnitudeSymbol}</b><br/>`;
            }
          },
          valueDecimals: 2,
          valueSuffix: ' %',
        },
      });
    } catch (error) {
      if ('message' in error && error.message.includes('error #13')) {
        // eat missing chart element error. Probably intentionally hidden.
        this.logger.info('Skipping hidden chart', id);
      } else {
        throw(error);
      }
    }
  }

  // Create portfolio daily return chart.
  public createPortfolioDailyReturn(data: DailyReturnData|IntradayPerformancePoint[], realTrackStartDate?: Date): void {
    let intraday = false;
    let retData: DailyReturnData = null;
    if (data == null) {
      return;
    }

    if ((<DailyReturnData>data).DailyPerformances) {
      if ((<DailyReturnData>data).DailyPerformances.length === 0) {
        return;
      }
      retData = <DailyReturnData>data;
    } else {
      intraday = true;
      if ((<IntradayPerformancePoint[]>data).length === 0) {
        return;
      }
      retData = this.createCumulativeIntradayPerformance(<IntradayPerformancePoint[]>data);
    }

    this._acctOverviewSvc.acctIdxs$.pipe(take(1)).subscribe({
      next: (aiList) => {
        const ai = aiList.find(aiItem => aiItem.Id === this.accountId);
        const portfolioDailyReturnData = retData.DailyPerformances.map(d => {
          return [moment.tz(d.Date, TZ).valueOf(), d.Performance * 100.0] as [number, number];
        });
  
        const portfolioCumulativeDailyReturnData = retData.CumulativePerformances.map(d => {
          return [moment.tz(d.Date, TZ).valueOf(), d.Performance == null ? null : Number(d.Performance) * 100.0] as [number, number];
        });
  
        const cumSeries: {
          simulated: [number, number][],
          real: [number, number][],
        } = { simulated: [], real: [] };
  
        let max_y = portfolioCumulativeDailyReturnData.reduce(
          (acc, point) => Math.max(Math.abs(point[1]), acc)
          , 0) * this.defaultLineChartScalingFactor;
  
        // Special case for ALAN2
        let magnitudeSymbol: string;
        let scalingFactor = 1;
        if (this.showTradingLevelBasedFigures()) {
          [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
          for (const p of portfolioCumulativeDailyReturnData) {
            if (p[1] == null) {
              continue;
            }
            p[1] = p[1] * this.tradingLevel.TL * scalingFactor;
          }
          for (const p of portfolioDailyReturnData) {
            if (p[1] == null) {
              continue;
            }
            p[1] = p[1] * this.tradingLevel.TL * scalingFactor;
          }
          max_y = max_y * this.tradingLevel.TL * scalingFactor;
        }
  
        if (realTrackStartDate != null) {
          const startDate = moment.tz(realTrackStartDate, 'Europe/Helsinki');
          let simSeries: [number, number][] = [];
          let realSeries: [number, number][] = [];
          const realIndex = portfolioCumulativeDailyReturnData.findIndex(point => point[0] >= startDate.valueOf());
          if (realIndex < 0) {
            simSeries = portfolioCumulativeDailyReturnData;
          }
          if (realIndex === 0) {
            realSeries = portfolioCumulativeDailyReturnData;
          }
          if (realIndex > 0) {
            simSeries = portfolioCumulativeDailyReturnData.slice(0, realIndex + 1); // +1 to avoid a gap in the line
            realSeries = portfolioCumulativeDailyReturnData.slice(realIndex);
  
            // add dummy points to avoid zooming
            realSeries.splice(0, 0, [simSeries[0][0], null]);
            simSeries.push([realSeries[realSeries.length - 1][0], null]);
          }
          cumSeries.simulated = simSeries;
          cumSeries.real = realSeries;
        } else {
          // if realTrackStartDate is empty we assume everything is real
          cumSeries.real = portfolioCumulativeDailyReturnData;
        }
  
        const chartOptions = <Highcharts.Options>{
          chart: {
            events: {
              render: deleteLegendSymbols,
            }
          },
          colors: this.lineColors,
          legend: checkboxLegendOptions,
          title: {
            text: null
          },
          tooltip: {
            valueDecimals: 1,
            xDateFormat: (intraday ? '%H:%M, ' : '') + '%A, %b %d, %Y',
            pointFormatter: function() {
              let valStr: string;
              if (magnitudeSymbol == null) {
                valStr = Number(this.y).toFixed(2).toString() + '%';
              } else {
                valStr = '€' + sigFigs(Number(this.y), 3).toString() + magnitudeSymbol;
              }
              return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${valStr}</b><br/>`;
            },
            split: false,
            shared: true,
          },
          xAxis: {
            type: 'datetime',
            crosshair: true,
            labels: {
              format: intraday ? '{value:%H:%M}' : '{value:%d-%m-%Y}',
              padding: 10,
            },
            max: intraday ? moment.tz(TZ).endOf('day').valueOf() : null,
            min: intraday ? moment.tz(TZ).startOf('day').valueOf() : null,
            minTickInterval: 1000 * (intraday ? 60 * 60 : 60 * 60 * 24), // 1 hour or 1 day
            // tickPixelInterval: 100,
            units:  undefined,
          },
          yAxis: <Highcharts.AxisOptions> {
            labels: {
              formatter: (opts: Highcharts.AxisLabelsFormatterContextObject) => {
                if (magnitudeSymbol != null) {
                  return `€${sigFigs(Number(opts.value), 3)}${magnitudeSymbol}`;
                }
                return `${sigFigs(Number(opts.value), 2)} %`;
              },
            },
            tickAmount: 5,
            title: {
              text: 'Return'
            },
            max: max_y,
            min: -max_y,
          },
          exporting: {
            enabled: false
          },
          credits: {
            enabled: false
          },
          series: [
            <Highcharts.SeriesColumnOptions> {
              type: 'column',
              labels: {
                format: '{value:.2f} %',
              },
              color: this.lineColors[1],
              name: intraday ? 'Hourly return' : 'Daily return',
              data: portfolioDailyReturnData,
              pointWidth: intraday ? 20 : null,
              },
            // line series added below
          ],
          plotOptions: {
            series: {
              connectNulls: false,
              marker: {
                enabled: false,
              },
            },
          },
        };
  
        const genericReturnSeriesOptions = <Highcharts.SeriesLineOptions> {
          type: 'line',
          labels: {
            format: '{value:.2f} %',
          },
        };
        const defaultTitle = ai != null ? ai.Name : 'Cumulative return';
        const simTitle = defaultTitle + ' (simulated)';
        const realTitle = defaultTitle + ' (real)';
        const colorSelection = [
          this.lineColors[2], // light brown
          this.lineColors[0]  // dark brown
        ]; // series will take one color each
  
        if (cumSeries.simulated.length > 0) {
          const seriesOptions = cloneDeep(genericReturnSeriesOptions);
          seriesOptions.data = cumSeries.simulated;
          seriesOptions.name = cumSeries.real.length > 0 ? simTitle : defaultTitle;
          seriesOptions.color = colorSelection.shift() || undefined;
          chartOptions.series.push(seriesOptions);
        }
        if (cumSeries.real.length > 0) {
          const seriesOptions = cloneDeep(genericReturnSeriesOptions);
          seriesOptions.data = cumSeries.real;
          seriesOptions.name = cumSeries.simulated.length > 0 ? realTitle : defaultTitle;
          seriesOptions.color = colorSelection.pop() || undefined;
          chartOptions.series.push(seriesOptions);
        }
  
        Promise.resolve(null).then(() => {
          try {
            Highcharts.chart('portfolio-daily-return', chartOptions);
          } catch (err) {
            if (err.message.includes('Highcharts error #13')) {
              this.logger.warn(`Portfolio daily return chart element #portfolio-daily-return not available`);
            } else {
              this.logger.error(`Cannot draw portfolio daily return chart`, err);
            }
          }
          this.spinnerService.hide('portfolio-daily-return');
        });
        }
    });


  }

  disableTodayButtons(val: boolean, repeat?: number) {
    let nullflag = false;
    this.showTodayButtons = !val;
    [
      this.performanceBreakdownNavbar,
    ].map((navbar: NavbarButtonComponent) => {
      if (navbar != null) {
        if (!this.showTodayButtons) {
          navbar.disable('Today');
        } else {
          navbar.enable('Today');
        }
      } else {
        nullflag = true;
      }
    });
    // Because buttons may not be loaded by the time this method runs,
    // we repeat
    let repeatCounter = 0;
    if (repeat != null) {
      repeatCounter = repeat + 1;
    }
    if (nullflag) {
      if (repeatCounter < 20) {
        setTimeout(
          () => {
            this.disableTodayButtons(val, repeatCounter);
          },
          250
        );
      }
    }
  }

  sortAssetClasses(items, classAttr = 'name') {
    items.sort((a, b) => cmpAssetClasses(a, b, classAttr));
    return items;
  }


  /** This function is used to create cumulative return chart
   * @param id - the id of the HTML element that will contain the chart
   * @param series - the data series
   * @param format - the format to be used for yAxis labels
   * @param useDefaultRange - whether to show the builtin Highcharts range control - don't use this
   * @param isFiveDay - use special formatting for five-day price chart
   * @param extraChartParams - Highcharts.Options object that will used to override defaults
   */
  createCumulativeReturnChart(
    id: string,
    series: Highcharts.SeriesLineOptions[],
    format: string,
    useDefaultRange = false,
    isFiveDay = false,
    extraChartParams: Highcharts.Options = {}
  ): Highstock.StockChart {
    let rangeSelector = {enabled: false};
    let scrollbar = {enabled: false};
    if (useDefaultRange) {
      rangeSelector = {enabled: true};
      rangeSelector['selected'] = 0;
      scrollbar = {enabled: true};
    }

    series = this.sortAssetClasses(series);

    const series_max_vals: number[] = [];
    for (const s of series) {
      series_max_vals.push((s.data as (number | [number | string, number] | Highcharts.Point)[]).reduce<number>(
        (acc: number, point: number | [number | string, number] | Highcharts.Point): number => {
          let value: number;
          if (typeof point === 'number') {
            value = point;
          } else if (point['y'] === undefined) {
            value = (point as [number | string, number])[1];
          } else {
            value = (point as Highcharts.Point).y;
          }
          return Math.max(Math.abs(value), acc);
        },
      0));
    }
    let max_y = this.defaultLineChartScalingFactor * Math.max(...series_max_vals);

    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this.showTradingLevelBasedFigures()) {
      [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();

      max_y = max_y * this.tradingLevel.TL * scalingFactor;
      for (const s of series) {
        for (let point of s.data) {
          if (typeof point === 'number') {
            if (point == null) {
              continue;
            }
            point = point * this.tradingLevel.TL * scalingFactor;
          } else if (Array.isArray(point) && point.length >= 2) {
            if (point[1] == null) {
              continue;
            }
            point[1] = (point as [number | string, number])[1] * this.tradingLevel.TL * scalingFactor;
          } else if ('y' in point) {
            if (point.y == null) {
              continue;
            }
            (point as Highcharts.Point).y = (point as Highcharts.Point).y
              * this.tradingLevel.TL * scalingFactor;
          }
        }
      }
    }


    let chartParams: Highstock.Options = {
      colors: this.lineColors,
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: checkboxLegendOptions,
      navigator: {
        enabled: false
      },
      plotOptions: {
        series: {
          connectNulls: false,
        },
      },
      rangeSelector,
      scrollbar,
      series: series,
      tooltip: {
        valueDecimals: 2,
        pointFormatter: function() {
          const valStr = magnitudeSymbol === undefined ?
            `${this.y.toFixed(2)} %`
            : `€${sigFigs(this.y, 3)}${magnitudeSymbol}`;
          return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${valStr}</b><br/>`;
        },
        split: false,
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        // crosshair: true,
        labels: {
          enabled: true,
          format: '{value:%d-%m-%Y}',
        },

      },
      yAxis: {
        labels: {
          // format: format,
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            return magnitudeSymbol === undefined ?
              `${Number(this.value)} %`
              : `€${sigFigs(this.value as number, 2)}${magnitudeSymbol}`;
          },
        },
        max: max_y,
        min: -max_y,
        opposite: false,
        tickAmount: 5,
        title: {
          text: 'Return',
        }
      },
    };
    if (isFiveDay) {
      chartParams.tooltip.pointFormat = '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>';
      chartParams.xAxis['labels'] = {
        step: 1,
      };
      chartParams.xAxis['minTickInterval'] = moment.duration(1, 'day').asMilliseconds();
      chartParams.yAxis['labels']['reserveSpace'] = true;
      chartParams.yAxis['showLastLabel'] = true;
    }
    if (this.showTradingLevelBasedFigures()) {
      chartParams = merge(extraChartParams as Highstock.Options, chartParams);
    } else {
      chartParams = merge(chartParams, extraChartParams);
    }
    try {
      return stockChart(id, chartParams);
    } catch (error) {
      if (error.message.indexOf('error #13') >= 0) {
        // eat missing chart element error. Probably intentionally hidden.
        this.logger.info('Skipping hidden chart', id);
      } else {
        throw(error);
      }
    }
  }


  showTradingLevelBasedFigures(): boolean {
    const showTL = this.acctDataSvc.showTradingLevelBasedFigures(this.userId, this.accountId, this.tradingLevel);
    if (this._showTradingLevelBasedFigures !== showTL) {
      setTimeout(() => {
        if (this._changeDetRef != null) {
          this._changeDetRef.detectChanges()
        }
      });
      this._showTradingLevelBasedFigures = showTL;
    }
    return showTL;
  }

  getTradingLevelFactors(): [string, number] {
    let magnitudeSymbol: string;
    let scalingFactor = 1;
    if (this.tradingLevel != null) {
      if (this.tradingLevel.TL > 1000000) {
        scalingFactor = 1 / 100000000;
        magnitudeSymbol = 'M';
      } else if (this.tradingLevel.TL > 1000) {
        scalingFactor = 1 / 100000;
        magnitudeSymbol = 'K';
      } else {
        scalingFactor = 1 / 100;
        magnitudeSymbol = '';
      }
    }
    return [magnitudeSymbol, scalingFactor];
  }

  // This function is used to create area chart
  createAreaChart(id, series) {
    series = this.sortAssetClasses(series);
    const options = <Highstock.Options>{
      rangeSelector: {
        selected: 0,
        enabled: true,
      },
      scrollbar: {
        enabled: true,
      },
      credits: {
        enabled: false
      },
      legend: checkboxLegendOptions,
      title: {
        text: ''
      },
      chart: {
        type: 'area'
      },
      xAxis: {
        type: 'datetime',
        tickmarkPlacement: 'on',
        labels: {
          format: '{value:%d-%m-%Y}',
        },
      },
      yAxis: {
        title: {
          text: 'Percent'
        },
        max: 100,
        min: 0,
      },
      colors: this.lineColors,
      tooltip: {
        backgroundColor: 'rgba(247,247,247,1)',
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b><br/>',
        shared: true,
        split: false,
      },
      plotOptions: {
        area: {
          stacking: 'percent',
          lineColor: '#ffffff',
          lineWidth: 0,
          fillOpacity: 1,
          marker: {
            enabled: false,
          }
        }
      },
      exporting: {
        enabled: false
      },
      series: series,
    };
    try {
      Highcharts.chart(id, options);
    } catch (error) {
      if (error.message.indexOf('error #13') >= 0) {
        // eat missing chart element error. Probably intentionally hidden.
        this.logger.info('Skipping hidden chart', id);
      } else {
        throw(error);
      }
    }
  }


  /* Construct the Highcharts configuration parameters for return charts. */
  public buildReturnChartParams(
    period: string,
    series?: Highcharts.SeriesLineOptions[],
    min?: number,
    max?: number): Highcharts.Options {
    const chartPeriodStepMap = {
      'Today':    0.25,
      'Last day':    0.25,
      'MTD':      0.5,
      'YTD':      1.0,
      '3 months':  0.5,
      '6 months': 1.0,
      '1 year':   1.0,
      'All':      2.0,
    };

    let maxVal = 0;
    let minVal = 0;
    if (series != null && (min == null || max == null)) {
      for (let i = 0; i < series.length; i++) {
        for (let j = 0; j < series[i].data.length; j++) {
          maxVal = Math.max(maxVal, Math.abs(series[i].data[j][1]));
        }
      }
      maxVal = Math.ceil(maxVal / chartPeriodStepMap[period]) * chartPeriodStepMap[period]; // round up to nearest 0.5
      maxVal = Math.max(maxVal, chartPeriodStepMap[period]);
      maxVal = isNil(max) ? maxVal : max;
      minVal = isNil(min) ? -maxVal : min;
    }

    maxVal = Math.ceil(this.defaultLineChartScalingFactor * maxVal);
    minVal = -maxVal;

    const chartParams: Highcharts.Options = {
      legend: checkboxLegendOptions,
      time: {
        timezone: this._appService.TZ,
        useUTC: false,
      },
      xAxis: {
        dateTimeLabelFormats: {
          hour: '%H:%M',
          day: '%d-%m-%Y',
          week: '%d-%m-%Y',
          month: '%b-%Y',
        },
        labels: {
          rotation: -45,
        },
      },
      yAxis: {
        labels: {
          format: '{value:f} %',
          y: +5,
        },
        max: isNumber(max) ? max : maxVal,
        min: isNumber(min) ? min : minVal,
        opposite: false,
        showLastLabel: true,
        tickAmount: 5,
        title: {
          text: 'Return',
        }
      },
    };

    if (period === 'Today') {
      merge(chartParams, {
        xAxis: {
          dateTimeLabelFormats: {
            minute: '%H:%M:%s',
            hour: '%H:%M',
            day: '%H:%M',
          },
          endOnTick: false,
          labels: {
            format: '{value:%H:%M}',
            rotation: 270,
          },
          min: +(moment().tz(this._appService.TZ).startOf('day')), // 00:00:00
          maxPadding: 0.2,
          max: +(moment().tz(this._appService.TZ).endOf('day')), // 23:59:59
          ordinal: false,
          startOnTick: false,
          tickInterval: 3600 * 1000, // 1 hour
          units : [['hour', [1, ]]],
        },
      });
    }

    if (period === 'Last day') {
      Object.assign(chartParams, {
        xAxis: {
          dateTimeLabelFormats: {
            day: '%d-%m-%Y',
          },
          labels: {
            y: -75,
          },
          minPadding: 0.20,
          maxPadding: 0.20,
          tickWidth: 0,
        }
      });
    }

    if (period === 'MTD') {
      Object.assign(chartParams, {
        xAxis: {
          labels: {
            format: '{value:%d-%m-%Y}',
          },
          ordinal: false,
          tickPositioner: this.weeklyTickPositioner,
        },
      });
    }

    if (period === 'YTD') {
      Object.assign(chartParams, {
        xAxis: {
          labels: {
            format: '{value:%d-%m-%Y}',
          },
          ordinal: false,
          tickPositioner: this.bimonthlyTickPositioner,
        },
      });
    }

    return chartParams;
  }

  twoDigits(num: number) {
    return num.toPrecision(2);
  }


  // function to get asset class cumulative return data call function to create chart
  createAssetClassesCumulativeReturnChart(
    assetClassesCumulativeReturns: AssetClassCumulativeReturns[] | AssetClassIntradayPerformances[],
    period: string
  ) {
    if (assetClassesCumulativeReturns == null || assetClassesCumulativeReturns.length === 0) {
      return;
    }
    // this.assetClassesCumulativeReturns = assetClassesCumulativeReturns;
    const assetClassCumulative: Highcharts.SeriesLineOptions[] = [];


    this.getSelectedDate$().pipe(take(1)).subscribe(
      (selDate) => {
        for (let i = 0; i < assetClassesCumulativeReturns.length; i++) {
          let perfs = [];
          let data: [number, number][] = [];
          if (period === 'Today') {
            // intraday
            perfs = (assetClassesCumulativeReturns[i] as AssetClassIntradayPerformances).Performances;
            data = perfs.map((point: IntradayPerformancePoint): [number, number] => {
              return [moment.tz(point.Time, this._appService.TZ).valueOf(), point.Performance * 100.0];
            }).filter( point => point[1] !== 0 );
            data.unshift([+(moment().tz(this._appService.TZ).startOf('day')), 0]);
            data.push([+(moment().tz(this._appService.TZ).endOf('day')), null]);
          } else {
            perfs = (assetClassesCumulativeReturns[i] as AssetClassCumulativeReturns).PerformancesOrdered;
            data = perfs.map((point: PerformancePoint): [number, number] => {
              return [moment.tz(point.Date, this._appService.TZ).valueOf(), point.Performance * 100.0];
            });
          }
          data.sort((x, y) => x[0] - y[0]);
          const element = {
            name: assetClassesCumulativeReturns[i].AssetClass.Name,
            data: data,
          } as Highcharts.SeriesLineOptions;
          assetClassCumulative.push(element);
        }
    
        if (period === 'MTD') {
          const lastPoint = assetClassCumulative[0].data[assetClassCumulative[0].data.length - 1];
    
          if (moment(lastPoint[0]).isBefore(moment(selDate).endOf('month').startOf('day'))) {
            // add dummy point to last day of month.
            assetClassCumulative[0].data.push([moment(selDate).endOf('month').startOf('day').valueOf(), null]);
          }
        }
        if (period === 'YTD') {
          const lastPoint = assetClassCumulative[0].data[assetClassCumulative[0].data.length - 1];
    
          if (moment(lastPoint[0]).isBefore(moment(selDate).endOf('year').startOf('day'))) {
            // add dummy point to last day of month.
            assetClassCumulative[0].data.push([moment(selDate).endOf('year').startOf('day').valueOf(), null]);
          }
        }
    
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE;
        assetClassCumulative.forEach((series) => {
          [max, min] = series.data.reduce(
            ([tmpMax, tmpMin] :[number, number], [x, y]:[number, number]) => y != null ? [ Math.max(y, tmpMax), Math.min(y, tmpMin)] : [tmpMax, tmpMin],
            [max, min]
          ) as [number, number];
        });
        let limit = Math.max(Math.abs(max), Math.abs(min));
        if (limit < 0.5) {
          limit = 0.5;
        } else {
          limit = null;
        }
    
        const chartParams = this.buildReturnChartParams(period, assetClassCumulative, limit == null ? limit : -limit, limit);
        setTimeout(() => {
          const chart = this.createCumulativeReturnChart(
            'asset-class-cumulative-return',
            assetClassCumulative,
            '{value:.2f} %',
            false,
            false,
            chartParams
          );
          this.assetClassesCumulativeReturnLineChart = chart;
        });
      }
    );
  }

  // function to get style cumulative return data call function to create chart
  createStyleCumulativeReturnChart(
    strategyStyleCumulativeReturns: StrategyStyleCumulativeReturns[] | StrategyStyleIntradayPerformances[],
    period: string
  ) {
    if (strategyStyleCumulativeReturns == null || strategyStyleCumulativeReturns.length === 0) {
      this.spinnerService.hide('strategy-style-return');
      return;
    }
    const isIntraday = strategyStyleCumulativeReturns[0]['Performances'] !== undefined;

    const styleCumulativeSeries: Highcharts.SeriesLineOptions[] = [];

    const xPointSet = new Set<number>(); // the set of every date with a data point for any style strategy
    for (const series of strategyStyleCumulativeReturns) {
      let perfs: IntradayPerformancePoint[] | PerformancePoint[];
      if (isIntraday) {
        perfs = (<StrategyStyleIntradayPerformances>series).Performances;
      } else {
        perfs = (<StrategyStyleCumulativeReturns>series).PerformancesOrdered;
      }
      if (perfs == null || perfs.length === 0) {
        return;
      } else {
        for (const p of perfs) {
          const m = moment.tz(isIntraday ? (<IntradayPerformancePoint>p).Time : (<PerformancePoint>p).Date, TZ).valueOf();
          xPointSet.add(m);
        }
      }
    }
    const xPoints = Array.from(xPointSet.values()).sort();

    for (let i = 0; i < strategyStyleCumulativeReturns.length; i++) {
      let perfs = [];
      if (isIntraday) {
        perfs = (<StrategyStyleIntradayPerformances>strategyStyleCumulativeReturns[i]).Performances;
      } else {
        perfs = (<StrategyStyleCumulativeReturns>strategyStyleCumulativeReturns[i]).PerformancesOrdered;
      }
      if (!isArray(perfs) || perfs.length === 0) {
        // skip empty performance series
        continue;
      }
      const data: [number, number][] = [];
      let j = 0;
      let y = 0.0;
      let time_j = moment.tz(isIntraday ? (<IntradayPerformancePoint>perfs[j]).Time : (<PerformancePoint>perfs[j]).Date, TZ).valueOf();
      for (const x of xPoints) {
        if (x === time_j) {
          y = perfs[j].Performance * 100.0;
          data.push([x, y] as [number, number]);
          j += 1;
          if (perfs.length > j) {
            time_j = moment.tz(isIntraday ? (<IntradayPerformancePoint>perfs[j]).Time : (<PerformancePoint>perfs[j]).Date, TZ).valueOf();
          } else {
            time_j = undefined;
          }
        } else {
          // plot latest valid y value
          data.push([x, y] as [number, number]);
        }
      }
      data.sort((x, y) => x[0] - y[0]);

      const element = {
        color: this.lineColors[i % this.lineColors.length],
        name: strategyStyleCumulativeReturns[i].StrategyStyle.Name,
        data: data
      } as Highcharts.SeriesLineOptions;
      styleCumulativeSeries.push(element);
    }
    if (styleCumulativeSeries.length > 0) {
      const styleReturnCategories = [];
      const styleReturnSeries = [];
      const mostRecent = styleCumulativeSeries.reduce(
        (time: number, series) => {
          if (series.data.length === 0) {
            return 0;  // if no data in series, return time = epoch
          }
          return Math.max(time, series.data[series.data.length - 1][0]);
        },
      0);
      this.getSelectedDate$().pipe(take(1)).subscribe(
        (selDate) => { 
          if (period === 'MTD') {
            for (let i = 0; i < styleCumulativeSeries.length; i++) {
              const lastPoint = styleCumulativeSeries[i].data[styleCumulativeSeries[i].data.length - 1];
    
              if (moment(lastPoint[0]).isBefore(moment(selDate).endOf('month').startOf('day'))) {
                // add dummy point to last day of month.
                styleCumulativeSeries[i].data.push([moment(selDate).endOf('month').startOf('day').valueOf(), null]);
              }
            }
          }
    
          for (let i = 0; i < styleCumulativeSeries.length; i++) {
            const series = styleCumulativeSeries[i];
            if ( isArray(series.data) && series.data.length > 0) {
              const data = series.data.filter(([x, y]: [number, number]) => y != null);
              if (data.length === 0) {
                continue;
              }
              const lastPoint = data[data.length - 1];
              const color = series.color;
              if (lastPoint[0] === mostRecent) {
                styleReturnCategories.push(series.name);
                styleReturnSeries.push({color: color, name: series.name, y: data[data.length - 1][1]});
              } else {
                styleReturnCategories.push(series.name);
                styleReturnSeries.push({color: color, name: series.name, y: 0});
              }
            }
          }
          const chartParams = this.buildReturnChartParams(period, styleCumulativeSeries);
          setTimeout(() => {
            this.createVerticalBarChart('style-return', styleReturnCategories, styleReturnSeries, 'bar');
            this.createCumulativeReturnChart('style-cumulative-return', styleCumulativeSeries, '{value:.2f} %', false, false, chartParams);
          });
        }
      );
    }
  }

  updateRiskData(
    varOverview: AccountVaROverview | IndexVaROverview,
    volatilityOverview: VolatilityOverview,
    marginOverview: AccountMarginOverview | IndexMarginOverview,
    assetClassExposures: AssetClassExposures[] = null,
    subAssetClassExposures: SubAssetClassExposures[] = null,
    productExposures: ProductExposure[] = null
  ) {
    let portfolio: Account | Index;
    if ((<IndexVaROverview>varOverview).Index) {
      portfolio = (<IndexVaROverview>varOverview).Index;
    }
    if ((<AccountVaROverview>varOverview).Account) {
      portfolio = (<AccountVaROverview>varOverview).Account;
    }

    this._acctOverviewSvc.updateRiskBreakdown(
      {
        Portfolio: portfolio,
        VaROverview: varOverview.VaROverview,
        MarginOverview: marginOverview.MarginOverview,
        VolatilityOverview: volatilityOverview,
        AssetClassExposures: assetClassExposures,
        SubAssetClassExposures: subAssetClassExposures,
        ProductExposures: productExposures,
        RiskOverview: this.accountDailyRiskOverview,
      }
    );
  }

  loadRiskBreakdown(userId: number, accountId: number | string, date: Date) {
    this.spinnerService.show('risk-breakdown');
    const dateStr = moment.tz(date, this._appService.TZ).startOf('day').toISOString(true);
    if (this.isIndex(accountId)) {
      forkJoin<
        IndexVaROverview,
        VolatilityOverview,
        IndexMarginOverview,
        HoldingsOverview
      >(
        this.pspWebService.getIndexVaROverview(userId, +accountId, dateStr),
        this.acctDataSvc.getExAnteVolatilityOverview(userId, accountId, dateStr),
        this.pspWebService.getIndexMarginOverview(userId, +accountId, dateStr),
        this.acctDataSvc.getHoldingsOverview$(userId, +accountId, dateStr),
      ).pipe(
        map(data => {
          return [data[0], data[1], data[2],
            data[3].AssetClassesExposures,
            data[3].SubAssetClassesExposures,
            data[3].ProductsExposure,
          ] as [IndexVaROverview,
            VolatilityOverview,
            IndexMarginOverview,
            AssetClassExposures[],
            SubAssetClassExposures[],
            ProductExposure[]
          ];
        }),
      ).subscribe(data => {
        this.updateRiskData(data[0], data[1], data[2], data[3], data[4], data[5]);
      }, (error: any) => {
      });
    } else {
      forkJoin<
        AccountVaROverview,
        VolatilityOverview,
        AccountMarginOverview,
        HoldingsOverview
      >([
        this.pspWebService.getAccountVaROverview(userId, accountId, dateStr),
        this.acctDataSvc.getExAnteVolatilityOverview(userId, accountId, dateStr),
        this.pspWebService.getAccountMarginOverview(userId, accountId, dateStr),
        this.acctDataSvc.getHoldingsOverview$(userId, accountId, dateStr),
      ]).pipe(
        map(data => {
          return [data[0], data[1], data[2],
            data[3].AssetClassesExposures,
            data[3].SubAssetClassesExposures,
            data[3].ProductsExposure,
          ] as [AccountVaROverview,
            VolatilityOverview,
            AccountMarginOverview,
            AssetClassExposures[],
            SubAssetClassExposures[],
            ProductExposure[]
          ];
        }),
      ).subscribe((data: any[]) => {
        this.updateRiskData(data[0], data[1], data[2], data[3], data[4], data[5]);
      },
      (err) => {
        this.logger.error('Cannot fetch risk status data', err);
      });
    }
  }

  // hide performance chart when user clicks close button
  showDropDown(id: string) {
    document.getElementById(id).classList.toggle('show');
  }

  hideDropDown(id: string) {
    document.getElementById(id).classList.remove('show');
  }

  ngOnDestroy() {
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this._destroySubj.complete();
  }

  changeAcctIdx(id: string): void {
    // this.hideDropDown('dropdown-menu-change-account');

    if (id !== this.accountId && id != null) {
      // reset
      this.accountId = id;
      this.flags.clear();
      this.acctDataSvc.cancelAccountOverviewSubj.next();
      this.isLoading$.next(true);
      this.acctDataSvc.signalDynamicsAvailable(false);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.navigate(['/portal/account/', id]);
    }
  }

  // This function is used to create the performance chart
  plotPerformanceChart(id, name, productReturn, series2: { type: string, data: Array<[number, number]>}, extraOptions?: Highstock.Options) {
    // DD's scaling algorithm from issure #446
    productReturn.sort((x, y) => x[0] - y[0]);
    const price = productReturn.map(point => point[1]);
    price.sort((x, y) => x - y);
    const min_price = price[0];
    const max_price = price[price.length - 1];
    const range = max_price - min_price;
    let n_dig = numDigits(range);
    const n_dig_count = n_dig;
    if (n_dig < 0) {
      n_dig = Math.pow(10, n_dig);
    }
    const y_max = n_dig * Math.ceil(max_price / n_dig); // round up nearest multiple of n_dig
    let y_min = n_dig * Math.floor(min_price / n_dig);
    y_min = Math.max(y_min - (y_max - y_min), 0);

    const chartParams: Highstock.Options = {
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigator: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      legend: checkboxLegendOptions,
      colors: this.lineColors,
      xAxis: {
        labels: {
          enabled: true,
          format: '{value:%d-%m-%Y}',
        },
        type: 'datetime',
      },
      yAxis: [{
        opposite: false,
        visible: true,
        endOnTick: false,
        labels: {
          align: 'left',
        },
        max: y_max,
        min: y_min,
        showLastLabel: true,
        title: {
          text: 'Price',
        },
      }],
      series: [{
        showInLegend: false,
        name: name,
        data: productReturn,
        tooltip: {
          valueDecimals: n_dig_count <= 0 ? (2 - n_dig_count) : 2,
        },
        yAxis: 0,
      } as Highcharts.SeriesLineOptions,
      ],
    };
    if (series2 != null && series2.data.length > 0) {
      chartParams.series[0].name = 'Price';
      (chartParams.series[0] as Highcharts.SeriesAreaOptions).showInLegend = true;
      chartParams.series[0].zIndex = 2;
      chartParams.yAxis[0].opposite = true;
      chartParams.yAxis[0].alignTicks = false;

      if (series2.type === 'Deltas') {
        const deltaSeriesParams: any = {
          borderWidth: 0,
          data: series2.data,
          fillOpacity: 1,
          lineWidth: 0,
          name: 'Delta',
          showInLegend: true,
          tooltip: {
            valueDecimals: 1,
          },
          type: 'area',
          yAxis: 1,
          zIndex: 1,
        };
        chartParams.series[1] = deltaSeriesParams;
        chartParams.yAxis[1] = {
          enabled: true,
          endOnTick: false,
          max: 100,
          min: -100,
          opposite: false,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            align: 'right',
            format: '{value:.1f} %',
          },
          showLastLabel: true,
          startOnTick: false,
          tickAmount: 5,
          title: {
            text: 'Delta',
          }
        };
      } else if (series2.type === 'NetExposures') {
        const exposure = series2.data.map(point => point[1]);
        exposure.sort((x, y) => x - y);
        const min_exposure = exposure[0];
        const max_exposure = exposure[exposure.length - 1];
        const exp_range = max_exposure - min_exposure;
        let exp_n_dig = numDigits(exp_range);
        const exp_n_dig_count = exp_n_dig;
        if (exp_n_dig < 0) {
          exp_n_dig = Math.pow(10, exp_n_dig);
        }
        const exp_y_min = exp_n_dig * Math.floor(min_exposure / exp_n_dig); // min_exposure rounded down to the closest factor of n_dig
        let exp_y_max = exp_n_dig * Math.round(max_exposure / exp_n_dig); // max_exposure rounded up to the closest factor of n_dig
        exp_y_max = exp_y_max + (exp_y_max - exp_y_min);

        const netExpSeriesParams: any = {
          borderWidth: 0,
          data: series2.data,
          endOnTick: false,
          fillOpacity: 1,
          lineWidth: 0,
          name: 'Net Exposure',
          showInLegend: true,
          tooltip: {
            valueDecimals: exp_n_dig_count <= 0 ? (2 - exp_n_dig_count) : 2,
          },
          startOnTick: false,
          step: 'left',
          type: 'area',
          yAxis: 1,
          zIndex: 1,
        };
        chartParams.series[1] = netExpSeriesParams;
        chartParams.yAxis[1] = {
          enabled: true,
          labels: {
            format: '{value:.1f} %'
          },
          opposite: false,
          gridLineWidth: 0,
          max: exp_y_max,
          min: exp_y_min,
          minorGridLineWidth: 0,
          title: {
            text: 'Net Exposure',
          }
        };
      }
    }

    Object.assign(chartParams, extraOptions);

    return stockChart(id, chartParams);
  }

  updateAssetClassPerformanceTableData(newData: Array<AssetClassPerformanceAggregations>
    | Array<AssetClassIntradayDetails>
    | Array<IAssetClassReturn>): void {
    if (newData === null || newData === undefined) {
      return;
    }

    if (newData.length === 0) {
      this.assetClassPerformanceTableData = [];
      return;
    }

    // Flatten the data structures
    let assetClassPerfData: IAssetClassReturn[] = [];
    // if (newData[0] instanceof AssetClassIntradayDetails) {
      if ('VolatilityAndPerformance' in newData[0]) {
      assetClassPerfData = (newData as AssetClassIntradayDetails[]).map(item => {
        return {
          id: item.AssetClass.AssetClassId,
          assetClassName: item.AssetClass.Name,
          return: item.VolatilityAndPerformance.Performance.Performance,
        } as IAssetClassReturn;
      });
    }
    if ('DailyPerformanceAggregations' in newData[0]) {
      assetClassPerfData = (newData as AssetClassPerformanceAggregations[]).map(item => {
        return {
          id: item.AssetClass.AssetClassId,
          assetClassName: item.AssetClass.Name,
          return: item.DailyPerformanceAggregations[this.assetClassCumlativeReturnPropMap[this.assetClassCumulativeReturnPeriod]],
        } as IAssetClassReturn;
      });
    }
    if ('return' in newData[0]) {
      assetClassPerfData = (newData as IAssetClassReturn[]);
    }

    assetClassPerfData = this.sortAssetClasses(assetClassPerfData, 'assetClassName');

    if (this.showTradingLevelBasedFigures()) {
      const [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      if (magnitudeSymbol !== undefined) {
        assetClassPerfData = assetClassPerfData.map(item => {
          item.return = item.return * this.tradingLevel.TL * scalingFactor * 100;
          item['magnitudeSymbol'] = magnitudeSymbol;
          item['currencySymbol'] = '€';
          return item;
        });
      }
      this.assetClassPerformanceTableColumns[1].type = 'currencybar';
    } else {
      this.assetClassPerformanceTableColumns[1].type = 'percentbar';
    }

    this.assetClassPerformanceTableData = assetClassPerfData;

    const max_return = assetClassPerfData.reduce((a, item) => Math.max(a, Math.abs(item.return)), 0.015);
    if (max_return !== 0) {
      this.assetClassPerformanceTableColumns[1].scale = max_return * this.defaultLineChartScalingFactor;
    }

    this.updateMarketPerformanceTableBreadcrumbs();
  }

  updateSubAssetClassPerformanceTableData(
    newData: Array<SubAssetClassIntradayDetails | SubAssetClassPerformanceAggregations | ISubAssetClassReturn>,
  ) {
    if (newData === null || newData === undefined) {
      return;
    }

    if (newData.length === 0) {
      this.subAssetClassPerformanceTableData = [];
      this._subAssetClassPerformanceTableData = [];
      return;
    }

    // Flatten the data structures
    let subAssetClassPerfData: ISubAssetClassReturn[] = [];
    if ('VolatilityAndPerformance' in newData[0]) {
      subAssetClassPerfData = (newData as SubAssetClassIntradayDetails[]).map(item => {
        return {
          id: item.SubAssetClass.Name,
          subAssetClassName: item.SubAssetClass.Name,
          return: item.VolatilityAndPerformance.Performance.Performance,
          assetClassName: item.SubAssetClass.AssetClass.Name,
          assetClassId: item.SubAssetClass.AssetClass.AssetClassId,
        } as ISubAssetClassReturn;
      });
    }
    if ('DailyPerformanceAggregations' in newData[0]) {
      subAssetClassPerfData = (newData as SubAssetClassPerformanceAggregations[]).map(item => {
        return {
          id: item.SubAssetClass.Name,
          subAssetClassName: item.SubAssetClass.Name,
          return: item.DailyPerformanceAggregations[this.assetClassCumlativeReturnPropMap[this.assetClassCumulativeReturnPeriod]],
          assetClassName: item.SubAssetClass.AssetClass.Name,
          assetClassId: item.SubAssetClass.AssetClass.AssetClassId,
        } as ISubAssetClassReturn;
      });
    }
    if ('return' in newData[0]) {
        subAssetClassPerfData = (newData as ISubAssetClassReturn[]);
    }

    sortSubAssetClassColumn(subAssetClassPerfData, 'subAssetClassName', true, cmpSubAssetClassNames );

    if (this.showTradingLevelBasedFigures()) {
      const [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      if (magnitudeSymbol !== undefined) {
        subAssetClassPerfData = subAssetClassPerfData.map(item => {
          item.return = item.return * this.tradingLevel.TL * scalingFactor * 100;
          item['magnitudeSymbol'] = magnitudeSymbol;
          item['currencySymbol'] = '€';
          return item;
        });
      }
      this.subAssetClassPerformanceTableColumns[1].type = 'currencybar';
    } else {
      this.subAssetClassPerformanceTableColumns[1].type = 'percentbar';
    }

    this._subAssetClassPerformanceTableData = subAssetClassPerfData;
    this.updateSubAssetClassPerformanceTableFiltering();

    const max_return = this._subAssetClassPerformanceTableData.reduce((a, item) => Math.max(a, Math.abs(item.return)), 0.015);
    if (max_return !== 0) {
      this.subAssetClassPerformanceTableColumns[1].scale = max_return * this.defaultLineChartScalingFactor;
    }
  }


  updateMarketPerformanceTableData(newData: Array<ProductIntradayDetails | ProductPerformanceAggregations | IProductReturn>) {
    if (newData === null || newData === undefined) {
      return;
    }
    if (newData.length === 0) {
      this.marketPerformanceTableData = [];
      this._marketPerformanceTableData = [];
      return;
    }

    // Flatten the data structures
    let productPerfData: IProductReturn[] = [];
    if ('VolatilityAndPerformance' in newData[0]) {
      productPerfData = (newData as ProductIntradayDetails[]).map(item => {
        return {
          id: item.Product.ProductId,
          productName: item.Product.LongName,
          return: item.VolatilityAndPerformance.Performance.Performance,
          subAssetClassId: item.Product.SubAssetClass.Name,
          subAssetClassName: item.Product.SubAssetClass.Name,
          assetClassName: item.Product.SubAssetClass.AssetClass.Name,
          assetClassId: item.Product.SubAssetClass.AssetClass.AssetClassId,
        } as IProductReturn;
      });
    }
    if ('DailyPerformanceAggregations' in newData[0]) {
      productPerfData = (newData as ProductPerformanceAggregations[]).map(item => {
        return {
          id: item.Product.ProductId,
          productName: item.Product.LongName,
          return: item.DailyPerformanceAggregations[this.assetClassCumlativeReturnPropMap[this.assetClassCumulativeReturnPeriod]],
          subAssetClassId: item.Product.SubAssetClass.Name,
          subAssetClassName: item.Product.SubAssetClass.Name,
          assetClassName: item.Product.SubAssetClass.AssetClass.Name,
          assetClassId: item.Product.SubAssetClass.AssetClass.AssetClassId,
        } as IProductReturn;
      });
    }
    if ('return' in newData[0]) {
      productPerfData = (newData as IProductReturn[]);
    }

    if (this.showTradingLevelBasedFigures()) {
      const [magnitudeSymbol, scalingFactor] = this.getTradingLevelFactors();
      if (magnitudeSymbol !== undefined) {
        productPerfData = productPerfData.map(item => {
          item.return = item.return * this.tradingLevel.TL * scalingFactor * 100;
          item['magnitudeSymbol'] = magnitudeSymbol;
          item['currencySymbol'] = '€';
          return item;
        });
      }
      this.marketPerformanceTableColumns[1].type = 'currencybar';
    } else {
      this.marketPerformanceTableColumns[1].type = 'percentbar';
    }

    this._marketPerformanceTableData = productPerfData;

    this.updateMarketPerformanceTableFiltering();

    const max_return = this._marketPerformanceTableData.reduce((a, item) => Math.max(a, Math.abs(item.return)), 0.015);
    if (max_return !== 0) {
      this.marketPerformanceTableColumns[1].scale = max_return * this.defaultLineChartScalingFactor;
    }
  }

  updateSubAssetClassPerformanceTableFiltering() {
    const selectedItems = this.selectedItems['assetClassPerformance'] || {};
    const selectedAssetClasses = Object.keys(selectedItems)
      .filter(key => {
        return selectedItems[key] || false;
      })
      .map(item => {
        return String(item);
      });
    // debugger;
    if (selectedAssetClasses.length === 1) {
      const foundRow = this.assetClassPerformanceTableData.find(
        item => item.id.toString() === selectedAssetClasses[0]
      );
      const assetClassName = foundRow != null ? foundRow.assetClassName : null;
      this.subAssetClassPerformanceTableColumns[0].label = assetClassName != null ? `${assetClassName} sectors` : 'Sectors';
    } else {
      this.marketPerformanceTableColumns[0].label = `Sectors`;
    }

    const selectedSubAssetClassItems = this.selectedItems['subAssetClassPerformance'] || {};

    this.subAssetClassPerformanceTableData = this._subAssetClassPerformanceTableData.filter(item => {
      return selectedAssetClasses.indexOf(String(item.assetClassId)) >= 0;
    }).map(item => {
      return item;
    });

    this.selectedItems['subAssetClassPerformance'] = selectedSubAssetClassItems;
    this.updateMarketPerformanceTableBreadcrumbs();
  }

  updateMarketPerformanceTableFiltering() {
    const selectedSubAssetClassItems = this.selectedItems['subAssetClassPerformance'] || {};
    const selectedSubAssetClasses = Object.keys(selectedSubAssetClassItems).filter(key => {
      const value = selectedSubAssetClassItems[key];
      return value || false;
    }).map(item => {
      return String(item);
    });

    if (selectedSubAssetClasses.length === 1) {
      this.marketPerformanceTableColumns[0].label = `${selectedSubAssetClasses[0]} markets`;
    } else {
      // this.marketPerformanceTableColumns[0].label = `Markets`;
    }

    const selectedAssetClassItems = this.selectedItems['assetClassPerformance'] || {};
    const selectedAssetClasses = Object.keys(selectedAssetClassItems).filter(key => {
      return selectedAssetClassItems[key] || false;
    }).map(item => {
      return String(item);
    });

    this.marketPerformanceTableData = this._marketPerformanceTableData.filter(item => {
      return (
        selectedAssetClasses.indexOf(String(item.assetClassId)) >= 0 &&
        selectedSubAssetClasses.indexOf(String(item.subAssetClassName)) >= 0
      );
    });
    /*
    if (selectedSubAssetClasses.length > 1) {
      for (let i = 0; i < this.marketPerformanceTableData.length; i++) {
        let item = this.marketPerformanceTableData[i];
        item.marketName = `${item.subAssetClassName} ‣ ${item.marketName}`;
        if (selectedAssetClasses.length > 1) {
          item.marketName = `${item.assetClassName} ‣ ${item.marketName}`;
        }
      }
    }
    */
    this.updateMarketPerformanceTableBreadcrumbs();
  }

  updateMarketPerformanceTableBreadcrumbs(): void {
    const breadcrumbs = [];
    const selectedAssetClassIds = Object.keys(
      this.selectedItems['assetClassPerformance']
    ).filter(
      x => !!this.selectedItems['assetClassPerformance'][x]
    ).map(strId => Number(strId));
    const selectedSectorIds = Object.keys(
      this.selectedItems['subAssetClassPerformance']
    ).filter(
      x => !!this.selectedItems['subAssetClassPerformance'][x]
    );

    breadcrumbs.push({ label: 'All asset classes', type: 'assetClassPerformance', id: 'all'});
    breadcrumbs.push({ label: 'All sectors', type: 'subAssetClassPerformance', id: 'all'});
    if (this._marketPerformanceTableData != null && this._marketPerformanceTableData.length > 0) {
      if (
        selectedAssetClassIds != null && selectedAssetClassIds.length === 1
        && this.marketPerformanceTableData != null && this.marketPerformanceTableData.length === 0
      ) {
        // If one asset class selected add 'All <sector> markets' button
        const selectedClassId = selectedAssetClassIds[0];
        const selectedClassData = this.assetClassPerformanceAggregations.find(acPerf => acPerf.AssetClass.AssetClassId === selectedClassId);
        if (selectedClassData !== undefined) {
          const selectedClassName = selectedClassData.AssetClass.Name;
          breadcrumbs.push({ label: `All ${selectedClassName.toLowerCase() } markets`, type: 'assetClassMarkets', id: selectedClassId});
        } else {
          breadcrumbs.push({ label: 'All markets', type: 'marketPerformance', id: 'all'});
        }
      } else {
        breadcrumbs.push({ label: 'All markets', type: 'marketPerformance', id: 'all'});
      }
    }
    if (
      selectedSectorIds != null
      && selectedSectorIds.length === this._subAssetClassPerformanceTableData.length
      && breadcrumbs != null
      && breadcrumbs.length > 2) {
      breadcrumbs[2].selected = true;
    } else if (
      selectedSectorIds != null
      && this.assetClassPerformanceTableData != null
      && selectedAssetClassIds.length === this.assetClassPerformanceTableData.length
      && selectedSectorIds.length === 0
    ) {
      breadcrumbs[1].selected = true;
    } else if (Object.values(this.selectedItems['assetClassPerformance']).filter(x => !!x).length === 0) {
      breadcrumbs[0].selected = true;
    }
    this.marketPerformanceTableBreadcrumbs = breadcrumbs;

    /*
    if (this.selectedItems['assetClassPerformance']
      && Object.keys(this.selectedItems['assetClassPerformance']).length > 0
      && Object.keys(this.selectedItems['assetClassPerformance']).filter(
        id => { return this.selectedItems['assetClassPerformance'][id] }).length > 0) {
      breadcrumbs.push({ label: 'All sectors', type: 'subAssetClassPerformance', id: 'all'});
    }
    if (this.selectedItems['subAssetClassPerformance']
      && Object.keys(this.selectedItems['subAssetClassPerformance']).length > 0
      && Object.keys(this.selectedItems['subAssetClassPerformance']).filter(
        id => { return this.selectedItems['subAssetClassPerformance'][id] }).length > 0) {
      breadcrumbs.push({ label: 'All markets', type: 'marketPerformance', id: 'all'});
    }
    this.marketPerformanceTableBreadcrumbs = breadcrumbs;
    */
  }

  public getShortAcctName$(accountId: string): Observable<string> {
    return this._acctOverviewSvc.acctIdxs$.pipe(
      take(1),
      map((aiList) => {
        const maxLen = 24;
        if (!accountId) {
          accountId = this.accountId;
        }
        const acctIdx = aiList.find(elem => elem.Id === accountId);
        if (!acctIdx) { return null; }
    
        let name = acctIdx.Name;
    
        if (name.length > maxLen) {
          // abbreviate company name
          name = name.replace('Estlander & Partners', 'EP');
          if (name.length > maxLen) {
            name = name.slice(0, maxLen) + '...';
          }
          return name;
        } else {
          return name;
        }
      }),
    );
  }

  public isNumber(val: any) {
    return !isNaN(parseFloat(val));
  }

  public isIndex(pfId: string | number) {
    return isIndex(pfId);
  }

  public loadAccountPerformance(accountId: number | string) {
    this.accountPerformance = null;

    const finalizeAcctPerf = () => {
      Promise.resolve(null).then(() => this.spinnerService.hide('account-performance'));
    };

    if (this.showIntraday) {
      this.spinnerService.show('account-performance');
      // get accountPerformance for
      this.acctDataSvc.getLandingPage$().subscribe(
        (data: LandingPageData) => {
        // Update account performances
        let accountPerformances = data.AccountInfos.map(accountOverview => {
          try {
            const account = accountOverview.Account;
            const accountPerformanceTime = accountOverview.AccountIntradayPerformance.IntradayPerformance.Time;
            const accountPerformance = accountOverview.AccountIntradayPerformance.IntradayPerformance.Performance;
            return new AccountPerformance(
              account.AccountId,
              account.Name,
              accountPerformance,
              accountPerformanceTime
            );
          } catch (error) {
            if (error instanceof TypeError) {
              console.warn('Skipping empty account:', error);
              return;
            }
          }
        });

        if (data.IndexInfos !== null && data.IndexInfos.length > 0) {
          accountPerformances = accountPerformances.concat(data.IndexInfos.map(idxOverview => {
            try {
              const idx = idxOverview.Index;
              const idxPerformanceTime = idxOverview.IndexIntradayPerformance.IntradayPerformance.Time;
              const idxPerformance = idxOverview.IndexIntradayPerformance.IntradayPerformance.Performance;
              return new AccountPerformance(
                idx.IndexId.toString(),
                idx.Name,
                idxPerformance,
                idxPerformanceTime
              );
            } catch (error) {
              if (error instanceof TypeError) {
                console.warn('Skipping empty index:', error);
                return;
              }
            }
          }));
        }
        accountPerformances = accountPerformances.filter(perf => perf != null);
        this.accountPerformance = accountPerformances.find(item => item.accountId === accountId);
        if (this.accountPerformance == null) {
          this.notificationsService.error('Account not found', `Unable to open account ${accountId}. Please select a different account`);
          this.router.navigateByUrl('/portal/dashboard');
        }
        this._appService.updateTime = moment.tz(this.accountPerformance.time, TZ).toDate();
        this._appService.updateTimeFormat = 'default';
        if ( this.accountPerformance !== null) {
          console.log('Loaded Account Performance Overview for account ' + this.accountPerformance.accountName);
        }
      }, (err) => console.log(err), finalizeAcctPerf);
    } else {
      this.getSelectedDate$().pipe(take(1)).subscribe(
        (selDate) => {
          const tDay = this.getTradingDay(selDate);
          const params: any[] = [
          ];
          this.acctDataSvc.getDailyPerformanceOverview(
            +this.userId,
            accountId,
            tDay,
            365,
            this.getTradingDay(moment(tDay).subtract(1, 'year').toDate()),
            this.getTradingDay(moment(tDay).subtract(1, 'year').toDate())
          ).subscribe(
              (data: IndexDailyPerformanceOverview | AccountDailyPerformanceOverview) => {
                let acctPerf: AccountPerformance;
                if (data instanceof IndexDailyPerformanceOverview) {
                  const lastPoint = data.PerformanceOverview.DailyPerformances[data.PerformanceOverview.DailyPerformances.length - 1];
                  acctPerf = new AccountPerformance(
                    data.Index.IndexId.toString(),
                    data.Index.Name,
                    lastPoint.Performance,
                    lastPoint.Date,
                  );
                } else {
                  const lastPoint = data.PerformanceOverview.DailyPerformances[data.PerformanceOverview.DailyPerformances.length - 1];
                  acctPerf = new AccountPerformance(
                    data.Account.AccountId.toString(),
                    data.Account.Name,
                    lastPoint.Performance,
                    lastPoint.Date,
                  );
                }
                this.accountPerformance = acctPerf;
              },
              (err) => this.logger.error(`Error fetching Daily Performance Overview`, err),
              finalizeAcctPerf
          );
        }
      );
    }
  }

  loadIntradayOrders(accountId: string | int) {
    this.intradayOrdersFullfillmentRatio = null;
    this.intradayOrders = null;
    this.pspWebService.getIntradayOrders(this.userId, accountId).subscribe(data => {
      this.intradayOrders = data;

      this._acctOverviewSvc.showIntradayOrders = Array.isArray(data) && data.length > 0;

      let totalQuantity = 0;
      let filledQuantity = 0;
      for (let i = 0; i < this.intradayOrders.length; i++) {
        const order = this.intradayOrders[i];
        totalQuantity += 1;
        filledQuantity += +(order.FilledQuantity === order.Quantity);
      }
      this.intradayOrdersFullfillmentRatio = filledQuantity / totalQuantity;
      this.spinnerService.hide('intraday-orders');
    },
    (err) => {
      this.logger.error('Error loading intraday orders', err);
      this._acctOverviewSvc.showIntradayOrders = false;
    });

  }

  /** Distriubte data from updatePerformanceOverview to appropriate destination */
  updateProductPerformanceAggregations(data) {
    this.accountSubAssetClassesPerformanceAggregations = data[0];
    this.accountProductsPerformanceAggregations = data[1];
    this.updateAssetClassPerformanceTableData(this.assetClassPerformanceAggregations);
    this.updateSubAssetClassPerformanceTableData(this.accountSubAssetClassesPerformanceAggregations);
    this.updateMarketPerformanceTableData(this.accountProductsPerformanceAggregations);
  }

  /**
   *  Callback from loadPerformanceOverview. Extracts asset class
   *
   *
   */
  updatePerformanceOverview(perfOverview: PerformanceOverview, userId: string | number, accountId: string | number, date: Date) {
    this.accountDailyPerformanceOverview = perfOverview;
    if ((perfOverview !== null) && (perfOverview !== null)) {
      this.cumulativeReturns = perfOverview.CumulativeReturns;
    }

    if (perfOverview !== null) {
      this.correlationsAndVolatilities = perfOverview.BenchmarkCorrelationsAndVolatilities;
    }

    this.assetClassPerformanceTableData = null;
    this.acctDataSvc.getSubAssetClassAndProductPerformanceAggregations(+userId, accountId, dt2midnights(date)).subscribe(
      data => {
        if ((perfOverview !== null) && (perfOverview.AssetClassPerformanceAggregations !== null)) {
          this.assetClassPerformanceAggregations = perfOverview.AssetClassPerformanceAggregations;
        }
        this.updateProductPerformanceAggregations(data);
      },
      (err) => this.logger.error(err),
    );

    this.spinnerService.hide('account-daily-performance');
  }

  loadPerformanceOverview(userId: string | number, accountId: string | number, date: Date) {
    this.spinnerService.show('performance-breakdown');

    // wipe out old data
    this.accountDailyPerformanceOverview = null;
    this.cumulativeReturns = [];
    this.correlationsAndVolatilities = new IndexCorrelationsAndVolatilities();
    this.assetClassPerformanceAggregations = [];
    this.updateProductPerformanceAggregations([[], []]);

    this.acctDataSvc.getPerformanceOverview(+userId, accountId, date).subscribe(
      (data: PerformanceOverview) => {
        this.updatePerformanceOverview(data, userId, accountId, date);
        if (!this.showIntraday) {
          this._appService.updateTimeFormat = 'DD-MMM-YYYY';
          this._appService.updateTime = moment.tz(data.PerformanceDate, TZ).toDate();
        }
      },
      (err: Error) => this.logger.error(err),
    );
  }

  updateDailyReturnData(data: DailyReturnData, realTrackStartDate?: Date) {
    this.dailyReturnData = data;
    this.createPortfolioDailyReturn(data, realTrackStartDate);
  }

  loadDailyReturnData(userId: number | string, accountId: number | string, fromDate: Date, toDate: Date, period: string) {
    const handleError = (err) => {
      let errMessage = 'Could not fetch portfolio return data!';
      if (typeof err === 'string') {
        errMessage += ' ' + err;
      } else {
        if ('message' in err) {
          errMessage += ' ' + err.message;
        }
      }
      this.notificationsService.error('Error', errMessage);
      this.logger.error(errMessage, [err]);

      const defaultPeriod = this.portfolioDailyReturnTabs[this.portfolioDailyReturnTabsInitial];
      if (period !== defaultPeriod) {
        if (this.performanceBreakdownNavbar != null) {
          this.performanceBreakdownNavbar.reset();
        }
        this.PerformanceBreakdownPeriodSelect({selected: defaultPeriod});
      }
    };

    this.spinnerService.show('portfolio-daily-return');
    this.dailyReturnData = { CumulativePerformances: [], DailyPerformances: [] };
    this.acctDataSvc.getDailyReturnData(+userId, accountId, dt2midnights(fromDate), dt2midnights(toDate)).pipe(
      takeUntil(this.acctDataSvc.cancelPerformanceBreakdown$)
    ).subscribe(
      ([data, realStartDate]: [DailyReturnData, Date]) => {
        if ( data == null || (isArray(data) && (<Array<int>>data).length === 0)) {
          handleError('Portfolio return data was empty. Maybe you do not have access to this data?');
          return;
        } else {
          if (period === 'MTD') {
            if (
              data.CumulativePerformances.length > 0
              && data.CumulativePerformances[data.CumulativePerformances.length - 1].Performance == null
            ) {
              // remove previous placeholder
              data.CumulativePerformances.pop();
            }
            const lastDay = moment.tz(toDate, TZ).endOf('month').startOf('day');
            if (moment.tz(data.CumulativePerformances[data.CumulativePerformances.length - 1].Date, TZ).isBefore(lastDay)) {
              // add a placeholder at last day of period to stretch chart x-axis to end of period
              data.CumulativePerformances.push({ Date: lastDay.format('YYYY-MM-DDThh:mm'), Performance: null});
            }
          }
          if (period === 'YTD') {
            if (
              data.CumulativePerformances.length > 0
              && data.CumulativePerformances[data.CumulativePerformances.length - 1].Performance == null
            ) {
              // remove previous placeholder
              data.CumulativePerformances.pop();
            }
            const lastDay = moment.tz(toDate, TZ).endOf('year').startOf('day');
            if (moment.tz(data.CumulativePerformances[data.CumulativePerformances.length - 1].Date, TZ).isBefore(lastDay)) {
              // add a placeholder at last day of period to stretch chart x-axis to end of period
              data.CumulativePerformances.push({ Date: lastDay.format('YYYY-MM-DDThh:mm'), Performance: null});
            }
          }
          this.updateDailyReturnData(data, realStartDate);
        }
      },
      error => handleError(error),
      () => this.spinnerService.hide('portfolio-daily-return')
    );
  }

  updateDailyRiskData(data: RiskOverview, dateTime: string) {
    this.accountDailyRiskOverview = data;
    this.spinnerService.hide('account-daily-risk');
    this.loadRiskBreakdown(this.userId, this.accountId, moment.tz(dateTime, this._appService.TZ).toDate());
  }

  loadDailyRiskOverview(userId: number, accountId: number | string, dateTime: string): void {
    this.spinnerService.show('risk-breakdown');
    this.accountDailyRiskOverview = null;
    this.volatilityExAnte = null;
    this.acctDataSvc.getDailyRiskOverview(+userId, accountId, dateTime).subscribe(data => {
      this.updateDailyRiskData(data, dateTime);
      this.acctDataSvc.getVolatilityExAnteOverview$(userId, accountId, data.LastRiskInfo.Date, data.LastMonthEndRiskInfo.Date).subscribe(
        ([last, lastMonthEnd]) => {
          const volEx = { Last: null, LastMonth: null };
          if ('Volatility' in last) {
            volEx.Last = last.Volatility;
          }
          if ('Volatility' in lastMonthEnd) {
            volEx.LastMonth = lastMonthEnd.Volatility;
          }
          this.volatilityExAnte = volEx;
        }
      );
    });
  }

  unselectMarkets(unselect: boolean) {
    if (unselect) {
      this.selectedProduct = null;
      this.selectedItems['marketPerformance'] = [];
    }
  }


  updateAccountOverview(userId: int, accountId: string | int, currentDate: Date) {
    // Disable intraday data if activeDate is within 24h of current time.
    let date = currentDate;
    const now = new Date();
    this._appService.updateTime = null;
    if (moment(date).isSame(moment(now), 'day')) {
      date = this._acctOverviewSvc.getLastDay();
      this.setSelectedDate(date);
    }

    if (userId == null || accountId == null) {
      this.logger.warn('userID or accountID is null, aborting updateAccountOverview()', { userId, accountId });
      return;
    }

    this._acctOverviewSvc.showIntradayOrders = false;
  
    forkJoin([
      this._acctOverviewSvc.isLastDay$().pipe(take(1)),
      this._acctOverviewSvc.isLastMonth$().pipe(take(1)),
    ]).subscribe({
      next: ([isLastDay, isLastMonth]) => {
        this.lastDayBtnActive = isLastDay;
        this.lastMonthBtnActive = isLastMonth;
        if (!isLastDay) {
          this.showIntraday = false;
          this._acctOverviewSvc.showIntradayOrders = false;
          this.disableTodayButtons(true);
        } else {
          this.showIntraday = true;
    
          this.loadPortfolioIntradayReturn$(userId, accountId, 'Today').subscribe(
            data => {
              this.portfolioIntradayReturn = data;
              if (data != null && data.length > 0) {
                this.disableTodayButtons(false);
              } else {
                this.disableTodayButtons(true);
              }
            }
          );
        }
      }
    });
    if (!isIndex(accountId)) {
      this.acctDataSvc.getAccountTradingLevel(userId, accountId as string, date).subscribe(
        (tl) => {
          // trigger performance section update here to avoid race with trading level.
          this.tradingLevel = tl;
          this.PerformanceBreakdownPeriodSelect({selected: this.defaultPeriod});
        }
      );
    }

    // reset ui card state
    // this.perfCardCollapsed$.next(true);
    // this.riskCardCollapsed$.next(true);

    // reset time period to default
    this._navbarSvc.resetAll();

    this.spinnerService.show('account-daily-performance');
    this.spinnerService.show('account-daily-risk');
    this.loadAccountPerformance(this.accountId);
    if (this.showIntraday) {
      this.spinnerService.show('intraday-orders');
      this.loadIntradayOrders(accountId);
    }
    this.loadPerformanceOverview(userId, accountId, date);
    this.loadDailyRiskOverview(userId, accountId, dt2midnights(date));
    // this.createPositioningHolding(userId, accountId, date);
  }

  public updatePerfNavbarVisible() {
    if (this.perfSubsections != null && this.perfSubsections.length > 0) {
      const allCollapsed = this.perfSubsections.filter(sect => !sect.collapsed).length === 0;
      Promise.resolve(null).then(
        () => this.perfNavbarVisible$.next(
          allCollapsed
        )
      );
    }
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    const initDate = this._acctOverviewSvc.getLastDay();
    this.getSelectedDate$().pipe(take(1)).subscribe(
      (selDate) => {
        if (!selDate) {
          () => {
            this.setSelectedDate(initDate, true);
            selDate = initDate;
          }
        }
    
        // options for all charts on page
        Highcharts.setOptions({
          plotOptions : {
            series: {
              animation: {
                duration: 500,
              },
            },
          },
          exporting: {
            enabled: false,
          },
          time: {
            timezone: 'Europe/Helsinki',
          }
        });

        this._acctOverviewSvc.activeAcctIdx$.pipe(
          filter((active) => active != null),
          takeUntil(this._destroy$),
        ).subscribe((active) => this.changeAcctIdx(active.Id));    

        this.activeAcctIdxDescription$ = this._acctOverviewSvc.activeAcctIdx$.pipe(
          mergeMap((acctIdx) => this.acctDataSvc.getAccountIndexDescriptions$().pipe(
              map((descriptions) => {
                return descriptions.find((desc) => desc.portfolio_id === acctIdx.Id);
              }),
            ),
          ),
          takeUntil(this._destroy$),
        );
        this.portfolioIntradayReturn = null;
        
        this.resizeStickyNavbars();
    
        this._acctOverviewSvc.selectedSection$.pipe(
          map((frag) => frag != null && frag.startsWith('#') ? frag.replace(/^\#+/, '') : frag),
          takeUntil(this._destroy$),
        ).subscribe((sectionId) => {
          this.router.routeReuseStrategy.shouldReuseRoute = () => true;
          this.router.navigate([], {fragment: sectionId });
          setTimeout(() => this.router.routeReuseStrategy.shouldReuseRoute = () => false);
        });

        combineLatest([
          this.route.params.pipe(map((params) => params['id'])),
          this._acctOverviewSvc.selectedDate$,
        ]).pipe(
          switchMap(([accountId, selDate]: [string, Date]) => {
            return this.authenticationService.getCurrentUser().pipe(
              switchMap((user: User) => {
                return this.acctDataSvc.defaultPeriod$.pipe(
                  take(1),
                  switchMap((defaultPeriod) => {
                    if (user.userId === null) {
                      return throwError(new Error('userId is not set for the current user!'));
                    }
                    return this._acctOverviewSvc.acctIdxs$.pipe(
                        take(1),
                        switchMap((aiList) => {
                          this.userId = user.userId;
                          this.changeAcctIdx(accountId);
                          this.acctDataSvc.updateShowTradingLevelBasedFiguresAccountIds();
                          return of([accountId, user.userId, defaultPeriod, aiList, selDate]);
                        }),
                      )
                  }),
                );
              }),
            );
          }),
          takeUntil(this._destroy$),
        ).subscribe(
          ([accountId, userId, defaultPeriod, aiList, selDate, sectionId]: [ int | string, int, string, AcctIdx[], Date, string ]) => {
            this.defaultPeriod = defaultPeriod;
            this.setNavbarInitialSelection(this.defaultPeriod);
            this.menuService.setActiveAccountId(accountId);
            
            this._acctOverviewSvc.activeAcctIdx = aiList.find(ai => ai.Id === this.accountId);
    
            // adjust positioning of navbar in case loading the portfolio name caused the portfolio selection
            // button to wrap to separate row.
            Promise.resolve(null).then(
              () => this.resizeNavigationBar(this._appService.viewportSize)
            );
  
            // Set up market chart trigger
            if (this._selectedProductSubj != null) {
              this._selectedProductSubj.complete();
            }
            this._selectedProductSubj = new Subject<number>();
            this.selectedProduct$ = this._selectedProductSubj.asObservable().pipe(takeUntil(this._destroy$));

            this.updatePerfNavbarVisible();
            Promise.resolve(null).then(
              () => {
                if (this.perfSubsections != null && 'changes' in this.perfSubsections) {
                  this.perfSubsections.changes.subscribe(
                    (sections) => {
                      this.updatePerfNavbarVisible();
                    }
                  );
                }
              }
            );
            this.acctDataSvc.getAttachedDocuments$(userId, accountId).pipe(
              map((docs) => Array.isArray(docs) && docs.length > 1),
            ).subscribe((show) => this._acctOverviewSvc.showDocuments = show);
            this.showDocuments$ = this._acctOverviewSvc.showDocuments$;
            if (selDate == null) {
              selDate = this.getTradingDay(new Date());
              this.setSelectedDate(selDate);
            }
            this.updateAccountOverview(this.userId, this.accountId, selDate);
          },
          (err) => { this.logger.error(err); },
        );
      }
    );
    this.overviewMenuHeight$ = this._acctOverviewSvc.overviewMenuHeight$;
    this.perfNavStyle$ = this.overviewMenuHeight$.pipe(
      map((height) => {
        return { 
          'top': 'calc( ' + height + 'px + 0rem)' };
      }),
      // tap((styleObj) => console.table(styleObj)),
    );
    this._appService.viewportChange$.pipe(
      takeUntil(this._destroy$),
    ).subscribe({
      next: () => this.resizeStickyNavbars(),
    });
  }

    /**
   * This function is used as the xAxis.tickPositioner callback in Highstocks.Options to position the date ticks in YTD charts.
   * The data series should have a dummy value inserted at the end of the year, eg. [endOfYearTimestamp, null].
   *
   * The function puts a tick every friday between the first and last date in the xAxis.
   *
   * Note: xAxis.ordinal should be false for this to work well. This is only available on Highstocks charts.
   */
  private bimonthlyTickPositioner = function() {
    const positions: number[] = [];
    const startPoint = this.min;
    const endPoint = this.max;

    // loop over days between min and max, marking start of odd months
    let current = startPoint;
    while (current < endPoint) {
      const thisDay = moment.tz(current, 'Europe/Helsinki');
      // Moment.month() starts from 0, Moment.date() starts from 1
      if (thisDay.date() === 2 && thisDay.month() % 2 === 0) {
        positions.push(current);
      }
      current += 24 * 60 * 60 * 1000;
    }
    return positions;
  };

  private loadPortfolioIntradayReturn$(userId: number, accountId: string | number, period: string): Observable<IntradayPerformancePoint[]> {
    const handleError = (err: Error | string) => {
      let errMessage = 'Could not fetch portfolio return data';
      if (typeof err === 'string') {
        errMessage += ' ' + err;
      } else {
        if ('message' in err) {
          errMessage += ' ' + err.message;
        }
      }
      this.notificationsService.error('Error', errMessage);
      this.logger.error(errMessage, [err]);

      const defaultPeriod = this.portfolioDailyReturnTabs[this.portfolioDailyReturnTabsInitial];
      if (period !== defaultPeriod) {
        if (this.performanceBreakdownNavbar != null) {
          this.performanceBreakdownNavbar.reset();
        }
        this.PerformanceBreakdownPeriodSelect({selected: defaultPeriod});
      }
      return throwError(err);
    };

    const performances$ = this.acctDataSvc.getIntradayPerformances$(userId, accountId);
    return performances$.pipe(
      catchError(err => handleError(err)),
    );
  }

  private sorting(obj, sortBy, order) {
    obj.sort(
      (a, b) => {
        switch (sortBy) {
          case '': {
            const v1 = a.LS.charCodeAt(0);
            const v2 = b.LS.charCodeAt(0);
            if (order === 'Increase') {
              return v2 - v1;
            }
            return v1 - v2;
          }
          case 'risk': if (order === 'Increase') { return a.Risk - b.Risk; } return b.Risk - a.Risk;
          case 'return': if (order === 'Increase') { return a.Return - b.Return; } return b.Return - a.Return;
        }

      }
    );
    return obj;
  }

  /**
   * Expand or contract section identified by sectionId
   * @param sectionId the id of the section
   */
  toggleSectionCollapsed(sectionId: string, collapse?: boolean) {
    const sectionTable = {
      overview: this._acctOverviewSvc.collapseOverviewCard,
      performance: this._acctOverviewSvc.collapsePerfCard,
      risk: this._acctOverviewSvc.collapseRiskCard,
      orders: this._acctOverviewSvc.collapseOrderCard,
      documents: this._acctOverviewSvc.collapseDocsCard,
    }
    if (sectionId in sectionTable) {
      sectionTable[sectionId].call(this._acctOverviewSvc, collapse);
    }
  }

  /**
   * Convert a date-like input (anything moment.tz() can accept) to a standard JS Date object in browsers TZ
   */
  private tzDate(dateish: string | Date | moment.Moment ): Date {
    return moment.tz(dateish, this._appService.TZ).toDate();
  }


   /**
   * This function is used as the xAxis.tickPositioner callback in Highstocks.Options to position the date ticks in MTD charts.
   * The data series should have a dummy value inserted at the end of the month, eg. [endOfMonthTimestamp, null].
   *
   * The function puts a tick every friday between the first and last date in the xAxis.
   *
   * Note: xAxis.ordinal should be false for this to work well. This is only available on Highstocks charts.
   */
  private weeklyTickPositioner = function() {
    const positions: number[] = [];
    const startPoint = this.min;
    const endPoint = this.max;

    // loop over days between min and max, marking every friday
    let current = startPoint;
    while (current < endPoint) {
      const thisDay = new Date(current);
      if (thisDay.getDay() === 5) {
        // Friday
        positions.push(current);
      }
      current += 24 * 60 * 60 * 1000;
    }
    return positions;
  };

}

