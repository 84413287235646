<div class="">
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title title">Legal & privacy</h1>
    </div>
    <div class="card-content">
      <p><a target="_blank" href="/static/assets/Privacy.Policy.pdf">Your privacy at a glance.</a> <br>
      <a target="_blank" href="https://www.estlanderpartners.fi/?page=privacy#body367">Information about Estlander & Partners Ltd and its services.</a></p>

      <h2>DISCLAIMER</h2>
      <p>INFORMATION CONTAINED ON THE CLARITY PLATFORM OF ESTLANDER & PARTNERS LTD HAS BEEN PREPARED FOR CIRCULATION TO PERSONS REASONABLY BELIEVED TO BE WITHIN ONE OF THE EXEMPTIONS CONTAINED IN THE FINNISH ACT ON MUTUAL FUNDS (SIJOITUSRAHASTOLAKI 213/2019, AS AMENDED) AND ACT ON INVESTMENT SERVICES (SIJOITUSPALVELULAKI 747/2012, AS AMENDED) IN FINLAND (“PROFESSIONAL INVESTOR EXEMPTION”), OTHER PERSONS REASONABLY BELIEVED TO HAVE A LEVEL OF EXPERIENCE OF AND INFORMATION ON, AS WELL AS THE ABILITY TO BEAR, THE RISKS OF INVESTMENTS SIMILAR TO THE LEVEL OF PROFESSIONAL INVESTORS (“SOPHISTICATED INVESTORS”), OR TO WHOM SUCH INFORMATION MAY OTHERWISE LAWFULLY BE COMMUNICATED TO GIVE PRELIMINARY INFORMATION ABOUT THE INVESTMENT STRATEGIES DESCRIBED HEREIN. IT IS CONFIDENTIAL COMMUNICATION TO, AND SOLELY FOR THE USE OF, SUCH PERSONS. THE INFORMATION CONTAINED ON THE CLARITY PLATFORM IS NOT DIRECTED AT PERSON IN ANY JURISDICTION WHERE (BY REASON OF THAT PERSON’S NATIONALITY, RESIDENCE, CLIENT CLASSIFICATION OR OTHERWISE) AVAILABILITY OF IT IS PROHIBITED. IT IS THE RESPONSIBILITY OF EVERY PERSON RECEIVING ANY INFORMATION FROM THE CLARITY PLATFORM TO SATISFY HIMSELF AS TO THE FULL OBSERVANCE OF LAWS OF ANY RELEVANT COUNTRY. ESTLANDER & PARTNERS LTD CANNOT BE HELD LIABLE FOR ANY ACCESS TO THE CLARITY PLATFORM IN BREACH OF THE ACCESS REQUIREMENTS.</p>

      <p>THE INFORMATION PROVIDED HEREIN IS FOR INFORMATION PURPOSES ONLY AND SUBJECT TO CHANGE WITHOUT NOTICE. NO INFORMATION CONTAINED ON THE CLARITY PLATFORM SHALL/SHOULD BE CONSTRUED AS A SOLICITATION, AN OFFER, AN INVITATION OR RECOMMENDATION TO ACQUIRE OR DISPOSE OF ANY INVESTMENT IN ANY PRODUCT MANAGED OR ADVISED BY ESTLANDER & PARTNERS LTD NOR DOES IT CONSTITUTE AN OFFER FOR SALE OF ANY SUCH PRODUCT OR TO PROVIDE ADVISORY SERVICES OR TO ENGAGE IN ANY OTHER TRANSACTION. THE INFORMATION ON THE CLARITY PLATFORM IS ISSUED BY ESTLANDER & PARTNERS LTD FOR THE BENEFIT OF THE CATEGORY OF PERSON DESCRIBED IN THE FIRST PARAGRAPH ABOVE. IT IS NOT ADDRESSED TO ANY OTHER PERSON AND MAY NOT BE USED BY THEM FOR ANY PURPOSE WHATSOEVER. CONSEQUENTLY, THE INFORMATION CONTAINED HEREIN MAY NOT BE REPRODUCED OR DISSEMINATED IN WHOLE OR IN PART BY ANY RECIPIENT HEREOF OR ANY OTHER THIRD PARTY WITHOUT THE PRIOR WRITTEN CONSENT OF ESTLANDER & PARTNERS LTD.</p>

      <p>THE INFORMATION ON THE CLARITY PLATFORM CANNOT DISCLOSE ALL THE RISKS AND OTHER SIGNIFICANT ASPECTS RELATING TO THE TRADING PROGRAMS DESCRIBED HEREIN OR TO ALTERNATIVE INVESTMENTS AND IT EXPRESSES NO VIEWS AS TO THE SUITABILITY OF THE SERVICES OR OTHER MATTERS DESCRIBED HEREIN TO THE INDIVIDUAL CIRCUMSTANCES OF ANY RECIPIENT. THE CLARITY PLATFORM MAY BE USED AS A SOURCE OF INFORMATION IN RELATION TO INVESTMENT SERVICES IF ESTLANDER & PARTNERS LTD IN ITS SOLE DISCRETION DEEMS IT FIT AND IF SUCH USE IS IN ACCORDANCE WITH APPLICABLE LAW. POTENTIAL INVESTORS SHOULD NOTE THAT ALTERNATIVE INVESTMENTS CAN INVOLVE SIGNIFICANT RISKS, INCLUDING THAT ANY INVESTED CAPITAL IS AT RISK, AND THE VALUE OF AN INVESTMENT MAY GO DOWN AS WELL AS UP. THERE IS NO GUARANTEE OF TRADING PERFORMANCE AND PAST OR PROJECTED PERFORMANCE IS NOT A GUARANTEE FOR OR INDICATION OF FUTURE RESULTS.</p>

      <p>WHILE ESTLANDER & PARTNERS LTD ENDEAVORS TO KEEP THE INFORMATION UP TO DATE AND CORRECT, ESTLANDER & PARTNERS LTD OR ANY OF THE COMPANIES IN THE ESTLANDER & PARTNERS GROUP MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, ABOUT THE COMPLETENESS, ACCURACY, RELIABILITY, SUITABILITY OR AVAILABILITY WITH RESPECT TO THE PRESENTATION OR THE INFORMATION, PRODUCTS, SERVICES, OR RELATED GRAPHICS CONTAINED ON THE CLARITY PLATFORM FOR ANY PURPOSE. ANY RELIANCE YOU PLACE ON SUCH INFORMATION IS THEREFORE STRICTLY AT YOUR OWN RISK. ANY DATA ON THE ON THE CLARITY PLATFORM IS PRESENTED AS DERIVED DATA AND IS DISPLAYED WITH A 15 MINUTES DELAY.</p>
    </div>
  </div>
</div>