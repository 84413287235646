import { EPSILON } from '@app/sandbox3/constants';
import {
  Index, SubAssetClass, int, double, decimal, PerformanceInfo, guid, NAVPoint, datetime, FundingType, IndexType
} from './psp-backend.models';

export class ForecastSet {
  Name: string;
  Members: ForecastSetMember[];
}

export class ForecastResult {
  IdentifierGuid: guid;
  PortfolioName: string;
  ForecastSetName: string;
  AnnualizedReturn: double;
  ForecastNavs: NAVPoint[];
  UpperBound: NAVPoint[];
  LowerBound: NAVPoint[];
  toString = () => `${this.ForecastSetName} ${this.AnnualizedReturn}`;
}

export class ForecastSetMember {
  IndexId: int;
  AnnualizedReturn: double;
}

export class LongTermVolatility {
  Volatility: double;
  VolatilityHorizon: VolatilityHorizon;
  toString = () => `${this.VolatilityHorizon}/${this.Volatility}`;
}

export class MemberAssetWeight {
  IndexId: int;
  RiskAllocation: decimal = 0;
  CapitalAllocation: decimal = 0;
  LongTermVolatility: decimal = 0;

  constructor(member?: MemberAssetWeight) {
    if (member != null) {
      this.CapitalAllocation = member.CapitalAllocation;
      this.IndexId = member.IndexId;
      this.LongTermVolatility = member.LongTermVolatility;
      this.RiskAllocation = member.RiskAllocation;
    }
  }

  toString = () => `${this.IndexId}, RiskAllocation: ${this.RiskAllocation}, CapitalAllocation: ${this.CapitalAllocation}, `
    + `LongTermVolatility: ${this.LongTermVolatility}`
  validate = () => {
    const errors = [];

    if (this.IndexId == null || this.IndexId <= 0) {
      errors.push({IndexId: 'Asset Id must be greater than 0'});
    }
    if (this.CapitalAllocation == null || this.CapitalAllocation < 0) {
      errors.push({ CapitalAllocation: 'Must be a number greater than or equal to 0'});
    }
    if (this.RiskAllocation == null || this.RiskAllocation < 0) {
      errors.push({ RiskAllocation: 'Must be a number greater than or equal to 0'});
    }
    if (this.RiskAllocation != null && this.CapitalAllocation != null && this.RiskAllocation <= 0 && this.CapitalAllocation <= 0) {
      errors.push({Allocations: 'One of RiskAllocation or CapitalAllocation must be greater than 0'});
    }
    /*  Can't check fully here bacuse we don't know asset class of member
      If asset is cash it should be null, otherwise always larger than 0.
    if (this.LongTermVolatility != null && this.LongTermVolatility <= 0) {
      errors.push({LongTermVolatility: 'Must be greater than 0'});
    }
    */

    if (errors.length === 0) {
      return null;
    } else {
      return errors;
    }
  }
  equals: (mwB: MemberAssetWeight) => boolean = (mwB) => (
    this.IndexId === mwB.IndexId &&
    Math.abs(this.RiskAllocation - mwB.RiskAllocation) < EPSILON &&
    Math.abs(this.CapitalAllocation - mwB.CapitalAllocation) < EPSILON &&
    Math.abs(this.LongTermVolatility - mwB.LongTermVolatility) < EPSILON
  )
}

export class PortfolioCapital {
  Currency: string;
  Amount: decimal;
  equals: (pcB: PortfolioCapital) => boolean = (pcB) => {
    if (this.Amount == null || this.Currency == null) {
      return null;
    }
    return this.Currency === pcB.Currency && this.Amount === pcB.Amount;
  }
  toString = () => `${this.Amount} ${this.Currency}`;
}

export class PortfolioPositionalIndices {
  IdentifierGuid: guid;
  PositionalIndex: int;
}

export class Region {
  RegionId: RegionId;
  Name: string;

  toString = () => this.Name;
}

export enum RegionId {
  US = 1,
  Global = 4,
  EUR = 5,
}

export class ReturnType {
  ReturnTypeId: ReturnTypeId;
  Name: string;

  toString = () => this.Name;
}

export enum ReturnTypeId {
  Total = 1,
  Excess = 2,
}

export const SANDBOX_CASH_ASSET_CLASS_ID = 6;

export class SandboxAsset {
  Index: Index;
  SubAssetClass: SubAssetClass;
  Region: Region;
  ReturnType: ReturnType;
  CapitalRequirementFactor: double;
  LongTermVolatilities: LongTermVolatility[];
  PairedIndexId: int;

  get id() { return this.Index.IndexId; }
  get isCash() { return this.SubAssetClass.AssetClass.AssetClassId === SANDBOX_CASH_ASSET_CLASS_ID; }
  get name() { return this.Index.Name; }
  get subClassName() { return this.SubAssetClass.AssetClass.AssetClassId + '♢' + this.SubAssetClass.Name; }

  constructor(values: { [k in keyof SandboxAsset]?: SandboxAsset[k] }) {
    // Initialise with SandboxAsset-like object
    if ('Index' in values) {
      this.Index = values.Index as Index;
    }
    if ('SubAssetClass' in values) {
      this.SubAssetClass = values.SubAssetClass as SubAssetClass;
    }
    if ('Region' in values) {
      this.Region = values.Region as Region;
    }
    if ('ReturnType' in values) {
      this.ReturnType = values.ReturnType as ReturnType;
    }
    if ('PairedIndexId' in values) {
      this.PairedIndexId = values.PairedIndexId;
    }
    if ('LongTermVolatilities' in values) {
      this.LongTermVolatilities = values.LongTermVolatilities;
    }
    if ('CapitalRequirementFactor' in values) {
      this.CapitalRequirementFactor = values.CapitalRequirementFactor;
    }
  }
  toString = () => this.Index.toString();
}

export class SandboxPortfolio {
  CombinedIndex: Index;
  PortfolioCapital: PortfolioCapital;
  Members: MemberAssetWeight[];

  constructor(init?: SandboxPortfolio | { [k in keyof SandboxPortfolio]?: SandboxPortfolio[k] }) {
    if (init == null) {
      return;
    }
    if (init.CombinedIndex) {
      const idx = new Index();
      Object.assign(idx, init.CombinedIndex);
      if (init.CombinedIndex['FundingType'] != null) {
        const ft = new FundingType();
        Object.assign(ft, init.CombinedIndex['FundingType']);
        idx.FundingType = ft;
      }
      if (init.CombinedIndex['IndexType'] != null) {
        const it = new IndexType();
        Object.assign(it, init.CombinedIndex['IndexType']);
        idx.IndexType = it;
      }
      this.CombinedIndex = idx;
    }
    if (init.PortfolioCapital) {
      const pcap = new PortfolioCapital();
      Object.assign(pcap, init.PortfolioCapital);
      this.PortfolioCapital = pcap;
    }
    if (init.Members != null && Array.isArray(init.Members)) {
      this.Members = [];
      for (const m of init.Members) {
        const newMember = new MemberAssetWeight(m);
        this.Members.push(m);
      }
    }
  }

  equals(pfB: SandboxPortfolio): boolean {
    return this.CombinedIndex.equals(pfB.CombinedIndex) &&
      this.PortfolioCapital.equals(pfB.PortfolioCapital) &&
      this.Members.length === pfB.Members.length &&
      this.Members.reduce((allEqual, m, i) => {
        if (m instanceof MemberAssetWeight) {
          return allEqual && m.equals(pfB.Members[i]);
        } else {
          // Something is wierd. Fall back to ===, which will probably fail unless this and pfB are the same object.
          return allEqual && m === pfB.Members[i];
        }
      }, true);
  }

  toString = () => `Portfolio “${this.CombinedIndex.toString()}”`;

  validate() {
    const errors = [];

    // Check CombinedIndex
    if (this.CombinedIndex == null) {
      errors.push({ CombinedIndex: 'CombinedIndex must not be null' });
    } else {
      if (this.CombinedIndex.Name == null || this.CombinedIndex.Name === '') {
        errors.push({ CombinedIndex: 'CombinedIndex.Name must not be empty' });
      }
    }

    if (this.PortfolioCapital == null) {
      errors.push({ PortfolioCapital: 'PortfolioCapital must not be null' });
    } else {
      if (this.PortfolioCapital.Amount == null || this.PortfolioCapital.Amount <= 0) {
        errors.push({ PortfolioCapital: 'PortfolioCapital.Amount must be greater than 0' });
      }
    }

    if (!Array.isArray(this.Members) || this.Members.length === 0) {
      errors.push({ Members: 'Members must not be empty' });
    } else {
      const memberErrors = this.Members.map(m => m.validate()).filter(mv => Array.isArray(mv) && mv.length > 0);
      if (memberErrors.length > 0) {
        errors.push({ Members: memberErrors });
      }
    }

    if (errors.length === 0) {
      return null;
    } else {
      return errors;
    }
  }
}

export class SandboxPortfolioForecastsRequest {
  SandboxPortfolio: SandboxPortfolioParameters;
  ForecastSets: ForecastSet[];
  NavStart: NAVPoint;
  ToDateInclusive: datetime;
  toString = () => this.SandboxPortfolio.Name;
}

export class SandboxPortfolioParameters {
  Name: string;
  Description: string;
  PortfolioCapital: PortfolioCapital;
  Members: MemberAssetWeight[];
  IdentifierGuid: guid;

  toString = () => this.Name || this.IdentifierGuid;
}

export class SandboxPortfoliosCorrelations {
  PortfolioPositionalIndices: PortfolioPositionalIndices[];
  Correlations: number[][];
}

export class TemporarySandboxPortfolioInfo {
  SandboxPortfolioParameters: SandboxPortfolioParameters;
  PerformanceInfo: PerformanceInfo;
  IdentifierGuid: guid;
}

export class VolatilityHorizon {
  Name: string;
  VolatilityHorizonId: int;

  toString = () => this.Name;
}

